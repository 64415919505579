import _ from 'lodash'

import * as services from '../services/serviceCorrosionRate'
import * as corrosionRateConstants from '../containers/views/corrosion-rate-page/helpers/constants'

import { fetchStart, fetchSuccess, fetchError } from './common/actionFetch'

//#region Search Panel

export const CORROSION_RATE_SEARCH_UPDATE = 'CORROSION_RATE_SEARCH_UPDATE'
export const CORROSION_CALCULATE_RATE = 'CORROSION_CALCULATE_RATE'
export const CORROSION_CREATE_SUMMARY = 'CORROSION_CREATE_SUMMARY'
export const CORROSION_HISTORICAL_DATA = 'CORROSION_HISTORICAL_DATA'

export const calculateCorrosionRate = (searchObject) => {
  return (dispatch, getState) => {
    //const { iyoInputPage: { demand } = {} } = getState()
    dispatch({
      type: CORROSION_RATE_SEARCH_UPDATE,
      payload: searchObject,
    })

    if (
      searchObject.calculateResultMode ===
      corrosionRateConstants.SEARCH_CALCULATE_RESULT_MODE.CALCULATE_CORROSION_RATE
    ) {
      dispatch(fetchStart(CORROSION_CALCULATE_RATE))
      services
        .postAPIStandard({
          searchType: searchObject.highTempNhHs,
          crudeType: searchObject.crudeMode,
          unit: searchObject.unit,
          operatingUnit: searchObject.operatingUnit,
          fraction: searchObject.fraction,
          feedRate: searchObject.unitFeedRate,
          washWater: searchObject.washWater,
          crudeCode: _.get(searchObject, 'crudeNameAbbreviation.crudeCode'),
          blendDate: searchObject.blendDate,
          blendComposition: convertInputUnitsToBlendComposition(searchObject),
          material: searchObject.material,
          temperature: searchObject.operatingTemperature,
        })
        .then((response) =>
          dispatch({
            type: CORROSION_CALCULATE_RATE,
            payload: response,
          })
        )
        .then(() => dispatch(fetchSuccess(CORROSION_CALCULATE_RATE)))
        .catch(() => dispatch(fetchError(CORROSION_CALCULATE_RATE)))

      dispatch(fetchStart(CORROSION_HISTORICAL_DATA))
      services
        .postHistoricalData({
          crudeType: searchObject.crudeMode,
          crudeCode: _.get(searchObject, 'crudeNameAbbreviation.crudeCode'),
          blendDate: searchObject.blendDate,
          blendComposition: convertInputUnitsToBlendComposition(searchObject),
          isBlendDate: searchObject.isSearchByCrudeBlendDate,
          operatingUnit: searchObject.operatingUnit,
        })
        .then((response) =>
          dispatch({
            type: CORROSION_HISTORICAL_DATA,
            payload: response,
          })
        )
        .then(() => dispatch(fetchSuccess(CORROSION_HISTORICAL_DATA)))
        .catch(() => dispatch(fetchError(CORROSION_HISTORICAL_DATA)))
    } else {
      dispatch(fetchStart(CORROSION_CREATE_SUMMARY))
      services
        .postSummaryTable({
          searchType: searchObject.highTempNhHs,
          crudeType: searchObject.crudeMode,
          crudeCode: _.get(searchObject, 'crudeNameAbbreviation.crudeCode'),
          unit: searchObject.unit,
          blendDate: searchObject.blendDate,
          blendComposition: convertInputUnitsToBlendComposition(searchObject),
          feedRate: searchObject.unitFeedRate,
          washWater: searchObject.washWater,
        })
        .then((response) =>
          dispatch({
            type: CORROSION_CREATE_SUMMARY,
            payload: response,
          })
        )
        .then(() => dispatch(fetchSuccess(CORROSION_CREATE_SUMMARY)))
        .catch(() => dispatch(fetchError(CORROSION_CREATE_SUMMARY)))
    }
  }
}

export const CORROSION_SIMULATE_SUMMARY_TABLE = 'CORROSION_SIMULATE_SUMMARY_TABLE'
export const simulateSummaryTable = (data) => {
  return (dispatch, getState) => {
    dispatch(fetchStart(CORROSION_SIMULATE_SUMMARY_TABLE))
    services
      .postSummaryTableSimulateNSave({
        saveType: data.saveType,
        isSave: data.isSave,
        summaryTable: data.summaryTable,
        crudeType: data.crudeType,
      })
      .then((response) =>
        dispatch({
          type: CORROSION_SIMULATE_SUMMARY_TABLE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(CORROSION_SIMULATE_SUMMARY_TABLE)))
      .catch(() => dispatch(fetchError(CORROSION_SIMULATE_SUMMARY_TABLE)))
  }
}

export const saveSummaryTable = (data, successCallback) => {
  return (dispatch, getState) => {
    dispatch(fetchStart(CORROSION_SIMULATE_SUMMARY_TABLE))
    services
      .postSummaryTableSimulateNSave({
        saveType: data.saveType,
        isSave: data.isSave,
        summaryTable: data.summaryTable,
        crudeType: data.crudeType,
      })
      .then((response) => successCallback && successCallback())
      .then(() => dispatch(fetchSuccess(CORROSION_SIMULATE_SUMMARY_TABLE)))
      .catch(() => dispatch(fetchError(CORROSION_SIMULATE_SUMMARY_TABLE)))
  }
}

//#endregion

//#region Private Methods

const convertInputUnitsToBlendComposition = (searchObject) => {
  if (
    _.isNull(_.get(searchObject, 'inputUnits.unit1')) &&
    _.isNull(_.get(searchObject, 'inputUnits.unit2'))
  )
    return undefined

  let blendComposition = []

  for (let count = 1; count <= 10; count++) {
    if (
      !_.isNull(
        _.get(searchObject, 'inputUnits.unit' + count) &&
          !_.isEmpty(_.get(searchObject, 'inputUnits.unit' + count)) &&
          !_.isNull(_.get(searchObject, 'inputUnits.unitPercent' + count)) &&
          _.get(searchObject, 'inputUnits.unitPercent' + count) !== ''
      )
    ) {
      blendComposition.push({
        crudeCode: _.get(searchObject, 'inputUnits.unit' + count + '.crudeCode'),
        percentage: _.get(searchObject, 'inputUnits.unitPercent' + count),
      })
    }
  }
  return blendComposition
}

//#endregion
