
import React from 'react'

const CrudeSummaryBrief = () => {

    return (
        <div>Crude Summary Brief</div>
    )
}

export default CrudeSummaryBrief;