import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'

// Components
import { Grid, Header, Segment, Dropdown, Button, Image } from 'semantic-ui-react'
import { RisksCrudeBlendChart } from '../../../../components/charts/risks-crude-blend/risks-crude-blend-chart'
import Chevron from '../../../../components/chevron'
import PeriodPicker from '../../../../components/period-picker'
import FetchStateWrapper from '../../../../components/fetch-state-wrapper'
import NoDataBlock from '../../../../components/no-data-block'

// Hooks
import { useStateWithPaths } from '../../../../hooks/useConnect'

// Redux
import { PERSONAL_SETTING_REDUCER } from '../../../../reducers/common/personalSettingReducer'
import { MASTER_DATA_REDUCER } from '../../../../reducers/common/masterDataReducer'
import { setCrudeBlendChart } from '../../../../actions/actionRisks'
import * as RisksPageAction from '../../../../actions/actionRisks'
import { getMasterUnits } from '../../../../actions/common/actionMasterData'

const CompareBlendChart = (props) => {
  const dispatch = useDispatch()
  const inputUnits = props.searchObject.inputUnits
  const [{ singleCrudeUnits }] = useStateWithPaths([`${MASTER_DATA_REDUCER}`])

  const [{ units }] = useStateWithPaths([`${MASTER_DATA_REDUCER}`])
  const [{ currentPersona }] = useStateWithPaths([`${PERSONAL_SETTING_REDUCER}`])

  const [crudeBlendChartData] = useStateWithPaths(['risksPage.crudeBlendChart'])
  const [periods] = useStateWithPaths(['risksPage.crudeBlendChart.period'])

  const [defaultPeriod] = useState([crudeBlendChartData.defaultPeriod])
  const [period, setPeriod] = useState([periods])

  const [unit, setUnit] = useState(crudeBlendChartData.unit)

  const getCrudeBlendData = _.get(crudeBlendChartData, 'data')

  const offsetPercentage = props.searchObject.offset

  let numberBarDisplay = 32
  const [isFirstBar, setIsFirstBar] = useState(true)
  const [isLastBar, setIsLastBar] = useState(false)
  const [indexFrom, setIndexFrom] = useState(0)
  const [indexTo, setIndexTo] = useState(32)
  const getNewBarList = { category: [], series: [] }
  const onClickNext = () => {
    setIsFirstBar(false)
    setIsLastBar(indexTo + numberBarDisplay >= Object.keys(getCrudeBlendData.category).length - 1)
    setIndexFrom(indexFrom + numberBarDisplay)
    setIndexTo(indexTo + numberBarDisplay)
  }

  const onClickPrevious = () => {
    setIsFirstBar(indexFrom - numberBarDisplay <= 0)
    setIsLastBar(false)
    setIndexFrom(indexFrom - numberBarDisplay)
    setIndexTo(indexTo - numberBarDisplay)
  }

  if (!_.isEmpty(getCrudeBlendData.category)) {
    getCrudeBlendData.category
      .filter((item, i) => i >= indexFrom && i < indexTo)
      .map((item) => getNewBarList.category.push(item))

    _.map(getCrudeBlendData.series, (item, index) => {
      const dataSeries = []
      item.data
        .filter((itemSeries, i) => i >= indexFrom && i < indexTo)
        .map((itemSeries) => dataSeries.push(itemSeries))

      const seriesObj = { name: item.name, color: item.color, data: dataSeries }
      getNewBarList.series.push(seriesObj)
    })
  }

  useEffect(() => {
    dispatch(setCrudeBlendChart(offsetPercentage, period, unit, inputUnits))
    dispatch(getMasterUnits(currentPersona))
  }, [dispatch, period, unit, currentPersona])
  return (
    <div>
      <Grid padded className={`crude-blend-chart`}>
        <Grid.Row columns={2} verticalAlign="bottom">
          <Grid.Column textAlign="left">
            <Header size="small" textAlign="left">
              CRUDE BLEND
            </Header>
          </Grid.Column>
          <Grid.Column>
            <div className={`flex end`}>
              <Dropdown
                placeholder="Select unit"
                className={`cdu-unit-dropdown-container`}
                value={unit}
                defaultValue={unit}
                selection
                options={singleCrudeUnits}
                onChange={(e, data) => {
                  setUnit(data.value)
                  setIsFirstBar(true)
                  setIsLastBar(false)
                  setIndexFrom(0)
                  setIndexTo(32)
                }}
              />
              <PeriodPicker
                defaultPeriod={defaultPeriod}
                period={period}
                setPeriod={setPeriod}
                onClick={setCrudeBlendChart(offsetPercentage, period, unit)}
              />
            </div>
          </Grid.Column>
        </Grid.Row>

        <Segment className={`width-100`}>
          <FetchStateWrapper id={RisksPageAction.POST_RISK_CRUDE_BLEND_CHART}>
            <Grid.Row columns={12}>
              <div className={`flex pad-x-4 pad-top-2`}>
                <div className={`pad-x-4`}>
                  <span className={`bold-value`}>Time processed</span> in History since 2011:{' '}
                  <span className={`time-processed bold-value`}>
                    {_.get(getCrudeBlendData, 'timesProcessedInHistory')}
                  </span>{' '}
                  days
                </div>
                <div>
                  <span className={`bold-value`}>Time processed</span> in Selected time:{' '}
                  <span className={`time-processed bold-value`}>
                    {_.get(getCrudeBlendData, 'timesProcessedInRange')}
                  </span>{' '}
                  days
                </div>
              </div>
              <div className={` crude-blend-container pad-bottom-2 flex center`}>
                <Button
                  disabled={isFirstBar}
                  onClick={onClickPrevious}
                  style={{ backgroundColor: 'transparent' }}
                >
                  <Chevron direction="left" />
                </Button>

                {_.isEmpty(_.get(crudeBlendChartData, 'data.series')) ? (
                  <NoDataBlock />
                ) : (
                  <div className={`flex column width-100`}>
                    <RisksCrudeBlendChart crudeBlendData={getNewBarList} />
                  </div>
                )}

                <Button
                  disabled={getNewBarList.category.length <= 31 ? true : isLastBar}
                  onClick={onClickNext}
                  style={{ backgroundColor: 'transparent' }}
                >
                  <Chevron direction="right" />
                </Button>
              </div>
            </Grid.Row>
          </FetchStateWrapper>
        </Segment>
      </Grid>
    </div>
  )
}

export default CompareBlendChart
