import React from 'react'

import _ from 'lodash'
import { Grid, Header, Table } from 'semantic-ui-react'

//Redux
import { CORROSION_PAGE_REDUCER } from '../../../../reducers/corrosionPageReducer'

//Hooks
import { useStateWithPaths } from '../../../../hooks/useConnect'

//Constants
import * as corrosionPageConstants from '../helpers/constants'

//Global Helpers
import { rowSpanTableDataByProperties } from '../../../../helpers/table'

//#region Private

const ApiStandardTable = ({ data, temperatureRangeData }) => {
  let renderFirstColumn = false
  return (
    <Table celled structured textAlign="center" className="table-api-standard">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell rowSpan="1">Sulfer (wt%)</Table.HeaderCell>
          <Table.HeaderCell rowSpan="1">TAN (mg/g)</Table.HeaderCell>
          <Table.HeaderCell colSpan="8">Temperature (C)</Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell colSpan="2">C</Table.HeaderCell>
          {_.get(temperatureRangeData, 'celsius').map((item, index) => {
            return <Table.HeaderCell key={index}>{item}</Table.HeaderCell>
          })}
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell colSpan="2">F</Table.HeaderCell>
          {_.get(temperatureRangeData, 'fahrenheit').map((item, index) => {
            return <Table.HeaderCell key={index}>{item}</Table.HeaderCell>
          })}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {data.map((item, index) => {
          renderFirstColumn = index < 1 || item.sulfer.value !== data[index - 1].sulfer.value

          return (
            <Table.Row key={index}>
              {renderFirstColumn && (
                <Table.Cell rowSpan={item.sulfer.rowSpan} textAlign="center">
                  {item.sulfer.value}
                </Table.Cell>
              )}
              <Table.Cell textAlign="center">{item.tan.value}</Table.Cell>
              <Table.Cell
                textAlign="center"
                className={item.temp_lteq_232.isHighlighted ? 'label-highlighted' : ''}
              >
                {item.temp_lteq_232.value}
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                className={item.temp_233_260.isHighlighted ? 'label-highlighted' : ''}
              >
                {item.temp_233_260.value}
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                className={item.temp_261_270.isHighlighted ? 'label-highlighted' : ''}
              >
                {item.temp_261_270.value}
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                className={item.temp_288_315.isHighlighted ? 'label-highlighted' : ''}
              >
                {item.temp_288_315.value}
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                className={item.temp_316_343.isHighlighted ? 'label-highlighted' : ''}
              >
                {item.temp_316_343.value}
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                className={item.temp_344_371.isHighlighted ? 'label-highlighted' : ''}
              >
                {item.temp_344_371.value}
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                className={item.temp_372_398.isHighlighted ? 'label-highlighted' : ''}
              >
                {item.temp_372_398.value}
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                className={item.temp_gt_399.isHighlighted ? 'label-highlighted' : ''}
              >
                {item.temp_gt_399.value}
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

//#endregion

const ApiStandard = () => {
  const [{ searchPanel, searchResult }] = useStateWithPaths([`${CORROSION_PAGE_REDUCER}`])
  const { highTempNhHs, calculateResultMode } = searchPanel

  const tableData = _.get(searchResult, 'apiStandard.data')
  const getMaterial = _.get(searchPanel, 'material')

  if (!tableData || _.get(tableData, 'length') < 1) {
    return null
  }

  if (
    highTempNhHs !== corrosionPageConstants.SEARCH_HIGHTEMP_NHNS.HIGH_TEMP ||
    calculateResultMode !==
      corrosionPageConstants.SEARCH_CALCULATE_RESULT_MODE.CALCULATE_CORROSION_RATE
  ) {
    return null
  }

  const temperatureRangeData = {
    celsius: [
      '<= 232',
      '233 - 260',
      '261 - 287',
      '288 - 315',
      '316 - 343',
      '344 - 371',
      '372 - 398',
      '> 399',
    ],
    fahrenheit: [
      '<= 450',
      '451 - 500',
      '501 - 550',
      '551 - 600',
      '601 - 650',
      '651 - 700',
      '701 - 750',
      '> 750',
    ],
  }

  const formattedTableData = rowSpanTableDataByProperties(tableData, 'sulfer', 'sulfer.value')

  return (
    <div className="section section-api-standard">
      <Grid padded>
        <Grid.Row>
          <Grid.Column textAlign="left">
            <Header size="small" textAlign="left">
              API STANDARD
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="left">
            <div>
              Estimated corrosion rate for {getMaterial} in mm/y (1 mm = 4 mils) (Reproduced
              courtesy of the American Petroleum Institute)
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <div>
              <ApiStandardTable
                data={formattedTableData}
                temperatureRangeData={temperatureRangeData}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={`mar-top-1`}>
          <Grid.Column textAlign="left">
            <Header size="small" textAlign="left">
              HISTORICAL DATA
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default ApiStandard
