
import React from 'react'


const CrudeSummaryEnts = () => {

    return (
        <div>ENTS</div>
    )
}

export default CrudeSummaryEnts;