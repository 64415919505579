import React, { useState } from "react";
import { Modal, Button, Icon } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

//Redux
import * as personalSettingActions from "../../actions/common/actionPersonalSetting";
import { PERSONAL_SETTING_REDUCER } from "../../reducers/common/personalSettingReducer";

//Hooks
import { useActions, useStateWithPaths } from "../../hooks/useConnect";

import { USER_PERSONA_LIST } from "../../constants/userGroups";

const PersonaSwitcher = ({ isOpen, confirmCallback, cancelCallback }) => {
  const history = useHistory();
  const [{ currentPersona }] = useStateWithPaths([
    `${PERSONAL_SETTING_REDUCER}`,
  ]);

  const [selectedUserPersona, setSelectedUserPersona] = useState(
    currentPersona
  );

  return (
    <Modal
      dimmer={"blurring"}
      open={isOpen}
      onClose={() => cancelCallback()}
      size="tiny"
      style={{ zIndex: 999 }}
    >
      <Modal.Header className="with-close">
        <div className="user-persona-modal-header">
          <Icon className="icon-background" name="refresh" size="tiny" /> Switch
          Persona
        </div>
        <div>
          <Icon name="close" onClick={cancelCallback} />
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className="user-persona-modal-content">
          <div>Please select the persona you would like to switch to</div>
          <div className="user-persona-container">
            {USER_PERSONA_LIST.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`user-persona-item ${
                    selectedUserPersona === item.value ? "active" : ""
                  } ${currentPersona === item.value ? "disabled" : ""}`}
                  onClick={() => setSelectedUserPersona(item.value)}
                >
                  {item.title}
                </div>
              );
            })}
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="btn-primary"
          onClick={() => (
            confirmCallback(selectedUserPersona), history.push("/")
          )}
        >
          OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PersonaSwitcher;
