import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'

// Components
import {
  Grid,
  Header,
  Segment,
  Divider,
  Image,
  Dropdown,
  Button,
  Popup,
  Icon,
} from 'semantic-ui-react'
import { AMSevenDayCrudeBlendChart } from '../../../components/charts/seven-day-crude-blend-chart/am-seven-day-crude-blend-chart'
import { PMSevenDayCrudeBlendChart } from '../../../components/charts/seven-day-crude-blend-chart/pm-seven-day-crude-blend-chart'
import Chevron from '../../../components/chevron'
import PeriodPicker from '../../../components/period-picker'
import FetchStateWrapper from '../../../components/fetch-state-wrapper'
import NoDataBlock from '../../../components/no-data-block'

// Helper
import { dropdownCduUnitOptions } from './helper'
import { sevenDayChartInfo } from './helper'
import { stringToDecimalPoint } from '../../../helpers/variables'

// Hooks
import { useStateWithPaths } from '../../../hooks/useConnect'

// Redux
import { PERSONAL_SETTING_REDUCER } from '../../../reducers/common/personalSettingReducer'
import { MASTER_DATA_REDUCER } from '../../../reducers/common/masterDataReducer'

import {
  setSevenDayCrudeBlendChart,
  setSevenDaySelectedChartData,
} from '../../../actions/actionLanding'
import { getMasterUnits } from '../../../actions/common/actionMasterData'

import * as SevenDayActions from '../../../actions/actionLanding'

import DisabledNewIcon from '../../../assets/images/disabled-new-icon.svg'
import DisabledConcernIcon from '../../../assets/images/disabled-concern-icon.svg'
import NewIcon from '../../../assets/images/new-icon.svg'
import ConcernIcon from '../../../assets/images/concern-icon.svg'

const SevenDaysCrudeBlendContainer = (props) => {
  const i = _.get(props.selectedData, 'selectedIndex')
  return (
    <div className={`seven-day-info-container`}>
      <Header size="medium" textAlign="left">
        <span>Blend of </span>
        {_.isEmpty(props.selectedDate) ? (
          ''
        ) : (
          <span>{moment(props.selectedDate).format('DD MMM YYYY')}</span>
        )}

        <span>, {props.selectedData.chartType}</span>
      </Header>
      <Divider />
      <div className={`flex column`}>
        <div className={`flex row width-100 space-between`}>
          <div className={`pad-right-1_5`}>
            <div className={`seven-day-chart-legend-title`}>Status:</div>
            {_.map(props.selectedData, (a) => (
              <div className={`flex space-between seven-day-info-icons`}>
                {a.isNew === true ? <Image src={NewIcon} /> : <Image src={DisabledNewIcon} />}
                {a.hasConcerns === true ? (
                  <Image src={ConcernIcon} />
                ) : (
                  <Image src={DisabledConcernIcon} />
                )}
              </div>
            ))}
          </div>
          <div className={`pad-right-1_5`} style={{ width: '30%' }}>
            <div className={`seven-day-chart-legend-title`}>Processing:</div>
            {_.map(props.selectedData, (a) => (
              <div className={`flex center space-between seven-day-info-column-container`}>
                <div
                  className={`seven-day-content-info-square`}
                  style={{ background: `${a.color}` }}
                />
                <div className={`seven-day-content-info-name`}>{a.name}</div>
                <div className={`seven-day-content-info-value`}>
                  {stringToDecimalPoint(a.value, 1, 1)}%
                </div>
              </div>
            ))}
          </div>
          <div className={`pad-right-1_5`} style={{ width: '70%' }}>
            <div className={`seven-day-chart-legend-title`}>Tank Number:</div>
            {_.map(props.selectedData, (a) => (
              <div className={`flex center space-between seven-day-tank-no-column-container`}>
                <div className={`seven-day-content-tank-no`}>
                  {!_.isEmpty(a.tank_no[i]) ? (
                    <div>{a.tank_no[i]}</div>
                  ) : (
                    // <Popup
                    //   content={a.tank_no[i]}
                    //   trigger={<Icon size="small" name="info circle" />}
                    // />
                    ''
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={`pad-right-1_5`}>
          <div className={`seven-day-chart-legend-title`}>Note:</div>
          <div className={`flex center space-between seven-day-info-column-container`}>
            <div className={`seven-day-content-tank-no`}>
              {_.isEmpty(_.get(props.selectedData, 'note'))
                ? '-'
                : _.get(props.selectedData, 'note')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const SevenDaysCrudeBlend = () => {
  const dispatch = useDispatch()

  const [{ units }] = useStateWithPaths([`${MASTER_DATA_REDUCER}`])
  const [{ currentPersona }] = useStateWithPaths([`${PERSONAL_SETTING_REDUCER}`])

  const [sevenDayCrudeBlendData] = useStateWithPaths(['landingPage.sevenDayCrudeBlendChart'])
  const [selectedData] = useStateWithPaths(['landingPage.sevenDayCrudeBlendChart.selectedData'])
  const periods = useStateWithPaths(['landingPage.sevenDayCrudeBlendChart.period'])

  const [period, setPeriod] = useState(periods)

  const startDate = _.map(period, (a) => a.startDate)
  const year = moment(...startDate).format('YYYY')
  const formatSelectedDate = _.get(selectedData, 'date') + year

  const selectedDate = !_.isEmpty(selectedData)
    ? moment(formatSelectedDate, ['DDMMMY', 'DDMMMY']).format('YYYY-MM-DD')
    : ''

  const [unit, setUnit] = useState(sevenDayCrudeBlendData.unit)

  useEffect(() => {
    dispatch(setSevenDayCrudeBlendChart(period, unit, currentPersona))
    dispatch(getMasterUnits(currentPersona))
  }, [period, unit, currentPersona])
  return (
    <Grid padded className={`seven-day-crude-blend-chart`}>
      <Grid.Row columns={2} verticalAlign="bottom">
        <Grid.Column textAlign="left">
          <Header size="small" textAlign="left">
            7 DAYS CRUDE BLEND
          </Header>
        </Grid.Column>
        <Grid.Column>
          <div className={`flex end`}>
            <Dropdown
              placeholder="Select unit"
              className={`cdu-unit-dropdown-container`}
              value={unit}
              defaultValue={unit}
              selection
              options={units}
              onChange={(e, data) => {
                setUnit(data.value)
              }}
            />
            {/* <PeriodPicker
              defaultPeriod={period}
              period={period}
              setPeriod={setPeriod}
              onClick={setSevenDayCrudeBlendChart(period, unit)}
            /> */}
          </div>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row column={2}>
        <Segment className={`width-100`}>
          <div className={`flex pad-x-1_5 pad-y-2`}>
            <SevenDaysCrudeBlendContainer selectedData={selectedData} selectedDate={selectedDate} />

            <div className={`flex center pad-x-1`} style={{ width: '50%' }}>
              <Button
                className={``}
                onClick={() => {
                  const startDate = moment(_.get(...period, 'startDate'))
                  const endDate = moment(_.get(...period, 'endDate'))
                  return (
                    setPeriod([
                      {
                        startDate: moment(startDate._d).subtract(7, 'days')._d,
                        endDate: moment(endDate._d).subtract(7, 'days')._d,
                        key: 'selection',
                      },
                    ]),
                    dispatch(setSevenDaySelectedChartData([]))
                  )
                }}
              >
                <Chevron direction="left" />
              </Button>
              <FetchStateWrapper id={SevenDayActions.POST_SEVEN_DAY_CRUDE_BLEND_CHART}>
                <div className={`width-100`}>
                  {_.isEmpty(_.get(sevenDayCrudeBlendData.data.chart, 'series.AM')) ? (
                    <NoDataBlock />
                  ) : (
                    <>
                      <div className={`flex width-100 center`}>
                        <Header size="small" textAlign="left">
                          AM
                        </Header>

                        <AMSevenDayCrudeBlendChart
                          sevenDayCrudeBlendData={sevenDayCrudeBlendData}
                        />
                      </div>
                      <div className={`flex width-100 center`}>
                        <Header size="small" textAlign="left">
                          PM
                        </Header>
                        <PMSevenDayCrudeBlendChart
                          sevenDayCrudeBlendData={sevenDayCrudeBlendData}
                        />
                      </div>
                    </>
                  )}
                </div>
              </FetchStateWrapper>
              <Button
                className={``}
                onClick={() => {
                  const startDate = moment(_.get(...period, 'startDate'))
                  const endDate = moment(_.get(...period, 'endDate'))
                  return (
                    setPeriod([
                      {
                        startDate: moment(startDate._d).add(7, 'days')._d,
                        endDate: moment(endDate._d).add(7, 'days')._d,
                        key: 'selection',
                      },
                    ]),
                    dispatch(setSevenDaySelectedChartData([]))
                  )
                }}
              >
                <Chevron direction="right" />
              </Button>
            </div>
          </div>
        </Segment>
      </Grid.Row>
    </Grid>
  )
}

export default SevenDaysCrudeBlend
