export const SC = "AWS-CWP_groups_sc_" + process.env.REACT_APP_TOKEN_ENV_FOR_USER_GROUP;
export const TN = "AWS-CWP_groups_tn_" + process.env.REACT_APP_TOKEN_ENV_FOR_USER_GROUP;
export const ENTS = "AWS-CWP_groups_ents_"  + process.env.REACT_APP_TOKEN_ENV_FOR_USER_GROUP;

export const USER_PERSONA_LIST = [
    {
        title: 'TN',
        description: 'TN',
        value: TN,
    },
    {
        title: 'ENTS',
        description: 'ENTS',
        value: ENTS,
    },
    {
        title: 'SC',
        description: 'SC',
        value: SC,
    }
]
