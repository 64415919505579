import _ from 'lodash'
import { LAMBDA_API_INSTANCE } from './serverInstances/lambdaServer'
import * as UserGroup from '../constants/userGroups'

import * as propertyPageConstant from '../../src/containers/views/property-page/helper/constant'
const CONTROLLER_NAME = '/property'

export const postPropertyResultTable = ({
  mode,
  property,
  currentPersona,
  paramters,
  riskRating,
  source,
  propertyRange,
}) => {
  const postBodyDefault = {
    mode: mode,
    property: property,
    component: 'prop_search_results',
    persona: currentPersonaFormatted(currentPersona),
  }

  const postBodyFilter = {
    mode: mode,
    property: property,
    component: 'prop_search_results',
    persona: currentPersonaFormatted(currentPersona),
    riskParameters: paramters,
    riskRating: riskRating,
    riskSource: source,
    riskSearchDateRange: propertyRange,
  }

  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/results`,
    mode === propertyPageConstant.SEARCH_MODE.DEFAULT_MODE ? postBodyDefault : postBodyFilter
  )
}

const currentPersonaFormatted = (currentPersona) =>
  _.isEqual(currentPersona, UserGroup.SC)
    ? 'SC'
    : _.isEqual(currentPersona, UserGroup.ENTS)
    ? 'ENTS'
    : _.isEqual(currentPersona, UserGroup.TN)
    ? 'TN'
    : ''
