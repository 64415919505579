export default {
    "data": [
        {
            "Density": { min: '-', max: 0.837 },
            "Crude TAN": { min: 0.01, max: 0.5 },
            "Crude Sulfur": { min: 0.03, max: 1.5 },
            "Mercaptan (ppmw)": { min: 60, max: 140 },
            "Asphaltene (%wt)": { min: '-', max: 2 },
            "Basic Nitrogen (ppmw)": { min: 200, max:'-' }
        }
    ]
}