import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import ReactHighchart from '../../react-highcharts'
import PropTypes from 'prop-types'

import ReactDOMServer from 'react-dom/server'
import Tooltip from '../../tooltip'

// Helpers
import { stringToDecimalPoint } from '../../../helpers/variables'

const daysInMonth = []

var monthDate = moment().startOf('month') // change to a date in the month of interest

_.times(monthDate.daysInMonth(), function (n) {
  daysInMonth.push(monthDate.format('DD MMM')) // your format
  monthDate.add(1, 'day')
})

export const ParameterTrendChart = (props) => {
  const CHART_OPTIONS = setChartOptions(props.parameterTrendData.data, props.period)
  return (
    <div>
      <ReactHighchart options={CHART_OPTIONS} />
    </div>
  )
}

ParameterTrendChart.propTypes = {
  parameterTrendChartData: PropTypes.object,
}

function setChartOptions(data, period) {
  const parameterTrendCategoriesData = _.get(data, 'category')
  const parameterTrendChartSeriesData = _.get(data, 'series')

  const formatTrendCategories = _.map(parameterTrendCategoriesData, (item) =>
    moment(item).format('DD MMM')
  )
  return {
    chart: {
      height: 500,
    },
    credits: {
      enabled: false,
    },

    title: {
      text: '',
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    xAxis: {
      categories: parameterTrendCategoriesData,
    },
    tooltip: getTooltip(data, tooltipFormatter, period),
    // tooltip: {
    //   useHTML: true,
    //   headerFormat:
    //     '<div style="font-weight: 700; font-size: 16px; padding-bottom: 5px;">{point.key}</div><table>',
    //   pointFormat:
    //     '<tr><td><div style="background-color: {series.color}; padding: 6px; border-radius: 50%;"></div<</td><td style="padding-left: 5px; padding-right: 20px; font-size: 16px;">{series.name} </td>' +
    //     '<td style="text-align: right; font-size: 16px;"><b>{point.y}</b></td></tr>',
    //   footerFormat: '</table>',
    // },
    legend: {
      enabled: false,
    },

    plotOptions: {
      series: {
        pointStart: 0,
      },
    },

    series: [parameterTrendChartSeriesData],
  }
}

const getTooltip = (payload, formatter, period) => {
  return {
    shared: false,
    backgroundColor: null,
    borderWidth: 0,
    shadow: false,
    className: 'test-classname-tooltip',
    style: {
      padding: 0,
    },
    useHTML: true,
    formatter: function () {
      return formatter(this, payload, period)
    },
    outside: true,
  }
}

function getTooltipContent(category, data) {
  let content = [
    {
      name: _.get(data.series, 'name'),
      color: _.get(data.series, 'color'),
      data: _.get(data.series, 'data'),
    },
  ]
  return content
}

// tooltip in normal view
const tooltipFormatter = (chart, data, period) => {
  const x = chart
  const i = x.point.index
  const points = getTooltipContent(x, data)

  const options = {
    title: `${x.x}`,
    items: [
      _.map(points, (a) => ({
        type: 'card',
        label: a.name,
        value: _.round(a.data[i], 2),
        indicatorColor: a.color,
      })),
    ],
  }

  return ReactDOMServer.renderToString(
    <Tooltip
      medium
      // headerColor={data.series[i].color}
      type="TooltipTwoColumns"
      {...options}
      className={'parameter-trend-tooltip'}
    />
  )
}
