/*global process*/
//import axios from 'axios'
import swal from 'sweetalert'
// import _ from 'lodash'
// import './service_storage'
// import './service_feedgas_supply'
// import './service_plant_production'
// import './service_overview'
// import './service_cargo'
// import './service_alerts'
// import './service_help_support'
// import './service_master_data'
//import Qs from 'qs'

//Constants
//const API_URL = process.env.REACT_APP_API_URL
const SWAL_UNAUTHORIZED = {
  title: 'Unauthorized',
  text: 'Your session has expired. Please re-login.',
  icon: 'error',
  button: 'Go to Login Page'
}

//Microservice
// export const API_GATEWAY = axios.create({
//   baseURL: process.env.REACT_APP_API_GATEWAY_URL,
//   paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'repeat' })
// })

export const serviceUnauthorized = () => {
  // swal(SWAL_UNAUTHORIZED).then(value => {
  //   if (value) {
  //     localStorage.clear()
  //     window.location.href = '/'
  //   }
  // })
  localStorage.clear()
  window.location.href = '/'
}

// //Setup defaults
// export const setup = () => {
//   axios.defaults.baseURL = API_URL
//   axios.defaults.transformResponse = [
//     data => {
//       try {
//         data = JSON.parse(data)
//         if (_.get(data, 'status.code') !== 200) {
//           // console.error( `Error: ${_.get(data, 'data')}`)
//         }
//         // if ( _.includes([401,1010],_.get(data, 'status.code')) && _.includes(_.get(data, 'data'), 'token')) {
//         // 	swal(SWAL_UNAUTHORIZED).then((value)=> {
//         // 		if(value) {
//         // 			localStorage.clear()
//         // 			// window.location.href = '/'
//         // 		}
//         // 	})
//         // }
//       } catch (error) {
//         console.error(error)
//       }
//       return data
//     }
//   ]
//   axios.defaults.headers.common['Content-Type'] = 'application/json'
//   axios.defaults.headers.common['Cache-Control'] = 'no-store'
//   axios.defaults.headers.common['Pragma'] = 'no-cache'
// }
// setup()
