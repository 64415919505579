import React, { useState, useCallback, useEffect, useMemo } from 'react'
import _ from 'lodash'
import jwt from 'jsonwebtoken'
import moment from 'moment'
import { Grid, Header, Table, Input, Button, Dropdown, Icon, Modal } from 'semantic-ui-react'
import { useJsonToCsv } from 'react-json-csv'
import NoDataBlock from '../../../../../components/no-data-block'

//Helpers
import {
  rowSpanTableDataByProperties,
  removeRowSpanTableDataByProperties,
} from '../../../../../helpers/table'

//Redux
import { CORROSION_PAGE_REDUCER } from '../../../../../reducers/corrosionPageReducer'
import { MASTER_DATA_REDUCER } from '../../../../../reducers/common/masterDataReducer'
import { SESSION_REDUCER } from '../../../../../reducers/common/sessionReducer'
import * as CorrosionRatePageActions from '../../../../../actions/actionCorrosionRate'
import * as MasterDataActions from '../../../../../actions/common/actionMasterData'

//Hooks
import { useStateWithPaths, useActions } from '../../../../../hooks/useConnect'

//Constants
import * as corrosionPageConstants from '../../helpers/constants'
import * as UserGroup from '../../../../../constants/userGroups'

//#region Private

const TableSummary = ({ data, updateTableDataCallback, temperatureRangeData }) => {
  const [{ materialNames }] = useStateWithPaths([`${MASTER_DATA_REDUCER}`])
  const [{ searchPanel }] = useStateWithPaths([`${CORROSION_PAGE_REDUCER}`])
  const [{ idToken }] = useStateWithPaths([`${SESSION_REDUCER}`])

  const idTokenDetails = jwt.decode(idToken)
  let tokenGroups = _.get(idTokenDetails, 'name') || ''
  tokenGroups = tokenGroups.replace('[', '').replace(']', '').split(', ')

  const ENTS_DEV = _.intersection(tokenGroups, ['AWS-CWP_groups_ents_dev'])
  const ENTS_PROD = _.intersection(tokenGroups, ['AWS-CWP_groups_ents_prod'])

  const disabledPersona = _.isEmpty(ENTS_DEV) || _.isEmpty(ENTS_PROD) ? 'disabled-button' : ''

  const { simulateSummaryTable, saveSummaryTable } = useActions({
    simulateSummaryTable: CorrosionRatePageActions.simulateSummaryTable,
    saveSummaryTable: CorrosionRatePageActions.saveSummaryTable,
  })

  const [isOpenSaveResultModal, setIsOpenSaveResultModal] = useState(false)

  const updateTableDataByValue = useCallback(
    (index, propertyName, value) => {
      let newData = [...data]
      let newRowData = { ...newData[index] }
      newRowData[propertyName].value = value

      //update strengthOfNh4Hs value
      newRowData['estimatedCorrosionRate'].value = '-'
      newRowData['estimatedCorrosionRate'].isHighlighted = false

      newData[index] = newRowData
      updateTableDataCallback(newData)
    },
    [data, updateTableDataCallback]
  )

  const isDisableButton = (isResultFieldMustHaveValue) => {
    let hasChangeOnEstimatedRate = false
    let isEmptyFields = false
    _.forEach(data, (item) => {
      if (
        _.isEmpty(_.get(item, 'materialAtRiskLocations.value')) ||
        _.isNull(item.maxOperatingTemp.value) ||
        item.maxOperatingTemp.value === '' ||
        item.maxOperatingTemp.value < 200 ||
        item.maxOperatingTemp.value > 500 ||
        !item.maxOperatingTemp.value ||
        !item.maxAllowableRate.value
      ) {
        isEmptyFields = true
        return false
      }

      if (!hasChangeOnEstimatedRate) {
        hasChangeOnEstimatedRate = _.get(item, 'estimatedCorrosionRate.value') === '-'
      }
    })

    let isDisable = false

    if (isResultFieldMustHaveValue) {
      isDisable = isEmptyFields || hasChangeOnEstimatedRate
    } else {
      isDisable = isEmptyFields || !hasChangeOnEstimatedRate
    }

    return isDisable
  }

  const onClickSimulateResult = (summaryTableData) => {
    const RemovedRowSpanData = removeRowSpanTableDataByProperties(
      _.cloneDeep(summaryTableData),
      'processingUnit'
    )
    simulateSummaryTable({
      saveType: corrosionPageConstants.SEARCH_HIGHTEMP_NHNS.HIGH_TEMP,
      isSave: false,
      summaryTable: RemovedRowSpanData,
      crudeType: _.get(searchPanel, 'crudeMode'),
    })
  }

  const onClickSaveResult = (summaryTableData) => {
    const RemovedRowSpanData = removeRowSpanTableDataByProperties(
      _.cloneDeep(summaryTableData),
      'processingUnit'
    )
    saveSummaryTable(
      {
        saveType: corrosionPageConstants.SEARCH_HIGHTEMP_NHNS.HIGH_TEMP,
        isSave: true,
        summaryTable: RemovedRowSpanData,
        crudeType: _.get(searchPanel, 'crudeMode'),
      },
      () => {
        setIsOpenSaveResultModal(true)
      }
    )
  }

  let renderFirstColumn = false
  return (
    <>
      <Table celled structured textAlign="center" className="table-summary">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Processing Unit</Table.HeaderCell>
            <Table.HeaderCell>Fraction</Table.HeaderCell>
            <Table.HeaderCell>Sulfer (%wt)</Table.HeaderCell>
            <Table.HeaderCell>TAN (mg KOH/g oil)</Table.HeaderCell>
            <Table.HeaderCell>Material at Risk Locations</Table.HeaderCell>
            <Table.HeaderCell>Max. Operating Temp (C)</Table.HeaderCell>
            <Table.HeaderCell>Max. Allowable Rate (mm/y)</Table.HeaderCell>
            <Table.HeaderCell>Estimated Corrosion Rate (mm/y)</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data.map((item, index) => {
            const checkMinMaxTemp =
              _.isNull(item.maxOperatingTemp.value) ||
              item.maxOperatingTemp.value === '' ||
              item.maxOperatingTemp.value < 200 ||
              item.maxOperatingTemp.value > 500

            renderFirstColumn =
              index < 1 || item.processingUnit.value !== data[index - 1].processingUnit.value

            return (
              <Table.Row key={index}>
                {renderFirstColumn && (
                  <Table.Cell rowSpan={item.processingUnit.rowSpan} textAlign="center">
                    {item.processingUnit.value}
                  </Table.Cell>
                )}
                <Table.Cell>{item.fraction.value}</Table.Cell>
                <Table.Cell>{_.isNull(item.sulfer.value) ? 'N.A' : item.sulfer.value}</Table.Cell>
                <Table.Cell>{_.isNull(item.tan.value) ? 'N.A' : item.tan.value}</Table.Cell>
                <Table.Cell>
                  <Dropdown
                    placeholder="Select Location*"
                    className={`ddl ddl-secondary full-width`}
                    value={item.materialAtRiskLocations.value}
                    selection
                    error={_.isEmpty(item.materialAtRiskLocations.value)}
                    options={materialNames}
                    onChange={(e, ddlItem) =>
                      updateTableDataByValue(index, 'materialAtRiskLocations', ddlItem.value)
                    }
                  />
                </Table.Cell>
                <Table.Cell>
                  <Input
                    type="number"
                    className="grey-border input-decimal"
                    error={checkMinMaxTemp}
                    min="200"
                    max="500"
                    onChange={({ target = {} }) =>
                      updateTableDataByValue(index, 'maxOperatingTemp', target.value)
                    }
                    value={item.maxOperatingTemp.value}
                    placeholder="--*"
                  />
                </Table.Cell>
                <Table.Cell>
                  <Input
                    type="number"
                    className="grey-border input-decimal"
                    error={!item.maxAllowableRate.value}
                    onChange={({ target = {} }) =>
                      updateTableDataByValue(index, 'maxAllowableRate', target.value)
                    }
                    value={item.maxAllowableRate.value}
                    placeholder="--*"
                  />
                </Table.Cell>
                <Table.Cell>
                  <div
                    className={`estimated-corrosion-rate ${
                      item.estimatedCorrosionRate.isHighlighted ? 'label-highlighted' : ''
                    }`}
                  >
                    {_.isNull(item.estimatedCorrosionRate.value)
                      ? 'N.A'
                      : item.estimatedCorrosionRate.value}
                  </div>
                </Table.Cell>
              </Table.Row>
            )
          })}

          {/* Buttons in table */}
          <Table.Row>
            <Table.Cell colSpan={7} textAlign="right">
              <Button
                className="btn-primary"
                disabled={isDisableButton()}
                onClick={() => onClickSimulateResult(data)}
              >
                Simulate Result
              </Button>
              <Button
                className={`btn-secondary icon ${disabledPersona}`}
                disabled={isDisableButton(true)}
                onClick={() => onClickSaveResult(data)}
              >
                Save All <Icon name="save outline"></Icon>
              </Button>
            </Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <SaveSummaryResultModal open={isOpenSaveResultModal} setOpen={setIsOpenSaveResultModal} />
    </>
  )
}

const SaveSummaryResultModal = ({ open, setOpen }) => {
  return (
    <Modal
      centered={false}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      size={'small'}
    >
      <Modal.Header>Result</Modal.Header>
      <Modal.Content>
        <Modal.Description>Saved successfully.</Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button className="btn-primary" onClick={() => setOpen(false)}>
          OK
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

//#endregion

const HighTempCrudeSummaryTable = () => {
  const [{ searchResult, searchPanel }] = useStateWithPaths([`${CORROSION_PAGE_REDUCER}`])
  const { isSearchByCrudeBlendDate } = searchPanel
  const { saveAsCsv } = useJsonToCsv()

  const { getMasterMaterialNames } = useActions({
    getMasterMaterialNames: MasterDataActions.getMasterMaterialNames,
  })

  useEffect(() => {
    getMasterMaterialNames(true)
  }, [getMasterMaterialNames])

  const tableData = _.get(searchResult, 'summaryTable.data')
  const tableDataForExport = useMemo(() => {
    return _.map(tableData, (item, index) => {
      return _.mapValues(item, (value, key) => {
        return _.get(value, 'value')
      })
    })
  }, [tableData])

  const clickExport = useCallback(
    (e) => {
      const filename = 'Summary Table',
        fields = {
          processingUnit: 'Processing Unit',
          fraction: 'Fraction',
          sulfer: 'Sulfer (%wt)',
          tan: 'TAN (mg KOH/g oil)',
          materialAtRiskLocations: 'Material at Risk Locations',
          maxOperatingTemp: 'Max. Operating Temp (C)',
          maxAllowableRate: 'Max. Allowable Rate (mm/y)',
          estimatedCorrosionRate: 'Estimated Corrosion Rate',
        },
        style = {
          padding: '5px',
        },
        data = tableDataForExport
      saveAsCsv({ data, fields, filename, style })
    },
    [tableDataForExport, saveAsCsv]
  )

  const [editableTableData, setEditableTableData] = useState(tableData)

  useEffect(() => {
    setEditableTableData(tableData)
  }, [tableData])

  if (!editableTableData || _.get(editableTableData, 'length') < 1) {
    return <NoDataBlock />
  }

  const formattedTableData = rowSpanTableDataByProperties(
    editableTableData,
    'processingUnit',
    'processingUnit.value'
  )

  return (
    <div className="section section-summary-table">
      <Grid padded>
        <Grid.Row>
          <Grid.Column textAlign="left">
            <div className="flex center space-between">
              <div>
                <Header size="small" textAlign="left">
                  SUMMARY TABLE
                </Header>
              </div>
              <div>
                <Button className="btn-secondary icon" onClick={clickExport}>
                  Export <Icon name="external alternate"></Icon>
                </Button>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        {!_.isEmpty(tableData) && !_.isEmpty(tableData[0].date) && !isSearchByCrudeBlendDate && (
          <Grid.Row>
            <div className={`flex pad-2 mar-x-0_5 mar-bottom-1 blend-container width-100`}>
              <div className={`mar-right-0_5`}>Blend date for the selected criteria is</div>
              <div className={`blend-date-value`}>
                {!_.isEmpty(tableData) && !_.isEmpty(tableData[0].date)
                  ? _.upperCase(moment(tableData[0].date).format('DD MMM YYYY'))
                  : 'N.A.'}
              </div>
            </div>
          </Grid.Row>
        )}

        <Grid.Row columns={1}>
          <Grid.Column>
            <div>
              <TableSummary
                data={formattedTableData}
                updateTableDataCallback={setEditableTableData}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default HighTempCrudeSummaryTable
