import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";

// Components
import { CrudeArrivalChart } from "../../../components/charts/crude-arrival-chart/crude-arrival-chart";
import { CrudeArrivalChartDual } from "../../../components/charts/crude-arrival-chart-dual/crude-arrival-chart-dual";
import { Grid, Header, Segment, Dropdown } from "semantic-ui-react";
import PeriodPicker from "../../../components/period-picker";

// Hooks
import { useStateWithPaths } from "../../../hooks/useConnect";

// Action // NEED TO CHANGE ACTION POINT
import { setCrudeArrivalChart } from "../../../actions/actionLanding";

const CrudeArrivals = () => {
  const dispatch = useDispatch();
  const [crudeArrivalChartData] = useStateWithPaths([
    "landingPage.crudeArrivalChart.data",
  ]);
  const [periods] = useStateWithPaths(["landingPage.crudeArrivalChart.period"]);
  const [period, setPeriod] = useState([periods]);

  const [materialType, setMaterialType] = useState('All Material');

  const data = crudeArrivalChartData;

  useEffect(() => {
    dispatch(setCrudeArrivalChart(period, materialType));
  }, [period, materialType]);

  return (
    <Grid padded>
      <Grid.Row columns={2} verticalAlign="bottom">
        <Grid.Column textAlign="left">
          <Header size="small" textAlign="left">
            CRUDE ARRIVALS
          </Header>
        </Grid.Column>
        <Grid.Column>
          <div className={`flex end`}>
            <Dropdown
              placeholder="Select unit"
              className={`material-type-dropdown-container`}
              value={materialType}
              defaultValue={`All Material`}
              selection
              options={[{text:'All Material', value:'All Material'},{text:'LR', value:'LR'},{text:'Crude', value:'Crude'}]}
              onChange={(e, data) => {
                setMaterialType(data.value)
                setCrudeArrivalChart(period, data.value)
              }}
            />

            <PeriodPicker
              period={period}
              setPeriod={setPeriod}
              type="month"
              onClick={setCrudeArrivalChart(period, materialType)}
              maxYear={true}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row column={2}>
        <Segment className={`width-100`}>
          <div className={`pad-x-1 pad-y-1`}>
            {materialType === 'All Material' ? (
              <CrudeArrivalChartDual data={data} />
            ) : (
              <CrudeArrivalChart data={data} materialType={materialType}/>
            )}
          </div>
        </Segment>
      </Grid.Row>
    </Grid>
  );
};

export default CrudeArrivals;
