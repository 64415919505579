import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import WebFont from 'webfontloader';

//library css
import 'semantic-ui-css/semantic.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file

//application css
import './index.scss';

import App from './app';
import * as serviceWorker from './serviceWorker';
import { ReduxProvider } from './helpers/redux';

WebFont.load({
  google: {
    families: ['IBM Plex Sans']
  }
});

ReactDOM.render(
  <ReduxProvider>
    <Router>
      <App />
    </Router>
  </ReduxProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();