import _ from 'lodash'
import { FETCH_START, FETCH_SUCCESS, FETCH_ERROR, FETCH_RESET } from '../../actions/common/actionFetch'

const defaultState = {}

export const isFetching = ({ state = defaultState, id }) => {
  return state[id] === FETCH_START
}
export const isSuccess = ({ state = defaultState, id }) => {
  return state[id] === FETCH_SUCCESS
}
export const isError = ({ state = defaultState, id }) => {
  return state[id] === FETCH_ERROR
}

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case FETCH_START:
    case FETCH_SUCCESS:
    case FETCH_ERROR:
      return {
        ...state,
        [payload]: type
      }
    case FETCH_RESET:
      return _.has(state, payload)
        ? {
            ...state,
            [payload]: undefined
          }
        : state
  }

  return state
}

export const FETCH_REDUCER = 'commonFetch'