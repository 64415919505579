import _ from 'lodash'
import { startOfMonth, endOfMonth } from 'date-fns'

import * as statisticalAnalysisPageActions from '../actions/actionStatisticalAnalysis'
import * as statisticalAnalysisConstants from '../containers/views/statistical-analysis-page/helpers/constants'

const defaultState = {
  searchPanel: {
    options: statisticalAnalysisConstants.SEARCH_OPTIONS.FindSimiliarCrude,
    crudeNameAbbreviation: null,
    crudeNameA: null,
    crudeNameB: null,
    crudeNameC: null,
    isSearchByCrudeBlendDate: true,
    blendDateA: null,
    blendDateB: null,
    unitBlendA: '',
    unitBlendB: '',
    inputUnits: {
      unit1: null,
      unitPercent1: '',
      unit2: null,
      unitPercent2: '',
      unit3: null,
      unitPercent3: '',
      unit4: null,
      unitPercent4: '',
      unit5: null,
      unitPercent5: '',
      unit6: null,
      unitPercent6: '',
      unit7: null,
      unitPercent7: '',
      unit8: null,
      unitPercent8: '',
      unit9: null,
      unitPercent9: '',
      unit10: null,
      unitPercent10: '',
      unit11: null,
      unitPercent11: '',
      unit12: null,
      unitPercent12: '',
      unit13: null,
      unitPercent13: '',
      unit14: null,
      unitPercent14: '',
      unit15: null,
      unitPercent15: '',
      unit16: null,
      unitPercent16: '',
      unit17: null,
      unitPercent17: '',
      unit18: null,
      unitPercent18: '',
      unit19: null,
      unitPercent19: '',
      unit20: null,
      unitPercent20: '',
    },
    property: '',
    input: '',
    operatingUnit: '',
    fraction: '',
    settingPageResult: [],
  },
  searchSimiliarCrude: {
    dataSource: 'Spiral Data',
  },
  searchCompareCrude: {
    dataSource: 'Spiral Data',
    compareUnit: '',
    compareProperty1: '',
    compareProperty2: '',
    compareProperty3: '',
    compareProperty4: '',
    compareProperty5: '',
    cutProperty1: '',
    cutProperty2: '',
    cutProperty3: '',
    cutProperty4: '',
    cutProperty5: '',
    fraction1: '',
    fraction2: '',
    fraction3: '',
    fraction4: '',
    fraction5: '',
    cutUnit1: '',
    cutUnit2: '',
    cutUnit3: '',
    cutUnit4: '',
    cutUnit5: '',
  },
  searchCompareBlend: {
    defaultPeriod: {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
    },
    period: {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: 'selection',
    },
    dataSource: 'APS Data',
    unit: '',
    property1: '',
    property2: '',
    property3: '',
    property4: '',
    property5: '',
    freeformData: [],
    freeformDataSource1: '',
    freeformDataSource2: '',
    freeformDataSource3: '',
    freeformDataSource4: '',
    freeformDataSource5: '',
    freeformUnit1: '',
    freeformUnit2: '',
    freeformUnit3: '',
    freeformUnit4: '',
    freeformUnit5: '',
    freeformStream1: '',
    freeformStream2: '',
    freeformStream3: '',
    freeformStream4: '',
    freeformStream5: '',
    freeformProperty1: '',
    freeformProperty2: '',
    freeformProperty3: '',
    freeformProperty4: '',
    freeformProperty5: '',
  },
  // settingPageResult: [],
  searchResult: {},
}

const REDUCERS = {
  // [SET_IS_CARGO_LOCK_IN_ERRORS]: (state, { payload }) => {
  //   return {
  //     ...state,
  //     isCargoLockInErrors: payload
  //   }
  // }
  [statisticalAnalysisPageActions.STATISTICAL_ANALYSIS_SEARCH_UPDATE]: (state, { payload }) => {
    return {
      ...state,
      searchPanel: {
        ...state.searchPanel,
        ...payload,
      },
      searchResult: {},
    }
  },

  [statisticalAnalysisPageActions.STATISTICAL_ANALYSIS_SIMILIAR_CRUDE]: (state, { payload }) => {
    return {
      ...state,
      searchResult: {
        ...state.searchResult,
        searchSimiliarCrude: {
          ...state.searchResult.searchSimiliarCrude,
          data: payload,
        },
      },
    }
  },

  [statisticalAnalysisPageActions.STATISTICAL_ANALYSIS_COMPARE_CRUDE]: (state, { payload }) => {
    return {
      ...state,
      searchResult: {
        ...state.searchResult,
        searchCompareCrude: {
          ...state.searchResult.searchCompareCrude,
          data: payload,
        },
      },
    }
  },

  [statisticalAnalysisPageActions.STATISTICAL_ANALYSIS_COMPARE_BLEND]: (state, { payload }) => {
    return {
      ...state,
      searchResult: {
        ...state.searchResult,
        searchCompareBlend: {
          ...state.searchResult.searchCompareBlend,
          data: payload,
        },
      },
    }
  },

  [statisticalAnalysisPageActions.STATISTICAL_ANALYSIS_TREND_CHART]: (state, { payload }) => {
    return {
      ...state,
      searchCompareBlend: {
        ...state.searchCompareBlend,
        freeformData: payload,
      },
    }
  },

  [statisticalAnalysisPageActions.STATISTICAL_ANALYSIS_GET_SETTING_PAGE]: (state, { payload }) => {
    return {
      ...state,
      searchPanel: {
        ...state.searchPanel,
        settingPageResult: payload,
      },
    }
  },

  // [statisticalAnalysisPageActions.CORROSION_CALCULATE_RATE]: (state, { payload }) => {
  //   return {
  //     ...state,
  //     searchResult: {
  //       ...state.searchResult,
  //       apiStandard: {
  //         ...state.searchResult.apiStandard,
  //         data: _.get(payload, 'table'),
  //       },
  //       calculationResult: {
  //         ...state.searchResult.calculationResult,
  //         ..._.get(payload, 'calculationResult'),
  //       },
  //     },
  //   }
  // },
}
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}
export const STATISTICAL_ANALYSIS_PAGE_REDUCER = 'statisticalAnalaysisPage'
