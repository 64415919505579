import React, { useState } from 'react'
import moment from 'moment'
import _ from 'lodash'
import {
  Table,
  Image,
  Popup,
  Button,
  Modal,
  TextArea,
  Header,
  Segment,
  Grid,
} from 'semantic-ui-react'
import { useDispatch } from 'react-redux'

// Component
import RadioButton from '../../../../components/radio-button'
import NoDataBlock from '../../../../components/no-data-block'
import SortTableData from '../../../../components/sort-table-data/sortTableData'

// Asset
import IconCopy from '../../../../assets/images/icon-copy.svg'
import IconRating from '../../../../assets/images/icon-rating.svg'
import IconClose from '../../../../assets/images/icon-close.svg'
import IconMitigationGrey from '../../../../assets/images/icon-mitigation-grey.svg'
import IconMitigationWhite from '../../../../assets/images/icon-mitigation-white.svg'
import IconMitigationNotification from '../../../../assets/images/icon-mitigation-notification.svg'

// Action
import { saveFeedback } from '../../../../actions/actionRisks'

// Redux
import { PERSONAL_SETTING_REDUCER } from '../../../../reducers/common/personalSettingReducer'
import { CORROSION_PAGE_REDUCER } from '../../../../reducers/corrosionPageReducer'

//Hooks
import { useStateWithPaths } from '../../../../hooks/useConnect'

//Constants
import * as corrosionPageConstants from '../helpers/constants'

const renderFeedbackRating = (feedbackRatingValue, setCallback) => {
  const menu = [
    {
      label: 'Low',
      value: 'Low',
    },
    {
      label: 'High',
      value: 'High',
    },
  ]
  return (
    <RadioButton
      menuData={menu}
      className="color-black"
      selectCallback={setCallback}
      selectedValue={feedbackRatingValue}
    />
  )
}
const HistoricalData = () => {
  const [{ searchPanel, searchResult }] = useStateWithPaths([`${CORROSION_PAGE_REDUCER}`])
  const { calculateResultMode } = searchPanel

  if (calculateResultMode === corrosionPageConstants.SEARCH_CALCULATE_RESULT_MODE.CREATE_SUMMARY)
    return null

  return <HistoricalDataContainer />
}

const HistoricalDataContainer = () => {
  const dispatch = useDispatch()
  const [{ currentPersona }] = useStateWithPaths([`${PERSONAL_SETTING_REDUCER}`])
  const [{ searchResult }] = useStateWithPaths([`${CORROSION_PAGE_REDUCER}`])

  const data = _.get(searchResult, 'historicalData.data')
  const [open, setOpen] = useState(false)
  const [modalContent, setModalContent] = useState('')
  const [isSubmitFeedback, setIsSubmitFeedback] = useState(false)
  const [feedbackRating, setFeedbackRating] = useState('Low')
  const [feedbackComment, setFeedbackComment] = useState('')

  const NewlineText = (props) => {
    const text = props.text
    const newText = text.split('\n').map((str) => (<p class={`${_.includes(str, '<b>') ? 'bold' : ''}`}>{_.split(str,'<b>')}</p>))

    return newText
  }

  // const NewlineText = (props) => {
  //   const text = props.text
  //   const newText = text.split('\n').map((str) => <p>{str}</p>)

  //   return newText
  // }

  const GetModalContent = (item) => {
    setOpen(true)
    setModalContent(
      <div className={`flex`}>
        <div className="left-content">
          <div className={`pad-1`}>
            <div className={`mitigation-header`}>Parameter and Property</div>
            <div className={`mitigation-detail`}>{item.riskParameter}</div>
          </div>
          <div className={`pad-1`}>
            <div className={`mitigation-header`}>Corrosion Rating</div>
            <div
              className={`mitigation-detail`}
              style={{ fontWeight: item.riskRating === 'High' ? '900' : '' }}
            >
              {(item.riskRating === 'High' ||
                item.riskRating === 'Medium' ||
                item.riskRating === 'Low') && (
                <div className={`rate-point ${item.riskRating}`}></div>
              )}
              {item.riskRating}
            </div>
          </div>
          <div className={`pad-1`}>
            <div className={`mitigation-header`}>Source</div>
            <div className={`mitigation-detail`}>{item.riskSource}</div>
          </div>
        </div>
        <div className="right-content">
          <div className={`pad-1`}>
            <div className={`mitigation-header`}>Corrosion Description</div>
            {!_.isEmpty(item.riskDescription) ? (
              <>
                <div className={`flex`}>
                  <div style={{ width: '93%' }}>
                    <NewlineText text={item.riskDescription} className={`mitigation-detail`} />
                  </div>
                  <div>
                    <Popup
                      className={`pad-2`}
                      content="Copied Successfully"
                      on="click"
                      trigger={
                        <Image
                          src={IconCopy}
                          className={`icon-hover`}
                          onClick={() => {
                            navigator.clipboard.writeText(item.riskDescription)
                          }}
                        />
                      }
                    />
                  </div>
                </div>
                <div className="date">{moment(item.riskDate).format('YYYY-MM-DD').toString()}</div>
              </>
            ) : (
              <div className={`loader-message`}>Corrosion Description is not available</div>
            )}
          </div>
          <div className={`pad-1`}>
            <div className={`mitigation-header`}>Mitigation</div>
            {!_.isEmpty(item.riskMitigationDesc) ? (
              <>
                <div className={`flex`}>
                  <div style={{ width: '93%' }}>
                    <NewlineText text={item.riskMitigationDesc} className={`mitigation-detail`} />
                  </div>
                  <div>
                    <Popup
                      className={`pad-2`}
                      content="Copied Successfully"
                      on="click"
                      trigger={
                        <Image
                          src={IconCopy}
                          className={`icon-hover`}
                          onClick={() => {
                            navigator.clipboard.writeText(item.riskMitigationDesc)
                          }}
                        />
                      }
                    />
                  </div>
                </div>
                <div className="date">
                  {moment(item.riskMitigationDate).format('YYYY-MM-DD').toString()}
                </div>
              </>
            ) : (
              <div className={`loader-message`}>Mitigation is not available</div>
            )}
          </div>
        </div>
      </div>
    )
  }

  const onClickSubmitFeedback = (item) => {
    dispatch(
      saveFeedback({
        persona: currentPersona,
        referenceId: item.reference_id,
        component: 'risk-search-results',
        feedback: [
          {
            riskDescription: item.riskDescription,
            riskRating: item.riskRating,
            riskParameter: item.riskParameter,
            riskDate: item.riskDate,
            riskSource: item.riskSource,
            riskSourceUrl: item.riskSourceUrl,
            riskMitigationDate: item.riskMitigationDate,
            riskMitigationDesc: item.riskMitigationDesc,
            feedbackRating: feedbackRating,
            feedbackComment: feedbackComment,
          },
        ],
      }),
      [saveFeedback, currentPersona, item, feedbackRating, feedbackComment]
    )
    setIsSubmitFeedback(true)
  }

  const onChangeFeedbackRating = (feedbackRatingValue) => {
    setFeedbackRating(feedbackRatingValue)
  }

  const [sortConfig, setSortConfig] = useState(null)
  const { items, requestSort, returnSortConfig } = SortTableData(
    !_.isEmpty(data) ? data : [],
    sortConfig,
    setSortConfig
  )
  const getClassNamesFor = (name) => {
    if (!returnSortConfig) {
      return
    }
    return returnSortConfig.key === name ? returnSortConfig.direction : undefined
  }

  return (
    <>
      <div className={`historical-data`}>
        <Segment className={`segment-container`}>
          {!_.isEmpty(data) ? (
            <div className="result-table-wrapper pad-1">
              <Grid padded>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <Table
                      sortable
                      celled
                      structured
                      textAlign="left"
                      className="table-risks border-less"
                    >
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell
                            width={3}
                            sorted={
                              !_.isEmpty(sortConfig) && sortConfig.key === 'riskParameter'
                                ? sortConfig.direction
                                : null
                            }
                            onClick={() => requestSort('riskParameter')}
                            className={getClassNamesFor('riskParameter')}
                          >
                            Parameter and Property
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            width={2}
                            sorted={
                              !_.isEmpty(sortConfig) && sortConfig.key === 'riskRating'
                                ? sortConfig.direction
                                : null
                            }
                            onClick={() => requestSort('riskRating')}
                            className={getClassNamesFor('riskRating')}
                          >
                            Corrosion Rating
                          </Table.HeaderCell>
                          <Table.HeaderCell>Corrosion Description</Table.HeaderCell>
                          <Table.HeaderCell width={2}>Source</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>

                      <Table.Body>
                        {items.map((item, index) => {
                          return (
                            <Table.Row key={index}>
                              <Table.Cell>{item.riskParameter}</Table.Cell>
                              <Table.Cell
                                style={{ fontWeight: item.riskRating === 'High' ? '900' : '' }}
                              >
                                {(item.riskRating === 'High' ||
                                  item.riskRating === 'Medium' ||
                                  item.riskRating === 'Low') && (
                                  <div className={`rate-point ${item.riskRating}`}></div>
                                )}
                                {item.riskRating}
                              </Table.Cell>
                              <Table.Cell style={{ lineHeight: '1.5' }}>
                                <NewlineText text={item.riskDescription} />
                                <div className="risk-date pad-y-1">
                                  {moment(item.riskDate).format('YYYY-MM-DD').toString()}
                                </div>
                                <div className={`flex`}>
                                  <Popup
                                    className={`pad-2`}
                                    content="Copied Successfully"
                                    on="click"
                                    trigger={
                                      <Image
                                        src={IconCopy}
                                        alt="copy"
                                        className={`icon-hover`}
                                        onClick={() => {
                                          navigator.clipboard.writeText(item.riskDescription)
                                        }}
                                      />
                                    }
                                  />
                                  <Popup
                                    className={`rating-container`}
                                    style={{ minWidth: '400px', height: '220px' }}
                                    trigger={
                                      <Image
                                        src={IconRating}
                                        alt="copy"
                                        className={`icon-hover`}
                                        onClick={() => setIsSubmitFeedback(false)}
                                      />
                                    }
                                    content={
                                      <div>
                                        <div className={`pad-1`} style={{ textAlign: 'left' }}>
                                          <div className={`pad-bottom-1 bold`}>
                                            <Header>Criticality</Header>
                                            {renderFeedbackRating(
                                              feedbackRating,
                                              onChangeFeedbackRating
                                            )}
                                          </div>
                                          <div className={`pad-bottom-1 pad-right-1`}>
                                            <TextArea
                                              className={`width-100`}
                                              style={{
                                                border: '1.5px solid #e5e5e5',
                                                borderRadius: '5px',
                                              }}
                                              rows={4}
                                              placeholder="Please give feedback"
                                              defaultValue={item.feedbackComment}
                                              onChange={(e) => setFeedbackComment(e.target.value)}
                                            />
                                          </div>
                                          <div className={`flex center`}>
                                            <div
                                              className={`width-100`}
                                              style={{ color: '#00b45a', textAlign: 'left' }}
                                            >
                                              {isSubmitFeedback
                                                ? 'Feedback submit Successfully'
                                                : ''}
                                            </div>
                                            <div>
                                              <Button
                                                className={`btn-primary`}
                                                onClick={() => onClickSubmitFeedback(item)}
                                              >
                                                Submit
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    }
                                    on="click"
                                    position="top center"
                                  />
                                  <Image
                                    src={
                                      !_.isEmpty(item.riskMitigationDesc)
                                        ? IconMitigationNotification
                                        : IconMitigationGrey
                                    }
                                    alt="copy"
                                    className={`${
                                      _.isEmpty(item.riskMitigationDesc) ? 'icon-hover' : ''
                                    }`}
                                    onClick={() => GetModalContent(item)}
                                  />
                                </div>
                              </Table.Cell>
                              <Table.Cell>
                                <div>{item.riskSource}</div>
                                {item.riskSourceUrl && (
                                  <div className="column-details">
                                    <a href={item.riskSourceUrl} target="_blank">
                                      Details
                                    </a>
                                  </div>
                                )}
                              </Table.Cell>
                            </Table.Row>
                          )
                        })}
                        <Modal
                          className={`modal-table`}
                          onClose={() => setOpen(false)}
                          onOpen={() => setOpen(true)}
                          open={open}
                        >
                          <Modal.Header>
                            <div className={`flex`}>
                              <div className={`left-header flex center`}>
                                <div className={`header-icon`}>
                                  <Image src={IconMitigationWhite} style={{ padding: '5px 8px' }} />
                                </div>
                                <Header size="medium" style={{ margin: 'auto 0' }}>
                                  Mitigation
                                </Header>
                              </div>
                              <div className={`right-header`}>
                                <div className={`flex end`}>
                                  <Button
                                    onClick={() => setOpen(false)}
                                    style={{ backgroundColor: 'transparent' }}
                                  >
                                    <Image src={IconClose} />
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Modal.Header>
                          <Modal.Content>{modalContent}</Modal.Content>
                        </Modal>
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          ) : (
            <NoDataBlock />
          )}
        </Segment>
      </div>
    </>
  )
}

export default HistoricalData
