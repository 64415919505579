import React, { useState, useCallback, useEffect } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import ImageProperty from '../../../assets/images/property-bg-image.svg'
import moment from 'moment'
import { Calendar } from 'react-date-range'
import { endOfMonth } from 'date-fns'

// Components
import { Grid, Icon, Button, Segment, Header, Checkbox, Popup, Input } from 'semantic-ui-react'
import ResultTable from './components/resultTable'
import SearchInput from '../../../components/search/search-input/searchInput'
import ResultContainer from '../../../components/result-container'
import FetchStateWrapper from '../../../components/fetch-state-wrapper'
import CheckboxList from '../../../components/checkbox-list'
import NoDataBlock from '../../../components/no-data-block'

// Hooks
import { useStateWithPaths } from '../../../hooks/useConnect'
import { PROPERTY_PAGE_REDUCER } from '../../../reducers/propertyPageReducer'
import { PERSONAL_SETTING_REDUCER } from '../../../reducers/common/personalSettingReducer'
import { getPropertyResultTable } from '../../../actions/actionProperty'

// Action
import { MASTER_DATA_REDUCER } from '../../../reducers/common/masterDataReducer'
import {
  getMasterPropertyPageSearchInput,
  getFilterRiskRatings,
  getFilterSource,
  getFilterParameters,
} from '../../../actions/common/actionMasterData'
import * as PropertyPageActions from '../../../actions/actionProperty'

// Constant
import * as propertyPageConstant from './helper/constant'

// Assets & Style
import background from '../../../assets/images/search_banner.svg'

//#region Private Methods
const getSearchIcon = () => {
  return (
    <div className="search-icon-wrapper flex center justify-center">
      <Icon name="search margin-none"></Icon>
    </div>
  )
}
////#endregion

const searchBarStyle = {
  height: '120px',
  padding: '40px',
  paddingBottom: '0px',
  textAlign: 'left',
  backgroundImage: `url(${background})`,
  backgroundSize: '100%',
  backgroundRepeat: 'no-repeat',
  borderRadius: '10px',
}

const searchOutputStyle = {
  height: '100px',
  padding: '35px 300px',
  textAlign: 'left',
  fontSize: '30px',
  fontWeight: 'bold',
  backgroundImage: `url(${ImageProperty})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  borderRadius: '10px',
  backgroundColor: '#e3f2fb',
}

const PropertyPage = (props) => {
  const dispatch = useDispatch()
  const [{ searchResult }] = useStateWithPaths([`${PROPERTY_PAGE_REDUCER}`])
  const [
    { propertiesPage, filterRiskRatings, filterSource, filterParameters },
  ] = useStateWithPaths([`${MASTER_DATA_REDUCER}`])
  const [{ currentPersona }] = useStateWithPaths([`${PERSONAL_SETTING_REDUCER}`])
  const [properties, setProperties] = useState('')
  const [selectedPropertyName, setSelectedPropertyName] = useState('')
  const [errorInputs, setErrorInputs] = useState({})

  useEffect(() => {
    dispatch(getMasterPropertyPageSearchInput())
    dispatch(getFilterRiskRatings())
    dispatch(getFilterSource())
    dispatch(getFilterParameters())
  }, [])

  const [filterSelectedRiskRating, setFilterSelectedRiskRating] = useState([])
  const [isFilterAllSelectedRiskRating, setIsFilterAllSelectedRiskRating] = useState(true)
  const [checkboxListRiskRating, setCheckboxListRiskRating] = useState([])

  const [filterSelectedSource, setFilterSelectedSource] = useState([])
  const [isFilterAllSelectedSource, setIsFilterAllSelectedSource] = useState(false)
  const [checkboxListSource, setCheckboxListSource] = useState([])
  const [filterSourceDefaultList, setFilterSourceDefaultList] = useState([])

  const [filterSelectedParameters, setFilterSelectedParameters] = useState([])
  const [isFilterAllSelectedParameters, setIsFilterAllSelectedParameters] = useState(true)
  const [checkboxListParameters, setCheckboxListParameters] = useState([])

  const defaultStartDate = new Date()
  defaultStartDate.setMonth(defaultStartDate.getMonth() - 12)

  const [isCheckedStartDate, setIsCheckedStartDate] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [isCheckedEndDate, setIsCheckedEndDate] = useState(false)
  const [endDate, setEndDate] = useState(new Date())

  const [sortConfig, setSortConfig] = useState(null)

  if (_.isEqual(startDate, new Date())) {
    setStartDate(defaultStartDate)
  }

  const onClickApply = () => {
    const paramters = isFilterAllSelectedParameters
      ? _.map(checkboxListParameters, (item) => item.text)
      : _.map(filterSelectedParameters, (item) => item.text)
    const riskRating = isFilterAllSelectedRiskRating
      ? _.map(checkboxListRiskRating, (item) => item.text)
      : _.map(filterSelectedRiskRating, (item) => item.text)
    const source =
      _.isEmpty(filterSourceDefaultList) && _.isEmpty(filterSelectedSource)
        ? _.filter(checkboxListSource, function (item) {
            return item.text !== 'Desalter Email'
          })
        : _.map(filterSelectedSource, (item) => item.text)

    if (_.isEmpty(filterSourceDefaultList)) {
      setFilterSourceDefaultList(source)
    }

    const propertyRange = {}
    propertyRange['startDate'] = moment(startDate).format('yyyy-MM-DD')
    propertyRange['endDate'] = moment(endDate).format('yyyy-MM-DD')

    if (!isValidated()) {
      return
    }

    dispatch(
      getPropertyResultTable({
        mode: propertyPageConstant.SEARCH_MODE.FILTER_MODE,
        currentPersona,
        property: selectedPropertyName.integration_crude_prop,
        paramters,
        riskRating,
        source,
        propertyRange,
      }),
      [
        getPropertyResultTable,
        currentPersona,
        selectedPropertyName,
        paramters,
        riskRating,
        source,
        propertyRange,
      ]
    )
  }

  const onClickReset = () => {
    setFilterSelectedRiskRating([])
    setIsFilterAllSelectedRiskRating(true)
    setCheckboxListRiskRating([])

    setFilterSelectedSource([])
    setIsFilterAllSelectedSource(false)
    setCheckboxListSource([])
    setFilterSourceDefaultList([])

    setFilterSelectedParameters([])
    setIsFilterAllSelectedParameters(true)
    setCheckboxListParameters([])

    setIsCheckedStartDate(false)
    setStartDate(defaultStartDate)
    setIsCheckedEndDate(false)
    setEndDate(new Date())

    setSortConfig(null)

    dispatch(
      getPropertyResultTable({
        mode: propertyPageConstant.SEARCH_MODE.DEFAULT_MODE,
        currentPersona,
        property: properties.integration_crude_prop,
      }),
      [getPropertyResultTable, currentPersona, properties]
    )
  }

  const onClickSearch = () => {
    onClickReset()
    setSelectedPropertyName(properties)
  }

  const isValidated = useCallback(() => {
    let newErrorInputs = {}

    newErrorInputs.propertyName = _.isNull(properties) || _.isEmpty(properties)
    newErrorInputs.startDate = startDate > endDate

    setErrorInputs(newErrorInputs)
    let hasError = false
    _.forOwn(newErrorInputs, (value, key) => {
      if (hasError) return

      hasError = value
    })
    return !hasError
  }, [properties, startDate, endDate])

  const getBlendDateDisplayLabel = (selectedDate) => {
    return _.isDate(selectedDate) ? _.upperCase(moment(selectedDate).format('DD MMM YYYY')) : ''
  }

  const endMonth = endOfMonth(new Date())
  const endOfNextMonth = moment(endMonth).add(1, 'M')._d

  const minDate = moment('2010-08-01')._d

  return (
    <div className="main-container page-property">
      <Grid padded>
        <Grid.Row>
          <Grid.Column style={searchBarStyle}>
            <Grid.Row className={`flex`}>
              <SearchInput
                className={`search-input-container ${errorInputs.propertyName ? 'error' : ''}`}
                displayProperty="propertyName"
                displayInListProperty="propertyName"
                defaultSelection={properties.propertyName}
                selectionOptions={propertiesPage}
                onSelectionUpdate={(selectedResult) => {
                  errorInputs.propertyName = false
                  setProperties(selectedResult)
                }}
                placeholder="Enter property name"
              />
              <Button className="btn-primary" onClick={onClickSearch}>
                Search
              </Button>
            </Grid.Row>{' '}
            <Grid.Row>
              <div className={`field-error-label ${errorInputs.propertyName ? 'show' : ''}`}>
                <Icon name="warning circle" /> This field is required
              </div>
            </Grid.Row>
          </Grid.Column>{' '}
        </Grid.Row>{' '}
      </Grid>{' '}
      <FetchStateWrapper id={PropertyPageActions.POST_PROPERTY_RESULT_TABLE}></FetchStateWrapper>
      {!_.isEmpty(selectedPropertyName) ? (
        <div>
          <div className={`pad-top-1`}>
            <div style={searchOutputStyle}>{selectedPropertyName.propertyName}</div>
          </div>
          <div className="section section-table-filter flex">
            <div className="left-nav">
              <div className="property-page-left-nav-filter-wrapper">
                <Grid padded>
                  <Grid.Row>
                    <Grid.Column textAlign="left">
                      <Header className={`nav-title`}>FILTERS</Header>
                      {
                        <CheckboxList
                          title="PARAMETER"
                          data={filterParameters}
                          checkboxList={checkboxListParameters}
                          setCheckboxList={setCheckboxListParameters}
                          filterSelected={filterSelectedParameters}
                          setFilterSelected={setFilterSelectedParameters}
                          isAllSelected={isFilterAllSelectedParameters}
                          setIsAllSelected={setIsFilterAllSelectedParameters}
                        />
                      }
                      {
                        <CheckboxList
                          title="RISK RATING"
                          data={filterRiskRatings}
                          checkboxList={checkboxListRiskRating}
                          setCheckboxList={setCheckboxListRiskRating}
                          filterSelected={filterSelectedRiskRating}
                          setFilterSelected={setFilterSelectedRiskRating}
                          isAllSelected={isFilterAllSelectedRiskRating}
                          setIsAllSelected={setIsFilterAllSelectedRiskRating}
                        />
                      }
                      {
                        <CheckboxList
                          title="SOURCE"
                          data={filterSource}
                          checkboxList={checkboxListSource}
                          setCheckboxList={setCheckboxListSource}
                          filterSelected={filterSelectedSource}
                          setFilterSelected={setFilterSelectedSource}
                          isAllSelected={isFilterAllSelectedSource}
                          setIsAllSelected={setIsFilterAllSelectedSource}
                        />
                      }
                      <div className={`nav-header`}>SEARCH START DATE</div>
                      <div className={`pad-y-1`}>
                        <div>
                          <Popup
                            trigger={
                              <Input
                                value={getBlendDateDisplayLabel(startDate)}
                                error={errorInputs.startDate}
                                placeholder="dd-mm-yyyy"
                              />
                            }
                            content={
                              <Calendar
                                minDate={minDate}
                                maxDate={endOfNextMonth}
                                date={startDate}
                                onChange={(selectedStartDate) => {
                                  errorInputs.startDate = false
                                  setStartDate(selectedStartDate)
                                }}
                              />
                            }
                            on="click"
                            className="bg-blue"
                            hideOnScroll={true}
                            position="bottom right"
                          />
                        </div>
                      </div>
                      <div className={`nav-header`}>SEARCH END DATE</div>
                      <div className={`pad-y-1`}>
                        <div>
                          <Popup
                            trigger={
                              <Input
                                value={getBlendDateDisplayLabel(endDate)}
                                error={errorInputs.endDate}
                                placeholder="dd-mm-yyyy"
                              />
                            }
                            content={
                              <Calendar
                                minDate={minDate}
                                maxDate={endOfNextMonth}
                                date={endDate}
                                onChange={(selectedEndDate) => setEndDate(selectedEndDate)}
                              />
                            }
                            on="click"
                            className="bg-blue"
                            hideOnScroll={true}
                            position="bottom right"
                          />
                        </div>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row style={{ padding: '1px 0px 40px 0px' }}>
                    <Button className="btn-primary" onClick={onClickApply}>
                      Apply
                    </Button>
                    <Button className="btn-primary-outer" onClick={onClickReset}>
                      Reset
                    </Button>
                  </Grid.Row>
                </Grid>
              </div>
            </div>
            <div className="left-nav-container">
              {!_.isEmpty(searchResult.data) ? (
                <Segment>
                  <ResultTable
                    data={searchResult.data}
                    currentPersona={currentPersona}
                    property={selectedPropertyName}
                    sortConfig={sortConfig}
                    setSortConfig={setSortConfig}
                  />
                </Segment>
              ) : (
                <NoDataBlock />
              )}
            </div>
          </div>{' '}
        </div>
      ) : (
        <div className={`flex justify-center pad-1 section-result-message`}>
          <ResultContainer
            label="Please input property above to enable the search"
            icon={getSearchIcon()}
          />
        </div>
      )}
    </div>
  )
}

export default PropertyPage
