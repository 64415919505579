import React from "react";
import { Image, Dropdown } from "semantic-ui-react";
import logo from "../../assets/images/thai-oil-logo.svg";
import notifications from "../../assets/images/notifications.svg";

//Components
import ProfileMenu from "./profile-menu/profileMenu";
import NotificationMenu from "./notification-menu/notificationMenu";

const ToHeader = () => {
  return (
    <div
      className="top-header-container"
      style={{ position: "relative", zIndex: "100" }}
    >
      <div className="logo-wrapper">
        <img id="thailogo" src={logo} alt="Logo" />
      </div>
      <div className="header-menu-wrapper flex center end">
        <NotificationMenu />
        <ProfileMenu />
      </div>
    </div>
  );
};

export default ToHeader;
