import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';

import Routes from "./routes";

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#DE1782',
			contrastText: '#FFFFFF'
		},
		secondary: {
			main: '#004A97',
			// contrastText:'#'
		}
	},
	typography: {
		fontFamily: ['IBM Plex Sans', 'sans-serif'],
		button: {
			textTransform: 'none'
		}
	}
})

const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<div className="app container">
				<Routes />
			</div>
		</ThemeProvider>
	);
}

export default App;
