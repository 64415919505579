import { fetchStart, fetchSuccess, fetchError } from './actionFetch'
import _ from 'lodash'
import * as services from '../../services/common/serviceMasterData'
import * as UserGroup from '../../constants/userGroups'

const currentPersonaFormatted = (currentPersona) =>
  _.isEqual(currentPersona, UserGroup.SC)
    ? 'SC'
    : _.isEqual(currentPersona, UserGroup.ENTS)
    ? 'ENTS'
    : _.isEqual(currentPersona, UserGroup.TN)
    ? 'TN'
    : ''

export const CLEAR_MASTER_DATA = 'CLEAR_MASTER_DATA'
export const clearMasterData = ({ storePath }) => {
  return {
    type: CLEAR_MASTER_DATA,
    getMasterFreeformUnit,
    payload: storePath,
  }
}

//Key Action
export const GET_MASTER_DATA_WITH_OPTIONS = 'GET_MASTER_DATA_WITH_OPTIONS'
export const getMasterDataWithOptions = (
  { apiPath, storePath, bodyData },
  cache,
  ignoreFetchState
) => {
  return (dispatch, getState) => {
    const currentState = _.get(getState(), `masterDataPage.${storePath}`)
    if (cache && !_.isEmpty(currentState)) return
    //if (!ignoreFetchState) dispatch(fetchStart(GET_MASTER_DATA_WITH_OPTIONS))
    services
      .getMasterData(apiPath, bodyData)
      .then((result) => {
        dispatch({
          type: GET_MASTER_DATA_WITH_OPTIONS,
          payload: {
            path: storePath,
            value: result,
          },
        })
        //if (!ignoreFetchState) dispatch(fetchSuccess(GET_MASTER_INPUT_DATA))
      })
      .catch((e) => {
        //if (!ignoreFetchState) dispatch(fetchError(GET_MASTER_INPUT_DATA, e))
      })
    dispatch({
      type: GET_MASTER_DATA_WITH_OPTIONS,
      payload: {
        path: storePath,
        value: [],
      },
      isAttach: true,
    })
  }
}

export const GET_MASTER_DATA_MERGE_WITH_OPTIONS = 'GET_MASTER_DATA_MERGE_WITH_OPTIONS'
export const getMasterDataMergeWithOptions = (
  { apiPath, storePath, bodyData },
  cache,
  ignoreFetchState
) => {
  return (dispatch, getState) => {
    const currentState = _.get(getState(), `masterDataPage.${storePath}`)
    if (cache && !_.isEmpty(currentState)) return
    //if (!ignoreFetchState) dispatch(fetchStart(GET_MASTER_DATA_WITH_OPTIONS))
    services
      .getMasterData(apiPath, bodyData)
      .then((result) => {
        dispatch({
          type: GET_MASTER_DATA_MERGE_WITH_OPTIONS,
          payload: {
            path: storePath,
            value: result,
          },
        })
        //if (!ignoreFetchState) dispatch(fetchSuccess(GET_MASTER_INPUT_DATA))
      })
      .catch((e) => {
        //if (!ignoreFetchState) dispatch(fetchError(GET_MASTER_INPUT_DATA, e))
      })
    dispatch({
      type: GET_MASTER_DATA_MERGE_WITH_OPTIONS,
      payload: {
        path: storePath,
        value: [],
      },
      isAttach: true,
    })
  }
}

//Various master data apis
export const getMasterCrudeNames = (searchName, isCache) => {
  const config = {
    apiPath: '/crudeNames',
    storePath: 'crudeNames',
    bodyData: {
      crudeSearch: searchName || '',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterPropertyPageSearchInput = (searchName, isCache) => {
  const config = {
    apiPath: 'properties',
    storePath: 'propertiesPage',
    bodyData: {
      propertySearch: searchName || '',
      component: 'search',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterCrudeFractions = (isCache) => {
  const config = {
    apiPath: '/crudeBlendFractions',
    storePath: 'crudeBlendFractions',
    bodyData: {
      fraction: 'fraction',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterSingleCrudeFractions = (isCache) => {
  const config = {
    apiPath: '/singleCrudeFractions',
    storePath: 'singleCrudeFractions',
    bodyData: {
      fraction: 'fraction',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterProcessUnits = (isCache) => {
  const config = {
    apiPath: '/processUnits',
    storePath: 'processUnits',
    bodyData: {},
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterMaterialNames = (isCache) => {
  const config = {
    apiPath: '/materialNames',
    storePath: 'materialNames',
    bodyData: {},
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterFractions = (currentPersona, isCache) => {
  const config = {
    apiPath: '/fractions',
    storePath: 'fractions',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'blend_daily',
      unit: 'CDU-1',
      source: 'APS Data',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterUnits = (currentPersona, isCache) => {
  const config = {
    apiPath: '/units',
    storePath: 'units',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'blend_monthly',
      feedType: 'CDU',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterProperties = (currentPersona, isCache) => {
  const config = {
    apiPath: '/properties',
    storePath: 'properties',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'blend_daily',
      crudeType: 'Blend',
      feedType: 'CDU',
      unit: 'CDU-1',
      fraction: 'Gasoil',
      source: 'APS Data',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterDataSources = (currentPersona, isCache) => {
  const config = {
    apiPath: '/dataSources',
    storePath: 'dataSources',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'blend_monthly',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

// CORROSION PAGE

export const getMasterCorrosionUnits = (currentPersona, crudeMode, isCache) => {
  const componentType = _.isEqual(crudeMode, 'single')
    ? 'high_temp_calc_crude'
    : _.isEqual(crudeMode, 'blend')
    ? 'high_temp_calc_blend'
    : ''
  const config = {
    apiPath: '/units',
    storePath: 'corrosionUnits',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: componentType,
      feedType: 'CDU',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterCorrosionUnitsDS = (currentPersona, crudeMode, highTempNhHs, isCache) => {
  const componentTypes =
    _.isEqual(highTempNhHs, 'HT') && _.isEqual(crudeMode, 'single')
      ? highTempNhHs + '_' + crudeMode
      : _.isEqual(highTempNhHs, 'HT') && _.isEqual(crudeMode, 'blend')
      ? highTempNhHs + '_' + crudeMode
      : _.isEqual(highTempNhHs, 'NH') && _.isEqual(crudeMode, 'single')
      ? highTempNhHs + '_' + crudeMode
      : _.isEqual(highTempNhHs, 'NH') && _.isEqual(crudeMode, 'blend')
      ? highTempNhHs + '_' + crudeMode
      : highTempNhHs + '_' + crudeMode

  const config = {
    apiPath: '/units',
    storePath: 'corrosionUnitsDS',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: componentTypes,
      feedType: 'DS',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterCorrosionFractions = (
  currentPersona,
  crudeMode,
  highTempNhHs,
  operatingUnit,
  isCache
) => {
  const componentTypes =
    _.isEqual(highTempNhHs, 'HT') && _.isEqual(crudeMode, 'single')
      ? highTempNhHs + '_' + crudeMode
      : _.isEqual(highTempNhHs, 'HT') && _.isEqual(crudeMode, 'blend')
      ? highTempNhHs + '_' + crudeMode
      : _.isEqual(highTempNhHs, 'NH') && _.isEqual(crudeMode, 'single')
      ? highTempNhHs + '_' + crudeMode
      : _.isEqual(highTempNhHs, 'NH') && _.isEqual(crudeMode, 'blend')
      ? highTempNhHs + '_' + crudeMode
      : highTempNhHs + '_' + crudeMode

  const config = {
    apiPath: '/fractions',
    storePath: 'corrosionFractions',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: componentTypes,
      unit: operatingUnit,
      source: 'APS Data',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

// CRUDE BLEND CHART

export const getMasterDownstreamUnits = (currentPersona, isCache) => {
  const config = {
    apiPath: '/units',
    storePath: 'downstreamUnits',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'blend_monthly',
      feedType: 'DS',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterCrudeDataSources = (currentPersona, isCache) => {
  const config = {
    apiPath: '/dataSources',
    storePath: 'crudeDataSources',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'blend_monthly',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterCduFeedProperties = (currentPersona, unit, dataSource, isCache) => {
  const config = {
    apiPath: '/properties',
    storePath: 'cduFeedProperties',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'blend_daily',
      crudeType: 'Blend',
      feedType: 'CDU',
      unit: unit,
      fraction: null,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterDownstreamFractions = (currentPersona, unit1, dataSource, isCache) => {
  const config = {
    apiPath: '/fractions',
    storePath: 'downstreamFractions',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'blend_daily',
      unit: unit1,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterDownstreamProperties = (
  currentPersona,
  unit1,
  fraction1,
  dataSource,
  isCache
) => {
  const config = {
    apiPath: '/properties',
    storePath: 'downstreamProperties',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'blend_daily',
      crudeType: 'Blend',
      feedType: 'DS',
      unit: unit1,
      fraction: fraction1,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterDownstreamProperties2 = (
  currentPersona,
  unit1,
  fraction2,
  dataSource,
  isCache
) => {
  const config = {
    apiPath: '/properties',
    storePath: 'downstreamProperties2',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'blend_daily',
      crudeType: 'Blend',
      feedType: 'DS',
      unit: unit1,
      fraction: fraction2,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterDownstreamProperties3 = (
  currentPersona,
  unit1,
  fraction3,
  dataSource,
  isCache
) => {
  const config = {
    apiPath: '/properties',
    storePath: 'downstreamProperties3',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'blend_daily',
      crudeType: 'Blend',
      feedType: 'DS',
      unit: unit1,
      fraction: fraction3,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterDownstreamProperties4 = (
  currentPersona,
  unit1,
  fraction4,
  dataSource,
  isCache
) => {
  const config = {
    apiPath: '/properties',
    storePath: 'downstreamProperties4',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'blend_daily',
      crudeType: 'Blend',
      feedType: 'DS',
      unit: unit1,
      fraction: fraction4,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterDownstreamProperties5 = (
  currentPersona,
  unit1,
  fraction5,
  dataSource,
  isCache
) => {
  const config = {
    apiPath: '/properties',
    storePath: 'downstreamProperties5',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'blend_daily',
      crudeType: 'Blend',
      feedType: 'DS',
      unit: unit1,
      fraction: fraction5,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

// PARAMETER TREND CHART

export const getMasterParameterDataSources = (currentPersona, isCache) => {
  const config = {
    apiPath: '/dataSources',
    storePath: 'parameterDataSources',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'blend_monthly',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterParameterUnits = (currentPersona, isCache) => {
  const config = {
    apiPath: '/units',
    storePath: 'parameterUnits',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'blend_param_trend',
      feedType: 'DS',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterParameterFractions = (currentPersona, unit1, dataSource, isCache) => {
  const config = {
    apiPath: '/fractions',
    storePath: 'parameterFractions',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'blend_param_trend',
      unit: unit1,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterParamaterProperties = (
  currentPersona,
  unit1,
  fraction1,
  dataSource,
  isCache
) => {
  const config = {
    apiPath: '/properties',
    storePath: 'parameterProperties',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'blend_param_trend',
      crudeType: 'Blend',
      feedType: 'DS',
      unit: unit1,
      fraction: fraction1,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterParameterUnits1 = (currentPersona, isCache) => {
  const config = {
    apiPath: '/units',
    storePath: 'parameterUnits1',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'blend_param_trend',
      feedType: 'DS',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterParameterFractions1 = (currentPersona, unit2, dataSource, isCache) => {
  const config = {
    apiPath: '/fractions',
    storePath: 'parameterFractions1',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'blend_param_trend',
      unit: unit2,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterParamaterProperties1 = (
  currentPersona,
  unit2,
  fraction2,
  dataSource,
  isCache
) => {
  const config = {
    apiPath: '/properties',
    storePath: 'parameterProperties1',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'blend_param_trend',
      crudeType: 'Blend',
      feedType: 'DS',
      unit: unit2,
      fraction: fraction2,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

// SINGLE CRUDE CHART
export const getMasterSingleCrudeProperties = (currentPersona, isCache) => {
  const config = {
    apiPath: '/properties',
    storePath: 'singleCrudeProperties',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'single_crude_corro',
      crudeType: 'Crude',
      feedType: null,
      unit: null,
      fraction: null,
      source: null,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterSingleCrudeUnits = (currentPersona, isCache) => {
  const config = {
    apiPath: '/units',
    storePath: 'singleCrudeUnits',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'blend_monthly',
      feedType: 'CDU',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

// CORROSION PARAMETER TREND CHART

export const getMasterCorrosionParamUnits = (currentPersona, isCache) => {
  const config = {
    apiPath: '/units',
    storePath: 'corrosionParamUnits',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'corro_param_trend',
      feedType: 'DS',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterCorrosionParamProperties = (currentPersona, unit, isCache) => {
  const config = {
    apiPath: '/properties',
    storePath: 'corrosionParamproperties',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'corro_param_trend',
      crudeType: 'Blend',
      feedType: 'DS',
      unit: unit,
      fraction: 'Gasoil',
      source: 'APS Data',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

// STATISTICAL ANALYSIS PAGE

export const getMasterStatisticalProperties = (currentPersona, isCache) => {
  const config = {
    apiPath: '/properties',
    storePath: 'statisticalProperties',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'blend_daily',
      crudeType: 'Blend',
      feedType: 'CDU',
      unit: 'CDU-1',
      fraction: 'Gasoil',
      source: 'APS Data',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterStatisticalSettingProperties = (currentPersona, isCache) => {
  const config = {
    apiPath: '/properties',
    storePath: 'statisticalSettingProperties',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'stat-sim-modify-prop',
      crudeType: 'Crude',
      feedType: '',
      unit: '',
      fraction: 'IBP-FBP',
      source: 'Spiral Data',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterSimiliarCrudeDataSources = (currentPersona, isCache) => {
  const config = {
    apiPath: '/dataSources',
    storePath: 'similiarCrudeDataSources',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'blend_monthly',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterCompareCrudeDataSources = (currentPersona, isCache) => {
  const config = {
    apiPath: '/dataSources',
    storePath: 'compareCrudeDataSources',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_single',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterCrudePropertyUnits = (currentPersona, isCache) => {
  const config = {
    apiPath: '/units',
    storePath: 'crudePropertiesUnits',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_single',
      feedType: 'single',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterCrudeCompareProperties = (currentPersona, dataSource, isCache) => {
  const getFraction = (dataSource) => {
    return _.isEqual(dataSource, 'Spiral Data') ? 'IBP-FBP' : 'Whole Crude'
  }
  const config = {
    apiPath: '/properties',
    storePath: 'crudeCompareProperties',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_single',
      crudeType: 'Crude',
      feedType: '',
      unit: '',
      fraction: getFraction(dataSource),
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterCrudeCompareFractions = (currentPersona, dataSource, isCache) => {
  const config = {
    apiPath: '/fractions',
    storePath: 'crudeCompareFractions',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_single_cut',
      unit: '',
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterCrudeCutCamUnit1 = (
  currentPersona,
  fraction,
  dataSource,
  cutProperty1,
  isCache
) => {
  const config = {
    apiPath: '/camUnits',
    storePath: 'crudeCutCamUnit1',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_single_cut',
      crudeType: 'Crude',
      property: cutProperty1,
      fraction: fraction,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterCrudeCutCamUnit2 = (
  currentPersona,
  fraction,
  dataSource,
  cutProperty2,
  isCache
) => {
  const config = {
    apiPath: '/camUnits',
    storePath: 'crudeCutCamUnit2',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_single_cut',
      crudeType: 'Crude',
      property: cutProperty2,
      fraction: fraction,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterCrudeCutCamUnit3 = (
  currentPersona,
  fraction,
  dataSource,
  cutProperty3,
  isCache
) => {
  const config = {
    apiPath: '/camUnits',
    storePath: 'crudeCutCamUnit3',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_single_cut',
      crudeType: 'Crude',
      property: cutProperty3,
      fraction: fraction,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterCrudeCutCamUnit4 = (
  currentPersona,
  fraction,
  dataSource,
  cutProperty4,
  isCache
) => {
  const config = {
    apiPath: '/camUnits',
    storePath: 'crudeCutCamUnit4',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_single_cut',
      crudeType: 'Crude',
      property: cutProperty4,
      fraction: fraction,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterCrudeCutCamUnit5 = (
  currentPersona,
  fraction,
  dataSource,
  cutProperty5,
  isCache
) => {
  const config = {
    apiPath: '/camUnits',
    storePath: 'crudeCutCamUnit5',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_single_cut',
      crudeType: 'Crude',
      property: cutProperty5,
      fraction: fraction,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterCrudeCutProperties1 = (currentPersona, fraction, dataSource, isCache) => {
  const config = {
    apiPath: '/properties',
    storePath: 'crudeCutProperties1',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_single',
      crudeType: 'Crude',
      feedType: 'crude-cut',
      unit: '',
      fraction: fraction,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterCrudeCutProperties2 = (currentPersona, fraction, dataSource, isCache) => {
  const config = {
    apiPath: '/properties',
    storePath: 'crudeCutProperties2',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_single',
      crudeType: 'Crude',
      feedType: 'crude-cut',
      unit: '',
      fraction: fraction,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterCrudeCutProperties3 = (currentPersona, fraction, dataSource, isCache) => {
  const config = {
    apiPath: '/properties',
    storePath: 'crudeCutProperties3',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_single',
      crudeType: 'Crude',
      feedType: 'crude-cut',
      unit: '',
      fraction: fraction,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterCrudeCutProperties4 = (currentPersona, fraction, dataSource, isCache) => {
  const config = {
    apiPath: '/properties',
    storePath: 'crudeCutProperties4',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_single',
      crudeType: 'Crude',
      feedType: 'crude-cut',
      unit: '',
      fraction: fraction,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterCrudeCutProperties5 = (currentPersona, fraction, dataSource, isCache) => {
  const config = {
    apiPath: '/properties',
    storePath: 'crudeCutProperties5',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_single',
      crudeType: 'Crude',
      feedType: 'crude-cut',
      unit: '',
      fraction: fraction,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

// Compare Blend

export const getMasterCompareBlendDataSources = (currentPersona, isCache) => {
  const config = {
    apiPath: '/dataSources',
    storePath: 'compareBlendDataSources',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_compare_blend',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterCrudeBlendUnits = (currentPersona, isCache) => {
  const config = {
    apiPath: '/units',
    storePath: 'crudeBlendUnits',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_compare_blend',
      feedType: 'CDU',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterCrudeBlendUnitsDS = (currentPersona, isCache) => {
  const config = {
    apiPath: '/units',
    storePath: 'crudeBlendUnitsDS',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_compare_blend',
      feedType: 'DS',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterCompareBlendFractions = (currentPersona, operatingUnit, isCache) => {
  const config = {
    apiPath: '/fractions',
    storePath: 'compareBlendFraction',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_compare_blend',
      unit: operatingUnit,
      source: 'APS Data',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterCrudeBlendProperties = (
  currentPersona,
  operatingUnit,
  fraction,
  dataSource,
  isCache
) => {
  const config = {
    apiPath: '/properties',
    storePath: 'crudeBlendProperties',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_compare_blend',
      crudeType: 'Blend',
      feedType: 'DS',
      unit: operatingUnit,
      fraction: fraction,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

// Freeform Trend

export const getMasterFreeformDataSource = (currentPersona, isCache) => {
  const config = {
    apiPath: '/dataSources',
    storePath: 'freeformDataSource',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_freeform',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterFreeformUnit = (currentPersona, isCache) => {
  const config = {
    apiPath: '/units',
    storePath: 'freeformUnit',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_freeform',
      feedType: 'DS',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterFreeformStream1 = (currentPersona, unit, dataSource, isCache) => {
  const config = {
    apiPath: '/fractions',
    storePath: 'freeformStream1',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_freeform',
      unit: unit,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterFreeformStream2 = (currentPersona, unit, dataSource, isCache) => {
  const config = {
    apiPath: '/fractions',
    storePath: 'freeformStream2',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_freeform',
      unit: unit,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterFreeformStream3 = (currentPersona, unit, dataSource, isCache) => {
  const config = {
    apiPath: '/fractions',
    storePath: 'freeformStream3',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_freeform',
      unit: unit,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterFreeformStream4 = (currentPersona, unit, dataSource, isCache) => {
  const config = {
    apiPath: '/fractions',
    storePath: 'freeformStream4',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_freeform',
      unit: unit,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterFreeformStream5 = (currentPersona, unit, dataSource, isCache) => {
  const config = {
    apiPath: '/fractions',
    storePath: 'freeformStream5',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_freeform',
      unit: unit,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterFreeformProperty1 = (currentPersona, unit, stream, dataSource, isCache) => {
  const config = {
    apiPath: '/properties',
    storePath: 'freeformProperty1',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_freeform',
      crudeType: 'Blend',
      feedType: 'DS',
      unit: unit,
      fraction: stream,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterFreeformProperty2 = (currentPersona, unit, stream, dataSource, isCache) => {
  const config = {
    apiPath: '/properties',
    storePath: 'freeformProperty2',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_freeform',
      crudeType: 'Blend',
      feedType: 'DS',
      unit: unit,
      fraction: stream,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterFreeformProperty3 = (currentPersona, unit, stream, dataSource, isCache) => {
  const config = {
    apiPath: '/properties',
    storePath: 'freeformProperty3',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_freeform',
      crudeType: 'Blend',
      feedType: 'DS',
      unit: unit,
      fraction: stream,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterFreeformProperty4 = (currentPersona, unit, stream, dataSource, isCache) => {
  const config = {
    apiPath: '/properties',
    storePath: 'freeformProperty4',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_freeform',
      crudeType: 'Blend',
      feedType: 'DS',
      unit: unit,
      fraction: stream,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getMasterFreeformProperty5 = (currentPersona, unit, stream, dataSource, isCache) => {
  const config = {
    apiPath: '/properties',
    storePath: 'freeformProperty5',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'statistical_freeform',
      crudeType: 'Blend',
      feedType: 'DS',
      unit: unit,
      fraction: stream,
      source: dataSource,
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getFilterRiskRatings = (isCache) => {
  const config = {
    apiPath: 'riskRatingsSourceParameters',
    storePath: 'filterRiskRatings',
    bodyData: {
      persona: 'SC',
      component: 'blend_monthly',
      filterType: 'RISK_RATING',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getFilterSource = (isCache) => {
  const config = {
    apiPath: 'riskRatingsSourceParameters',
    storePath: 'filterSource',
    bodyData: {
      persona: 'SC',
      component: 'blend_monthly',
      filterType: 'DATA_SOURCES',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getFilterParameters = (isCache) => {
  const config = {
    apiPath: 'riskRatingsSourceParameters',
    storePath: 'filterParameters',
    bodyData: {
      persona: 'SC',
      component: 'blend_monthly',
      filterType: 'PARAMETERS',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}

export const getHighlightUnit = (currentPersona, isCache) => {
  const config = {
    apiPath: '/units',
    storePath: 'highlightUnits',
    bodyData: {
      persona: currentPersonaFormatted(currentPersona),
      component: 'blend_monthly',
      feedType: 'CDU',
    },
  }
  return (dispatch) => {
    dispatch(getMasterDataWithOptions(config, isCache))
  }
}
