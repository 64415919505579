import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import moment from 'moment'

// Redux
import { PERSONAL_SETTING_REDUCER } from "../../../reducers/common/personalSettingReducer";

// Hooks
import { useStateWithPaths } from "../../../hooks/useConnect";

// Components
import { Popup, Button, Icon, Header, Divider } from "semantic-ui-react";

// Actions
import { setNotification, setSaveNotificationStatus } from "../../../actions/actionLanding";

const PopupContent = (getNotificationData, currentPersona) => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(setNotification(currentPersona));
  }, [dispatch, setNotification, setSaveNotificationStatus, currentPersona]);

  return (
    <div className={`flex column menu-container`}>
      <div className={`pad-1 mar-0`}>
        <Header size="small" textAlign="left">
          NOTIFICATIONS
        </Header>
      </div>
      <Divider />
      <div className={`notification-content-container`}>
        {_.map(getNotificationData, (item) => (
          <div
            className={`flex pad-x-1 notification-item-container`}
            style={{ backgroundColor: `${item.is_new === 1 ? "#F2FAFF" : ""}` }}
            onClick={() => (dispatch(setSaveNotificationStatus(item.id)), dispatch(setNotification(currentPersona)))}
          >
            <div
              className={`blue-icon-nottification`}
              style={{ backgroundColor: `${item.is_new === 1 ? "#004a97" : "#FFF"}` }}
            ></div>
            {_.isEqual(item.name, "Crude Arrival") ? (
              <div className={`flex left column width-100`}>
                <div className={`mar-0`}>
                  <span className={`text-primary bold`}>{item.name}</span>
                </div>
                <div className={`flex space-between pad-y-1`}>
                  <div className={`line-break-anywhere`}>{item.data}</div>
                  <div className={`nowrap`}>{moment(item.date).fromNow(true)}</div>
                </div>
              </div>
            ) : _.isEqual(item.name, "CAM file") ? (
              <div className={`flex left column width-100`}>
                <div className={`mar-0`}>
                  New <span className={`text-primary bold`}>{item.name}</span>{" "}
                  has been uploaded
                </div>
                <div className={`flex space-between pad-y-1`}>
                  <div className={`line-break-anywhere`}>Data {item.data} is now available</div>
                  <div className={`nowrap`}>{moment(item.date).fromNow(true)}</div>
                </div>
              </div>
            ) : (
              <div className={`flex left column width-100`}>
                <div className={`mar-0`}>
                  New <span className={`text-primary bold`}>{item.name}</span>{" "}
                  has been uploaded
                </div>
                <div className={`flex space-between pad-y-1`}>
                  <div className={`line-break-anywhere`}>Data {item.data} is now available</div>
                  <div className={`nowrap`}>{moment(item.date).fromNow(true)}</div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const NotificationMenu = () => {
  const dispatch = useDispatch();
  
  const [notificationData] = useStateWithPaths(["landingPage.notification"]);
  const [{ currentPersona }] = useStateWithPaths([
    `${PERSONAL_SETTING_REDUCER}`,
  ]);

  const getNotificationData = _.get(notificationData, 'data')

  useEffect(() => {
    dispatch(setNotification(currentPersona));
  }, [dispatch, setNotification, setSaveNotificationStatus, currentPersona]);

  const getNew = _.map(getNotificationData, item => item.is_new)
  const isNew = _.includes(getNew, 1) ? '' : 'none'

  return (
    <div className="notification-menu-container">
      <Popup
        trigger={
          <Button>
            {}
            <div className={`new-notification-pink ${isNew}`}></div>
            <Icon color="black" size="big" name="bell outline" />
          </Button>
        }
        content={PopupContent(getNotificationData, currentPersona)}
        pinned
        basic
        offset="0,20"
        on="click"
        position="bottom right"
        hideOnScroll={false}
      />
    </div>
  );
};

export default NotificationMenu;
