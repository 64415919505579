import React from "react";
import { Route, Switch } from "react-router-dom";
import LandingPage from "./containers/views/landing-page/landing-page";
import CorrosionRatePage from "./containers/views/corrosion-rate-page/corrosionRatePage";
import RisksPage from "./containers/views/risks-page/risksPage";
import StatisticalAnalysisPage from "./containers/views/statistical-analysis-page/statisticalAnalysisPage";
import ProcessingPage from "./containers/views/processing-page/processingPage";
import PropertyPage from "./containers/views/property-page/propertyPage";
import CompatibilityPage from "./containers/views/compatibility-page/compatibilityPage";
import UnauthorisedAccessPage from "./containers/views/common/unauthorisedAccessPage";
import LoginPage from "./containers/login";
import LogoutPage from "./containers/logout";

import MitigationView from "./containers/views/mitigationView";
import GraphRiskView from "./containers/views/graphRiskView";
import GraphBlendView from "./containers/views/graphBlendView";
import GraphCrudeView from "./containers/views/graphCrudeView";
import GraphView from "./containers/views/graphRiskOldView";

import RouteWithAuth from "./components/route-with-auth/routeWithAuth";
import * as USER_GROUPS from "./constants/userGroups";
import WithMasterLayout from "./helpers/hoc/withMasterLayout";

function Routes() {
  return (
    <Switch>
      <RouteWithAuth
        path="/"
        exact
        component={WithMasterLayout(LandingPage)}
        accessGroups={[]}
      />
      <RouteWithAuth
        path="/corrosion-rate"
        exact
        component={WithMasterLayout(CorrosionRatePage)}
        accessGroups={[USER_GROUPS.ENTS]} //{[]}
      />
      <RouteWithAuth
        path="/risks"
        exact
        component={WithMasterLayout(RisksPage)}
        accessGroups={[]}
      />
      <RouteWithAuth
        path="/statistical-analysis"
        exact
        component={WithMasterLayout(StatisticalAnalysisPage)}
        accessGroups={[USER_GROUPS.TN, USER_GROUPS.SC, USER_GROUPS.ENTS]}
      />
      <RouteWithAuth
        path="/processing"
        exact
        component={WithMasterLayout(ProcessingPage)}
        accessGroups={[USER_GROUPS.SC]} //{[]}
      />
      <RouteWithAuth
        path="/property"
        exact
        component={WithMasterLayout(PropertyPage)}
        accessGroups={[]}
      />
      <RouteWithAuth
        path="/compatibility"
        exact
        component={WithMasterLayout(CompatibilityPage)}
        accessGroups={[USER_GROUPS.TN, USER_GROUPS.SC]} //{[]}
      />


      <Route
        path="/logout"
        exact
        component={LogoutPage}
      />
      <Route
        path="/login"
        exact
        component={LoginPage}
      />
      <Route
        path="/unauthorised-access"
        exact
        component={UnauthorisedAccessPage}
      />

      {/* TO be removed */}
      <Route path="/basic" exact component={MitigationView} />
      <Route path="/graphRisk" exact component={GraphRiskView} />
      <Route path="/graphRiskOld" exact component={GraphView} />

      <Route path="/graphBlend" exact component={GraphBlendView} />
      <Route path="/graphCrude" exact component={GraphCrudeView} />
    </Switch>
  );
}

export default Routes;
