import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Style
import './tooltip.scss'
import { useMobile, SCREEN_MODE } from '../../hooks/useMobile'

export const TooltipTypes = {
  TooltipOneColumn: 'TooltipOneColumn',
  TooltipTwoColumns: 'TooltipTwoColumns',
  TooltipOneColumnDescription: 'TooltipOneColumnDescription',
}

const TooltipContent = ({
  label = '',
  value = '',
  indicatorColor = '',
  className = '',
  Content,
  descriptions = [],
  valueColor = '',
  tooltipIndicatorClassName = '',
}) => (
  <div className={className}>
    <div className="tooltipContent">
      <div className="tooltipLabel">
        {!!indicatorColor && (
          <div className={`tooltipIndicatorContainer`}>
            <span
              className={`tooltipIndicator ${tooltipIndicatorClassName}`}
              style={{ background: `${indicatorColor}` }}
            ></span>
            <span> </span>
          </div>
        )}
        <span>{label}</span>
      </div>
      {!!Content && <Content />}
      {valueColor ? (
        <div className="valueStyle" style={{ color: `${indicatorColor}` }}>
          {className === 'tooltipContentGroup tooltipSplit bold' ? (
            <span>{value}</span>
          ) : (
            <span className="tooltipValue">{value}</span>
          )}
        </div>
      ) : (
        <div className="valueStyle">
          {className === 'tooltipContentGroup tooltipSplit bold' ? (
            <span>{value}</span>
          ) : (
            <span className="tooltipValue">{value}</span>
          )}
        </div>
      )}
      {!_.isEmpty(descriptions) && (
        <div className={'tooltipDescriptions'}>
          {descriptions.map((description) => (
            <div
              className={`pad-top-0_5 tooltipDescription`}
              style={{ color: `${indicatorColor}` }}
            >
              {description}
            </div>
          ))}
        </div>
      )}
    </div>
  </div>
)

const TooltipCard = ({
  type = TooltipTypes.TooltipOneColumn,
  label = '',
  value = '',
  indicatorColor = '',
  items = [],
  descriptions = [],
  valueColor = '',
  Content = '',
  style,
  tooltipIndicatorClassName = '',
}) => (
  <div className={`tooltipCard pad-bottom-1 pad-x-1`} style={style}>
    <TooltipContent
      value={value}
      label={label}
      descriptions={descriptions}
      indicatorColor={indicatorColor}
      valueColor={valueColor}
      Content={Content}
      className={`${
        type === TooltipTypes.TooltipTwoColumns ? 'tooltipContentGroup tooltipSplit bold' : ''
      }`}
      tooltipIndicatorClassName={tooltipIndicatorClassName}
    />
    {items.map((item, index) => (
      <TooltipContent
        key={index}
        {...item}
        type={type}
        indicatorColor={'transparent'}
        className={`text-warm-grey ${
          type === TooltipTypes.TooltipTwoColumns ? 'tooltipContentGroup tooltipSplit' : ''
        }`}
        descriptions={descriptions}
        valueColor={valueColor}
        tooltipIndicatorClassName={tooltipIndicatorClassName}
      />
    ))}
  </div>
)

const Tooltip = (props) => {
  const {
    title,
    headerColor,
    items,
    type = TooltipTypes.TooltipOneColumn,
    small,
    medium,
    large,
    className,
    valueColor,
    children,
  } = props
  const screenMode = useMobile()
  return (
    <div
      className={`alpha-tooltip-container text-black center ${classNames({
        'tooltip--small': small,
        'tooltip--large': large,
        'tooltip--medium': medium,
        mobile: screenMode !== SCREEN_MODE.DESKTOP,
      })} ${className}`}
    >
      <header className={`tooltip-header`} style={{ background: headerColor }}>
        <span className="headerText">{title}</span>
      </header>
      <div>
        {_.map(items, (itemGroup, index) => (
          <div
            key={index}
            className={classNames({
              contentSection: true,
              hasDescriptions: _.some(itemGroup, (item) => !!_.size(item.descriptions)),
            })}
          >
            {itemGroup.length !== 0 &&
              _.map(itemGroup, (item, i) =>
                item.type === 'card' ? (
                  <TooltipCard key={i} {...item} type={type} valueColor={valueColor} />
                ) : (
                 ''
                )
              )}
          </div>
        ))}
        {children}
      </div>
    </div>
  )
}

Tooltip.propTypes = {
  title: PropTypes.string,
  headerColor: PropTypes.string,
  items: PropTypes.array,
  type: PropTypes.oneOf(_.values(TooltipTypes)),
  indicatorColor: PropTypes.string,
  valueColor: PropTypes.bool,
}

export default Tooltip
