import _ from 'lodash'
import moment from 'moment'
import { LAMBDA_API_INSTANCE } from './serverInstances/lambdaServer'
import * as UserGroup from '../constants/userGroups'

// Constant
import * as landingPageConstant from '../../src/containers/views/landing-page/helper'
import * as riskPageConstant from '../../src/containers/views/risks-page/helper/constant'

const CONTROLLER_NAME = '/risk'

export const postRiskResultTableSingleCrude = ({
  options,
  mode,
  crudeNameAbbreviation,
  currentPersona,
  paramters,
  riskRating,
  source,
  propertyRange,
  property,
}) => {
  const crudeCode = !_.isEmpty(crudeNameAbbreviation) ? crudeNameAbbreviation.crudeCode : ''
  const crudeName = !_.isEmpty(crudeNameAbbreviation) ? crudeNameAbbreviation.crudeName : ''
  const postBodyDefault = {
    searchType: currentOptions(options),
    mode: mode,
    crudeCode: crudeCode,
    crudeName: crudeName,
    component: 'risk_search_results',
    persona: currentPersonaFormatted(currentPersona),
  }

  const postBodyFilter = {
    searchType: currentOptions(options),
    mode: mode,
    crudeCode: crudeCode,
    crudeName: crudeName,
    component: 'risk_search_results',
    persona: currentPersonaFormatted(currentPersona),
    riskParameters: paramters,
    riskRating: riskRating,
    riskSource: source,
    riskSearchDateRange: propertyRange,
    riskRelatedProperties: property,
  }
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/searchResults`,
    mode === riskPageConstant.SEARCH_MODE.DEFAULT_MODE ? postBodyDefault : postBodyFilter
  )
}

export const postSaveFeedback = ({
  options,
  currentPersona,
  inputUnits,
  crudeNameAbbreviation,
  property,
  referenceId,
  feedback,
  component,
}) => {
  const crudeCode = !_.isEmpty(crudeNameAbbreviation) ? crudeNameAbbreviation.crudeCode : ''
  const blendComposition = _.map(inputUnits, (item) =>
    !_.isUndefined(item.crudeCode) ? { crudeCode: item.crudeCode, percentage: item.percentage } : {}
  )
  const filterBlendComposition = _.filter(blendComposition, (v) => _.keys(v).length !== 0)

  const postBodySingle = {
    persona: currentPersonaFormatted(currentPersona),
    component: component,
    referenceId: referenceId,
    searchType: currentOptions(options),
    crudeCode: crudeCode,
    feedback: feedback,
  }

  const postBodyBlend = {
    persona: currentPersonaFormatted(currentPersona),
    component: component,
    referenceId: referenceId,
    searchType: currentOptions(options),
    blendComposition: filterBlendComposition,
    feedback: feedback,
  }

  const postBodyProperty = {
    persona: currentPersonaFormatted(currentPersona),
    component: component,
    referenceId: referenceId,
    searchType: currentOptions(options),
    property: property,
    feedback: feedback,
  }

  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/saveFeedback`,
    _.isEqual(options, landingPageConstant.SEARCH_OPTIONS.SingleCrude)
      ? postBodySingle
      : _.isEqual(options, landingPageConstant.SEARCH_OPTIONS.CustomBlend)
      ? postBodyBlend
      : postBodyProperty
  )
}

export const postRiskResultTableCustomBlend = ({
  options,
  mode,
  blendComposition,
  currentPersona,
  paramters,
  riskRating,
  source,
  propertyRange,
  property,
}) => {
  const postBodyDefault = {
    searchType: currentOptions(options),
    mode: mode,
    blendComposition: blendComposition,
    component: 'risk_search_results',
    persona: currentPersonaFormatted(currentPersona),
  }

  const postBodyFilter = {
    searchType: currentOptions(options),
    mode: mode,
    blendComposition: blendComposition,
    component: 'risk_search_results',
    persona: currentPersonaFormatted(currentPersona),
    riskParameters: paramters,
    riskRating: riskRating,
    riskSource: source,
    riskSearchDateRange: propertyRange,
    riskRelatedProperties: property,
  }
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/searchResults`,
    mode === riskPageConstant.SEARCH_MODE.DEFAULT_MODE ? postBodyDefault : postBodyFilter
  )
}

export const postFindSimiliarCrude = ({ crudeCode, currentPersona }) => {
  const getCrudeCode = _.get(crudeCode, 'crudeCode')
  const postBody = {
    crudeCode: getCrudeCode,
    fraction: 'IBP-FBP',
    source: 'Spiral Data',
    component: 'stati-similar-crude',
    persona: currentPersonaFormatted(currentPersona),
  }
  return LAMBDA_API_INSTANCE.post(`statistical/similarcrude`, postBody)
}

export const postFindRisksHeaderSingle = ({ options, crudeNameAbbreviation, currentPersona }) => {
  const crudeCode = !_.isEmpty(crudeNameAbbreviation) ? crudeNameAbbreviation.crudeCode : ''
  const crudeName = !_.isEmpty(crudeNameAbbreviation) ? crudeNameAbbreviation.crudeName : ''
  const postBody = {
    searchType: currentOptions(options),
    crudeCode: crudeCode,
    crudeName: crudeName,
    persona: currentPersonaFormatted(currentPersona),
  }
  return LAMBDA_API_INSTANCE.post(`${CONTROLLER_NAME}/header`, postBody)
}

export const postFindRisksHeaderBlend = ({ options, blendComposition, offsetPercentage }) => {
  const postBody = {
    searchType: currentOptions(options),
    blendComposition: blendComposition,
    offsetPercentage: offsetPercentage,
  }
  return LAMBDA_API_INSTANCE.post(`${CONTROLLER_NAME}/header`, postBody)
}

export const postSaveHeaderFavourite = ({ actionObject }) => {
  const getCrudeCode = _.get(actionObject.crudeNameAbbreviation, 'crudeCode')
  const postBody = {
    crudeCode: getCrudeCode,
    component: 'risk-header-single',
    persona: currentPersonaFormatted(actionObject.currentPersona),
    isSelected: actionObject.isClickedOption,
  }
  return LAMBDA_API_INSTANCE.post(`${CONTROLLER_NAME}/saveFavourites`, postBody)
}

export const postCrudeBlendChart = (offset, period, unit, inputUnits) => {
  const startDate = _.map(period, (a) => moment(a.startDate).format('YYYY-MM-DD')).toString()
  const endDate = _.map(period, (a) => moment(a.endDate).format('YYYY-MM-DD')).toString()
  const bodyParams = {
    startDate: startDate,
    endDate: endDate,
    unit: unit,
    blendComposition: inputUnits,
    offsetPercentage: offset,
  }
  return LAMBDA_API_INSTANCE.post(`${CONTROLLER_NAME}/crudeBlendChart`, bodyParams)
}

export const postFavouriteIcon = (currentPersona, crudeCode) => {
  const postBody = {
    persona: currentPersonaFormatted(currentPersona),
    crudeCode: crudeCode,
  }
  return LAMBDA_API_INSTANCE.post(`${CONTROLLER_NAME}/getFavourites`, postBody)
}


export const postFreeformTrend = (
  currentPersona,
  formatCrudeList,
  period,
  color1,
  color2,
  color3,
  color4,
  color5,
  dataSource1,
  dataSource2,
  dataSource3,
  dataSource4,
  dataSource5,
  unit1,
  unit2,
  unit3,
  unit4,
  unit5,
  stream1,
  stream2,
  stream3,
  stream4,
  stream5,
  property1,
  property2,
  property3,
  property4,
  property5,
  unit
) => {
  // const startDate = _.map(period, (item) => item.startDate)
  // const endDate = _.map(period, (item) => item.endDate)

  const startDate = _.map(period, (a) => moment(a.startDate).format('YYYY-MM-DD')).toString()
  const endDate = _.map(period, (a) => moment(a.endDate).format('YYYY-MM-DD')).toString()

  const freeformList = [
    {
      color: color1,
      dataSource: dataSource1,
      unit: unit1,
      fraction: stream1,
      property: property1,
    },
    {
      color: color2,
      dataSource: dataSource2,
      unit: unit2,
      fraction: stream2,
      property: property2,
    },
    {
      color: color3,
      dataSource: dataSource3,
      unit: unit3,
      fraction: stream3,
      property: property3,
    },
    {
      color: color4,
      dataSource: dataSource4,
      unit: unit4,
      fraction: stream4,
      property: property4,
    },
    {
      color: color5,
      dataSource: dataSource5,
      unit: unit5,
      fraction: stream5,
      property: property5,
    },
  ]

  const formatSearchObject = _.map(freeformList, (item) =>
    !_.isEmpty(item.property)
      ? {
          color: item.color,
          dataSource: item.dataSource,
          unit: item.unit,
          fraction: item.fraction,
          property: item.property,
        }
      : {}
  )
  const filterSearchObject = _.filter(formatSearchObject, (v) => _.keys(v).length !== 0)

  const postBody = {
    component: 'statistical-compare',
    unit: unit,
    crudeList: formatCrudeList,
    crudeType: 'Blend',
    startDate: startDate,
    endDate: moment(endDate.toString()).format('YYYY-MM-DD'),
    persona: currentPersonaFormatted(currentPersona),
    freeformList: filterSearchObject,
  }
  return LAMBDA_API_INSTANCE.post(`/statistical/freeformChart`, postBody)
}

const currentPersonaFormatted = (currentPersona) =>
  _.isEqual(currentPersona, UserGroup.SC)
    ? 'SC'
    : _.isEqual(currentPersona, UserGroup.ENTS)
    ? 'ENTS'
    : _.isEqual(currentPersona, UserGroup.TN)
    ? 'TN'
    : ''

const currentOptions = (currentOptions) =>
  _.isEqual(currentOptions, landingPageConstant.SEARCH_OPTIONS.SingleCrude)
    ? 'Single'
    : _.isEqual(currentOptions, landingPageConstant.SEARCH_OPTIONS.CustomBlend)
    ? 'Blend'
    : _.isEqual(currentOptions, landingPageConstant.SEARCH_OPTIONS.Property)
    ? 'Property'
    : ''
