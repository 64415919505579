import React, { useMemo } from "react";
import PropTypes from "prop-types";
// import Checkbox from '../checkbox'
// import { UncontrolledTooltip } from 'reactstrap'
import _ from "lodash";
// import INFO_ICON from '../../img/info_icon.svg'

// import PATH_ICON from '../../img/path.svg'

//Hooks
import { useMobile, SCREEN_MODE } from "../../hooks/useMobile";

export const LEGEND_TYPE = {
  LINE: "line",
  CIRCLE: "circle",
  SQUARE: "square",
  DASH: "dash",
  PILL: "pill",
};

const LegendItem = ({
  title,
  type,
  onCheck,
  checked,
  color,
  icon,
  tooltipContent,
}) => {
  const tooltipId = useMemo(() => _.uniqueId("legend-item-id-"), []);
  return (
    <div className={`alpha-legend-item alpha-mobile-legend-item`}>
      {/* {onCheck && <Checkbox onChange={onCheck} checked={checked} />} */}
      {icon ? (
        _.isString(icon) ? (
          <img className={`mar-right-0_5`} src={icon}></img>
        ) : (
          icon
        )
      ) : (
        !!type && (
          <span
            style={{ backgroundColor: color }}
            className={`alpha-legend-icon __${type}`}
          />
        )
      )}
      <div className={`alpha-legend-title`}>{title}</div>
      {tooltipContent && ( // check if tooltipId is null or undefined (falsey)
        <span
          href="#"
          className={`info-tooltip ${
            _.isEmpty(tooltipContent) ? "hide-tooltip" : ""
          }`}
          id={tooltipId}
        >
          {/* <img src={INFO_ICON} />
          <UncontrolledTooltip
            className={'tooltip-container'}
            placement="right"
            hideArrow={true}
            autohide={true}
            target={tooltipId}
          >
            {tooltipContent}
          </UncontrolledTooltip> */}
        </span>
      )}
    </div>
  );
};
LegendItem.propTypes = {
  title: PropTypes.string,
  type: PropTypes.oneOf([
    LEGEND_TYPE.LINE,
    LEGEND_TYPE.SQUARE,
    LEGEND_TYPE.CIRCLE,
    LEGEND_TYPE.DASH,
    LEGEND_TYPE.PILL,
  ]),
  onCheck: PropTypes.func,
  checked: PropTypes.bool,
  color: PropTypes.string,
  icon: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
  tooltipId: PropTypes.string,
  tooltipContent: PropTypes.string,
};

const Legend = ({
  children,
  title = "",
  onCheck,
  items = [],
  tooltipContent = null,
  large,
  small,
  className,
  style,
  headerStyle,
}) => {
  const tooltipId = useMemo(() => _.uniqueId("legend-title-id-"), []);
  const SCREEN = useMobile();
  if (SCREEN === SCREEN_MODE.DESKTOP) {
    return (
      <div className={`alpha-legend`} style={style}>
        <div className={`alpha-legend-header flex`} style={headerStyle}>
          {title}
          {tooltipContent && (
            <span
              href="#"
              className={`info-tooltip ${
                _.isEmpty(tooltipContent) ? "hide-tooltip" : ""
              }`}
              id={tooltipId}
            >
              {/* <img src={INFO_ICON} />
              <UncontrolledTooltip
                innerClassName={`${classNames({
                  'tooltip--small': small,
                  'tooltip--large': large
                })}`}
                className={`tooltip-container ${classNames({
                  'tooltip--small': small,
                  'tooltip--large': large
                })} ${_.isEmpty(tooltipContent) ? '' : className}`}
                placement="right"
                hideArrow={true}
                autohide={true}
                target={tooltipId}
              >
                {tooltipContent}
              </UncontrolledTooltip> */}
            </span>
          )}
        </div>

        <div className={`alpha-legend-content`}>
          {items.map((item, index) => (
            <LegendItem
              key={index}
              {...item}
              onCheck={
                onCheck ? (...args) => onCheck(item, ...args) : undefined
              }
            />
          ))}
          {children}
        </div>
      </div>
    );
  } else {
    return (
      <div className={`alpha-legend`} style={style}>
        <div className={`alpha-legend-header flex`} style={headerStyle}>
          {title}
          {tooltipContent && (
            <span
              href="#"
              className={`info-tooltip ${
                _.isEmpty(tooltipContent) ? "hide-tooltip" : ""
              }`}
              id={tooltipId}
            >
              {/* <img src={INFO_ICON} />
              <UncontrolledTooltip
                innerClassName={`${classNames({
                  'tooltip--small': small,
                  'tooltip--large': large
                })}`}
                className={`tooltip-container ${classNames({
                  'tooltip--small': small,
                  'tooltip--large': large
                })} ${_.isEmpty(tooltipContent) ? '' : className}`}
                placement="right"
                hideArrow={true}
                autohide={true}
                target={tooltipId}
              >
                {tooltipContent}
              </UncontrolledTooltip> */}
            </span>
          )}
        </div>

        <div
          className={`alpha-legend-content alpha-mobile-legend  mobile-legend-scrollbar`}
        >
          {items.map((item, index) => (
            <LegendItem
              key={index}
              {...item}
              onCheck={
                onCheck ? (...args) => onCheck(item, ...args) : undefined
              }
            />
          ))}
          {children}
        </div>
        {/* <span className={'square-slider'}>
          <img src={PATH_ICON} alt={'Slide'} className={'path-icon'} />
        </span> */}
      </div>
    );
  }
};
Legend.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  title: PropTypes.string,
  className: PropTypes.string,
  onCheck: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape(LegendItem.propTypes)),
};

export default Legend;
export { LegendItem };
