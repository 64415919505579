
import React, { useState } from 'react'
import _ from 'lodash'
import { Image, Button, Popup } from 'semantic-ui-react';

import UserProfile from '../../../assets/images/profile-user.svg';

//Redux
import * as sessionActions from '../../../actions/common/actionSession';
import * as personalSettingActions from '../../../actions/common/actionPersonalSetting';
import { SESSION_REDUCER } from '../../../reducers/common/sessionReducer';
import { PERSONAL_SETTING_REDUCER } from '../../../reducers/common/personalSettingReducer';

//Hooks
import { useActions, useStateWithPaths } from '../../../hooks/useConnect';

//Components
import PersonaSwitcher from '../../persona-switcher/personaSwitcher';

import { USER_PERSONA_LIST } from  '../../../constants/userGroups'

//#region Private Methods

//#endregion

const PopupContent = () => {
    const [{ email, given_name, family_name, userGroups }] = useStateWithPaths([`${SESSION_REDUCER}`])
    
    const { sessionLogout, switchPersonaModal } = useActions({
        sessionLogout: sessionActions.sessionLogout,
        switchPersonaModal: personalSettingActions.switchPersonaModal,
    });

    const signOut = async () => {
        sessionLogout()
    }

    const foundUserPersona = _.find(USER_PERSONA_LIST, ['value', userGroups[0]])
    const userPersonaDisplay = _.get(foundUserPersona, 'title')

    return (
        <>
            <div className="profile-popup-wrapper">
                <div className="welcome-message-container">
                    <div className="welcome-message">
                        HELLO,
               </div>
                    <div className="welcome-person-name">
                        {given_name + ' ' + family_name}
                    </div>
                    <div className="welcome-person-email">
                        {email}
                    </div>
                    <div className="welcome-person-persona">
                        Persona - {userPersonaDisplay}
               </div>
                </div>
                <div className="welcome-switch-persona">
                    <Button className="link-primary" onClick={()=>switchPersonaModal(true)}>Switch Persona</Button>
                </div>
                <div className="welcome-sign-out">
                    <Button className="link-primary" onClick={signOut}>Sign Out</Button>
                </div>
            </div>
            
        </>
    )
}

const ProfileMenu = () => {
    const [{ currentPersona, isShowSwitchPersonaModal }] = useStateWithPaths([`${PERSONAL_SETTING_REDUCER}`])

    const { switchPersonaModal, switchPersona } = useActions({
        switchPersonaModal: personalSettingActions.switchPersonaModal,
        switchPersona: personalSettingActions.switchPersona,
    });

    //const [isOpenPopup, setIsOpenPopup] = useState(false)
    
    const confirmCallback = (switchPersonaTo) => {
        switchPersona(switchPersonaTo)
        switchPersonaModal(false)
    }

    return (
        <div className="profile-menu-container">
            <Popup
                trigger={
                    <Button circular className="no-padding-margin" >
                        <Image src={UserProfile} />
                    </Button>
                }
                content={PopupContent()}
                pinned
                basic
                offset='0,20'
                on='click'
                // open={isOpenPopup}
                position='bottom right'
                hideOnScroll={true}
            />
            <PersonaSwitcher
                isOpen={isShowSwitchPersonaModal}
                confirmCallback={confirmCallback}
                cancelCallback={() => switchPersonaModal(false)}
            />
        </div>
    )
}

export default ProfileMenu;