import React from 'react';
import { Header } from 'semantic-ui-react';
import { Link } from "react-router-dom";

function CustomHeader(props) {

	const headerstyle = {
		"flex": 4,
		"paddingLeft": "43px",
		"paddingTop": "10px",
		"height": "100%",
		"fontFamily": 'IBM Plex Sans',
	}

	const homestyle = {
		"alignSelf": "flex-end",
		"paddingRight": "40px",
		"paddingTop": "10px",
		"height": "100%",
		"fontFamily": 'IBM Plex Sans',
		"color": '#DE1782'
	}

	return (<div className="summary-header">
		<Header as='h3' style={headerstyle} textAlign='left' >{props.msg}</Header>
		<Link to={props.backlink}>
			<Header as='h3' style={homestyle} >Back to Home</Header>
		</Link>
	</div>);
}
export default CustomHeader;