import _ from 'lodash'
import moment from 'moment'
import { LAMBDA_API_INSTANCE } from './serverInstances/lambdaServer'

const CONTROLLER_NAME = '/processing'

export const postCdu1Chart = (period) => {
  const startDate = _.map(period, (a) => moment(a.startDate).format('YYYY-MM-DD')).toString()
  const endDate = _.map(period, (a) => moment(a.endDate).format('YYYY-MM-DD')).toString()
  const formatEndDate = moment(endDate).endOf('month').format('YYYY-MM-DD').toString()
  const bodyParams = {
    startDate: startDate,
    endDate: formatEndDate,
    unit: 'CDU-1',
  }
  return LAMBDA_API_INSTANCE.post(`${CONTROLLER_NAME}/chart`, bodyParams)
}

export const postCdu2Chart = (period) => {
  const startDate = _.map(period, (a) => moment(a.startDate).format('YYYY-MM-DD')).toString()
  const endDate = _.map(period, (a) => moment(a.endDate).format('YYYY-MM-DD')).toString()
  const formatEndDate = moment(endDate).endOf('month').format('YYYY-MM-DD').toString()
  const bodyParams = {
    startDate: startDate,
    endDate: formatEndDate,
    unit: 'CDU-2',
  }
  return LAMBDA_API_INSTANCE.post(`${CONTROLLER_NAME}/chart`, bodyParams)
}

export const postCdu3Chart = (period) => {
  const startDate = _.map(period, (a) => moment(a.startDate).format('YYYY-MM-DD')).toString()
  const endDate = _.map(period, (a) => moment(a.endDate).format('YYYY-MM-DD')).toString()
  const formatEndDate = moment(endDate).endOf('month').format('YYYY-MM-DD').toString()
  const bodyParams = {
    startDate: startDate,
    endDate: formatEndDate,
    unit: 'CDU-3',
  }
  return LAMBDA_API_INSTANCE.post(`${CONTROLLER_NAME}/chart`, bodyParams)
}
