import React, { Component } from 'react';
import axios from 'axios';
import { Table, Divider, Grid, Image, TableCell, Dimmer } from 'semantic-ui-react';
import queryString from 'query-string';
import CustomHeader from '../../components/header/customHeader';
import ToHeader from '../../components/header/toHeader';
import SearchBar from '../../components/header/searchBar';
import LeftMenu from '../../components/left-menu/leftMenuGraph';
import crudeCodes from '../../data/crude-code-old';
//import Cdu3nav from '../assets/images/cdu3nav.svg';
import CrudeOn from '../../assets/images/crude-on2.svg';
import Graph from '../../assets/images/crude-graph.svg';
import GraphOn1 from '../../assets/images/crude-graph-on1.svg';
import GraphOn2 from '../../assets/images/crude-graph-on2.svg';
import Table_data from '../../assets/images/crude-table.svg';
//import Table_data_overlay from '../assets/images/crude-table-overlay.svg';

const crudeProps = ["name", "country", "assay", "process", "cargo", "kerogen", "fouling", "density", "tan", "sulfur", "sulfurtan", "mercaptan", "asphaltene", "nitrogen"];
//const tableHeader = { color: 'var(--secondary)', borderLeft: 'none', borderBottom: '1px solid var(--secondary)' };
//const descUrl = 'https://api.dnalytiks.com/get_crude_info?keyword=';
//const mitiUrl = 'https://api.dnalytiks.com/mitigation/get_crude_info?keyword=';


class GraphView extends Component {

	constructor(props) {
		super(props);
		this.state = {
			grpIndex: 0,
			grpImgList: [Graph, GraphOn1, GraphOn2],			
			crudeCd: '',
			crudeSummary: {},
			camRef: [],
			propKeys: [],
			isLoaded: false,
			active: false,
			curr: {},
			filtered: []
		};
		for (let crudeProp of crudeProps) {
			this.state[crudeProp + 'Desc'] = [];
			this.state[crudeProp + 'Mitigation'] = []
		}
	}

	async componentDidMount() {
		var params = queryString.parse(this.props.location.search);
		console.log(params.crudeName);
		this.setState({ crudeCd: crudeCodes.data[1][params.crudeName] });
		await this.getCamRef(params.crudeName);
		await this.getCrudeSummary(params.crudeName);
		let msg = `Graph Data for ${this.state.crudeCd} - ${params.crudeName}, ${this.state.crudeSummary.Country}`;
		let blendUrl = "/graphBlend?crudeName="+params.crudeName;
		let risksUrl = "/graphRisk?crudeName="+params.crudeName;
		this.setState({ blendUrl: blendUrl });
		this.setState({ risksUrl: risksUrl });
		this.setState({ headermsg: msg });
		this.setState({ isLoaded: true });
	}


	//Data fetching methods
	getCamRef = async () => {
		await axios.get('https://iu2pa7hq67.execute-api.us-east-1.amazonaws.com/dev/camref').then(
			res => {
				this.setState({
					camRef: res.data
				});
			}).catch(
				error => {
					console.log(error)
				})
	}

	getCrudeSummary = async (param) => {
		await axios.get('https://iu2pa7hq67.execute-api.us-east-1.amazonaws.com/dev/crudedata?name=' + param).then(
			res => {
				var csummary = res.data.data[0];
				this.setState({
					propKeys: Object.keys(csummary),
					crudeSummary: csummary
				});
			}).catch(
				error => {
					console.log(error)
				})
	}

	onClickChangeGrpImg = () => {
		if(this.state.grpIndex+1 === this.state.grpImgList.length) {
			this.setState({ grpIndex: 0 }); 
		} else {
			this.setState({ grpIndex: this.state.grpIndex+1 });
		}		
	}


	//Render methods
	filterProps = (params) => {
		this.setState({ filtered: params })
	}

	handleHide = () => this.setState({ active: false });

	handleShow = curr => {
		this.setState({ active: true, curr: curr });
	}


	render() {
		let { active } = this.state;
		return (
			<div className='Mitigation'>
				<Dimmer.Dimmable dimmed={active}>
					<ToHeader></ToHeader>
					{/* <SearchBar search='intelligent' /> */}
					<CustomHeader backlink="/" msg={this.state.headermsg}></CustomHeader>
					<Divider style={{ height: '3px' }} fitted />

					{!this.state.isLoaded
						? (<div style={{ margin: 'auto', marginTop: '250px' }} className="ui active centered inline loader"></div>)
						:
						(
							<div className='MitiMain'>
								<LeftMenu crudeProps={this.state.propKeys} filterProps={this.filterProps}></LeftMenu>
								<div className='MitiRight'>
									<Grid style={{ marginTop: '0px', paddingTop: '27px' }} columns={3} textAlign='left'>
										<Grid.Column className='headerText' width={3}>
											<p style={{ marginBottom: '5px' }}><a href={this.state.risksUrl}>RISKS</a></p>
										</Grid.Column>
										<Grid.Column className='headerText' width={5}>
											FUTURE MONTH REVIEW
										</Grid.Column>
										<Grid.Column className='headerText' style={{ color: 'var(--secondary)' }} width={8}>
											<p style={{ marginBottom: '5px' }}>STATISTICAL ANALYSIS</p>
											<hr style={{ width: '190px', float: 'left', margin: '0px', border: '1px solid var(--secondary)' }}></hr>
										</Grid.Column>
									</Grid>

									<Table style={{ border: 'none' }} basic celled>
										<Table.Body>
											<Table.Row style={{ maxWidth: '30%', fontWeight: '600px', color: 'rgba(0, 0, 0, 0.56)' }}>
												<TableCell className='innerTable'>
											      <Grid style={{ paddingLeft: '50px', paddingRight: '50px' }}>
													 <Grid.Row verticalAlign='bottom'>
														<Grid.Column> 	
														  <Image style={{ float: 'right' }} href={this.state.blendUrl} src={CrudeOn} />
														</Grid.Column>
													  </Grid.Row>												  
												    <Grid.Row>
														<Image src={this.state.grpImgList[this.state.grpIndex]} onClick={this.onClickChangeGrpImg} />
													</Grid.Row>
												  </Grid>  
												</TableCell>		
											</Table.Row>
											<Table.Row>
												<TableCell className='innerTable'>
												  <Grid style={{ paddingLeft: '50px', paddingRight: '50px' }}>
												    <Grid.Row>
														<Image src={Table_data} />
													</Grid.Row>
												  </Grid>  
												</TableCell>		
											</Table.Row>											
										</Table.Body>
									</Table>

								</div>
							</div>)}
				</Dimmer.Dimmable>
			</div>
		);
	}
}

export default GraphView