import _ from 'lodash'
import jwt from 'jsonwebtoken'

import { getUserGroupFromIdToken } from '../../helpers/string'

import {
  // authenticate,
  validateAPIToken,
  getUserRoles,
  getRoleList,
  getUserList,
  getDepartmentList,
  postRegisterUser,
  putUpdateUser,
  deleteUser,
  startSSO,
  logoutSSO,
  getUserInfo,
  refreshSSOTokens,
  postNotifyNewUser,
  logHistory,
} from '../../services/common/serviceSession'
import browserHistory, { routeTo } from '../../helpers/history'
import { fetchStart, fetchSuccess, fetchError } from './actionFetch'
import { switchPersona } from './actionPersonalSetting'

export const SESSION_STATUS = {
  START: 'start',
  SUCCESS: 'success',
  FAILED: 'failed',
}

const TOKEN_REFRESH_MS = 15 * 60 * 1000

export const SESSION_AUTHENTICATION = 'SESSION_AUTHENTICATION'
export const sessionAuthentication = () => {
  return (dispatch) => {
    dispatch(fetchStart(SESSION_AUTHENTICATION))

    // .then(tokens => {
    //   const { id_token } = tokens
    //   return validateAPIToken(id_token).then(() => tokens)
    // })

    //TODO: for future check user with api
    // .then(() =>
    //   getUserInfo().catch(reason => {
    //     if (_.get(reason, 'response.status') === 503) {
    //       routeTo({
    //         path: '/maintenance'
    //       })
    //     } else {
    //       throw Error('User is not found/registered in Thai Oil')
    //     }
    //   })
    // )
    startSSO()
      .then(storeTokens)
      .then((data) =>
        dispatch(
          sessionAuthenticationSuccess({
            data,
            idToken: localStorage.getItem('id_token'),
            accessToken: localStorage.getItem('access_token'),
          })
        )
      )
      .catch((reason) => {
        localStorage.clear()
        window.history.pushState(null, '', '/')
        dispatch(
          sessionAuthenticationFailed({
            reason,
          })
        )
        dispatch(fetchError(SESSION_AUTHENTICATION))
      })
    dispatch(sessionAuthenticationStart())
  }
}

export const SESSION_AUTHENTICATION_START = 'SESSION_AUTHENTICATION_START'
export const sessionAuthenticationStart = () => {
  return {
    type: SESSION_AUTHENTICATION_START,
  }
}

export const SESSION_AUTHENTICATION_FAILED = 'SESSION_AUTHENTICATION_FAILED'
export const sessionAuthenticationFailed = ({ reason }) => {
  return {
    type: SESSION_AUTHENTICATION_FAILED,
    error: true,
    payload: reason,
  }
}

export const SESSION_AUTHENTICATION_SUCCESS = 'SESSION_AUTHENTICATION_SUCCESS'
export const sessionAuthenticationSuccess = ({ data, idToken, accessToken }) => {
  let session = jwt.decode(idToken)
  return (dispatch) => {
    dispatch({
      type: SESSION_AUTHENTICATION_SUCCESS,
      payload: {
        data,
        idToken,
        accessToken,
        ...session,
      },
    })
    //setupRouteLog()

    const userGroups = getUserGroupFromIdToken(idToken)
    dispatch(switchPersona(userGroups[0]))

    routeTo({
      path: '/',
    })
    // localStorage.setItem('token', JSON.stringify(data.access_token))
  }
}

export const SESSION_LOGOUT = 'SESION_LOGOUT'
export const sessionLogout = () => {
  return (dispatch) => {
    localStorage.clear()
    dispatch({
      type: SESSION_LOGOUT,
    })
    window.location.href = '/logout'
  }

  // return (dispatch) => {
  // 	localStorage.clear()
  // 	dispatch({
  // 		type: SESSION_LOGOUT
  // 	})
  // 	logoutSSO()
  // }
}

const storeTokens = (tokens) => {
  const { idToken, accessToken, refresh_token } = tokens
  if (idToken) {
    localStorage.setItem('id_token', idToken)

    if (refresh_token) localStorage.setItem('refresh_token', refresh_token)
    if (accessToken) localStorage.setItem('access_token', accessToken)
  } else {
    throw Error('Id token missing')
  }

  return idToken
}

const refreshTokenTimeout = (refreshToken, dispatch) => (tokens) => {
  setTimeout(() => {
    refreshSSOTokens(refreshToken)
      .then(refreshTokenTimeout(refreshToken, dispatch))
      .then(storeTokens)
      .then((access_token) =>
        dispatch({
          type: SESSION_AUTHENTICATION_SUCCESS,
          payload: {
            access_token,
          },
        })
      )
  }, TOKEN_REFRESH_MS)
  return tokens
}

export const SESSION_START = 'SESSION_START'
export const sessionStart = () => {
  return (dispatch, getState) => {
    // const { session: {access_token} } = getState()
    // validateAPIToken(access_token)
    // .then(() => {
    // 	dispatch(fetchUserRoles())
    // })
    // .catch(() => {
    // 	dispatch(sessionLogout())
    // })

    const id_token = localStorage.getItem('id_token')
    const refresh_token = localStorage.getItem('refresh_token')
    // const fetchUserInfo = () => {
    //   return getUserInfo().catch(reason => {
    //     if (_.get(reason, 'response.status') === 503) {
    //       routeTo({
    //         path: '/maintenance'
    //       })
    //     } else {
    //       throw Error('User is not found/registered in ThaiOil')
    //     }
    //   })
    // }

    validateAPIToken(id_token, refresh_token)
      .then(refreshTokenTimeout(refresh_token, dispatch))
      .then(storeTokens)
      //.then(fetchUserInfo)
      .catch(() => {
        if (refresh_token) {
          return refreshSSOTokens(refresh_token)
            .then(refreshTokenTimeout(refresh_token, dispatch))
            .then(storeTokens)
          //.then(fetchUserInfo)
        }
        throw Error('No refresh_token')
      })
      .then((data) => {
        dispatch({
          type: SESSION_AUTHENTICATION_SUCCESS,
          payload: data,
        })
      })
      // .then(() => setupRouteLog())
      //.then(() => dispatch(fetchUserRoles()))
      .catch(() => {
        dispatch(sessionLogout())
      })
    dispatch({ type: SESSION_START })

    // const refresh_token = localStorage.getItem('refresh_token')
    // startSSO(refresh_token)
    // 	.then((tokens) => {
    // 		const {
    // 			id_token
    // 		} = tokens
    // 		return validateAPIToken(id_token)
    // 			.then(() => tokens)
    // 	})
    // 	.then((tokens) => {
    // 		const {
    // 			id_token,
    // 			refresh_token
    // 		} = tokens
    // 		if (id_token) {
    // 			localStorage.setItem('id_token', id_token)
    // 		  if (refresh_token) localStorage.setItem('refresh_token', refresh_token)
    // 		} else {
    // 			throw Error('Id token missing')
    // 		}
    // 		return true
    // 	})
    // 	.then(() => getUserInfo())
    // 	.then((data) => dispatch(sessionAuthenticationSuccess({ data })))
    // 	.then(() => dispatch(fetchSuccess(SESSION_AUTHENTICATION)))
    // 	.then(() => {
    // 		dispatch(fetchUserRoles())
    // 	})
    // 	.catch((e) => {
    // 		console.error(e)
    // 		dispatch(fetchError(SESSION_AUTHENTICATION))
    // 		dispatch(sessionLogout())
    // 	})
  }
}

export const FETCH_USER_ROLES = 'FETCH_USER_ROLES'
export const fetchUserRoles = () => {
  return (dispatch) => {
    getUserRoles().then((roles) => {
      dispatch({
        type: FETCH_USER_ROLES,
        payload: roles,
      })
    })
  }
}

export const FETCH_ROLE_LIST = 'FETCH_ROLE_LIST'
export const fetchRoleList = () => {
  return (dispatch) => {
    getRoleList().then((roles) => {
      dispatch({
        type: FETCH_ROLE_LIST,
        payload: roles,
      })
    })
  }
}

export const FETCH_USER_LIST = 'FETCH_USER_LIST'
export const fetchUserList = () => {
  return (dispatch) => {
    getUserList().then((users) => {
      dispatch({
        type: FETCH_USER_LIST,
        payload: users,
      })
    })
  }
}

export const FETCH_DEPARTMENT_LIST = 'FETCH_DEPARTMENT_LIST'
export const fetchDepartmentList = () => {
  return (dispatch) => {
    getDepartmentList().then((departments) => {
      dispatch({
        type: FETCH_DEPARTMENT_LIST,
        payload: departments,
      })
    })
  }
}

export const REGISTER_USER = 'REGISTER_USER'
export const registerUser = ({ email, name, departmentId, roleIds }) => {
  return (dispatch) => {
    dispatch({
      type: REGISTER_USER,
      payload: {},
    })
    if (email) {
      postRegisterUser({
        email,
        name,
        departmentId,
        roleIds,
      }).then((user) => {
        dispatch({
          type: REGISTER_USER,
          payload: user,
        })
      })
    }
  }
}

export const UPDATE_USER = 'UPDATE_USER'
export const updateUser = ({ email, name, departmentId, roleIds }) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_USER,
      payload: {},
    })
    const state = getState()
    const userRoles = _.get(state, 'session.roleList')
    if (email) {
      putUpdateUser({
        email,
        name,
        departmentId,
        roleIds,
      }).then((user) => {
        dispatch({
          type: UPDATE_USER,
          payload: {
            ...user,
            role_names: _.map(user.role_ids, (id) =>
              _.get(
                _.find(userRoles, {
                  id,
                }),
                'name'
              )
            ),
          },
        })
      })
    }
  }
}

export const REMOVE_USER = 'REMOVE_USER'
export const removeUser = ({ email }) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_USER,
      payload: {},
    })
    if (email) {
      deleteUser({
        email,
      }).then((user) => {
        dispatch({
          type: REMOVE_USER,
          payload: user,
        })
      })
    }
  }
}

export const NOTIFY_NEW_USER = 'NOTIFY_NEW_USER'
export const notifyNewUser = ({ email }) => {
  return (dispatch) => {
    if (email) {
      postNotifyNewUser({
        email,
      }).then((user) => {
        dispatch({
          type: NOTIFY_NEW_USER,
          payload: user,
        })
      })
    }
  }
}

let prevRoute
export const setupRouteLog = () => {
  logHistory({ path: window.location.pathname })
  prevRoute = window.location.pathname
  browserHistory.listen((location, action) => {
    if (prevRoute !== location.pathname) {
      logHistory({ path: location.pathname })
    }
    prevRoute = location.pathname
  })
}
