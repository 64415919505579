
import React from 'react'

const ResultContainer = (props) => {

    const { label, type, icon } = props;

    return (
        <span className="result-container-wrapper center justify-center">
            {
                icon && <span className="main-icon">{icon}</span>
            }           
            <span className="main-label">{label}</span>
        </span>
    )
}

export default ResultContainer;