export default {
    "data": [
        {
			code: "AMB",
			name: "Arab Medium Banoco"
		},
        {
			code: "CHP",
			name: "Champion"
		},	
        {
			code: "SOK",
			name: "Sokol"
		}
    ]
}