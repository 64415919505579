import React from 'react'
import _ from 'lodash'
import { Grid, Segment } from 'semantic-ui-react'
import NoDataBlock from '../../../../components/no-data-block'

const LabResult = (props) => {
  const data = _.get(props.data, 'data')

  let totalColumn = _.isEmpty(data.headers) ? 0 : Object.keys(data.headers).length
  return (
    <div className={`lab-result mar-x-2`}>
      <Segment className={`segment-container`}>
        <div className={`flex column pad-3`}>
          {_.isEmpty(data.headers) ? (
            <NoDataBlock />
          ) : (
            <Grid>
              <Grid.Row columns={totalColumn}>
                {Object.keys(data.headers).map(function (currentKey, index) {
                  return (
                    <Grid.Column key={index}>
                      <div className={`flex column center pad-y-1`}>
                        <div className={`crude-code`}>
                          {index === totalColumn - 1 ? '' : currentKey}
                        </div>
                        <div className={`crude-name`}>
                          {index === totalColumn - 1 ? '' : data.headers[currentKey]}
                        </div>
                      </div>
                    </Grid.Column>
                  )
                })}
              </Grid.Row>
              {_.map(data.data, (item, index) => (
                <Grid.Row columns={totalColumn}>
                  {Object.keys(item).map(function (currentKey, index) {
                    return (
                      <Grid.Column key={currentKey}>
                        <div className={`flex column center pad-y-1`}>
                          {index === totalColumn - 1 ? (
                            <div id={index}>
                              <span
                                className={`ui label span-result ${
                                  item[currentKey] == 'Compatible' ? 'green' : 'red'
                                }
                          }`}
                              >
                                {item[currentKey]}
                              </span>
                            </div>
                          ) : (
                            <div id={index} className={`crude-data`}>
                              {item[currentKey] > 0 ? item[currentKey] : '--'}{' '}
                              <span className={`grey`}>%</span>
                            </div>
                          )}
                        </div>
                      </Grid.Column>
                    )
                  })}
                </Grid.Row>
              ))}
            </Grid>
          )}
        </div>
      </Segment>
    </div>
  )
}

export default LabResult
