import React, { useState, useEffect, useReducer } from "react";
import _ from "lodash";

//Components
import { Grid, Header, Segment, Dropdown, Table } from "semantic-ui-react";
import NoDataBlock from "../../../../components/no-data-block";
import TableSortBy from "../../../../components/table-sort-by";

//Redux
import { PERSONAL_SETTING_REDUCER } from "../../../../reducers/common/personalSettingReducer";
import { MASTER_DATA_REDUCER } from "../../../../reducers/common/masterDataReducer";
import { STATISTICAL_ANALYSIS_PAGE_REDUCER } from "../../../../reducers/statisticalAnalaysisPageReducer";
import * as MasterDataActions from "../../../../actions/common/actionMasterData";

//Hooks
import { useStateWithPaths, useActions } from "../../../../hooks/useConnect";

const BaseCrude = (props) => {
  const getData = props.data;
  return (
    <Grid padded>
      <Grid.Row columns={2} verticalAlign="bottom">
        <Grid.Column textAlign="left">
          <Header size="small" textAlign="left">
            BASE CRUDE
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Segment className={`base-crude-container width-100`}>
        <Grid.Row columns={12}>
          <div className={`pad-x-1 pad-y-3`}>
            {_.map(...getData, (item, i) => (
              <>
                <Header>
                  {item.Crude_Code} {"-"} {item.Crude_Name}
                </Header>

                <div className={`base-crude-scrollable`}>
                  <Table
                    sortable
                    className={`similiar-crude-container no-border`}
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell
                          style={{ minWidth: "86px" }}
                          className={`base-crude-header`}
                        ></Table.HeaderCell>
                        {_.map(
                          _.keys(item),
                          (objectKeys) =>
                            objectKeys !== "Crude_Code" &&
                            (objectKeys !== "Crude_Name" ? (
                              <Table.HeaderCell
                                style={{ minWidth: "128px" }}
                                className={`base-crude-header`}
                              >
                                {_.startCase(objectKeys)}
                              </Table.HeaderCell>
                            ) : (
                              ""
                            ))
                        )}
                        <Table.HeaderCell
                          style={{ minWidth: "86px" }}
                          className={`base-crude-header`}
                        ></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row key={i}>
                        <Table.Cell
                          id={i}
                          style={{ minWidth: "86px" }}
                        ></Table.Cell>
                        {Object.keys(item).map(function (currentKey) {
                          return currentKey !== "Crude_Code" &&
                            currentKey !== "Crude_Name" ? (
                            <Table.Cell id={i} style={{ minWidth: "128px" }}>
                              {item[currentKey]}
                            </Table.Cell>
                          ) : (
                            ""
                          );
                        })}
                         <Table.Cell
                          id={i}
                          style={{ minWidth: "86px" }}
                        ></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>

                {/* <div className={`flex space-evenly mar-top-2 `}>
                  <div className={`base-crude-table-key`} />
                  {_.map(
                    _.keys(item),
                    (objectKeys) =>
                      objectKeys !== "Crude_Code" &&
                      (objectKeys !== "Crude_Name" ? (
                        <div className={`base-crude-table-key`}>
                          {_.startCase(objectKeys)}
                        </div>
                      ) : (
                        ""
                      ))
                  )}
                  <div className={`base-crude-table-key`} />
                </div>

                <div
                  className={`flex center space-evenly mar-top-1 base-crude-table-value-container`}
                >
                  <div className={`base-crude-table-value`} />
                  {Object.keys(item).map(function (currentKey) {
                    return currentKey !== "Crude_Code" &&
                      currentKey !== "Crude_Name" ? (
                      <div className={`base-crude-table-value`}>
                        {_.isEmpty(item[currentKey]) ? "N.A" : item[currentKey]}
                      </div>
                    ) : (
                      ""
                    );
                  })}
                  <div className={`base-crude-table-value`} />
                </div> */}
              </>
            ))}
          </div>
        </Grid.Row>
      </Segment>
    </Grid>
  );
};

const SimiliarCrude = (props) => {
  // const [similiarCrude, setSimiliarCrude] = useState({
  //   column: null,
  //   data: props.data,
  //   direction: null,
  // });
  // console.log(props.data);

  const [state, dispatch] = useReducer(TableSortBy, {
    column: null,
    data: props.data,
    direction: null,
  });
  const { column, data, direction } = state;
  return (
    <Grid padded>
      <Grid.Row columns={2} verticalAlign="bottom">
        <Grid.Column textAlign="left">
          <Header>SIMILAR CRUDE</Header>
        </Grid.Column>
      </Grid.Row>
      {!_.isEmpty(data[0]) ? (
        <div class={`similiar-crude-scrollable`}>
          <Table sortable className={`similiar-crude-container`}>
            <Table.Header>
              <Table.Row>
                {_.map(data[0], (value, objectKeys) =>
                  _.startCase(objectKeys) !== "Crude Code" &&
                  _.startCase(objectKeys) !== "Total Score" ? (
                    <Table.HeaderCell
                      style={{ width: "128px" }}
                      sorted={column === objectKeys ? direction : null}
                      onClick={() =>
                        dispatch({ type: "CHANGE_SORT", column: objectKeys })
                      }
                    >
                      {_.startCase(objectKeys)}
                    </Table.HeaderCell>
                  ) : _.startCase(objectKeys) === "Total Score" ? (
                    <Table.HeaderCell
                      style={{ width: "128px" }}
                      sorted={direction ? direction : "descending"}
                      className={`${direction} sorted`}
                      onClick={() =>
                        dispatch({ type: "CHANGE_SORT", column: objectKeys })
                      }
                    >
                      {_.startCase(objectKeys)}
                    </Table.HeaderCell>
                  ) : (
                    ""
                  )
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(data, (x, i) => (
                <Table.Row key={i}>
                  {Object.keys(x).map(function (currentKey) {
                    const getTotalScore =
                      x.Total_Score >= 90 ? "textGreen" : "textYellow";
                    return currentKey === "Crude_Code" ? (
                      <Table.Cell id={i} style={{ width: "128px" }}>
                        <div>{x[currentKey]}</div>
                        <div className="crude-value">{x.Crude_Name}</div>
                      </Table.Cell>
                    ) : currentKey !== "Crude_Name" ? (
                      <Table.Cell
                        id={i}
                        className={`${getTotalScore}`}
                        style={{ width: "128px" }}
                      >
                        {x[currentKey]}
                      </Table.Cell>
                    ) : (
                      ""
                    );
                  })}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      ) : (
        <NoDataBlock />
      )}
    </Grid>
  );
};

const FindSimiliarCrude = () => {
  const [{ searchSimiliarCrude, searchResult }] = useStateWithPaths([
    `${STATISTICAL_ANALYSIS_PAGE_REDUCER}`,
  ]);
  const [{ currentPersona }] = useStateWithPaths([
    `${PERSONAL_SETTING_REDUCER}`,
  ]);
  const [{ similiarCrudeDataSources }] = useStateWithPaths([
    `${MASTER_DATA_REDUCER}`,
  ]);

  const { getMasterSimiliarCrudeDataSources } = useActions({
    getMasterSimiliarCrudeDataSources:
      MasterDataActions.getMasterSimiliarCrudeDataSources,
  });

  const [dataSource, setDataSource] = useState(searchSimiliarCrude.dataSource);

  useEffect(() => {
    getMasterSimiliarCrudeDataSources(currentPersona);
  }, [getMasterSimiliarCrudeDataSources, currentPersona]);

  const searchSimiliarCrudeResult = _.get(searchResult, "searchSimiliarCrude");

  // if (
  //   !_.get(searchSimiliarCrudeResult, "data") ||
  //   _.isEmpty(_.get(searchSimiliarCrudeResult, "data"))
  // ) {
  //   return <NoDataBlock />;
  // }

  const data = _.get(searchResult.searchSimiliarCrude, "data");

  const errorMessage = data[0].errorMessage;
  const baseCrudeData = _.map(data, (item) => item.baseCrude);
  const similiarCrudeData = _.map(data, (item) => item.similiarCrude);

  return (
    <div className="section section-find-similiar-crude">
      {_.isEmpty(errorMessage) ? (
        <>
          <BaseCrude
            data={baseCrudeData}
            similiarCrudeDataSources={similiarCrudeDataSources}
            dataSource={dataSource}
            setDataSource={setDataSource}
          />
          <div className={`mar-top-3`}>
            <SimiliarCrude
              data={_.flattenDeep(similiarCrudeData)}
              similiarCrudeDataSources={similiarCrudeDataSources}
              dataSource={dataSource}
              setDataSource={setDataSource}
            />
          </div>
        </>
      ) : (
        <div className={`loader-message mar-2 center`}>{errorMessage}</div>
      )}
    </div>
  );
};

export default FindSimiliarCrude;
