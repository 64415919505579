import _ from 'lodash'
import moment from 'moment'
import * as services from '../services/serviceStatisticalAnalysis'
import * as statisticalAnalysisConstants from '../containers/views/statistical-analysis-page/helpers/constants'
import { fetchStart, fetchSuccess, fetchError } from './common/actionFetch'

//#region Search Panel

export const STATISTICAL_ANALYSIS_SEARCH_UPDATE = 'STATISTICAL_ANALYSIS_SEARCH_UPDATE'
export const STATISTICAL_ANALYSIS_SIMILIAR_CRUDE = 'STATISTICAL_ANALYSIS_SIMILIAR_CRUDE'
export const STATISTICAL_ANALYSIS_COMPARE_CRUDE = 'STATISTICAL_ANALYSIS_COMPARE_CRUDE'
export const STATISTICAL_ANALYSIS_COMPARE_BLEND = 'STATISTICAL_ANALYSIS_COMPARE_BLEND'

export const STATISTICAL_ANALYSIS_TREND_CHART = 'STATISTICAL_ANALYSIS_TREND_CHART'
export const setFreeformTrend = (
  currentPersona,
  period,
  color1,
  color2,
  color3,
  color4,
  color5,
  dataSource1,
  dataSource2,
  dataSource3,
  dataSource4,
  dataSource5,
  unit1,
  unit2,
  unit3,
  unit4,
  unit5,
  stream1,
  stream2,
  stream3,
  stream4,
  stream5,
  property1,
  property2,
  property3,
  property4,
  property5
) => {
  return (dispatch) => {
    dispatch(fetchStart(STATISTICAL_ANALYSIS_TREND_CHART))
    services
      .postFreeformTrend(
        currentPersona,
        period,
        color1,
        color2,
        color3,
        color4,
        color5,
        dataSource1,
        dataSource2,
        dataSource3,
        dataSource4,
        dataSource5,
        unit1,
        unit2,
        unit3,
        unit4,
        unit5,
        stream1,
        stream2,
        stream3,
        stream4,
        stream5,
        property1,
        property2,
        property3,
        property4,
        property5
      )
      .then((response) =>
        dispatch({
          type: STATISTICAL_ANALYSIS_TREND_CHART,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(STATISTICAL_ANALYSIS_TREND_CHART)))
      .catch(() => dispatch(fetchError(STATISTICAL_ANALYSIS_TREND_CHART)))
  }
}

export const searchStatisticalAnalysis = (searchObject) => {
  return (dispatch, getState) => {
    dispatch({
      type: STATISTICAL_ANALYSIS_SEARCH_UPDATE,
      payload: searchObject,
    })
    if (searchObject.options === statisticalAnalysisConstants.SEARCH_OPTIONS.FindSimiliarCrude) {
      dispatch(fetchStart(STATISTICAL_ANALYSIS_SIMILIAR_CRUDE))
      services
        .postFindSimiliarCrude({
          crudeCode: searchObject.crudeNameAbbreviation,
          persona: searchObject.currentPersona,
        })
        .then((response) =>
          dispatch({
            type: STATISTICAL_ANALYSIS_SIMILIAR_CRUDE,
            payload: response,
          })
        )
        .then(() => dispatch(fetchSuccess(STATISTICAL_ANALYSIS_SIMILIAR_CRUDE)))
        .catch(() => dispatch(fetchError(STATISTICAL_ANALYSIS_SIMILIAR_CRUDE)))
    } else if (searchObject.options === statisticalAnalysisConstants.SEARCH_OPTIONS.CompareCrude) {
      dispatch(fetchStart(STATISTICAL_ANALYSIS_COMPARE_CRUDE))

      services
        .postCompareCrude({
          dataSource: searchObject.dataSource,
          persona: searchObject.currentPersona,
          compareUnit: searchObject.compareUnit,
          crudeNameA: searchObject.crudeNameA,
          crudeNameB: searchObject.crudeNameB,
          crudeNameC: searchObject.crudeNameC,
          propertyList: [
            searchObject.compareProperty1,
            searchObject.compareProperty2,
            searchObject.compareProperty3,
            searchObject.compareProperty4,
            searchObject.compareProperty5,
          ],
          crudeCut: [
            {
              unit:
                _.isEmpty(searchObject.cutUnit1) || _.isEqual(searchObject.cutUnit1, '--')
                  ? 'N.A'
                  : searchObject.cutUnit1,
              fraction: searchObject.fraction1,
              property: searchObject.cutProperty1,
            },
            {
              unit:
                _.isEmpty(searchObject.cutUnit2) || _.isEqual(searchObject.cutUnit2, '--')
                  ? 'N.A'
                  : searchObject.cutUnit2,
              fraction: searchObject.fraction2,
              property: searchObject.cutProperty2,
            },
            {
              unit:
                _.isEmpty(searchObject.cutUnit3) || _.isEqual(searchObject.cutUnit3, '--')
                  ? 'N.A'
                  : searchObject.cutUnit3,
              fraction: searchObject.fraction3,
              property: searchObject.cutProperty3,
            },
            {
              unit:
                _.isEmpty(searchObject.cutUnit4) || _.isEqual(searchObject.cutUnit4, '--')
                  ? 'N.A'
                  : searchObject.cutUnit4,
              fraction: searchObject.fraction4,
              property: searchObject.cutProperty4,
            },
            {
              unit:
                _.isEmpty(searchObject.cutUnit5) || _.isEqual(searchObject.cutUnit5, '--')
                  ? 'N.A'
                  : searchObject.cutUnit5,
              fraction: searchObject.fraction5,
              property: searchObject.cutProperty5,
            },
          ],
        })
        .then((response) =>
          dispatch({
            type: STATISTICAL_ANALYSIS_COMPARE_CRUDE,
            payload: response,
          })
        )
        .then(() => dispatch(fetchSuccess(STATISTICAL_ANALYSIS_COMPARE_CRUDE)))
        .catch(() => dispatch(fetchError(STATISTICAL_ANALYSIS_COMPARE_CRUDE)))
    } else if (searchObject.options === statisticalAnalysisConstants.SEARCH_OPTIONS.CompareBlend) {
      dispatch(fetchStart(STATISTICAL_ANALYSIS_COMPARE_BLEND))
      services
        .postCompareBlend({
          blendDataSource: searchObject.blendDataSource,
          isSearchByCrudeBlendDate: searchObject.isSearchByCrudeBlendDate,
          persona: searchObject.currentPersona,
          blendDateA: searchObject.blendDateA,
          blendDateB: searchObject.blendDateB,
          operatingUnit: searchObject.operatingUnit,
          fraction: searchObject.fraction,
          unitBlendA: searchObject.blendDateB,
          unitBlendB: searchObject.blendDateB,
          blendList: blendDateOrBlendComposition(searchObject),
          propertyList: [
            searchObject.compareBlendProperty1,
            searchObject.compareBlendProperty2,
            searchObject.compareBlendProperty3,
            searchObject.compareBlendProperty4,
            searchObject.compareBlendProperty5,
          ],
        })
        .then((response) =>
          dispatch({
            type: STATISTICAL_ANALYSIS_COMPARE_BLEND,
            payload: response,
          })
        )
        .then(() => dispatch(fetchSuccess(STATISTICAL_ANALYSIS_COMPARE_BLEND)))
        .catch(() => dispatch(fetchError(STATISTICAL_ANALYSIS_COMPARE_BLEND)))
    }
  }
}

export const STATISTICAL_ANALYSIS_GET_SETTING_PAGE = 'STATISTICAL_ANALYSIS_GET_SETTING_PAGE'
export const getSettingPage = (currentPersona) => {
  return (dispatch) => {
    dispatch(fetchStart(STATISTICAL_ANALYSIS_GET_SETTING_PAGE))
    services
      .getSettingPageList(currentPersona)
      .then((response) =>
        dispatch({
          type: STATISTICAL_ANALYSIS_GET_SETTING_PAGE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(STATISTICAL_ANALYSIS_GET_SETTING_PAGE)))
      .catch(() => dispatch(fetchError(STATISTICAL_ANALYSIS_GET_SETTING_PAGE)))
  }
}

export const STATISTICAL_ANALYSIS_SAVE_SETTING_PAGE = 'STATISTICAL_ANALYSIS_SAVE_SETTING_PAGE'
export const saveSettingPage = (saveObject) => {
  return (dispatch) => {
    dispatch(fetchStart(STATISTICAL_ANALYSIS_SAVE_SETTING_PAGE))
    services
      .saveSettingPageList({
        options: saveObject.options,
        currentPersona: saveObject.currentPersona,
        inputList: saveObject.inputList,
      })
      .then((response) =>
        dispatch({
          type: STATISTICAL_ANALYSIS_SAVE_SETTING_PAGE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(STATISTICAL_ANALYSIS_SAVE_SETTING_PAGE)))
      .catch(() => dispatch(fetchError(STATISTICAL_ANALYSIS_SAVE_SETTING_PAGE)))
  }
}

const convertInputUnitsToBlendCompositionA = (searchObject) => {
  let blendComposition = []

  for (let count = 1; count <= 10; count++) {
    if (
      !_.isNull(
        _.get(searchObject, 'inputUnits.unit' + count) &&
          !_.isEmpty(_.get(searchObject, 'inputUnits.unit' + count)) &&
          !_.isNull(_.get(searchObject, 'inputUnits.unitPercent' + count)) &&
          _.get(searchObject, 'inputUnits.unitPercent' + count) !== ''
      )
    ) {
      blendComposition.push({
        crudeCode: _.get(searchObject, 'inputUnits.unit' + count + '.crudeCode'),
        percentage: _.get(searchObject, 'inputUnits.unitPercent' + count),
      })
    }
  }
  return blendComposition
}

const convertInputUnitsToBlendCompositionB = (searchObject) => {
  let blendComposition = []

  for (let count = 11; count <= 20; count++) {
    if (
      !_.isNull(
        _.get(searchObject, 'inputUnits.unit' + count) &&
          !_.isEmpty(_.get(searchObject, 'inputUnits.unit' + count)) &&
          !_.isNull(_.get(searchObject, 'inputUnits.unitPercent' + count)) &&
          _.get(searchObject, 'inputUnits.unitPercent' + count) !== ''
      )
    ) {
      blendComposition.push({
        crudeCode: _.get(searchObject, 'inputUnits.unit' + count + '.crudeCode'),
        percentage: _.get(searchObject, 'inputUnits.unitPercent' + count),
      })
    }
  }
  return blendComposition
}

const formatBlendDate = (blendDate) => {
  return moment(blendDate).format('YYYY-MM-DD')
}

const blendDateOrBlendComposition = (searchObject) => {
  let blendList = []

  if (searchObject.isSearchByCrudeBlendDate) {
    blendList.push(
      {
        name: 'Blend A',
        blendDate: formatBlendDate(searchObject.blendDateA),
      },
      {
        name: 'Blend B',
        blendDate: formatBlendDate(searchObject.blendDateB),
      }
    )
  } else
    blendList.push(
      {
        name: 'Blend A',
        unit: searchObject.unitBlendA,
        blendComposition: convertInputUnitsToBlendCompositionA(searchObject),
      },
      {
        name: 'Blend B',
        unit: searchObject.unitBlendB,
        blendComposition: convertInputUnitsToBlendCompositionB(searchObject),
      }
    )
  return blendList
}
