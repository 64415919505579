import React, { Component } from 'react';
import { Grid, Image, Dropdown } from 'semantic-ui-react';
import searchbox from '../../assets/images/searchbox.svg';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';

const crudeProps = ["Density@15C, kg/liter", "TAN, mgKOH/g", "Asphaltene, %wt", "Basic Nitrogen. ppmw", "Viscosity, V50"];
const optionsBlend = [
  { key: 1, text: 'Density@15C, kg/liter', value: 1, disabled: true},
  { key: 2, text: 'Crude Sulphur (%wt)', value: 2},
  { key: 3, text: 'TAN, mgKOH/g', value: 3, disabled: true },
  { key: 4, text: 'TAN/Sulphur, %wt', value: 4 },
  { key: 5, text: 'Asphaltene, %wt', value: 5, disabled: true },
  { key: 6, text: 'Basic Nitrogen. ppmw', value: 6, disabled: true },
  { key: 7, text: 'Viscosity, V50', value: 7, disabled: true }
];
const optionsCrude = [
  { key: 1, text: 'Density@15C, kg/liter', value: 1, disabled: true},
  { key: 2, text: 'Crude Sulphur (%wt)', value: 2},
  { key: 3, text: 'TAN, mgKOH/g', value: 3, disabled: true },
  { key: 4, text: 'TAN/Sulphur, %wt', value: 4 },
  { key: 5, text: 'Asphaltene, %wt', value: 5, disabled: true },
  { key: 6, text: 'Basic Nitrogen. ppmw', value: 6, disabled: true },
  { key: 7, text: 'Viscosity, V50', value: 7, disabled: true },
  { key: 8, text: 'Max % in a blend', value: 8 }
];

const leftMenu = {
    flex: 3,
    paddingLeft: '40px'
}

export default class LeftMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
			page: '',
            selected: Array(this.props.crudeProps.length).fill(true)
        }
    }

    componentDidMount() {
        this.assignPage();
    }

    assignPage() {
		let url = window.location.href;
		console.log(url);
		console.log(url.indexOf('graphBlend'));
		console.log(url.indexOf('graphCrude'));
		if(url.indexOf('graphCrude') > 0)
			this.setState({ page: 'Crude' });
		else if(url.indexOf('graphBlend') > 0)
			this.setState({ page: 'Blend' });
		console.log(this.state.page);
	}	

    renderParams() {
        let rows = [];
		console.log(this.state.page);
        crudeProps.forEach((crudeProp, index) => {
				if(index === 0) {
					if(this.state.page === 'Crude')
						rows.push(<FormControlLabel control={<Dropdown direction='down' color='primary' text={crudeProp} selection options={optionsCrude} key={index} />}></FormControlLabel>)
					if(this.state.page === 'Blend')
						rows.push(<FormControlLabel control={<Dropdown direction='down' color='primary' text={crudeProp} selection options={optionsBlend} key={index} />}></FormControlLabel>)
				}
				else	
					rows.push(<FormControlLabel control={<Dropdown color='primary' text={crudeProp} selection key={index} />}></FormControlLabel>)
				rows.push(<br />)	
        });
        return rows;
    }

    render() {
        return (
            <div style={leftMenu}>
                <Grid style={{ marginTop: '0px', paddingTop: '27px' }} >
                    <Grid.Row className='headerText'>
                        <b>FILTERS</b>
                    </Grid.Row>
                    <Grid.Row style={{padding: '1px 0px 40px 0px'}}>
                        <Button style={{ color: '#FFFFFF'}} basic variant='contained' color='primary' onClick={this.filterProps} ><b>Apply</b></Button>
                        <Button style={{ color: '#FFFFFF', marginLeft: '15px'}} basic variant='contained' color='primary' onClick={this.resetProps} ><b>Reset</b></Button>
                    </Grid.Row>
                    <Grid.Row >
                        <div className='subMenu'>
                            <h4>SEARCH START DATE</h4>
                            <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: '14px' }}>
                                <Checkbox style={{marginLeft: '-10px'}} color='primary'></Checkbox>
                                <Image alt='' src={searchbox} />
                            </div>
                        </div>
                    </Grid.Row>
                    <Grid.Row >
                        <div className='subMenu'>
                            <h4>SEARCH END DATE</h4>
                            <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: '14px' }} >
                                <Checkbox style={{marginLeft: '-10px'}} color='primary'></Checkbox>
                                <Image alt='' src={searchbox} />
                            </div>
                        </div>
                    </Grid.Row>					
                    <Grid.Row >
                        <div className='subMenu'>
                            <div style={{ paddingBottom: '14px' }}>
                                <h4 style={{ display: 'inline', paddingRight: '10px' }}>PARAMETER</h4>
                            </div>
                            {this.renderParams()}
                        </div>
                    </Grid.Row>
                    <Grid.Row >
                        <div className='subMenu'>
                            <div style={{ paddingBottom: '28px' }}>
                               
                            </div>
                        </div>
                    </Grid.Row>
                    <Grid.Row >
                        <div className='subMenu'>
                            <div style={{ paddingBottom: '28px' }}>
                                
                            </div>
                        </div>
                    </Grid.Row>	
                    <Grid.Row >
                        <div className='subMenu'>
                            <div style={{ paddingBottom: '28px' }}>
                              
                            </div>
                        </div>
                    </Grid.Row>					
                    <Grid.Row >
                        <div className='subMenu'>
                            <div style={{ paddingBottom: '0px' }}>
                                <h4 style={{ display: 'inline', paddingRight: '5px' }}>PROCESSING HISTORY</h4>
                            </div>
                            <div style={{ display: 'inline', paddingBottom: '0px', paddingRight: '0px' }}>
							<b>Champion, Brunei</b>
							<br /> <br />
							Max % Allowed:	12 <br /> <br />
							Max % Processed: 11 <br /> <br />
							No of Cargos: 8 <br /> <br />
							<a href="/graphRisk?crudeName=Champion">Details</a><br />     
                            </div>							
                        </div>
                    </Grid.Row>	
                    
                </Grid>

            </div>
        )
    }
}