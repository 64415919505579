import React from 'react';

import AppHeader from "../../components/header/app-header";

const WithMasterLayout = (ChildComponent) => {

	const wrapper = () => (
        <div className="layout-master">
            <AppHeader />
            <ChildComponent />
        </div>
    )
    
    return wrapper;
}

export default WithMasterLayout;
