import { POST_PROPERTY_RESULT_TABLE } from '../actions/actionProperty'

const defaultState = {
  isCargoLockInErrors: false,
  searchResult: {
    data: [],
  },
}

const REDUCERS = {
  [POST_PROPERTY_RESULT_TABLE]: (state, { payload }) => {
    return {
      ...state,
      searchResult: {
        ...state.searchResult,
        data: payload,
      },
    }
  },
}
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}
export const PROPERTY_PAGE_REDUCER = 'propertyPage'
