import React from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import moment from "moment";
import ReactHighchart from "../../react-highcharts";
import PropTypes from "prop-types";

import { setCrudeBlendSelectedChartData } from "../../../actions/actionLanding";

import ReactDOMServer from 'react-dom/server'
import Tooltip from '../../tooltip'

// Helpers
import { stringToDecimalPoint } from '../../../helpers/variables'

const onClickEvent = (props, dispatch) => {
  const indexP = props.point.x;
  const series = props.point.series.chart.series;

  const getSeriesName = _.map(series, (a) => a.name);
  const getSeriesColor = _.map(series, (a) => a.color);
  const getSeriesUserOptions = _.map(series, (a) => a.userOptions);
  const getSeriesIsNew = _.map(getSeriesUserOptions, a => a.isNew[indexP])
  const getSeriesHasConcerns = _.map(getSeriesUserOptions, a => a.hasConcerns)
  const getSeriesValue = _.map(series, (item) => item.data[indexP].y);
  const getSeriesDate = props.point.category;
  
  const a = _.map(getSeriesName, (a) => ({ name: a }));
  const b = _.map(getSeriesValue, (a) => ({ value: a }));
  const c = _.map(getSeriesColor, (a) => ({ color: a }));
  const d = { date: getSeriesDate };
  const e = _.map(getSeriesIsNew, (a) => ({ isNew: a }));
  const f = _.map(getSeriesHasConcerns, (a) => ({ hasConcerns: a }));

  const selectedData = _.merge(a, b, c, d, e, f);

  dispatch(setCrudeBlendSelectedChartData(selectedData));
};

export const CrudeBlendChart = (props) => {
  const dispatch = useDispatch();
  const CHART_OPTIONS = setChartOptions(props.crudeBlendData.data, dispatch);
  return (
    <div style={{ width: "100%", overflowX: "auto" }}>
      <ReactHighchart options={CHART_OPTIONS} />
    </div>
  );
};

CrudeBlendChart.propTypes = {
  crudeBlendData: PropTypes.object,
};

function setChartOptions(data, dispatch) {
  const crudeBlendCategoriesData = _.get(data, "category");
  const crudeBlendChartSeriesData = _.get(data, "series");

  const formatCrudeCategories = _.map(crudeBlendCategoriesData, (item) =>
    moment(item).format("DD MMM")
  );

  return {
    chart: {
      type: "column",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: crudeBlendCategoriesData,
      labels: {
        autoRotation: [0],
        style: {
          fontSize: '14px'
        },
      },
      lineColor: "#FFF",
    },
    yAxis: {
      visible: false,
    },
    tooltip: getTooltip(data, tooltipFormatter),
    legend: {
      enabled: false,
    },
    // tooltip: {
    //   enabled: false,
    //   shared: true,
    // },
    colors: [
      "#FFD645",
      "#FFA000",
      "#00AFED",
      "#0081C9",
      "#3BC2C7",
      "#259396",
      "#7A8CD1",
      "#3750B3",
    ],
    plotOptions: {
      column: {
        stacking: "percent",
        allowPointSelect: false,
        dataLabels: {
          enabled: false,
        },
        events: {
          click: (props) => onClickEvent(props, dispatch),
        },
        opacity: 1,
        states: {
          select: {
            enabled: true,
          },
          hover: {
            enabled: true,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
      series: {
        borderWidth: 0,
        // pointWidth: 30,
        inactive: {
          opacity: 1,
        },
      },
    },
    series: crudeBlendChartSeriesData,
  };
}


const getTooltip = (payload, formatter) => {
  return {
    shared: true,
    backgroundColor: null,
    borderWidth: 0,
    shadow: false,
    className: 'test-classname-tooltip',
    style: {
      padding: 0,
    },
    useHTML: true,
    formatter: function () {
      return formatter(this, payload)
    },
    outside: true,
  }
}

function getTooltipContent(category, data) {
  let content = _.map(_.get(data, 'series'), (a) => ({
    name: a.name,
    color: a.color,
    data: a.data,
  }))
  return content
}

// tooltip in normal view
const tooltipFormatter = (chart, data) => {
  const x = chart
  const i = x.points[0].point.index
  const points = getTooltipContent(x, data)

  const options = {
    title: `${x.x}`,
    items: [
      _.map(points, (a) =>
        !_.isNull(a.data[i])
          ? {
              type: 'card',
              label: a.name,
              value: stringToDecimalPoint(a.data[i], 1, 1),
              indicatorColor: a.color,
            }
          : ''
      ),
    ],
  }

  return ReactDOMServer.renderToString(
    <Tooltip
      small
      // headerColor={data.series[i].color}
      type="TooltipTwoColumns"
      {...options}
      className={'plant-production-volume-tooltip'}
    />
  )
}
