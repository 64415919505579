import _ from 'lodash'

import * as actions from '../../actions/common/actionPersonalSetting';

import { getUserGroupFromIdToken } from '../../helpers/string';

const getDefaultState = () => { 
  const token = localStorage.getItem('id_token')
  let userGroups = getUserGroupFromIdToken(token)

  return {
    currentPersona: userGroups[0] || null,
    isShowSwitchPersonaModal: false,
    notificationList: []
  }
}

const REDUCERS = {
  [actions.PERSONAL_SWITCH_PERSONA]: (state, { payload }) => {
    return {
      ...state,
      currentPersona: payload
    }
  },
  [actions.PERSONAL_SWITCH_PERSONA_MODAL]: (state, { payload }) => {
    return {
      ...state,
      isShowSwitchPersonaModal: payload
    }
  },
}
export default (state = getDefaultState(), { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}
export const PERSONAL_SETTING_REDUCER = 'commonPersonalSetting'
