import React from "react";
import crudsource from '../../data/crude-code';
import { Button, makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions();

const useStyles = makeStyles(theme => ({
    root: {
        color: 'white',
        flexGrow: 3,
        paddingRight: '50px'
    },
    text: {
        backgroundColor: 'white',
        borderRadius: '5px',
    }
}));


export default function SearchBar(props) {

    const source = crudsource.data;

    const [value, setValue] = React.useState(null);


    const handleResultSelect = () => {
        if (props.search === 'basic')
            window.location.href = "/basic?crudeName=" + value.name;
        else if (props.search === 'intelligent')
            window.location.href = "/graphRisk?crudeName=" + value.name;
    }

    const handleSearchChange = (event, newValue) => {
        // Create a new value from the user input
        if (newValue && newValue.inputValue) {
            setValue({
                name: newValue.inputValue,
            });
            return;
        }
        setValue(newValue);
    }

    const filterOptions = (options, params) => {
        const filtered = filter(options, params);
        return filtered;
    }

    const getOptions = (option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
            return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
            return option.inputValue;
        }
        // Regular option
        return option.name;
    }

    const classes = useStyles();
    return (
        <div className='searchBar'>
            <Autocomplete className={classes.root}
                value={value} onChange={handleSearchChange} filterOptions={filterOptions} id='searchbar' getOptionLabel={getOptions}
                selectOnFocus
                clearOnBlur
                options={source}
                renderOption={(option) => `${option.code} - ${option.name}`}
                freeSolo
                renderInput={(params) => (
                    <TextField fullWidth variant= 'outlined' color='secondary' className={classes.text} {...params} placeholder='Enter crude name' />
                )}
            />
            <Button style={{ fontSize: '16px', color: '#ffffff'}} variant='contained' color="primary" onClick={handleResultSelect}><b>Search</b></Button>
        </div>
    )
}
