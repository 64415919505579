import _ from "lodash";
import React from "react";

import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import lollipop from "highcharts/modules/lollipop";

import PropTypes from "prop-types";

import ReactDOMServer from "react-dom/server";

lollipop(Highcharts);

export const CrudeArrivalChart = (props) => {
  const CHART_OPTIONS = setChartOptions(props.data, props.materialType);

  return <HighchartsReact
  highcharts={Highcharts}
  containerProps={{ style: { width: "100%", height: "100%" } }}
  constructorType={"chart"}
  options={CHART_OPTIONS}
  immutable={true}
/>
};

CrudeArrivalChart.propTypes = {
  CrudeArrivalChartData: PropTypes.object,
  CrudeArrivalMaterialType: PropTypes.string,
};

function setChartOptions(data, materialType) {
  const getCrudeCategories = _.map(data, item => item.day)

  const markerColor = materialType === 'Crude' ? '#de1782' : '#004A97';
  const markerDot = materialType === 'Crude' ? 'url(data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADQSURBVHgB7ZUxDgFREIb/fzah3UYoSTQ6TmDdZG/gBvLiBNzATXACOo3wSomGzire2GVtvbGvUOxXTCZTfJlM8Q+RswtNGDaCKYhIoRFKwb1zuuxfZ6tikpVDx3SbKuu07eI3bEI3GVyMfQtP7fm5gqyQ3hM3Co4tE5OMUZ2wGSARYXo3TwgxFlCH8ISCkcAztbAW/oWQ0A28oXtxii084cilyFMXaW9RHdu/zFbSu5kb03CsKLW545PYX44dE4vL3kDZBOJG6bby0EW2WDZ5AfqFQxn3o3KeAAAAAElFTkSuQmCC)' 
  : 'url(data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADMSURBVHgB7ZQxDoJAEEX/DtEYK47AEfQEQkc4BZZUxJtAYSjlCFaGTrgBN5AjUBm0cBw0aEvcLSx4yW52J9mXTSbzFQbcg41lF4PhQskahaoBTnGK8k/ltfuZA8JZTg5+o8EDHoqoeQuD7KIh+0qvi7WFYB/KR0PoY2N2vxGUFcMUTBsC8wqmkGYSDDMJJ+F/CFmVMAVzLbPMFUzBKiXMu0RCtYEuvaOIcsJx18rF05L2b3sHhsQe8LMQpCTORiYQoxRDJcGaoNy2fekJvsc6GwXhLEYAAAAASUVORK5CYII=)';

  return {
    chart: {
      type: "lollipop",
      height: 180,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    title: {
      text: "",
    },
    tooltip: getTooltip(data, tooltipFormatter),
    colors: [markerColor],
    xAxis: {
      categories: getCrudeCategories,
      labels: {
        autoRotation: [0],
        style: {
          fontSize: '18px'
        },
      },
      lineColor: "#FFF",
    },
    yAxis: {
      visible: false,
    },
    plotOptions: {
      series: {
        connectorWidth: 2,
        marker: {
          allowPointSelect: true,
          states: {
            select: {
              enabled: true,
            },
          },
          symbol: markerDot,
        },
      },
    },
    series: [
      {
        data: data,
      },
    ],
  };
}

const getTooltip = (payload, formatter) => {
  return {
    stickOnContact: true,
    enabled: true,
    shared: false,
    backgroundColor: null,
    borderWidth: 0,
    shadow: false,
    className: "test-classname-tooltip",
    style: {
      padding: 0,
    },
    useHTML: true,
    formatter: function () {
      return formatter(this, payload);
    },
    outside: false,
  };
};

function getTooltipContent(category, data) {
  let content = _.map(data, (item) => ({
    name: item.name,
    dischargeData: item.dischargeData,
    loadingData: item.loadingData,
  }));
  return content;
}

// tooltip in normal view
const tooltipFormatter = (chart, data, period) => {
  const x = chart;
  const i = x.point.index;
  const points = getTooltipContent(x, data);

  const getTooltipHeight =
    _.isEmpty(points[i].dischargeData) || _.isEmpty(points[i].loadingData)
      ? "110px"
      : "150px";

  return ReactDOMServer.renderToString(
    <div
      className={`crude-arrival-tooltip-container custom-scrollbar-mini`}
      style={{ height: `${getTooltipHeight}` }}
    >
      <div className={`bold pad-y-0_5`}>{points[i].name}</div>
      {!_.isEmpty(points[i].dischargeData) ? (
        <>
          <div className={`text-pink`}>Discharging Date</div>
          {_.map(
            _.map(points[i].dischargeData, (item) => (
              <div className={`flex space-between pad-y-0_5`}>
                <div>
                  <div className={`bold`}>{item.crude}</div>
                  <div className={`text-dark-grey`} style={{ whiteSpace: "normal" }}>{item.supplier}</div>
                </div>
                <div className={`bold`}>{item.value} {item.material_type == 'LR' ? 'kT':'kbbl'}</div>
              </div>
            ))
          )}
        </>
      ) : (
        ""
      )}
      {!_.isEmpty(points[i].loadingData) ? (
        <>
          <div className={`text-primary`}>Loading Date</div>
          {_.map(
            _.map(points[i].loadingData, (item) => (
              <div className={`flex space-between pad-y-0_5`}>
                <div>
                  <div className={`bold`}>{item.crude}</div>
                  <div
                    className={`text-dark-grey `}
                    style={{ whiteSpace: "normal" }}
                  >
                    {item.supplier}
                  </div>
                </div>
                <div className={`bold`}>{item.value} {item.material_type == 'LR' ? 'kT':'kbbl'}</div>
              </div>
            ))
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
};
