import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import ReactHighchart from '../../react-highcharts'
import PropTypes from 'prop-types'

export const CorrosionPropertyChart = (props) => {
  const CHART_OPTIONS = setChartOptions(props.singleCrudeChartData.data)
  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <ReactHighchart options={CHART_OPTIONS} />
    </div>
  )
}

CorrosionPropertyChart.propTypes = {
  CorrosionPropertyChartData: PropTypes.object,
}

function setChartOptions(data, period) {
  const CorrosionPropertyChartData = data
  return {
    chart: {
      type: 'column',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'category',
      lineColor: '#FFF',
    },
    yAxis: {
      gridLineColor: '#fff',
      title: {
        text: '',
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        pointWidth: 55,
        dataLabels: {
          enabled: true,
          format: '{point.y}',
        },
      },
    },
    series: [
      {
        data: CorrosionPropertyChartData,
      },
    ],
  }
}
