import React, { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { Grid, Header, Button, Dropdown, Popup, Input, Checkbox, Segment } from 'semantic-ui-react'
import moment from 'moment'
import { Calendar } from 'react-date-range'
import { endOfMonth } from 'date-fns'

// Components
import CrudeHeader from './components/crudeHeader'
import SingleCrudeChart from './components/singleCrudeChart'
import CompareBlendChart from './components/compareBlendChart'
import SimilarCrude from './components/similarCrude'
import ResultTable from './components/resultTable'
import FreeformTrend from './components/freeformTrendChart'
import CheckboxList from '../../../components/checkbox-list'
import FetchStateWrapper from '../../../components/fetch-state-wrapper'
import NoDataBlock from '../../../components/no-data-block'

// //Hooks
import { useStateWithPaths, useActions } from '../../../hooks/useConnect'

// Redux
import { PERSONAL_SETTING_REDUCER } from '../../../reducers/common/personalSettingReducer'
import { RISKS_PAGE_REDUCER } from '../../../reducers/risksPageReducer'

// Action
import { MASTER_DATA_REDUCER } from '../../../reducers/common/masterDataReducer'
import {
  getMasterPropertyPageSearchInput,
  getFilterRiskRatings,
  getFilterSource,
  getFilterParameters,
} from '../../../actions/common/actionMasterData'
import { getRiskResultTable } from '../../../actions/actionRisks'
import * as MasterDataActions from '../../../actions/common/actionMasterData'
import * as RiskPageActions from '../../../actions/actionRisks'

// Constant
import * as landingPageConstant from '../landing-page/helper'
import * as riskPageConstant from './helper/constant'

const RisksPage = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const [
    { propertiesPage, filterRiskRatings, filterSource, filterParameters },
  ] = useStateWithPaths([`${MASTER_DATA_REDUCER}`])

  const [{ risksPage, freeformChart }] = useStateWithPaths([`${RISKS_PAGE_REDUCER}`])
  const [{ currentPersona }] = useStateWithPaths([`${PERSONAL_SETTING_REDUCER}`])


  const getSingleCrudeCode = _.get(location.crudeNameAbbreviation, 'crudeCode')
  const formatSingleCrudeCodeToInputList = !_.isNull(getSingleCrudeCode) ? [{ crudeCode: getSingleCrudeCode, percentage: getSingleCrudeCode ? 100 : null}] : []

  const [isSearchByCrudeBlendDate, setIsSearchByCrudeBlendDate] = useState(freeformChart.isSearchByCrudeBlendDate)
  
  const crudeList = _.isEmpty(formatSingleCrudeCodeToInputList) ? location.inputUnits : formatSingleCrudeCodeToInputList
  const formatCrudeList = isSearchByCrudeBlendDate ? crudeList : []

  // FREEFORM TREND USESTATE & USEEFFECT CALLS

  const [
    {
      freeformDataSource,
      freeformUnit,
      freeformStream1,
      freeformStream2,
      freeformStream3,
      freeformStream4,
      freeformStream5,
      freeformProperty1,
      freeformProperty2,
      freeformProperty3,
      freeformProperty4,
      freeformProperty5,
      units
    },
  ] = useStateWithPaths([`${MASTER_DATA_REDUCER}`])

  const {
    getMasterFreeformDataSource,
    getMasterFreeformUnit,
    getMasterFreeformStream1,
    getMasterFreeformStream2,
    getMasterFreeformStream3,
    getMasterFreeformStream4,
    getMasterFreeformStream5,
    getMasterFreeformProperty1,
    getMasterFreeformProperty2,
    getMasterFreeformProperty3,
    getMasterFreeformProperty4,
    getMasterFreeformProperty5,
    getMasterUnits,
  } = useActions({
    getMasterFreeformDataSource: MasterDataActions.getMasterFreeformDataSource,
    getMasterFreeformUnit: MasterDataActions.getMasterFreeformUnit,
    getMasterFreeformStream1: MasterDataActions.getMasterFreeformStream1,
    getMasterFreeformStream2: MasterDataActions.getMasterFreeformStream2,
    getMasterFreeformStream3: MasterDataActions.getMasterFreeformStream3,
    getMasterFreeformStream4: MasterDataActions.getMasterFreeformStream4,
    getMasterFreeformStream5: MasterDataActions.getMasterFreeformStream5,
    getMasterFreeformProperty1: MasterDataActions.getMasterFreeformProperty1,
    getMasterFreeformProperty2: MasterDataActions.getMasterFreeformProperty2,
    getMasterFreeformProperty3: MasterDataActions.getMasterFreeformProperty3,
    getMasterFreeformProperty4: MasterDataActions.getMasterFreeformProperty4,
    getMasterFreeformProperty5: MasterDataActions.getMasterFreeformProperty5,
    getMasterUnits: MasterDataActions.getMasterUnits,
  })

  const { setFreeformTrend } = useActions({
    setFreeformTrend: RiskPageActions.setFreeformTrend,
  })

  const [freeformChartData] = useStateWithPaths([
    'risksPage.freeformChart.data',
  ])
  
  const color1 = '#983E66'
  const color2 = '#FFA000'
  const color3 = '#259396'
  const color4 = '#40C3F2'
  const color5 = '#3750B3'

  const [unit, setUnit] = useState(freeformChart.freeformUnit)

  const [dataSource1, setDataSource1] = useState(freeformChart.freeformDataSource1)
  const [dataSource2, setDataSource2] = useState(freeformChart.freeformDataSource2)
  const [dataSource3, setDataSource3] = useState(freeformChart.freeformDataSource3)
  const [dataSource4, setDataSource4] = useState(freeformChart.freeformDataSource4)
  const [dataSource5, setDataSource5] = useState(freeformChart.freeformDataSource5)

  const [unit1, setUnit1] = useState(freeformChart.freeformUnit1)
  const [unit2, setUnit2] = useState(freeformChart.freeformUnit2)
  const [unit3, setUnit3] = useState(freeformChart.freeformUnit3)
  const [unit4, setUnit4] = useState(freeformChart.freeformUnit4)
  const [unit5, setUnit5] = useState(freeformChart.freeformUnit5)

  const [stream1, setStream1] = useState(freeformChart.freeformStream1)
  const [stream2, setStream2] = useState(freeformChart.freeformStream2)
  const [stream3, setStream3] = useState(freeformChart.freeformStream3)
  const [stream4, setStream4] = useState(freeformChart.freeformStream4)
  const [stream5, setStream5] = useState(freeformChart.freeformStream5)

  const [property1, setProperty1] = useState(freeformChart.freeformProperty1)
  const [property2, setProperty2] = useState(freeformChart.freeformProperty2)
  const [property3, setProperty3] = useState(freeformChart.freeformProperty3)
  const [property4, setProperty4] = useState(freeformChart.freeformProperty4)
  const [property5, setProperty5] = useState(freeformChart.freeformProperty5)

  const [defaultPeriod] = useState([freeformChart.defaultPeriod])
  const [period, setPeriod] = useState([freeformChart.period])
  
  useEffect(() => {
    setFreeformTrend(
      currentPersona,
      formatCrudeList,
      period,
      color1,
      color2,
      color3,
      color4,
      color5,
      dataSource1,
      dataSource2,
      dataSource3,
      dataSource4,
      dataSource5,
      unit1,
      unit2,
      unit3,
      unit4,
      unit5,
      stream1,
      stream2,
      stream3,
      stream4,
      stream5,
      property1,
      property2,
      property3,
      property4,
      property5,
      unit
    )
  }, [
    setFreeformTrend,
    isSearchByCrudeBlendDate,
    currentPersona,
    period,
    color1,
    color2,
    color3,
    color4,
    color5,
    dataSource1,
    dataSource2,
    dataSource3,
    dataSource4,
    dataSource5,
    unit1,
    unit2,
    unit3,
    unit4,
    unit5,
    stream1,
    stream2,
    stream3,
    stream4,
    stream5,
    property1,
    property2,
    property3,
    property4,
    property5,
    unit
  ])

  
  // Freeform Data & Unit

  useEffect(() => {
    getMasterFreeformDataSource(currentPersona)
  }, [getMasterFreeformDataSource, currentPersona])

  useEffect(() => {
    getMasterFreeformUnit(currentPersona)
  }, [getMasterFreeformUnit, currentPersona])

  // Freeform Stream

  useEffect(() => {
    getMasterFreeformStream1(currentPersona, unit1, dataSource1)
  }, [getMasterFreeformStream1, currentPersona, unit1, dataSource1])

  useEffect(() => {
    getMasterFreeformStream2(currentPersona, unit2, dataSource2)
  }, [getMasterFreeformStream2, currentPersona, unit2, dataSource2])

  useEffect(() => {
    getMasterFreeformStream3(currentPersona, unit3, dataSource3)
  }, [getMasterFreeformStream3, currentPersona, unit3, dataSource3])

  useEffect(() => {
    getMasterFreeformStream4(currentPersona, unit4, dataSource4)
  }, [getMasterFreeformStream4, currentPersona, unit4, dataSource4])

  useEffect(() => {
    getMasterFreeformStream5(currentPersona, unit5, dataSource5)
  }, [getMasterFreeformStream5, currentPersona, unit5, dataSource5])

  // Freeform Property

  useEffect(() => {
    getMasterFreeformProperty1(currentPersona, unit1, stream1, dataSource1)
  }, [getMasterFreeformProperty1, currentPersona, unit1, stream1, dataSource1])

  useEffect(() => {
    getMasterFreeformProperty2(currentPersona, unit2, stream2, dataSource2)
  }, [getMasterFreeformProperty2, currentPersona, unit2, stream2, dataSource2])

  useEffect(() => {
    getMasterFreeformProperty3(currentPersona, unit3, stream3, dataSource3)
  }, [getMasterFreeformProperty3, currentPersona, unit3, stream3, dataSource3])

  useEffect(() => {
    getMasterFreeformProperty4(currentPersona, unit4, stream4, dataSource4)
  }, [getMasterFreeformProperty4, currentPersona, unit4, stream4, dataSource4])

  useEffect(() => {
    getMasterFreeformProperty5(currentPersona, unit5, stream5, dataSource5)
  }, [getMasterFreeformProperty5, currentPersona, unit5, stream5, dataSource5])

  useEffect(() => {
    getMasterUnits(currentPersona)
  }, [getMasterUnits, currentPersona])

  //

  const [errorInputs, setErrorInputs] = useState({})
  const [filterSelectedRiskRating, setFilterSelectedRiskRating] = useState([])
  const [isFilterAllSelectedRiskRating, setIsFilterAllSelectedRiskRating] = useState(true)
  const [checkboxListRiskRating, setCheckboxListRiskRating] = useState([])

  const [filterSelectedSource, setFilterSelectedSource] = useState([])
  const [isFilterAllSelectedSource, setIsFilterAllSelectedSource] = useState(false)
  const [checkboxListSource, setCheckboxListSource] = useState([])
  const [filterSourceDefaultList, setFilterSourceDefaultList] = useState([])

  const [filterSelectedParameters, setFilterSelectedParameters] = useState([])
  const [isFilterAllSelectedParameters, setIsFilterAllSelectedParameters] = useState(true)
  const [checkboxListParameters, setCheckboxListParameters] = useState([])

  const [filterProperties, setFilterProperties] = useState([])

  const defaultStartDate = new Date()
  defaultStartDate.setMonth(defaultStartDate.getMonth() - 12)

  const [isCheckedStartDate, setIsCheckedStartDate] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [isCheckedEndDate, setIsCheckedEndDate] = useState(false)
  const [endDate, setEndDate] = useState(new Date())

  if (_.isEqual(startDate, new Date())) {
    setStartDate(defaultStartDate)
  }

  useEffect(() => {
    dispatch(
      getRiskResultTable({
        location,
        mode: riskPageConstant.SEARCH_MODE.DEFAULT_MODE,
      })
    )
    dispatch(getMasterPropertyPageSearchInput())
    dispatch(getFilterRiskRatings())
    dispatch(getFilterSource())
    dispatch(getFilterParameters())
  }, [getRiskResultTable, location])

  const propertiesOption = !_.isEmpty(propertiesPage)
    ? _.map(propertiesPage, (properties, index) => ({
        key: propertiesPage[index],
        text: properties.propertyName,
        value: propertiesPage[index],
      }))
    : ''

  const onClickReset = () => {
    setFilterSelectedRiskRating([])
    setIsFilterAllSelectedRiskRating(true)
    setCheckboxListRiskRating([])

    setFilterSelectedSource([])
    setIsFilterAllSelectedSource(false)
    setCheckboxListSource([])
    setFilterSourceDefaultList([])

    setFilterSelectedParameters([])
    setIsFilterAllSelectedParameters(true)
    setCheckboxListParameters([])

    setIsCheckedStartDate(false)
    setStartDate(defaultStartDate)
    setIsCheckedEndDate(false)
    setEndDate(new Date())

    dispatch(
      getRiskResultTable({
        location,
        mode: riskPageConstant.SEARCH_MODE.DEFAULT_MODE,
      })
    )
  }

  const onClickApply = () => {
    const paramters = isFilterAllSelectedParameters
      ? _.map(checkboxListParameters, (item) => item.text)
      : _.map(filterSelectedParameters, (item) => item.text)
    const riskRating = isFilterAllSelectedRiskRating
      ? _.map(checkboxListRiskRating, (item) => item.text)
      : _.map(filterSelectedRiskRating, (item) => item.text)
    const source =
      _.isEmpty(filterSourceDefaultList) && _.isEmpty(filterSelectedSource)
        ? _.filter(checkboxListSource, function (item) {
            return item.text !== 'Desalter Email'
          })
        : _.map(filterSelectedSource, (item) => item.text)

    if (_.isEmpty(filterSourceDefaultList)) {
      setFilterSourceDefaultList(source)
    }

    const propertyRange = {}

    propertyRange['startDate'] = moment(startDate).format('yyyy-MM-DD')
    propertyRange['endDate'] = moment(endDate).format('yyyy-MM-DD')

    const properties = _.map(filterProperties, (item) => item.integration_crude_prop)

    if (!isValidated()) {
      return
    }

    dispatch(
      getRiskResultTable({
        location,
        mode: riskPageConstant.SEARCH_MODE.FILTER_MODE,
        paramters,
        riskRating,
        source,
        propertyRange,
        properties,
      }),
      [getRiskResultTable, location, paramters, riskRating, source, propertyRange, properties]
    )
  }

  const isValidated = useCallback(() => {
    let newErrorInputs = {}

    newErrorInputs.startDate = startDate > endDate

    setErrorInputs(newErrorInputs)
    let hasError = false
    _.forOwn(newErrorInputs, (value, key) => {
      if (hasError) return

      hasError = value
    })
    return !hasError
  }, [startDate, endDate])

  const onClickFilterProperties = (e, data) => {
    setFilterProperties(data.value)
  }

  const getBlendDateDisplayLabel = (selectedDate) => {
    return _.isDate(selectedDate) ? _.upperCase(moment(selectedDate).format('DD MMM YYYY')) : ''
  }

  const endMonth = endOfMonth(new Date())
  const endOfNextMonth = moment(endMonth).add(1, 'M')._d

  const minDate = moment('2010-08-01')._d

  const [resultTableData, setResultTableData] = useState(risksPage.data)
  useEffect(() => {
    setResultTableData(risksPage.data)
  }, [setResultTableData, risksPage.data])

  return (
    <>
      <div className="main-container page-risks">
        <CrudeHeader searchObject={location} />
        <div className="section section-table-filter flex">
          <div className="left-nav">
            <div className="left-nav-filter-wrapper">
              <Grid padded>
                <Grid.Row>
                  <Grid.Column textAlign="left">
                    <Header size="small" textAlign="left">
                      FILTERS
                    </Header>
                    {
                      <CheckboxList
                        title="PARAMETER"
                        data={filterParameters}
                        checkboxList={checkboxListParameters}
                        setCheckboxList={setCheckboxListParameters}
                        filterSelected={filterSelectedParameters}
                        setFilterSelected={setFilterSelectedParameters}
                        isAllSelected={isFilterAllSelectedParameters}
                        setIsAllSelected={setIsFilterAllSelectedParameters}
                      />
                    }
                    <div className={`pad-bottom-2`}>
                      <div className={`nav-header`}>PROPERTIES</div>
                      <Dropdown
                        className={`filter-properties`}
                        placeholder="Search Properties"
                        fluid
                        multiple
                        search
                        selection
                        options={propertiesOption}
                        onChange={onClickFilterProperties}
                      />
                    </div>
                    {
                      <CheckboxList
                        title="RISK RATING"
                        data={filterRiskRatings}
                        checkboxList={checkboxListRiskRating}
                        setCheckboxList={setCheckboxListRiskRating}
                        filterSelected={filterSelectedRiskRating}
                        setFilterSelected={setFilterSelectedRiskRating}
                        isAllSelected={isFilterAllSelectedRiskRating}
                        setIsAllSelected={setIsFilterAllSelectedRiskRating}
                      />
                    }
                    {
                      <CheckboxList
                        title="SOURCE"
                        data={filterSource}
                        checkboxList={checkboxListSource}
                        setCheckboxList={setCheckboxListSource}
                        filterSelected={filterSelectedSource}
                        setFilterSelected={setFilterSelectedSource}
                        isAllSelected={isFilterAllSelectedSource}
                        setIsAllSelected={setIsFilterAllSelectedSource}
                      />
                    }
                    <div className={`nav-header`}>SEARCH START DATE</div>
                    <div className={`pad-y-1`}>
                      <div>
                        <Popup
                          trigger={
                            <Input
                              value={getBlendDateDisplayLabel(startDate)}
                              error={errorInputs.startDate}
                              placeholder="dd-mm-yyyy"
                            />
                          }
                          content={
                            <Calendar
                              minDate={minDate}
                              maxDate={endOfNextMonth}
                              date={startDate}
                              onChange={(selectedStartDate) => {
                                errorInputs.startDate = false
                                setStartDate(selectedStartDate)
                              }}
                            />
                          }
                          on="click"
                          className="bg-blue"
                          hideOnScroll={true}
                          position="bottom right"
                        />
                      </div>
                    </div>
                    <div className={`nav-header`}>SEARCH END DATE</div>
                    <div className={``}>
                      <div>
                        <Popup
                          trigger={
                            <Input
                              value={getBlendDateDisplayLabel(endDate)}
                              placeholder="dd-mm-yyyy"
                            />
                          }
                          content={
                            <Calendar
                              minDate={minDate}
                              maxDate={endOfNextMonth}
                              date={endDate}
                              onChange={(selectedEndDate) => setEndDate(selectedEndDate)}
                            />
                          }
                          on="click"
                          className="bg-blue"
                          hideOnScroll={true}
                          position="bottom right"
                        />
                      </div>
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ padding: '1px 0px 40px 0px' }}>
                  <Button className="btn-primary" onClick={onClickApply}>
                    Apply
                  </Button>
                  <Button className="btn-primary-outer" onClick={onClickReset}>
                    Reset
                  </Button>
                </Grid.Row>
              </Grid>
            </div>
          </div>
          <div className="left-nav-container">
            <FetchStateWrapper id={RiskPageActions.RISKS_RESULT_TABLE}></FetchStateWrapper>
            {!_.isEmpty(resultTableData) ? (
              <Segment>
                <ResultTable data={resultTableData} location={location} />
              </Segment>
            ) : (
              <NoDataBlock />
            )}
          </div>
        </div>

        {location.options === landingPageConstant.SEARCH_OPTIONS.SingleCrude && (
          <div className="section section-compare-blend">
            <SingleCrudeChart searchObject={location} />
            <FreeformTrend
              freeformChartData={freeformChartData}
              defaultPeriod={defaultPeriod}
              period={period}
              isSearchByCrudeBlendDate={isSearchByCrudeBlendDate}
              setIsSearchByCrudeBlendDate={setIsSearchByCrudeBlendDate}
              setPeriod={setPeriod}
              color1={color1}
              color2={color2}
              color3={color3}
              color4={color4}
              color5={color5}
              freeformDataSource={freeformDataSource}
              dataSource1={dataSource1}
              dataSource2={dataSource2}
              dataSource3={dataSource3}
              dataSource4={dataSource4}
              dataSource5={dataSource5}
              setDataSource1={setDataSource1}
              setDataSource2={setDataSource2}
              setDataSource3={setDataSource3}
              setDataSource4={setDataSource4}
              setDataSource5={setDataSource5}
              freeformUnit={freeformUnit}
              unit1={unit1}
              unit2={unit2}
              unit3={unit3}
              unit4={unit4}
              unit5={unit5}
              setUnit1={setUnit1}
              setUnit2={setUnit2}
              setUnit3={setUnit3}
              setUnit4={setUnit4}
              setUnit5={setUnit5}
              freeformStream1={freeformStream1}
              freeformStream2={freeformStream2}
              freeformStream3={freeformStream3}
              freeformStream4={freeformStream4}
              freeformStream5={freeformStream5}
              stream1={stream1}
              stream2={stream2}
              stream3={stream3}
              stream4={stream4}
              stream5={stream5}
              setStream1={setStream1}
              setStream2={setStream2}
              setStream3={setStream3}
              setStream4={setStream4}
              setStream5={setStream5}
              freeformProperty1={freeformProperty1}
              freeformProperty2={freeformProperty2}
              freeformProperty3={freeformProperty3}
              freeformProperty4={freeformProperty4}
              freeformProperty5={freeformProperty5}
              property1={property1}
              property2={property2}
              property3={property3}
              property4={property4}
              property5={property5}
              setProperty1={setProperty1}
              setProperty2={setProperty2}
              setProperty3={setProperty3}
              setProperty4={setProperty4}
              setProperty5={setProperty5}
              unit={unit}
              setUnit={setUnit}
              units={units}
              location={location}
            />
            <SimilarCrude searchObject={location} />
          </div>
        )}
        {location.options === landingPageConstant.SEARCH_OPTIONS.CustomBlend && (
          <div className="section section-compare-blend">
            <CompareBlendChart searchObject={location} />
            <FreeformTrend
              freeformChartData={freeformChartData}
              defaultPeriod={defaultPeriod}
              period={period}
              isSearchByCrudeBlendDate={isSearchByCrudeBlendDate}
              setIsSearchByCrudeBlendDate={setIsSearchByCrudeBlendDate}
              setPeriod={setPeriod}
              color1={color1}
              color2={color2}
              color3={color3}
              color4={color4}
              color5={color5}
              freeformDataSource={freeformDataSource}
              dataSource1={dataSource1}
              dataSource2={dataSource2}
              dataSource3={dataSource3}
              dataSource4={dataSource4}
              dataSource5={dataSource5}
              setDataSource1={setDataSource1}
              setDataSource2={setDataSource2}
              setDataSource3={setDataSource3}
              setDataSource4={setDataSource4}
              setDataSource5={setDataSource5}
              freeformUnit={freeformUnit}
              unit1={unit1}
              unit2={unit2}
              unit3={unit3}
              unit4={unit4}
              unit5={unit5}
              setUnit1={setUnit1}
              setUnit2={setUnit2}
              setUnit3={setUnit3}
              setUnit4={setUnit4}
              setUnit5={setUnit5}
              freeformStream1={freeformStream1}
              freeformStream2={freeformStream2}
              freeformStream3={freeformStream3}
              freeformStream4={freeformStream4}
              freeformStream5={freeformStream5}
              stream1={stream1}
              stream2={stream2}
              stream3={stream3}
              stream4={stream4}
              stream5={stream5}
              setStream1={setStream1}
              setStream2={setStream2}
              setStream3={setStream3}
              setStream4={setStream4}
              setStream5={setStream5}
              freeformProperty1={freeformProperty1}
              freeformProperty2={freeformProperty2}
              freeformProperty3={freeformProperty3}
              freeformProperty4={freeformProperty4}
              freeformProperty5={freeformProperty5}
              property1={property1}
              property2={property2}
              property3={property3}
              property4={property4}
              property5={property5}
              setProperty1={setProperty1}
              setProperty2={setProperty2}
              setProperty3={setProperty3}
              setProperty4={setProperty4}
              setProperty5={setProperty5}
              unit={unit}
              setUnit={setUnit}
              units={units}
              location={location}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default RisksPage
