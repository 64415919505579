import React from 'react'

//Components
import HighTempCrudeCalculationResult from './calculationResult/highTempCrudeCalculationResult';
import NhHsCorrosionCalculationResult from './calculationResult/nhHsCorrosionCalculationResult';

//Redux
import { CORROSION_PAGE_REDUCER } from '../../../../reducers/corrosionPageReducer';

//Hooks
import { useStateWithPaths } from '../../../../hooks/useConnect';

//Constants
import * as corrosionPageConstants from '../helpers/constants';

const CalculationResult = () => {

  const [{ searchPanel }] = useStateWithPaths([`${CORROSION_PAGE_REDUCER}`])
  const { highTempNhHs, calculateResultMode } = searchPanel;

  if(calculateResultMode === corrosionPageConstants.SEARCH_CALCULATE_RESULT_MODE.CREATE_SUMMARY)
    return null;

  if(highTempNhHs === corrosionPageConstants.SEARCH_HIGHTEMP_NHNS.HIGH_TEMP){
    return (
      <HighTempCrudeCalculationResult />
    )
  }
  else if (highTempNhHs === corrosionPageConstants.SEARCH_HIGHTEMP_NHNS.NHNS){
    return (
      <NhHsCorrosionCalculationResult />
    )
  }
  else{
    return null
  }
}

export default CalculationResult
