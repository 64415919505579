import React, { useCallback } from "react";
import _ from "lodash";
import { useJsonToCsv } from "react-json-csv";
import { Grid, Segment, Table, Button, Icon } from "semantic-ui-react";
import NoDataBlock from "../../../../components/no-data-block";
import { CompatibilityAssessmentChart } from "../../../../components/charts/compatibility-assessment-chart/compatibility-assessment-chart";
import * as compatibilityPageConstants from "../helpers/constants";

const ThaiOilCompatibilityAssessment = (props) => {
  const data = _.get(props.data, "data")[0];
  const table = _.get(data, "Table");
  const assessmentChart = _.get(data, "CustomTriangle");

  const tableLastHeader = (value) =>
    _.isEqual(value, compatibilityPageConstants.TABLE_HEADER.BLEND_A)
      ? "Blend A"
      : _.isEqual(value, compatibilityPageConstants.TABLE_HEADER.BLEND_B)
      ? "Blend B"
      : _.isEqual(value, compatibilityPageConstants.TABLE_HEADER.BLEND_C)
      ? "Blend C"
      : "";

  let totalColumn = 0;
  if (!_.isEmpty(table)) {
    const setA = Object.keys(table.setA.Solubility).length;
    const setB = Object.keys(table.setB.Solubility).length;
    const setC = Object.keys(table.setC.Solubility).length;

    totalColumn = totalColumn > setA ? totalColumn : setA;
    totalColumn = totalColumn > setB ? totalColumn : setB;
    totalColumn = totalColumn > setC ? totalColumn : setC;
  }

  const adjustTableHeader = (object) => {
    const rows = [];
    let i = 0;
    // let len = 11
    while (++i <= totalColumn) {
      if (i === totalColumn) {
        rows.push(
          <Table.HeaderCell key={i}>
            <div className={`last-header`}>
              {tableLastHeader(
                Object.keys(object)[Object.keys(object).length - 1]
              )}
            </div>
          </Table.HeaderCell>
        );
      } else if (i >= Object.keys(object).length) {
        rows.push(<Table.HeaderCell key={i} />);
      } else {
        rows.push(
          <Table.HeaderCell key={i}>
            {Object.keys(object)[i - 1]}
          </Table.HeaderCell>
        );
      }
    }
    return rows;
  };

  const adjustTableCell = (object) => {
    const rows = [];
    let i = 0;
    // let len = 11
    while (++i <= totalColumn) {
      if (i === totalColumn) {
        rows.push(
          <Table.Cell>
            {!_.isEmpty(Object.values(object)[Object.keys(object).length - 1])
              ? Object.values(object)[Object.keys(object).length - 1]
              : Object.values(object)[Object.keys(object).length - 1] > 0
              ? Object.values(object)[Object.keys(object).length - 1]
              : "--"}
          </Table.Cell>
        );
      } else if (i >= Object.keys(object).length) {
        rows.push(<Table.Cell key={i} />);
      } else {
        rows.push(
          <Table.Cell key={i}>
            {Object.values(object)[i - 1] > 0
              ? Object.values(object)[i - 1]
              : "N.A."}
          </Table.Cell>
        );
      }
    }
    return rows;
  };

  const { saveAsCsv } = useJsonToCsv();

  // Export Compatibility Assessment Triangle
  const tansformAssessmentChart = _.map(assessmentChart, (item) => ({
    name: item.name,
    setA: item.setA,
    setB: item.setB,
    setC: item.setC,
    value: item.value,
    result: _.isEqual(item.result, "C") ? "Compatible" : "Incompatible",
  }));

  const assessmentChartExport = tansformAssessmentChart;

  const transfromAssessmentFileName = `Thaioil Compatibility Assessment Triangle`;

  const clickExportTriangle = useCallback(
    (e) => {
      const filename = transfromAssessmentFileName,
        fields = {
          name: "ID",
          setA: "Set A",
          setB: "Set B",
          setC: "Set C",
          value: "Value",
          result: "Compatible / Incompatible",
        },
        style = {
          padding: "5px",
        },
        data = assessmentChartExport;
      saveAsCsv({ data, fields, filename, style });
    },
    [assessmentChartExport, saveAsCsv]
  );

  // // Export Compatibility Assessment Table
  // const tansformAssessmentTable = _.map(table, item => _.map(item, a => a))

  // console.log(tansformAssessmentTable)
  // const assessmentTableExport = tansformAssessmentTable

  // const transfromAssessmentTableFileName = `Thaioil Comapatibility Assessment Table`

  // const clickExportTable = useCallback(
  //   (e) => {
  //     const filename = transfromAssessmentTableFileName,
  //       fields = {
  //         Solubility: "Solubility",
  //         Insolubility: "Insolubility",
  //       },
  //       style = {
  //         padding: "5px",
  //       },
  //       data = assessmentTableExport;
  //     saveAsCsv({ data, fields, filename, style });
  //   },
  //   [assessmentTableExport, saveAsCsv]
  // );

  const onClickExport = () => {
    clickExportTriangle();
  };

  const axisLeftNumber = [...Array(20)].map((_, i) => 5 + i * 5);
  const axisLeftTopNumber = [8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46]
  const axisLeftPosition = [47, 46, 45, 44, 43, 42.2, 41.2, 40.2, 39.2, 38.4, 37.4, 36.4, 35.4, 34.6, 33.6, 32.8, 31.8, 31, 30, 29]

  const axisRightNumber = [95, 90, 85, 80, 75, 70, 65, 60, 55, 50, 45, 40, 35, 30, 25, 20, 15, 10, 5, 0]
  const axisRightTopNumber = [8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46]
  const axisRightPosition = [46.5, 45.5, 44.5, 43.5, 42.5, 41.5, 40.8, 40, 39.2, 38.4, 37.4, 36.4, 35.4, 34.6, 33.6, 32.8, 31.8, 31, 30, 29]

  return (
    <div className={`thai-oil-compatibility-assessment mar-x-2 mar-bottom-2`}>
      <Segment>
        <>
          {_.isEmpty(assessmentChart) ? <div className={`flex center justify-center width-100 bold no-data-available`} style={{ height: '400px'}}>Unable to calculate compatibility due to unavailbility of data</div> : <><div
            className={`flex justify-center mar-top-2 width-100 assessment-text`}
          >
            Set A
          </div>
          <div className={`flex justify-center`}>
            <div className={`flex end assessment-set-c-b assessment-text`}>
              Set C
            </div>
            <div className={`flex column axisNumber`}>
              <div style={{ position: 'absolute', top: '75px', left: '48%'}}>0</div>
              {_.map(axisLeftNumber, (item, key) => (
                <div className={`absolute`} style={{ left: `${axisLeftPosition[key]}%`, top: `${axisLeftTopNumber[key]}%`}}>{item}</div>
              ))}
            </div>
            <CompatibilityAssessmentChart assessmentChart={assessmentChart} />
            <div className={`flex column axisNumber`}>
              <div style={{ position: 'absolute', top: '75px', right: '47%'}}>100</div>
              {_.map(axisRightNumber, (item, key) => (
                <div className={`absolute`} style={{ right: `${axisRightPosition[key]}%`, top: `${axisRightTopNumber[key]}%`}}>{item}</div>
              ))}
            </div>
            <div className={`flex assessment-set-c-b assessment-text`}>
              Set B
            </div>
          </div></>}
          
        </>

        <div className={`pad-2`}>
          {!_.isEmpty(table) ? (
            <>
              <div className={`pad-bottom-2`}>
                <Grid padded>
                  <Grid.Row>
                    <Grid.Column>
                      <Table
                        celled
                        structured
                        textAlign="left"
                        className="table-property border-dot"
                      >
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Set A</Table.HeaderCell>
                            {adjustTableHeader(table.setA.Solubility)}
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>Solubility</Table.Cell>
                            {adjustTableCell(table.setA.Solubility)}
                          </Table.Row>
                          <Table.Row className={`last-row`}>
                            <Table.Cell>Insolubility</Table.Cell>
                            {adjustTableCell(table.setA.Insolubility)}
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
              <div className={`pad-bottom-2`}>
                <Grid padded>
                  <Grid.Row>
                    <Grid.Column>
                      <Table
                        celled
                        structured
                        textAlign="left"
                        className="table-property border-dot"
                      >
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Set B</Table.HeaderCell>
                            {adjustTableHeader(table.setB.Solubility)}
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>Solubility</Table.Cell>
                            {adjustTableCell(table.setB.Solubility)}
                          </Table.Row>
                          <Table.Row className={`last-row`}>
                            <Table.Cell>Insolubility</Table.Cell>
                            {adjustTableCell(table.setB.Insolubility)}
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
              <div className={`pad-bottom-2`}>
                <Grid padded>
                  <Grid.Row>
                    <Grid.Column>
                      <Table
                        celled
                        structured
                        textAlign="left"
                        className="table-property border-dot"
                      >
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Set C</Table.HeaderCell>
                            {adjustTableHeader(table.setC.Solubility)}
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>Solubility</Table.Cell>
                            {adjustTableCell(table.setC.Solubility)}
                          </Table.Row>
                          <Table.Row className={`last-row`}>
                            <Table.Cell>Insolubility</Table.Cell>
                            {adjustTableCell(table.setC.Insolubility)}
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </>
          ) : (
            <NoDataBlock />
          )}
        </div>
        <Grid.Row>
          <div className={`flex mar-2 end`}>
            <Button
              size="mini"
              className="btn-secondary icon"
              onClick={onClickExport}
            >
              Export <Icon name="external alternate"></Icon>
            </Button>
          </div>
        </Grid.Row>
      </Segment>
    </div>
  );
};

export default ThaiOilCompatibilityAssessment;
