import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'

// Components
import { CorrosionParamterChart } from '../../../components/charts/corrosion-parameter-chart/corrosion-parameter-chart'
import { Grid, Header, Segment, Dropdown, Button, Icon } from 'semantic-ui-react'
import Chevron from '../../../components/chevron'
import PeriodPicker from '../../../components/period-picker'
import Legend, { LegendItem } from '../../../components/legend'
import FetchStateWrapper from '../../../components/fetch-state-wrapper'
import { useJsonToCsv } from 'react-json-csv'
import NoDataBlock from '../../../components/no-data-block'

// Hooks
import { useStateWithPaths } from '../../../hooks/useConnect'

// Action
import * as CorrosionParameterActions from '../../../actions/actionLanding'
import { PERSONAL_SETTING_REDUCER } from '../../../reducers/common/personalSettingReducer'
import { MASTER_DATA_REDUCER } from '../../../reducers/common/masterDataReducer'
import {
  getMasterCorrosionParamProperties,
  getMasterCorrosionParamUnits,
} from '../../../actions/common/actionMasterData'
import {
  setCorrosionParamterTrend,
  setCorrosionBlendComposition,
} from '../../../actions/actionLanding'

const GetLegend = () => {
  const [corrosionParamterTrendData] = useStateWithPaths([
    'landingPage.corrosionParamterTrendChart',
  ])

  const getLegendName = _.map(_.get(corrosionParamterTrendData.data, 'series'), (item) => item.name)
  const getLegendColor = _.map(
    _.get(corrosionParamterTrendData.data, 'series'),
    (item) => item.color
  )

  const b = _.map(getLegendName, (a) => ({ type: 'square', name: a }))
  const c = _.map(getLegendColor, (a) => ({ color: a }))

  const mergeLegendObject = _.merge(b, c)

  return (
    <Legend>
      {mergeLegendObject.map(({ name, color, type }, id) => (
        <LegendItem key={id} title={name} type={type} color={color} />
      ))}
    </Legend>
  )
}

const CorrosionParamterTrend = () => {
  const dispatch = useDispatch()

  const [{ currentPersona }] = useStateWithPaths([`${PERSONAL_SETTING_REDUCER}`])
  const [{ corrosionParamproperties, corrosionParamUnits }] = useStateWithPaths([
    `${MASTER_DATA_REDUCER}`,
  ])
  const [corrosionParamterTrendData] = useStateWithPaths([
    'landingPage.corrosionParamterTrendChart',
  ])

  const [periods] = useStateWithPaths(['landingPage.corrosionParamterTrendChart.period'])
  const [defaultPeriod] = useState([corrosionParamterTrendData.defaultPeriod])
  const [period, setPeriod] = useState([periods])

  const [unit, setUnit] = useState(corrosionParamterTrendData.unit)
  const [material, setMaterial] = useState(corrosionParamterTrendData.material)

  const corrosionParamterTrendChartData = _.get(corrosionParamterTrendData, 'data')

  useEffect(() => {
    dispatch(setCorrosionParamterTrend(period, unit, material))
    dispatch(getMasterCorrosionParamUnits(currentPersona))
    dispatch(getMasterCorrosionParamProperties(currentPersona, unit))
  }, [period, unit, material, currentPersona])

  const { saveAsCsv } = useJsonToCsv()

  const tableData = _.get(corrosionParamterTrendChartData, 'drilldown')

  const tableDataForExport = useMemo(() => {
    return _.map(tableData, (item, index) => {
      return _.mapValues(item, (value, key) => {
        return value
      })
    })
  }, [tableData])

  const startDate = _.map(period, (item) => item.startDate)
  const selectedYear = startDate[0].getFullYear()
  const chartExport = _.map(tableDataForExport, (item) => ({
    unit: unit,
    properties: material,
    id: item.id + '_' + selectedYear,
    name: item.name,
    data: _.map(item.data, (x) => _.last(x)),
  }))

  var daysInMonth = []

  var monthDate = moment().startOf('month') // change to a date in the month of interest

  _.times(monthDate.daysInMonth(), function (n) {
    daysInMonth.push(monthDate.format('D')) // your format
    monthDate.add(1, 'day')
  })

  const clickExport = useCallback(
    (e) => {
      const filename = `Corrosion Parameter Trend ${selectedYear}`,
        fields = {
          unit: 'Unit',
          properties: 'Properties',
          id: 'ID',
          name: 'Name',
          data: daysInMonth,
        },
        style = {
          padding: '5px',
        },
        data = chartExport
      saveAsCsv({ data, fields, filename, style })
    },
    [chartExport, saveAsCsv]
  )

  return (
    <Grid padded className={`corrosion-parameter-chart`}>
      <Grid.Row columns={2} verticalAlign="bottom">
        <Grid.Column textAlign="left">
          <Header size="small" textAlign="left">
            CORROSION PARAMETER TREND
          </Header>
        </Grid.Column>
        <Grid.Column>
          <div className={`flex end`}>
            <Dropdown
              placeholder="Select unit"
              className={`cdu-unit-dropdown-container`}
              value={unit}
              defaultValue={unit}
              selection
              options={corrosionParamUnits}
              onChange={(e, data) => {
                setUnit(data.value)
              }}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row column={2}>
        <Segment className={`width-100`}>
          <div className={`flex space-between pad-x-1_5 pad-y-2`}>
            <div className={`width-100`}>
              <FetchStateWrapper id={CorrosionParameterActions.POST_CORROSION_PARAMTER_TREND_CHART}>
                <div className={`flex start center`}>
                  <div className={`filter-text`}>Trend In</div>
                  <PeriodPicker
                    defaultPeriod={defaultPeriod}
                    period={period}
                    setPeriod={setPeriod}
                    onClick={setCorrosionParamterTrend(period, unit, material)}
                    type="year"
                  />
                  <div className={`filter-text`}>of</div>
                  <Dropdown
                    placeholder="Select Property"
                    className={``}
                    value={material}
                    selection
                    options={corrosionParamproperties}
                    onChange={(e, data) => {
                      setMaterial(data.value)
                    }}
                  />
                </div>

                <Grid.Row columns={12}>
                  <div>
                    <GetLegend />
                  </div>
                </Grid.Row>
                <div className={`flex center`}>
                  <Button
                    className={``}
                    onClick={() => {
                      const startDate = moment(_.get(...period, 'startDate'))
                      const endDate = moment(_.get(...period, 'endDate'))
                      return setPeriod([
                        {
                          startDate: moment(startDate._d).subtract(1, 'years')._d,
                          endDate: moment(endDate._d).subtract(1, 'years')._d,
                          key: 'selection',
                        },
                      ])
                    }}
                  >
                    <Chevron direction="left" />
                  </Button>

                  {!_.isEmpty(corrosionParamterTrendChartData.series) ? (
                    <CorrosionParamterChart
                      corrosionParamterTrendChartData={corrosionParamterTrendChartData}
                      period={period}
                      unit={unit}
                    />
                  ) : (
                    <NoDataBlock />
                  )}

                  <Button
                    className={``}
                    onClick={() => {
                      const startDate = moment(_.get(...period, 'startDate'))
                      const endDate = moment(_.get(...period, 'endDate'))
                      return setPeriod([
                        {
                          startDate: moment(startDate._d).add(1, 'years')._d,
                          endDate: moment(endDate._d).add(1, 'years')._d,
                          key: 'selection',
                        },
                      ])
                    }}
                  >
                    <Chevron direction="right" />
                  </Button>
                </div>
                <div className={`flex end`}>
                  <Button size="mini" className="btn-secondary icon" onClick={clickExport}>
                    Export <Icon name="external alternate"></Icon>
                  </Button>
                </div>
              </FetchStateWrapper>
            </div>
          </div>
        </Segment>
      </Grid.Row>
    </Grid>
  )
}

export default CorrosionParamterTrend
