import React, { useCallback } from 'react'
import { Form, Checkbox } from 'semantic-ui-react'
import _ from 'lodash'

const CheckboxList = (props) => {
  const {
    title,
    data,
    checkboxList,
    setCheckboxList,
    filterSelected,
    setFilterSelected,
    isAllSelected,
    setIsAllSelected,
  } = props

  const checkSpecifyCheckbox = (itemText) => {
    let returnBoolean = true

    if (itemText === 'Desalter Email') {
      returnBoolean = false
    }

    return returnBoolean
  }

  if (_.isEmpty(checkboxList) || _.isNull(checkboxList)) {
    _.each(data, (item) => {
      _.forEach({ checked: checkSpecifyCheckbox(item.text) }, function (value, key) {
        return (item.checked = value)
      })
    })

    const mapped = _.map(data, (obj, index) => obj.text)
    const dataFiltered = _.filter(data, (item, index) => mapped.indexOf(item.text) === index)

    _.map(dataFiltered, (item) => {
      checkboxList.push(item)
    })
  }

  function onCheckBoxChange(itemText, isChecked) {
    setFilterSelected((filterSelected.length = 0))
    let isAllChecked = itemText === 'selectAll' && isChecked
    let isAllUnChecked = itemText === 'selectAll' && !isChecked
    const checked = isChecked

    const checkList = checkboxList.map((item, index) => {
      if (isAllChecked || item.text === itemText) {
        return Object.assign({}, item, {
          checked,
        })
      } else if (isAllUnChecked) {
        return Object.assign({}, item, {
          checked: false,
        })
      }
      return item
    })

    let isAllSelected = checkList.findIndex((item) => item.checked === false) === -1 || isAllChecked

    setCheckboxList(checkList)
    setIsAllSelected(isAllSelected)

    const checkedFilter = _.filter(checkList, (v) => v.checked == true)
    _.map(checkedFilter, (item) => {
      filterSelected.push(item)
    })
    setFilterSelected(filterSelected)
  }

  return (
    <div className={`flex row checkbox-list-wrapper`}>
      <Form className={`pad-bottom-2`}>
        <Form.Field className={`nav-header`} style={{ margin: '0' }}>
          {title}
        </Form.Field>
        <Form.Field className="subMenu">
          <Checkbox
            name="selectAll"
            label="Select All"
            checked={isAllSelected}
            onClick={(e, { checked }) => checked}
            onChange={(e, { checked }) => onCheckBoxChange('selectAll', checked)}
          />
          {checkboxList.map((item, index) => {
            return (
              <>
                <Checkbox
                  key={index}
                  label={item.text}
                  checked={isAllSelected ? isAllSelected : item.checked}
                  onClick={(e, { checked }) => checked}
                  onChange={(e, { checked }) => onCheckBoxChange(item.text, checked)}
                />
              </>
            )
          })}
        </Form.Field>
      </Form>
    </div>
  )
}

export default CheckboxList
