import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

// Components
import { Grid, Header, Button, Image } from 'semantic-ui-react'
import Carousel from 'semantic-ui-carousel-react'

import { PERSONAL_SETTING_REDUCER } from '../../../reducers/common/personalSettingReducer'
import * as UserGroup from '../../../constants/userGroups'
import { useStateWithPaths } from '../../../hooks/useConnect'
import { setFavourites } from '../../../actions/actionLanding'

import IconFavouriteUnclick from '../../../assets/images/icon-favourite.svg'

const currentPersonaFormatted = (currentPersona) =>
  _.isEqual(currentPersona, UserGroup.SC)
    ? 'SC'
    : _.isEqual(currentPersona, UserGroup.ENTS)
    ? 'ENTS'
    : _.isEqual(currentPersona, UserGroup.TN)
    ? 'TN'
    : ''

const toFixedDecimals = (data) => (
  Number(data).toFixed(2)
)

const FavouritesComponent = (props) => {
  const history = useHistory()
  const [{ currentPersona }] = useStateWithPaths([`${PERSONAL_SETTING_REDUCER}`])
  return currentPersonaFormatted(currentPersona) === 'ENTS'
    ? _.map(props.data, (a) => (
        <div>
          <div className={`favourites-title`}>
            {a.crudeCode} - {a.crudeName}
          </div>
          <div className={`pad-y-0_5`}>{a.country}</div>
          <div className={`favourites-container`}>
            <div className={`favourites-content-container`}>
              <div className={`favourites-content-title`}>Corrosion Rating</div>
              <div
                className={`favourites-content-value bold`}
                style={{
                  color: `${_.isEqual(a.corrosionRating, 'High') ? '#de1782' : ''}`,
                }}
              >
                {_.isEmpty(a.corrosionRating) ? 'N.A' : toFixedDecimals(a.corrosionRating)}
              </div>
            </div>
            <div className={`favourites-content-container`}>
              <div className={`favourites-content-title`}>Total Acid Number (mgKOH/g)</div>
              <div className={`favourites-content-value bold`}>
                {!_.isEmpty(a.tan) ? 'N.A' : toFixedDecimals(a.tan)}
              </div>
            </div>
          </div>
          <div className={`favourites-container pad-top-1`}>
            <div className={`favourites-content-container`}>
              <div className={`favourites-content-title`}>Total Sulfur (%wt)</div>
              <div className={`favourites-content-value bold`}>
                {!_.isEmpty(a.sulphur) ? 'N.A' : toFixedDecimals(a.sulphur)}
              </div>
            </div>
            <div className={`favourites-content-container`}>
              <div className={`favourites-content-title`}>S/TAN Ratio (-)</div>
              <div className={`favourites-content-value bold`}>
                {!_.isEmpty(a.stan) ? 'N.A' : toFixedDecimals(a.stan)}
              </div>
            </div>
          </div>
          <div className={`flex end pad-top-1`}>
            <Button
              className={`btn-secondary`}
              tiny
              onClick={() => {
                history.push({
                  pathname: '/risks',
                  options: 'single-crude',
                  currentPersona,
                  crudeNameAbbreviation: {
                    crudeCode: a.crudeCode,
                    crudeName: a.crudeName,
                  },
                })
              }}
            >
              View Report
            </Button>
          </div>
        </div>
      ))
    : _.map(props.data, (a) => (
        <div>
          <div className={`favourites-title`}>
            {a.crudeCode} - {a.crudeName}
          </div>
          <div className={`pad-y-0_5`}>{a.country}</div>

          <div className={`favourites-container`}>
            <div className={`favourites-content-container`}>
              <div className={`favourites-content-title`}>Max % Processing</div>
              <div
                className={`favourites-content-value bold`}
                style={{
                  color: `${_.isEqual(a.maxProcessing, 'High') ? '#de1782' : ''}`,
                }}
              >
                {!_.isEmpty(a.maxProcessing) ? 'N.A' : a.maxProcessing}
              </div>
            </div>
            <div className={`favourites-content-container`}>
              <div className={`favourites-content-title`}>Times purchased since 2017</div>
              <div className={`flex`}>
                <div className={`favourites-content-value bold`}>
                  {_.isEmpty(a.purchased) ? 'N.A' : _.get(a.purchased, 'timesPurchased')}
                </div>
                <div className={`favourites-content-value`}>times</div>
                <div className={`favourites-content-value bold`}>
                  ({_.isEmpty(a.purchased) ? 'N.A' : _.get(a.purchased, 'percentagePurchased')}%)
                </div>
              </div>
            </div>
          </div>
          <div className={`favourites-container pad-top-1`}>
            <div className={`favourites-content-container`}>
              <div className={`favourites-content-title`}>Cannot be processed at</div>
              <div className={`favourites-content-value bold`}>
                {_.isEmpty(a.rejection) ? 'N.A' : _.join(a.rejection, ', ')}
              </div>
            </div>
            <div className={`favourites-content-container`}>
              <div className={`favourites-content-title`}>Fouling Possibility</div>
              <div className={`favourites-content-value bold`}>
                {_.isEmpty(a.fouling) ? 'N.A' : a.fouling}
              </div>
            </div>
          </div>

          <div className={`flex end pad-top-1`}>
            <Button
              className={`btn-secondary`}
              tiny
              onClick={() => {
                history.push({
                  pathname: '/risks',
                  options: 'single-crude',
                  currentPersona,
                  crudeNameAbbreviation: {
                    crudeCode: a.crudeCode,
                    crudeName: a.crudeName,
                  },
                })
              }}
            >
              View Report
            </Button>
          </div>
        </div>
      ))
}

const Favourites = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [{ currentPersona }] = useStateWithPaths([`${PERSONAL_SETTING_REDUCER}`])

  const [favouritesData] = useStateWithPaths(['landingPage.favourites'])

  const formatFavouritesData = _.map(_.get(favouritesData, 'data'), (item) => item)

  useEffect(() => {
    dispatch(setFavourites(currentPersona))
  }, [dispatch, setFavourites, currentPersona])

  const elements = _.map(formatFavouritesData, (item) => ({
    render: () => {
      return <FavouritesComponent data={item} />
    },
  }))
  return (
    <Grid.Column className={`favourites`} textAlign="left" width={5}>
      <Header size="small" textAlign="left">
        FAVOURITES
      </Header>
      <div>
        {_.isEmpty(formatFavouritesData) ? (
          <div className={`width-100 flex center justify-center no-data-container`}>
            <div className={`flex center justify-center no-data-content`}>
              <Image src={IconFavouriteUnclick} className={`favourite-btn`} />
              <div>Please favourite your crude on the risk screen</div>
            </div>
          </div>
        ) : (
          <Carousel
            elements={elements}
            duration={10000}
            animation="slide left"
            showNextPrev={false}
            showIndicators={true}
          />
        )}
      </div>
    </Grid.Column>
  )
}

export default Favourites
