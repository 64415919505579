import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Grid, Header } from 'semantic-ui-react'
import NoDataBlock from '../../../../../components/no-data-block'

//Redux
import { CORROSION_PAGE_REDUCER } from '../../../../../reducers/corrosionPageReducer'

//Hooks
import { useStateWithPaths } from '../../../../../hooks/useConnect'

//Helpers
import { getEstimatedCorrosionRateLevelClassName } from '../../helpers/getCorrosionLevelRateClass'

//#region Private Methods

//#endregion

const NhHsCorrosionCalculationResult = () => {
  const [{ searchResult, searchPanel }] = useStateWithPaths([`${CORROSION_PAGE_REDUCER}`])
  const { isSearchByCrudeBlendDate } = searchPanel

  const calculationResult = _.get(searchResult, 'calculationResult')
  const date = _.get(searchResult, 'date')

  const valueIndicator = _.get(calculationResult, 'valueIndicator')

  if (!valueIndicator || _.isEmpty(valueIndicator)) {
    return <NoDataBlock />
  }

  return (
    <div className="section section-calculation-result">
      <Grid padded>
        <Grid.Row>
          <Grid.Column textAlign="left">
            <Header size="small" textAlign="left">
              CALCULATION RESULT
            </Header>
          </Grid.Column>
        </Grid.Row>
        {!_.isEmpty(date) && !_.isEmpty(date.date) && !isSearchByCrudeBlendDate && (
          <Grid.Row>
            <div className={`flex pad-2 mar-x-0_5 mar-bottom-1 blend-container width-100`}>
              <div className={`mar-right-0_5`}>Blend date for the selected criteria is</div>
              <div className={`blend-date-value`}>
                {!_.isEmpty(date) && !_.isEmpty(date.date)
                  ? _.upperCase(moment(date.date).format('DD MMM YYYY'))
                  : 'N.A.'}
              </div>
            </div>
          </Grid.Row>
        )}

        <Grid.Row columns={1}>
          <Grid.Column>
            <div className={`calculation-result-container`}>
              <div className="result-title">Percent Strength of NH4HS</div>
              <div className="value-wrapper-nh4hs flex center">
                <div className="result-value">{_.get(calculationResult, 'value')}</div>
                <div className="result-label-percent">%</div>
                <div className="result-label-equal">=</div>
                <div
                  className={`result-label-indicator flex center align-self-stretch justify-center ${getEstimatedCorrosionRateLevelClassName(
                    valueIndicator
                  )}`}
                >
                  {valueIndicator}
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default NhHsCorrosionCalculationResult
