import React from 'react'
import _ from 'lodash'
import { useLocation, useHistory } from 'react-router-dom'

//Redux
import { PERSONAL_SETTING_REDUCER } from '../../../reducers/common/personalSettingReducer'

//Hooks
import { useStateWithPaths } from '../../../hooks/useConnect'

//Constants
import * as userGroups from '../../../constants/userGroups'

//#region Private Methods

const getMenu = (curentPersona) => {
  const menus = [
    {
      title: 'HOME',
      route: '/',
      forPersona: [],
    },
    {
      title: 'STATISTICAL ANALYSIS',
      route: '/statistical-analysis',
      forPersona: []
    },
    {
      title: 'COMPATIBILITY',
      route: '/compatibility',
      forPersona: [userGroups.TN, userGroups.SC],
    },
    // {
    //     title: 'RISKS',
    //     route: '/risks',
    //     forPersona: [],
    // },
    {
      title: 'CORROSION RATE',
      route: '/corrosion-rate',
      forPersona: [userGroups.ENTS],
    },
    {
      title: '% PROCESSING',
      route: '/processing',
      forPersona: [userGroups.SC],
    },
    {
      title: 'PROPERTY',
      route: '/property',
      forPersona: [userGroups.TN, userGroups.SC],
    }
  ]

  const filterMenus = _.filter(menus, (item) => {
    return (
      !item.forPersona ||
      _.isEmpty(item.forPersona) ||
      _.indexOf(item.forPersona, curentPersona) >= 0
    )
  })

  return filterMenus
}

//#endregion

const HeaderMenuTop = () => {
  const [{ currentPersona }] = useStateWithPaths([`${PERSONAL_SETTING_REDUCER}`])

  const { pathname } = useLocation()

  const history = useHistory()
  const currentPathname = _.get(history, 'location.pathname')
  const menuList = getMenu(currentPersona)

  //Check if the current path is in the menu list else redirect to the first route in menu list
  const foundMenuWithCurrentPathname = _.find(menuList, ['route', currentPathname])
  // if(!foundMenuWithCurrentPathname && menuList[0] && !_.isEmpty(menuList[0])){
  //     history.push(menuList[0].route)
  // }

  return (
    <div className="header-menu-top-container">
      {_.map(menuList, (item, index) => {
        return (
          <a
            key={index}
            onClick={() => history.push(item.route)}
            href={item.route}
            className={`menu-item ${pathname === item.route ? 'active' : ''}`}
          >
            {item.title}
          </a>
        )
      })}
    </div>
  )
}

export default HeaderMenuTop
