import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import { Grid, Header } from 'semantic-ui-react'

// Redux
import { PERSONAL_SETTING_REDUCER } from '../../../reducers/common/personalSettingReducer'

// Hooks
import { useStateWithPaths } from '../../../hooks/useConnect'

// Actions
import { setRecentSearch } from '../../../actions/actionLanding'

const RecentSearch = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [{ currentPersona }] = useStateWithPaths([`${PERSONAL_SETTING_REDUCER}`])
  const [recentSearchData] = useStateWithPaths(['landingPage.recentSearch'])

  const getRecentSearchData = _.map(recentSearchData, (item) =>
    _.map(item, (a) => ({
      crudeCodeList: a.crudeCodeList,
      crudeName: a.crudeName,
      crudeType: a.crudeType,
      crudeOffset: a.crudeOffset,
      navigationLink: a.navigationLink,
    }))
  )

  const reduceRecentSearch = _.reduceRight(
    getRecentSearchData,
    function (flattened, other) {
      return flattened.concat(other)
    },
    []
  )

  useEffect(() => {
    dispatch(setRecentSearch(currentPersona))
  }, [dispatch, setRecentSearch, currentPersona])

  return (
    <Grid padded className={`recent-search`}>
      <Grid.Row>
        <Grid.Column textAlign="left">
          <div className={`pad-top-2`}>
            <Header size="small" textAlign="left">
              RECENT SEARCHES
            </Header>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        {_.isEmpty(reduceRecentSearch) ? (
          ""
        ) : (
          <div className={`recent-search-container`}>
            {_.map(reduceRecentSearch, (a) => (
              <div
                className={`recent-search-box`}
                onClick={() => {
                  history.push({
                    pathname: "/risks",
                    currentPersona,
                    options: _.isEqual(a.crudeType, "Single")
                      ? "single-crude"
                      : "custom-blend",
                    crudeNameAbbreviation: {
                      crudeCode: _.isEqual(a.crudeType, "Single")
                        ? _.reduce(
                            _.map(a.crudeCodeList, (item) => item.crudeCode)
                          )
                        : null,
                      crudeName: _.isEqual(a.crudeType, "Single")
                        ? a.crudeName
                        : null,
                    },
                    inputUnits: !_.isEqual(a.crudeType, "Single")
                      ? _.map(a.crudeCodeList, (item) => ({
                          crudeCode: item.crudeCode,
                          percentage: _.parseInt(item.value),
                        }))
                      : null,
                    offset: a.crudeOffset,
                  });
                }}
              >
                <div className={`recent-search-crudename`}>{a.crudeName}</div>
                <div className={`flex wrap recent-search-crudecode`}>
                  {_.map(a.crudeCodeList, (item, index) => {
                    if (index < 5) {
                      return (
                        <>
                          <div className={`flex`}>
                            <span className={`bold`}>{item.crudeCode}</span>
                            {_.isEqual(a.crudeType, "Single") ? (
                              <span>{item.value}</span>
                            ) : (
                              <span>{item.value}%</span>
                            )}
                          </div>
                        </>
                      );
                    }
                  })}
                  <div style={{ display: `${Object.keys(a.crudeCodeList).length < 5 ? 'none' : ''}`, color: '#000'}}>...</div>
                </div>
                <div className={`recent-search-offset`}>
                  {_.isEqual(a.crudeType, "Blend") && (
                    <p>% Offset <span className={`recent-search-offset-bold`}>+/- {!!a.crudeOffset ? a.crudeOffset : 0}%</span></p>
                  )}
                </div>
                
              </div>
            ))}
            
          </div>
        )}
      </Grid.Row>
    </Grid>
  );
}

export default RecentSearch
