import React, {
  useState,
  useCallback,
  useReducer,
  useEffect,
  useRef,
} from "react";

import _ from "lodash";
import PropTypes from "prop-types";

// Components
import { Grid, Search, Image, Header } from "semantic-ui-react";
import SearchPanel from "./search-panel";
import Favourites from "./favourites";
import RecentSearch from "./recent-search";
import CrudeBlend from "./crude-blend";
import SevenDaysCrudeBlend from "./seven-days-crude-blend";
import SingleCrude from "./single-crude";
import CrudeArrivals from "./crude-arrival";
import FreeformTrend from "./freeform-trend";
import ParameterTrend from "./parameter-trend";
import CorrosionParamterTrend from "./corrosion-parameter-trend";
import Highlights from "./highlights";

// Data
import crudsource from "../../../data/crude-code";

// Hooks
import { useStateWithPaths } from "../../../hooks/useConnect";

// Redux
import { PERSONAL_SETTING_REDUCER } from "../../../reducers/common/personalSettingReducer";

//Constants
import * as userGroups from "../../../constants/userGroups";

// Assets & Style
import background from "../../../assets/images/search_banner.svg";
import CarrivalNav from "../../../assets/images/carrivnav.svg";
import carriv_overlay from "../../../assets/images/crudearrivals-overlay.svg";
import Carrival from "../../../assets/images/arrivchart.svg";
import { CrudeArrivalChart } from "../../../components/charts/crude-arrival-chart/crude-arrival-chart";

const getComponent = (currentPersona) => {
  const component = [
    {
      components: RecentSearch,
      forPersona: [userGroups.SC, userGroups.TN, userGroups.ENTS],
    },
    {
      components: CrudeBlend,
      forPersona: [userGroups.SC, userGroups.TN, userGroups.ENTS],
    },
    {
      components: SevenDaysCrudeBlend,
      forPersona: [userGroups.SC],
    },
    {
      components: SingleCrude,
      forPersona: [userGroups.SC, userGroups.TN, userGroups.ENTS],
    },
    {
      components: Highlights,
      forPersona: [userGroups.SC, userGroups.TN, userGroups.ENTS],
    },
    {
      components: FreeformTrend,
      forPersona: [userGroups.SC, userGroups.TN],
    },
    {
      components: CorrosionParamterTrend,
      forPersona: [userGroups.ENTS],
    },
    {
      components: CrudeArrivals,
      forPersona: [userGroups.SC, userGroups.TN],
    },
  ];

  const filterComponent = _.filter(component, (item) => {
    return (
      !item.forPersona ||
      _.isEmpty(item.forPersona) ||
      _.indexOf(item.forPersona, currentPersona) >= 0
    );
  });

  return filterComponent;
};

const LandingPage = (props) => {
  const [{ currentPersona }] = useStateWithPaths([
    `${PERSONAL_SETTING_REDUCER}`,
  ]);
  const componentList = getComponent(currentPersona);

  return (
    <div className="main-container page-landing">
      <Grid padded>
        <Grid.Row className={`margin-bottom-2`}>
          <SearchPanel />
          <Favourites />
        </Grid.Row>
      </Grid>

      {_.map(componentList, (item) => {
        return <item.components />;
      })}
    </div>
  );
};

export default LandingPage;
