import _ from 'lodash'

import { routeTo } from '../../helpers/history'
import { getUrlHashValueBykey, getRootUrl } from '../helper'
import { serviceUnauthorized } from '../index'
import { LAMBDA_API_INSTANCE } from '../serverInstances/lambdaServer'

export const checkServer = () =>
  LAMBDA_API_INSTANCE.get('/').then(response => {
    if (response.data.status === 'server is online') return true
    else throw new Error('Server is offline')
  })

export const startSSO = refreshToken => {
  if (refreshToken) {
    return refreshSSOTokens(refreshToken)
  }
  
  const idToken = getUrlHashValueBykey('id_token')
  const accessToken = getUrlHashValueBykey('access_token')
  if (idToken) {
    return new Promise((resolve, reject) => {
      setAccessToken({
        token: idToken
      });
      resolve({ idToken, accessToken });
    })
  } else {
    loginSSO()
  }
}

export const refreshSSOTokens = refreshToken => {
  return LAMBDA_API_INSTANCE.post('/auth/token?action=refresh_token', {
    refresh_token: refreshToken
  })
    .then(response => {
      const id_token = _.get(response.data, 'id_token')
      setAccessToken({
        token: id_token
      })
      return response.data
    })
    .catch(error => {
      if (_.get(error, 'response.status') === 503) {
        routeTo({
          path: '/maintenance'
        })
        throw Error('Invalid')
      } else throw Error('Login Failed')
    })
}

// export const getSSOTokens = code => {
//   return LAMBDA_API_INSTANCE.post('/auth/token?action=get_token', {
//     code,
//     redirect_uri: getRootUrl()
//   })
//     .then(response => {
//       const id_token = _.get(response.data, 'id_token')
//       setAccessToken({
//         token: id_token
//       })
//       return response.data
//     })
//     .catch(error => {
//       if (_.get(error, 'response.status') === 503) {
//         routeTo({
//           path: '/maintenance'
//         })
//         throw Error('Invalid')
//       } else throw Error('Login Failed')
//     })
// }

export const loginSSO = () => {
  const redirectUri = getRootUrl()
  console.log(redirectUri)
  const url = process.env.REACT_APP_AWS_COGNITO_SSO_URL
  const clientId = process.env.REACT_APP_AWS_COGNITO_CLIENT_ID
  const responseType = 'token'
  window.location.href = `${url}/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}`
}

export const logoutSSO = () => {
  const redirectUri = getRootUrl()
  const url = process.env.REACT_APP_AWS_COGNITO_SSO_URL
  const clientId = process.env.REACT_APP_AWS_COGNITO_CLIENT_ID
  const responseType = 'code'
  window.location.href = `${url}/logout?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}`
}

export const setAccessToken = ({ token }) => {
  // if (token) {
  //   axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  //   LAMBDA_API_INSTANCE.defaults.headers.common['Authorization'] = `Bearer ${token}`
  // }
}

// export const authenticate = ({ email, password }) =>
//   axios
//     .post(`/auth/login`, {
//       email,
//       password
//     })
//     .then(response => {
//       if (response.data.status.code !== 200) {
//         delete axios.defaults.headers.common['Authorization']
//         throw new Error(response.data.data)
//       } else {
//         return response.data.data
//       }
//     })
//     .then(data => {
//       setAccessToken({
//         token: data.access_token
//       })
//       return data
//     })

export const validateAPIToken = (id_token, refresh_token) => {
  // return axios
  // .post(`/auth/validate`,{},{
  // 	headers:{Authorization:`Bearer ${token}`},
  // 	transformResponse: [
  // 		JSON.parse
  // 	]
  // })
  // .then(response => {
  // 	if(response.data.status.code === 200)
  // 		return response.data.status.code
  // 	else throw new Error(response.data.data)
  // })
  return LAMBDA_API_INSTANCE.get(`/auth/user_info`, {
    headers: {
      Authorization: `Bearer ${id_token}`
    }
  })
    .then(() => ({ id_token, refresh_token }))
    .catch(error => {
      if (refresh_token) {
        return refreshSSOTokens(refresh_token)
      } else {
        if (_.get(error, 'response.status') === 503) {
          routeTo({
            path: '/maintenance'
          })
          throw Error('Invalid')
        } else throw Error('Login Failed')
      }
    })
}

export const getUserInfo = () => {
  return LAMBDA_API_INSTANCE.get(`auth/user_info`, {
    validateStatus: function (status) {
      if (status >= 200 && status < 300) return true
      if (_.includes([401, 403], status)) {
        serviceUnauthorized()
        return false
      }
      return false
    }
  }).then(response => {
    return response.data.data
  })
}

export const getUserRoles = () =>
  LAMBDA_API_INSTANCE.get(`auth/roles`).then(response => {
    return response.data.data.roles
  })

export const getUserList = () =>
  LAMBDA_API_INSTANCE.get(`auth/user_list`).then(response => {
    return response.data.data.users
  })

export const getRoleList = () =>
  LAMBDA_API_INSTANCE.get(`auth/role_list`).then(response => {
    return response.data.data.roles
  })

export const getDepartmentList = () =>
  LAMBDA_API_INSTANCE.get(`auth/department_list`).then(response => {
    return response.data.data.departments
  })

export const postNotifyNewUser = ({ email }) =>
  LAMBDA_API_INSTANCE
    .post(`notifications/trigger_email`, {
      email_type: 'new-user',
      email_address: email
    })
    .then(({ data }) => {
      if (data.status.code !== 200) throw new Error(data.status.description)
      return data.data
    })
    .catch(console.error)

export const postRegisterUser = ({ email, name, departmentId, roleIds = [] }) => {
  return LAMBDA_API_INSTANCE
    .post(`auth/register`, {
      email: email,
      name: name,
      department_id: departmentId,
      role_ids: roleIds
    })
    .then(({ data }) => {
      if (data.status.code !== 200) throw new Error(data.status.description)
      return data.data
    })
    .catch(console.error)
}

export const putUpdateUser = ({ email, name, departmentId, roleIds = [] }) =>
  LAMBDA_API_INSTANCE
    .put(`auth/update`, {
      email: email,
      name: name,
      department_id: departmentId,
      role_ids: roleIds
    })
    .then(({ data }) => {
      if (data.status.code !== 200) throw new Error(data.status.description)
      return data.data
    })
    .catch(console.error)

export const deleteUser = ({ email }) =>
  LAMBDA_API_INSTANCE
    .delete(`auth/delete`, {
      data: {
        email
      }
    })
    .then(({ data }) => {
      if (data.status.code !== 200) throw new Error(data.status.description)
      return data.data
    })
    .catch(console.error)

export const logHistory = ({ email, path }) =>
  LAMBDA_API_INSTANCE.post('/logger', {
    'eventType': 'page_view',
    'eventPayload': {
      'path': path,
      //'module': getELKRouteModule(path)
    }
  }).then(() => { })
