export const SEARCH_COMPATIBILITY = {
  CRUDE_BLEND: 'CB',
  CALCULATION: 'CC',
  ASSESSMENT: 'CA',
}

export const SEARCH_VALIDATION = {
  ERROR_MSG_1: 'This field is required',
  ERROR_MSG_2: 'Please enter the total composition of 100',
  ERROR_MSG_3: 'Please enter a different crude',
  ERROR_MSG_4: 'Please enter at least 2 sets of crudes for check compatibility',
  ERROR_MSG_5: 'Please enter composition more than 0',
}

export const TABLE_HEADER = {
  BLEND_A: 'blendA',
  BLEND_B: 'blendB',
  BLEND_C: 'blendC',
}
