import _ from 'lodash'
import { fetchStart, fetchSuccess, fetchError } from './common/actionFetch'
import { postPropertyResultTable, postPropertyFeedback } from '../services/serviceProperty'

export const POST_PROPERTY_RESULT_TABLE = 'POST_PROPERTY_RESULT_TABLE'
export const getPropertyResultTable = (searchObject) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_PROPERTY_RESULT_TABLE))
    postPropertyResultTable({
      mode: searchObject.mode,
      property: searchObject.property,
      currentPersona: searchObject.currentPersona,
      paramters: searchObject.paramters,
      riskRating: searchObject.riskRating,
      source: searchObject.source,
      propertyRange: searchObject.propertyRange,
    })
      .then((response) =>
        dispatch({
          type: POST_PROPERTY_RESULT_TABLE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_PROPERTY_RESULT_TABLE)))
      .catch(() => dispatch(fetchError(POST_PROPERTY_RESULT_TABLE)))
  }
}
