import _ from 'lodash'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import React from 'react'
import ReactHighchart from '../../react-highcharts'
import Highcharts from 'highcharts/highstock'
import PropTypes from 'prop-types'

import { setSevenDaySelectedChartData } from '../../../actions/actionLanding'

const onClickEvent = (props, data, dispatch) => {
  const indexP = props.point.x
  const series = props.point.series.chart.series

  const seriesData = _.get(data, 'series')
  const seriesDataAM = _.get(seriesData, 'AM')

  const getSeriesName = _.map(series, (a) => a.name)
  const getSeriesColor = _.map(series, (a) => a.color)
  const getSeriesValue = _.map(series, (item) => item.data[indexP].y)
  const getSeriesUserOptions = _.map(series, (a) => a.userOptions);
  const getSeriesIsNew = _.map(getSeriesUserOptions, a => a.isNew[indexP])
  const getSeriesHasConcerns = _.map(getSeriesUserOptions, a => a.hasConcerns)
  const getSeriesDate = props.point.category
  const getSeriesTankNo = _.map(seriesDataAM, (a) => a.tank_no)

  const a = _.map(getSeriesName, (a) => ({ name: a }))
  const b = _.map(getSeriesValue, (a) => ({ value: a }))
  const c = _.map(getSeriesColor, (a) => ({ color: a }))
  const d = {
    date: getSeriesDate,
    selectedIndex: indexP,
    chartType: 'AM',
  }
  const e = _.map(getSeriesTankNo, (a) => ({ tank_no: a }))
  const f = _.map(getSeriesIsNew, (a) => ({ isNew: a }))
  const g = _.map(getSeriesHasConcerns, (a) => ({ hasConcerns: a }));

  const selectedData = _.merge(a, b, c, d, e, f, g)

  dispatch(setSevenDaySelectedChartData(selectedData))
}

const onLoadSelect = (props, dispatch) => {
  // const chart = props
  // const category = _.get(props, 'category')
  // const series = _.get(_.get(props, 'series'), 'AM')
  // const getSeriesName = _.map(series, (a) => a.name)
  // const getSeriesDate = _.last(category)
  // console.log(getSeriesDate)
  // console.log(getSeriesName)
  // const chart = this
  //   const getSeriesName = _.map(chart.series, (item) => item.name)
  //   const getSeriesColor = _.map(chart.series, (item) => item.color)
  //   const getSeriesValue = _.map(chart.series, (item) => item.data[0].y)
  //   const getSeriesDate = chart.series[0].data[0].category
  //   const a = _.map(getSeriesName, (a) => ({ name: a }))
  //   const b = _.map(getSeriesValue, (a) => ({ value: a }))
  //   const c = _.map(getSeriesColor, (a) => ({ color: a }))
  //   const d = {
  //     date: getSeriesDate,
  //     chartType: 'AM',
  //   }
  //   const selectedData = _.merge(a, b, c, d)
  //   dispatch(setSevenDaySelectedChartData(selectedData))
  // const getSeriesName = _.map(series, (a) => a.name)
  // const getSeriesColor = _.map(series, (a) => a.color)
  // const getSeriesValue = _.map(series, (item) => item.data[indexP].y)
  // const getSeriesDate = props.point.category
  // const a = _.map(getSeriesName, (a) => ({ name: a }))
  // const b = _.map(getSeriesValue, (a) => ({ value: a }))
  // const c = _.map(getSeriesColor, (a) => ({ color: a }))
  // const d = {
  //   date: getSeriesDate,
  //   chartType: 'AM',
  // }
  // const selectedData = _.merge(a, b, c, d)
  // dispatch(setSevenDaySelectedChartData(selectedData))
}

export const AMSevenDayCrudeBlendChart = (props) => {
  const dispatch = useDispatch()
  const CHART_OPTIONS = setChartOptions(props.sevenDayCrudeBlendData.data.chart, dispatch)
  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <ReactHighchart options={CHART_OPTIONS} />
    </div>
  )
}

AMSevenDayCrudeBlendChart.propTypes = {
  AMsevenDayCrudeBlendData: PropTypes.object,
}

function setChartOptions(data, dispatch) {
  const AMsevenDayCrudeBlendDataSeries = _.get(_.get(data, 'series'), 'AM')
  const AMsevenDayCrudeBlendDataCategory = _.get(data, 'category')

  return {
    chart: {
      type: 'column',
      height: 200,
      // events: {
      //   load: function () {
      //     const chart = this

      //     const getSeriesName = _.map(chart.series, (item) => item.name)
      //     const getSeriesColor = _.map(chart.series, (item) => item.color)
      //     const getSeriesValue = _.map(chart.series, (item) => item.data[0].y)
      //     const getSeriesDate = chart.series[0].data[0].category

      //     const a = _.map(getSeriesName, (a) => ({ name: a }))
      //     const b = _.map(getSeriesValue, (a) => ({ value: a }))
      //     const c = _.map(getSeriesColor, (a) => ({ color: a }))
      //     const d = {
      //       date: getSeriesDate,
      //       chartType: 'AM',
      //     }

      //     const selectedData = _.merge(a, b, c, d)

      //     dispatch(setSevenDaySelectedChartData(selectedData))
      //   },
      // },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: AMsevenDayCrudeBlendDataCategory,
      labels: {
        autoRotation: [0],
      },
      lineColor: '#FFF',
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
      shared: true,
    },
    plotOptions: {
      column: {
        stacking: 'percent',
        allowPointSelect: false,
        dataLabels: {
          enabled: false,
        },
        point: {
          events: {
            click: (props) => onClickEvent(props, data, dispatch),
          },
        },

        opacity: 1,
        states: {
          select: {
            enabled: true,
          },
          hover: {
            enabled: true,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
      series: {
        borderWidth: 0,
        pointWidth: 45,
        // animation: {
        //   duration: 2000,
        //   complete: () => onLoadSelect(data, dispatch),
        // complete: function () {
        //   const chart = this

        //   const getSeriesName = _.map(chart.series, (item) => item.name)
        //   const getSeriesColor = _.map(chart.series, (item) => item.color)
        //   const getSeriesValue = _.map(chart.series, (item) => item.data[0].y)
        //   const getSeriesDate = chart.series[0].data[0].category

        //   const a = _.map(getSeriesName, (a) => ({ name: a }))
        //   const b = _.map(getSeriesValue, (a) => ({ value: a }))
        //   const c = _.map(getSeriesColor, (a) => ({ color: a }))
        //   const d = {
        //     date: getSeriesDate,
        //     chartType: 'AM',
        //   }

        //   const selectedData = _.merge(a, b, c, d)

        //   dispatch(setSevenDaySelectedChartData(selectedData))
        // },
        // },
      },
    },
    series: AMsevenDayCrudeBlendDataSeries,
  }
}
