import React from 'react'

//Components
import HighTempCrudeSummaryTable from './summaryTable/highTempCrudeSummaryTable';
import NhHsCorrosionSummaryTable from './summaryTable/nhHsCorrosionSummaryTable';

//Redux
import { CORROSION_PAGE_REDUCER } from '../../../../reducers/corrosionPageReducer';

//Hooks
import { useStateWithPaths } from '../../../../hooks/useConnect';

//Constants
import * as corrosionPageConstants from '../helpers/constants';

const SummaryTable = () => {
  
  const [{ searchPanel }] = useStateWithPaths([`${CORROSION_PAGE_REDUCER}`])
  const { highTempNhHs, calculateResultMode } = searchPanel;

  if(calculateResultMode === corrosionPageConstants.SEARCH_CALCULATE_RESULT_MODE.CALCULATE_CORROSION_RATE)
    return null;

  if(highTempNhHs === corrosionPageConstants.SEARCH_HIGHTEMP_NHNS.HIGH_TEMP){
    return (
      <HighTempCrudeSummaryTable />
    )
  }
  else if (highTempNhHs === corrosionPageConstants.SEARCH_HIGHTEMP_NHNS.NHNS){
    return (
      <NhHsCorrosionSummaryTable />
    )
  }
  else{
    return null
  }
}

export default SummaryTable
