import _ from "lodash";
import moment from "moment";
import { LAMBDA_API_INSTANCE } from "./serverInstances/lambdaServer";
import * as UserGroup from "../constants/userGroups";

const CONTROLLER_NAME = "/home";

const currentPersonaFormatted = (currentPersona) =>
  _.isEqual(currentPersona, UserGroup.SC)
    ? "SC"
    : _.isEqual(currentPersona, UserGroup.ENTS)
    ? "ENTS"
    : _.isEqual(currentPersona, UserGroup.TN)
    ? "TN"
    : "";

export const postCrudeBlendChart = (period, unit, currentPersona) => {
  const startDate = _.map(period, (a) =>
    moment(a.startDate).format("YYYY-MM-DD")
  ).toString();
  const endDate = _.map(period, (a) =>
    moment(a.endDate).format("YYYY-MM-DD")
  ).toString();

  const bodyParams = {
    persona: currentPersonaFormatted(currentPersona),
    startDate: startDate,
    endDate: endDate,
    unit: unit,
  };
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/crudeBlendChart`,
    bodyParams
  );
};

export const postCrudeBlendCduValue1 = (
  cduFeed,
  unit,
  dataSource,
  selectedDate
) => {
  const formatSelectedDate = moment(selectedDate).format("YYYY-MM-DD");
  const bodyParams = {
    source: dataSource,
    unit: unit,
    date: formatSelectedDate,
    fraction: "Crude",
    property: cduFeed,
  };
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/crudeBlendPropertyValues`,
    bodyParams
  );
};

export const postCrudeBlendCduValue2 = (
  cduFeed,
  unit,
  dataSource,
  selectedDate
) => {
  const formatSelectedDate = moment(selectedDate).format("YYYY-MM-DD");
  const bodyParams = {
    source: dataSource,
    unit: unit,
    date: formatSelectedDate,
    fraction: "Crude",
    property: cduFeed,
  };
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/crudeBlendPropertyValues`,
    bodyParams
  );
};

export const postCrudeBlendCduValue3 = (
  cduFeed,
  unit,
  dataSource,
  selectedDate
) => {
  const formatSelectedDate = moment(selectedDate).format("YYYY-MM-DD");
  const bodyParams = {
    source: dataSource,
    unit: unit,
    date: formatSelectedDate,
    fraction: "Crude",
    property: cduFeed,
  };
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/crudeBlendPropertyValues`,
    bodyParams
  );
};

export const postCrudeBlendCduValue4 = (
  cduFeed,
  unit,
  dataSource,
  selectedDate
) => {
  const formatSelectedDate = moment(selectedDate).format("YYYY-MM-DD");
  const bodyParams = {
    source: dataSource,
    unit: unit,
    date: formatSelectedDate,
    fraction: "Crude",
    property: cduFeed,
  };
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/crudeBlendPropertyValues`,
    bodyParams
  );
};

export const postCrudeBlendCduValue5 = (
  cduFeed,
  unit,
  dataSource,
  selectedDate
) => {
  const bodyParams = {
    source: dataSource,
    unit: unit,
    date: selectedDate,
    fraction: "Crude",
    property: cduFeed,
  };
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/crudeBlendPropertyValues`,
    bodyParams
  );
};

export const postCrudeBlendDownstreamValue1 = (
  fraction,
  properties,
  unit,
  dataSource,
  selectedDate
) => {
  const bodyParams = {
    source: dataSource,
    unit: unit,
    date: selectedDate,
    fraction: fraction,
    property: properties,
  };
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/crudeBlendPropertyValues`,
    bodyParams
  );
};

export const postCrudeBlendDownstreamValue2 = (
  fraction,
  properties,
  unit,
  dataSource,
  selectedDate
) => {
  const bodyParams = {
    source: dataSource,
    unit: unit,
    date: selectedDate,
    fraction: fraction,
    property: properties,
  };
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/crudeBlendPropertyValues`,
    bodyParams
  );
};

export const postCrudeBlendDownstreamValue3 = (
  fraction,
  properties,
  unit,
  dataSource,
  selectedDate
) => {
  const bodyParams = {
    source: dataSource,
    unit: unit,
    date: selectedDate,
    fraction: fraction,
    property: properties,
  };
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/crudeBlendPropertyValues`,
    bodyParams
  );
};

export const postCrudeBlendDownstreamValue4 = (
  fraction,
  properties,
  unit,
  dataSource,
  selectedDate
) => {
  const bodyParams = {
    source: dataSource,
    unit: unit,
    date: selectedDate,
    fraction: fraction,
    property: properties,
  };
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/crudeBlendPropertyValues`,
    bodyParams
  );
};

export const postCrudeBlendDownstreamValue5 = (
  fraction,
  properties,
  unit,
  dataSource,
  selectedDate
) => {
  const bodyParams = {
    source: dataSource,
    unit: unit,
    date: selectedDate,
    fraction: fraction,
    property: properties,
  };
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/crudeBlendPropertyValues`,
    bodyParams
  );
};

export const postSingleCrudeChart = (
  period,
  chartType,
  unit,
  crudeNameAbbreviation,
  property
) => {
  const startDate = _.map(period, (a) =>
    moment(a.startDate).format("YYYY-MM-DD")
  ).toString();
  const endDate = _.map(period, (a) =>
    moment(a.endDate).format("YYYY-MM-DD")
  ).toString();
  const crudeCode = _.get(crudeNameAbbreviation, "crudeCode");
  const crudeName = _.get(crudeNameAbbreviation, "crudeName");

  const bodyParams = {
    startDate: startDate,
    endDate: endDate,
    chartType: chartType,
    unit: unit,
    crudeCode: crudeCode,
    crudeName: crudeName,
    crudeProperty: property,
  };
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/singleCrudeChart`,
    bodyParams
  );
};

export const postParameterTrendChart1 = (
  period,
  unit,
  fraction,
  properties,
  dataSource
) => {
  const startDate = _.map(period, (a) =>
    moment(a.startDate).format("YYYY-MM-DD")
  ).toString();
  const endDate = _.map(period, (a) =>
    moment(a.endDate).format("YYYY-MM-DD")
  ).toString();

  const bodyParams = {
    source: dataSource,
    startDate: startDate,
    endDate: endDate,
    unit: unit,
    fraction: fraction,
    property: properties,
  };
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/parameterTrendChart1`,
    bodyParams
  );
};

export const postParameterTrendChart2 = (
  period,
  unit,
  fraction,
  properties,
  dataSource
) => {
  const startDate = _.map(period, (a) =>
    moment(a.startDate).format("YYYY-MM-DD")
  ).toString();
  const endDate = _.map(period, (a) =>
    moment(a.endDate).format("YYYY-MM-DD")
  ).toString();

  const bodyParams = {
    source: dataSource,
    startDate: startDate,
    endDate: endDate,
    unit: unit,
    fraction: fraction,
    property: properties,
  };
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/parameterTrendChart2`,
    bodyParams
  );
};

export const postCorrosionParamterTrendChart = (period, unit, material) => {
  const startDate = _.map(period, (a) =>
    moment(a.startDate).format("YYYY")
  ).toString();
  const endDate = _.map(period, (a) =>
    moment(a.endDate).format("YYYY-MM-DD")
  ).toString();
  const bodyParams = {
    year: startDate,
    unit: unit,
    property: material,
  };
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/corrosionParameterTrendChart`,
    bodyParams
  );
};

export const postCorrosionBlendComposition = (period, unit, month) => {
  const startDate = _.map(period, (a) =>
    moment(a.startDate).format("YYYY")
  ).toString();
  const bodyParams = {
    year: startDate,
    unit: unit,
    month: month,
  };
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/corrosionParamBlendComposition`,
    bodyParams
  );
};

export const postSevenDayCrudeBlendChart = (period, unit, currentPersona) => {
  const startDate = _.map(period, (a) =>
    moment(a.startDate).format("YYYY-MM-DD")
  ).toString();
  const endDate = _.map(period, (a) =>
    moment(a.endDate).format("YYYY-MM-DD")
  ).toString();
  const bodyParams = {
    startDate: startDate,
    endDate: endDate,
    unit: unit,
    persona: currentPersonaFormatted(currentPersona)
  };
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/7daysCrudeBlendChart`,
    bodyParams
  );
};

export const postCrudeArrivalChart = (period, materialType) => {
  const startDate = _.map(period, (a) =>
    moment(a.startDate).format("YYYY-MM-DD")
  ).toString();
  const endDate = _.map(period, (a) =>
    moment(a.endDate).format("YYYY-MM-DD")
  ).toString();
  const matType = materialType === 'All Material' ? 'All' : materialType;
  const bodyParams = {
    startDate: startDate,
    endDate: endDate,
    matType: matType,
  };
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/crudeArrivals`,
    bodyParams
  );
};

export const postRecentSearch = (currentPersona) => {
  const bodyParams = {
    component: "recent-search",
    persona: currentPersonaFormatted(currentPersona),
  };
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/recentSearch`,
    bodyParams
  );
};

export const postFavourites = (currentPersona) => {
  const bodyParams = {
    persona: currentPersonaFormatted(currentPersona),
  };
  return LAMBDA_API_INSTANCE.post(`${CONTROLLER_NAME}/favorites`, bodyParams);
};

// Notification

export const postNotification = (currentPersona) => {
  const bodyParams = {
    persona: currentPersonaFormatted(currentPersona),
  };
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/notifications`,
    bodyParams
  );
};

export const postSaveNotificationStatus = (id) => {
  const bodyParams = {
    notificationID: id,
  };
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/saveNotificationStatus`,
    bodyParams
  );
};

export const postCamLimitNotification = (cduFeedUnit, period, dataSource) => {
  const bodyParams = {
    cduFeedPropertyUnit: cduFeedUnit,
    startDate: period,
    endDate: period,
    dataSource: dataSource,
  };
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/camLimitNotifications`,
    bodyParams
  );
};

// Highlights

export const postHighlights = (currentPersona, period, unit) => {
  const startDate = _.map(period, (a) =>
    moment(a.startDate).format("YYYY-MM-DD")
  ).toString();
  const endDate = _.map(period, (a) =>
    moment(a.endDate).format("YYYY-MM-DD")
  ).toString();
  const bodyParams = {
    startDate: startDate,
    endDate: endDate,
    unit: unit,
    persona: currentPersonaFormatted(currentPersona),
    component: "highlights",
  };
  return LAMBDA_API_INSTANCE.post(
    `${CONTROLLER_NAME}/highlights`,
    bodyParams
  );
};

export const getList = (postData) => {
  return LAMBDA_API_INSTANCE.post("/search/globalsearch", postData);
};

// Freeform 


export const postFreeformTrend = (
  currentPersona,
  period,
  color1,
  color2,
  color3,
  color4,
  color5,
  dataSource1,
  dataSource2,
  dataSource3,
  dataSource4,
  dataSource5,
  unit1,
  unit2,
  unit3,
  unit4,
  unit5,
  stream1,
  stream2,
  stream3,
  stream4,
  stream5,
  property1,
  property2,
  property3,
  property4,
  property5
) => {
  const startDate = _.map(period, (item) => item.startDate)
  const endDate = _.map(period, (item) => item.endDate)

  const freeformList = [
    {
      color: color1,
      dataSource: dataSource1,
      unit: unit1,
      fraction: stream1,
      property: property1,
    },
    {
      color: color2,
      dataSource: dataSource2,
      unit: unit2,
      fraction: stream2,
      property: property2,
    },
    {
      color: color3,
      dataSource: dataSource3,
      unit: unit3,
      fraction: stream3,
      property: property3,
    },
    {
      color: color4,
      dataSource: dataSource4,
      unit: unit4,
      fraction: stream4,
      property: property4,
    },
    {
      color: color5,
      dataSource: dataSource5,
      unit: unit5,
      fraction: stream5,
      property: property5,
    },
  ]

  const formatSearchObject = _.map(freeformList, (item) =>
    !_.isEmpty(item.property)
      ? {
          color: item.color,
          dataSource: item.dataSource,
          unit: item.unit,
          fraction: item.fraction,
          property: item.property,
        }
      : {}
  )
  const filterSearchObject = _.filter(formatSearchObject, (v) => _.keys(v).length !== 0)

  const postBody = {
    component: 'statistical-compare',
    crudeList: [],
    crudeType: 'Blend',
    startDate: moment(startDate.toString()).format('YYYY-MM-DD'),
    endDate: moment(endDate.toString()).format('YYYY-MM-DD'),
    persona: currentPersonaFormatted(currentPersona),
    freeformList: filterSearchObject,
  }
  return LAMBDA_API_INSTANCE.post(`/statistical/freeformChart`, postBody)
}
