import React, { useState, useCallback, useEffect, useMemo } from 'react'
import _ from 'lodash'
import moment from 'moment'
import jwt from 'jsonwebtoken'
import { Grid, Header, Table, Input, Button, Dropdown, Icon, Modal } from 'semantic-ui-react'
import { useJsonToCsv } from 'react-json-csv'
import NoDataBlock from '../../../../../components/no-data-block'

//Helpers
import { getEstimatedCorrosionRateLevelClassName } from '../../helpers/getCorrosionLevelRateClass'

//Redux
import { CORROSION_PAGE_REDUCER } from '../../../../../reducers/corrosionPageReducer'
import { MASTER_DATA_REDUCER } from '../../../../../reducers/common/masterDataReducer'
import { SESSION_REDUCER } from '../../../../../reducers/common/sessionReducer'
import * as CorrosionRatePageActions from '../../../../../actions/actionCorrosionRate'
import * as MasterDataActions from '../../../../../actions/common/actionMasterData'

//Hooks
import { useStateWithPaths, useActions } from '../../../../../hooks/useConnect'

//Constants
import * as corrosionPageConstants from '../../helpers/constants'
import * as UserGroup from '../../../../../constants/userGroups'

//#region Private

const TableSummary = ({ data, updateTableDataCallback, originalData }) => {
  const [{ materialNames }] = useStateWithPaths([`${MASTER_DATA_REDUCER}`])
  const [{ searchPanel }] = useStateWithPaths([`${CORROSION_PAGE_REDUCER}`])
  const [{ idToken }] = useStateWithPaths([`${SESSION_REDUCER}`])

  const idTokenDetails = jwt.decode(idToken)
  let tokenGroups = _.get(idTokenDetails, 'name') || ''
  tokenGroups = tokenGroups.replace('[', '').replace(']', '').split(', ')

  const ENTS_DEV = _.intersection(tokenGroups, ['AWS-CWP_groups_ents_dev'])
  const ENTS_PROD = _.intersection(tokenGroups, ['AWS-CWP_groups_ents_prod'])

  const disabledPersona = _.isEmpty(ENTS_DEV) || _.isEmpty(ENTS_PROD) ? 'disabled-button' : ''

  const { simulateSummaryTable, saveSummaryTable } = useActions({
    simulateSummaryTable: CorrosionRatePageActions.simulateSummaryTable,
    saveSummaryTable: CorrosionRatePageActions.saveSummaryTable,
  })

  const [isOpenSaveResultModal, setIsOpenSaveResultModal] = useState(false)

  const updateTableDataByValue = useCallback(
    (index, propertyName, value) => {
      let newData = [...data]
      let newRowData = { ...newData[index] }

      newRowData[propertyName].value = value

      //update strengthOfNh4Hs value
      newRowData['strengthOfNh4Hs'].value = '-'
      newRowData['estimatedCorrosionRateLevel'].value = '-'

      newData[index] = newRowData
      updateTableDataCallback(newData)
    },
    [data, updateTableDataCallback]
  )

  const isDisableButton = (isResultFieldMustHaveValue) => {
    let hasChangeOnStrengthOfNh4Hs = false
    let isEmptyFields = false
    _.forEach(data, (item) => {
      if (
        _.isEmpty(_.get(item, 'materialAtRiskLocations.value')) ||
        !item.unitFeedRate.value ||
        !item.washWater.value
      ) {
        isEmptyFields = true
        return false
      }

      if (!hasChangeOnStrengthOfNh4Hs) {
        hasChangeOnStrengthOfNh4Hs = _.get(item, 'strengthOfNh4Hs.value') === '-'
      }
    })

    let isDisable = false

    if (isResultFieldMustHaveValue) {
      isDisable = isEmptyFields || hasChangeOnStrengthOfNh4Hs
    } else {
      isDisable = isEmptyFields || !hasChangeOnStrengthOfNh4Hs
    }

    return isDisable
  }

  const onClickSimulateResult = (summaryTableData) => {
    simulateSummaryTable({
      saveType: corrosionPageConstants.SEARCH_HIGHTEMP_NHNS.NHNS,
      isSave: false,
      summaryTable: summaryTableData,
      crudeType: _.get(searchPanel, 'crudeMode'),
    })
  }

  const onClickSaveResult = (summaryTableData) => {
    saveSummaryTable(
      {
        saveType: corrosionPageConstants.SEARCH_HIGHTEMP_NHNS.NHNS,
        isSave: true,
        summaryTable: summaryTableData,
        crudeType: _.get(searchPanel, 'crudeMode'),
      },
      () => {
        setIsOpenSaveResultModal(true)
      }
    )
  }

  return (
    <>
      <Table celled structured textAlign="center" className="table-summary">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Processing Unit</Table.HeaderCell>
            <Table.HeaderCell>Fraction</Table.HeaderCell>
            <Table.HeaderCell>Total Nitrogen (ppm)</Table.HeaderCell>
            <Table.HeaderCell>Material at Risk Locations</Table.HeaderCell>
            <Table.HeaderCell>Unit Feed Rate (tpd)</Table.HeaderCell>
            <Table.HeaderCell>Wash Water (tpd)</Table.HeaderCell>
            <Table.HeaderCell>%Strength of NH4HS</Table.HeaderCell>
            <Table.HeaderCell>Estimated Corrosion Rate Level</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data.map((item, index) => {
            return (
              <Table.Row key={index}>
                <Table.Cell>{item.processingUnit.value}</Table.Cell>
                <Table.Cell>{item.fraction.value}</Table.Cell>
                <Table.Cell>{item.totalNitrogen.value}</Table.Cell>
                <Table.Cell>
                  <Dropdown
                    placeholder="Select Location*"
                    className={`ddl ddl-secondary full-width`}
                    value={item.materialAtRiskLocations.value}
                    selection
                    error={_.isEmpty(item.materialAtRiskLocations.value)}
                    options={materialNames}
                    onChange={(e, ddlItem) =>
                      updateTableDataByValue(index, 'materialAtRiskLocations', ddlItem.value)
                    }
                  />
                </Table.Cell>
                <Table.Cell>
                  <Input
                    type="number"
                    className="grey-border input-decimal"
                    error={!item.unitFeedRate.value}
                    onChange={({ target = {} }) => {
                      let inputValue = parseFloat(target.value)
                      inputValue = _.isNaN(inputValue) ? null : inputValue
                      updateTableDataByValue(index, 'unitFeedRate', inputValue)
                    }}
                    value={item.unitFeedRate.value}
                    placeholder="--*"
                  ></Input>
                </Table.Cell>
                <Table.Cell>
                  <Input
                    type="number"
                    className="grey-border input-decimal"
                    error={!item.washWater.value}
                    onChange={({ target = {} }) => {
                      let inputValue = parseFloat(target.value)
                      inputValue = _.isNaN(inputValue) ? null : inputValue
                      updateTableDataByValue(index, 'washWater', inputValue)
                    }}
                    value={item.washWater.value}
                    placeholder="--*"
                  ></Input>
                </Table.Cell>
                <Table.Cell>
                  {item.strengthOfNh4Hs.value ? item.strengthOfNh4Hs.value : '-'}
                </Table.Cell>
                <Table.Cell>
                  <div
                    className={`estimated-corrosion-rate-level ${getEstimatedCorrosionRateLevelClassName(
                      item.estimatedCorrosionRateLevel.value
                    )}`}
                  >
                    {item.estimatedCorrosionRateLevel.value
                      ? item.estimatedCorrosionRateLevel.value
                      : '-'}
                  </div>
                </Table.Cell>
              </Table.Row>
            )
          })}

          {/* Buttons in table */}
          <Table.Row>
            <Table.Cell colSpan={6} textAlign="right">
              <Button
                className="btn-primary"
                disabled={isDisableButton()}
                onClick={() => onClickSimulateResult(data)}
              >
                Simulate Result
              </Button>
              <Button
                className={`btn-secondary icon ${disabledPersona}`}
                disabled={isDisableButton(true)}
                onClick={() => onClickSaveResult(data)}
              >
                Save All <Icon name="save outline"></Icon>
              </Button>
            </Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <SaveSummaryResultModal open={isOpenSaveResultModal} setOpen={setIsOpenSaveResultModal} />
    </>
  )
}

const SaveSummaryResultModal = ({ open, setOpen }) => {
  return (
    <Modal
      centered={false}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      size={'small'}
    >
      <Modal.Header>Result</Modal.Header>
      <Modal.Content>
        <Modal.Description>Saved successfully.</Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button className="btn-primary" onClick={() => setOpen(false)}>
          OK
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

//#endregion

const NhHsCorrosionSummaryTable = () => {
  const [{ searchResult, searchPanel }] = useStateWithPaths([`${CORROSION_PAGE_REDUCER}`])
  const { isSearchByCrudeBlendDate } = searchPanel
  const { saveAsCsv } = useJsonToCsv()

  const { getMasterMaterialNames } = useActions({
    getMasterMaterialNames: MasterDataActions.getMasterMaterialNames,
  })

  useEffect(() => {
    getMasterMaterialNames(true)
  }, [getMasterMaterialNames])

  const tableData = _.get(searchResult, 'summaryTable.data')

  const tableDataForExport = useMemo(() => {
    return _.map(tableData, (item, index) => {
      return _.mapValues(item, (value, key) => {
        return _.get(value, 'value')
      })
    })
  }, [tableData])

  const clickExport = useCallback(
    (e) => {
      const filename = 'Summary Table',
        fields = {
          processingUnit: 'Processing Unit',
          fraction: 'Fraction',
          totalNitrogen: 'Total Nitrogen (ppm)',
          materialAtRiskLocations: 'Material at Risk Locations',
          unitFeedRate: 'Unit Feed Rate',
          washWater: 'Wash Water',
          strengthOfNh4Hs: '%Strength of NH4HS',
          estimatedCorrosionRateLevel: 'Estimated Corrosion Rate Level',
        },
        style = {
          padding: '5px',
        },
        // fields = {
        //     "index": "Index",
        //     "guid": "GUID"
        //   },
        // data = [
        //     { index: 0, guid: 'asdf231234' },
        //     { index: 1, guid: 'wetr2343af' }
        // ],
        data = tableDataForExport
      saveAsCsv({ data, fields, filename, style })
    },
    [tableDataForExport, saveAsCsv]
  )

  const [editableTableData, setEditableTableData] = useState(tableData)

  useEffect(() => {
    setEditableTableData(tableData)
  }, [tableData])

  if (_.isEqualWith(editableTableData, 'Data Unavailable')) {
    return <NoDataBlock />
  }

  return (
    <div className="section section-summary-table">
      <Grid padded>
        <Grid.Row>
          <Grid.Column textAlign="left">
            <div className="flex center space-between">
              <div>
                <Header size="small" textAlign="left">
                  SUMMARY TABLE
                </Header>
              </div>
              <div>
                <Button className="btn-secondary icon" onClick={clickExport}>
                  Export <Icon name="external alternate"></Icon>
                </Button>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        {!_.isEmpty(tableData) && !_.isEmpty(tableData[0].date) && !isSearchByCrudeBlendDate && (
          <Grid.Row>
            <div className={`flex pad-2 mar-x-0_5 mar-bottom-1 blend-container width-100`}>
              <div className={`mar-right-0_5`}>Blend date for the selected criteria is</div>
              <div className={`blend-date-value`}>
                {!_.isEmpty(tableData) && !_.isEmpty(tableData[0].date)
                  ? _.upperCase(moment(tableData[0].date).format('DD MMM YYYY'))
                  : 'N.A.'}
              </div>
            </div>
          </Grid.Row>
        )}

        <Grid.Row columns={1}>
          <Grid.Column>
            <div>
              <TableSummary
                data={editableTableData}
                updateTableDataCallback={setEditableTableData}
                originalData={tableData}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default NhHsCorrosionSummaryTable
