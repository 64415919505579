import React, { useReducer, useRef, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'

// Components
import { SingleCrudeChart } from '../../../components/charts/single-crude-chart/single-crude-chart'
import { CorrosionPropertyChart } from '../../../components/charts/single-crude-chart/corrosion-property'
import { Grid, Header, Segment, Divider, Dropdown, Button } from 'semantic-ui-react'
import Chevron from '../../../components/chevron'
import PeriodPicker from '../../../components/period-picker'
import RadioButton from '../../../components/radio-button'
import SearchInput from '../../../components/search/search-input/searchInput'
import FetchStateWrapper from '../../../components/fetch-state-wrapper'
import NoDataBlock from '../../../components/no-data-block'

import { stringToDecimalPoint } from '../../../helpers/variables'

// Hooks
import { useStateWithPaths, useActions } from '../../../hooks/useConnect'
import { LANDING_PAGE_REDUCER } from '../../../reducers/landingPageReducer'

// Action
import { PERSONAL_SETTING_REDUCER } from '../../../reducers/common/personalSettingReducer'
import * as SingleCrudeActions from '../../../actions/actionLanding'
import { MASTER_DATA_REDUCER } from '../../../reducers/common/masterDataReducer'
import * as MasterDataActions from '../../../actions/common/actionMasterData'
import { getMasterSingleCrudeUnits } from '../../../actions/common/actionMasterData'
import { setSingleCrudeChart } from '../../../actions/actionLanding'

// Constant
import * as UserGroup from '../../../constants/userGroups'

const renderCorrosionProperty = (isCorrosionProperty, setisCorrosionProperty) => {
  const menu = [
    {
      label: 'Past Processing',
      value: true,
    },
    {
      label: 'Corrosion Property',
      value: false,
    },
  ]
  return (
    <RadioButton
      menuData={menu}
      selectCallback={setisCorrosionProperty}
      selectedValue={isCorrosionProperty}
    />
  )
}

const SingleCrudeContainer = (props) => {
  const dispatch = useDispatch()

  _.each(props.crudeNames, (item) => {
    return (item.title = item.crudeCode + ' - ' + item.crudeName)
  })

  const [singleCrudeChartData] = useStateWithPaths(['landingPage.singleCrudeChart'])

  const timesPurchased = _.get(_.get(singleCrudeChartData, 'data'), 'timesPurchased')
  const percentagePurchased = _.get(_.get(singleCrudeChartData, 'data'), 'percentagePurchased')
  const maxHistory = _.get(_.get(singleCrudeChartData, 'data'), 'maxHistory')
  const maxHistoryDate = _.get(_.get(singleCrudeChartData, 'data'), 'maxHistoryDate')
  const maxInRange = _.get(_.get(singleCrudeChartData, 'data'), 'maxInRange')
  const maxInRangeDate = _.get(_.get(singleCrudeChartData, 'data'), 'maxInRangeDate')

  const getRejection = _.get(_.get(singleCrudeChartData, 'data'), 'rejection')

  const currentPersonaFormatted = _.isEqual(props.currentPersona, UserGroup.SC)
    ? 'SC'
    : _.isEqual(props.currentPersona, UserGroup.ENTS)
    ? 'ENTS'
    : _.isEqual(props.currentPersona, UserGroup.TN)
    ? 'TN'
    : ''

  return (
    <div className={`single-crude-info-container`}>
      <div className={`flex column height-100 space-between`}>
        <div>
          {_.isEqual(currentPersonaFormatted, 'ENTS') &&
            renderCorrosionProperty(
              props.isCorrosionProperty,
              props.setisCorrosionProperty,
              currentPersonaFormatted
            )}

          {props.isCorrosionProperty && (
            <>
              <div className={`flex pad-top-1 column width-100`}>
                <div className={`flex`}>
                  <SearchInput
                    className={`search-input-container`}
                    displayProperty="title"
                    displayInListProperty="title"
                    defaultSelection={props.crudeNameAbbreviation}
                    selectionOptions={props.crudeNames}
                    onSelectionUpdate={(selectedResult) => {
                      props.setCrudeNameAbbreviation(selectedResult)
                    }}
                    placeholder="Enter crude name"
                  />
                </div>

                <div className={`flex`}></div>
              </div>
              <div className={`flex space-between center pad-top-1`}>
                <div className={`flex center`}>
                  <div className={`single-crude-code`}>
                    {_.get(props.crudeNameAbbreviation, 'crudeCode')}
                  </div>
                  <div className={`single-crude-name`}>
                    {_.get(props.crudeNameAbbreviation, 'crudeName')}
                  </div>
                </div>
              </div>
              <Divider />
              <div className={`single-crude-description pad-bottom-0_5`}>
                <span>
                  {_.get(props.crudeNameAbbreviation, 'crudeName')} has been purchased{' '}
                  <span className={`single-crude-max-value`}>
                    {!_.isEmpty(timesPurchased) ? 'N.A' : timesPurchased}
                  </span>{' '}
                  times or{' '}
                  <span className={`single-crude-max-value`}>
                    {!_.isEmpty(percentagePurchased) ? 'N.A' : percentagePurchased}%
                  </span>{' '}
                  of all crude purchase in Thaioil since 2017
                </span>
              </div>
              {!_.isEmpty(getRejection) && (
                <div className={`single-crude-description pad-top-1`}>
                  <span>The crude cannot be processed at {_.join(getRejection, ', ')}</span>
                </div>
              )}
              <div>
                <div className={`flex `}>
                  <div className={`flex width-50 column pad-y-2_5`}>
                    <div className={`single-crude-max-title`}>MAX % Processing in history: </div>
                    <div className={`flex single-crude-value-container`}>
                      <div className={`single-crude-max-value`}>
                        {_.isEmpty(maxHistory) ? stringToDecimalPoint(maxHistory, 1, 1) : 'N.A'}%
                      </div>
                      <div className={`single-crude-max-date`}>
                        on {''}
                        {_.isEmpty(maxHistoryDate)
                          ? 'N.A'
                          : moment(maxHistoryDate).format('DD MMM YYYY')}
                      </div>
                    </div>
                  </div>
                  <div className={`flex width-50 column pad-y-2_5`}>
                    <div className={`single-crude-max-title`}>
                      MAX % Processing in selected time:
                    </div>
                    <div className={`flex single-crude-value-container`}>
                      <div className={`single-crude-max-value`}>
                        {_.isEmpty(maxInRange) ? stringToDecimalPoint(maxInRange, 1, 1) : 'N.A'}%
                      </div>
                      <div className={`single-crude-max-date`}>
                        on {''}
                        {_.isEmpty(maxInRangeDate)
                          ? 'N.A'
                          : moment(maxInRangeDate).format('DD MMM YYYY')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {!props.isCorrosionProperty && (
            <>
              <div className={`flex pad-top-1 column width-100`}>
                <div>
                  <SearchInput
                    className={`search-input-container`}
                    displayProperty="title"
                    displayInListProperty="title"
                    defaultSelection={props.crudeNameAbbreviation}
                    selectionOptions={props.crudeNames}
                    onSelectionUpdate={(selectedResult) => {
                      props.setCrudeNameAbbreviation(selectedResult)
                    }}
                    placeholder="Enter crude name"
                  />
                </div>
                <div className={`flex`}>
                  <Dropdown
                    placeholder="Select Property"
                    className={`single-crude-dropdown`}
                    value={props.property}
                    defaultValue={props.property}
                    selection
                    options={props.singleCrudeProperties}
                    onChange={(e, data) => {
                      props.setProperty(data.value)
                    }}
                  />
                </div>
              </div>
              <div className={`flex space-between center pad-top-1`}>
                <div className={`flex center`}>
                  <div className={`single-crude-code`}>
                    {_.get(props.crudeNameAbbreviation, 'crudeCode')}
                  </div>
                  <div className={`single-crude-name`}>
                    {_.get(props.crudeNameAbbreviation, 'crudeName')}
                  </div>
                </div>
              </div>
              <Divider />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const SingleCrude = () => {
  const dispatch = useDispatch()
  const [singleCrudeChartData] = useStateWithPaths(['landingPage.singleCrudeChart'])
  const [{ currentPersona }] = useStateWithPaths([`${PERSONAL_SETTING_REDUCER}`])

  const [{ crudeNames, singleCrudeUnits, singleCrudeProperties }] = useStateWithPaths([
    `${MASTER_DATA_REDUCER}`,
  ])

  const {
    getMasterCrudeNames,
    getMasterSingleCrudeProperties,
    getMasterSingleCrudeUnits,
  } = useActions({
    getMasterCrudeNames: MasterDataActions.getMasterCrudeNames,
    getMasterSingleCrudeProperties: MasterDataActions.getMasterSingleCrudeProperties,
    getMasterSingleCrudeUnits: MasterDataActions.getMasterSingleCrudeUnits,
  })

  const [{ landingPage }] = useStateWithPaths([`${LANDING_PAGE_REDUCER}`])
  const [isCorrosionProperty, setisCorrosionProperty] = useState(
    landingPage.isSelectCorrosionProperty
  )
  const [unit, setUnit] = useState(singleCrudeChartData.unit)
  const [property, setProperty] = useState(singleCrudeChartData.property)

  const [crudeNameAbbreviation, setCrudeNameAbbreviation] = useState(
    singleCrudeChartData.crudeNameAbbreviation
  )

  const crudeCode = _.get(crudeNameAbbreviation, 'crudeCode')
  const chartType = isCorrosionProperty === true ? 'processing' : 'corrosion'

  const [defaultPeriod] = useState([singleCrudeChartData.defaultPeriod])
  const [periods] = useStateWithPaths(['landingPage.singleCrudeChart.period'])
  const [period, setPeriod] = useState([periods])

  const getSingleCrudeData = _.get(singleCrudeChartData, 'data')

  useEffect(() => {
    getMasterSingleCrudeProperties(currentPersona)
    getMasterCrudeNames()
    getMasterSingleCrudeUnits()
  }, [
    getMasterSingleCrudeUnits,
    getMasterSingleCrudeProperties,
    getMasterCrudeNames,
    currentPersona,
  ])

  useEffect(() => {
    dispatch(setSingleCrudeChart(period, chartType, unit, crudeNameAbbreviation, property))
  }, [period, chartType, unit, crudeNameAbbreviation, property])
  return (
    <Grid padded className={`single-crude-chart`}>
      <Grid.Row columns={2} verticalAlign="bottom">
        <Grid.Column textAlign="left">
          <Header size="small" textAlign="left">
            SINGLE CRUDE
          </Header>
        </Grid.Column>
        <Grid.Column>
          <div className={`flex end`}>
            <Dropdown
              placeholder="Select unit"
              className={`cdu-unit-dropdown-container`}
              disabled={!isCorrosionProperty}
              value={unit}
              defaultValue={unit}
              selection
              options={singleCrudeUnits}
              onChange={(e, data) => {
                setUnit(data.value)
              }}
            />
            <PeriodPicker
              disabled={!isCorrosionProperty}
              defaultPeriod={defaultPeriod}
              period={period}
              setPeriod={setPeriod}
              onClick={setSingleCrudeChart(
                period,
                chartType,
                unit,
                crudeNameAbbreviation,
                property
              )}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row column={2}>
        <Segment className={`width-100`}>
          <div className={`flex pad-x-1_5 pad-y-2`}>
            <SingleCrudeContainer
              isCorrosionProperty={isCorrosionProperty}
              setisCorrosionProperty={setisCorrosionProperty}
              crudeNameAbbreviation={crudeNameAbbreviation}
              setCrudeNameAbbreviation={setCrudeNameAbbreviation}
              property={property}
              setProperty={setProperty}
              currentPersona={currentPersona}
              crudeNames={crudeNames}
              singleCrudeProperties={singleCrudeProperties}
            />
            <FetchStateWrapper id={SingleCrudeActions.POST_SINGLE_CRUDE_CHART}>
              {isCorrosionProperty && (
                <div className={`flex center width-100`}>
                  <Button
                    className={``}
                    onClick={() => {
                      const startDate = moment(_.get(getSingleCrudeData, 'prevStartDate'))
                      const endDate = moment(_.get(getSingleCrudeData, 'prevEndDate'))
                      return setPeriod([
                        {
                          startDate: startDate._d,
                          endDate: endDate._d,
                          key: 'selection',
                        },
                      ])
                    }}
                  >
                    <Chevron direction="left" />
                  </Button>
                  {_.isEmpty(_.get(singleCrudeChartData.data.chart, 'series')) ? (
                    <NoDataBlock />
                  ) : (
                    <SingleCrudeChart singleCrudeChartData={singleCrudeChartData} period={period} />
                  )}
                  <Button
                    className={``}
                    onClick={() => {
                      const startDate = moment(_.get(getSingleCrudeData, 'nextStartDate'))
                      const endDate = moment(_.get(getSingleCrudeData, 'nextEndDate'))
                      return setPeriod([
                        {
                          startDate: startDate._d,
                          endDate: endDate._d,
                          key: 'selection',
                        },
                      ])
                    }}
                  >
                    <Chevron direction="right" />
                  </Button>
                </div>
              )}

              {!isCorrosionProperty && (
                <div className={`flex center width-100`}>
                  <CorrosionPropertyChart singleCrudeChartData={singleCrudeChartData} />
                </div>
              )}
            </FetchStateWrapper>
          </div>
        </Segment>
      </Grid.Row>
    </Grid>
  )
}

export default SingleCrude
