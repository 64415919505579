import _ from 'lodash'
import queryString from 'query-string'

//TODO: Try not to use the functions below, common function should implemented in the lambdaServer.js
export const determineResponseResult = (response) => {
	if (_.get(response.data, 'status.code') !== 200) {
		// throw new Error(response.data.data)
	}
	return response.data.data
}

export const determineLambdaResponseResult = (response) => {
	if (_.get(response.data, 'status.code') !== 200) {
		// throw new Error(response.data.data)
	}
	return response.data
}

export const checkStatusCode = (response) => {
	if (_.get(response.data, 'status.code') !== 200) {
		throw new Error(response.data.data)
	}
	return response.data.data
}

export function getUrlKeyValue(key) {
	const url = new URL(window.location.href);
	return url.searchParams.get(key);
}

export function getUrlHashValueBykey(key) {
	const url = new URL(window.location.href);
	const hashObj = queryString.parse(url.hash);
	return _.get(hashObj, key);
}

export function getRootUrl(url = window.location.href) {
	const path = url.split('/');
	path.length = 3;
	return path.join('/');
}