
import React from 'react'

import ToHeader from './../toHeader';
import HeaderMenuTop from './../header-menu-top';

const AppHeader = () => {

    return (
        <>
            <ToHeader />
            <HeaderMenuTop />
        </>
    )
}

export default AppHeader;