import _ from 'lodash'

export const rowSpanTableDataByProperties = (data, groupByProperty, valueProperty) => {

    const groupByData = _.groupBy(data, valueProperty);
    _.map(data, (item) => {
        const itemValue = _.get(item, valueProperty);
        const countItems = _.get(groupByData[itemValue], 'length') || 0;
        return item[groupByProperty].rowSpan = countItems;
    })
    return data;
}

export const removeRowSpanTableDataByProperties = (data, property) => {
    _.map(data, (item) => {
        delete item[property].rowSpan
        return item
    })
    return data;
}