import React, { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import { Grid, Input, Button, Dropdown, Popup, Icon } from 'semantic-ui-react'
import { Calendar } from 'react-date-range'
import moment from 'moment'
import { endOfMonth } from 'date-fns'

//Components
import RadioMenu from '../../../../components/radio-menu'
import RadioButton from '../../../../components/radio-button'
import SearchInput from '../../../../components/search/search-input/searchInput'
import FetchStateWrapper from '../../../../components/fetch-state-wrapper'

//Redux
import { CORROSION_PAGE_REDUCER } from '../../../../reducers/corrosionPageReducer'
import { PERSONAL_SETTING_REDUCER } from '../../../../reducers/common/personalSettingReducer'

import { MASTER_DATA_REDUCER } from '../../../../reducers/common/masterDataReducer'
import * as CorrosionRatePageActions from '../../../../actions/actionCorrosionRate'
import * as MasterDataActions from '../../../../actions/common/actionMasterData'

//Hooks
import { useStateWithPaths, useActions } from '../../../../hooks/useConnect'

//Helper
import * as corrosionPageConstants from '../helpers/constants'

//#region Private Methods
const renderHighTempNhHS = (highTempNhHs, setHighTempNhNs) => {
  const menu = [
    {
      label: 'High Temp. Sulphidic and Naphthenic Acid Corrosion',
      value: 'HT',
    },
    {
      label: 'NH4HS Corrosion',
      value: 'NH',
    },
  ]
  return <RadioMenu menuData={menu} selectCallback={setHighTempNhNs} selectedValue={highTempNhHs} />
}

const renderCrudeMode = (crudeMode, setCrudeMode) => {
  const menu = [
    {
      label: 'Single Crude',
      value: 'single',
    },
    {
      label: 'Crude Blend',
      value: 'blend',
    },
  ]

  return <RadioMenu menuData={menu} selectCallback={setCrudeMode} selectedValue={crudeMode} />
}

const renderCalculationResultMode = (calculationResultMode, setCalculationResultMode) => {
  const menu = [
    {
      label: 'Calculate Corrosion Rate',
      value: 'calculateCorrosionRate',
    },
    {
      label: 'Create Summary Table',
      value: 'createSummary',
    },
  ]

  return (
    <RadioMenu
      menuData={menu}
      selectCallback={setCalculationResultMode}
      selectedValue={calculationResultMode}
    />
  )
}

const renderBlendDateComposition = (isSearchByCrudeBlendDate, setCallback) => {
  const menu = [
    {
      label: 'Blend Date',
      value: true,
    },
    {
      label: 'Blend Composition',
      value: false,
    },
  ]
  return (
    <RadioButton
      menuData={menu}
      className="color-white"
      selectCallback={setCallback}
      selectedValue={isSearchByCrudeBlendDate}
    />
  )
}
//#endregion

const SearchPanel = (props) => {
  const [{ searchPanel }] = useStateWithPaths([`${CORROSION_PAGE_REDUCER}`])
  const [{ currentPersona }] = useStateWithPaths([`${PERSONAL_SETTING_REDUCER}`])
  const [
    { crudeNames, materialNames, corrosionUnits, corrosionUnitsDS, corrosionFractions },
  ] = useStateWithPaths([`${MASTER_DATA_REDUCER}`])

  _.each(crudeNames, (item) => {
    return (item.title = item.crudeCode + ' - ' + item.crudeName)
  })

  const {
    calculateCorrosionRate,
    getMasterCrudeNames,
    getMasterCorrosionFractions,
    getMasterCorrosionUnits,
    getMasterCorrosionUnitsDS,
    getMasterMaterialNames,
  } = useActions({
    calculateCorrosionRate: CorrosionRatePageActions.calculateCorrosionRate,
    getMasterCrudeNames: MasterDataActions.getMasterCrudeNames,
    getMasterCorrosionFractions: MasterDataActions.getMasterCorrosionFractions,
    getMasterCorrosionUnits: MasterDataActions.getMasterCorrosionUnits,
    getMasterCorrosionUnitsDS: MasterDataActions.getMasterCorrosionUnitsDS,
    getMasterMaterialNames: MasterDataActions.getMasterMaterialNames,
  })

  const [highTempNhHs, setHighTempNhHs] = useState(searchPanel.highTempNhHs)
  const [crudeMode, setCrudeMode] = useState(searchPanel.crudeMode)
  const [calculateResultMode, setCalculateResultMode] = useState(searchPanel.calculateResultMode)

  const [crudeNameAbbreviation, setCrudeNameAbbreviation] = useState(
    searchPanel.crudeNameAbbreviation
  )
  const [operatingTemperature, setOperatingTemperature] = useState(searchPanel.operatingTemperature)
  const [unit, setUnit] = useState(searchPanel.unit)
  const [operatingUnit, setOperatingUnit] = useState(searchPanel.operatingUnit)
  const [fraction, setFraction] = useState(searchPanel.fraction)
  const [material, setMaterial] = useState(searchPanel.material)
  const [blendDate, setBlendDate] = useState(searchPanel.blendDate)
  const [unitFeedRate, setUnitFeedRate] = useState(searchPanel.unitFeedRate)
  const [washWater, setWashWater] = useState(searchPanel.washWater)

  const [inputUnit1, setInputUnit1] = useState(searchPanel.inputUnits.unit1)
  const [inputPercent1, setInputPercent1] = useState(searchPanel.inputUnits.unitPercent1)
  const [inputUnit2, setInputUnit2] = useState(searchPanel.inputUnits.unit2)
  const [inputPercent2, setInputPercent2] = useState(searchPanel.inputUnits.unitPercent2)
  const [inputUnit3, setInputUnit3] = useState(searchPanel.inputUnits.unit3)
  const [inputPercent3, setInputPercent3] = useState(searchPanel.inputUnits.unitPercent3)
  const [inputUnit4, setInputUnit4] = useState(searchPanel.inputUnits.unit4)
  const [inputPercent4, setInputPercent4] = useState(searchPanel.inputUnits.unitPercent4)
  const [inputUnit5, setInputUnit5] = useState(searchPanel.inputUnits.unit5)
  const [inputPercent5, setInputPercent5] = useState(searchPanel.inputUnits.unitPercent5)
  const [inputUnit6, setInputUnit6] = useState(searchPanel.inputUnits.unit6)
  const [inputPercent6, setInputPercent6] = useState(searchPanel.inputUnits.unitPercent6)
  const [inputUnit7, setInputUnit7] = useState(searchPanel.inputUnits.unit7)
  const [inputPercent7, setInputPercent7] = useState(searchPanel.inputUnits.unitPercent7)
  const [inputUnit8, setInputUnit8] = useState(searchPanel.inputUnits.unit8)
  const [inputPercent8, setInputPercent8] = useState(searchPanel.inputUnits.unitPercent8)
  const [inputUnit9, setInputUnit9] = useState(searchPanel.inputUnits.unit9)
  const [inputPercent9, setInputPercent9] = useState(searchPanel.inputUnits.unitPercent9)
  const [inputUnit10, setInputUnit10] = useState(searchPanel.inputUnits.unit10)
  const [inputPercent10, setInputPercent10] = useState(searchPanel.inputUnits.unitPercent10)

  const [isSearchByCrudeBlendDate, setIsSearchByCrudeBlendDate] = useState(
    searchPanel.isSearchByCrudeBlendDate
  )
  const [errorInputs, setErrorInputs] = useState({})

  useEffect(() => {
    getMasterCrudeNames()
    getMasterCorrosionFractions(currentPersona, crudeMode, highTempNhHs, operatingUnit)
    getMasterCorrosionUnits(currentPersona, crudeMode)
    getMasterCorrosionUnitsDS(currentPersona, crudeMode, highTempNhHs)
    getMasterMaterialNames()
  }, [
    getMasterCrudeNames,
    getMasterMaterialNames,
    getMasterCorrosionFractions,
    getMasterCorrosionUnits,
    getMasterCorrosionUnitsDS,
    currentPersona,
    crudeMode,
    highTempNhHs,
    operatingUnit,
  ])

  const isPassValidationForCalculate = useCallback(() => {
    let newErrorInputs = {}
    //High temp, Single Crude, Calculate Ratio
    if (
      highTempNhHs === corrosionPageConstants.SEARCH_HIGHTEMP_NHNS.HIGH_TEMP &&
      crudeMode === corrosionPageConstants.SEARCH_CRUDE_MODE.SINGLE_CRUDE &&
      calculateResultMode ===
        corrosionPageConstants.SEARCH_CALCULATE_RESULT_MODE.CALCULATE_CORROSION_RATE
    ) {
      newErrorInputs = {
        crudeNameAbbreviation: _.isNull(crudeNameAbbreviation) || _.isEmpty(crudeNameAbbreviation),
        operatingTemperature:
          _.isNull(operatingTemperature) ||
          operatingTemperature === '' ||
          operatingTemperature < 200 ||
          operatingTemperature > 500,
        operatingUnit: !operatingUnit,
        fraction: !fraction,
        material: !material,
      }
      setErrorInputs(newErrorInputs)
    }
    //High temp or NHHS, Single Crude, Create Summary
    else if (
      (highTempNhHs === corrosionPageConstants.SEARCH_HIGHTEMP_NHNS.HIGH_TEMP ||
        highTempNhHs === corrosionPageConstants.SEARCH_HIGHTEMP_NHNS.NHNS) &&
      crudeMode === corrosionPageConstants.SEARCH_CRUDE_MODE.SINGLE_CRUDE &&
      calculateResultMode === corrosionPageConstants.SEARCH_CALCULATE_RESULT_MODE.CREATE_SUMMARY
    ) {
      newErrorInputs = {
        crudeNameAbbreviation: _.isNull(crudeNameAbbreviation) || _.isEmpty(crudeNameAbbreviation),
      }
      setErrorInputs(newErrorInputs)
    }
    //High temp, Crude Blend, Calculate Ratio
    else if (
      highTempNhHs === corrosionPageConstants.SEARCH_HIGHTEMP_NHNS.HIGH_TEMP &&
      crudeMode === corrosionPageConstants.SEARCH_CRUDE_MODE.CRUDE_BLEND &&
      calculateResultMode ===
        corrosionPageConstants.SEARCH_CALCULATE_RESULT_MODE.CALCULATE_CORROSION_RATE
    ) {
      newErrorInputs = {
        operatingTemperature:
          _.isNull(operatingTemperature) ||
          operatingTemperature === '' ||
          operatingTemperature < 200 ||
          operatingTemperature > 500,
        operatingUnit: !operatingUnit,
        fraction: !fraction,
        material: !material,
      }

      if (isSearchByCrudeBlendDate) {
        newErrorInputs.blendDate = _.isNull(blendDate) || !_.isDate(blendDate)
      } else {
        newErrorInputs.unit = !unit
        newErrorInputs.inputUnit1 = _.isNull(inputUnit1) || _.isEmpty(inputUnit1)
        newErrorInputs.inputPercent1 =
          _.isNull(inputPercent1) ||
          inputPercent1 === '' ||
          inputPercent1 < 0 ||
          inputPercent1 > 100
        newErrorInputs.inputUnit2 = _.isNull(inputUnit2) || _.isEmpty(inputUnit2)
        newErrorInputs.inputPercent2 =
          _.isNull(inputPercent2) ||
          inputPercent2 === '' ||
          inputPercent2 < 0 ||
          inputPercent2 > 100
      }

      setErrorInputs(newErrorInputs)
    }
    //High temp or NHHS, Crude Blend, Create Summary
    else if (
      (highTempNhHs === corrosionPageConstants.SEARCH_HIGHTEMP_NHNS.HIGH_TEMP ||
        highTempNhHs === corrosionPageConstants.SEARCH_HIGHTEMP_NHNS.NHNS) &&
      crudeMode === corrosionPageConstants.SEARCH_CRUDE_MODE.CRUDE_BLEND &&
      calculateResultMode === corrosionPageConstants.SEARCH_CALCULATE_RESULT_MODE.CREATE_SUMMARY
    ) {
      if (isSearchByCrudeBlendDate) {
        newErrorInputs.blendDate = _.isNull(blendDate) || !_.isDate(blendDate)
      } else {
        newErrorInputs.unit = !unit
        newErrorInputs.inputUnit1 = _.isNull(inputUnit1) || _.isEmpty(inputUnit1)
        newErrorInputs.inputPercent1 =
          _.isNull(inputPercent1) ||
          inputPercent1 === '' ||
          inputPercent1 < 0 ||
          inputPercent1 > 100
        newErrorInputs.inputUnit2 = _.isNull(inputUnit2) || _.isEmpty(inputUnit2)
        newErrorInputs.inputPercent2 =
          _.isNull(inputPercent2) ||
          inputPercent2 === '' ||
          inputPercent2 < 0 ||
          inputPercent2 > 100
      }
      setErrorInputs(newErrorInputs)
    }
    //Nhhs, Single Crude, Calculate Ratio
    else if (
      highTempNhHs === corrosionPageConstants.SEARCH_HIGHTEMP_NHNS.NHNS &&
      crudeMode === corrosionPageConstants.SEARCH_CRUDE_MODE.SINGLE_CRUDE &&
      calculateResultMode ===
        corrosionPageConstants.SEARCH_CALCULATE_RESULT_MODE.CALCULATE_CORROSION_RATE
    ) {
      newErrorInputs = {
        crudeNameAbbreviation: _.isNull(crudeNameAbbreviation) || _.isEmpty(crudeNameAbbreviation),
        operatingUnit: !operatingUnit,
        fraction: !fraction,
        unitFeedRate: _.isNull(unitFeedRate) || unitFeedRate === '',
        washWater: _.isNull(washWater) || washWater === '',
      }
      setErrorInputs(newErrorInputs)
    }
    //Nhhs, Crude Blend, Calculate Ratio
    else if (
      highTempNhHs === corrosionPageConstants.SEARCH_HIGHTEMP_NHNS.NHNS &&
      crudeMode === corrosionPageConstants.SEARCH_CRUDE_MODE.CRUDE_BLEND &&
      calculateResultMode ===
        corrosionPageConstants.SEARCH_CALCULATE_RESULT_MODE.CALCULATE_CORROSION_RATE
    ) {
      newErrorInputs = {
        operatingUnit: !operatingUnit,
        fraction: !fraction,
        unitFeedRate: _.isNull(unitFeedRate) || unitFeedRate === '',
        washWater: _.isNull(washWater) || washWater === '',
      }

      if (isSearchByCrudeBlendDate) {
        newErrorInputs.blendDate = _.isNull(blendDate) || !_.isDate(blendDate)
      } else {
        newErrorInputs.unit = !unit
        newErrorInputs.inputUnit1 = _.isNull(inputUnit1) || _.isEmpty(inputUnit1)
        newErrorInputs.inputPercent1 =
          _.isNull(inputPercent1) ||
          inputPercent1 === '' ||
          inputPercent1 < 0 ||
          inputPercent1 > 100
        newErrorInputs.inputUnit2 = _.isNull(inputUnit2) || _.isEmpty(inputUnit2)
        newErrorInputs.inputPercent2 =
          _.isNull(inputPercent2) ||
          inputPercent2 === '' ||
          inputPercent2 < 0 ||
          inputPercent2 > 100
      }

      setErrorInputs(newErrorInputs)
    }

    let hasError = false
    _.forOwn(newErrorInputs, (value, key) => {
      if (hasError) return

      hasError = value
    })
    return !hasError
  }, [
    crudeNameAbbreviation,
    operatingTemperature,
    blendDate,
    fraction,
    material,
    unit,
    operatingUnit,
    unitFeedRate,
    washWater,
    inputUnit1,
    inputPercent1,
    inputUnit2,
    inputPercent2,
    calculateResultMode,
    crudeMode,
    isSearchByCrudeBlendDate,
    highTempNhHs,
  ])

  const onClickCalculate = useCallback(() => {
    if (!isPassValidationForCalculate()) {
      return
    }

    calculateCorrosionRate({
      highTempNhHs,
      crudeMode,
      calculateResultMode,
      crudeNameAbbreviation,
      operatingTemperature,
      unit,
      operatingUnit,
      fraction,
      material,
      blendDate,
      unitFeedRate,
      washWater,
      isSearchByCrudeBlendDate,
      inputUnits: {
        unit1: inputUnit1,
        unitPercent1: inputPercent1,
        unit2: inputUnit2,
        unitPercent2: inputPercent2,
        unit3: inputUnit3,
        unitPercent3: inputPercent3,
        unit4: inputUnit4,
        unitPercent4: inputPercent4,
        unit5: inputUnit5,
        unitPercent5: inputPercent5,
        unit6: inputUnit6,
        unitPercent6: inputPercent6,
        unit7: inputUnit7,
        unitPercent7: inputPercent7,
        unit8: inputUnit8,
        unitPercent8: inputPercent8,
        unit9: inputUnit9,
        unitPercent9: inputPercent9,
        unit10: inputUnit10,
        unitPercent10: inputPercent10,
      },
    })
  }, [
    highTempNhHs,
    crudeMode,
    calculateResultMode,
    crudeNameAbbreviation,
    operatingTemperature,
    unit,
    operatingUnit,
    fraction,
    material,
    blendDate,
    unitFeedRate,
    washWater,
    isPassValidationForCalculate,
    calculateCorrosionRate,
    inputUnit1,
    inputUnit2,
    inputUnit3,
    inputUnit4,
    inputUnit5,
    inputUnit6,
    inputUnit7,
    inputUnit8,
    inputUnit9,
    inputUnit10,
    inputPercent1,
    inputPercent2,
    inputPercent3,
    inputPercent4,
    inputPercent5,
    inputPercent6,
    inputPercent7,
    inputPercent8,
    inputPercent9,
    inputPercent10,
    isSearchByCrudeBlendDate,
  ])

  const onClickReset = () => {
    setCrudeNameAbbreviation(null)
    setOperatingTemperature('')
    setBlendDate(null)
    setFraction('')
    setMaterial('')
    setUnit('')
    setOperatingUnit('')
    setUnitFeedRate('')
    setWashWater('')
    clearInputBlendComposition()
    calculateCorrosionRate({})
  }

  const clearInputBlendComposition = () => {
    setInputUnit1(null)
    setInputPercent1('')
    setInputUnit2(null)
    setInputPercent2('')
    setInputUnit3(null)
    setInputPercent3('')
    setInputUnit4(null)
    setInputPercent4('')
    setInputUnit5(null)
    setInputPercent5('')
    setInputUnit6(null)
    setInputPercent6('')
    setInputUnit7(null)
    setInputPercent7('')
    setInputUnit8(null)
    setInputPercent8('')
    setInputUnit9(null)
    setInputPercent9('')
    setInputUnit10(null)
    setInputPercent10('')
  }

  const getBlendDateDisplayLabel = (selectedDate) => {
    return _.isDate(selectedDate) ? _.upperCase(moment(selectedDate).format('DD MMM YYYY')) : ''
  }

  const onChangeCrudeMode = (value) => {
    setCrudeMode(value)
  }

  const onChangeHighTempNhHs = (value) => {
    setHighTempNhHs(value)
    setOperatingUnit('')
    setFraction('')
  }

  const onChangeBlendDateComposition = (isSearchByCrudeBlendDateValue) => {
    if (isSearchByCrudeBlendDateValue) {
      setUnit('')
      clearInputBlendComposition()
    } else {
      setBlendDate(null)
    }
    setIsSearchByCrudeBlendDate(isSearchByCrudeBlendDateValue)
  }

  const endMonth = endOfMonth(new Date())
  const endOfNextMonth = moment(endMonth).add(1, 'M')._d

  const minDate = moment('2010-08-01')._d

  return (
    <div className="section section-search">
      <Grid padded>
        <Grid.Row>
          <Grid.Column>{renderHighTempNhHS(highTempNhHs, onChangeHighTempNhHs)}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {renderCalculationResultMode(calculateResultMode, setCalculateResultMode)}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>{renderCrudeMode(crudeMode, onChangeCrudeMode)}</Grid.Column>
        </Grid.Row>
        {crudeMode === corrosionPageConstants.SEARCH_CRUDE_MODE.SINGLE_CRUDE && (
          <Grid.Row>
            <Grid.Column width={8}>
              <SearchInput
                displayProperty="title"
                displayInListProperty="title"
                defaultSelection={crudeNameAbbreviation}
                selectionOptions={crudeNames}
                onSelectionUpdate={(selectedResult) => {
                  errorInputs.crudeNameAbbreviation = false
                  setCrudeNameAbbreviation(selectedResult)
                }}
                placeholder="Enter crude name or abbreviation*"
                className={errorInputs.crudeNameAbbreviation ? 'error' : ''}
              />
              <div
                className={`field-error-label ${errorInputs.crudeNameAbbreviation ? 'show' : ''}`}
              >
                <Icon name="warning circle" /> This field is required
              </div>
            </Grid.Column>
          </Grid.Row>
        )}
        {crudeMode === corrosionPageConstants.SEARCH_CRUDE_MODE.CRUDE_BLEND && (
          <>
            <Grid.Row>
              <Grid.Column>
                <div>
                  {renderBlendDateComposition(
                    isSearchByCrudeBlendDate,
                    onChangeBlendDateComposition
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
            {isSearchByCrudeBlendDate && (
              <Grid.Row>
                <Grid.Column width={8}>
                  <Popup
                    trigger={
                      <Input
                        value={getBlendDateDisplayLabel(blendDate)}
                        error={errorInputs.blendDate}
                        placeholder="Select blend date*"
                      />
                    }
                    content={
                      <Calendar
                        minDate={minDate}
                        maxDate={endOfNextMonth}
                        date={blendDate}
                        onChange={(selectedDate) => setBlendDate(selectedDate)}
                      />
                    }
                    on="click"
                    className="bg-blue"
                    hideOnScroll={true}
                    position="bottom right"
                  />
                  <div className={`field-error-label ${errorInputs.blendDate ? 'show' : ''}`}>
                    <Icon name="warning circle" /> This field is required
                  </div>
                </Grid.Column>
              </Grid.Row>
            )}
            {!isSearchByCrudeBlendDate && (
              <>
                <Grid.Row>
                  <Grid.Column>
                    <Dropdown
                      placeholder="Select CDU*"
                      className={`ddl ddl-secondary`}
                      value={unit}
                      selection
                      error={errorInputs.unit}
                      options={corrosionUnits}
                      onChange={(e, data) => {
                        setUnit(data.value)
                      }}
                    />
                    <div className={`field-error-label ${errorInputs.unit ? 'show' : ''}`}>
                      <Icon name="warning circle" /> This field is required
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={5}>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="crudeCode"
                        displayInListProperty="title"
                        defaultSelection={inputUnit1}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.inputUnit1 = false
                          setInputUnit1(selectedResult)
                        }}
                        placeholder="-*"
                        className={`input-unit ${errorInputs.inputUnit1 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={inputPercent1}
                        error={errorInputs.inputPercent1}
                        onChange={({ target = {} }) => {
                          errorInputs.inputPercent1 = false
                          const inputValue = parseFloat(target.value)
                          setInputPercent1(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--*"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                    <div
                      className={`field-error-label ${
                        errorInputs.inputUnit1 || errorInputs.inputPercent1 ? 'show' : ''
                      }`}
                    >
                      <Icon name="warning circle" /> This field is required
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="crudeCode"
                        displayInListProperty="title"
                        defaultSelection={inputUnit2}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.inputUnit2 = false
                          setInputUnit2(selectedResult)
                        }}
                        placeholder="-*"
                        className={`input-unit ${errorInputs.inputUnit2 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={inputPercent2}
                        error={errorInputs.inputPercent2}
                        onChange={({ target = {} }) => {
                          errorInputs.inputPercent2 = false
                          const inputValue = parseFloat(target.value)
                          setInputPercent2(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--*"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                    <div
                      className={`field-error-label ${
                        errorInputs.inputUnit2 || errorInputs.inputPercent2 ? 'show' : ''
                      }`}
                    >
                      <Icon name="warning circle" /> This field is required
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="crudeCode"
                        displayInListProperty="title"
                        defaultSelection={inputUnit3}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          setInputUnit3(selectedResult)
                        }}
                        placeholder=""
                        className={`input-unit`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={inputPercent3}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setInputPercent3(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="crudeCode"
                        displayInListProperty="title"
                        defaultSelection={inputUnit4}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          setInputUnit4(selectedResult)
                        }}
                        placeholder=""
                        className={`input-unit`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={inputPercent4}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setInputPercent4(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="crudeCode"
                        displayInListProperty="title"
                        defaultSelection={inputUnit5}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          setInputUnit5(selectedResult)
                        }}
                        placeholder=""
                        className={`input-unit`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={inputPercent5}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setInputPercent5(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="crudeCode"
                        displayInListProperty="title"
                        defaultSelection={inputUnit6}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          setInputUnit6(selectedResult)
                        }}
                        placeholder=""
                        className={`input-unit`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={inputPercent6}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setInputPercent6(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="crudeCode"
                        displayInListProperty="title"
                        defaultSelection={inputUnit7}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          setInputUnit7(selectedResult)
                        }}
                        placeholder=""
                        className={`input-unit`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={inputPercent7}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setInputPercent7(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="crudeCode"
                        displayInListProperty="title"
                        defaultSelection={inputUnit8}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          setInputUnit8(selectedResult)
                        }}
                        placeholder=""
                        className={`input-unit`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={inputPercent8}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setInputPercent8(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="crudeCode"
                        displayInListProperty="title"
                        defaultSelection={inputUnit9}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          setInputUnit9(selectedResult)
                        }}
                        placeholder=""
                        className={`input-unit`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={inputPercent9}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setInputPercent9(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="crudeCode"
                        displayInListProperty="title"
                        defaultSelection={inputUnit10}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          setInputUnit10(selectedResult)
                        }}
                        placeholder=""
                        className={`input-unit`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={inputPercent10}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setInputPercent10(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </>
            )}
          </>
        )}
        {calculateResultMode ===
          corrosionPageConstants.SEARCH_CALCULATE_RESULT_MODE.CALCULATE_CORROSION_RATE && (
          <>
            <Grid.Row>
              <Grid.Column>
                <div className="operating-criteria-wrapper flex center">
                  <div className="operating-criteria-label">Operating Criteria</div>
                  <div className="operating-criteria-line"></div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4}>
              <Grid.Column>
                <Dropdown
                  placeholder="Select operating unit*"
                  className={`ddl ddl-secondary full-width`}
                  value={operatingUnit}
                  selection
                  error={errorInputs.operatingUnit}
                  options={corrosionUnitsDS}
                  onChange={(e, data) => {
                    errorInputs.operatingUnit = false
                    setOperatingUnit(data.value)
                    setFraction('')
                  }}
                />
                <div className={`field-error-label ${errorInputs.operatingUnit ? 'show' : ''}`}>
                  <Icon name="warning circle" /> This field is required
                </div>
              </Grid.Column>

              {crudeMode === corrosionPageConstants.SEARCH_CRUDE_MODE.SINGLE_CRUDE && (
                <Grid.Column>
                  <Dropdown
                    placeholder="Select fraction*"
                    className={`ddl ddl-secondary full-width`}
                    value={fraction}
                    selection
                    error={errorInputs.fraction}
                    options={corrosionFractions}
                    onChange={(e, data) => {
                      errorInputs.fraction = false
                      setFraction(data.value)
                    }}
                  />
                  <div className={`field-error-label ${errorInputs.fraction ? 'show' : ''}`}>
                    <Icon name="warning circle" /> This field is required
                  </div>
                </Grid.Column>
              )}
              {crudeMode === corrosionPageConstants.SEARCH_CRUDE_MODE.CRUDE_BLEND && (
                <Grid.Column>
                  <Dropdown
                    placeholder="Select fraction*"
                    className={`ddl ddl-secondary full-width`}
                    value={fraction}
                    selection
                    error={errorInputs.fraction}
                    options={corrosionFractions}
                    onChange={(e, data) => {
                      errorInputs.fraction = false
                      setFraction(data.value)
                    }}
                  />
                  <div className={`field-error-label ${errorInputs.fraction ? 'show' : ''}`}>
                    <Icon name="warning circle" /> This field is required
                  </div>
                </Grid.Column>
              )}

              {highTempNhHs === corrosionPageConstants.SEARCH_HIGHTEMP_NHNS.NHNS && (
                <>
                  <Grid.Column>
                    <Input
                      value={unitFeedRate}
                      error={errorInputs.unitFeedRate}
                      onChange={({ target = {} }) => {
                        errorInputs.unitFeedRate = false
                        const inputValue = parseFloat(target.value)
                        setUnitFeedRate(_.isNaN(inputValue) ? null : inputValue)
                      }}
                      placeholder="Enter unit feed rate, tpd*"
                    ></Input>
                    <div className={`field-error-label ${errorInputs.unitFeedRate ? 'show' : ''}`}>
                      <Icon name="warning circle" /> This field is required
                    </div>
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      value={washWater}
                      error={errorInputs.washWater}
                      onChange={({ target = {} }) => {
                        errorInputs.washWater = false
                        const inputValue = parseFloat(target.value)
                        setWashWater(_.isNaN(inputValue) ? null : inputValue)
                      }}
                      placeholder="Enter wash water, tpd*"
                    ></Input>
                    <div className={`field-error-label ${errorInputs.washWater ? 'show' : ''}`}>
                      <Icon name="warning circle" /> This field is required
                    </div>
                  </Grid.Column>
                </>
              )}
              {highTempNhHs === corrosionPageConstants.SEARCH_HIGHTEMP_NHNS.HIGH_TEMP && (
                <>
                  <Grid.Column>
                    <Dropdown
                      placeholder="Select material*"
                      className={`ddl ddl-secondary full-width`}
                      value={material}
                      selection
                      error={errorInputs.material}
                      options={materialNames}
                      onChange={(e, data) => {
                        errorInputs.material = false
                        setMaterial(data.value)
                      }}
                    />
                    <div className={`field-error-label ${errorInputs.material ? 'show' : ''}`}>
                      <Icon name="warning circle" /> This field is required
                    </div>
                  </Grid.Column>
                </>
              )}
              {highTempNhHs === corrosionPageConstants.SEARCH_HIGHTEMP_NHNS.HIGH_TEMP &&
                calculateResultMode ===
                  corrosionPageConstants.SEARCH_CALCULATE_RESULT_MODE.CALCULATE_CORROSION_RATE && (
                  <Grid.Column>
                    <Input
                      type="number"
                      min="200"
                      max="500"
                      className=""
                      value={operatingTemperature}
                      onChange={({ target = {} }) => {
                        errorInputs.operatingTemperature = false
                        const inputValue = parseFloat(target.value)
                        setOperatingTemperature(_.isNaN(inputValue) ? null : inputValue)
                      }}
                      error={errorInputs.operatingTemperature}
                      placeholder="Enter temperature(&#176;C)*"
                    ></Input>
                    <div
                      className={`field-error-label ${
                        errorInputs.operatingTemperature ? 'show' : ''
                      }`}
                    >
                      <Icon name="warning circle" /> Please enter value within 200-500&#176;C
                    </div>
                  </Grid.Column>
                )}
            </Grid.Row>
          </>
        )}
        <Grid.Row>
          <Grid.Column textAlign="right">
            <Button className="btn-primary-outer" onClick={onClickReset}>
              Reset
            </Button>
            <Button className="btn-primary" onClick={onClickCalculate}>
              Calculate
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <FetchStateWrapper id={CorrosionRatePageActions.CORROSION_CALCULATE_RATE}></FetchStateWrapper>
      <FetchStateWrapper id={CorrosionRatePageActions.CORROSION_CREATE_SUMMARY}></FetchStateWrapper>
    </div>
  )
}

export default SearchPanel
