import _ from 'lodash'
import React from 'react'
import moment from 'moment'

//images
// import PTTEP from '../../src/img/operators/pttep.png'
// import SHELL from '../../src/img/operators/shell.png'
// import MURPHY from '../../src/img/operators/murphy.png'
// import PETRONAS from '../../src/img/operators/petronas.png'
// import JXNIPPON from '../../src/img/operators/jxnippon.png'
// import MUBADALA from '../../src/img/operators/mubadala.png'
// import IMG_EMPTY_STATE from '../../src/img/table/empty-state.svg'
// import SAPURAENERGY from '../../src/img/operators/sapuraenergy.png'
// import SAPURAENERGYOMV from '../../src/img/operators/sapura-omv.png'

const GLOBAL_UNIT_OPTIONS = [
  {
    text: 'BCe',
    value: 'bce',
  },
  {
    text: 'KM3',
    value: 'km3',
  },
  {
    text: 'MMT',
    value: 'mmt',
  },
  {
    text: 'Mmscf/d',
    value: 'mmscfd',
  },
]

export const FEEDGAS_HEADER_OPTIONS = [
  {
    text: 'BCe',
    value: 'bce',
  },
  {
    text: 'Mmscf/d',
    value: 'mmscfd',
  },
]

export const FEEDGAS_ALTERNATIVE_HEADER_OPTIONS = [
  {
    text: 'Mmscf/d',
    value: 'mmscfd',
  },
]

export const PRODUCTION_PLANT_HEADER_OPTIONS = [
  {
    text: 'BCe',
    value: 'bce',
  },
  {
    text: 'KM3',
    value: 'km3',
  },
  {
    text: 'MMT',
    value: 'mmt',
  },
]

export const PRODUCTION_PLANT_ALTERNATIVE_HEADER_OPTIONS = [
  {
    text: 'BCe',
    value: 'bce',
  },
]

// export const OPERATOR_MAP = {
//   'JX Nippon': JXNIPPON,
//   Murphy: MURPHY,
//   'Petronas SK Gas': PETRONAS,
//   'Petronas SK Oil': PETRONAS,
//   'Petronas SB Gas': PETRONAS,
//   'Sarawak Shell Berhad (SSB)': SHELL,
//   SEP: SAPURAENERGY,
//   MUBADALA: MUBADALA,
//   'Sapura OMV': SAPURAENERGYOMV,
//   'PTT Exploration and Production': PTTEP
// }

// export const PAC_MAP = {
//   jxn: JXNIPPON,
//   murphy: MURPHY,
//   skgas: PETRONAS,
//   skoil: PETRONAS,
//   sbgas: PETRONAS,
//   ssb: SHELL,
//   sep: SAPURAENERGYOMV,
//   mub: MUBADALA,
//   pttep: PTTEP
// }

// export const NoDataCell = ({ message = 'No available data.' }) => {
//   return (
//     <div className='ReactTable-no-data'>
//       <div>
//         <img alt='no-data-icon' src={IMG_EMPTY_STATE} />
//       </div>
//       <span>{message}</span>
//     </div>
//   )
// }

export const WarningMessageCell = ({ message, status = null, className }) => {
  return _.map(message, (item, index) => {
    let message = _.isUndefined(item.message) ? '' : item.message
    let description = !_.isNull(status) ? `${status}: ${message}` : `${message}`
    return (
      <span
        key={`i_${index}`}
        style={{ wordBreak: 'break-word' }}
        className={`flex text-pastel-red ${className}`}
      >
        {description}
      </span>
    )
  })
}

export const numberWithCommas = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function getTextFromUnit(unit) {
  return _.find(GLOBAL_UNIT_OPTIONS, { value: unit }).text
}

export function NaNToNA(value) {
  return _.includes(value, 'NaN') ? 'N.A.' : value
}

export function NullToNA(value) {
  return _.isNull(value) || _.isUndefined(value) || _.isNaN(value) || _.includes(value, 'NaN')
    ? 'N.A.'
    : value
}

export function roundingValueToUnit(value, unit) {
  let rounding = null
  if (_.includes(['bce', 'BCe', 'MUSD'], unit)) rounding = 2
  else if (_.includes(['mmscfd', 'Mmscf/d'], unit)) rounding = 0
  else if (_.includes(['km3', 'KM3'], unit)) rounding = 0
  else if (_.includes(['mmt', 'MMT'], unit)) rounding = 3

  return _.isNumber(value) && value.toFixed(rounding)
}

export function mailToHelpDesk() {
  return (window.location.href = `mailto:ict.servicedesk@email.com.my?subject=Thai Oil Support: <Please insert your email title here>`)
}

export const DATE_TYPE = {
  DAY_MONTH_YEAR: 'date_1',
  MONTH_YEAR: 'date_2',
  DAY_MONTH_YEAR_TIME: 'default',
  DAY_MONTH_YEAR_TIME_BRACKET: 'date_3',
  DAY_MONTH: 'date_4',
  DAY_MONTH_YEAR_WITHOUT_TIME_BRACKET: 'date_5',
}
export function dateFormat(date, type) {
  if (!date) return null
  let format = 'DD MMM YYYY @ HH:mm'
  switch (type) {
    case DATE_TYPE.DAY_MONTH_YEAR:
      format = 'DD MMM YYYY'
      break
    case DATE_TYPE.MONTH_YEAR:
      format = 'MMM YYYY'
      break
    case DATE_TYPE.DAY_MONTH_YEAR_TIME_BRACKET:
      format = 'DD MMM YYYY (HH:mm)'
      break
    case DATE_TYPE.DAY_MONTH_YEAR_WITHOUT_TIME_BRACKET:
      format = 'DD MMM YYYY HH:mm'
      break
    case DATE_TYPE.DAY_MONTH_YEAR_TIME_ALIAS:
      format = 'DD MMM YYYY @ HH:mm'
      break
    case DATE_TYPE.DAY_MONTH:
      format = 'DD MMM'
      break
  }
  return moment(date).format(format)
}

export const stringToDecimalPoint = (
  text,
  rounding = 2,
  parse = false,
  percentage = false,
  ifEmpty = 'N.A'
) => {
  if (parse) text = String(text)
  if (_.isNull(text) || _.isEmpty(text) || _.isUndefined(text)) return ifEmpty
  if (Number(text) === 0) return '0'
  const number = Math.round(parseFloat(text) * Math.pow(10, rounding)) / Math.pow(10, rounding)
  if (_.isNaN(number)) return ifEmpty
  if (percentage)
    return (number / 100).toLocaleString('en-US', {
      minimumFractionDigits: rounding,
      style: 'percent',
    })
  return number.toLocaleString('en-US', { minimumFractionDigits: rounding })
}

export const extractNumberFromString = (value = '') => {
  if (_.isNull(value) || _.isNumber(value)) return value
  return Number(value.match(/\d/g).join(''))
}

export const returnNullIfEmpty = (value) => {
  return _.isEmpty(value) ? null : value
}

export const returnUndefinedIfEmpty = (value) => {
  return _.isEmpty(value) ? undefined : value
}

export const trimLowerStringToCompare = (originalText, compareText) => {
  return _.chain(originalText).trim().lowerCase().isEqual(compareText).value()
}

export const convertToNumberWithDecimalPoints = (value, unit) => {
  if (_.isNull(value)) return null
  let denom = 1
  switch (unit) {
    case 'M':
      denom = 1000000
      break
    default:
      break
  }
  return Number(value) / denom
}

export const trimSnakeStringToCompare = (originalText, compareText) => {
  return _.chain(originalText).trim().snakeCase().isEqual(compareText).value()
}
