import _ from "lodash";
import React from "react";
import ReactHighchart from "../../react-highcharts";
import PropTypes from "prop-types";

export const CrudeCompareChart = (props) => {
  const CHART_OPTIONS = setChartOptions(props.data);
  return (
    <div style={{ width: "100%", overflowX: "auto", margin: 0 }}>
      <ReactHighchart options={CHART_OPTIONS} />
    </div>
  );
};

CrudeCompareChart.propTypes = {
  CrudeCompareChartData: PropTypes.object,
};

function setChartOptions(data) {
  const crudeCompareProperties = _.map(data.properties, (item) => item.name);
  const crudeCompareValues = _.map(data.properties, (item) => item.value);
  const crudeCompareLimit = _.map(data.properties, (item) => _.isEqual(item.max, '-') || _.isNull(item.max)  ? item.value : item.max);
  const crudeCompareMinLimit = _.map(data.cuts, (item) => item.min);
  const crudeColor = data.color;

  const crudeYAxisMinMax = _.map(data.properties, item => ({
    min: item.value < 0 ? item.value - 0.5 : item.value - item.value,
    max: _.isNull(item.max) || _.isEqual(item.max, '-') ?  item.value : item.value > item.max ? item.value + 0.5 : item.max,
    labels: {
      enabled: false,
    },
  }))

  return {
    chart: {
      parallelCoordinates: true,
      parallelAxes: {
        gridLineWidth: 0,
      },
      polar: true,
      type: "area",
    },

    credits: {
      enabled: false,
    },

    title: {
      text: "",
    },

    pane: {
      size: "80%",
    },

    xAxis: {
      categories: crudeCompareProperties,
      labels: {
        distance: 30,
        style: {
          fontWeight: "bold",
          fontSize: "15px",
        },
      },
      gridLineWidth: 0,
    },

    yAxis: crudeYAxisMinMax,

    tooltip: {
      enabled: false,
    },

    legend: {
      enabled: false,
    },

    plotOptions: {
      series: {
        threshold: -100,
      },
      area: {
        states: {
          select: {
            enabled: false,
          },
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
    },

    series: [
      {
        name: "Processing Limit",
        data: crudeCompareLimit,
        color: "#9D9D9D",
        fillOpacity: 0.1,
        lineWidth: 0,
        marker: {
          enabled: true,
        },
        pointPlacement: "on",
      },
      {
        name: "Processing Min Limit",
        data: crudeCompareMinLimit,
        color: "#fff",
        fillOpacity: 0.8,
        lineWidth: 0,
        marker: {
          enabled: false,
        },
      },
      {
        name: "Crude Property",
        data: crudeCompareValues,
        color: `${crudeColor}`,
        fillOpacity: 0.1,
        lineWidth: 1,
        marker: {
          enabled: true,
        },
        dataLabels: [
          {
            enabled: true,
            crop: false,
            overflow: "allow",
            padding: 0,
            allowOverlap: true,
            style: {
              fontSize: '15px',
            }
          },
        ],
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              align: "center",
              verticalAlign: "bottom",
              layout: "horizontal",
            },
            pane: {
              size: "70%",
            },
          },
        },
      ],
    },
  };
}
