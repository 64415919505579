import React from "react";
import { useLocation, useHistory } from "react-router-dom";

// Components
import {
  Grid,
  Image,
  Icon,
  Header,
  Segment,
  Divider,
  Dropdown,
  Button,
} from "semantic-ui-react";

// Assets
import Logo from "../../../assets/images/thai-oil-logo.svg";

const UnauthorisedAccessPage = () => {
  const history = useHistory();
  return (
    <Grid>
      <div className={`pad-4 mar-4`}>
        <Grid.Row className={`mar-left-4 pad-y-3`}>
          <Image size="small" src={Logo} />
        </Grid.Row>
        <div className={`flex center mar-left-4 `}>
          <Icon circular inverted color="red" name="cancel" />
          <div style={{ fontSize: "32px", fontWeight: "700" }}>Error</div>
        </div>
        <div className={`pad-top-2 mar-left-4`} style={{ fontSize: "20px" }}>
          Sorry, this page is not visible for this persona. Kindly click the
          home page and switch to appropriate persona to view this page.
        </div>
        <div className={`pad-top-4 mar-left-4 flex end`}>
          <Button className="btn-primary" onClick={() => history.push("/")}>
            Go to Homepage
          </Button>
        </div>
      </div>
    </Grid>
  );
};

export default UnauthorisedAccessPage;
