import React, { useMemo, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Divider, Grid, Icon, Popup, TableCell, Dimmer } from 'semantic-ui-react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import camref from '../../data/CAMref';
import CustomHeader from '../../components/header/customHeader';
import ToHeader from '../../components/header/toHeader';
import SearchBar from '../../components/header/searchBar';
import LeftMenu from '../../components/left-menu/leftMenu';
import CustomPopUp from '../../components/customPopUp';
import CrudeSummaryBrief from '../../components/crude-summary/crude-summary-brief';
import CrudeSummaryEnts from '../../components/crude-summary/crude-summary-ents';
import crudeCodes from '../../data/crude-code-old';


const crudeProps = ["name", "country", "assay", "process", "cargo", "kerogen", "fouling", "density", "tan", "sulfur", "sulfurtan", "mercaptan", "asphaltene", "nitrogen"];
const tableHeader = { color: 'var(--secondary)', borderLeft: 'none', borderBottom: '1px solid var(--secondary)' };
const descUrl = 'https://api.dnalytiks.com/get_crude_info?keyword=';
const mitiUrl = 'https://api.dnalytiks.com/mitigation/get_crude_info?keyword=';


const GraphRiskView = () => {
	const [crudeCd, setCrudeCd] = useState('')
	const [crudeSummary, setCrudeSummary] = useState({})
	const [camRef, setCamRef] = useState([])
	const [propKeys, setPropKeys] = useState([])
	const [isLoaded, setIsLoaded] = useState(false)
	const [active, setActive] = useState(false)
	const [curr, setCurr] = useState({})
	const [filtered, setFiltered] = useState([])
	const [headermsg, setHeadermsg] = useState([])
	const [blendUrl, setBlendUrl] = useState([])

	const location = useLocation();

	useEffect(async () => {
		const params = queryString.parse(location.search);
		console.log(params.crudeName);
		setCrudeCd(crudeCodes.data[1][params.crudeName])

		setIsLoaded(true)

		await getCamRef(params.crudeName);
		await getCrudeSummary(params.crudeName);

		const msg = `Graph Data for ${crudeCd} - ${params.crudeName}, ${crudeSummary.Country}`;
		const blendUrl = "/graphBlend?crudeName=" + params.crudeName;

		setHeadermsg(msg)
		setBlendUrl(blendUrl)

		await analyzeDescData(params.crudeName);
		await analyzeMitigationData(params.crudeName);
		setIsLoaded(true);

	}, []);

	//Data fetching methods
	const getCamRef = async () => {
		await axios.get('https://iu2pa7hq67.execute-api.us-east-1.amazonaws.com/dev/camref').then(
			res => {
				this.setState({
					camRef: res.data
				});
			}).catch(
				error => {
					console.log(error)
				})
	}

	const getCrudeSummary = async (param) => {
		await axios.get('https://iu2pa7hq67.execute-api.us-east-1.amazonaws.com/dev/crudedata?name=' + param).then(
			res => {
				var csummary = res.data.data[0];
				this.setState({
					propKeys: Object.keys(csummary),
					crudeSummary: csummary
				});
			}).catch(
				error => {
					console.log(error)
				})
	}

	const analyzeDescData = async (param) => {
		await axios.get(descUrl + param).then(
			res => {
				getDescDataByPropertyType(res.data);
			}).catch(
				error => {
					console.error(error)
				})
	}

	const analyzeMitigationData = async (param) => {
		await axios.get(mitiUrl + param).then(
			res => {
				getMitigationDataByPropertyType(res.data);
			}).catch(
				error => {
					console.error(error)
				})
	}

	const getMitigationDataByPropertyType = (data) => {
		let miti_initial = data.hits.hits;
		crudeProps.forEach(crudeProp => {
			let filtered = miti_initial.filter((elem, index, self) => {
				return self.indexOf(elem) === index && elem._source.class === crudeProp
			}).map(miti => miti._source.content);

			// this.setState({
			// 	[crudeProp + 'Mitigation']: filtered
			// })
		})
	}

	const getDescDataByPropertyType = (data) => {
		let desc_initial = data.hits.hits;
		crudeProps.forEach(crudeProp => {
			let filtered = desc_initial.map(desc => {
				let objKey = desc._source.objectKey;
				return { key: objKey.split('/')[0] || '', val: desc._source.content, class: desc._source.class }
			})
				.filter((elem, index, self) => {
					return self.indexOf(elem) === index && elem.class === crudeProp
				});
			this.setState({
				[crudeProp + 'Desc']: filtered
			})
		})
	}

	const filterProps = (params) => {
		setFiltered(params);
	}

	const handleHide = () => {
		setActive(false);
	};
	
	const handleShow = curr => {
		setActive(true);
		setCurr(curr);
	}

	const getDetails = (index) => {
		let name = propKeys[index];
		return {
			name: name,
			val: crudeSummary[name],
			ref: camref.data[0][name],
			// desc: this.state[`${crudeProps[index]}Desc`],
			// miti: this.state[`${crudeProps[index]}Mitigation`]
		}
	}
	

	const renderDetails = () => {
		let rows = [];
		for (let i = 3; i < filtered.length; i++) {
			if (filtered[i]) {
				let curr = getDetails(i);
				//console.log(curr);
				if (curr.name === 'Density')
					curr.name = 'Density @15C, kg/litre';
				rows.push(
					<Table.Row key={i}>
						<Table.Cell className='innerTable'>{curr.name}</Table.Cell>
						<Table.Cell className='innerTable' >
							{curr.ref ?
								(
									<p>{curr.val}<br />
										<Popup content={`min: ${curr.ref.min} max: ${curr.ref.max}`} trigger={<p style={{ color: '#2696E7', cursor: 'pointer' }}>Details</p>}></Popup></p>) : <p>{curr.val}</p>
							}
						</Table.Cell>
						<TableCell className='innerTable'>
							{curr.desc.length > 0 && 'Medium'}
						</TableCell>
						<Table.Cell className='innerTable' >
							<ul>
								{
									curr.desc.map((item, count) => {
										return <li key={count}>{item.val}</li>;
									})}
							</ul>
							{curr.desc.length > 0 &&
								<div style={{ paddingLeft: '40px' }}>
									<Popup content='Copy Text' trigger={<Icon name='copy outline' size='large'></Icon>}></Popup>
									<Popup content='Feedback' trigger={<Icon name='comment outline' size='large'></Icon>}></Popup>
									{curr.miti.length > 0 && <Popup content='Mitigation' trigger={<Icon style={{ cursor: 'pointer' }} name='lightbulb outline'
										onClick={() => handleShow(curr)} size='large'></Icon>}></Popup>}
								</div>}
						</Table.Cell >
						<TableCell className='innerTable' style={{ width: '150px' }}>
							<ul>
								{
									curr.desc.map((item, count) => {
										return <li key={count}><p>{item.key}<br /><p style={{ color: '#2696E7' }}>Details</p></p></li>;
									})}
							</ul>
						</TableCell>
						{active && <CustomPopUp curr={curr} active={active} handleHide={handleHide}></CustomPopUp>}
					</Table.Row>
				)
			}
		}

		return rows;
	}

	const renderCrudeSummary = (userPersonaType) => {
		if(userPersonaType === 'ENTS'){
			return <CrudeSummaryEnts />
		}

		return <CrudeSummaryBrief />
	}

	return (
		<div className='Mitigation'>
			<Dimmer.Dimmable dimmed={active}>
				<ToHeader></ToHeader>
				{/* <SearchBar search='intelligent' /> */}
				<CustomHeader backlink="/" msg={headermsg}></CustomHeader>
				<Divider style={{ height: '3px' }} fitted />

				{!isLoaded
					? (<div style={{ margin: 'auto', marginTop: '250px' }} className="ui active centered inline loader"></div>)
					:
					(
						<div className='MitiMain'>
							{renderCrudeSummary('')}
							<LeftMenu crudeProps={propKeys} filterProps={filterProps}></LeftMenu>
							<div className='MitiRight'>
								<Grid style={{ marginTop: '0px', paddingTop: '27px' }} columns={3} textAlign='left'>
									<Grid.Column className='headerText' style={{ color: 'var(--primary)' }} width={3}>
										<p style={{ marginBottom: '5px' }}>RISKS</p>
										<hr style={{ width: '48px', float: 'left', margin: '0px', border: '1px solid var(--primary)' }}></hr>
									</Grid.Column>
									<Grid.Column className='headerText' width={5}>
										FUTURE MONTH REVIEW
										</Grid.Column>
									<Grid.Column className='headerText' width={8}>
										<a href={blendUrl}>STATISTICAL ANALYSIS</a>
									</Grid.Column>
								</Grid>
								<Grid style={{ paddingBottom: '15px' }} columns={2}>
									<Grid.Column textAlign='left' style={{ color: "#000000" }} width={8}>
										Assay Reference: {crudeSummary['Assay Reference']}
									</Grid.Column>
									<Grid.Column textAlign='right' style={{ color: '#979797' }} width={8}>
										LastUpdated {new Date().toUTCString()}
									</Grid.Column>
								</Grid>
								<Table style={{ border: 'none' }} basic celled>
									<Table.Header>
										<Table.Row style={{ maxWidth: '30%', fontWeight: '600px', color: 'rgba(0, 0, 0, 0.56)' }}>
											<Table.HeaderCell style={tableHeader} >Parameter</Table.HeaderCell>
											<Table.HeaderCell style={tableHeader}>Value</Table.HeaderCell>
											<Table.HeaderCell style={tableHeader}>Risk Rating</Table.HeaderCell>
											<Table.HeaderCell style={tableHeader}>Risk Description</Table.HeaderCell>
											<Table.HeaderCell style={tableHeader}>Source</Table.HeaderCell>
										</Table.Row>
									</Table.Header>

									<Table.Body>
										{renderDetails()}
									</Table.Body>
								</Table>

							</div>
						</div>)}
			</Dimmer.Dimmable>
		</div>
	);
}

export default GraphRiskView;
