import _ from 'lodash'

import { fetchStart, fetchSuccess, fetchError } from './common/actionFetch'
import * as services from '../services/serviceProcessing'

export const PROCESSING_CDU1_PERIOD_UPDATE = 'PROCESSING_CDU1_PERIOD_UPDATE'
export const updateCDU1Period = (period) => {
  return (dispatch, getState) => {
    //const { iyoInputPage: { demand } = {} } = getState()
    dispatch({
      type: PROCESSING_CDU1_PERIOD_UPDATE,
      payload: period,
    })
    //dispatch(getCDU1ChartData())
  }
}

export const PROCESSING_CDU1_FETCH_CHART_DATA = 'PROCESSING_CDU1_FETCH_CHART_DATA'
export const postCDU1ChartData = (period) => {
  return (dispatch, getState) => {
    //const { iyoInputPage: { demand } = {} } = getState()
    dispatch(fetchStart(PROCESSING_CDU1_FETCH_CHART_DATA))
    services
      .postCdu1Chart(period)
      .then((response) =>
        dispatch({
          type: PROCESSING_CDU1_FETCH_CHART_DATA,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(PROCESSING_CDU1_FETCH_CHART_DATA)))
      .catch(() => dispatch(fetchError(PROCESSING_CDU1_FETCH_CHART_DATA)))
  }
}

export const PROCESSING_CDU2_PERIOD_UPDATE = 'PROCESSING_CDU2_PERIOD_UPDATE'
export const updateCDU2Period = (period) => {
  return (dispatch, getState) => {
    //const { iyoInputPage: { demand } = {} } = getState()
    dispatch({
      type: PROCESSING_CDU2_PERIOD_UPDATE,
      payload: period,
    })
  }
}

export const PROCESSING_CDU2_FETCH_CHART_DATA = 'PROCESSING_CDU2_FETCH_CHART_DATA'
export const postCDU2ChartData = (period) => {
  return (dispatch, getState) => {
    //const { iyoInputPage: { demand } = {} } = getState()
    dispatch(fetchStart(PROCESSING_CDU2_FETCH_CHART_DATA))
    services
      .postCdu2Chart(period)
      .then((response) =>
        dispatch({
          type: PROCESSING_CDU2_FETCH_CHART_DATA,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(PROCESSING_CDU2_FETCH_CHART_DATA)))
      .catch(() => dispatch(fetchError(PROCESSING_CDU2_FETCH_CHART_DATA)))
  }
}

export const PROCESSING_CDU3_PERIOD_UPDATE = 'PROCESSING_CDU3_PERIOD_UPDATE'
export const updateCDU3Period = (period) => {
  return (dispatch, getState) => {
    //const { iyoInputPage: { demand } = {} } = getState()
    dispatch({
      type: PROCESSING_CDU3_PERIOD_UPDATE,
      payload: period,
    })
  }
}

export const PROCESSING_CDU3_FETCH_CHART_DATA = 'PROCESSING_CDU3_FETCH_CHART_DATA'
export const postCDU3ChartData = (period) => {
  return (dispatch, getState) => {
    //const { iyoInputPage: { demand } = {} } = getState()
    dispatch(fetchStart(PROCESSING_CDU3_FETCH_CHART_DATA))
    services
      .postCdu3Chart(period)
      .then((response) =>
        dispatch({
          type: PROCESSING_CDU3_FETCH_CHART_DATA,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(PROCESSING_CDU3_FETCH_CHART_DATA)))
      .catch(() => dispatch(fetchError(PROCESSING_CDU3_FETCH_CHART_DATA)))
  }
}
