export const SEARCH_HIGHTEMP_NHNS = {
    HIGH_TEMP: 'HT',
    NHNS: 'NH'
};

export const SEARCH_CRUDE_MODE = {
    SINGLE_CRUDE: 'single',
    CRUDE_BLEND: 'blend'
};

export const SEARCH_CALCULATE_RESULT_MODE = {
    CALCULATE_CORROSION_RATE: 'calculateCorrosionRate',
    CREATE_SUMMARY: 'createSummary'
};