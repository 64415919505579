import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import ReactHighchart from '../../react-highcharts'
import PropTypes from 'prop-types'

import ReactDOMServer from 'react-dom/server'
import Tooltip from '../../tooltip'

// Helpers
import { stringToDecimalPoint } from '../../../helpers/variables'

export const SingleCrudeChart = (props) => {
  const CHART_OPTIONS = setChartOptions(props.singleCrudeChartData.data, props.period)
  return (
    <div style={{ width: '100%', overflowX: 'auto', margin: '0' }}>
      <ReactHighchart options={CHART_OPTIONS} />
    </div>
  )
}

SingleCrudeChart.propTypes = {
  SingleCrudeChartData: PropTypes.object,
}

function setChartOptions(data, period) {
  const singleCrudeChartSeries = _.get(_.get(data, 'chart'), 'series')
  const singleCrudeChartCategories = _.get(_.get(data, 'chart'), 'category')

  const formatSingleCrudeChartCategories = _.map(singleCrudeChartCategories, (item) =>
    moment(item).format('DD MMM YYYY')
  )
  const axisFontSize = '14px';
  return {
    chart: {
      type: 'area',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: singleCrudeChartCategories,
      lineColor: '#FFF',
      labels: {
        style: {
          fontSize: axisFontSize
        }
      }
    },
    yAxis: {
      visible: true,
      title: {
        enabled: false,
      },
      labels: {
        style: {
          fontSize: axisFontSize
        }
      }
    },
    legend: {
      enabled: false,
    },
    tooltip: getTooltip(data, tooltipFormatter, period),
    plotOptions: {
      column: {
        stacking: 'percent',
        allowPointSelect: false,
        dataLabels: {
          enabled: false,
        },
      },
      series: {
        borderWidth: 1,
        marker: {
          enabled: false
        },
        states: {
          select: {
            enabled: false,
          },
          hover: {
            enabled: true,
            lineWidthPlus: 0
          },
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    series: singleCrudeChartSeries,
  }
}

const getTooltip = (payload, formatter, period) => {
  return {
    shared: true,
    backgroundColor: null,
    borderWidth: 0,
    shadow: false,
    className: 'test-classname-tooltip',
    style: {
      padding: 0,
    },
    useHTML: true,
    formatter: function () {
      return formatter(this, payload, period)
    },
    outside: true,
  }
}

function getTooltipContent(category, data) {
  let content = _.map(_.get(data.chart, 'series'), (a) => ({
    name: a.name,
    color: a.color,
    data: a.data,
  }))
  return content
}

// tooltip in normal view
const tooltipFormatter = (chart, data, period) => {
  const x = chart
  const i = x.points[0].point.index
  const points = getTooltipContent(x, data)

  const options = {
    title: `${x.x}`,
    items: [
      _.map(points, (a) =>
        !_.isNull(a.data[i])
          ? {
              type: 'card',
              label: a.name,
              value: stringToDecimalPoint(a.data[i], 1, 1),
              indicatorColor: a.color,
            }
          : ''
      ),
    ],
  }

  return ReactDOMServer.renderToString(
    <Tooltip
      small
      // headerColor={data.series[i].color}
      type="TooltipTwoColumns"
      {...options}
      className={'plant-production-volume-tooltip'}
    />
  )
}
