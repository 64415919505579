export const SEARCH_OPTIONS = {
  FindSimiliarCrude: 'FSC',
  CompareCrude: 'CC',
  CompareBlend: 'CB',
}

export const OPTIONS_SETTING = {
  AdjustPropertyRange: 'APR',
  ModifyCriteriaScore: 'MCS',
}

export const VALIDATION_MSG = {
  ERROR_MSG_1: 'This field is required',
  ERROR_MSG_2: 'Please enter the total composition of 100',
  ERROR_MSG_3: 'Please enter a different crude',
  ERROR_MSG_4: 'Please enter composition more than 0',
}

export const SETTING_PAGE_SAVED_MSG = {
  ADJUST_PROPERTY: 'Adjust Property Range is Successfully updated.',
  MODIFY_CRITERIA:
    'Modify Criteria & Score is successfully updated. Click button navigate to Adjust Property Range adjust the parameter pencentage.',
}
