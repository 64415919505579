import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import _, { property } from 'lodash'

//Components
import { Grid, Header, Divider, Dropdown } from 'semantic-ui-react'
import { CrudeCutCompareChart } from '../../../../components/charts/statistical-analysis-chart/crude-cut-compare-chart'
import { CrudeCompareChart } from '../../../../components/charts/statistical-analysis-chart/crude-compare-chart'
import NoDataBlock from '../../../../components/no-data-block'

//Redux
import { STATISTICAL_ANALYSIS_PAGE_REDUCER } from '../../../../reducers/statisticalAnalaysisPageReducer'

//Hooks
import { useStateWithPaths } from '../../../../hooks/useConnect'

const CrudePropertyComparison = (props) => {
  return (
    <Grid padded>
      <Grid.Row columns={2} verticalAlign="bottom">
        <Grid.Column textAlign="left">
          <Header size="small" textAlign="left">
            CRUDE PROPERTY COMPARISON
          </Header>
        </Grid.Column>
      </Grid.Row>
      <div className={`flex width-100 pad-bottom-2`}>
        {_.map(props.data, (item) => (
          <div className={`card-container pad-x-12 pad-y-20`}>
            <div className={`flex title-container`}>
              <div className={`title-crudeCode`} style={{ color: `${item.color}` }}>
                {_.get(item.name, 'crudeCode')}
              </div>
              <div className={`title-crudeName`}>{_.get(item.name, 'crudeName')}</div>
            </div>
            <CrudeCompareChart data={item} />
            <div className={`flex end`}>
              <div className={`flex center`}>
                <div
                  className={`legend-box`}
                  style={{
                    backgroundColor: `#F5F5F5`,
                    border: `1px solid #F5F5F5`,
                  }}
                />
                <div className={`legend-title`}>Processing Limit</div>
              </div>
              <div className={`flex center`}>
                <div
                  className={`legend-box`}
                  style={{
                    backgroundColor: `${item.color}`,
                    border: `1px solid ${item.color}`,
                  }}
                />
                <div className={`legend-title`}>Crude Property</div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Grid.Row verticalAlign="bottom">
        <div className={`table-container mar-bottom-2 width-100`}>
          <div className={`flex pad-x-2 pad-top-1 space-between`}>
            <div className={`text-primary crude-property-container crude-property-text`}>
              Crude Property
            </div>
            {_.map(props.data, (item) => (
              <div className={`crude-code-container`} style={{ color: `${item.color}` }}>
                {_.get(item.name, 'crudeCode')}
              </div>
            ))}
            <div className={`text-primary crude-code-container`}>Min. Limit</div>
            <div className={`text-primary crude-code-container`}>Max. Limit</div>
          </div>
          <Divider />
          <div className={`flex pad-x-2 pad-bottom-2`}>
            <div className={`flex width-100 space-between`}>
              <div className={`flex column crude-property-container`}>
                {_.map([props.data[0]], (item) => (
                  <div>
                    {_.map(item.properties, (property, i) => (
                      <>
                        <div className={`flex center mar-bottom-1 crude-property-value-container`}>
                          {property.name}
                        </div>
                      </>
                    ))}
                  </div>
                ))}
              </div>
              {_.map(props.data, (item) => (
                <div>
                  {_.map(item.properties, (property, i) => (
                    <>
                      <div
                        className={`flex center mar-bottom-1 crude-value-container`}
                        style={{
                          color: `${
                            _.isEqual(property.max, '-') && _.isEqual(property.min, '-')
                              ? '#000000'
                              : _.isNull(property.max) && _.isNull(property.min)
                              ? '#000000'
                              : property.value >= property.max || property.value <= property.min
                              ? '#E95258'
                              : '#000000'
                          }`,
                          fontWeight: `${
                            _.isEqual(property.max, '-') && _.isEqual(property.min, '-')
                              ? ''
                              : _.isNull(property.max) && _.isNull(property.min)
                              ? ''
                              : property.value >= property.max || property.value <= property.min
                              ? '700'
                              : ''
                          }`,
                        }}
                      >
                        {property.value}
                      </div>
                    </>
                  ))}
                </div>
              ))}
              {_.map([props.data[0]], (item) => (
                <div>
                  {_.map(item.properties, (property, i) => (
                    <>
                      <div className={`flex center mar-bottom-1 crude-value-container`}>
                        {_.isEqual(property.min, '-') ? 'N.A' : property.min}
                      </div>
                    </>
                  ))}
                </div>
              ))}
              {_.map([props.data[0]], (item) => (
                <div>
                  {_.map(item.properties, (property, i) => (
                    <>
                      <div className={`flex center mar-bottom-1 crude-value-container`}>
                        {_.isEqual(property.max, '-') ? 'N.A' : property.max}
                      </div>
                    </>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Grid.Row>
    </Grid>
  )
}

const CrudeCutComparison = (props) => {
  return (
    <Grid padded>
      <Grid.Row columns={2} verticalAlign="bottom">
        <Grid.Column textAlign="left">
          <Header size="small" textAlign="left">
            CRUDE CUT COMPARISON
          </Header>
        </Grid.Column>
      </Grid.Row>
      <div className={`flex width-100 pad-bottom-2`}>
        {_.map(props.data, (item) => (
          <div className={`card-container pad-x-12 pad-y-20`}>
            <div className={`flex title-container`}>
              <div className={`title-crudeCode`} style={{ color: `${item.color}` }}>
                {_.get(item.name, 'crudeCode')}
              </div>
              <div className={`title-crudeName`}>{_.get(item.name, 'crudeName')}</div>
            </div>
            <CrudeCutCompareChart data={item} />
            <div className={`flex end`}>
              <div className={`flex center`}>
                <div
                  className={`legend-box`}
                  style={{
                    backgroundColor: `#F5F5F5`,
                    border: `1px solid #F5F5F5`,
                  }}
                />
                <div className={`legend-title`}>Processing Limit</div>
              </div>
              <div className={`flex center`}>
                <div
                  className={`legend-box`}
                  style={{
                    backgroundColor: `${item.color}`,
                    border: `1px solid ${item.color}`,
                  }}
                />
                <div className={`legend-title`}>Crude Property</div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Grid.Row verticalAlign="bottom">
        <div className={`table-container width-100`}>
          <div className={`flex pad-x-2 pad-top-1 space-between`}>
            <div
              className={`text-primary crude-compare-cut-property-container crude-property-text`}
            >
              Fractions
            </div>
            <div
              className={`text-primary crude-compare-cut-property-container crude-property-text`}
            >
              Crude Property
            </div>
            {_.map(props.data, (item) => (
              <div className={`crude-code-container`} style={{ color: `${item.color}` }}>
                {_.get(item.name, 'crudeCode')}
              </div>
            ))}
            <div
              className={`text-primary crude-compare-cut-property-container crude-property-text`}
            >
              Processing Unit
            </div>
            <div className={`text-primary crude-code-container`}>Min. Limit</div>
            <div className={`text-primary crude-code-container`}>Max. Limit</div>
          </div>
          <Divider />
          <div className={`flex pad-x-2 pad-bottom-2`}>
            <div className={`flex width-100 space-between`}>
              {_.map([props.data[0]], (item) => (
                <div>
                  {_.map(item.cuts, (property, i) => (
                    <>
                      <div className={`flex center mar-bottom-1 crude-property-value-container`}>
                        {property.fraction}
                      </div>
                    </>
                  ))}
                </div>
              ))}
              {_.map([props.data[0]], (item) => (
                <div>
                  {_.map(item.cuts, (property, i) => (
                    <>
                      <div className={`flex center mar-bottom-1 crude-property-value-container`}>
                        {property.name}
                      </div>
                    </>
                  ))}
                </div>
              ))}
              {_.map(props.data, (item) => (
                <div>
                  {_.map(item.cuts, (property, i) => (
                    <div
                      className={`flex center mar-bottom-1 crude-value-container`}
                      style={{
                        color: `${
                          _.isEqual(property.max, '-') && _.isEqual(property.min, '-')
                            ? '#000000'
                            : _.isNull(property.max) && _.isNull(property.min)
                            ? '#000000'
                            : property.value >= property.max || property.value <= property.min
                            ? '#E95258'
                            : '#000000'
                        }`,
                        fontWeight: `${
                          _.isEqual(property.max, '-') && _.isEqual(property.min, '-')
                            ? ''
                            : _.isNull(property.max) && _.isNull(property.min)
                            ? ''
                            : property.value >= property.max || property.value <= property.min
                            ? '700'
                            : ''
                        }`,
                      }}
                    >
                      {property.value}
                    </div>
                  ))}
                </div>
              ))}
              {_.map([props.data[0]], (item) => (
                <div>
                  {_.map(item.cuts, (property, i) => (
                    <>
                      <div className={`flex center mar-bottom-1 crude-property-value-container`}>
                        {property.unit}
                      </div>
                    </>
                  ))}
                </div>
              ))}
              {_.map([props.data[0]], (item) => (
                <div>
                  {_.map(item.cuts, (property, i) => (
                    <>
                      <div className={`flex center mar-bottom-1 crude-value-container`}>
                        {_.isEqual(property.min, '-') ? 'N.A' : property.min}
                      </div>
                    </>
                  ))}
                </div>
              ))}
              {_.map([props.data[0]], (item) => (
                <div>
                  {_.map(item.cuts, (property, i) => (
                    <>
                      <div className={`flex center mar-bottom-1 crude-value-container`}>
                        {_.isEqual(property.max, '-') ? 'N.A' : property.max}
                      </div>
                    </>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Grid.Row>
    </Grid>
  )
}

const CompareCrude = () => {
  const [{ searchResult }] = useStateWithPaths([`${STATISTICAL_ANALYSIS_PAGE_REDUCER}`])

  const searchCompareCrudeResult = _.get(searchResult, 'searchCompareCrude')

  if (
    !_.get(searchCompareCrudeResult, 'data') ||
    _.isEmpty(_.get(searchCompareCrudeResult, 'data'))
  ) {
    return <NoDataBlock />
  }

  const data = _.get(searchResult.searchCompareCrude, 'data')

  const getProperties = _.map(data, (item) => item.properties)
  const getCuts = _.map(data, (item) => item.cuts)

  return (
    <div className="section section-compare-crude">
      {!_.isEmpty(_.reduce(getProperties)) && <CrudePropertyComparison data={data} />}
      {!_.isEmpty(_.reduce(getCuts)) && <CrudeCutComparison data={data} />}
    </div>
  )
}

export default CompareCrude
