export const FETCH_START = '@@redux-fetch/FETCH_START'
export const FETCH_SUCCESS = '@@redux-fetch/FETCH_SUCCESS'
export const FETCH_ERROR = '@@redux-fetch/FETCH_ERROR'
export const FETCH_RESET = '@@redux-fetch/FETCH_RESET'


export const fetchStart = (id) => ({
	type: FETCH_START,
	payload: id
})

export const fetchSuccess = (id) => ({
	type: FETCH_SUCCESS,
	payload: id
})

export const fetchError = (id, error) => ({
	type: FETCH_ERROR,
	payload: id,
	error
})

export const fetchReset = (id) => ({
	type: FETCH_RESET,
	payload: id
})

export const fetchSuccessWrap = (creator, id) => {
	return (dispatch) => creator(dispatch)
	.then(()=>dispatch(fetchSuccess(id)))
	.catch((error)=>dispatch(fetchError(id, error)))
} 
