import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

// Components
import { Grid, Input, Button, Icon } from 'semantic-ui-react'
import SearchInput from '../../../components/search/search-input/searchInput'
import RadioMenu from '../../../components/radio-menu'

// Image
import background from '../../../assets/images/search_banner_2.svg'

//Redux
import { LANDING_PAGE_REDUCER } from '../../../reducers/landingPageReducer'
import { PERSONAL_SETTING_REDUCER } from '../../../reducers/common/personalSettingReducer'
import { MASTER_DATA_REDUCER } from '../../../reducers/common/masterDataReducer'
import * as UserGroup from '../../../constants/userGroups'
import * as MasterDataActions from '../../../actions/common/actionMasterData'
import * as landingPageConstant from './helper'
import * as landingActions from '../../../actions/actionLanding'

//Hooks
import { useStateWithPaths, useActions } from '../../../hooks/useConnect'

import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'

const renderOptions = (options, setOptions) => {
  const menu = [
    {
      label: 'Single Crude',
      value: 'single-crude',
    },
    {
      label: 'Custom Blend',
      value: 'custom-blend',
    },
  ]
  return <RadioMenu menuData={menu} selectCallback={setOptions} selectedValue={options} />
}

const currentPersonaFormatted = (currentPersona) =>
  _.isEqual(currentPersona, UserGroup.SC)
    ? 'SC'
    : _.isEqual(currentPersona, UserGroup.ENTS)
    ? 'ENTS'
    : _.isEqual(currentPersona, UserGroup.TN)
    ? 'TN'
    : ''

const SearchPanel = () => {
  const history = useHistory()

  const [{ currentPersona }] = useStateWithPaths([`${PERSONAL_SETTING_REDUCER}`])
  const [{ landingPage }] = useStateWithPaths([`${LANDING_PAGE_REDUCER}`])
  const [{ crudeNames }] = useStateWithPaths([`${MASTER_DATA_REDUCER}`])

  const [options, setOptions] = useState(landingPage.options)
  const [crudeNameAbbreviation, setCrudeNameAbbreviation] = useState(
    landingPage.crudeNameAbbreviation
  )

  const [inputUnit1, setInputUnit1] = useState(landingPage.inputUnits.unit1)
  const [inputPercent1, setInputPercent1] = useState(landingPage.inputUnits.unitPercent1)
  const [inputUnit2, setInputUnit2] = useState(landingPage.inputUnits.unit2)
  const [inputPercent2, setInputPercent2] = useState(landingPage.inputUnits.unitPercent2)
  const [inputUnit3, setInputUnit3] = useState(landingPage.inputUnits.unit3)
  const [inputPercent3, setInputPercent3] = useState(landingPage.inputUnits.unitPercent3)
  const [inputUnit4, setInputUnit4] = useState(landingPage.inputUnits.unit4)
  const [inputPercent4, setInputPercent4] = useState(landingPage.inputUnits.unitPercent4)
  const [inputUnit5, setInputUnit5] = useState(landingPage.inputUnits.unit5)
  const [inputPercent5, setInputPercent5] = useState(landingPage.inputUnits.unitPercent5)
  const [inputUnit6, setInputUnit6] = useState(landingPage.inputUnits.unit6)
  const [inputPercent6, setInputPercent6] = useState(landingPage.inputUnits.unitPercent6)
  const [inputUnit7, setInputUnit7] = useState(landingPage.inputUnits.unit7)
  const [inputPercent7, setInputPercent7] = useState(landingPage.inputUnits.unitPercent7)
  const [inputUnit8, setInputUnit8] = useState(landingPage.inputUnits.unit8)
  const [inputPercent8, setInputPercent8] = useState(landingPage.inputUnits.unitPercent8)
  const [inputUnit9, setInputUnit9] = useState(landingPage.inputUnits.unit9)
  const [inputPercent9, setInputPercent9] = useState(landingPage.inputUnits.unitPercent9)
  const [inputUnit10, setInputUnit10] = useState(landingPage.inputUnits.unit10)
  const [inputPercent10, setInputPercent10] = useState(landingPage.inputUnits.unitPercent10)

  const [errorInputs, setErrorInputs] = useState({})

  const [offsetInput, setOffsetInput] = useState(5)
  const [offsetInputError, setOffsetInputError] = useState(false)

  const { getMasterCrudeNames } = useActions({
    getMasterCrudeNames: MasterDataActions.getMasterCrudeNames,
  })

  const fadeInAnimation = keyframes`${fadeIn}`

  const FadeInDiv = styled.div`
    animation: 2s ${fadeInAnimation};
  `

  const [content, setContent] = useState(
    <div className={`search-title pad-bottom-2`}>{`Welcome to ${currentPersonaFormatted(
      currentPersona
    )} Persona,`}</div>
  )

  useEffect(() => {
    const interval = setInterval(
      () =>
        setContent(
          <FadeInDiv className={`search-title pad-bottom-2`}>
            What is your interest of today?
          </FadeInDiv>
        ),
      5000
    )
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    getMasterCrudeNames()
  }, [getMasterCrudeNames])

  const onClickReset = () => {
    setInputUnit1(null)
    setInputPercent1('')
    setInputUnit2(null)
    setInputPercent2('')
    setInputUnit3(null)
    setInputPercent3('')
    setInputUnit4(null)
    setInputPercent4('')
    setInputUnit5(null)
    setInputPercent5('')
    setInputUnit6(null)
    setInputPercent6('')
    setInputUnit7(null)
    setInputPercent7('')
    setInputUnit8(null)
    setInputPercent8('')
    setInputUnit9(null)
    setInputPercent9('')
    setInputUnit10(null)
    setInputPercent10('')
  }

  const convertInputUnitsToBlendComposition = ({ inputUnits }) => {
    let blendComposition = []
    for (let count = 1; count <= 10; count++) {
      if (
        !_.isNull(
          _.get(inputUnits, 'inputUnit' + count) &&
            !_.isEmpty(_.get(inputUnits, 'inputUnit' + count)) &&
            !_.isNull(_.get(inputUnits, 'inputPercent' + count)) &&
            _.get(inputUnits, 'inputPercent' + count) !== ''
        )
      ) {
        blendComposition.push({
          crudeCode: _.get(inputUnits, 'inputUnit' + count + '.crudeCode'),
          percentage: _.get(inputUnits, 'inputPercent' + count),
        })
      }
    }
    return blendComposition
  }

  const isEmptyValue = useCallback((inputValue, isInputUnit) => {
    if (isInputUnit) {
      return _.isNull(inputValue) || _.isEmpty(inputValue)
    } else {
      return (
        _.isNull(inputValue) || _.isUndefined(inputValue) || inputValue <= 0 || inputValue > 100
      )
    }
  })

  const isNotEmptyPercentage = (inputPercent) => {
    return !_.isEqualWith(inputPercent, '') && !_.isUndefined(inputPercent)
  }

  const checkDuplicateInputUnits = (inputUnits) => {
    const inputUnitsList = _.map(inputUnits, (item, index) => ({ ...item, inputUnit: index }))
    const arr = []
    _.map(inputUnitsList, (item, index) => {
      if (!_.isEmpty(item.crudeCode)) {
        arr.push(item)
      }
    })

    const mapped = _.map(arr, (obj, index) => obj.crudeCode)
    const sorted_arr = mapped.slice().sort()

    var results = []
    for (var i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] === sorted_arr[i]) {
        results.push(sorted_arr[i])
      }
    }

    const exists = []
    _.map(results, (crudeCode) => {
      _.map(arr, (item) => {
        if (item.crudeCode === crudeCode) {
          exists.push(item)
        }
      })
    })

    return exists
  }

  const isPassValidationForCalculate = useCallback(
    (optionValue) => {
      let newErrorInputs = {}

      if (optionValue === landingPageConstant.SEARCH_OPTIONS.SingleCrude) {
        newErrorInputs.crudeNameAbbreviation = isEmptyValue(crudeNameAbbreviation, true)
      } else {
        newErrorInputs.inputUnit1 = isEmptyValue(inputUnit1, true)
        newErrorInputs.inputUnit2 = isEmptyValue(inputUnit2, true)
        newErrorInputs.inputPercent1 = isEmptyValue(inputPercent1, false)
        newErrorInputs.inputPercent2 = isEmptyValue(inputPercent2, false)

        let total = 0
        total = isNotEmptyPercentage(inputPercent1) ? total + inputPercent1 : total + 0
        total = isNotEmptyPercentage(inputPercent2) ? total + inputPercent2 : total + 0
        total = isNotEmptyPercentage(inputPercent3) ? total + inputPercent3 : total + 0
        total = isNotEmptyPercentage(inputPercent4) ? total + inputPercent4 : total + 0
        total = isNotEmptyPercentage(inputPercent5) ? total + inputPercent5 : total + 0
        total = isNotEmptyPercentage(inputPercent6) ? total + inputPercent6 : total + 0
        total = isNotEmptyPercentage(inputPercent7) ? total + inputPercent7 : total + 0
        total = isNotEmptyPercentage(inputPercent8) ? total + inputPercent8 : total + 0
        total = isNotEmptyPercentage(inputPercent9) ? total + inputPercent9 : total + 0
        total = isNotEmptyPercentage(inputPercent10) ? total + inputPercent10 : total + 0

        if (
          (!isEmptyValue(inputUnit1, true) && isEmptyValue(inputPercent1, false)) ||
          (!isEmptyValue(inputUnit2, true) && isEmptyValue(inputPercent2, false)) ||
          (!isEmptyValue(inputUnit3, true) && isEmptyValue(inputPercent3, false)) ||
          (!isEmptyValue(inputUnit4, true) && isEmptyValue(inputPercent4, false)) ||
          (!isEmptyValue(inputUnit5, true) && isEmptyValue(inputPercent5, false)) ||
          (!isEmptyValue(inputUnit6, true) && isEmptyValue(inputPercent6, false)) ||
          (!isEmptyValue(inputUnit7, true) && isEmptyValue(inputPercent7, false)) ||
          (!isEmptyValue(inputUnit8, true) && isEmptyValue(inputPercent8, false)) ||
          (!isEmptyValue(inputUnit9, true) && isEmptyValue(inputPercent9, false)) ||
          (!isEmptyValue(inputUnit10, true) && isEmptyValue(inputPercent10, false))
        ) {
          if (!isEmptyValue(inputUnit1, true)) {
            newErrorInputs.inputPercent1 = isEmptyValue(inputPercent1)
          }
          if (!isEmptyValue(inputUnit2, true)) {
            newErrorInputs.inputPercent2 = isEmptyValue(inputPercent2)
          }
          if (!isEmptyValue(inputUnit3, true)) {
            newErrorInputs.inputPercent3 = isEmptyValue(inputPercent3)
          }
          if (!isEmptyValue(inputUnit4, true)) {
            newErrorInputs.inputPercent4 = isEmptyValue(inputPercent4)
          }
          if (!isEmptyValue(inputUnit5, true)) {
            newErrorInputs.inputPercent5 = isEmptyValue(inputPercent5)
          }
          if (!isEmptyValue(inputUnit6, true)) {
            newErrorInputs.inputPercent6 = isEmptyValue(inputPercent6)
          }
          if (!isEmptyValue(inputUnit7, true)) {
            newErrorInputs.inputPercent7 = isEmptyValue(inputPercent7)
          }
          if (!isEmptyValue(inputUnit8, true)) {
            newErrorInputs.inputPercent8 = isEmptyValue(inputPercent8)
          }
          if (!isEmptyValue(inputUnit9, true)) {
            newErrorInputs.inputPercent9 = isEmptyValue(inputPercent9)
          }
          if (!isEmptyValue(inputUnit10, true)) {
            newErrorInputs.inputPercent10 = isEmptyValue(inputPercent10)
          }

          newErrorInputs.errorCustomBlend = landingPageConstant.VALIDATION_MSG.ERROR_MSG_3
        }

        if (_.isEmpty(newErrorInputs.errorCustomBlend)) {
          if (total > '100' || total <= '0') {
            newErrorInputs.inputPercent1 = isNotEmptyPercentage(inputPercent1)
            newErrorInputs.inputPercent2 = isNotEmptyPercentage(inputPercent2)
            newErrorInputs.inputPercent3 = isNotEmptyPercentage(inputPercent3)
            newErrorInputs.inputPercent4 = isNotEmptyPercentage(inputPercent4)
            newErrorInputs.inputPercent5 = isNotEmptyPercentage(inputPercent5)
            newErrorInputs.inputPercent6 = isNotEmptyPercentage(inputPercent6)
            newErrorInputs.inputPercent7 = isNotEmptyPercentage(inputPercent7)
            newErrorInputs.inputPercent8 = isNotEmptyPercentage(inputPercent8)
            newErrorInputs.inputPercent9 = isNotEmptyPercentage(inputPercent9)
            newErrorInputs.inputPercent10 = isNotEmptyPercentage(inputPercent10)

            newErrorInputs.errorCustomBlend = landingPageConstant.VALIDATION_MSG.ERROR_MSG_1
          } else {
            const inputUnits = {
              inputUnit1,
              inputUnit2,
              inputUnit3,
              inputUnit4,
              inputUnit5,
              inputUnit6,
              inputUnit7,
              inputUnit8,
              inputUnit9,
              inputUnit10,
            }

            const duplicateList = checkDuplicateInputUnits(inputUnits)
            _.map(duplicateList, (error) => {
              newErrorInputs[error.inputUnit] = true
            })

            newErrorInputs.errorCustomBlend = !_.isEmpty(duplicateList)
              ? landingPageConstant.VALIDATION_MSG.ERROR_MSG_2
              : ''
          }
        }
      }

      setErrorInputs(newErrorInputs)
      let hasError = false
      _.forOwn(newErrorInputs, (value, key) => {
        if (hasError) return

        hasError = value
      })
      return !hasError
    },
    [
      crudeNameAbbreviation,
      inputUnit1,
      inputUnit2,
      inputUnit3,
      inputUnit4,
      inputUnit5,
      inputUnit6,
      inputUnit7,
      inputUnit8,
      inputUnit9,
      inputUnit10,
      inputPercent1,
      inputPercent2,
      inputPercent3,
      inputPercent4,
      inputPercent5,
      inputPercent6,
      inputPercent7,
      inputPercent8,
      inputPercent9,
      inputPercent10,
    ]
  )

  const onClickSearchInput = useCallback(() => {
    if (!isPassValidationForCalculate(options)) {
      return
    }

    history.push({
      pathname: '/risks',
      options: options,
      currentPersona,
      crudeNameAbbreviation: {
        crudeCode: crudeNameAbbreviation.crudeCode,
        crudeName: crudeNameAbbreviation.crudeName,
      },
      inputUnits: convertInputUnitsToBlendComposition({
        inputUnits: {
          inputUnit1,
          inputPercent1,
          inputUnit2,
          inputPercent2,
          inputUnit3,
          inputPercent3,
          inputUnit4,
          inputPercent4,
          inputUnit5,
          inputPercent5,
          inputUnit6,
          inputPercent6,
          inputUnit7,
          inputPercent7,
          inputUnit8,
          inputPercent8,
          inputUnit9,
          inputPercent9,
          inputUnit10,
          inputPercent10,
        },
      }),
      offset: _.round(offsetInput),
    })
  }, [
    options,
    currentPersona,
    crudeNameAbbreviation,
    inputUnit1,
    inputUnit2,
    inputUnit3,
    inputUnit4,
    inputUnit5,
    inputUnit6,
    inputUnit7,
    inputUnit8,
    inputUnit9,
    inputUnit10,
    inputPercent1,
    inputPercent2,
    inputPercent3,
    inputPercent4,
    inputPercent5,
    inputPercent6,
    inputPercent7,
    inputPercent8,
    inputPercent9,
    inputPercent10,
    offsetInput,
  ])

  const handleOffsetInput = (input) => {
    var errorOrNot = true;
    if(_.inRange(input.value, 0, 101)){
      errorOrNot = false;
    }

    setOffsetInput(input.value);
    setOffsetInputError(errorOrNot);
  }

  return (
    <Grid.Column width={11}>
      <div className={`search-panel-container`} style={{ backgroundImage: `url(${background})` }}>
        {content}
        <div>{renderOptions(options, setOptions)}</div>
        {options === landingPageConstant.SEARCH_OPTIONS.SingleCrude && (
          <>
            <div className={`flex width-100 pad-top-1`}>
              <div className={`search-searchbar`}>
                <SearchInput
                  displayProperty="title"
                  displayInListProperty="title"
                  defaultSelection={crudeNameAbbreviation}
                  selectionOptions={crudeNames}
                  onSelectionUpdate={(selectedResult) => {
                    errorInputs.crudeNameAbbreviation = false
                    setCrudeNameAbbreviation(selectedResult)
                  }}
                  placeholder="Enter crude name or abbreviation*"
                  className={errorInputs.crudeNameAbbreviation ? 'error' : ''}
                />
              </div>
              <Button className="btn-primary" onClick={onClickSearchInput}>
                Search
              </Button>
            </div>
            <div className={`field-error-label ${errorInputs.crudeNameAbbreviation ? 'show' : ''}`}>
              <Icon name="warning circle" /> This field is required
            </div>
          </>
        )}
        {options === landingPageConstant.SEARCH_OPTIONS.CustomBlend && (
          <div className={`flex column width-100 pad-top-1`}>
            <Grid padded="vertically">
              <Grid.Row columns={5}>
                <Grid.Column className="margin-b-16">
                  <div className="unit-percent-group">
                    <SearchInput
                      displayProperty="crudeCode"
                      displayInListProperty="title"
                      defaultSelection={inputUnit1}
                      selectionOptions={crudeNames}
                      onSelectionUpdate={(selectedResult) => {
                        errorInputs.inputUnit1 = false
                        setInputUnit1(selectedResult)
                      }}
                      placeholder="-*"
                      className={`input-unit ${errorInputs.inputUnit1 ? 'error' : ''}`}
                    />
                    <Input
                      className="input-percent"
                      type="number"
                      min="1"
                      max="100"
                      value={inputPercent1}
                      error={errorInputs.inputPercent1}
                      onChange={({ target = {} }) => {
                        errorInputs.inputPercent1 = false
                        const inputValue = parseFloat(target.value)
                        setInputPercent1(_.isNaN(inputValue) ? undefined : inputValue)
                      }}
                      placeholder="--*"
                    ></Input>
                    <div className="input-percent-symbol">%</div>
                  </div>
                  <div
                    className={`field-error-label ${
                      (errorInputs.inputUnit1 || errorInputs.inputPercent1) &&
                      _.isEmpty(errorInputs.errorCustomBlend)
                        ? 'show'
                        : ''
                    }`}
                  >
                    <Icon name="warning circle" /> This field is required
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="unit-percent-group">
                    <SearchInput
                      displayProperty="crudeCode"
                      displayInListProperty="title"
                      defaultSelection={inputUnit2}
                      selectionOptions={crudeNames}
                      onSelectionUpdate={(selectedResult) => {
                        errorInputs.inputUnit2 = false
                        setInputUnit2(selectedResult)
                      }}
                      placeholder="-*"
                      className={`input-unit ${errorInputs.inputUnit2 ? 'error' : ''}`}
                    />
                    <Input
                      className="input-percent"
                      type="number"
                      min="1"
                      max="100"
                      value={inputPercent2}
                      error={errorInputs.inputPercent2}
                      onChange={({ target = {} }) => {
                        errorInputs.inputPercent2 = false
                        const inputValue = parseFloat(target.value)
                        setInputPercent2(_.isNaN(inputValue) ? undefined : inputValue)
                      }}
                      placeholder="--*"
                    ></Input>
                    <div className="input-percent-symbol">%</div>
                  </div>
                  <div
                    className={`field-error-label ${
                      (errorInputs.inputUnit2 || errorInputs.inputPercent2) &&
                      _.isEmpty(errorInputs.errorCustomBlend)
                        ? 'show'
                        : ''
                    }`}
                  >
                    <Icon name="warning circle" /> This field is required
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="unit-percent-group">
                    <SearchInput
                      displayProperty="crudeCode"
                      displayInListProperty="title"
                      defaultSelection={inputUnit3}
                      selectionOptions={crudeNames}
                      onSelectionUpdate={(selectedResult) => {
                        errorInputs.inputUnit3 = false
                        setInputUnit3(selectedResult)
                      }}
                      placeholder="-"
                      className={`input-unit ${errorInputs.inputUnit3 ? 'error' : ''}`}
                    />
                    <Input
                      className="input-percent"
                      type="number"
                      min="1"
                      max="100"
                      value={inputPercent3}
                      error={errorInputs.inputPercent3}
                      onChange={({ target = {} }) => {
                        errorInputs.inputPercent3 = false
                        const inputValue = parseFloat(target.value)
                        setInputPercent3(_.isNaN(inputValue) ? undefined : inputValue)
                      }}
                      placeholder="--"
                    ></Input>
                    <div className="input-percent-symbol">%</div>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="unit-percent-group">
                    <SearchInput
                      displayProperty="crudeCode"
                      displayInListProperty="title"
                      defaultSelection={inputUnit4}
                      selectionOptions={crudeNames}
                      onSelectionUpdate={(selectedResult) => {
                        errorInputs.inputUnit4 = false
                        setInputUnit4(selectedResult)
                      }}
                      placeholder="-"
                      className={`input-unit ${errorInputs.inputUnit4 ? 'error' : ''}`}
                    />
                    <Input
                      className="input-percent"
                      type="number"
                      min="1"
                      max="100"
                      value={inputPercent4}
                      error={errorInputs.inputPercent4}
                      onChange={({ target = {} }) => {
                        errorInputs.inputPercent4 = false
                        const inputValue = parseFloat(target.value)
                        setInputPercent4(_.isNaN(inputValue) ? undefined : inputValue)
                      }}
                      placeholder="--"
                    ></Input>
                    <div className="input-percent-symbol">%</div>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="unit-percent-group">
                    <SearchInput
                      displayProperty="crudeCode"
                      displayInListProperty="title"
                      defaultSelection={inputUnit5}
                      selectionOptions={crudeNames}
                      onSelectionUpdate={(selectedResult) => {
                        errorInputs.inputUnit5 = false
                        setInputUnit5(selectedResult)
                      }}
                      placeholder="-"
                      className={`input-unit ${errorInputs.inputUnit5 ? 'error' : ''}`}
                    />
                    <Input
                      className="input-percent"
                      type="number"
                      min="1"
                      max="100"
                      value={inputPercent5}
                      error={errorInputs.inputPercent5}
                      onChange={({ target = {} }) => {
                        errorInputs.inputPercent5 = false
                        const inputValue = parseFloat(target.value)
                        setInputPercent5(_.isNaN(inputValue) ? undefined : inputValue)
                      }}
                      placeholder="--"
                    ></Input>
                    <div className="input-percent-symbol">%</div>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={5}>
                <Grid.Column>
                  <div className="unit-percent-group">
                    <SearchInput
                      displayProperty="crudeCode"
                      displayInListProperty="title"
                      defaultSelection={inputUnit6}
                      selectionOptions={crudeNames}
                      onSelectionUpdate={(selectedResult) => {
                        errorInputs.inputUnit6 = false
                        setInputUnit6(selectedResult)
                      }}
                      placeholder="-"
                      className={`input-unit ${errorInputs.inputUnit6 ? 'error' : ''}`}
                    />
                    <Input
                      className="input-percent"
                      type="number"
                      min="1"
                      max="100"
                      value={inputPercent6}
                      error={errorInputs.inputPercent6}
                      onChange={({ target = {} }) => {
                        errorInputs.inputPercent6 = false
                        const inputValue = parseFloat(target.value)
                        setInputPercent6(_.isNaN(inputValue) ? undefined : inputValue)
                      }}
                      placeholder="--"
                    ></Input>
                    <div className="input-percent-symbol">%</div>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="unit-percent-group">
                    <SearchInput
                      displayProperty="crudeCode"
                      displayInListProperty="title"
                      defaultSelection={inputUnit7}
                      selectionOptions={crudeNames}
                      onSelectionUpdate={(selectedResult) => {
                        errorInputs.inputUnit7 = false
                        setInputUnit7(selectedResult)
                      }}
                      placeholder="-"
                      className={`input-unit ${errorInputs.inputUnit7 ? 'error' : ''}`}
                    />
                    <Input
                      className="input-percent"
                      type="number"
                      min="1"
                      max="100"
                      value={inputPercent7}
                      error={errorInputs.inputPercent7}
                      onChange={({ target = {} }) => {
                        errorInputs.inputPercent7 = false
                        const inputValue = parseFloat(target.value)
                        setInputPercent7(_.isNaN(inputValue) ? undefined : inputValue)
                      }}
                      placeholder="--"
                    ></Input>
                    <div className="input-percent-symbol">%</div>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="unit-percent-group">
                    <SearchInput
                      displayProperty="crudeCode"
                      displayInListProperty="title"
                      defaultSelection={inputUnit8}
                      selectionOptions={crudeNames}
                      onSelectionUpdate={(selectedResult) => {
                        errorInputs.inputUnit8 = false
                        setInputUnit8(selectedResult)
                      }}
                      placeholder="-"
                      className={`input-unit ${errorInputs.inputUnit8 ? 'error' : ''}`}
                    />
                    <Input
                      className="input-percent"
                      type="number"
                      min="1"
                      max="100"
                      value={inputPercent8}
                      error={errorInputs.inputPercent8}
                      onChange={({ target = {} }) => {
                        errorInputs.inputPercent8 = false
                        const inputValue = parseFloat(target.value)
                        setInputPercent8(_.isNaN(inputValue) ? undefined : inputValue)
                      }}
                      placeholder="--"
                    ></Input>
                    <div className="input-percent-symbol">%</div>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="unit-percent-group">
                    <SearchInput
                      displayProperty="crudeCode"
                      displayInListProperty="title"
                      defaultSelection={inputUnit9}
                      selectionOptions={crudeNames}
                      onSelectionUpdate={(selectedResult) => {
                        errorInputs.inputUnit9 = false
                        setInputUnit9(selectedResult)
                      }}
                      placeholder="-"
                      className={`input-unit ${errorInputs.inputUnit9 ? 'error' : ''}`}
                    />
                    <Input
                      className="input-percent"
                      type="number"
                      min="1"
                      max="100"
                      value={inputPercent9}
                      error={errorInputs.inputPercent9}
                      onChange={({ target = {} }) => {
                        errorInputs.inputPercent9 = false
                        const inputValue = parseFloat(target.value)
                        setInputPercent9(_.isNaN(inputValue) ? undefined : inputValue)
                      }}
                      placeholder="--"
                    ></Input>
                    <div className="input-percent-symbol">%</div>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="unit-percent-group">
                    <SearchInput
                      displayProperty="crudeCode"
                      displayInListProperty="title"
                      defaultSelection={inputUnit10}
                      selectionOptions={crudeNames}
                      onSelectionUpdate={(selectedResult) => {
                        errorInputs.inputUnit10 = false
                        setInputUnit10(selectedResult)
                      }}
                      placeholder="-"
                      className={`input-unit ${errorInputs.inputUnit10 ? 'error' : ''}`}
                    />
                    <Input
                      className="input-percent"
                      type="number"
                      min="1"
                      max="100"
                      value={inputPercent10}
                      error={errorInputs.inputPercent10}
                      onChange={({ target = {} }) => {
                        errorInputs.inputPercent10 = false
                        const inputValue = parseFloat(target.value)
                        setInputPercent10(_.isNaN(inputValue) ? undefined : inputValue)
                      }}
                      placeholder="--"
                    ></Input>
                    <div className="input-percent-symbol">%</div>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <div
                    className={`field-error-label error-msg-percentage ${
                      !_.isEmpty(errorInputs.errorCustomBlend) ? 'show' : ''
                    }`}
                  >
                    <Icon name="warning circle" /> {errorInputs.errorCustomBlend}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div className={`flex end mar-right-1_5`}>
              <div className={`custBlend-offset-group flex`}>
                <div className={`offset-label`}>% Offset ±</div>
                <Input
                  name="ui_display_name"
                  className={`offset-input-percent ${offsetInputError?'error':''}`}
                  type="number"
                  value={offsetInput}
                  min="0"
                  max="100"
                  onChange={(e, data) => {
                    handleOffsetInput(data)
                  }}
                />
                <div className={`offset-input-percent-symbol`}>%</div>
              </div>

              <Button className="reset-btn-container" onClick={onClickReset}>
                Reset
              </Button>
              <Button className="btn-primary search-btn-container" onClick={onClickSearchInput}>
                Search
              </Button>
            </div>
          </div>
        )}
      </div>
    </Grid.Column>
  )
}

export default SearchPanel
