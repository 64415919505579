import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { useDispatch } from 'react-redux'

import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown.js";

import PropTypes from 'prop-types'
import ReactDOMServer from 'react-dom/server'
import Tooltip from '../../tooltip'

// Helpers
import { stringToDecimalPoint } from '../../../helpers/variables'

drilldown(Highcharts);

export const CorrosionParamterChart = (props) => {
  const dispatch = useDispatch()
  const CHART_OPTIONS = setChartOptions(
    props.corrosionParamterTrendChartData,
    dispatch,
    props.period,
    props.unit,
  )
  
  return <HighchartsReact
  highcharts={Highcharts}
  containerProps={{ style: { width: "100%", height: "100%" } }}
  constructorType={"chart"}
  options={CHART_OPTIONS}
  immutable={true}
/>
}

CorrosionParamterChart.propTypes = {
  CorrosionParamterChartData: PropTypes.object,
}

function setChartOptions(data, dispatch, period, unit) {
  const chartSeries = _.get(data, 'series')
  const chartDrillDown = _.get(data, 'drilldown')

  return {
    chart: {
      type: 'spline',
      marginBottom: 100,
    },
    credits: {
      enabled: false,
    },

    title: {
      text: '',
    },

    yAxis: {
      title: {
        text: null,
      },
    },
    colors: ['#FFD645', '#FFA000', '#00AFED', '#0081C9', '#3BC2C7', '#259396', '#7A8CD1'],

    xAxis: {
      type: 'category',
    },

    legend: {
      enabled: false,
    },

    tooltip: getTooltip(data, tooltipFormatter, period),

    plotOptions: {
      spline: {
        states: {
          select: {
            enabled: true,
          },
          hover: {
            enabled: true,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
      series: {
        label: {
          connectorAllowed: false,
        },
        pointStart: 0,
        marker: {
          enabled: true,
          symbol: 'circle',
        },
        inactive: {
          opacity: 1,
        },
      },
    },

    series: chartSeries,
    drilldown: {
      activeAxisLabelStyle: {
        textDecoration: 'none',
      },
      activeDataLabelStyle: {
        textDecoration: 'none',
      },
      drillUpButton: {
        position: {
          align: 'left',
          verticalAlign: 'bottom',
          relativeTo: 'spacingBox',
          y: 50,
          x: 0,
        },
        theme: {
          r: 4,
        },
      },
      series: chartDrillDown,
    },
  }
}

const getTooltip = (payload, formatter, period) => {
  return {
    shared: false,
    backgroundColor: null,
    borderWidth: 0,
    shadow: false,
    className: 'test-classname-tooltip',
    style: {
      padding: 0,
    },
    useHTML: true,
    formatter: function () {
      return formatter(this, payload, period)
    },
    outside: true,
  }
}

function getTooltipContent(category, data) {
  let content = _.map(data.series, (a) => ({
    name: a.name,
    maxYear: a.maxYear,
    maxYearDate: a.maxYearDate,
    minYear: a.minYear,
    minYearDate: a.minYearDate,
    monthlyInfo: a.monthlyInfo
  }))
  return content
}

// tooltip in normal view
const tooltipFormatter = (chart, data, period) => {
  const x = chart
  const i = x.series.index
  const points = getTooltipContent(x.x, data)

  const index = x.point.index
  const drilldownCategory = x.point.name
  const drilldown = x.point.series.chart.series

  const ddDupesMonth = _.get(x.point.series.chart, 'ddDupes')
  const getFirstArray = _.first(ddDupesMonth)
  const splitArray = _.split(getFirstArray, '_')
  const month = _.last(splitArray)

  const getPeriod = _.map(period, (a) => a.startDate)
  const year = moment(...getPeriod).format('YYYY')


  // const options = {
  //   title: _.get(x.point.series, 'name'),
  //   items: [
  //     [
  //       {
  //         type: 'card',
  //         label: 'Min.',
  //         value: stringToDecimalPoint(points[i].min, 2, 2),
  //       },
  //       {
  //         type: 'card',
  //         label: 'Min. Date',
  //         value: points[i].mindate,
  //       },
  //       {
  //         type: 'card',
  //         label: 'Max.',
  //         value: stringToDecimalPoint(points[i].max, 2, 2),
  //       },
  //       {
  //         type: 'card',
  //         label: 'Max. Date',
  //         value: points[i].maxdate,
  //       },
  //     ],
  //   ],
  // }

  const drilldownOptions = {
    title: `Blend of ${drilldownCategory} ${_.startCase(month)} ${year}`,
    items: [
      _.map(drilldown, (a) => ({
        type: 'card',
        label: a.name,
        value: stringToDecimalPoint(a.data[index].y, 2, 2),
        indicatorColor: a.data[index].color,
      })),
    ],
  }

  const getMin = _.map(points[i].monthlyInfo, item => item.min)
  const getMax = _.map(points[i].monthlyInfo, item => item.max)
  const getMinDate = _.map(points[i].monthlyInfo, item => item.minDate)
  const getMaxDate = _.map(points[i].monthlyInfo, item => item.maxDate)

  return ReactDOMServer.renderToString(
    _.isEmpty(chart.series.chart.ddDupes) ? (
      <div className={`corrosion-custom-tooltip flex column`}>
        <div className={`corrosion-border-radius center text-white pad-y-0_5 mar-0 bold`} style={{ backgroundColor: `${data.series[i].color}`}}>{_.upperCase(_.get(x.point.series, 'name'))}</div>
        <div className={`pad-x-1 pad-y-0_5 mar-0 corrosion-divider bold`}>Min.</div>
        <div className={`flex space-between pad-x-1 pad-y-0_5 mar-0 corrosion-divider`}>
          <div className={`content-title-container`}>{_.upperCase(x.key)} Min.</div>
          <div className={`content-value-container bold`}>{stringToDecimalPoint(getMin[index], 2, 2)}</div>
          <div className={`content-date-container bold`}>{_.upperCase(getMinDate[index])}</div>
        </div>
        <div className={`flex space-between pad-x-1 pad-y-0_5 mar-0 corrosion-divider`}>
          <div className={`content-title-container`}>Yearly Min.</div>
          <div className={`content-value-container bold`}>{stringToDecimalPoint(points[i].minYear, 2, 2)}</div>
          <div className={`content-date-container bold`}>{_.upperCase(points[i].minYearDate)}</div>
        </div>
        <div className={`pad-x-1 pad-y-0_5 mar-0 corrosion-divider bold`}>Max.</div>
        <div className={`flex space-between pad-x-1 pad-y-0_5 mar-0 corrosion-divider`}>
          <div className={`content-title-container`}>{_.upperCase(x.key)} Max.</div>
          <div className={`content-value-container bold`}>{stringToDecimalPoint(getMax[index], 2, 2)}</div>
          <div className={`content-date-container bold`}>{_.upperCase(getMaxDate[index])}</div>
        </div>
        <div className={`flex space-between pad-x-1 pad-top-0_5 pad-bottom-1 mar-0`}>
          <div className={`content-title-container`}>Yearly Max.</div>
          <div className={`content-value-container bold`}>{stringToDecimalPoint(points[i].maxYear, 2, 2)}</div>
          <div className={`content-date-container bold`}>{_.upperCase(points[i].maxYearDate)}</div>
        </div>
        
      </div>
      // <Tooltip
      //   large
      //   headerColor={data.series[i].color}
      //   type="TooltipTwoColumns"
      //   {...options}
      //   className={'plant-production-volume-tooltip'}
      // />
    ) : (
      <Tooltip
        large
        type="TooltipTwoColumns"
        {...drilldownOptions}
        className={'plant-production-volume-tooltip'}
      />
    )
  )
}
