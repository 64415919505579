import React from 'react'
import _ from 'lodash'
import { Segment } from 'semantic-ui-react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import NoDataBlock from '../../../../components/no-data-block'

const ShellCompatibility = (props) => {
  const data = _.get(props.shellAssessmentData, 'data')
  return (
    <div className={`shell-compatibility mar-x-2`}>
      <Segment className={`segment-container`}>
        <div>
          <div className={`flex column`}>
            <div className={`flex wrap space-evenly center pad-bottom-4`}>
              {_.map(data, (a, index) => (
                <div className={`flex column center`}>
                  <div className={`flex space-evenly center`}>
                    {_.map(a.crudeSelected, (item) => (
                      <div className={`flex column center pad-top-2 pad-bottom-1`}>
                        <div className={`crude-code`}>{item.crudeCode}</div>
                        <div className={`crude-name`}>{item.crudeName}</div>
                      </div>
                    ))}
                  </div>
                  {
                    <div className={`flex center`}>
                      {/* <img src={`data:image/png;base64,${a.image}`} /> */}
                      {_.isNull(a.imageURL) ? (
                        <NoDataBlock />
                      ) : (
                        <LazyLoadImage
                          effect="blur"
                          src={a.imageURL}
                          key={index}
                          width={400}
                          height={400}
                        />
                      )}
                    </div>
                  }
                </div>
              ))}
            </div>
          </div>
        </div>
      </Segment>
    </div>
  )
}

export default ShellCompatibility
