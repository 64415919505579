import _ from 'lodash'
import {
  postCheckCompatibilityShell,
  postCheckCompatibilityLab,
  postCheckCompatibilityProcessingHistory,
  postCheckCompatibilityCalculation,
  postCheckCompatibilityAssessment,
} from '../services/serviceCompatibility'
import { fetchStart, fetchSuccess, fetchError } from './common/actionFetch'
import * as CompatibilityPageConstants from '../../src/containers/views/compatibility-page/helpers/constants'

export const POST_CHECK_COMPATIBILITY_LAB = 'POST_CHECK_COMPATIBILITY_LAB'
export const POST_CHECK_COMPATIBILITY_SHELL = 'POST_CHECK_COMPATIBILITY_SHELL'
export const POST_CHECK_COMPATIBILITY_PROCESSING = 'POST_CHECK_COMPATIBILITY_PROCESSING'
export const POST_CHECK_COMPATIBILITY_CALCULATION = 'POST_CHECK_COMPATIBILITY_CALCULATION'
export const POST_CHECK_COMPATIBILITY_ASSESSMENT = 'POST_CHECK_COMPATIBILITY_ASSESSMENT'
export const setCheckCompatibility = (
  option,
  currentPersona,
  inputUnit1,
  inputUnit2,
  inputUnit3,
  inputUnit4,
  inputUnit5,
  inputUnit6,
  inputUnit7,
  inputUnit8,
  inputUnit9,
  inputUnit10
) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_CHECK_COMPATIBILITY_LAB))
    postCheckCompatibilityLab(
      convertInputUnitsToBlendComposition(
        option,
        inputUnit1,
        inputUnit2,
        inputUnit3,
        inputUnit4,
        inputUnit5,
        inputUnit6,
        inputUnit7,
        inputUnit8,
        inputUnit9,
        inputUnit10
      )
    )
      .then((response) =>
        dispatch({
          type: POST_CHECK_COMPATIBILITY_LAB,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_CHECK_COMPATIBILITY_LAB)))
      .catch(() => dispatch(fetchError(POST_CHECK_COMPATIBILITY_LAB)))

    dispatch(fetchStart(POST_CHECK_COMPATIBILITY_SHELL))
    postCheckCompatibilityShell(
      convertInputUnitsToBlendComposition(
        option,
        inputUnit1,
        inputUnit2,
        inputUnit3,
        inputUnit4,
        inputUnit5,
        inputUnit6,
        inputUnit7,
        inputUnit8,
        inputUnit9,
        inputUnit10
      )
    )
      .then((response) =>
        dispatch({
          type: POST_CHECK_COMPATIBILITY_SHELL,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_CHECK_COMPATIBILITY_SHELL)))
      .catch(() => dispatch(fetchError(POST_CHECK_COMPATIBILITY_SHELL)))

    dispatch(fetchStart(POST_CHECK_COMPATIBILITY_PROCESSING))
    postCheckCompatibilityProcessingHistory({
      blendComposition: convertInputUnitsToBlendComposition(
        option,
        inputUnit1,
        inputUnit2,
        inputUnit3,
        inputUnit4,
        inputUnit5,
        inputUnit6,
        inputUnit7,
        inputUnit8,
        inputUnit9,
        inputUnit10
      ),
      currentPersona: currentPersona,
    })
      .then((response) =>
        dispatch({
          type: POST_CHECK_COMPATIBILITY_PROCESSING,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_CHECK_COMPATIBILITY_PROCESSING)))
      .catch(() => dispatch(fetchError(POST_CHECK_COMPATIBILITY_PROCESSING)))
  }
}

export const setCheckCompatibilityCalculation = (
  option,
  calUnit1,
  calUnit2,
  calUnit3,
  calUnit4,
  calUnit5,
  calUnit6,
  calUnit7,
  calUnit8,
  calUnit9,
  calUnit10,
  calPercent1,
  calPercent2,
  calPercent3,
  calPercent4,
  calPercent5,
  calPercent6,
  calPercent7,
  calPercent8,
  calPercent9,
  calPercent10
) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_CHECK_COMPATIBILITY_CALCULATION))
    postCheckCompatibilityCalculation(
      convertInputUnitsToBlendComposition(
        option,
        calUnit1,
        calUnit2,
        calUnit3,
        calUnit4,
        calUnit5,
        calUnit6,
        calUnit7,
        calUnit8,
        calUnit9,
        calUnit10,
        calPercent1,
        calPercent2,
        calPercent3,
        calPercent4,
        calPercent5,
        calPercent6,
        calPercent7,
        calPercent8,
        calPercent9,
        calPercent10
      )
    )
      .then((response) =>
        dispatch({
          type: POST_CHECK_COMPATIBILITY_CALCULATION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_CHECK_COMPATIBILITY_CALCULATION)))
      .catch(() => dispatch(fetchError(POST_CHECK_COMPATIBILITY_CALCULATION)))
  }
}

export const setCheckCompatibilityAssessment = (
  option,
  assessUnit1,
  assessUnit2,
  assessUnit3,
  assessUnit4,
  assessUnit5,
  assessUnit6,
  assessUnit7,
  assessUnit8,
  assessUnit9,
  assessUnit10,
  assessPercent1,
  assessPercent2,
  assessPercent3,
  assessPercent4,
  assessPercent5,
  assessPercent6,
  assessPercent7,
  assessPercent8,
  assessPercent9,
  assessPercent10,
  assessUnit11,
  assessUnit12,
  assessUnit13,
  assessUnit14,
  assessUnit15,
  assessUnit16,
  assessUnit17,
  assessUnit18,
  assessUnit19,
  assessUnit20,
  assessPercent11,
  assessPercent12,
  assessPercent13,
  assessPercent14,
  assessPercent15,
  assessPercent16,
  assessPercent17,
  assessPercent18,
  assessPercent19,
  assessPercent20,
  assessUnit21,
  assessUnit22,
  assessUnit23,
  assessUnit24,
  assessUnit25,
  assessUnit26,
  assessUnit27,
  assessUnit28,
  assessUnit29,
  assessUnit30,
  assessPercent21,
  assessPercent22,
  assessPercent23,
  assessPercent24,
  assessPercent25,
  assessPercent26,
  assessPercent27,
  assessPercent28,
  assessPercent29,
  assessPercent30
) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_CHECK_COMPATIBILITY_ASSESSMENT))
    postCheckCompatibilityAssessment(
      convertInputUnitsToBlendComposition(
        option,
        assessUnit1,
        assessUnit2,
        assessUnit3,
        assessUnit4,
        assessUnit5,
        assessUnit6,
        assessUnit7,
        assessUnit8,
        assessUnit9,
        assessUnit10,
        assessPercent1,
        assessPercent2,
        assessPercent3,
        assessPercent4,
        assessPercent5,
        assessPercent6,
        assessPercent7,
        assessPercent8,
        assessPercent9,
        assessPercent10,
        assessUnit11,
        assessUnit12,
        assessUnit13,
        assessUnit14,
        assessUnit15,
        assessUnit16,
        assessUnit17,
        assessUnit18,
        assessUnit19,
        assessUnit20,
        assessPercent11,
        assessPercent12,
        assessPercent13,
        assessPercent14,
        assessPercent15,
        assessPercent16,
        assessPercent17,
        assessPercent18,
        assessPercent19,
        assessPercent20,
        assessUnit21,
        assessUnit22,
        assessUnit23,
        assessUnit24,
        assessUnit25,
        assessUnit26,
        assessUnit27,
        assessUnit28,
        assessUnit29,
        assessUnit30,
        assessPercent21,
        assessPercent22,
        assessPercent23,
        assessPercent24,
        assessPercent25,
        assessPercent26,
        assessPercent27,
        assessPercent28,
        assessPercent29,
        assessPercent30
      )
    )
      .then((response) =>
        dispatch({
          type: POST_CHECK_COMPATIBILITY_ASSESSMENT,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_CHECK_COMPATIBILITY_ASSESSMENT)))
      .catch(() => dispatch(fetchError(POST_CHECK_COMPATIBILITY_ASSESSMENT)))
  }
}

const convertInputUnitsToBlendComposition = (
  option,
  inputUnit1,
  inputUnit2,
  inputUnit3,
  inputUnit4,
  inputUnit5,
  inputUnit6,
  inputUnit7,
  inputUnit8,
  inputUnit9,
  inputUnit10,
  inputPercent1,
  inputPercent2,
  inputPercent3,
  inputPercent4,
  inputPercent5,
  inputPercent6,
  inputPercent7,
  inputPercent8,
  inputPercent9,
  inputPercent10,
  inputUnit11,
  inputUnit12,
  inputUnit13,
  inputUnit14,
  inputUnit15,
  inputUnit16,
  inputUnit17,
  inputUnit18,
  inputUnit19,
  inputUnit20,
  inputPercent11,
  inputPercent12,
  inputPercent13,
  inputPercent14,
  inputPercent15,
  inputPercent16,
  inputPercent17,
  inputPercent18,
  inputPercent19,
  inputPercent20,
  inputUnit21,
  inputUnit22,
  inputUnit23,
  inputUnit24,
  inputUnit25,
  inputUnit26,
  inputUnit27,
  inputUnit28,
  inputUnit29,
  inputUnit30,
  inputPercent21,
  inputPercent22,
  inputPercent23,
  inputPercent24,
  inputPercent25,
  inputPercent26,
  inputPercent27,
  inputPercent28,
  inputPercent29,
  inputPercent30
) => {
  const searchObject = {
    inputUnit1,
    inputUnit2,
    inputUnit3,
    inputUnit4,
    inputUnit5,
    inputUnit6,
    inputUnit7,
    inputUnit8,
    inputUnit9,
    inputUnit10,
    inputPercent1,
    inputPercent2,
    inputPercent3,
    inputPercent4,
    inputPercent5,
    inputPercent6,
    inputPercent7,
    inputPercent8,
    inputPercent9,
    inputPercent10,
    inputUnit11,
    inputUnit12,
    inputUnit13,
    inputUnit14,
    inputUnit15,
    inputUnit16,
    inputUnit17,
    inputUnit18,
    inputUnit19,
    inputUnit20,
    inputPercent11,
    inputPercent12,
    inputPercent13,
    inputPercent14,
    inputPercent15,
    inputPercent16,
    inputPercent17,
    inputPercent18,
    inputPercent19,
    inputPercent20,
    inputUnit21,
    inputUnit22,
    inputUnit23,
    inputUnit24,
    inputUnit25,
    inputUnit26,
    inputUnit27,
    inputUnit28,
    inputUnit29,
    inputUnit30,
    inputPercent21,
    inputPercent22,
    inputPercent23,
    inputPercent24,
    inputPercent25,
    inputPercent26,
    inputPercent27,
    inputPercent28,
    inputPercent29,
    inputPercent30,
  }

  let blendComposition = []
  let blendCompositionAssessment = {
    setA: [],
    setB: [],
    setC: [],
  }

  let isAssessment = option === CompatibilityPageConstants.SEARCH_COMPATIBILITY.ASSESSMENT
  let totalNumber = isAssessment ? 30 : 10
  for (let count = 1; count <= totalNumber; count++) {
    if (
      !_.isNull(
        _.get(searchObject, 'inputUnit' + count) &&
          !_.isEmpty(_.get(searchObject, 'inputUnit' + count)) &&
          !_.isNull(_.get(searchObject, 'inputPercent' + count)) &&
          _.get(searchObject, 'inputPercent' + count) !== ''
      )
    ) {
      if (isAssessment) {
        if (count <= 10) {
          blendCompositionAssessment.setA.push({
            crudeCode: _.get(searchObject, 'inputUnit' + count + '.crudeCode'),
            percentage: _.get(searchObject, 'inputPercent' + count),
          })
        } else if (count <= 20) {
          blendCompositionAssessment.setB.push({
            crudeCode: _.get(searchObject, 'inputUnit' + count + '.crudeCode'),
            percentage: _.get(searchObject, 'inputPercent' + count),
          })
        } else if (count <= 30) {
          blendCompositionAssessment.setC.push({
            crudeCode: _.get(searchObject, 'inputUnit' + count + '.crudeCode'),
            percentage: _.get(searchObject, 'inputPercent' + count),
          })
        }
      } else {
        blendComposition.push({
          crudeCode: _.get(searchObject, 'inputUnit' + count + '.crudeCode'),
          percentage: _.get(searchObject, 'inputPercent' + count),
        })
      }
    }
  }

  return isAssessment ? blendCompositionAssessment : blendComposition
}
