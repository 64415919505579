import React, { useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown.js";
import lollipop from "highcharts/modules/lollipop";
import coordinate from "highcharts/modules/parallel-coordinates.js"
import HC_more from "highcharts/highcharts-more";
import PropTypes from "prop-types";

drilldown(Highcharts);
lollipop(Highcharts);
coordinate(Highcharts);
HC_more(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
    drillUpText: "<< Back to Year Trend",
  },
});

const ReactHighchart = ({ options, callback }) => {
  return (
    <div className="highchart-wrapper">
      <HighchartsReact
        highcharts={Highcharts}
        containerProps={{ style: { height: "100%" } }}
        constructorType={"chart"}
        options={options}
        callback={callback}
      />
    </div>
  );
};

ReactHighchart.propTypes = {
  options: PropTypes.object.isRequired,
  callback: PropTypes.func,
};

export default ReactHighchart;
