import _ from 'lodash'
import * as actions from '../actions/actionProcessing'
import { startOfMonth, endOfMonth, addDays, subDays } from 'date-fns'

const defaultState = {
  cdu1: {
    selectedPeriod: {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: 'selection',
    },
    chartData: {},
  },
  cdu2: {
    selectedPeriod: {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: 'selection',
    },
    chartData: {},
  },
  cdu3: {
    selectedPeriod: {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: 'selection',
    },
    chartData: {},
  },
}

const initialState = defaultState

const REDUCERS = {
  [actions.PROCESSING_CDU1_PERIOD_UPDATE]: (state, { payload }) => {
    return {
      ...state,
      cdu1: {
        ...state.cdu1,
        selectedPeriod: payload,
      },
    }
  },
  [actions.PROCESSING_CDU1_FETCH_CHART_DATA]: (state, { payload }) => {
    return {
      ...state,
      cdu1: {
        ...state.cdu1,
        chartData: payload,
      },
    }
  },
  [actions.PROCESSING_CDU2_PERIOD_UPDATE]: (state, { payload }) => {
    return {
      ...state,
      cdu2: {
        ...state.cdu2,
        selectedPeriod: payload,
      },
    }
  },
  [actions.PROCESSING_CDU2_FETCH_CHART_DATA]: (state, { payload }) => {
    return {
      ...state,
      cdu2: {
        ...state.cdu2,
        chartData: payload,
      },
    }
  },
  [actions.PROCESSING_CDU3_PERIOD_UPDATE]: (state, { payload }) => {
    return {
      ...state,
      cdu3: {
        ...state.cdu3,
        selectedPeriod: payload,
      },
    }
  },
  [actions.PROCESSING_CDU3_FETCH_CHART_DATA]: (state, { payload }) => {
    return {
      ...state,
      cdu3: {
        ...state.cdu3,
        chartData: payload,
      },
    }
  },
}
export default (state = initialState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}
export const PROCESSING_PAGE_REDUCER = 'processingPage'

function getSampleChartData() {
  return {
    // categories: ['JAS', 'CEN', 'LAB', 'BRV', 'PHT', 'SLO', 'MUR', 'BNG', 'BAK', 'NKO'],
    // series: [
    //   {
    //     name: 'ACTUAL',
    //     data: [29, 9, 29, 29, 18, 6, 3, 1, 0, 0],
    //   },
    //   {
    //     name: 'APS',
    //     data: [28, 12, 18, 12, 19, 6, 3, 1, 0, 0],
    //   }
    // ]
  }
}
