import _ from 'lodash'
import { useState, useEffect, useCallback } from 'react'

export const SCREEN_MODE = {
  PORTRAIT: 'portrait',
  LANDSCAPE: 'landscape',
  DESKTOP: 'desktop'
}

export const SCREEN_SIZE = {
  PORTRAIT: 0,
  LANDSCAPE: 640,
  DESKTOP: 1024
}

const getMode = () => {
  let newMode = SCREEN_MODE.DESKTOP
  if (window.innerWidth < SCREEN_SIZE.LANDSCAPE) newMode = SCREEN_MODE.PORTRAIT
  if (window.innerWidth >= SCREEN_SIZE.LANDSCAPE && window.innerWidth < SCREEN_SIZE.DESKTOP)
    newMode = SCREEN_MODE.LANDSCAPE
	if (window.innerWidth >= SCREEN_SIZE.DESKTOP) newMode = SCREEN_MODE.DESKTOP
  return newMode
}

export const useMobile = () => {
  const [mode, setMode] = useState(getMode())

  const setNewMode = useCallback(() => {
    const newMode = getMode()
    if (newMode !== mode) {
      setMode(newMode)
    }
  }, [mode])

  useEffect(() => {
    window.addEventListener('resize', setNewMode)
    return () => window.removeEventListener('resize', setNewMode)
  }, [setNewMode])
  return mode
}
