import _ from 'lodash'


import { LAMBDA_API_INSTANCE } from '../serverInstances/lambdaServer'

const CONTROLLER_NAME = '/master'

export const getMasterData = (pathName, bodyData) => {
  return LAMBDA_API_INSTANCE.post(`${CONTROLLER_NAME}/${pathName}`, bodyData)
}
