import React, { useState, useEffect, useCallback, useMemo } from 'react'
import _ from 'lodash'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { useJsonToCsv } from 'react-json-csv'

//Asset
import IconCloseWhite from '../../../../assets/images/icon-close-white.svg'

//Components
import { Grid, Header, Divider, Dropdown, Segment, Button, Icon, Image } from 'semantic-ui-react'
import { CrudeCompareChart } from '../../../../components/charts/statistical-analysis-chart/crude-compare-chart'
import { FreeformChart } from '../../../../components/charts/statistical-analysis-chart/freefrom-chart'
import PeriodPicker from '../../../../components/period-picker'
import NoDataBlock from '../../../../components/no-data-block'

//Redux
import { PERSONAL_SETTING_REDUCER } from '../../../../reducers/common/personalSettingReducer'
import { MASTER_DATA_REDUCER } from '../../../../reducers/common/masterDataReducer'
import { STATISTICAL_ANALYSIS_PAGE_REDUCER } from '../../../../reducers/statisticalAnalaysisPageReducer'
import * as MasterDataActions from '../../../../actions/common/actionMasterData'
import * as StatisticalAnalysisPageActions from '../../../../actions/actionStatisticalAnalysis'

//Hooks
import { useStateWithPaths, useActions } from '../../../../hooks/useConnect'

const moment = extendMoment(Moment)

const CrudePropertyComparison = (props) => {
  return (
    <Grid padded>
      <Grid.Row columns={2} verticalAlign="bottom">
        <Grid.Column textAlign="left">
          <Header size="small" textAlign="left">
            BLEND COMPARISON
          </Header>
        </Grid.Column>
      </Grid.Row>
      <div className={`flex space-between width-100 pad-bottom-2`}>
        {_.map(props.data, (item) => (
          <div className={`card-container mar-0 pad-x-12 pad-y-20`}>
            <div className={`flex column`}>
              <div className={`title-crudeCode`} style={{ color: `${item.color}` }}>
                {item.name}
              </div>
              <div className={`flex center`}>
                <div>Blend Of</div>
                <div className={`title-crudeName`}>
                  {_.upperCase(moment(item.date).format('DD MMM YYYY'))}
                </div>
              </div>
            </div>
            <CrudeCompareChart data={item} />
            <div className={`flex end`}>
              <div className={`flex center`}>
                <div
                  className={`legend-box`}
                  style={{
                    backgroundColor: `#F5F5F5`,
                    border: `1px solid #F5F5F5`,
                  }}
                />
                <div className={`legend-title`}>Processing Limit</div>
              </div>
              <div className={`flex center`}>
                <div
                  className={`legend-box`}
                  style={{
                    backgroundColor: `${item.color}`,
                    border: `1px solid ${item.color}`,
                  }}
                />
                <div className={`legend-title`}>{item.name} Property</div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Grid.Row verticalAlign="bottom">
        <Grid.Column>
          <div className={`table-container mar-bottom-2`}>
            <div className={`flex pad-x-2 pad-top-1 space-between`}>
              <div className={`text-primary crude-property-container crude-property-text`}>
                Blend Property
              </div>
              {_.map(props.data, (item) => (
                <div className={`flex`}>
                  <div className={`crude-code-container`} style={{ color: `${item.color}` }}>
                    {item.name}
                  </div>
                  <div>{_.upperCase(moment(item.date).format('DD MMM YYYY'))}</div>
                </div>
              ))}
              <div className={`text-primary crude-code-container`}>Min. Limit</div>
              <div className={`text-primary crude-code-container`}>Max. Limit</div>
            </div>
            <Divider />
            <div className={`flex pad-x-2 pad-bottom-2`}>
              <div className={`flex width-100 space-between`}>
                {_.map([props.data[0]], (item) => (
                  <div>
                    {_.map(item.properties, (property, i) => (
                      <>
                        <div className={`flex center mar-bottom-1 crude-property-value-container`}>
                          {property.name}
                        </div>
                      </>
                    ))}
                  </div>
                ))}
                {_.map(props.data, (item) => (
                  <div>
                    {_.map(item.properties, (property, i) => (
                      <div
                        className={`flex center mar-bottom-1 crude-value-container`}
                        style={{
                          color: `${
                            _.isEqual(property.max, '-') && _.isEqual(property.min, '-')
                              ? '#000000'
                              : _.isNull(property.max) && _.isNull(property.min)
                              ? '#000000'
                              : property.value >= property.max || property.value <= property.min
                              ? '#E95258'
                              : '#000000'
                          }`,
                          fontWeight: `${
                            _.isEqual(property.max, '-') && _.isEqual(property.min, '-')
                              ? ''
                              : _.isNull(property.max) && _.isNull(property.min)
                              ? ''
                              : property.value >= property.max || property.value <= property.min
                              ? '700'
                              : ''
                          }`,
                        }}
                      >
                        {!_.isEmpty(property.value) ? property.value.toFixed(2) : property.value}
                      </div>
                    ))}
                  </div>
                ))}
                {_.map([props.data[0]], (item) => (
                  <div>
                    {_.map(item.properties, (property, i) => (
                      <>
                        <div className={`flex center mar-bottom-1 crude-value-container`}>
                          {_.isNull(property.min) ? 'N.A' : property.min}
                        </div>
                      </>
                    ))}
                  </div>
                ))}
                {_.map([props.data[0]], (item) => (
                  <div>
                    {_.map(item.properties, (property, i) => (
                      <>
                        <div className={`flex center mar-bottom-1 crude-value-container`}>
                          {_.isNull(property.max) ? 'N.A' : property.max}
                        </div>
                      </>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

const FreeformTrend = (props) => {
  const { saveAsCsv } = useJsonToCsv()
  const tableData = _.get(props.freeformChartData, 'series')

  const tableDataForExport = useMemo(() => {
    return _.map(tableData, (item, index) => {
      return _.mapValues(item, (value, key) => {
        return value
      })
    })
  }, [tableData])

  const transformTableData = [
    {
      dataSource: props.dataSource1,
      unit: props.unit1,
      fraction: props.stream1,
    },
    {
      dataSource: props.dataSource2,
      unit: props.unit2,
      fraction: props.stream2,
    },
    {
      dataSource: props.dataSource3,
      unit: props.unit3,
      fraction: props.stream3,
    },
    {
      dataSource: props.dataSource4,
      unit: props.unit4,
      fraction: props.stream4,
    },
    {
      dataSource: props.dataSource5,
      unit: props.unit5,
      fraction: props.stream5,
    },
  ]

  const formatSearchObject = _.map(transformTableData, (item) =>
    !_.isEmpty(item.dataSource)
      ? { dataSource: item.dataSource, unit: item.unit, fraction: item.fraction }
      : {}
  )
  const filterSearchObject = _.filter(formatSearchObject, (v) => _.keys(v).length !== 0)

  const chartExport = _.merge(tableData, filterSearchObject)

  const startDate = _.map(props.period, (item) => item.startDate)
  const endDate = _.map(props.period, (item) => item.endDate)

  const formatStart = moment(startDate.toString()).format('YYYY-MM-DD')
  const formatEnd = moment(endDate.toString()).format('YYYY-MM-DD')
  const range = moment.range(formatStart, formatEnd)

  for (let days of range.by('month')) {
    days.format('YYYY-MM-DD')
  }

  const days = Array.from(range.by('days'))
  const daysInMonth = days.map((m) => m.format('DD MMM'))

  const fileNameStartDate = moment(startDate.toString()).format('DD-MMM-YYYY')
  const fileNameEndDate = moment(endDate.toString()).format('DD-MMM-YYYY')

  const transfromFileName = `Freeform trend ${fileNameStartDate} - ${fileNameEndDate}`

  const clickExport = useCallback(
    (e) => {
      const filename = transfromFileName,
        fields = {
          dataSource: 'Data Source',
          unit: 'Unit',
          fraction: 'Fraction',
          name: 'Property',
          data: daysInMonth,
        },
        style = {
          padding: '5px',
        },
        data = chartExport
      saveAsCsv({ data, fields, filename, style })
    },
    [chartExport, saveAsCsv]
  )

  const onClickExport = () => {
    clickExport()
  }

  const onClickResetDataSourceParameter = (index) => {
    if (index === 1) {
      props.setDataSource1('')
      props.setUnit1('')
      props.setStream1('')
      props.setProperty1('')
    } else if (index === 2) {
      props.setDataSource2('')
      props.setUnit2('')
      props.setStream2('')
      props.setProperty2('')
    } else if (index === 3) {
      props.setDataSource3('')
      props.setUnit3('')
      props.setStream3('')
      props.setProperty3('')
    } else if (index === 4) {
      props.setDataSource4('')
      props.setUnit4('')
      props.setStream4('')
      props.setProperty4('')
    } else if (index === 5) {
      props.setDataSource5('')
      props.setUnit5('')
      props.setStream5('')
      props.setProperty5('')
    }
  }

  return (
    <Grid padded>
      <Grid.Row columns={1} verticalAlign="bottom">
        <Grid.Column textAlign="left">
          <div className={`flex center space-between`}>
            <div className={`flex`}>
              <Header className={`mar-0`} size="small" textAlign="left">
                FREEFORM TREND
              </Header>
            </div>
            <PeriodPicker
              defaultPeriod={props.defaultPeriod}
              period={props.period}
              setPeriod={props.setPeriod}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
      <Segment className={`width-100`}>
        <div className={`pad-2`}>
          <Grid.Row>
            <div className={`pad-y-2`}>
              {_.isEmpty(props.freeformChartData) ? (
                <NoDataBlock />
              ) : (
                <FreeformChart freeformChartData={props.freeformChartData} />
              )}
            </div>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header
                className={`mar-bottom-0 mar-left-0 text-primary`}
                size="small"
                textAlign="left"
              >
                Data Source & Parameter
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div className={`pad-top-1 flex center space-between`}>
                <div
                  className={`custom-legend-icon`}
                  style={{ backgroundColor: `${props.color1}` }}
                ></div>
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Data Source"
                  value={props.dataSource1}
                  selection
                  options={props.freeformDataSource}
                  onChange={(e, data) => {
                    props.setDataSource1(data.value)
                    props.setStream1('')
                    props.setProperty1('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Unit"
                  value={props.unit1}
                  selection
                  options={props.freeformUnit}
                  onChange={(e, data) => {
                    props.setUnit1(data.value)
                    props.setStream1('')
                    props.setProperty1('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Stream"
                  value={props.stream1}
                  selection
                  options={props.freeformStream1}
                  onChange={(e, data) => {
                    props.setStream1(data.value)
                    props.setProperty1('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Property"
                  value={props.property1}
                  selection
                  options={props.freeformProperty1}
                  onChange={(e, data) => {
                    props.setProperty1(data.value)
                  }}
                />
                <Button
                  onClick={() => onClickResetDataSourceParameter(1)}
                  className={`button-reset-data-source`}
                >
                  <Image src={IconCloseWhite} />
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div className={`pad-top-1 flex center space-between`}>
                <div
                  className={`custom-legend-icon`}
                  style={{ backgroundColor: `${props.color2}` }}
                ></div>
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Data Source"
                  value={props.dataSource2}
                  selection
                  options={props.freeformDataSource}
                  onChange={(e, data) => {
                    props.setDataSource2(data.value)
                    props.setStream2('')
                    props.setProperty2('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Unit"
                  value={props.unit2}
                  selection
                  options={props.freeformUnit}
                  onChange={(e, data) => {
                    props.setUnit2(data.value)
                    props.setStream2('')
                    props.setProperty2('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Stream"
                  value={props.stream2}
                  selection
                  options={props.freeformStream2}
                  onChange={(e, data) => {
                    props.setStream2(data.value)
                    props.setProperty2('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Property"
                  value={props.property2}
                  selection
                  options={props.freeformProperty2}
                  onChange={(e, data) => {
                    props.setProperty2(data.value)
                  }}
                />
                <Button
                  onClick={() => onClickResetDataSourceParameter(2)}
                  className={`button-reset-data-source`}
                >
                  <Image src={IconCloseWhite} />
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div className={`pad-top-1 flex center space-between`}>
                <div
                  className={`custom-legend-icon`}
                  style={{ backgroundColor: `${props.color3}` }}
                ></div>
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Data Source"
                  value={props.dataSource3}
                  selection
                  options={props.freeformDataSource}
                  onChange={(e, data) => {
                    props.setDataSource3(data.value)
                    props.setStream3('')
                    props.setProperty3('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Unit"
                  value={props.unit3}
                  selection
                  options={props.freeformUnit}
                  onChange={(e, data) => {
                    props.setUnit3(data.value)
                    props.setStream3('')
                    props.setProperty3('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Stream"
                  value={props.stream3}
                  selection
                  options={props.freeformStream3}
                  onChange={(e, data) => {
                    props.setStream3(data.value)
                    props.setProperty3('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Property"
                  value={props.property3}
                  selection
                  options={props.freeformProperty3}
                  onChange={(e, data) => {
                    props.setProperty3(data.value)
                  }}
                />
                <Button
                  onClick={() => onClickResetDataSourceParameter(3)}
                  className={`button-reset-data-source`}
                >
                  <Image src={IconCloseWhite} />
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div className={`pad-top-1 flex center space-between`}>
                <div
                  className={`custom-legend-icon`}
                  style={{ backgroundColor: `${props.color4}` }}
                ></div>
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Data Source"
                  value={props.dataSource4}
                  selection
                  options={props.freeformDataSource}
                  onChange={(e, data) => {
                    props.setDataSource4(data.value)
                    props.setStream4('')
                    props.setProperty4('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Unit"
                  value={props.unit4}
                  selection
                  options={props.freeformUnit}
                  onChange={(e, data) => {
                    props.setUnit4(data.value)
                    props.setStream4('')
                    props.setProperty4('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Stream"
                  value={props.stream4}
                  selection
                  options={props.freeformStream4}
                  onChange={(e, data) => {
                    props.setStream4(data.value)
                    props.setProperty4('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Property"
                  value={props.property4}
                  selection
                  options={props.freeformProperty4}
                  onChange={(e, data) => {
                    props.setProperty4(data.value)
                  }}
                />
                <Button
                  onClick={() => onClickResetDataSourceParameter(4)}
                  className={`button-reset-data-source`}
                >
                  <Image src={IconCloseWhite} />
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div className={`pad-top-1 flex center space-between`}>
                <div
                  className={`custom-legend-icon`}
                  style={{ backgroundColor: `${props.color5}` }}
                ></div>
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Data Source"
                  value={props.dataSource5}
                  selection
                  options={props.freeformDataSource}
                  onChange={(e, data) => {
                    props.setDataSource5(data.value)
                    props.setStream5('')
                    props.setProperty5('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Unit"
                  value={props.unit5}
                  selection
                  options={props.freeformUnit}
                  onChange={(e, data) => {
                    props.setUnit5(data.value)
                    props.setStream5('')
                    props.setProperty5('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Stream"
                  value={props.stream5}
                  selection
                  options={props.freeformStream5}
                  onChange={(e, data) => {
                    props.setStream5(data.value)
                    props.setProperty5('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Property"
                  value={props.property5}
                  selection
                  options={props.freeformProperty5}
                  onChange={(e, data) => {
                    props.setProperty5(data.value)
                  }}
                />
                <Button
                  onClick={() => onClickResetDataSourceParameter(5)}
                  className={`button-reset-data-source`}
                >
                  <Image src={IconCloseWhite} />
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <div className={`flex mar-top-2 end`}>
              <Button size="large" className="btn-secondary icon" onClick={onClickExport}>
                Export <Icon name="external alternate"></Icon>
              </Button>
            </div>
          </Grid.Row>
        </div>
      </Segment>
    </Grid>
  )
}

const CompareBlend = () => {
  const [{ searchCompareBlend, searchResult }] = useStateWithPaths([
    `${STATISTICAL_ANALYSIS_PAGE_REDUCER}`,
  ])

  const [{ currentPersona }] = useStateWithPaths([`${PERSONAL_SETTING_REDUCER}`])

  const [
    {
      freeformDataSource,
      freeformUnit,
      freeformStream1,
      freeformStream2,
      freeformStream3,
      freeformStream4,
      freeformStream5,
      freeformProperty1,
      freeformProperty2,
      freeformProperty3,
      freeformProperty4,
      freeformProperty5,
    },
  ] = useStateWithPaths([`${MASTER_DATA_REDUCER}`])

  const {
    getMasterFreeformDataSource,
    getMasterFreeformUnit,
    getMasterFreeformStream1,
    getMasterFreeformStream2,
    getMasterFreeformStream3,
    getMasterFreeformStream4,
    getMasterFreeformStream5,
    getMasterFreeformProperty1,
    getMasterFreeformProperty2,
    getMasterFreeformProperty3,
    getMasterFreeformProperty4,
    getMasterFreeformProperty5,
  } = useActions({
    getMasterFreeformDataSource: MasterDataActions.getMasterFreeformDataSource,
    getMasterFreeformUnit: MasterDataActions.getMasterFreeformUnit,
    getMasterFreeformStream1: MasterDataActions.getMasterFreeformStream1,
    getMasterFreeformStream2: MasterDataActions.getMasterFreeformStream2,
    getMasterFreeformStream3: MasterDataActions.getMasterFreeformStream3,
    getMasterFreeformStream4: MasterDataActions.getMasterFreeformStream4,
    getMasterFreeformStream5: MasterDataActions.getMasterFreeformStream5,
    getMasterFreeformProperty1: MasterDataActions.getMasterFreeformProperty1,
    getMasterFreeformProperty2: MasterDataActions.getMasterFreeformProperty2,
    getMasterFreeformProperty3: MasterDataActions.getMasterFreeformProperty3,
    getMasterFreeformProperty4: MasterDataActions.getMasterFreeformProperty4,
    getMasterFreeformProperty5: MasterDataActions.getMasterFreeformProperty5,
  })

  const { setFreeformTrend } = useActions({
    setFreeformTrend: StatisticalAnalysisPageActions.setFreeformTrend,
  })

  const [freeformChartData] = useStateWithPaths([
    'statisticalAnalaysisPage.searchCompareBlend.freeformData',
  ])

  const color1 = '#983E66'
  const color2 = '#FFA000'
  const color3 = '#259396'
  const color4 = '#40C3F2'
  const color5 = '#3750B3'

  const [dataSource1, setDataSource1] = useState(searchCompareBlend.freeformDataSource1)
  const [dataSource2, setDataSource2] = useState(searchCompareBlend.freeformDataSource2)
  const [dataSource3, setDataSource3] = useState(searchCompareBlend.freeformDataSource3)
  const [dataSource4, setDataSource4] = useState(searchCompareBlend.freeformDataSource4)
  const [dataSource5, setDataSource5] = useState(searchCompareBlend.freeformDataSource5)

  const [unit1, setUnit1] = useState(searchCompareBlend.freeformUnit1)
  const [unit2, setUnit2] = useState(searchCompareBlend.freeformUnit2)
  const [unit3, setUnit3] = useState(searchCompareBlend.freeformUnit3)
  const [unit4, setUnit4] = useState(searchCompareBlend.freeformUnit4)
  const [unit5, setUnit5] = useState(searchCompareBlend.freeformUnit5)

  const [stream1, setStream1] = useState(searchCompareBlend.freeformStream1)
  const [stream2, setStream2] = useState(searchCompareBlend.freeformStream2)
  const [stream3, setStream3] = useState(searchCompareBlend.freeformStream3)
  const [stream4, setStream4] = useState(searchCompareBlend.freeformStream4)
  const [stream5, setStream5] = useState(searchCompareBlend.freeformStream5)

  const [property1, setProperty1] = useState(searchCompareBlend.freeformProperty1)
  const [property2, setProperty2] = useState(searchCompareBlend.freeformProperty2)
  const [property3, setProperty3] = useState(searchCompareBlend.freeformProperty3)
  const [property4, setProperty4] = useState(searchCompareBlend.freeformProperty4)
  const [property5, setProperty5] = useState(searchCompareBlend.freeformProperty5)

  const [defaultPeriod] = useState([searchCompareBlend.defaultPeriod])
  const [period, setPeriod] = useState([searchCompareBlend.period])

  useEffect(() => {
    setFreeformTrend(
      currentPersona,
      period,
      color1,
      color2,
      color3,
      color4,
      color5,
      dataSource1,
      dataSource2,
      dataSource3,
      dataSource4,
      dataSource5,
      unit1,
      unit2,
      unit3,
      unit4,
      unit5,
      stream1,
      stream2,
      stream3,
      stream4,
      stream5,
      property1,
      property2,
      property3,
      property4,
      property5
    )
  }, [
    setFreeformTrend,
    currentPersona,
    period,
    color1,
    color2,
    color3,
    color4,
    color5,
    dataSource1,
    dataSource2,
    dataSource3,
    dataSource4,
    dataSource5,
    unit1,
    unit2,
    unit3,
    unit4,
    unit5,
    stream1,
    stream2,
    stream3,
    stream4,
    stream5,
    property1,
    property2,
    property3,
    property4,
    property5,
  ])

  // Freeform Data & Unit

  useEffect(() => {
    getMasterFreeformDataSource(currentPersona)
  }, [getMasterFreeformDataSource, currentPersona])

  useEffect(() => {
    getMasterFreeformUnit(currentPersona)
  }, [getMasterFreeformUnit, currentPersona])

  // Freeform Stream

  useEffect(() => {
    getMasterFreeformStream1(currentPersona, unit1, dataSource1)
  }, [getMasterFreeformStream1, currentPersona, unit1, dataSource1])

  useEffect(() => {
    getMasterFreeformStream2(currentPersona, unit2, dataSource2)
  }, [getMasterFreeformStream2, currentPersona, unit2, dataSource2])

  useEffect(() => {
    getMasterFreeformStream3(currentPersona, unit3, dataSource3)
  }, [getMasterFreeformStream3, currentPersona, unit3, dataSource3])

  useEffect(() => {
    getMasterFreeformStream4(currentPersona, unit4, dataSource4)
  }, [getMasterFreeformStream4, currentPersona, unit4, dataSource4])

  useEffect(() => {
    getMasterFreeformStream5(currentPersona, unit5, dataSource5)
  }, [getMasterFreeformStream5, currentPersona, unit5, dataSource5])

  // Freeform Property

  useEffect(() => {
    getMasterFreeformProperty1(currentPersona, unit1, stream1, dataSource1)
  }, [getMasterFreeformProperty1, currentPersona, unit1, stream1, dataSource1])

  useEffect(() => {
    getMasterFreeformProperty2(currentPersona, unit2, stream2, dataSource2)
  }, [getMasterFreeformProperty2, currentPersona, unit2, stream2, dataSource2])

  useEffect(() => {
    getMasterFreeformProperty3(currentPersona, unit3, stream3, dataSource3)
  }, [getMasterFreeformProperty3, currentPersona, unit3, stream3, dataSource3])

  useEffect(() => {
    getMasterFreeformProperty4(currentPersona, unit4, stream4, dataSource4)
  }, [getMasterFreeformProperty4, currentPersona, unit4, stream4, dataSource4])

  useEffect(() => {
    getMasterFreeformProperty5(currentPersona, unit5, stream5, dataSource5)
  }, [getMasterFreeformProperty5, currentPersona, unit5, stream5, dataSource5])

  const searchCompareBlendResult = _.get(searchResult, 'searchCompareBlend')

  // if (
  //   !_.get(searchCompareBlendResult, "data") ||
  //   _.isEmpty(_.get(searchCompareBlendResult, "data"))
  // ) {
  //   return <NoDataBlock />;
  // }

  const data = _.get(searchResult.searchCompareBlend, 'data')

  return (
    <div className="section section-compare-blend">
      <CrudePropertyComparison data={data} />
      {/* <FreeformTrend
        freeformChartData={freeformChartData}
        defaultPeriod={defaultPeriod}
        period={period}
        setPeriod={setPeriod}
        color1={color1}
        color2={color2}
        color3={color3}
        color4={color4}
        color5={color5}
        freeformDataSource={freeformDataSource}
        dataSource1={dataSource1}
        dataSource2={dataSource2}
        dataSource3={dataSource3}
        dataSource4={dataSource4}
        dataSource5={dataSource5}
        setDataSource1={setDataSource1}
        setDataSource2={setDataSource2}
        setDataSource3={setDataSource3}
        setDataSource4={setDataSource4}
        setDataSource5={setDataSource5}
        freeformUnit={freeformUnit}
        unit1={unit1}
        unit2={unit2}
        unit3={unit3}
        unit4={unit4}
        unit5={unit5}
        setUnit1={setUnit1}
        setUnit2={setUnit2}
        setUnit3={setUnit3}
        setUnit4={setUnit4}
        setUnit5={setUnit5}
        freeformStream1={freeformStream1}
        freeformStream2={freeformStream2}
        freeformStream3={freeformStream3}
        freeformStream4={freeformStream4}
        freeformStream5={freeformStream5}
        stream1={stream1}
        stream2={stream2}
        stream3={stream3}
        stream4={stream4}
        stream5={stream5}
        setStream1={setStream1}
        setStream2={setStream2}
        setStream3={setStream3}
        setStream4={setStream4}
        setStream5={setStream5}
        freeformProperty1={freeformProperty1}
        freeformProperty2={freeformProperty2}
        freeformProperty3={freeformProperty3}
        freeformProperty4={freeformProperty4}
        freeformProperty5={freeformProperty5}
        property1={property1}
        property2={property2}
        property3={property3}
        property4={property4}
        property5={property5}
        setProperty1={setProperty1}
        setProperty2={setProperty2}
        setProperty3={setProperty3}
        setProperty4={setProperty4}
        setProperty5={setProperty5}
      /> */}
    </div>
  )
}

export default CompareBlend
