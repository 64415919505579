import React from "react";
import _ from "lodash";

//Components
import { Icon } from "semantic-ui-react";
import SearchPanel from "./components/searchPanel";
import FindSimiliarCrude from "./components/similiarCrude";
import CompareCrude from "./components/compareCrude";
import CompareBlend from "./components/compareBlend";
// import CalculationResult from './components/calculationResult';
// import ApiStandard from './components/apiStandard';
// import SummaryTable from './components/summaryTable';
import ResultContainer from "../../../components/result-container";
import * as statisticalAnalysisConstants from "./helpers/constants";

//Redux
import { STATISTICAL_ANALYSIS_PAGE_REDUCER } from "../../../reducers/statisticalAnalaysisPageReducer";

//Hooks
import { useStateWithPaths } from "../../../hooks/useConnect";

//#region Private Methods
const getSearchIcon = () => {
  return (
    <div className="search-icon-wrapper flex center justify-center">
      <Icon name="search margin-none"></Icon>
    </div>
  );
};
////#endregion

const StatisticalAnalysisPage = (props) => {
  const [{ searchPanel, searchResult }] = useStateWithPaths([
    `${STATISTICAL_ANALYSIS_PAGE_REDUCER}`,
  ]);
  const hasSearch = !_.isEmpty(searchResult);
  return (
    <div className="main-container page-statistical-analysis">
      <SearchPanel />
      {!hasSearch && (
        <div className="session section-result-message">
          <ResultContainer
            label="Please input blend and parameters above to enable the calculation"
            icon={getSearchIcon()}
          />
        </div>
      )}
      {hasSearch && (
        <>
          {searchPanel.options ===
          statisticalAnalysisConstants.SEARCH_OPTIONS.FindSimiliarCrude ? (
            <FindSimiliarCrude />
          ) : searchPanel.options ===
            statisticalAnalysisConstants.SEARCH_OPTIONS.CompareCrude ? (
            <CompareCrude />
          ) : searchPanel.options ===
            statisticalAnalysisConstants.SEARCH_OPTIONS.CompareBlend ? (
            <CompareBlend />
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default StatisticalAnalysisPage;
