import React from "react";
import Highcharts from "highcharts/highmaps";
import HighchartsReact from "highcharts-react-official";
import tilemap from "highcharts/modules/tilemap";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";

import ReactDOMServer from "react-dom/server";
import Tooltip from "../../tooltip";

// Helpers
import { stringToDecimalPoint } from "../../../helpers/variables";

tilemap(Highcharts);

const daysInMonth = [];

var monthDate = moment().startOf("month"); // change to a date in the month of interest

_.times(monthDate.daysInMonth(), function (n) {
  daysInMonth.push(monthDate.format("DD MMM")); // your format
  monthDate.add(1, "day");
});

export const CompatibilityAssessmentChart = (props) => {
  const CHART_OPTIONS = setChartOptions(props.assessmentChart);
  return (
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={{ style: { width: "40%", height: "100%" } }}
      constructorType={"chart"}
      options={CHART_OPTIONS}
      immutable={true}
    />
  );
};

CompatibilityAssessmentChart.propTypes = {
  compatibilityAssessmentChartData: PropTypes.object,
};

function setChartOptions(data) {
  // const parameterTrendCategoriesData = _.get(data, 'category')
  // const parameterTrendChartSeriesData = _.get(data, 'series')

  // const formatTrendCategories = _.map(parameterTrendCategoriesData, (item) =>
  //   moment(item).format('DD MMM')
  // )
  return {
    chart: {
      type: "tilemap",
      inverted: true,
      height: "85%",
      style: {
        fontFamily: 'IBM Plex Sans'
      }
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },

    xAxis: {
      visible: false,
    },

    yAxis: {
      title: {
        text: "",
      },
      visible: true,
      categories: [
        0,
        5,
        10,
        15,
        20,
        25,
        30,
        35,
        40,
        45,
        50,
        55,
        60,
        65,
        70,
        75,
        80,
        85,
        90,
        95,
        100,
      ],
      gridLineColor: "#fff",
      lineColor: "#FFF",
      labels: {
        style: {
          fontSize: "14px",
          fontFamily: 'IBM Plex Sans',
          color:"#000"
        },
      },
    },

    colorAxis: {
      dataClasses: [
        {
          from: -11,
          to: 0,
          color: "#E0E0E0",
          name: "Not Available",
        },
        {
          from: 0,
          to: 1,
          color: "#E95258",
          name: "Incompatible",
        },
        {
          from: 1,
          to: 1000000,
          color: "#00B45A",
          name: "Compatible",
        },
      ],
    },

    tooltip: getTooltip(data, tooltipFormatter),

    // tooltip: {
    //   enabled: true,
    // },

    legend: {
      enabled: false,
    },

    plotOptions: {
      tilemap: {
        states: {
          select: {
            enabled: false,
          },
          hover: {
            halo: {
              size: 0,
            },
            enabled: true,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
      series: {
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "",
        data: data,
      },
    ],
  };
}

const getTooltip = (payload, formatter) => {
  return {
    shared: false,
    backgroundColor: null,
    borderWidth: 0,
    shadow: false,
    className: "test-classname-tooltip",
    style: {
      padding: 0,
    },
    useHTML: true,
    formatter: function () {
      return formatter(this, payload);
    },
    outside: true,
  };
};

function getTooltipContent(category, data) {
  let content = _.map(data, (a) => ({
    name: a.name,
    result: a.result,
    setA: a.setA,
    setB: a.setB,
    setC: a.setC,
  }));
  return content;
}

// tooltip in normal view
const tooltipFormatter = (chart, data) => {
  const x = chart;
  const i = x.point.index;
  const points = getTooltipContent(x, data);

  const options = {
    title: _.isEqual(_.get(x.point, "result"), "I")
      ? "Incompatible"
      : _.isEqual(_.get(x.point, "result"), "C")
      ? "Compatible"
      : "Not Available",
    headerColor: _.get(x, "color"),
    items: [
      [
        {
          type: "card",
          label: "Blend",
          value: points[i].name,
        },
        {
          type: "card",
          label: "Set A",
          value: points[i].setA,
        },
        {
          type: "card",
          label: "Set B",
          value: points[i].setB,
        },
        {
          type: "card",
          label: "Set C",
          value: points[i].setC,
        },
      ],
    ],
  };

  return ReactDOMServer.renderToString(
    <Tooltip
      medium
      // headerColor={data.series[i].color}
      type="TooltipTwoColumns"
      {...options}
      className={"parameter-trend-tooltip"}
    />
  );
};
