import React, { useCallback, useMemo, useState, useEffect } from 'react'
import _ from 'lodash'
import Moment from 'moment'
import { useDispatch } from 'react-redux'
import { extendMoment } from 'moment-range'
import { useJsonToCsv } from 'react-json-csv'

//Asset
import IconCloseWhite from '../../../../assets/images/icon-close-white.svg'

//Components
import { Grid, Header, Dropdown, Segment, Button, Icon, Image } from 'semantic-ui-react'
import RadioButton from '../../../../components/radio-button'
import { FreeformChart } from '../../../../components/charts/statistical-analysis-chart/freefrom-chart'
import PeriodPicker from '../../../../components/period-picker'
import NoDataBlock from '../../../../components/no-data-block'

import { RISKS_PAGE_REDUCER } from '../../../../reducers/risksPageReducer'

//Hooks
import { useStateWithPaths } from '../../../../hooks/useConnect'

const moment = extendMoment(Moment)

const renderBlendDateComposition = (isSearchByCrudeBlendDate, setCallback, location) => {
  const labelFormat = _.isEqual(location.options, 'custom-blend') ? 'Related Blend' : 'Related Crude'
  const menu = [
    {
      label: labelFormat,
      value: true,
    },
    {
      label: 'Default',
      value: false,
    },
  ]
  return (
    <RadioButton
      menuData={menu}
      selectCallback={setCallback}
      selectedValue={isSearchByCrudeBlendDate}
    />
  )
}

const FreeformTrend = (props) => {
    const { saveAsCsv } = useJsonToCsv()
    const tableData = _.get(props.freeformChartData, 'series')

    const onChangeBlendDateComposition = (isSearchByCrudeBlendDateValue) => {
      if (isSearchByCrudeBlendDateValue) {
        // setUnit('')
        // clearInputBlendComposition()
      } else {
        // setBlendDate(null)
      }
      props.setIsSearchByCrudeBlendDate(isSearchByCrudeBlendDateValue)
    }
  
    const tableDataForExport = useMemo(() => {
      return _.map(tableData, (item, index) => {
        return _.mapValues(item, (value, key) => {
          return value
        })
      })
    }, [tableData])
  
    const transformTableData = [
      {
        dataSource: props.dataSource1,
        unit: props.unit1,
        fraction: props.stream1,
      },
      {
        dataSource: props.dataSource2,
        unit: props.unit2,
        fraction: props.stream2,
      },
      {
        dataSource: props.dataSource3,
        unit: props.unit3,
        fraction: props.stream3,
      },
      {
        dataSource: props.dataSource4,
        unit: props.unit4,
        fraction: props.stream4,
      },
      {
        dataSource: props.dataSource5,
        unit: props.unit5,
        fraction: props.stream5,
      },
    ]
  
    const formatSearchObject = _.map(transformTableData, (item) =>
      !_.isEmpty(item.dataSource)
        ? { dataSource: item.dataSource, unit: item.unit, fraction: item.fraction }
        : {}
    )
    const filterSearchObject = _.filter(formatSearchObject, (v) => _.keys(v).length !== 0)
  
    const chartExport = _.merge(tableData, filterSearchObject)
  
    const startDate = _.map(props.period, (item) => item.startDate)
    const endDate = _.map(props.period, (item) => item.endDate)
  
    const formatStart = moment(startDate.toString()).format('YYYY-MM-DD')
    const formatEnd = moment(endDate.toString()).format('YYYY-MM-DD')
    const range = moment.range(formatStart, formatEnd)
  
    for (let days of range.by('month')) {
      days.format('YYYY-MM-DD')
    }
  
    const days = Array.from(range.by('days'))
    const daysInMonth = days.map((m) => m.format('DD MMM'))
  
    const fileNameStartDate = moment(startDate.toString()).format('DD-MMM-YYYY')
    const fileNameEndDate = moment(endDate.toString()).format('DD-MMM-YYYY')
  
    const transfromFileName = `Freeform trend ${fileNameStartDate} - ${fileNameEndDate}`
  
    const clickExport = useCallback(
      (e) => {
        const filename = transfromFileName,
          fields = {
            dataSource: 'Data Source',
            unit: 'Unit',
            fraction: 'Fraction',
            name: 'Property',
            data: daysInMonth,
          },
          style = {
            padding: '5px',
          },
          data = chartExport
        saveAsCsv({ data, fields, filename, style })
      },
      [chartExport, saveAsCsv]
    )
  
    const onClickExport = () => {
      clickExport()
    }
  
    const onClickResetDataSourceParameter = (index) => {
      if (index === 1) {
        props.setDataSource1('')
        props.setUnit1('')
        props.setStream1('')
        props.setProperty1('')
      } else if (index === 2) {
        props.setDataSource2('')
        props.setUnit2('')
        props.setStream2('')
        props.setProperty2('')
      } else if (index === 3) {
        props.setDataSource3('')
        props.setUnit3('')
        props.setStream3('')
        props.setProperty3('')
      } else if (index === 4) {
        props.setDataSource4('')
        props.setUnit4('')
        props.setStream4('')
        props.setProperty4('')
      } else if (index === 5) {
        props.setDataSource5('')
        props.setUnit5('')
        props.setStream5('')
        props.setProperty5('')
      }
    }

    
  
    return (
      <Grid padded>
        <Grid.Row columns={1} verticalAlign="bottom">
          <Grid.Column textAlign="left">
            <div className={`flex center space-between`}>
              <div className={`flex`}>
                <Header className={`mar-0`} size="small" textAlign="left">
                  FREEFORM TREND
                </Header>
              </div>
              <div className={`flex`}>
              {renderBlendDateComposition(
                    props.isSearchByCrudeBlendDate,
                    onChangeBlendDateComposition,
                    props.location
                  )}
                <Dropdown
                  placeholder="Select unit"
                  className={`freeform-unit-container`}
                  value={props.unit}
                  defaultValue={props.unit}
                  selection
                  options={props.units}
                  onChange={(e, data) => {
                    props.setUnit(data.value)
                  }}
                />
                <PeriodPicker
                  defaultPeriod={props.defaultPeriod}
                  period={props.period}
                  setPeriod={props.setPeriod}
                />
              </div>
              
            </div>
          </Grid.Column>
        </Grid.Row>
        <Segment className={`width-100`}>
          <div className={`pad-2`}>
            <Grid.Row>
              <div className={`pad-y-2`}>
                {_.isEmpty(props.freeformChartData) ? (
                  <NoDataBlock />
                ) : (
                  <FreeformChart freeformChartData={props.freeformChartData} />
                )}
              </div>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Header
                  className={`mar-bottom-0 mar-left-0 text-primary`}
                  size="small"
                  textAlign="left"
                >
                  Data Source & Parameter
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className={`pad-top-1 flex center space-between`}>
                  <div
                    className={`custom-legend-icon`}
                    style={{ backgroundColor: `${props.color1}` }}
                  ></div>
                  <Dropdown
                    className={`freeform-dropdown`}
                    placeholder="Select Data Source"
                    value={props.dataSource1}
                    selection
                    options={props.freeformDataSource}
                    onChange={(e, data) => {
                      props.setDataSource1(data.value)
                      props.setStream1('')
                      props.setProperty1('')
                    }}
                  />
                  <Dropdown
                    className={`freeform-dropdown`}
                    placeholder="Select Unit"
                    value={props.unit1}
                    selection
                    options={props.freeformUnit}
                    onChange={(e, data) => {
                      props.setUnit1(data.value)
                      props.setStream1('')
                      props.setProperty1('')
                    }}
                  />
                  <Dropdown
                    className={`freeform-dropdown`}
                    placeholder="Select Stream"
                    value={props.stream1}
                    selection
                    options={props.freeformStream1}
                    onChange={(e, data) => {
                      props.setStream1(data.value)
                      props.setProperty1('')
                    }}
                  />
                  <Dropdown
                    className={`freeform-dropdown`}
                    placeholder="Select Property"
                    value={props.property1}
                    selection
                    options={props.freeformProperty1}
                    onChange={(e, data) => {
                      props.setProperty1(data.value)
                    }}
                  />
                  <Button
                    onClick={() => onClickResetDataSourceParameter(1)}
                    className={`button-reset-data-source`}
                  >
                    <Image src={IconCloseWhite} />
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className={`pad-top-1 flex center space-between`}>
                  <div
                    className={`custom-legend-icon`}
                    style={{ backgroundColor: `${props.color2}` }}
                  ></div>
                  <Dropdown
                    className={`freeform-dropdown`}
                    placeholder="Select Data Source"
                    value={props.dataSource2}
                    selection
                    options={props.freeformDataSource}
                    onChange={(e, data) => {
                      props.setDataSource2(data.value)
                      props.setStream2('')
                      props.setProperty2('')
                    }}
                  />
                  <Dropdown
                    className={`freeform-dropdown`}
                    placeholder="Select Unit"
                    value={props.unit2}
                    selection
                    options={props.freeformUnit}
                    onChange={(e, data) => {
                      props.setUnit2(data.value)
                      props.setStream2('')
                      props.setProperty2('')
                    }}
                  />
                  <Dropdown
                    className={`freeform-dropdown`}
                    placeholder="Select Stream"
                    value={props.stream2}
                    selection
                    options={props.freeformStream2}
                    onChange={(e, data) => {
                      props.setStream2(data.value)
                      props.setProperty2('')
                    }}
                  />
                  <Dropdown
                    className={`freeform-dropdown`}
                    placeholder="Select Property"
                    value={props.property2}
                    selection
                    options={props.freeformProperty2}
                    onChange={(e, data) => {
                      props.setProperty2(data.value)
                    }}
                  />
                  <Button
                    onClick={() => onClickResetDataSourceParameter(2)}
                    className={`button-reset-data-source`}
                  >
                    <Image src={IconCloseWhite} />
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className={`pad-top-1 flex center space-between`}>
                  <div
                    className={`custom-legend-icon`}
                    style={{ backgroundColor: `${props.color3}` }}
                  ></div>
                  <Dropdown
                    className={`freeform-dropdown`}
                    placeholder="Select Data Source"
                    value={props.dataSource3}
                    selection
                    options={props.freeformDataSource}
                    onChange={(e, data) => {
                      props.setDataSource3(data.value)
                      props.setStream3('')
                      props.setProperty3('')
                    }}
                  />
                  <Dropdown
                    className={`freeform-dropdown`}
                    placeholder="Select Unit"
                    value={props.unit3}
                    selection
                    options={props.freeformUnit}
                    onChange={(e, data) => {
                      props.setUnit3(data.value)
                      props.setStream3('')
                      props.setProperty3('')
                    }}
                  />
                  <Dropdown
                    className={`freeform-dropdown`}
                    placeholder="Select Stream"
                    value={props.stream3}
                    selection
                    options={props.freeformStream3}
                    onChange={(e, data) => {
                      props.setStream3(data.value)
                      props.setProperty3('')
                    }}
                  />
                  <Dropdown
                    className={`freeform-dropdown`}
                    placeholder="Select Property"
                    value={props.property3}
                    selection
                    options={props.freeformProperty3}
                    onChange={(e, data) => {
                      props.setProperty3(data.value)
                    }}
                  />
                  <Button
                    onClick={() => onClickResetDataSourceParameter(3)}
                    className={`button-reset-data-source`}
                  >
                    <Image src={IconCloseWhite} />
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className={`pad-top-1 flex center space-between`}>
                  <div
                    className={`custom-legend-icon`}
                    style={{ backgroundColor: `${props.color4}` }}
                  ></div>
                  <Dropdown
                    className={`freeform-dropdown`}
                    placeholder="Select Data Source"
                    value={props.dataSource4}
                    selection
                    options={props.freeformDataSource}
                    onChange={(e, data) => {
                      props.setDataSource4(data.value)
                      props.setStream4('')
                      props.setProperty4('')
                    }}
                  />
                  <Dropdown
                    className={`freeform-dropdown`}
                    placeholder="Select Unit"
                    value={props.unit4}
                    selection
                    options={props.freeformUnit}
                    onChange={(e, data) => {
                      props.setUnit4(data.value)
                      props.setStream4('')
                      props.setProperty4('')
                    }}
                  />
                  <Dropdown
                    className={`freeform-dropdown`}
                    placeholder="Select Stream"
                    value={props.stream4}
                    selection
                    options={props.freeformStream4}
                    onChange={(e, data) => {
                      props.setStream4(data.value)
                      props.setProperty4('')
                    }}
                  />
                  <Dropdown
                    className={`freeform-dropdown`}
                    placeholder="Select Property"
                    value={props.property4}
                    selection
                    options={props.freeformProperty4}
                    onChange={(e, data) => {
                      props.setProperty4(data.value)
                    }}
                  />
                  <Button
                    onClick={() => onClickResetDataSourceParameter(4)}
                    className={`button-reset-data-source`}
                  >
                    <Image src={IconCloseWhite} />
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className={`pad-top-1 flex center space-between`}>
                  <div
                    className={`custom-legend-icon`}
                    style={{ backgroundColor: `${props.color5}` }}
                  ></div>
                  <Dropdown
                    className={`freeform-dropdown`}
                    placeholder="Select Data Source"
                    value={props.dataSource5}
                    selection
                    options={props.freeformDataSource}
                    onChange={(e, data) => {
                      props.setDataSource5(data.value)
                      props.setStream5('')
                      props.setProperty5('')
                    }}
                  />
                  <Dropdown
                    className={`freeform-dropdown`}
                    placeholder="Select Unit"
                    value={props.unit5}
                    selection
                    options={props.freeformUnit}
                    onChange={(e, data) => {
                      props.setUnit5(data.value)
                      props.setStream5('')
                      props.setProperty5('')
                    }}
                  />
                  <Dropdown
                    className={`freeform-dropdown`}
                    placeholder="Select Stream"
                    value={props.stream5}
                    selection
                    options={props.freeformStream5}
                    onChange={(e, data) => {
                      props.setStream5(data.value)
                      props.setProperty5('')
                    }}
                  />
                  <Dropdown
                    className={`freeform-dropdown`}
                    placeholder="Select Property"
                    value={props.property5}
                    selection
                    options={props.freeformProperty5}
                    onChange={(e, data) => {
                      props.setProperty5(data.value)
                    }}
                  />
                  <Button
                    onClick={() => onClickResetDataSourceParameter(5)}
                    className={`button-reset-data-source`}
                  >
                    <Image src={IconCloseWhite} />
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <div className={`flex mar-top-2 end`}>
                <Button size="large" className="btn-secondary icon" onClick={onClickExport}>
                  Export <Icon name="external alternate"></Icon>
                </Button>
              </div>
            </Grid.Row>
          </div>
        </Segment>
      </Grid>
    )
  }

export default FreeformTrend
