import _ from 'lodash'

import * as corrosionPageActions from '../actions/actionCorrosionRate'
import * as corrosionPageConstants from '../containers/views/corrosion-rate-page/helpers/constants'

const defaultState = {
  searchPanel: {
    isSearchByCrudeBlendDate: true,
    highTempNhHs: corrosionPageConstants.SEARCH_HIGHTEMP_NHNS.HIGH_TEMP,
    crudeMode: corrosionPageConstants.SEARCH_CRUDE_MODE.SINGLE_CRUDE,
    calculateResultMode:
      corrosionPageConstants.SEARCH_CALCULATE_RESULT_MODE.CALCULATE_CORROSION_RATE,
    crudeNameAbbreviation: null,
    operatingTemperature: '',
    blendDate: null,
    inputUnits: {
      unit1: null,
      unitPercent1: '',
      unit2: null,
      unitPercent2: '',
      unit3: null,
      unitPercent3: '',
      unit4: null,
      unitPercent4: '',
      unit5: null,
      unitPercent5: '',
      unit6: null,
      unitPercent6: '',
      unit7: null,
      unitPercent7: '',
      unit8: null,
      unitPercent8: '',
      unit9: null,
      unitPercent9: '',
      unit10: null,
      unitPercent10: '',
    },
    unit: '',
    operatingUnit: '',
    fraction: '',
    material: '',
    unitFeedRate: '',
    washWater: '',
  },
  searchResult: {
    // calculationResult: {},
    // apiStandard: {
    //   data: [],
    // },
    // summaryTable: {
    //   data: [],
    // },
    // processingHistory: [],
  },
}

const initialState = defaultState

const REDUCERS = {
  [corrosionPageActions.CORROSION_RATE_SEARCH_UPDATE]: (state, { payload }) => {
    return {
      ...state,
      searchPanel: {
        ...state.searchPanel,
        ...payload,
      },
      searchResult: {},
    }
  },
  [corrosionPageActions.CORROSION_CALCULATE_RATE]: (state, { payload }) => {
    return {
      ...state,
      searchResult: {
        ...state.searchResult,
        apiStandard: {
          ...state.searchResult.apiStandard,
          data: _.get(payload, 'table'),
        },
        calculationResult: {
          ...state.searchResult.calculationResult,
          ..._.get(payload, 'calculationResult'),
        },
        date: {
          ...state.searchResult.date,
          date: _.get(payload, 'date'),
        },
      },
    }
  },
  [corrosionPageActions.CORROSION_HISTORICAL_DATA]: (state, { payload }) => {
    return {
      ...state,
      searchResult: {
        ...state.searchResult,
        historicalData: {
          ...state.searchResult.historicalData,
          data: payload,
        },
      },
    }
  },
  [corrosionPageActions.CORROSION_CREATE_SUMMARY]: (state, { payload }) => {
    return {
      ...state,
      searchResult: {
        ...state.searchResult,
        summaryTable: {
          ...state.searchResult.summaryTable,
          data: payload,
        },
      },
    }
  },
  [corrosionPageActions.CORROSION_SIMULATE_SUMMARY_TABLE]: (state, { payload }) => {
    return {
      ...state,
      searchResult: {
        ...state.searchResult,
        summaryTable: {
          ...state.searchResult.summaryTable,
          data: payload,
        },
      },
    }
  },
}
export default (state = initialState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}
export const CORROSION_PAGE_REDUCER = 'corrosionPage'
