import React from 'react'
import PropTypes from 'prop-types'

import { Button, Icon } from 'semantic-ui-react'
import IMG_ERROR from '../../assets/images/icon-data-error.svg'

const NoDataBlock = ({ reFetchData, hideBtn, label = 'No Data Available' }) => {
  return (
    <div className="error-handling-container flex center justify-center">
      <div className={`no-data-block`}>
        <div className="loader-image center-content">
          {/* <Icon color="red" size="large" name="info circle" /> */}
        </div>
        <div className="loader-message center-content">{label}</div>
        {reFetchData && (
          <Button className={`${hideBtn ? 'hide' : 'btn-transparent-red'}`} onClick={reFetchData}>
            Try Again
          </Button>
        )}
      </div>
    </div>
  )
}

NoDataBlock.propTypes = {
  reFetchData: PropTypes.func,
  hideBtn: PropTypes.bool,
}
export default NoDataBlock
