import React, { useEffect, useState } from 'react'
import { Grid, Header, Button } from 'semantic-ui-react'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'

//Components
import Chevron from '../../../../components/chevron'

//Redux
import { PERSONAL_SETTING_REDUCER } from '../../../../reducers/common/personalSettingReducer'
import { RISKS_PAGE_REDUCER } from '../../../../reducers/risksPageReducer'
import * as RisksActions from '../../../../actions/actionRisks'

//Hooks
import { useStateWithPaths, useActions } from '../../../../hooks/useConnect'

//Constants

const SimilarCrude = (props) => {
  const history = useHistory()
  const crudeNameAbbreviation = props.searchObject.crudeNameAbbreviation
  const [{ currentPersona }] = useStateWithPaths([`${PERSONAL_SETTING_REDUCER}`])
  const [{ risksPage }] = useStateWithPaths([`${RISKS_PAGE_REDUCER}`])
  const similiarCrudeData = _.flattenDeep(
    _.map(risksPage.similarCrude, (item) => item.similiarCrude)
  )

  _.each(similiarCrudeData, (item) => {
    return (item.title = item.Crude_Code + ' - ' + item.Crude_Name)
  })

  const { searchSimiliarCrude } = useActions({
    searchSimiliarCrude: RisksActions.searchSimiliarCrude,
  })

  useEffect(() => {
    searchSimiliarCrude(crudeNameAbbreviation, currentPersona)
  }, [searchSimiliarCrude, crudeNameAbbreviation, currentPersona])

  let numberCrudeDisplay = 4
  const [isFirstCrude, setIsFirstCrude] = useState(true)
  const [isLastCrude, setIsLastCrude] = useState(false)
  const [indexFrom, setIndexFrom] = useState(0)
  const [indexTo, setIndexTo] = useState(4)
  const crudeList = []
  const onClickNext = () => {
    setIsFirstCrude(false)
    setIsLastCrude(indexTo + numberCrudeDisplay >= Object.keys(similiarCrudeData).length - 1)
    setIndexFrom(indexFrom + numberCrudeDisplay)
    setIndexTo(indexTo + numberCrudeDisplay)
  }

  const onClickPrevious = () => {
    setIsFirstCrude(indexFrom - numberCrudeDisplay <= 0)
    setIsLastCrude(false)
    setIndexFrom(indexFrom - numberCrudeDisplay)
    setIndexTo(indexTo - numberCrudeDisplay)
  }

  if (!_.isEmpty(similiarCrudeData)) {
    similiarCrudeData
      .filter((item, i) => i >= indexFrom && i < indexTo)
      .map((item) => crudeList.push(item))
  }

  const onClickSimilarCrude = (item) => {
    history.push({
      pathname: '/risks',
      options: props.searchObject.options,
      crudeNameAbbreviation: {
        crudeCode: item.Crude_Code,
        crudeName: item.Crude_Name,
      },
    })
  }

  return (
    <div className="section section-similar-crude">
      <Grid padded>
        <Grid.Row>
          <Grid.Column textAlign="left">
            <Header size="small" textAlign="left">
              SIMILAR CRUDE
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Button
            disabled={isFirstCrude}
            onClick={onClickPrevious}
            style={{ backgroundColor: 'transparent' }}
          >
            <Chevron direction="left" />
          </Button>
          {!_.isEmpty(crudeList) ? (
            _.map(crudeList, (item) => (
              <Grid.Column textAlign="center" width={3} style={{ margin: 'auto' }}>
                <Button className="crude-item" onClick={() => onClickSimilarCrude(item)}>
                  {item.title}
                </Button>
              </Grid.Column>
            ))
          ) : (
            <div className={`loader-message center`} style={{ width: '90%' }}>
              No Data Available
            </div>
          )}
          <Button
            disabled={crudeList.length <= 3 ? true : isLastCrude}
            onClick={onClickNext}
            style={{ backgroundColor: 'transparent' }}
          >
            <Chevron direction="right" />
          </Button>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default SimilarCrude
