import _ from 'lodash'
import {
  POST_CHECK_COMPATIBILITY_LAB,
  POST_CHECK_COMPATIBILITY_SHELL,
  POST_CHECK_COMPATIBILITY_PROCESSING,
  POST_CHECK_COMPATIBILITY_ASSESSMENT,
  POST_CHECK_COMPATIBILITY_CALCULATION,
} from '../actions/actionCompatibility'

import * as compatibilityPageConstants from '../containers/views/compatibility-page/helpers/constants'

const defaultState = {
  searchPanel: {
    option: compatibilityPageConstants.SEARCH_COMPATIBILITY.CRUDE_BLEND,
    inputUnits: {
      unit1: null,
      unit2: null,
      unit3: null,
      unit4: null,
      unit5: null,
      unit6: null,
      unit7: null,
      unit8: null,
      unit9: null,
      unit10: null,
    },
    calculation: {
      unit1: null,
      unitPercent1: '',
      unit2: null,
      unitPercent2: '',
      unit3: null,
      unitPercent3: '',
      unit4: null,
      unitPercent4: '',
      unit5: null,
      unitPercent5: '',
      unit6: null,
      unitPercent6: '',
      unit7: null,
      unitPercent7: '',
      unit8: null,
      unitPercent8: '',
      unit9: null,
      unitPercent9: '',
      unit10: null,
      unitPercent10: '',
    },
    assessment: {
      unit1: null,
      unitPercent1: '',
      unit2: null,
      unitPercent2: '',
      unit3: null,
      unitPercent3: '',
      unit4: null,
      unitPercent4: '',
      unit5: null,
      unitPercent5: '',
      unit6: null,
      unitPercent6: '',
      unit7: null,
      unitPercent7: '',
      unit8: null,
      unitPercent8: '',
      unit9: null,
      unitPercent9: '',
      unit10: null,
      unitPercent10: '',
      unit11: null,
      unitPercent11: '',
      unit12: null,
      unitPercent12: '',
      unit13: null,
      unitPercent13: '',
      unit14: null,
      unitPercent14: '',
      unit15: null,
      unitPercent15: '',
      unit16: null,
      unitPercent16: '',
      unit17: null,
      unitPercent17: '',
      unit18: null,
      unitPercent18: '',
      unit19: null,
      unitPercent19: '',
      unit20: null,
      unitPercent20: '',
      unit21: null,
      unitPercent21: '',
      unit22: null,
      unitPercent22: '',
      unit23: null,
      unitPercent23: '',
      unit24: null,
      unitPercent24: '',
      unit25: null,
      unitPercent25: '',
      unit26: null,
      unitPercent26: '',
      unit27: null,
      unitPercent27: '',
      unit28: null,
      unitPercent28: '',
      unit29: null,
      unitPercent29: '',
      unit30: null,
      unitPercent30: '',
    },
  },
  labResult: { data: [] },
  shellAssessment: { data: [] },
  processingHistory: { data: [] },
  compatibilityCalculation: {
    data: [],
  },
  thaiOilCompatibilityAssessment: {
    data: [],
  },
}

const REDUCERS = {
  [POST_CHECK_COMPATIBILITY_LAB]: (state, { payload }) => {
    return {
      ...state,
      compatibilityPage: { ...state.compatibilityPage },
      labResult: {
        data: payload,
      },
    }
  },

  [POST_CHECK_COMPATIBILITY_SHELL]: (state, { payload }) => {
    return {
      ...state,
      compatibilityPage: { ...state.compatibilityPage },
      shellAssessment: {
        data: payload,
      },
    }
  },

  [POST_CHECK_COMPATIBILITY_PROCESSING]: (state, { payload }) => {
    return {
      ...state,
      compatibilityPage: { ...state.compatibilityPage },
      processingHistory: {
        data: payload,
      },
    }
  },

  [POST_CHECK_COMPATIBILITY_CALCULATION]: (state, { payload }) => {
    return {
      ...state,
      compatibilityPage: { ...state.compatibilityPage },
      compatibilityCalculation: {
        data: payload,
      },
    }
  },

  [POST_CHECK_COMPATIBILITY_ASSESSMENT]: (state, { payload }) => {
    return {
      ...state,
      compatibilityPage: { ...state.compatibilityPage },
      thaiOilCompatibilityAssessment: {
        data: payload,
      },
    }
  },
}
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}
export const COMPATIBILITY_PAGE_REDUCER = 'compatibilityPage'
