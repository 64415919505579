import _ from 'lodash'
import moment from 'moment'
import { LAMBDA_API_INSTANCE } from './serverInstances/lambdaServer'

import * as UserGroup from '../constants/userGroups'
import { database } from 'faker'

import * as statisticalAnalysisConstants from '../../src/containers/views/statistical-analysis-page/helpers/constants'

const CONTROLLER_NAME = '/statistical'

const currentPersonaFormatted = (currentPersona) =>
  _.isEqual(currentPersona, UserGroup.SC)
    ? 'SC'
    : _.isEqual(currentPersona, UserGroup.ENTS)
    ? 'ENTS'
    : _.isEqual(currentPersona, UserGroup.TN)
    ? 'TN'
    : ''

export const postFindSimiliarCrude = ({ crudeCode, persona }) => {
  const getCrudeCode = _.get(crudeCode, 'crudeCode')
  const postBody = {
    crudeCode: getCrudeCode,
    fraction: 'IBP-FBP',
    source: 'Spiral Data',
    component: 'stati-similar-crude',
    persona: currentPersonaFormatted(persona),
  }
  return LAMBDA_API_INSTANCE.post(`${CONTROLLER_NAME}/similarcrude`, postBody)
}

export const postCompareCrude = ({
  dataSource,
  persona,
  compareUnit,
  crudeNameA,
  crudeNameB,
  crudeNameC,
  propertyList,
  crudeCut,
}) => {
  const formatSearchObject = _.map(crudeCut, (item) =>
    !_.isEmpty(item.fraction)
      ? {
          unit: item.unit,
          fraction: item.fraction,
          property: item.property,
        }
      : {}
  )
  const filterCrudeCutObject = _.filter(formatSearchObject, (v) => _.keys(v).length !== 0)
  const crudeName = [crudeNameA, crudeNameB, crudeNameC]
  const filterSearchObject = _.filter(crudeName, (v) => _.keys(v).length !== 0)
  const getFractions = (dataSource) => {
    return dataSource === 'Spiral Data' ? 'IBP-FBP' : 'Whole Crude'
  }
  const postBody = {
    persona: currentPersonaFormatted(persona),
    component: 'statistical-compare',
    unit: compareUnit,
    source: dataSource,
    crudeType: 'Single',
    crudeList: filterSearchObject,
    fraction: getFractions(dataSource),
    propertyList: propertyList,
    crudeCuts: filterCrudeCutObject,
  }
  return LAMBDA_API_INSTANCE.post(`${CONTROLLER_NAME}/compareCrudeProperties`, postBody)
}

export const postCompareBlend = ({
  blendDataSource,
  isSearchByCrudeBlendDate,
  persona,
  blendDateA,
  blendDateB,
  operatingUnit,
  fraction,
  unitBlendA,
  unitBlendB,
  blendList,
  propertyList,
}) => {
  const postBody = {
    persona: currentPersonaFormatted(persona),
    component: 'statistical-compare',
    source: blendDataSource,
    crudeType: 'Blend',
    operationUnit: operatingUnit,
    fraction: fraction,
    blendList: blendList,
    propertyList: propertyList,
  }
  return LAMBDA_API_INSTANCE.post(`${CONTROLLER_NAME}/compareCrudeProperties`, postBody)
}

export const postFreeformTrend = (
  currentPersona,
  period,
  color1,
  color2,
  color3,
  color4,
  color5,
  dataSource1,
  dataSource2,
  dataSource3,
  dataSource4,
  dataSource5,
  unit1,
  unit2,
  unit3,
  unit4,
  unit5,
  stream1,
  stream2,
  stream3,
  stream4,
  stream5,
  property1,
  property2,
  property3,
  property4,
  property5
) => {
  const startDate = _.map(period, (item) => item.startDate)
  const endDate = _.map(period, (item) => item.endDate)

  const freeformList = [
    {
      color: color1,
      dataSource: dataSource1,
      unit: unit1,
      fraction: stream1,
      property: property1,
    },
    {
      color: color2,
      dataSource: dataSource2,
      unit: unit2,
      fraction: stream2,
      property: property2,
    },
    {
      color: color3,
      dataSource: dataSource3,
      unit: unit3,
      fraction: stream3,
      property: property3,
    },
    {
      color: color4,
      dataSource: dataSource4,
      unit: unit4,
      fraction: stream4,
      property: property4,
    },
    {
      color: color5,
      dataSource: dataSource5,
      unit: unit5,
      fraction: stream5,
      property: property5,
    },
  ]

  const formatSearchObject = _.map(freeformList, (item) =>
    !_.isEmpty(item.property)
      ? {
          color: item.color,
          dataSource: item.dataSource,
          unit: item.unit,
          fraction: item.fraction,
          property: item.property,
        }
      : {}
  )
  const filterSearchObject = _.filter(formatSearchObject, (v) => _.keys(v).length !== 0)

  const postBody = {
    component: 'statistical-compare',
    crudeType: 'Blend',
    startDate: moment(startDate.toString()).format('YYYY-MM-DD'),
    endDate: moment(endDate.toString()).format('YYYY-MM-DD'),
    persona: currentPersonaFormatted(currentPersona),
    freeformList: filterSearchObject,
  }
  return LAMBDA_API_INSTANCE.post(`${CONTROLLER_NAME}/freeformChart`, postBody)
}

export const getSettingPageList = (currentPersona) => {
  const postBody = {
    persona: currentPersonaFormatted(currentPersona),
    component: 'stat-sim-get-settings',
  }
  return LAMBDA_API_INSTANCE.post(`${CONTROLLER_NAME}/similarCrudeSettings`, postBody)
}

export const saveSettingPageList = ({ options, currentPersona, inputList }) => {
  const postBody = {
    mode: currentOptionFormatted(options),
    persona: currentPersonaFormatted(currentPersona),
    component: 'stat-sim-get-settings',
    settings: inputList,
  }
  return LAMBDA_API_INSTANCE.post(`${CONTROLLER_NAME}/similarCrudeSaveSettings`, postBody)
}

const currentOptionFormatted = (currentOption) =>
  _.isEqual(currentOption, statisticalAnalysisConstants.OPTIONS_SETTING.AdjustPropertyRange)
    ? 'adjust-prop'
    : 'modify-criteria'
