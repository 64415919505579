import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

import ReactDOMServer from 'react-dom/server'
import Tooltip from '../../../../components/tooltip'

//Components
import ReactHighchart from '../../../../components/react-highcharts'

//Helpers
import { padNumber } from '../../../../helpers/number';
import { stringToDecimalPoint } from '../../../../helpers/variables';

//#region Private
const colors = [
  '#004A97',
  '#de1782',
];

const formatSeries = (series)=> {
  _.map(series, (item, index) => {
    return item.dataLabels = {
      color: colors[index]
    };
  })

  return series;
}

const setChartOptions = (data) => {
  const categories = _.get(data, 'categories')
  const series = _.get(data, 'series')
  
  return {
    chart: {
      type: 'column',
      height: 400,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: categories,
      lineColor: '#000',
      labels: {
        style: {
          fontWeight: '600',
          color: '#000',
          fontSize: '16px'
        }
      }
    },
    yAxis: {
      visible: true,
      title: {
        text: '% Processing',
        margin: 15,
        style: {
          fontSize: '15px'
        },
      },
      labels: {
        style: {
          color: '#000',
          fontSize: '16px',
        },
        formatter: formatValueWithZeroPad
      },
    },
    legend: {
      enabled: true,
      align: 'left',
    },
    // tooltip: {
    //   shared: true,
    // },
    tooltip: getTooltip(data, tooltipFormatter),
    colors: colors,
    plotOptions: {
      column: {
        allowPointSelect: false,
        dataLabels: {
          enabled: true,
          crop: false,
          overflow: 'allow',
          borderWidth: 0,
          style: {
            textOutline: 0
          },
          format: "{point.y:00.1f}"
          // formatter: (point)=>{
          //   return point.y;
          // }
        },
        opacity: 1,
        // states: {
        //   select: {
        //     enabled: true,
        //   },
        // },
      },
      series: {
        borderWidth: 0,
        pointPadding: 0,
        events: {
          legendItemClick: function () {
            return false;
          }
        },
        dataLabels: {
          style: {
            fontSize: '14px',
          }
        }
      },
    },
    series: formatSeries(series),
  }
}

const getTooltip = (payload, formatter) => {
  return {
    shared: true,
    backgroundColor: null,
    borderWidth: 0,
    shadow: false,
    className: 'test-classname-tooltip',
    style: {
      padding: 0,
    },
    useHTML: true,
    formatter: function () {
      return formatter(this, payload)
    },
    outside: true,
  }
}

function getTooltipContent(category, data) {
  let content = _.map(data.series, (a) => ({
    name: a.name,
    data: a.data,
    dataLabels: a.dataLabels,
  }))
  
  return content
}

// tooltip in normal view
const tooltipFormatter = (chart, data) => {
  const x = chart
  const i = x.points[0].point.index
  const points = getTooltipContent(x, data)

  const options = {
    title: `${x.x}`,
    items: [
       _.map(points, (a) =>  ({
        type: 'card',
        label: a.name,
        value: Number(a.data[i]).toFixed(1),
        indicatorColor: a.dataLabels.color,
      }) ),
    ],
  }

  return ReactDOMServer.renderToString(
    <Tooltip
      small
      // headerColor={data.series[i].color}
      type="TooltipTwoColumns"
      {...options}
      className={'parameter-trend-tooltip'}
    />
  )
}


//#endregion

const BarMultipleChart = (props) => {
  const chartOptions = setChartOptions(props.data)
  return (
    <div className="chart-wrapper">
      <ReactHighchart options={chartOptions} />
    </div>  
  )
}

BarMultipleChart.propTypes = {
  data: PropTypes.object,
}

const formatValueWithZeroPad = (obj) => {
  return padNumber(_.get(obj, 'value'), 2)
}

export default BarMultipleChart;
