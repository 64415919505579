import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { useDispatch } from 'react-redux'

// Component
import { SingleCrudeChart } from '../../../../components/charts/single-crude-chart/single-crude-chart'
import { Grid, Header, Segment, Dropdown, Button } from 'semantic-ui-react'
import Chevron from '../../../../components/chevron'
import PeriodPicker from '../../../../components/period-picker'
import FetchStateWrapper from '../../../../components/fetch-state-wrapper'
import NoDataBlock from '../../../../components/no-data-block'

// Hooks
import { useStateWithPaths } from '../../../../hooks/useConnect'

// Action
import * as SingleCrudeActions from '../../../../actions/actionLanding'
import { MASTER_DATA_REDUCER } from '../../../../reducers/common/masterDataReducer'
import { getMasterSingleCrudeUnits } from '../../../../actions/common/actionMasterData'
import { setSingleCrudeChart } from '../../../../actions/actionLanding'

const RisksSingleCrudeChart = (props) => {
  const dispatch = useDispatch()

  const [singleCrudeChartData] = useStateWithPaths(['landingPage.singleCrudeChart'])
  const [{ singleCrudeUnits }] = useStateWithPaths([`${MASTER_DATA_REDUCER}`])

  const [unit, setUnit] = useState(singleCrudeChartData.unit)
  const [property, setProperty] = useState(singleCrudeChartData.property)

  const crudeNameAbbreviation = props.searchObject.crudeNameAbbreviation

  const [defaultPeriod] = useState([singleCrudeChartData.defaultPeriod])
  const [periods] = useStateWithPaths(['landingPage.singleCrudeChart.period'])
  const [period, setPeriod] = useState([periods])

  const getSingleCrudeData = _.get(singleCrudeChartData, 'data')

  useEffect(() => {
    dispatch(setSingleCrudeChart(period, 'processing', unit, crudeNameAbbreviation, property))
    dispatch(getMasterSingleCrudeUnits())
  }, [period, 'processing', unit, crudeNameAbbreviation, property])

  return (
    <Grid padded className={`single-crude-chart`}>
      <Grid.Row columns={2} verticalAlign="bottom">
        <Grid.Column textAlign="left">
          <Header size="small" textAlign="left">
            SINGLE CRUDE
          </Header>
        </Grid.Column>
        <Grid.Column>
          <div className={`flex end`}>
            <Dropdown
              placeholder="Select unit"
              className={`cdu-unit-dropdown-container`}
              value={unit}
              defaultValue={unit}
              selection
              options={singleCrudeUnits}
              onChange={(e, data) => {
                setUnit(data.value)
              }}
            />
            <PeriodPicker
              defaultPeriod={defaultPeriod}
              period={period}
              setPeriod={setPeriod}
              onClick={setSingleCrudeChart(
                period,
                'processing',
                unit,
                crudeNameAbbreviation,
                property
              )}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row column={2}>
        <Segment className={`width-100`}>
          <div className={`flex pad-x-1_5 pad-y-2`}>
            <FetchStateWrapper id={SingleCrudeActions.POST_SINGLE_CRUDE_CHART}>
              <div className={`pad-left-4 pad-bottom-2`}>
                <Header size="small" textAlign="left">
                  {!_.isEmpty(crudeNameAbbreviation)
                    ? crudeNameAbbreviation.crudeCode + ' - ' + crudeNameAbbreviation.crudeName
                    : ''}
                </Header>
              </div>

              <div className={`flex center width-100`}>
                <Button
                  className={``}
                  onClick={() => {
                    const startDate = moment(_.get(getSingleCrudeData, 'prevStartDate'))
                    const endDate = moment(_.get(getSingleCrudeData, 'prevEndDate'))
                    return setPeriod([
                      {
                        startDate: startDate._d,
                        endDate: endDate._d,
                        key: 'selection',
                      },
                    ])
                  }}
                >
                  <Chevron direction="left" />
                </Button>
                {_.isEmpty(_.get(singleCrudeChartData.data.chart, 'series')) ? (
                  <NoDataBlock />
                ) : (
                  <SingleCrudeChart singleCrudeChartData={singleCrudeChartData} period={period} />
                )}
                <Button
                  className={``}
                  onClick={() => {
                    const startDate = moment(_.get(getSingleCrudeData, 'nextStartDate'))
                    const endDate = moment(_.get(getSingleCrudeData, 'nextEndDate'))
                    return setPeriod([
                      {
                        startDate: startDate._d,
                        endDate: endDate._d,
                        key: 'selection',
                      },
                    ])
                  }}
                >
                  <Chevron direction="right" />
                </Button>
              </div>
            </FetchStateWrapper>
          </div>
        </Segment>
      </Grid.Row>
    </Grid>
  )
}

export default RisksSingleCrudeChart
