import React, { Component } from 'react';
import { Grid, Image } from 'semantic-ui-react';
import searchbox from '../../assets/images/searchbox.svg';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';

const leftMenu = {
    flex: 3,
    paddingLeft: '40px'
}

export default class LeftMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: Array(this.props.crudeProps.length).fill(true)
        }
    }

    componentDidMount() {
        this.filterProps();
    }

    filterProps = () => {
        this.props.filterProps(this.state.selected);
    }

    resetProps = () => {
        this.setState({ selected: this.state.selected.fill(false) }, this.filterProps);
    };

    selectAll = () => {
        this.setState({ selected: this.state.selected.fill(true) });
    }

    toggle = index => {
        let sel = this.state.selected;
        sel[index] = !sel[index];
        this.setState({ selected: sel });
    }


    renderParams() {
        let rows = [];
        this.props.crudeProps.forEach((crudeProp, index) => {
            if (index > 2)
                rows.push(<FormControlLabel control={<Checkbox color='primary' onChange={() => this.toggle(index)} checked={this.state.selected[index]} key={index} />} label={crudeProp}></FormControlLabel>)
        });
        return rows;
    }

    render() {
        return (
            <div style={leftMenu}>
                <Grid style={{ marginTop: '0px', paddingTop: '27px' }} >
                    <Grid.Row className='headerText'>
                        FILTERS
                    </Grid.Row>
                    <Grid.Row style={{padding: '1px 0px 40px 0px'}}>
                        <Button style={{ color: '#FFFFFF'}} basic variant='contained' color='primary' onClick={this.filterProps} ><b>Apply</b></Button>
                        <Button style={{ color: '#FFFFFF', marginLeft: '15px'}} basic variant='contained' color='primary' onClick={this.resetProps} ><b>Reset</b></Button>
                    </Grid.Row>
                    <Grid.Row >
                        <div className='subMenu'>
                            <div style={{ paddingBottom: '14px' }}>
                                <h4 style={{ display: 'inline', paddingRight: '10px' }}>PARAMETER</h4>
                                <p style={{ display: 'inline', color: 'var(--primary)', cursor: 'pointer' }} onClick={this.selectAll}><b>Select All</b></p>
                            </div>
                            {this.renderParams()}
                        </div>
                    </Grid.Row>
                    <Grid.Row >
                        <div className='subMenu'>
                            <div style={{ paddingBottom: '14px' }}>
                                <h4 style={{ display: 'inline', paddingRight: '10px' }}>RISK RATING</h4>
                                <p style={{ display: 'inline', color: '#C4C4C4' }}><b>Select All</b></p>
                            </div>
                            <FormControlLabel control={<Checkbox checked={true} color='primary'/>} label='High'></FormControlLabel>
                            <FormControlLabel control={<Checkbox checked={true} color='primary'/>} label='Medium'></FormControlLabel>
                            <FormControlLabel control={<Checkbox checked={true} color='primary'/>} label='Low'></FormControlLabel>
                        </div>
                    </Grid.Row>
                    <Grid.Row >
                        <div className='subMenu'>
                            <div style={{ paddingBottom: '14px' }}>
                                <h4 style={{ display: 'inline', paddingRight: '10px' }}>SOURCE</h4>
                                <p style={{ display: 'inline', color: '#C4C4C4' }}>Select All</p>
                            </div>
                            <FormControlLabel control={<Checkbox checked={true} color='primary'/>} label='MS-Outlook'></FormControlLabel>
                            <FormControlLabel control={<Checkbox checked={true} color='primary'/>} label='Shared-Drive'></FormControlLabel>
                        </div>
                    </Grid.Row>
                    <Grid.Row >
                        <div className='subMenu'>
                            <h4>SEARCH START DATE</h4>
                            <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: '14px' }}>
                                <Checkbox style={{marginLeft: '-10px'}} color='primary'></Checkbox>
                                <Image alt='' src={searchbox} />
                            </div>
                        </div>
                    </Grid.Row>
                    <Grid.Row >
                        <div className='subMenu'>
                            <h4>SEARCH END DATE</h4>
                            <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: '14px' }} >
                                <Checkbox style={{marginLeft: '-10px'}} color='primary'></Checkbox>
                                <Image alt='' src={searchbox} />
                            </div>
                        </div>
                    </Grid.Row>
                </Grid>

            </div>
        )
    }
}