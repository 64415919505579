import jwt from 'jsonwebtoken'
import _ from 'lodash'

import { SC, TN, ENTS } from '../constants/userGroups'

export const getUserGroupFromIdToken = (idToken) => {
    let session = jwt.decode(idToken)
    let userGroups = _.get(session, 'name') || ''
    userGroups = userGroups.replace("[", "").replace("]", "").replace(/\s/g, '').split(",");
    userGroups = _.intersection([SC, TN, ENTS], userGroups);
    return userGroups;
}