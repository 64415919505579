import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";
import moment from "moment";

// Components

import FetchStateWrapper from "../../../components/fetch-state-wrapper";
import { Grid, Header, Button, Dropdown } from "semantic-ui-react";
import Chevron from "../../../components/chevron";
import PeriodPicker from "../../../components/period-picker";
import NoDataBlock from '../../../components/no-data-block'

// Hooks]
import { useStateWithPaths } from "../../../hooks/useConnect";

// Action
import { PERSONAL_SETTING_REDUCER } from "../../../reducers/common/personalSettingReducer";
import { MASTER_DATA_REDUCER } from "../../../reducers/common/masterDataReducer";
import * as HighlightsAction from "../../../actions/actionLanding";
import { setHighlights } from "../../../actions/actionLanding";

import { getHighlightUnit } from "../../../actions/common/actionMasterData";

const Highlights = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [{ currentPersona }] = useStateWithPaths([
    `${PERSONAL_SETTING_REDUCER}`,
  ]);
  const [{ highlightUnits }] = useStateWithPaths([`${MASTER_DATA_REDUCER}`]);

  const [highlightsData] = useStateWithPaths(["landingPage.highlights"]);
  const [periods] = useStateWithPaths(["landingPage.highlights.period"]);
  const [defaultPeriod] = useState([highlightsData.defaultPeriod]);
  const [period, setPeriod] = useState([periods]);
  const [unit, setUnit] = useState(highlightsData.unit);

  const highlightsOutput = _.get(highlightsData, "data");

  useEffect(() => {
    dispatch(getHighlightUnit(currentPersona));
  }, [dispatch, currentPersona, currentPersona]);

  useEffect(() => {
    dispatch(setHighlights(currentPersona, period, unit));
  }, [dispatch, setHighlights, currentPersona, period, unit]);

  let numberCrudeDisplay = 5;
  const [isFirstCrude, setIsFirstCrude] = useState(true);
  const [isLastCrude, setIsLastCrude] = useState(false);
  const [indexFrom, setIndexFrom] = useState(0);
  const [indexTo, setIndexTo] = useState(5);
  const crudeList = [];

  const onClickNext = () => {
    setIsFirstCrude(false);
    setIsLastCrude(
      indexTo + numberCrudeDisplay >= Object.keys(highlightsOutput).length
    );
    setIndexFrom(indexFrom + numberCrudeDisplay);
    setIndexTo(indexTo + numberCrudeDisplay);
  };

  const onClickPrevious = () => {
    setIsFirstCrude(indexFrom - numberCrudeDisplay <= 0);
    setIsLastCrude(false);
    setIndexFrom(indexFrom - numberCrudeDisplay);
    setIndexTo(indexTo - numberCrudeDisplay);
  };

  if (!_.isEmpty(highlightsOutput)) {
    highlightsOutput
      .filter((item, i) => i >= indexFrom && i < indexTo)
      .map((item) => crudeList.push(item));
  }

  return (
    <Grid padded className={`highlights`}>
      <Grid.Row className={``} columns={2} verticalAlign="bottom">
        <Grid.Column textAlign="left">
          <div className={`pad-top-2`}>
            <Header size="small" textAlign="left">
              HIGHLIGHTS
            </Header>
          </div>
        </Grid.Column>
        <Grid.Column textAlign="left">
          <div className={`flex end`}>
            <Dropdown
              placeholder="Select unit"
              className={`cdu-unit-dropdown-container`}
              value={unit}
              defaultValue={unit}
              selection
              options={highlightUnits}
              onChange={(e, data) => {
                setUnit(data.value);
              }}
            />
            <PeriodPicker
              defaultPeriod={defaultPeriod}
              period={period}
              setPeriod={setPeriod}
              type="month"
            />
          </div>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <div className={`highlights-search-container`}>
          <Button
            disabled={isFirstCrude}
            onClick={onClickPrevious}
            style={{ backgroundColor: "transparent" }}
          >
            <Chevron direction="left" />
          </Button>
          <FetchStateWrapper id={HighlightsAction.POST_HIGHLIGHTS}>
            <div className={`flex width-100`}>
              {!_.isEmpty(highlightsOutput) ? _.map(crudeList, (a) => (
                <div
                  className={`highlights-search-box`}
                  onClick={() => {
                    history.push({
                      pathname: "/risks",
                      options: "single-crude",
                      crudeNameAbbreviation: {
                        crudeCode: a.crudeCode,
                        crudeName: a.crudeName,
                      },
                    });
                  }}
                >
                  <div className={`highlights-search-date flex`}>
                    <div className={`bold`}>{_.isEqual(a.rating, 'RISK') ? 'Risk Date :' : 'Issue Date :'}</div>
                    <div>{_.upperCase(moment(a.date).format("DD MMM YYYY"))}</div>
                  </div>
                  <div className={`highlights-search-crudecode`}>
                    {a.crudeName} ({a.crudeCode})
                  </div>
                  <div className={`highlights-search-description`}>
                    {a.description}
                  </div>
                </div>
              )) : <NoDataBlock />
            }
            </div>
          </FetchStateWrapper>
          <Button
            disabled={isLastCrude}
            onClick={onClickNext}
            style={{ backgroundColor: "transparent" }}
          >
            <Chevron direction="right" />
          </Button>
        </div>
      </Grid.Row>
    </Grid>
  );
};

export default Highlights;
