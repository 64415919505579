import React from 'react'
import PropTypes from 'prop-types'
import CHEVRON_ICON from '../../assets/images/chevron-down.svg'
import { ReactSVG } from 'react-svg'
import './style.scss'

const Chevron = ({ direction = 'down', className = '', white, ...rest }) => {
  return (
    <ReactSVG
      className={`alpha-chevron alpha-chevron-${direction} ${className} ${white}`}
      src={CHEVRON_ICON}
      {...rest}
    />
  )
}

Chevron.propTypes = {
  direction: PropTypes.oneOf(['down', 'up', 'left', 'right']),
}

export default Chevron
