import React, { Component } from 'react';
import axios from 'axios';
import { Table, Divider, Grid, Icon, Popup, TableCell, Dimmer } from 'semantic-ui-react';
import queryString from 'query-string';
import camref from '../../data/CAMref';
import CustomHeader from '../../components/header/customHeader';
import ToHeader from '../../components/header/toHeader';
import SearchBar from '../../components/header/searchBar';
import LeftMenu from '../../components/left-menu/leftMenu';
import CustomPopUp from '../../components/customPopUp';
import crudeCodes from '../../data/crude-code-old';

const crudeProps = ["name", "country", "assay", "process", "cargo", "kerogen", "fouling", "density", "tan", "sulfur", "sulfurtan", "mercaptan", "asphaltene", "nitrogen"];
const tableHeader = { color: 'var(--secondary)', borderLeft: 'none', borderBottom: '1px solid var(--secondary)' };
//const descUrl = 'https://search-regx-r4caxugv67wvhqd6gx6sak7nny.us-east-1.es.amazonaws.com/description/_search?size=100&q=content:';
const descUrl = 'https://thaioilpoves.sg.aabglaunchpad.com/description/_search?size=100&q=content:'
//const mitiUrl = 'https://search-regx-r4caxugv67wvhqd6gx6sak7nny.us-east-1.es.amazonaws.com/mitigation/_search?size=100&q=content:';
const mitiUrl = 'https://thaioilpoves.sg.aabglaunchpad.com/mitigation/_search?size=100&q=content:'



class Mitigation extends Component {

	constructor(props) {
		super(props);
		this.state = {
			crudeCd: '',
			crudeSummary: {},
			camRef: [],
			propKeys: [],
			isLoaded: false,
			active: false,
			curr: {},
			filtered: []
		};
		for (let crudeProp of crudeProps) {
			this.state[crudeProp + 'Desc'] = [];
			this.state[crudeProp + 'Mitigation'] = []
		}
	}

	async componentDidMount() {
		var params = queryString.parse(this.props.location.search);
		console.log(params.crudeName);
		this.setState({ crudeCd: crudeCodes.data[1][params.crudeName] });
		await this.getCamRef(params.crudeName);
		await this.getCrudeSummary(params.crudeName);
		let msg = `Crude Report for ${this.state.crudeCd} - ${params.crudeName}, ${this.state.crudeSummary.Country}`;
		this.setState({ headermsg: msg });

		await this.analyzeDescData(params.crudeName);
		await this.analyzeMitigationData(params.crudeName);
		this.setState({ isLoaded: true });
	}


	//Data fetching methods
	getCamRef = async () => {
		await axios.get('https://iu2pa7hq67.execute-api.us-east-1.amazonaws.com/dev/camref').then(
			res => {
				this.setState({
					camRef: res.data
				});
			}).catch(
				error => {
					console.log(error)
				})
	}

	getCrudeSummary = async (param) => {
		await axios.get('https://iu2pa7hq67.execute-api.us-east-1.amazonaws.com/dev/crudedata?name=' + param).then(
			res => {
				var csummary = res.data.data[0];
				this.setState({
					propKeys: Object.keys(csummary),
					crudeSummary: csummary
				});
			}).catch(
				error => {
					console.log(error)
				})
	}

	analyzeDescData = async (param) => {
		await axios.get(descUrl + param).then(
			res => {
				this.getDescDataByPropertyType(res.data);
			}).catch(
				error => {
					console.error(error)
				})
	}

	analyzeMitigationData = async (param) => {
		await axios.get(mitiUrl + param).then(
			res => {
				this.getMitigationDataByPropertyType(res.data);
			}).catch(
				error => {
					console.error(error)
				})
	}

	getMitigationDataByPropertyType = (data) => {
		let miti_initial = data.hits.hits;
		crudeProps.forEach(crudeProp => {
			let filtered = miti_initial.map(miti => miti._source.content).filter((elem, index, self) => {
				if (crudeProp === 'sulfurtan')
					return self.indexOf(elem) === index && elem.toLowerCase().includes('sulfur') && elem.toLowerCase().includes('tan')
				return self.indexOf(elem) === index && elem.toLowerCase().includes(crudeProp)
			});
			this.setState({
				[crudeProp + 'Mitigation']: filtered
			})
		})
	}

	getDescDataByPropertyType = (data) => {
		let desc_initial = data.hits.hits;
		crudeProps.forEach(crudeProp => {
			let filtered = desc_initial.map(desc => {
				let objKey = desc._source.objectKey.split('/')[0];
				return { key: objKey, val: desc._source.content }
			})
				.filter((elem, index, self) => {
					if (crudeProp === 'sulfurtan')
						return self.indexOf(elem) === index && elem.val.toLowerCase().includes('sulfur') && elem.toLowerCase().includes('tan')
					return self.indexOf(elem) === index && elem.val.toLowerCase().includes(crudeProp)
				});
			this.setState({
				[crudeProp + 'Desc']: filtered
			})
		})
	}



	//Render methods
	filterProps = (params) => {
		this.setState({ filtered: params })
	}

	handleHide = () => this.setState({ active: false });

	handleShow = curr => {
		this.setState({ active: true, curr: curr });
	}

	getDetails = (index) => {
		let name = this.state.propKeys[index];
		return {
			name: name,
			val: this.state.crudeSummary[name],
			ref: camref.data[0][name],
			desc: this.state[`${crudeProps[index]}Desc`],
			miti: this.state[`${crudeProps[index]}Mitigation`]
		}
	}

	renderDetails() {
		let rows = [];
		for (let i = 3; i < this.state.filtered.length; i++) {
			if (this.state.filtered[i]) {
				let curr = this.getDetails(i);
				if (curr.name === 'Density')
					curr.name = 'Density @15C, kg/litre';
				rows.push(
					<Table.Row key={i}>
						<Table.Cell className='innerTable'>{curr.name}</Table.Cell>
						<Table.Cell className='innerTable' >
							{curr.ref ?
								(
									<p>{curr.val}<br />
										<Popup content={`min: ${curr.ref.min} max: ${curr.ref.max}`} trigger={<p style={{ color: '#2696E7', cursor: 'pointer' }}>Details</p>}></Popup></p>) : <p>{curr.val}</p>
							}
						</Table.Cell>
						<TableCell className='innerTable'>
							{curr.desc.length > 0 && 'Medium'}
						</TableCell>
						<Table.Cell className='innerTable' >
							<ul>
								{
									curr.desc.map((item, count) => {
										return <li key={count}>{item.val}</li>;
									})}
							</ul>
							{curr.desc.length > 0 &&
								<div style={{ paddingLeft: '40px' }}>
									<Popup content='Copy Text' trigger={<Icon name='copy outline' size='large'></Icon>}></Popup>
									<Popup content='Feedback' trigger={<Icon name='comment outline' size='large'></Icon>}></Popup>
									{curr.miti.length > 0 && <Popup content='Mitigation' trigger={<Icon style={{ cursor: 'pointer' }} name='lightbulb outline'
										onClick={() => this.handleShow(curr)} size='large'></Icon>}></Popup>}
								</div>}
						</Table.Cell >
						<TableCell className='innerTable' style={{ width: '150px' }}>
							<ul>
								{
									curr.desc.map((item, count) => {
										return <li key={count}><p>{item.key}<br /><p style={{ color: '#2696E7' }}>Details</p></p></li>;
									})}
							</ul>
						</TableCell>
						{this.state.active && <CustomPopUp curr={this.state.curr} active={this.state.active} handleHide={this.handleHide}></CustomPopUp>}
					</Table.Row>
				)
			}
		}

		return rows;
	}

	render() {
		let { active } = this.state;
		return (
			<div className='Mitigation'>
				<Dimmer.Dimmable dimmed={active}>
					<ToHeader />
					<SearchBar search='basic' />
					<CustomHeader backlink="/" msg={this.state.headermsg}></CustomHeader>
					<Divider style={{ height: '3px' }} fitted />

					{!this.state.isLoaded
						? (<div style={{ margin: 'auto', marginTop: '250px' }} className="ui active centered inline loader"></div>)
						:
						(
							<div className='MitiMain'>
								<LeftMenu crudeProps={this.state.propKeys} filterProps={this.filterProps}></LeftMenu>
								<div className='MitiRight'>
									<Grid style={{ marginTop: '0px', paddingTop: '27px' }} columns={3} textAlign='left'>
										<Grid.Column className='headerText' style={{ color: 'var(--primary)' }} width={3}>
											<p style={{ marginBottom: '5px' }}>RISKS</p>
											<hr style={{ width: '48px', float: 'left', margin: '0px', border: '1px solid var(--primary)' }}></hr>
										</Grid.Column>
										<Grid.Column className='headerText' width={5}>
											FUTURE MONTH REVIEW
							</Grid.Column>
										<Grid.Column className='headerText' width={8}>
											STATISTICAL ANALYSIS
							</Grid.Column>
									</Grid>
									<Grid style={{ paddingBottom: '15px' }} columns={2}>
										<Grid.Column textAlign='left' style={{ color: "#000000" }} width={8}>
											Assay Reference: {this.state.crudeSummary['Assay Reference']}
										</Grid.Column>
										<Grid.Column textAlign='right' style={{ color: '#979797' }} width={8}>
											LastUpdated {new Date().toUTCString()}
										</Grid.Column>
									</Grid>
									<Table style={{ border: 'none' }} basic celled>
										<Table.Header>
											<Table.Row style={{ maxWidth: '30%', fontWeight: '600px', color: 'rgba(0, 0, 0, 0.56)' }}>
												<Table.HeaderCell style={tableHeader} >Parameter</Table.HeaderCell>
												<Table.HeaderCell style={tableHeader}>Value</Table.HeaderCell>
												<Table.HeaderCell style={tableHeader}>Risk Rating</Table.HeaderCell>
												<Table.HeaderCell style={tableHeader}>Risk Description</Table.HeaderCell>
												<Table.HeaderCell style={tableHeader}>Source</Table.HeaderCell>
											</Table.Row>
										</Table.Header>

										<Table.Body>
											{this.renderDetails()}
										</Table.Body>
									</Table>

								</div>
							</div>)}
				</Dimmer.Dimmable>
			</div>
		);
	}
}

export default Mitigation;