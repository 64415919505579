import React, { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import { Collapse } from 'react-collapse'

// Asset
import IconArror from '../../../assets/images/icon-arror.svg'
import AddRound from '../../../assets/images/add-round.svg'
import DeleteRound from '../../../assets/images/delete-round.svg'

// Components
import { Grid, Input, Button, Icon, Image, Header } from 'semantic-ui-react'
import SearchInput from '../../../components/search/search-input/searchInput'
import LabResult from './components/labResult'
import ShellCompatibility from './components/shellCompatibility'
import CompatibilityCalculation from './components/compatibilityCalculation'
import ThaiOilCompatibilityAssessment from './components/thaiOilCompatibilityAssessment'
import ProcessingHistory from './components/processingHistory'
import FetchStateWrapper from '../../../components/fetch-state-wrapper'
import ResultContainer from '../../../components/result-container'
import RadioButton from '../../../components/radio-button'
import * as compatibilityPageConstants from './helpers/constants'

// Hooks
import { useStateWithPaths, useActions } from '../../../hooks/useConnect'

// Redux
import { MASTER_DATA_REDUCER } from '../../../reducers/common/masterDataReducer'
import * as CompatibilityPageActions from '../../../actions/actionCompatibility'
import * as MasterDataActions from '../../../actions/common/actionMasterData'
import { useDispatch } from 'react-redux'
import { PERSONAL_SETTING_REDUCER } from '../../../reducers/common/personalSettingReducer'

//#region Private Methods
const getSearchIcon = () => {
  return (
    <div className="search-icon-wrapper flex center justify-center">
      <Icon name="search margin-none"></Icon>
    </div>
  )
}
////#endregion

const renderRadioOption = (option, setCallback) => {
  const menu = [
    {
      label: 'Lab/ Shell Assessment',
      value: compatibilityPageConstants.SEARCH_COMPATIBILITY.CRUDE_BLEND,
    },
    {
      label: 'Compatibility Calculation',
      value: compatibilityPageConstants.SEARCH_COMPATIBILITY.CALCULATION,
    },
    {
      label: 'Thaioil Compatibility Assessment',
      value: compatibilityPageConstants.SEARCH_COMPATIBILITY.ASSESSMENT,
    },
  ]
  return (
    <RadioButton
      menuData={menu}
      className="color-white"
      selectCallback={setCallback}
      selectedValue={option}
    />
  )
}

const CompatibilityPage = (props) => {
  const dispatch = useDispatch()
  const [{ currentPersona }] = useStateWithPaths([`${PERSONAL_SETTING_REDUCER}`])
  const [{ crudeNames }] = useStateWithPaths([`${MASTER_DATA_REDUCER}`])
  const [searchPanel] = useStateWithPaths(['compatibilityPage.searchPanel'])
  const [shellAssessmentData] = useStateWithPaths(['compatibilityPage.shellAssessment'])
  const [labResultData] = useStateWithPaths(['compatibilityPage.labResult'])
  const [compatCalculData] = useStateWithPaths(['compatibilityPage.compatibilityCalculation'])
  const [thaiOilCompatAssessData] = useStateWithPaths([
    'compatibilityPage.thaiOilCompatibilityAssessment',
  ])
  const [processingHistoryData] = useStateWithPaths(['compatibilityPage.processingHistory'])

  _.each(crudeNames, (item) => {
    return (item.title = item.crudeCode + ' - ' + item.crudeName)
  })

  const { getMasterCrudeNames } = useActions({
    getMasterCrudeNames: MasterDataActions.getMasterCrudeNames,
  })

  useEffect(() => {
    getMasterCrudeNames()
  }, [getMasterCrudeNames])

  const [inputUnit1, setInputUnit1] = useState(searchPanel.inputUnits.unit1)
  const [inputUnit2, setInputUnit2] = useState(searchPanel.inputUnits.unit2)
  const [inputUnit3, setInputUnit3] = useState(searchPanel.inputUnits.unit3)
  const [inputUnit4, setInputUnit4] = useState(searchPanel.inputUnits.unit4)
  const [inputUnit5, setInputUnit5] = useState(searchPanel.inputUnits.unit5)
  const [inputUnit6, setInputUnit6] = useState(searchPanel.inputUnits.unit6)
  const [inputUnit7, setInputUnit7] = useState(searchPanel.inputUnits.unit7)
  const [inputUnit8, setInputUnit8] = useState(searchPanel.inputUnits.unit8)
  const [inputUnit9, setInputUnit9] = useState(searchPanel.inputUnits.unit9)
  const [inputUnit10, setInputUnit10] = useState(searchPanel.inputUnits.unit10)

  const [calUnit1, setCalUnit1] = useState(searchPanel.calculation.unit1)
  const [calPercent1, setCalPercent1] = useState(searchPanel.calculation.unitPercent1)
  const [calUnit2, setCalUnit2] = useState(searchPanel.calculation.unit2)
  const [calPercent2, setCalPercent2] = useState(searchPanel.calculation.unitPercent2)
  const [calUnit3, setCalUnit3] = useState(searchPanel.calculation.unit3)
  const [calPercent3, setCalPercent3] = useState(searchPanel.calculation.unitPercent3)
  const [calUnit4, setCalUnit4] = useState(searchPanel.calculation.unit4)
  const [calPercent4, setCalPercent4] = useState(searchPanel.calculation.unitPercent4)
  const [calUnit5, setCalUnit5] = useState(searchPanel.calculation.unit5)
  const [calPercent5, setCalPercent5] = useState(searchPanel.calculation.unitPercent5)
  const [calUnit6, setCalUnit6] = useState(searchPanel.calculation.unit6)
  const [calPercent6, setCalPercent6] = useState(searchPanel.calculation.unitPercent6)
  const [calUnit7, setCalUnit7] = useState(searchPanel.calculation.unit7)
  const [calPercent7, setCalPercent7] = useState(searchPanel.calculation.unitPercent7)
  const [calUnit8, setCalUnit8] = useState(searchPanel.calculation.unit8)
  const [calPercent8, setCalPercent8] = useState(searchPanel.calculation.unitPercent8)
  const [calUnit9, setCalUnit9] = useState(searchPanel.calculation.unit9)
  const [calPercent9, setCalPercent9] = useState(searchPanel.calculation.unitPercent9)
  const [calUnit10, setCalUnit10] = useState(searchPanel.calculation.unit10)
  const [calPercent10, setCalPercent10] = useState(searchPanel.calculation.unitPercent10)

  const [assessUnit1, setAssessUnit1] = useState(searchPanel.assessment.unit1)
  const [assessPercent1, setAssessPercent1] = useState(searchPanel.assessment.unitPercent1)
  const [assessUnit2, setAssessUnit2] = useState(searchPanel.assessment.unit2)
  const [assessPercent2, setAssessPercent2] = useState(searchPanel.assessment.unitPercent2)
  const [assessUnit3, setAssessUnit3] = useState(searchPanel.assessment.unit3)
  const [assessPercent3, setAssessPercent3] = useState(searchPanel.assessment.unitPercent3)
  const [assessUnit4, setAssessUnit4] = useState(searchPanel.assessment.unit4)
  const [assessPercent4, setAssessPercent4] = useState(searchPanel.assessment.unitPercent4)
  const [assessUnit5, setAssessUnit5] = useState(searchPanel.assessment.unit5)
  const [assessPercent5, setAssessPercent5] = useState(searchPanel.assessment.unitPercent5)
  const [assessUnit6, setAssessUnit6] = useState(searchPanel.assessment.unit6)
  const [assessPercent6, setAssessPercent6] = useState(searchPanel.assessment.unitPercent6)
  const [assessUnit7, setAssessUnit7] = useState(searchPanel.assessment.unit7)
  const [assessPercent7, setAssessPercent7] = useState(searchPanel.assessment.unitPercent7)
  const [assessUnit8, setAssessUnit8] = useState(searchPanel.assessment.unit8)
  const [assessPercent8, setAssessPercent8] = useState(searchPanel.assessment.unitPercent8)
  const [assessUnit9, setAssessUnit9] = useState(searchPanel.assessment.unit9)
  const [assessPercent9, setAssessPercent9] = useState(searchPanel.assessment.unitPercent9)
  const [assessUnit10, setAssessUnit10] = useState(searchPanel.assessment.unit10)
  const [assessPercent10, setAssessPercent10] = useState(searchPanel.assessment.unitPercent10)
  const [assessUnit11, setAssessUnit11] = useState(searchPanel.assessment.unit11)
  const [assessPercent11, setAssessPercent11] = useState(searchPanel.assessment.unitPercent11)
  const [assessUnit12, setAssessUnit12] = useState(searchPanel.assessment.unit12)
  const [assessPercent12, setAssessPercent12] = useState(searchPanel.assessment.unitPercent12)
  const [assessUnit13, setAssessUnit13] = useState(searchPanel.assessment.unit13)
  const [assessPercent13, setAssessPercent13] = useState(searchPanel.assessment.unitPercent13)
  const [assessUnit14, setAssessUnit14] = useState(searchPanel.assessment.unit14)
  const [assessPercent14, setAssessPercent14] = useState(searchPanel.assessment.unitPercent14)
  const [assessUnit15, setAssessUnit15] = useState(searchPanel.assessment.unit15)
  const [assessPercent15, setAssessPercent15] = useState(searchPanel.assessment.unitPercent15)
  const [assessUnit16, setAssessUnit16] = useState(searchPanel.assessment.unit16)
  const [assessPercent16, setAssessPercent16] = useState(searchPanel.assessment.unitPercent16)
  const [assessUnit17, setAssessUnit17] = useState(searchPanel.assessment.unit17)
  const [assessPercent17, setAssessPercent17] = useState(searchPanel.assessment.unitPercent17)
  const [assessUnit18, setAssessUnit18] = useState(searchPanel.assessment.unit18)
  const [assessPercent18, setAssessPercent18] = useState(searchPanel.assessment.unitPercent18)
  const [assessUnit19, setAssessUnit19] = useState(searchPanel.assessment.unit19)
  const [assessPercent19, setAssessPercent19] = useState(searchPanel.assessment.unitPercent19)
  const [assessUnit20, setAssessUnit20] = useState(searchPanel.assessment.unit20)
  const [assessPercent20, setAssessPercent20] = useState(searchPanel.assessment.unitPercent20)
  const [assessUnit21, setAssessUnit21] = useState(searchPanel.assessment.unit21)
  const [assessPercent21, setAssessPercent21] = useState(searchPanel.assessment.unitPercent21)
  const [assessUnit22, setAssessUnit22] = useState(searchPanel.assessment.unit22)
  const [assessPercent22, setAssessPercent22] = useState(searchPanel.assessment.unitPercent22)
  const [assessUnit23, setAssessUnit23] = useState(searchPanel.assessment.unit23)
  const [assessPercent23, setAssessPercent23] = useState(searchPanel.assessment.unitPercent23)
  const [assessUnit24, setAssessUnit24] = useState(searchPanel.assessment.unit24)
  const [assessPercent24, setAssessPercent24] = useState(searchPanel.assessment.unitPercent24)
  const [assessUnit25, setAssessUnit25] = useState(searchPanel.assessment.unit25)
  const [assessPercent25, setAssessPercent25] = useState(searchPanel.assessment.unitPercent25)
  const [assessUnit26, setAssessUnit26] = useState(searchPanel.assessment.unit26)
  const [assessPercent26, setAssessPercent26] = useState(searchPanel.assessment.unitPercent26)
  const [assessUnit27, setAssessUnit27] = useState(searchPanel.assessment.unit27)
  const [assessPercent27, setAssessPercent27] = useState(searchPanel.assessment.unitPercent27)
  const [assessUnit28, setAssessUnit28] = useState(searchPanel.assessment.unit28)
  const [assessPercent28, setAssessPercent28] = useState(searchPanel.assessment.unitPercent28)
  const [assessUnit29, setAssessUnit29] = useState(searchPanel.assessment.unit29)
  const [assessPercent29, setAssessPercent29] = useState(searchPanel.assessment.unitPercent29)
  const [assessUnit30, setAssessUnit30] = useState(searchPanel.assessment.unit30)
  const [assessPercent30, setAssessPercent30] = useState(searchPanel.assessment.unitPercent30)

  const [option, setOption] = useState(searchPanel.option)

  const onChangeRadioOption = (optionValue) => {
    setOption(optionValue)
  }

  const [errorInputs, setErrorInputs] = useState({})

  const [openLabResult, setOpenLabResult] = useState(true)
  const [openShellCompatibilityAssessment, setOpenShellCompatibilityAssessment] = useState(true)
  const [openProcessingHistory, setOpenProcessingHistory] = useState(true)

  const [addCrudeSetA, setAddCrudeSetA] = useState(false)
  const [addCrudeSetB, setAddCrudeSetB] = useState(false)
  const [addCrudeSetC, setAddCrudeSetC] = useState(false)

  const [openNotification, setOpenNotification] = useState(true)

  const isEmptyValue = useCallback((inputValue, isInputUnit) => {
    if (isInputUnit) {
      return _.isNull(inputValue) || _.isEmpty(inputValue)
    } else {
      return (
        _.isNull(inputValue) || _.isUndefined(inputValue) || inputValue <= 0 || inputValue > 100
      )
    }
  })

  const isNotEmptyPercentage = (inputPercent) => {
    return !_.isEqualWith(inputPercent, '') && !_.isUndefined(inputPercent)
  }

  const checkDuplicateInputUnits = (inputUnits) => {
    const inputUnitsList = _.map(inputUnits, (item, index) => ({ ...item, inputUnit: index }))
    const arr = []
    _.map(inputUnitsList, (item, index) => {
      if (!_.isEmpty(item.crudeCode)) {
        arr.push(item)
      }
    })

    const mapped = _.map(arr, (obj, index) => obj.crudeCode)
    const sorted_arr = mapped.slice().sort()

    var results = []
    for (var i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] === sorted_arr[i]) {
        results.push(sorted_arr[i])
      }
    }

    const exists = []
    _.map(results, (crudeCode) => {
      _.map(arr, (item) => {
        if (item.crudeCode === crudeCode) {
          exists.push(item)
        }
      })
    })

    return exists
  }

  const isPassValidationForCalculate = useCallback(
    (optionValue) => {
      let newErrorInputs = {}

      if (optionValue === compatibilityPageConstants.SEARCH_COMPATIBILITY.CRUDE_BLEND) {
        newErrorInputs.inputUnit1 = isEmptyValue(inputUnit1, true)
        newErrorInputs.inputUnit2 = isEmptyValue(inputUnit2, true)

        const inputUnits = {
          inputUnit1,
          inputUnit2,
          inputUnit3,
          inputUnit4,
          inputUnit5,
          inputUnit6,
          inputUnit7,
          inputUnit8,
          inputUnit9,
          inputUnit10,
        }

        const duplicateList = checkDuplicateInputUnits(inputUnits)
        _.map(duplicateList, (error) => {
          newErrorInputs[error.inputUnit] = true
        })

        newErrorInputs.lab = !_.isEmpty(duplicateList)
          ? compatibilityPageConstants.SEARCH_VALIDATION.ERROR_MSG_3
          : ''
      } else if (optionValue === compatibilityPageConstants.SEARCH_COMPATIBILITY.CALCULATION) {
        newErrorInputs.calUnit1 = isEmptyValue(calUnit1, true)
        newErrorInputs.calUnit2 = isEmptyValue(calUnit2, true)

        let total = 0
        total = isNotEmptyPercentage(calPercent1) ? total + calPercent1 : total + 0
        total = isNotEmptyPercentage(calPercent2) ? total + calPercent2 : total + 0
        total = isNotEmptyPercentage(calPercent3) ? total + calPercent3 : total + 0
        total = isNotEmptyPercentage(calPercent4) ? total + calPercent4 : total + 0
        total = isNotEmptyPercentage(calPercent5) ? total + calPercent5 : total + 0
        total = isNotEmptyPercentage(calPercent6) ? total + calPercent6 : total + 0
        total = isNotEmptyPercentage(calPercent7) ? total + calPercent7 : total + 0
        total = isNotEmptyPercentage(calPercent8) ? total + calPercent8 : total + 0
        total = isNotEmptyPercentage(calPercent9) ? total + calPercent9 : total + 0
        total = isNotEmptyPercentage(calPercent10) ? total + calPercent10 : total + 0

        if (
          (!isEmptyValue(calUnit1, true) && isEmptyValue(calPercent1, false)) ||
          (!isEmptyValue(calUnit2, true) && isEmptyValue(calPercent2, false)) ||
          (!isEmptyValue(calUnit3, true) && isEmptyValue(calPercent3, false)) ||
          (!isEmptyValue(calUnit4, true) && isEmptyValue(calPercent4, false)) ||
          (!isEmptyValue(calUnit5, true) && isEmptyValue(calPercent5, false)) ||
          (!isEmptyValue(calUnit6, true) && isEmptyValue(calPercent6, false)) ||
          (!isEmptyValue(calUnit7, true) && isEmptyValue(calPercent7, false)) ||
          (!isEmptyValue(calUnit8, true) && isEmptyValue(calPercent8, false)) ||
          (!isEmptyValue(calUnit9, true) && isEmptyValue(calPercent9, false)) ||
          (!isEmptyValue(calUnit10, true) && isEmptyValue(calPercent10, false))
        ) {
          if (!isEmptyValue(calUnit1, true)) {
            newErrorInputs.calPercent1 = isEmptyValue(calPercent1)
          }

          if (!isEmptyValue(calUnit2, true)) {
            newErrorInputs.calPercent2 = isEmptyValue(calPercent2)
          }

          if (!isEmptyValue(calUnit3, true)) {
            newErrorInputs.calPercent3 = isEmptyValue(calPercent3)
          }

          if (!isEmptyValue(calUnit4, true)) {
            newErrorInputs.calPercent4 = isEmptyValue(calPercent4)
          }

          if (!isEmptyValue(calUnit5, true)) {
            newErrorInputs.calPercent5 = isEmptyValue(calPercent5)
          }

          if (!isEmptyValue(calUnit6, true)) {
            newErrorInputs.calPercent6 = isEmptyValue(calPercent6)
          }

          if (!isEmptyValue(calUnit7, true)) {
            newErrorInputs.calPercent7 = isEmptyValue(calPercent7)
          }

          if (!isEmptyValue(calUnit8, true)) {
            newErrorInputs.calPercent8 = isEmptyValue(calPercent8)
          }

          if (!isEmptyValue(calUnit9, true)) {
            newErrorInputs.calPercent9 = isEmptyValue(calPercent9)
          }

          if (!isEmptyValue(calUnit10, true)) {
            newErrorInputs.calPercent10 = isEmptyValue(calPercent10)
          }

          newErrorInputs.calculation = compatibilityPageConstants.SEARCH_VALIDATION.ERROR_MSG_5
        }
        if (_.isEmpty(newErrorInputs.calculation)) {
          if (total != '100') {
            newErrorInputs.calPercent1 = isNotEmptyPercentage(calPercent1)
            newErrorInputs.calPercent2 = isNotEmptyPercentage(calPercent2)
            newErrorInputs.calPercent3 = isNotEmptyPercentage(calPercent3)
            newErrorInputs.calPercent4 = isNotEmptyPercentage(calPercent4)
            newErrorInputs.calPercent5 = isNotEmptyPercentage(calPercent5)
            newErrorInputs.calPercent6 = isNotEmptyPercentage(calPercent6)
            newErrorInputs.calPercent7 = isNotEmptyPercentage(calPercent7)
            newErrorInputs.calPercent8 = isNotEmptyPercentage(calPercent8)
            newErrorInputs.calPercent9 = isNotEmptyPercentage(calPercent9)
            newErrorInputs.calPercent10 = isNotEmptyPercentage(calPercent10)

            newErrorInputs.calculation = compatibilityPageConstants.SEARCH_VALIDATION.ERROR_MSG_2
          } else {
            const inputUnits = {
              calUnit1,
              calUnit2,
              calUnit3,
              calUnit4,
              calUnit5,
              calUnit6,
              calUnit7,
              calUnit8,
              calUnit9,
              calUnit10,
            }

            const duplicateList = checkDuplicateInputUnits(inputUnits)
            _.map(duplicateList, (error) => {
              newErrorInputs[error.inputUnit] = true
            })

            newErrorInputs.calculation = !_.isEmpty(duplicateList)
              ? compatibilityPageConstants.SEARCH_VALIDATION.ERROR_MSG_3
              : ''
          }
        }
      } else if (optionValue === compatibilityPageConstants.SEARCH_COMPATIBILITY.ASSESSMENT) {
        let totalA = 0
        let totalB = 0
        let totalC = 0

        totalA = isNotEmptyPercentage(assessPercent1) ? totalA + assessPercent1 : totalA + 0
        totalA = isNotEmptyPercentage(assessPercent2) ? totalA + assessPercent2 : totalA + 0
        totalA = isNotEmptyPercentage(assessPercent3) ? totalA + assessPercent3 : totalA + 0
        totalA = isNotEmptyPercentage(assessPercent4) ? totalA + assessPercent4 : totalA + 0
        totalA = isNotEmptyPercentage(assessPercent5) ? totalA + assessPercent5 : totalA + 0
        totalA = isNotEmptyPercentage(assessPercent6) ? totalA + assessPercent6 : totalA + 0
        totalA = isNotEmptyPercentage(assessPercent7) ? totalA + assessPercent7 : totalA + 0
        totalA = isNotEmptyPercentage(assessPercent8) ? totalA + assessPercent8 : totalA + 0
        totalA = isNotEmptyPercentage(assessPercent9) ? totalA + assessPercent9 : totalA + 0
        totalA = isNotEmptyPercentage(assessPercent10) ? totalA + assessPercent10 : totalA + 0

        totalB = isNotEmptyPercentage(assessPercent11) ? totalB + assessPercent11 : totalB + 0
        totalB = isNotEmptyPercentage(assessPercent12) ? totalB + assessPercent12 : totalB + 0
        totalB = isNotEmptyPercentage(assessPercent13) ? totalB + assessPercent13 : totalB + 0
        totalB = isNotEmptyPercentage(assessPercent14) ? totalB + assessPercent14 : totalB + 0
        totalB = isNotEmptyPercentage(assessPercent15) ? totalB + assessPercent15 : totalB + 0
        totalB = isNotEmptyPercentage(assessPercent16) ? totalB + assessPercent16 : totalB + 0
        totalB = isNotEmptyPercentage(assessPercent17) ? totalB + assessPercent17 : totalB + 0
        totalB = isNotEmptyPercentage(assessPercent18) ? totalB + assessPercent18 : totalB + 0
        totalB = isNotEmptyPercentage(assessPercent19) ? totalB + assessPercent19 : totalB + 0
        totalB = isNotEmptyPercentage(assessPercent20) ? totalB + assessPercent20 : totalB + 0

        totalC = isNotEmptyPercentage(assessPercent21) ? totalC + assessPercent21 : totalC + 0
        totalC = isNotEmptyPercentage(assessPercent22) ? totalC + assessPercent22 : totalC + 0
        totalC = isNotEmptyPercentage(assessPercent23) ? totalC + assessPercent23 : totalC + 0
        totalC = isNotEmptyPercentage(assessPercent24) ? totalC + assessPercent24 : totalC + 0
        totalC = isNotEmptyPercentage(assessPercent25) ? totalC + assessPercent25 : totalC + 0
        totalC = isNotEmptyPercentage(assessPercent26) ? totalC + assessPercent26 : totalC + 0
        totalC = isNotEmptyPercentage(assessPercent27) ? totalC + assessPercent27 : totalC + 0
        totalC = isNotEmptyPercentage(assessPercent28) ? totalC + assessPercent28 : totalC + 0
        totalC = isNotEmptyPercentage(assessPercent29) ? totalC + assessPercent29 : totalC + 0
        totalC = isNotEmptyPercentage(assessPercent30) ? totalC + assessPercent30 : totalC + 0

        let totalCompareSet = 0
        let isSetACompared =
          !_.isEmpty(assessUnit1) ||
          !_.isEmpty(assessUnit2) ||
          !_.isEmpty(assessUnit3) ||
          !_.isEmpty(assessUnit4) ||
          !_.isEmpty(assessUnit5) ||
          !_.isEmpty(assessUnit6) ||
          !_.isEmpty(assessUnit7) ||
          !_.isEmpty(assessUnit8) ||
          !_.isEmpty(assessUnit9) ||
          !_.isEmpty(assessUnit10)

        let isSetBCompared =
          !_.isEmpty(assessUnit11) ||
          !_.isEmpty(assessUnit12) ||
          !_.isEmpty(assessUnit13) ||
          !_.isEmpty(assessUnit14) ||
          !_.isEmpty(assessUnit15) ||
          !_.isEmpty(assessUnit16) ||
          !_.isEmpty(assessUnit17) ||
          !_.isEmpty(assessUnit18) ||
          !_.isEmpty(assessUnit19) ||
          !_.isEmpty(assessUnit20)

        let isSetCCompared =
          !_.isEmpty(assessUnit21) ||
          !_.isEmpty(assessUnit22) ||
          !_.isEmpty(assessUnit23) ||
          !_.isEmpty(assessUnit24) ||
          !_.isEmpty(assessUnit25) ||
          !_.isEmpty(assessUnit26) ||
          !_.isEmpty(assessUnit27) ||
          !_.isEmpty(assessUnit28) ||
          !_.isEmpty(assessUnit29) ||
          !_.isEmpty(assessUnit30)

        totalCompareSet = isSetACompared ? totalCompareSet + 1 : totalCompareSet
        totalCompareSet = isSetBCompared ? totalCompareSet + 1 : totalCompareSet
        totalCompareSet = isSetCCompared ? totalCompareSet + 1 : totalCompareSet

        if (totalCompareSet >= 2) {
          if (isSetACompared) {
            if (
              (!isEmptyValue(assessUnit1, true) && isEmptyValue(assessPercent1, false)) ||
              (!isEmptyValue(assessUnit2, true) && isEmptyValue(assessPercent2, false)) ||
              (!isEmptyValue(assessUnit3, true) && isEmptyValue(assessPercent3, false)) ||
              (!isEmptyValue(assessUnit4, true) && isEmptyValue(assessPercent4, false)) ||
              (!isEmptyValue(assessUnit5, true) && isEmptyValue(assessPercent5, false)) ||
              (!isEmptyValue(assessUnit6, true) && isEmptyValue(assessPercent6, false)) ||
              (!isEmptyValue(assessUnit7, true) && isEmptyValue(assessPercent7, false)) ||
              (!isEmptyValue(assessUnit8, true) && isEmptyValue(assessPercent8, false)) ||
              (!isEmptyValue(assessUnit9, true) && isEmptyValue(assessPercent9, false)) ||
              (!isEmptyValue(assessUnit10, true) && isEmptyValue(assessPercent10, false))
            ) {
              if (!isEmptyValue(assessUnit1, true)) {
                newErrorInputs.assessPercent1 = isEmptyValue(assessPercent1)
              }

              if (!isEmptyValue(assessUnit2, true)) {
                newErrorInputs.assessPercent2 = isEmptyValue(assessPercent2)
              }

              if (!isEmptyValue(assessUnit3, true)) {
                newErrorInputs.assessPercent3 = isEmptyValue(assessPercent3)
              }

              if (!isEmptyValue(assessUnit4, true)) {
                newErrorInputs.assessPercent4 = isEmptyValue(assessPercent4)
              }

              if (!isEmptyValue(assessUnit5, true)) {
                newErrorInputs.assessPercent5 = isEmptyValue(assessPercent5)
              }

              if (!isEmptyValue(assessUnit6, true)) {
                newErrorInputs.assessPercent6 = isEmptyValue(assessPercent6)
              }

              if (!isEmptyValue(assessUnit7, true)) {
                newErrorInputs.assessPercent7 = isEmptyValue(assessPercent7)
              }

              if (!isEmptyValue(assessUnit8, true)) {
                newErrorInputs.assessPercent8 = isEmptyValue(assessPercent8)
              }

              if (!isEmptyValue(assessUnit9, true)) {
                newErrorInputs.assessPercent9 = isEmptyValue(assessPercent9)
              }

              if (!isEmptyValue(assessUnit10, true)) {
                newErrorInputs.assessPercent10 = isEmptyValue(assessPercent10)
              }

              newErrorInputs.assessmentSetA =
                compatibilityPageConstants.SEARCH_VALIDATION.ERROR_MSG_5
            }

            if (_.isEmpty(newErrorInputs.assessmentSetA)) {
              if (totalA != '100') {
                newErrorInputs.assessPercent1 = isNotEmptyPercentage(assessPercent1)
                newErrorInputs.assessPercent2 = isNotEmptyPercentage(assessPercent2)
                newErrorInputs.assessPercent3 = isNotEmptyPercentage(assessPercent3)
                newErrorInputs.assessPercent4 = isNotEmptyPercentage(assessPercent4)
                newErrorInputs.assessPercent5 = isNotEmptyPercentage(assessPercent5)
                newErrorInputs.assessPercent6 = isNotEmptyPercentage(assessPercent6)
                newErrorInputs.assessPercent7 = isNotEmptyPercentage(assessPercent7)
                newErrorInputs.assessPercent8 = isNotEmptyPercentage(assessPercent8)
                newErrorInputs.assessPercent9 = isNotEmptyPercentage(assessPercent9)
                newErrorInputs.assessPercent10 = isNotEmptyPercentage(assessPercent10)

                newErrorInputs.assessmentSetA =
                  compatibilityPageConstants.SEARCH_VALIDATION.ERROR_MSG_2
              } else {
                const inputUnits = {
                  assessUnit1,
                  assessUnit2,
                  assessUnit3,
                  assessUnit4,
                  assessUnit5,
                  assessUnit6,
                  assessUnit7,
                  assessUnit8,
                  assessUnit9,
                  assessUnit10,
                }

                const duplicateList = checkDuplicateInputUnits(inputUnits)
                _.map(duplicateList, (error) => {
                  newErrorInputs[error.inputUnit] = true
                })

                newErrorInputs.assessmentSetA = !_.isEmpty(duplicateList)
                  ? compatibilityPageConstants.SEARCH_VALIDATION.ERROR_MSG_3
                  : ''
              }
            }
          }

          if (isSetBCompared) {
            if (
              (!isEmptyValue(assessUnit11, true) && isEmptyValue(assessPercent11, false)) ||
              (!isEmptyValue(assessUnit12, true) && isEmptyValue(assessPercent12, false)) ||
              (!isEmptyValue(assessUnit13, true) && isEmptyValue(assessPercent13, false)) ||
              (!isEmptyValue(assessUnit14, true) && isEmptyValue(assessPercent14, false)) ||
              (!isEmptyValue(assessUnit15, true) && isEmptyValue(assessPercent15, false)) ||
              (!isEmptyValue(assessUnit16, true) && isEmptyValue(assessPercent16, false)) ||
              (!isEmptyValue(assessUnit17, true) && isEmptyValue(assessPercent17, false)) ||
              (!isEmptyValue(assessUnit18, true) && isEmptyValue(assessPercent18, false)) ||
              (!isEmptyValue(assessUnit19, true) && isEmptyValue(assessPercent19, false)) ||
              (!isEmptyValue(assessUnit20, true) && isEmptyValue(assessPercent20, false))
            ) {
              if (!isEmptyValue(assessUnit11, true)) {
                newErrorInputs.assessPercent11 = isEmptyValue(assessPercent11)
              }

              if (!isEmptyValue(assessUnit12, true)) {
                newErrorInputs.assessPercent12 = isEmptyValue(assessPercent12)
              }

              if (!isEmptyValue(assessUnit13, true)) {
                newErrorInputs.assessPercent13 = isEmptyValue(assessPercent13)
              }

              if (!isEmptyValue(assessUnit14, true)) {
                newErrorInputs.assessPercent14 = isEmptyValue(assessPercent14)
              }

              if (!isEmptyValue(assessUnit15, true)) {
                newErrorInputs.assessPercent15 = isEmptyValue(assessPercent15)
              }

              if (!isEmptyValue(assessUnit16, true)) {
                newErrorInputs.assessPercent16 = isEmptyValue(assessPercent16)
              }

              if (!isEmptyValue(assessUnit17, true)) {
                newErrorInputs.assessPercent17 = isEmptyValue(assessPercent17)
              }

              if (!isEmptyValue(assessUnit18, true)) {
                newErrorInputs.assessPercent18 = isEmptyValue(assessPercent18)
              }

              if (!isEmptyValue(assessUnit19, true)) {
                newErrorInputs.assessPercent19 = isEmptyValue(assessPercent19)
              }

              if (!isEmptyValue(assessUnit20, true)) {
                newErrorInputs.assessPercent20 = isEmptyValue(assessPercent20)
              }

              newErrorInputs.assessmentSetB =
                compatibilityPageConstants.SEARCH_VALIDATION.ERROR_MSG_5
            }

            if (_.isEmpty(newErrorInputs.assessmentSetB)) {
              if (totalB != '100') {
                newErrorInputs.assessPercent11 = isNotEmptyPercentage(assessPercent11)
                newErrorInputs.assessPercent12 = isNotEmptyPercentage(assessPercent12)
                newErrorInputs.assessPercent13 = isNotEmptyPercentage(assessPercent13)
                newErrorInputs.assessPercent14 = isNotEmptyPercentage(assessPercent14)
                newErrorInputs.assessPercent15 = isNotEmptyPercentage(assessPercent15)
                newErrorInputs.assessPercent16 = isNotEmptyPercentage(assessPercent16)
                newErrorInputs.assessPercent17 = isNotEmptyPercentage(assessPercent17)
                newErrorInputs.assessPercent18 = isNotEmptyPercentage(assessPercent18)
                newErrorInputs.assessPercent19 = isNotEmptyPercentage(assessPercent19)
                newErrorInputs.assessPercent20 = isNotEmptyPercentage(assessPercent20)
                newErrorInputs.assessmentSetB =
                  compatibilityPageConstants.SEARCH_VALIDATION.ERROR_MSG_2
              } else {
                const inputUnits = {
                  assessUnit11,
                  assessUnit12,
                  assessUnit13,
                  assessUnit14,
                  assessUnit15,
                  assessUnit16,
                  assessUnit17,
                  assessUnit18,
                  assessUnit19,
                  assessUnit20,
                }

                const duplicateList = checkDuplicateInputUnits(inputUnits)
                _.map(duplicateList, (error) => {
                  newErrorInputs[error.inputUnit] = true
                })

                newErrorInputs.assessmentSetB = !_.isEmpty(duplicateList)
                  ? compatibilityPageConstants.SEARCH_VALIDATION.ERROR_MSG_3
                  : ''
              }
            }
          }

          if (isSetCCompared) {
            if (
              (!isEmptyValue(assessUnit21, true) && isEmptyValue(assessPercent21, false)) ||
              (!isEmptyValue(assessUnit22, true) && isEmptyValue(assessPercent22, false)) ||
              (!isEmptyValue(assessUnit23, true) && isEmptyValue(assessPercent23, false)) ||
              (!isEmptyValue(assessUnit24, true) && isEmptyValue(assessPercent24, false)) ||
              (!isEmptyValue(assessUnit25, true) && isEmptyValue(assessPercent25, false)) ||
              (!isEmptyValue(assessUnit26, true) && isEmptyValue(assessPercent26, false)) ||
              (!isEmptyValue(assessUnit27, true) && isEmptyValue(assessPercent27, false)) ||
              (!isEmptyValue(assessUnit28, true) && isEmptyValue(assessPercent28, false)) ||
              (!isEmptyValue(assessUnit29, true) && isEmptyValue(assessPercent29, false)) ||
              (!isEmptyValue(assessUnit30, true) && isEmptyValue(assessPercent30, false))
            ) {
              if (!isEmptyValue(assessUnit21, true)) {
                newErrorInputs.assessPercent21 = isEmptyValue(assessPercent21)
              }

              if (!isEmptyValue(assessUnit22, true)) {
                newErrorInputs.assessPercent22 = isEmptyValue(assessPercent22)
              }

              if (!isEmptyValue(assessUnit23, true)) {
                newErrorInputs.assessPercent23 = isEmptyValue(assessPercent23)
              }

              if (!isEmptyValue(assessUnit24, true)) {
                newErrorInputs.assessPercent24 = isEmptyValue(assessPercent24)
              }

              if (!isEmptyValue(assessUnit25, true)) {
                newErrorInputs.assessPercent25 = isEmptyValue(assessPercent25)
              }

              if (!isEmptyValue(assessUnit26, true)) {
                newErrorInputs.assessPercent26 = isEmptyValue(assessPercent26)
              }

              if (!isEmptyValue(assessUnit27, true)) {
                newErrorInputs.assessPercent27 = isEmptyValue(assessPercent27)
              }

              if (!isEmptyValue(assessUnit28, true)) {
                newErrorInputs.assessPercent28 = isEmptyValue(assessPercent28)
              }

              if (!isEmptyValue(assessUnit29, true)) {
                newErrorInputs.assessPercent29 = isEmptyValue(assessPercent29)
              }

              if (!isEmptyValue(assessUnit30, true)) {
                newErrorInputs.assessPercent30 = isEmptyValue(assessPercent30)
              }

              newErrorInputs.assessmentSetC =
                compatibilityPageConstants.SEARCH_VALIDATION.ERROR_MSG_5
            }

            if (_.isEmpty(newErrorInputs.assessmentSetC)) {
              if (totalC != '100') {
                newErrorInputs.assessPercent21 = isNotEmptyPercentage(assessPercent21)
                newErrorInputs.assessPercent22 = isNotEmptyPercentage(assessPercent22)
                newErrorInputs.assessPercent23 = isNotEmptyPercentage(assessPercent23)
                newErrorInputs.assessPercent24 = isNotEmptyPercentage(assessPercent24)
                newErrorInputs.assessPercent25 = isNotEmptyPercentage(assessPercent25)
                newErrorInputs.assessPercent26 = isNotEmptyPercentage(assessPercent26)
                newErrorInputs.assessPercent27 = isNotEmptyPercentage(assessPercent27)
                newErrorInputs.assessPercent28 = isNotEmptyPercentage(assessPercent28)
                newErrorInputs.assessPercent29 = isNotEmptyPercentage(assessPercent29)
                newErrorInputs.assessPercent30 = isNotEmptyPercentage(assessPercent30)

                newErrorInputs.assessmentSetC =
                  compatibilityPageConstants.SEARCH_VALIDATION.ERROR_MSG_2
              } else {
                const inputUnits = {
                  assessUnit21,
                  assessUnit22,
                  assessUnit23,
                  assessUnit24,
                  assessUnit25,
                  assessUnit26,
                  assessUnit27,
                  assessUnit28,
                  assessUnit29,
                  assessUnit30,
                }

                const duplicateList = checkDuplicateInputUnits(inputUnits)
                _.map(duplicateList, (error) => {
                  newErrorInputs[error.inputUnit] = true
                })

                newErrorInputs.assessmentSetC = !_.isEmpty(duplicateList)
                  ? compatibilityPageConstants.SEARCH_VALIDATION.ERROR_MSG_3
                  : ''
              }
            }
          }
        } else {
          newErrorInputs.assessmentSetC = compatibilityPageConstants.SEARCH_VALIDATION.ERROR_MSG_4
        }
      }

      setErrorInputs(newErrorInputs)
      let hasError = false
      _.forOwn(newErrorInputs, (value, key) => {
        if (hasError) return

        hasError = value
      })
      return !hasError
    },
    [
      inputUnit1,
      inputUnit2,
      inputUnit3,
      inputUnit4,
      inputUnit5,
      inputUnit6,
      inputUnit7,
      inputUnit8,
      inputUnit9,
      inputUnit10,
      calUnit1,
      calUnit2,
      calUnit3,
      calUnit4,
      calUnit5,
      calUnit6,
      calUnit7,
      calUnit8,
      calUnit9,
      calUnit10,
      calPercent1,
      calPercent2,
      calPercent3,
      calPercent4,
      calPercent5,
      calPercent6,
      calPercent7,
      calPercent8,
      calPercent9,
      calPercent10,
      assessUnit1,
      assessUnit2,
      assessUnit3,
      assessUnit4,
      assessUnit5,
      assessUnit6,
      assessUnit7,
      assessUnit8,
      assessUnit9,
      assessUnit10,
      assessUnit11,
      assessUnit12,
      assessUnit13,
      assessUnit14,
      assessUnit15,
      assessUnit16,
      assessUnit17,
      assessUnit18,
      assessUnit19,
      assessUnit20,
      assessUnit21,
      assessUnit22,
      assessUnit23,
      assessUnit24,
      assessUnit25,
      assessUnit26,
      assessUnit27,
      assessUnit28,
      assessUnit29,
      assessUnit30,
      assessPercent1,
      assessPercent2,
      assessPercent3,
      assessPercent4,
      assessPercent5,
      assessPercent6,
      assessPercent7,
      assessPercent8,
      assessPercent9,
      assessPercent10,
      assessPercent11,
      assessPercent12,
      assessPercent13,
      assessPercent14,
      assessPercent15,
      assessPercent16,
      assessPercent17,
      assessPercent18,
      assessPercent19,
      assessPercent20,
      assessPercent21,
      assessPercent22,
      assessPercent23,
      assessPercent24,
      assessPercent25,
      assessPercent26,
      assessPercent27,
      assessPercent28,
      assessPercent29,
      assessPercent30,
    ]
  )

  const onClickCheckCompatibility = () => {
    if (!isPassValidationForCalculate(option)) {
      return
    }

    if (option === compatibilityPageConstants.SEARCH_COMPATIBILITY.CRUDE_BLEND) {
      clearUnitPercentCalculation()
      clearUnitPercentAssessment()
      dispatch(
        CompatibilityPageActions.setCheckCompatibility(
          option,
          currentPersona,
          inputUnit1,
          inputUnit2,
          inputUnit3,
          inputUnit4,
          inputUnit5,
          inputUnit6,
          inputUnit7,
          inputUnit8,
          inputUnit9,
          inputUnit10
        )
      )
    } else if (option === compatibilityPageConstants.SEARCH_COMPATIBILITY.CALCULATION) {
      clearUnitPercent()
      clearUnitPercentAssessment()
      dispatch(
        CompatibilityPageActions.setCheckCompatibilityCalculation(
          option,
          calUnit1,
          calUnit2,
          calUnit3,
          calUnit4,
          calUnit5,
          calUnit6,
          calUnit7,
          calUnit8,
          calUnit9,
          calUnit10,
          calPercent1,
          calPercent2,
          calPercent3,
          calPercent4,
          calPercent5,
          calPercent6,
          calPercent7,
          calPercent8,
          calPercent9,
          calPercent10
        )
      )
    } else {
      clearUnitPercent()
      clearUnitPercentCalculation()
      dispatch(
        CompatibilityPageActions.setCheckCompatibilityAssessment(
          option,
          assessUnit1,
          assessUnit2,
          assessUnit3,
          assessUnit4,
          assessUnit5,
          assessUnit6,
          assessUnit7,
          assessUnit8,
          assessUnit9,
          assessUnit10,
          assessPercent1,
          assessPercent2,
          assessPercent3,
          assessPercent4,
          assessPercent5,
          assessPercent6,
          assessPercent7,
          assessPercent8,
          assessPercent9,
          assessPercent10,
          assessUnit11,
          assessUnit12,
          assessUnit13,
          assessUnit14,
          assessUnit15,
          assessUnit16,
          assessUnit17,
          assessUnit18,
          assessUnit19,
          assessUnit20,
          assessPercent11,
          assessPercent12,
          assessPercent13,
          assessPercent14,
          assessPercent15,
          assessPercent16,
          assessPercent17,
          assessPercent18,
          assessPercent19,
          assessPercent20,
          assessUnit21,
          assessUnit22,
          assessUnit23,
          assessUnit24,
          assessUnit25,
          assessUnit26,
          assessUnit27,
          assessUnit28,
          assessUnit29,
          assessUnit30,
          assessPercent21,
          assessPercent22,
          assessPercent23,
          assessPercent24,
          assessPercent25,
          assessPercent26,
          assessPercent27,
          assessPercent28,
          assessPercent29,
          assessPercent30
        )
      )
    }
  }

  const onClickReset = () => {
    if (option === compatibilityPageConstants.SEARCH_COMPATIBILITY.CRUDE_BLEND) {
      clearUnitPercent()
    } else if (option === compatibilityPageConstants.SEARCH_COMPATIBILITY.CALCULATION) {
      clearUnitPercentCalculation()
    } else if (option === compatibilityPageConstants.SEARCH_COMPATIBILITY.ASSESSMENT) {
      clearUnitPercentAssessment()
    }
  }

  const clearUnitPercent = () => {
    setInputUnit1(null)
    setInputUnit2(null)
    setInputUnit3(null)
    setInputUnit4(null)
    setInputUnit5(null)
    setInputUnit6(null)
    setInputUnit7(null)
    setInputUnit8(null)
    setInputUnit9(null)
    setInputUnit10(null)
    dispatch(CompatibilityPageActions.setCheckCompatibility({}))
  }

  const clearUnitPercentCalculation = () => {
    setCalUnit1(null)
    setCalPercent1('')
    setCalUnit2(null)
    setCalPercent2('')
    setCalUnit3(null)
    setCalPercent3('')
    setCalUnit4(null)
    setCalPercent4('')
    setCalUnit5(null)
    setCalPercent5('')
    setCalUnit6(null)
    setCalPercent6('')
    setCalUnit7(null)
    setCalPercent7('')
    setCalUnit8(null)
    setCalPercent8('')
    setCalUnit9(null)
    setCalPercent9('')
    setCalUnit10(null)
    setCalPercent10('')
    dispatch(CompatibilityPageActions.setCheckCompatibilityCalculation({}))
  }

  const clearUnitPercentAssessment = () => {
    setAssessUnit1(null)
    setAssessPercent1('')
    setAssessUnit2(null)
    setAssessPercent2('')
    setAssessUnit3(null)
    setAssessPercent3('')
    setAssessUnit4(null)
    setAssessPercent4('')
    setAssessUnit5(null)
    setAssessPercent5('')
    setAssessUnit6(null)
    setAssessPercent6('')
    setAssessUnit7(null)
    setAssessPercent7('')
    setAssessUnit8(null)
    setAssessPercent8('')
    setAssessUnit9(null)
    setAssessPercent9('')
    setAssessUnit10(null)
    setAssessPercent10('')
    setAssessUnit11(null)
    setAssessPercent11('')
    setAssessUnit12(null)
    setAssessPercent12('')
    setAssessUnit13(null)
    setAssessPercent13('')
    setAssessUnit14(null)
    setAssessPercent14('')
    setAssessUnit15(null)
    setAssessPercent15('')
    setAssessUnit16(null)
    setAssessPercent16('')
    setAssessUnit17(null)
    setAssessPercent17('')
    setAssessUnit18(null)
    setAssessPercent18('')
    setAssessUnit19(null)
    setAssessPercent19('')
    setAssessUnit20(null)
    setAssessPercent20('')
    setAssessUnit21(null)
    setAssessPercent21('')
    setAssessUnit22(null)
    setAssessPercent22('')
    setAssessUnit23(null)
    setAssessPercent23('')
    setAssessUnit24(null)
    setAssessPercent24('')
    setAssessUnit25(null)
    setAssessPercent25('')
    setAssessUnit26(null)
    setAssessPercent26('')
    setAssessUnit27(null)
    setAssessPercent27('')
    setAssessUnit28(null)
    setAssessPercent28('')
    setAssessUnit29(null)
    setAssessPercent29('')
    setAssessUnit30(null)
    setAssessPercent30('')
    dispatch(CompatibilityPageActions.setCheckCompatibilityAssessment({}))
  }

  const removeCrudeSetA = () => {
    setAssessUnit6(null)
    setAssessPercent6('')
    setAssessUnit7(null)
    setAssessPercent7('')
    setAssessUnit8(null)
    setAssessPercent8('')
    setAssessUnit9(null)
    setAssessPercent9('')
    setAssessUnit10(null)
    setAssessPercent10('')
    setAddCrudeSetA(!addCrudeSetA)
  }

  const removeCrudeSetB = () => {
    setAssessUnit16(null)
    setAssessPercent16('')
    setAssessUnit17(null)
    setAssessPercent17('')
    setAssessUnit18(null)
    setAssessPercent18('')
    setAssessUnit19(null)
    setAssessPercent19('')
    setAssessUnit20(null)
    setAssessPercent20('')
    setAddCrudeSetB(!addCrudeSetB)
  }

  const removeCrudeSetC = () => {
    setAssessUnit26(null)
    setAssessPercent26('')
    setAssessUnit27(null)
    setAssessPercent27('')
    setAssessUnit28(null)
    setAssessPercent28('')
    setAssessUnit29(null)
    setAssessPercent29('')
    setAssessUnit30(null)
    setAssessPercent30('')
    setAddCrudeSetC(!addCrudeSetC)
  }

  const hasResult = () => {
    if (option === compatibilityPageConstants.SEARCH_COMPATIBILITY.CRUDE_BLEND) {
      if (
        !_.isEmpty(labResultData.data) ||
        !_.isEmpty(shellAssessmentData.data) ||
        !_.isEmpty(processingHistoryData.data)
      ) {
        return true
      }
    } else if (option === compatibilityPageConstants.SEARCH_COMPATIBILITY.CALCULATION) {
      if (!_.isEmpty(compatCalculData.data)) {
        return true
      }
    } else if (option === compatibilityPageConstants.SEARCH_COMPATIBILITY.ASSESSMENT) {
      if (!_.isEmpty(thaiOilCompatAssessData.data)) {
        return true
      }
    }

    return false
  }

  const hasNotification = () => {
    if (option === compatibilityPageConstants.SEARCH_COMPATIBILITY.CRUDE_BLEND) {
      if(!_.isEmpty(labResultData.data)){
        if(!_.isEmpty(labResultData.data.latestIngestionDate) || labResultData.data.compatibilityCal == 'y'){
          return true
        }
      }
      return false
    }
  }

  return (
    <>
      <div className="page-compatibility mar-2">
        <div className="section">
          <Grid padded>
            <Grid.Row>
              <Grid.Column>
                <div>{renderRadioOption(option, onChangeRadioOption)}</div>
              </Grid.Column>
            </Grid.Row>
            {option === compatibilityPageConstants.SEARCH_COMPATIBILITY.CRUDE_BLEND && (
              <>
                <Grid.Row columns={5}>
                  <Grid.Column className="margin-b-16">
                    <div>
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={inputUnit1}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.inputUnit1 = false
                          setInputUnit1(selectedResult)
                        }}
                        placeholder="-*"
                        className={`input-unit ${errorInputs.inputUnit1 ? 'error' : ''}`}
                      />
                    </div>
                    <div
                      className={`field-error-label ${
                        errorInputs.inputUnit1 && _.isEmpty(errorInputs.lab) ? 'show' : ''
                      }`}
                    >
                      <Icon name="warning circle" />{' '}
                      {compatibilityPageConstants.SEARCH_VALIDATION.ERROR_MSG_1}
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div>
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={inputUnit2}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.inputUnit2 = false
                          setInputUnit2(selectedResult)
                        }}
                        placeholder="-*"
                        className={`input-unit ${errorInputs.inputUnit2 ? 'error' : ''}`}
                      />
                    </div>
                    <div
                      className={`field-error-label ${
                        errorInputs.inputUnit2 && _.isEmpty(errorInputs.lab) ? 'show' : ''
                      }`}
                    >
                      <Icon name="warning circle" />{' '}
                      {compatibilityPageConstants.SEARCH_VALIDATION.ERROR_MSG_1}
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div>
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={inputUnit3}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.inputUnit3 = false
                          setInputUnit3(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.inputUnit3 ? 'error' : ''}`}
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div>
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={inputUnit4}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.inputUnit4 = false
                          setInputUnit4(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.inputUnit4 ? 'error' : ''}`}
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div>
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={inputUnit5}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.inputUnit5 = false
                          setInputUnit5(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.inputUnit5 ? 'error' : ''}`}
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div>
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={inputUnit6}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.inputUnit6 = false
                          setInputUnit6(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.inputUnit6 ? 'error' : ''}`}
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div>
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={inputUnit7}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.inputUnit7 = false
                          setInputUnit7(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.inputUnit7 ? 'error' : ''}`}
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div>
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={inputUnit8}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.inputUnit8 = false
                          setInputUnit8(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.inputUnit8 ? 'error' : ''}`}
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div>
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={inputUnit9}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.inputUnit9 = false
                          setInputUnit9(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.inputUnit9 ? 'error' : ''}`}
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div>
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={inputUnit10}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.inputUnit10 = false
                          setInputUnit10(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.inputUnit10 ? 'error' : ''}`}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <div
                  className={`field-error-label error-msg-percentage ${
                    !_.isEmpty(errorInputs.lab) ? 'show' : ''
                  }`}
                >
                  <Icon name="warning circle" /> {errorInputs.lab}
                </div>
              </>
            )}
            {option === compatibilityPageConstants.SEARCH_COMPATIBILITY.CALCULATION && (
              <>
                <Grid.Row columns={5}>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={calUnit1}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.calUnit1 = false
                          setCalUnit1(selectedResult)
                        }}
                        placeholder="-*"
                        className={`input-unit ${errorInputs.calUnit1 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={calPercent1}
                        error={errorInputs.calPercent1}
                        onChange={({ target = {} }) => {
                          errorInputs.calPercent1 = false
                          const inputValue = parseFloat(target.value)
                          setCalPercent1(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--*"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                    <div
                      className={`field-error-label ${
                        errorInputs.calUnit1 && _.isEmpty(errorInputs.calculation) ? 'show' : ''
                      }`}
                    >
                      <Icon name="warning circle" />{' '}
                      {compatibilityPageConstants.SEARCH_VALIDATION.ERROR_MSG_1}
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={calUnit2}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.calUnit2 = false
                          setCalUnit2(selectedResult)
                        }}
                        placeholder="-*"
                        className={`input-unit ${errorInputs.calUnit2 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={calPercent2}
                        error={errorInputs.calPercent2}
                        onChange={({ target = {} }) => {
                          errorInputs.calPercent2 = false
                          const inputValue = parseFloat(target.value)
                          setCalPercent2(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--*"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                    <div
                      className={`field-error-label ${
                        errorInputs.calUnit2 && _.isEmpty(errorInputs.calculation) ? 'show' : ''
                      }`}
                    >
                      <Icon name="warning circle" />{' '}
                      {compatibilityPageConstants.SEARCH_VALIDATION.ERROR_MSG_1}
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={calUnit3}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.calUnit3 = false
                          setCalUnit3(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.calUnit3 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={calPercent3}
                        error={errorInputs.calPercent3}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setCalPercent3(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={calUnit4}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.calUnit4 = false
                          setCalUnit4(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.calUnit4 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={calPercent4}
                        error={errorInputs.calPercent4}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setCalPercent4(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={calUnit5}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.calUnit5 = false
                          setCalUnit5(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.calUnit5 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={calPercent5}
                        error={errorInputs.calPercent5}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setCalPercent5(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={calUnit6}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.calUnit6 = false
                          setCalUnit6(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.calUnit6 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={calPercent6}
                        error={errorInputs.calPercent6}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setCalPercent6(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={calUnit7}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.calUnit7 = false
                          setCalUnit7(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.calUnit7 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={calPercent7}
                        error={errorInputs.calPercent7}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setCalPercent7(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={calUnit8}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.calUnit8 = false
                          setCalUnit8(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.calUnit8 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={calPercent8}
                        error={errorInputs.calPercent8}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setCalPercent8(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={calUnit9}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.calUnit9 = false
                          setCalUnit9(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.calUnit9 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={calPercent9}
                        error={errorInputs.calPercent9}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setCalPercent9(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={calUnit10}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.calUnit10 = false
                          setCalUnit10(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.calUnit10 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={calPercent10}
                        error={errorInputs.calPercent10}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setCalPercent10(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <div
                  className={`field-error-label error-msg-percentage ${
                    !_.isEmpty(errorInputs.calculation) ? 'show' : ''
                  }`}
                >
                  <Icon name="warning circle" /> {errorInputs.calculation}
                </div>
              </>
            )}
            {option === compatibilityPageConstants.SEARCH_COMPATIBILITY.ASSESSMENT && (
              <>
                <Grid.Row>
                  <Grid.Column>
                    <Header size="small" textAlign="left" style={{ color: 'white' }}>
                      Set A
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={5}>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={assessUnit1}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.assessUnit1 = false
                          setAssessUnit1(selectedResult)
                        }}
                        placeholder="-*"
                        className={`input-unit ${errorInputs.assessUnit1 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={assessPercent1}
                        error={errorInputs.assessPercent1}
                        onChange={({ target = {} }) => {
                          errorInputs.assessPercent1 = false
                          const inputValue = parseFloat(target.value)
                          setAssessPercent1(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--*"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                    <div
                      className={`field-error-label ${
                        errorInputs.assessUnit1 && _.isEmpty(errorInputs.assessmentSetA)
                          ? 'show'
                          : ''
                      }`}
                    >
                      <Icon name="warning circle" />{' '}
                      {compatibilityPageConstants.SEARCH_VALIDATION.ERROR_MSG_1}
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={assessUnit2}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.assessUnit2 = false
                          setAssessUnit2(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.assessUnit2 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={assessPercent2}
                        error={errorInputs.assessPercent2}
                        onChange={({ target = {} }) => {
                          errorInputs.assessPercent2 = false
                          const inputValue = parseFloat(target.value)
                          setAssessPercent2(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                    <div
                      className={`field-error-label ${
                        errorInputs.assessUnit2 && _.isEmpty(errorInputs.assessmentSetA)
                          ? 'show'
                          : ''
                      }`}
                    >
                      <Icon name="warning circle" />{' '}
                      {compatibilityPageConstants.SEARCH_VALIDATION.ERROR_MSG_1}
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={assessUnit3}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.assessUnit3 = false
                          setAssessUnit3(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.assessUnit3 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={assessPercent3}
                        error={errorInputs.assessPercent3}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setAssessPercent3(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={assessUnit4}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.assessUnit4 = false
                          setAssessUnit4(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.assessUnit4 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={assessPercent4}
                        error={errorInputs.assessPercent4}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setAssessPercent4(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={assessUnit5}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.assessUnit5 = false
                          setAssessUnit5(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.assessUnit5 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={assessPercent5}
                        error={errorInputs.assessPercent5}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setAssessPercent5(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                  {addCrudeSetA && (
                    <>
                      <Grid.Column className="margin-b-16">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={assessUnit6}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.assessUnit6 = false
                              setAssessUnit6(selectedResult)
                            }}
                            placeholder="-"
                            className={`input-unit ${errorInputs.assessUnit6 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={assessPercent6}
                            error={errorInputs.assessPercent6}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setAssessPercent6(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>

                      <Grid.Column className="margin-b-16">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={assessUnit7}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.assessUnit7 = false
                              setAssessUnit7(selectedResult)
                            }}
                            placeholder="-"
                            className={`input-unit ${errorInputs.assessUnit7 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={assessPercent7}
                            error={errorInputs.assessPercent7}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setAssessPercent7(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>

                      <Grid.Column className="margin-b-16">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={assessUnit8}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.assessUnit8 = false
                              setAssessUnit8(selectedResult)
                            }}
                            placeholder="-"
                            className={`input-unit ${errorInputs.assessUnit8 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={assessPercent8}
                            error={errorInputs.assessPercent8}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setAssessPercent8(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>

                      <Grid.Column className="margin-b-16">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={assessUnit9}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.assessUnit9 = false
                              setAssessUnit9(selectedResult)
                            }}
                            placeholder="-"
                            className={`input-unit ${errorInputs.assessUnit9 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={assessPercent9}
                            error={errorInputs.assessPercent9}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setAssessPercent9(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>

                      <Grid.Column className="margin-b-16">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={assessUnit10}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.assessUnit10 = false
                              setAssessUnit10(selectedResult)
                            }}
                            placeholder="-"
                            className={`input-unit ${errorInputs.assessUnit10 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={assessPercent10}
                            error={errorInputs.assessPercent10}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setAssessPercent10(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>
                    </>
                  )}
                </Grid.Row>
                <div
                  className={`field-error-label error-msg-percentage ${
                    !_.isEmpty(errorInputs.assessmentSetA) ? 'show' : ''
                  }`}
                >
                  <Icon name="warning circle" /> {errorInputs.assessmentSetA}
                </div>

                <div
                  className={'add-crude'}
                  style={addCrudeSetA ? { marginRight: '182px' } : { marginRight: '155px' }}
                >
                  <Button onClick={removeCrudeSetA} className={`btn-secondary`}>
                    <Image src={addCrudeSetA ? DeleteRound : AddRound} verticalAlign="middle" />
                    {addCrudeSetA ? 'REMOVE CRUDE' : 'ADD CRUDE'}
                  </Button>
                </div>
                <Grid.Row>
                  <Grid.Column>
                    <Header size="small" textAlign="left" style={{ color: 'white' }}>
                      Set B
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={5}>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={assessUnit11}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.assessUnit11 = false
                          setAssessUnit11(selectedResult)
                        }}
                        placeholder="-*"
                        className={`input-unit ${errorInputs.assessUnit11 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={assessPercent11}
                        error={errorInputs.assessPercent11}
                        onChange={({ target = {} }) => {
                          errorInputs.assessPercent11 = false
                          const inputValue = parseFloat(target.value)
                          setAssessPercent11(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--*"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                    <div
                      className={`field-error-label ${
                        errorInputs.assessUnit11 && _.isEmpty(errorInputs.assessmentSetB)
                          ? 'show'
                          : ''
                      }`}
                    >
                      <Icon name="warning circle" />{' '}
                      {compatibilityPageConstants.SEARCH_VALIDATION.ERROR_MSG_1}
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={assessUnit12}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.assessUnit12 = false
                          setAssessUnit12(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.assessUnit12 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={assessPercent12}
                        error={errorInputs.assessPercent12}
                        onChange={({ target = {} }) => {
                          errorInputs.assessPercent12 = false
                          const inputValue = parseFloat(target.value)
                          setAssessPercent12(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                    <div
                      className={`field-error-label ${
                        errorInputs.assessUnit12 && _.isEmpty(errorInputs.assessmentSetB)
                          ? 'show'
                          : ''
                      }`}
                    >
                      <Icon name="warning circle" />{' '}
                      {compatibilityPageConstants.SEARCH_VALIDATION.ERROR_MSG_1}
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={assessUnit13}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.assessUnit13 = false
                          setAssessUnit13(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.assessUnit13 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={assessPercent13}
                        error={errorInputs.assessPercent13}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setAssessPercent13(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={assessUnit14}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.assessUnit14 = false
                          setAssessUnit14(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.assessUnit14 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={assessPercent14}
                        error={errorInputs.assessPercent14}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setAssessPercent14(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={assessUnit15}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.assessUnit15 = false
                          setAssessUnit15(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.assessUnit15 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={assessPercent15}
                        error={errorInputs.assessPercent15}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setAssessPercent15(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                  {addCrudeSetB && (
                    <>
                      <Grid.Column className="margin-b-16">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={assessUnit16}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.assessUnit16 = false
                              setAssessUnit16(selectedResult)
                            }}
                            placeholder="-"
                            className={`input-unit ${errorInputs.assessUnit16 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={assessPercent16}
                            error={errorInputs.assessPercent16}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setAssessPercent16(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>

                      <Grid.Column className="margin-b-16">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={assessUnit17}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.assessUnit17 = false
                              setAssessUnit17(selectedResult)
                            }}
                            placeholder="-"
                            className={`input-unit ${errorInputs.assessUnit17 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={assessPercent17}
                            error={errorInputs.assessPercent17}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setAssessPercent17(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>

                      <Grid.Column className="margin-b-16">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={assessUnit18}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.assessUnit18 = false
                              setAssessUnit18(selectedResult)
                            }}
                            placeholder="-"
                            className={`input-unit ${errorInputs.assessUnit18 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={assessPercent18}
                            error={errorInputs.assessPercent18}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setAssessPercent18(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>

                      <Grid.Column className="margin-b-16">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={assessUnit19}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.assessUnit19 = false
                              setAssessUnit19(selectedResult)
                            }}
                            placeholder="-"
                            className={`input-unit ${errorInputs.assessUnit19 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={assessPercent19}
                            error={errorInputs.assessPercent19}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setAssessPercent19(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>

                      <Grid.Column className="margin-b-16">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={assessUnit20}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.assessUnit20 = false
                              setAssessUnit20(selectedResult)
                            }}
                            placeholder="-"
                            className={`input-unit ${errorInputs.assessUnit20 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={assessPercent20}
                            error={errorInputs.assessPercent20}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setAssessPercent20(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>
                    </>
                  )}
                </Grid.Row>
                <div
                  className={`field-error-label error-msg-percentage ${
                    !_.isEmpty(errorInputs.assessmentSetB) ? 'show' : ''
                  }`}
                >
                  <Icon name="warning circle" /> {errorInputs.assessmentSetB}
                </div>

                <div
                  className={'add-crude'}
                  style={addCrudeSetB ? { marginRight: '182px' } : { marginRight: '155px' }}
                >
                  <Button onClick={removeCrudeSetB} className={`btn-secondary`}>
                    <Image src={addCrudeSetB ? DeleteRound : AddRound} verticalAlign="middle" />
                    {addCrudeSetB ? 'REMOVE CRUDE' : 'ADD CRUDE'}
                  </Button>
                </div>

                <Grid.Row>
                  <Grid.Column>
                    <Header size="small" textAlign="left" style={{ color: 'white' }}>
                      Set C
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={5}>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={assessUnit21}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.assessUnit21 = false
                          setAssessUnit21(selectedResult)
                        }}
                        placeholder="-*"
                        className={`input-unit ${errorInputs.assessUnit21 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={assessPercent21}
                        error={errorInputs.assessPercent21}
                        onChange={({ target = {} }) => {
                          errorInputs.assessPercent21 = false
                          const inputValue = parseFloat(target.value)
                          setAssessPercent21(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--*"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                    <div
                      className={`field-error-label ${
                        errorInputs.assessUnit21 && _.isEmpty(errorInputs.assessmentSetC)
                          ? 'show'
                          : ''
                      }`}
                    >
                      <Icon name="warning circle" />{' '}
                      {compatibilityPageConstants.SEARCH_VALIDATION.ERROR_MSG_1}
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={assessUnit22}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.assessUnit22 = false
                          setAssessUnit22(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.assessUnit22 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={assessPercent22}
                        error={errorInputs.assessPercent22}
                        onChange={({ target = {} }) => {
                          errorInputs.assessPercent22 = false
                          const inputValue = parseFloat(target.value)
                          setAssessPercent22(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                    <div
                      className={`field-error-label ${
                        errorInputs.assessUnit22 && _.isEmpty(errorInputs.assessmentSetC)
                          ? 'show'
                          : ''
                      }`}
                    >
                      <Icon name="warning circle" />{' '}
                      {compatibilityPageConstants.SEARCH_VALIDATION.ERROR_MSG_1}
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={assessUnit23}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.assessUnit23 = false
                          setAssessUnit23(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.assessUnit23 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={assessPercent23}
                        error={errorInputs.assessPercent23}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setAssessPercent23(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={assessUnit24}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.assessUnit24 = false
                          setAssessUnit24(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.assessUnit24 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={assessPercent24}
                        error={errorInputs.assessPercent24}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setAssessPercent24(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column className="margin-b-16">
                    <div className="unit-percent-group">
                      <SearchInput
                        displayProperty="title"
                        displayInListProperty="title"
                        defaultSelection={assessUnit25}
                        selectionOptions={crudeNames}
                        onSelectionUpdate={(selectedResult) => {
                          errorInputs.assessUnit25 = false
                          setAssessUnit25(selectedResult)
                        }}
                        placeholder="-"
                        className={`input-unit ${errorInputs.assessUnit25 ? 'error' : ''}`}
                      />
                      <Input
                        className="input-percent"
                        type="number"
                        min="1"
                        max="100"
                        value={assessPercent25}
                        error={errorInputs.assessPercent25}
                        onChange={({ target = {} }) => {
                          const inputValue = parseFloat(target.value)
                          setAssessPercent25(_.isNaN(inputValue) ? undefined : inputValue)
                        }}
                        placeholder="--"
                      ></Input>
                      <div className="input-percent-symbol">%</div>
                    </div>
                  </Grid.Column>
                  {addCrudeSetC && (
                    <>
                      <Grid.Column className="margin-b-16">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={assessUnit26}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.assessUnit26 = false
                              setAssessUnit26(selectedResult)
                            }}
                            placeholder="-"
                            className={`input-unit ${errorInputs.assessUnit26 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={assessPercent26}
                            error={errorInputs.assessPercent26}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setAssessPercent26(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>

                      <Grid.Column className="margin-b-16">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={assessUnit27}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.assessUnit27 = false
                              setAssessUnit27(selectedResult)
                            }}
                            placeholder="-"
                            className={`input-unit ${errorInputs.assessUnit27 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={assessPercent27}
                            error={errorInputs.assessPercent27}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setAssessPercent27(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>

                      <Grid.Column className="margin-b-16">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={assessUnit28}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.assessUnit28 = false
                              setAssessUnit28(selectedResult)
                            }}
                            placeholder="-"
                            className={`input-unit ${errorInputs.assessUnit28 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={assessPercent28}
                            error={errorInputs.assessPercent28}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setAssessPercent28(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>

                      <Grid.Column className="margin-b-16">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={assessUnit29}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.assessUnit29 = false
                              setAssessUnit29(selectedResult)
                            }}
                            placeholder="-"
                            className={`input-unit ${errorInputs.assessUnit29 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={assessPercent29}
                            error={errorInputs.assessPercent29}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setAssessPercent29(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>

                      <Grid.Column className="margin-b-16">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={assessUnit30}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.assessUnit30 = false
                              setAssessUnit30(selectedResult)
                            }}
                            placeholder="-"
                            className={`input-unit ${errorInputs.assessUnit30 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={assessPercent30}
                            error={errorInputs.assessPercent30}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setAssessPercent30(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>
                    </>
                  )}
                </Grid.Row>
                <div
                  className={`field-error-label error-msg-percentage ${
                    !_.isEmpty(errorInputs.assessmentSetC) ? 'show' : ''
                  }`}
                >
                  <Icon name="warning circle" /> {errorInputs.assessmentSetC}
                </div>
                <div
                  className={'add-crude'}
                  style={addCrudeSetC ? { marginRight: '182px' } : { marginRight: '155px' }}
                >
                  <Button onClick={removeCrudeSetC} className={`btn-secondary`}>
                    <Image src={addCrudeSetC ? DeleteRound : AddRound} verticalAlign="middle" />
                    {addCrudeSetC ? 'REMOVE CRUDE' : 'ADD CRUDE'}
                  </Button>
                </div>
              </>
            )}
            <Grid.Row>
              <Grid.Column textAlign="right">
                <Button className="btn-primary-outer" onClick={onClickReset}>
                  Reset
                </Button>
                <Button className="btn-primary" onClick={onClickCheckCompatibility}>
                  Check Compatibility
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <FetchStateWrapper
            id={CompatibilityPageActions.POST_CHECK_COMPATIBILITY_LAB}
          ></FetchStateWrapper>
          <FetchStateWrapper
            id={CompatibilityPageActions.POST_CHECK_COMPATIBILITY_SHELL}
          ></FetchStateWrapper>
          <FetchStateWrapper
            id={CompatibilityPageActions.POST_CHECK_COMPATIBILITY_PROCESSING}
          ></FetchStateWrapper>
          <FetchStateWrapper
            id={CompatibilityPageActions.POST_CHECK_COMPATIBILITY_CALCULATION}
          ></FetchStateWrapper>
          <FetchStateWrapper
            id={CompatibilityPageActions.POST_CHECK_COMPATIBILITY_ASSESSMENT}
          ></FetchStateWrapper>
        </div>
      </div>
      <div className="page-compatibility-result">
        {hasResult() ? (
          <>
            {(option === compatibilityPageConstants.SEARCH_COMPATIBILITY.CRUDE_BLEND && hasNotification()) && (
              <Grid padded>
                <Grid.Row verticalAlign="bottom">
                  <Grid.Column>
                    <Header size="small">
                      <Image
                        src={IconArror}
                        alt="collapse"
                        className={`icon-arror ${openNotification ? 'expand' : ''}`}
                        onClick={() => setOpenNotification(!openNotification)}
                      />
                      NOTIFICATION
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Collapse isOpened={openNotification}>
                      <div className={`crudeBlend-notification-group mar-x-4`}>
                        {!_.isEmpty(labResultData.data.latestIngestionDate) && (
                          <div className={`crudeBlend-notification-content`}>
                            <div className={`crudeBlend-notification-bullet`}></div>
                            <div className={`crudeBlend-notification-message`}>Latest Ingestion Date of Shell Diagram: <span className={`crudeBlend-notification-bold`}>{labResultData.data.latestIngestionDate}</span></div>
                          </div>
                        )}
                        {(labResultData.data.compatibilityCal == 'y') && (
                          <div className={`crudeBlend-notification-content`}>
                            <div className={`crudeBlend-notification-bullet`}></div>
                            <div className={`crudeBlend-notification-message`}>Compatibility Calculation (R) is <span className={`crudeBlend-notification-bold crudeBlend-notification-green`}>{labResultData.data.compatibilityCal == 'y'? 'available': ''}</span></div>
                          </div>
                        )}
                      </div>
                    </Collapse>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
            {option === compatibilityPageConstants.SEARCH_COMPATIBILITY.CRUDE_BLEND && (
              <Grid padded>
                <Grid.Row verticalAlign="bottom">
                  <Grid.Column>
                    <Header size="small">
                      <Image
                        src={IconArror}
                        alt="collapse"
                        className={`icon-arror ${openLabResult ? 'expand' : ''}`}
                        onClick={() => setOpenLabResult(!openLabResult)}
                      />
                      LAB RESULT
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Collapse isOpened={openLabResult}>
                      <LabResult data={labResultData} />
                    </Collapse>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row verticalAlign="bottom">
                  <Grid.Column>
                    <Header size="small">
                      <Image
                        src={IconArror}
                        alt="collapse"
                        className={`icon-arror ${openShellCompatibilityAssessment ? 'expand' : ''}`}
                        onClick={() =>
                          setOpenShellCompatibilityAssessment(!openShellCompatibilityAssessment)
                        }
                      />
                      SHELL COMPATIBILITY ASSESSMENT
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Collapse isOpened={openShellCompatibilityAssessment}>
                      <ShellCompatibility shellAssessmentData={shellAssessmentData} />
                    </Collapse>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row verticalAlign="bottom">
                  <Grid.Column>
                    <Header size="small">
                      <Image
                        src={IconArror}
                        alt="collapse"
                        className={`icon-arror ${openProcessingHistory ? 'expand' : ''}`}
                        onClick={() => setOpenProcessingHistory(!openProcessingHistory)}
                      />
                      HISTORICAL DATA
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Collapse isOpened={openProcessingHistory}>
                      <ProcessingHistory data={processingHistoryData} />
                    </Collapse>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
            {option === compatibilityPageConstants.SEARCH_COMPATIBILITY.CALCULATION && (
              <Grid padded>
                <Grid.Row verticalAlign="bottom">
                  <Grid.Column>
                    <Header className={`mar-left-2`} size="small" textAlign="left">
                      COMPATIBILITY CALCULATION
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <CompatibilityCalculation data={compatCalculData} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
            {option === compatibilityPageConstants.SEARCH_COMPATIBILITY.ASSESSMENT && (
              <Grid padded>
                <Grid.Row verticalAlign="bottom">
                  <Grid.Column>
                    <Header className={`mar-left-2`} size="small" textAlign="left">
                      THAIOIL COMPATIBILITY ASSESSMENT
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <ThaiOilCompatibilityAssessment data={thaiOilCompatAssessData} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
          </>
        ) : (
          <div className={`flex justify-center section-result-message`}>
            <ResultContainer
              label="Please input blend and parameters above to check compatibility"
              icon={getSearchIcon()}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default CompatibilityPage
