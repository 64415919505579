import _ from 'lodash'
import * as RisksActions from '../actions/actionRisks'
import { startOfMonth, endOfMonth } from 'date-fns'

const defaultState = {
  isCargoLockInErrors: false,
  risksPage: {
    data: [],
    similarCrude: [],
    singleCrudeHeader: {},
    customBlendHeader: {},
    isFavourite: '',
  },
  freeformChart: {
    isSearchByCrudeBlendDate: true,
    data: [],
    freeformUnit: 'CDU-3',
    defaultPeriod: {
      startDate: new Date().setMonth(new Date().getMonth() - 12),
      endDate: endOfMonth(new Date()),
      // startDate: startOfMonth(new Date()),
      // endDate: endOfMonth(new Date()),
    },
    period: {
      startDate: new Date().setMonth(new Date().getMonth() - 12),
      endDate: endOfMonth(new Date()),
      // startDate: startOfMonth(new Date()),
      // endDate: endOfMonth(new Date()),
      key: 'selection',
    },
    dataSource: 'APS Data',
    unit: '',
    property1: '',
    property2: '',
    property3: '',
    property4: '',
    property5: '',
    freeformData: [],
    freeformDataSource1: '',
    freeformDataSource2: '',
    freeformDataSource3: '',
    freeformDataSource4: '',
    freeformDataSource5: '',
    freeformUnit1: '',
    freeformUnit2: '',
    freeformUnit3: '',
    freeformUnit4: '',
    freeformUnit5: '',
    freeformStream1: '',
    freeformStream2: '',
    freeformStream3: '',
    freeformStream4: '',
    freeformStream5: '',
    freeformProperty1: '',
    freeformProperty2: '',
    freeformProperty3: '',
    freeformProperty4: '',
    freeformProperty5: '',
  },
  crudeBlendChart: {
    data: [],
    selectedData: [],
    unit: 'CDU-3',
    unit1: 'CDU-3',
    processUnit: 'CDU-1',
    dataSource: 'APS Data',
    cduFeed1: 'Total Sulfur',
    cduFeed2: 'Total distillate',
    cduFeed3: 'Intake',
    cduFeed4: '%Kerosene',
    cduFeed5: 'Total Acid Number',
    cduFeedValue1: '',
    cduFeedValue2: '',
    cduFeedValue3: '',
    cduFeedValue4: '',
    cduFeedValue5: '',
    downstreamValue1: '',
    downstreamValue2: '',
    downstreamValue3: '',
    downstreamValue4: '',
    downstreamValue5: '',
    fraction1: '',
    fraction3: '',
    fraction2: '',
    fraction4: '',
    fraction5: '',
    properties1: '',
    properties2: '',
    properties3: '',
    properties4: '',
    properties5: '',
    defaultPeriod: {
      startDate: new Date().setMonth(new Date().getMonth() - 12),
      endDate: endOfMonth(new Date()),
      // startDate: startOfMonth(new Date()),
      // endDate: endOfMonth(new Date()),
    },
    period: {
      startDate: new Date().setMonth(new Date().getMonth() - 12),
      endDate: endOfMonth(new Date()),
      // startDate: startOfMonth(new Date()),
      // endDate: endOfMonth(new Date()),
      key: 'selection',
    },
  },
}

const REDUCERS = {
  [RisksActions.RISKS_RESULT_TABLE]: (state, { payload }) => {
    return {
      ...state,
      risksPage: {
        ...state.risksPage,
        data: payload,
      },
    }
  },

  [RisksActions.RISKS_SIMILIAR_CRUDE]: (state, { payload }) => {
    return {
      ...state,
      risksPage: {
        ...state.risksPage,
        similarCrude: payload,
      },
    }
  },

  [RisksActions.RISKS_SINGLE_CRUDE_HEADER]: (state, { payload }) => {
    return {
      ...state,
      risksPage: {
        ...state.risksPage,
        singleCrudeHeader: {
          data: payload,
        },
      },
    }
  },

  [RisksActions.RISKS_CUSTOM_BLEND_HEADER]: (state, { payload }) => {
    return {
      ...state,
      risksPage: {
        ...state.risksPage,
        customBlendHeader: {
          data: payload,
        },
      },
    }
  },

  [RisksActions.POST_FAVOURITE_ICON]: (state, { payload }) => {
    return {
      ...state,
      risksPage: {
        ...state.risksPage,
        isFavourite: payload,
      },
    }
  },

  [RisksActions.POST_RISK_CRUDE_BLEND_CHART]: (state, { payload }) => {
    return {
      ...state,
      crudeBlendChart: {
        ...state.crudeBlendChart,
        data: payload,
      },
    }
  },

  [RisksActions.FREEFORM_TREND_CHART]: (state, { payload }) => {
    return {
      ...state,
      freeformChart: {
        ...state.freeformChart,
        data: payload,
      },
    }
  },
}
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}
export const RISKS_PAGE_REDUCER = 'risksPage'
