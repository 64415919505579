import _, { property } from 'lodash'
import {
  postCrudeBlendChart,
  postCrudeBlendCduValue1,
  postCrudeBlendCduValue2,
  postCrudeBlendCduValue3,
  postCrudeBlendCduValue4,
  postCrudeBlendCduValue5,
  postCrudeBlendDownstreamValue1,
  postCrudeBlendDownstreamValue2,
  postCrudeBlendDownstreamValue3,
  postCrudeBlendDownstreamValue4,
  postCrudeBlendDownstreamValue5,
  postSevenDayCrudeBlendChart,
  postSingleCrudeChart,
  postParameterTrendChart1,
  postParameterTrendChart2,
  postCorrosionParamterTrendChart,
  postCorrosionBlendComposition,
  postCrudeArrivalChart,
  postRecentSearch,
  postFavourites,
  postNotification,
  postSaveNotificationStatus,
  postCamLimitNotification,
  postHighlights
} from '../services/serviceLanding'

import * as services from '../services/serviceLanding'

import { fetchStart, fetchSuccess, fetchError } from './common/actionFetch'

// CRUDE BLEND CHART

export const SET_CRUDE_BLEND_SELECTED_CHART_DATA = 'SET_CRUDE_BLEND_SELECTED_CHART_DATA'
export const setCrudeBlendSelectedChartData = (selectedData) => {
  return (dispatch) => {
    dispatch({
      type: SET_CRUDE_BLEND_SELECTED_CHART_DATA,
      payload: selectedData,
    })
  }
}

export const SET_CRUDE_BLEND_CHART_DATE = 'SET_CRUDE_BLEND_CHART_DATE'
export const setCrudeBlendChartDate = (period) => {
  return (dispatch) => {
    dispatch({
      type: SET_CRUDE_BLEND_CHART_DATE,
      payload: period,
    })
  }
}

export const POST_CRUDE_BLEND_CHART = 'POST_CRUDE_BLEND_CHART'
export const setCrudeBlendChart = (period, unit, currentPersona) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_CRUDE_BLEND_CHART))
    postCrudeBlendChart(period, unit, currentPersona)
      .then((response) =>
        dispatch({
          type: POST_CRUDE_BLEND_CHART,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_CRUDE_BLEND_CHART)))
      .catch(() => dispatch(fetchError(POST_CRUDE_BLEND_CHART)))
  }
}

export const POST_CRUDE_BLEND_CDU_VALUE_1 = 'POST_CRUDE_BLEND_CDU_VALUE_1'
export const setCrudeBlendCduValue1 = (cduFeed1, unit, dataSource, selectedDate) => {
  return (dispatch) =>
    postCrudeBlendCduValue1(cduFeed1, unit, dataSource, selectedDate)
      .then((response) =>
        dispatch({
          type: POST_CRUDE_BLEND_CDU_VALUE_1,
          payload: response,
        })
      )
      .catch(console.error)
}

export const POST_CRUDE_BLEND_CDU_VALUE_2 = 'POST_CRUDE_BLEND_CDU_VALUE_2'
export const setCrudeBlendCduValue2 = (cduFeed2, unit, dataSource, selectedDate) => {
  return (dispatch) =>
    postCrudeBlendCduValue2(cduFeed2, unit, dataSource, selectedDate)
      .then((response) =>
        dispatch({
          type: POST_CRUDE_BLEND_CDU_VALUE_2,
          payload: response,
        })
      )
      .catch(console.error)
}

export const POST_CRUDE_BLEND_CDU_VALUE_3 = 'POST_CRUDE_BLEND_CDU_VALUE_3'
export const setCrudeBlendCduValue3 = (cduFeed3, unit, dataSource, selectedDate) => {
  return (dispatch) =>
    postCrudeBlendCduValue3(cduFeed3, unit, dataSource, selectedDate)
      .then((response) =>
        dispatch({
          type: POST_CRUDE_BLEND_CDU_VALUE_3,
          payload: response,
        })
      )
      .catch(console.error)
}

export const POST_CRUDE_BLEND_CDU_VALUE_4 = 'POST_CRUDE_BLEND_CDU_VALUE_4'
export const setCrudeBlendCduValue4 = (cduFeed4, unit, dataSource, selectedDate) => {
  return (dispatch) =>
    postCrudeBlendCduValue4(cduFeed4, unit, dataSource, selectedDate)
      .then((response) =>
        dispatch({
          type: POST_CRUDE_BLEND_CDU_VALUE_4,
          payload: response,
        })
      )
      .catch(console.error)
}

export const POST_CRUDE_BLEND_CDU_VALUE_5 = 'POST_CRUDE_BLEND_CDU_VALUE_5'
export const setCrudeBlendCduValue5 = (cduFeed5, unit, dataSource, selectedDate) => {
  return (dispatch) =>
    postCrudeBlendCduValue5(cduFeed5, unit, dataSource, selectedDate)
      .then((response) =>
        dispatch({
          type: POST_CRUDE_BLEND_CDU_VALUE_5,
          payload: response,
        })
      )
      .catch(console.error)
}

// Downstream value

export const POST_CRUDE_BLEND_DOWNSTREAM_VALUE_1 = 'POST_CRUDE_BLEND_DOWNSTREAM_VALUE_1'
export const setCrudeBlendDownstreamValue1 = (
  fraction1,
  properties1,
  unit1,
  dataSource,
  selectedDate
) => {
  return (dispatch) =>
    postCrudeBlendDownstreamValue1(fraction1, properties1, unit1, dataSource, selectedDate)
      .then((response) =>
        dispatch({
          type: POST_CRUDE_BLEND_DOWNSTREAM_VALUE_1,
          payload: response,
        })
      )
      .catch(console.error)
}

export const POST_CRUDE_BLEND_DOWNSTREAM_VALUE_2 = 'POST_CRUDE_BLEND_DOWNSTREAM_VALUE_2'
export const setCrudeBlendDownstreamValue2 = (
  fraction2,
  properties2,
  unit1,
  dataSource,
  selectedDate
) => {
  return (dispatch) =>
    postCrudeBlendDownstreamValue2(fraction2, properties2, unit1, dataSource, selectedDate)
      .then((response) =>
        dispatch({
          type: POST_CRUDE_BLEND_DOWNSTREAM_VALUE_2,
          payload: response,
        })
      )
      .catch(console.error)
}

export const POST_CRUDE_BLEND_DOWNSTREAM_VALUE_3 = 'POST_CRUDE_BLEND_DOWNSTREAM_VALUE_3'
export const setCrudeBlendDownstreamValue3 = (
  fraction3,
  properties3,
  unit1,
  dataSource,
  selectedDate
) => {
  return (dispatch) =>
    postCrudeBlendDownstreamValue3(fraction3, properties3, unit1, dataSource, selectedDate)
      .then((response) =>
        dispatch({
          type: POST_CRUDE_BLEND_DOWNSTREAM_VALUE_3,
          payload: response,
        })
      )
      .catch(console.error)
}

export const POST_CRUDE_BLEND_DOWNSTREAM_VALUE_4 = 'POST_CRUDE_BLEND_DOWNSTREAM_VALUE_4'
export const setCrudeBlendDownstreamValue4 = (
  fraction4,
  properties4,
  unit1,
  dataSource,
  selectedDate
) => {
  return (dispatch) =>
    postCrudeBlendDownstreamValue4(fraction4, properties4, unit1, dataSource, selectedDate)
      .then((response) =>
        dispatch({
          type: POST_CRUDE_BLEND_DOWNSTREAM_VALUE_4,
          payload: response,
        })
      )
      .catch(console.error)
}

export const POST_CRUDE_BLEND_DOWNSTREAM_VALUE_5 = 'POST_CRUDE_BLEND_DOWNSTREAM_VALUE_5'
export const setCrudeBlendDownstreamValue5 = (
  fraction5,
  properties5,
  unit1,
  dataSource,
  selectedDate
) => {
  return (dispatch) =>
    postCrudeBlendDownstreamValue5(fraction5, properties5, unit1, dataSource, selectedDate)
      .then((response) =>
        dispatch({
          type: POST_CRUDE_BLEND_DOWNSTREAM_VALUE_5,
          payload: response,
        })
      )
      .catch(console.error)
}

// PARAMETER TREND CHART

export const SET_PARAMETER_TREND_CHART_DATE = 'SET_PARAMETER_TREND_CHART_DATE'
export const setParameterTrendChartDate = (period) => {
  return (dispatch) => {
    dispatch({
      type: SET_PARAMETER_TREND_CHART_DATE,
      payload: period,
    })
  }
}

export const POST_PARAMETER_TREND_CHART_1 = 'POST_PARAMETER_TREND_CHART_1'
export const setParameterTrendChart1 = (period, unit1, fraction1, properties1, dataSource) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_PARAMETER_TREND_CHART_1))
    postParameterTrendChart1(period, unit1, fraction1, properties1, dataSource)
      .then((response) =>
        dispatch({
          type: POST_PARAMETER_TREND_CHART_1,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_PARAMETER_TREND_CHART_1)))
      .catch(() => dispatch(fetchError(POST_PARAMETER_TREND_CHART_1)))
  }
}

export const POST_PARAMETER_TREND_CHART_2 = 'POST_PARAMETER_TREND_CHART_2'
export const setParameterTrendChart2 = (period, unit2, fraction2, properties2, dataSource) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_PARAMETER_TREND_CHART_2))
    postParameterTrendChart2(period, unit2, fraction2, properties2, dataSource)
      .then((response) =>
        dispatch({
          type: POST_PARAMETER_TREND_CHART_2,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_PARAMETER_TREND_CHART_2)))
      .catch(() => dispatch(fetchError(POST_PARAMETER_TREND_CHART_2)))
  }
}

// SINGLE CRUDE CHART

export const SET_SINGLE_CRUDE_CHART = 'SET_SINGLE_CRUDE_CHART'
export const setSingleCrudeChartDate = (period) => {
  return (dispatch) => {
    dispatch({
      type: SET_SINGLE_CRUDE_CHART,
      payload: period,
    })
  }
}

export const POST_SINGLE_CRUDE_CHART = 'POST_SINGLE_CRUDE_CHART'
export const setSingleCrudeChart = (period, chartType, unit, crudeNameAbbreviation, property) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_SINGLE_CRUDE_CHART))
    postSingleCrudeChart(period, chartType, unit, crudeNameAbbreviation, property)
      .then((response) =>
        dispatch({
          type: POST_SINGLE_CRUDE_CHART,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_SINGLE_CRUDE_CHART)))
      .catch(() => dispatch(fetchError(POST_SINGLE_CRUDE_CHART)))
  }
}

// CORROSION PARAMTER TREND

export const SET_CORROSION_PARAMETER_DRILLDOWN = 'SET_CORROSION_PARAMETER_DRILLDOWN'
export const setCorrosionParameterDrilldown = (isDdDupes) => {
  return (dispatch) => {
    dispatch({
      type: SET_CORROSION_PARAMETER_DRILLDOWN,
      payload: isDdDupes,
    })
  }
}

export const SET_CORROSION_PARAMETER_TREND_CHART_DATE = 'SET_CORROSION_PARAMETER_TREND_CHART_DATE'
export const setCorrosionParameterTrendChartDate = (period) => {
  return (dispatch) => {
    dispatch({
      type: SET_CORROSION_PARAMETER_TREND_CHART_DATE,
      payload: period,
    })
  }
}

export const POST_CORROSION_PARAMTER_TREND_CHART = 'POST_CORROSION_PARAMTER_TREND_CHART'
export const setCorrosionParamterTrend = (period, unit, material) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_CORROSION_PARAMTER_TREND_CHART))
    postCorrosionParamterTrendChart(period, unit, material)
      .then((response) =>
        dispatch({
          type: POST_CORROSION_PARAMTER_TREND_CHART,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_CORROSION_PARAMTER_TREND_CHART)))
      .catch(() => dispatch(fetchError(POST_CORROSION_PARAMTER_TREND_CHART)))
  }
}

export const SET_CORROSION_BLEND_MONTH = 'SET_CORROSION_BLEND_MONTH'
export const setCorrosionBlendMonth = (month) => {
  return (dispatch) => {
    dispatch({
      type: SET_CORROSION_BLEND_MONTH,
      payload: month,
    })
  }
}

export const POST_CORROSION_BLEND_COMPOSITION = 'POST_CORROSION_BLEND_COMPOSITION'
export const setCorrosionBlendComposition = (period, unit, month) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_CORROSION_BLEND_COMPOSITION))
    postCorrosionBlendComposition(period, unit, month)
      .then((response) =>
        dispatch({
          type: POST_CORROSION_BLEND_COMPOSITION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_CORROSION_BLEND_COMPOSITION)))
      .catch(() => dispatch(fetchError(POST_CORROSION_BLEND_COMPOSITION)))
  }
}

// SEVEN DAY CRUDE BLEND CHART

export const SET_SEVEN_DAY_SELECTED_CHART_DATA = 'SET_SEVEN_DAY_SELECTED_CHART_DATA'
export const setSevenDaySelectedChartData = (selectedData) => {
  return (dispatch) => {
    dispatch({
      type: SET_SEVEN_DAY_SELECTED_CHART_DATA,
      payload: selectedData,
    })
  }
}

export const POST_SEVEN_DAY_CRUDE_BLEND_CHART = 'POST_SEVEN_DAY_CRUDE_BLEND_CHART'
export const setSevenDayCrudeBlendChart = (period, unit, currentPersona) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_SEVEN_DAY_CRUDE_BLEND_CHART))
    postSevenDayCrudeBlendChart(period, unit, currentPersona)
      .then((response) =>
        dispatch({
          type: POST_SEVEN_DAY_CRUDE_BLEND_CHART,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_SEVEN_DAY_CRUDE_BLEND_CHART)))
      .catch(() => dispatch(fetchError(POST_SEVEN_DAY_CRUDE_BLEND_CHART)))
  }
}

// SET DATE

export const SET_SEVEN_DAY_CRUDE_BLEND_CHART_DATE = 'SET_SEVEN_DAY_CRUDE_BLEND_CHART_DATE'
export const setSevenDayCrudeBlendChartDate = (period) => {
  return (dispatch) => {
    dispatch({
      type: SET_SEVEN_DAY_CRUDE_BLEND_CHART_DATE,
      payload: period,
    })
  }
}

export const SET_CRUDE_ARRIVAL_CHART_DATE = 'SET_CRUDE_ARRIVAL_CHART_DATE'
export const setCrudeArrivalChartDate = (period) => {
  return (dispatch) => {
    dispatch({
      type: SET_CRUDE_ARRIVAL_CHART_DATE,
      payload: period,
    })
  }
}

// Crude Arrival

export const POST_CRUDE_ARRIVAL_CHART = 'POST_CRUDE_ARRIVAL_CHART'
export const setCrudeArrivalChart = (period, materialType) => {
  return (dispatch) =>
    postCrudeArrivalChart(period, materialType)
      .then((response) =>
        dispatch({
          type: POST_CRUDE_ARRIVAL_CHART,
          payload: response,
        })
      )
      .catch(console.error)
}

// Recent Search

export const POST_RECENT_SEARCH = 'POST_RECENT_SEARCH'
export const setRecentSearch = (currentPersona) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_RECENT_SEARCH))
    postRecentSearch(currentPersona)
      .then((response) =>
        dispatch({
          type: POST_RECENT_SEARCH,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_RECENT_SEARCH)))
      .catch(() => dispatch(fetchError(POST_RECENT_SEARCH)))
  }
}

// Favourites

export const POST_FAVOURITES = 'POST_FAVOURITES'
export const setFavourites = (currentPersona) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_FAVOURITES))
    postFavourites(currentPersona)
      .then((response) =>
        dispatch({
          type: POST_FAVOURITES,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_FAVOURITES)))
      .catch(() => dispatch(fetchError(POST_FAVOURITES)))
  }
}

// Notification

export const POST_NOTIFICATION = 'POST_NOTIFICATION'
export const setNotification = (currentPersona) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_NOTIFICATION))
    postNotification(currentPersona)
      .then((response) =>
        dispatch({
          type: POST_NOTIFICATION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_NOTIFICATION)))
      .catch(() => dispatch(fetchError(POST_NOTIFICATION)))
  }
}

export const POST_SAVE_NOTIFICATION = 'POST_SAVE_NOTIFICATION'
export const setSaveNotificationStatus = (id) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_SAVE_NOTIFICATION))
    postSaveNotificationStatus(id)
      .then((response) =>
        dispatch({
          type: POST_SAVE_NOTIFICATION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_SAVE_NOTIFICATION)))
      .catch(() => dispatch(fetchError(POST_SAVE_NOTIFICATION)))
  }
}

// Cam Limit Notification

export const POST_CAM_LIMIT_NOTIFICATION = 'POST_CAM_LIMIT_NOTIFICATION'
export const setCamLimitNotification = (cduFeedUnit, period, dataSource) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_CAM_LIMIT_NOTIFICATION))
    postCamLimitNotification(cduFeedUnit, period, dataSource)
      .then((response) =>
        dispatch({
          type: POST_CAM_LIMIT_NOTIFICATION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_CAM_LIMIT_NOTIFICATION)))
      .catch(() => dispatch(fetchError(POST_CAM_LIMIT_NOTIFICATION)))
  }
}

// Highlights

export const POST_HIGHLIGHTS = 'POST_HIGHLIGHTS'
export const setHighlights = (currentPersona, period, unit) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_HIGHLIGHTS))
    postHighlights(currentPersona, period, unit)
      .then((response) =>
        dispatch({
          type: POST_HIGHLIGHTS,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_HIGHLIGHTS)))
      .catch(() => dispatch(fetchError(POST_HIGHLIGHTS)))
  }
}

// Freeform 

export const FREEFORM_TREND_CHART = 'FREEFORM_TREND_CHART'
export const setFreeformTrend = (
  currentPersona,
  period,
  color1,
  color2,
  color3,
  color4,
  color5,
  dataSource1,
  dataSource2,
  dataSource3,
  dataSource4,
  dataSource5,
  unit1,
  unit2,
  unit3,
  unit4,
  unit5,
  stream1,
  stream2,
  stream3,
  stream4,
  stream5,
  property1,
  property2,
  property3,
  property4,
  property5
) => {
  return (dispatch) => {
    dispatch(fetchStart(FREEFORM_TREND_CHART))
    services
      .postFreeformTrend(
        currentPersona,
        period,
        color1,
        color2,
        color3,
        color4,
        color5,
        dataSource1,
        dataSource2,
        dataSource3,
        dataSource4,
        dataSource5,
        unit1,
        unit2,
        unit3,
        unit4,
        unit5,
        stream1,
        stream2,
        stream3,
        stream4,
        stream5,
        property1,
        property2,
        property3,
        property4,
        property5
      )
      .then((response) =>
        dispatch({
          type: FREEFORM_TREND_CHART,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(FREEFORM_TREND_CHART)))
      .catch(() => dispatch(fetchError(FREEFORM_TREND_CHART)))
  }
}