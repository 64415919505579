import { LAMBDA_API_INSTANCE } from './serverInstances/lambdaServer'
import moment from 'moment'
import _ from 'lodash'

import * as corrosionPageConstants from '../containers/views/corrosion-rate-page/helpers/constants'

const CONTROLLER_NAME = '/corrosion'

export const postAPIStandard = ({
  searchType,
  crudeType,
  unit,
  operatingUnit,
  crudeCode,
  blendDate,
  blendComposition,
  feedRate,
  washWater,
  material,
  fraction,
  temperature,
}) => {
  const formatSearchObject = _.map(blendComposition, (item) =>
    !_.isUndefined(item.crudeCode) ? { crudeCode: item.crudeCode, percentage: item.percentage } : {}
  )
  const filterSearchObject = _.filter(formatSearchObject, (v) => _.keys(v).length !== 0)
  const postBody = {
    searchType,
    crudeType,
    unit,
    operationUnit: operatingUnit,
    crudeCode,
    blendDate: _.isNull(blendDate) ? undefined : moment(blendDate).format('YYYY-MM-DD').toString(),
    blendComposition: filterSearchObject,
    feedRate,
    washWater,
    material,
    fraction,
    temperature,
  }
  return LAMBDA_API_INSTANCE.post(`${CONTROLLER_NAME}/apiStandardTable`, postBody)
}

export const postSummaryTable = ({
  searchType,
  crudeType,
  crudeCode,
  unit,
  blendDate,
  blendComposition,
  feedRate,
  washWater,
}) => {
  const formatSearchObject = _.map(blendComposition, (item) =>
    !_.isUndefined(item.crudeCode) ? { crudeCode: item.crudeCode, percentage: item.percentage } : {}
  )
  const filterSearchObject = _.filter(formatSearchObject, (v) => _.keys(v).length !== 0)
  const postBody = {
    searchType,
    crudeType,
    crudeCode,
    unit,
    blendDate: _.isNull(blendDate) ? undefined : moment(blendDate).format('YYYY-MM-DD').toString(),
    blendComposition: filterSearchObject,
    feedRate,
    washWater,
  }
  return LAMBDA_API_INSTANCE.post(`${CONTROLLER_NAME}/summaryTable`, postBody)
}

export const postSummaryTableSimulateNSave = ({
  saveType, //HT or NS
  isSave,
  summaryTable,
  crudeType,
}) => {
  const postBody = {
    saveType,
    isSave,
    summaryTable,
    crudeType,
  }
  return LAMBDA_API_INSTANCE.post(`${CONTROLLER_NAME}/simulateNSave`, postBody)
}

export const postHistoricalData = ({
  crudeType,
  crudeCode,
  blendDate,
  blendComposition,
  isBlendDate,
  operatingUnit,
}) => {
  const formatSearchObject = _.map(blendComposition, (item) =>
    !_.isUndefined(item.crudeCode) ? { crudeCode: item.crudeCode } : {}
  )
  const filterSearchObject = _.filter(formatSearchObject, (v) => _.keys(v).length !== 0)

  const bodySingleCrude = {
    crudeCode: crudeCode,
    searchType: 'Single',
    mode: 'filter',
    persona: 'ENTS',
    component: 'corrosion-risk-single',
    operationUnit: operatingUnit,
  }

  const bodyBlendDate = {
    searchType: 'Blend',
    blendDate: _.isNull(blendDate) ? undefined : moment(blendDate).format('YYYY-MM-DD').toString(),
    mode: 'filter',
    persona: 'ENTS',
    component: 'corrosion-risk-blend-date',
    operationUnit: operatingUnit,
  }

  const bodyBlendComposition = {
    searchType: 'Blend',
    blendComposition: blendComposition,
    mode: 'filter',
    persona: 'ENTS',
    component: 'corrosion-risk-blend-composition',
    operationUnit: operatingUnit,
  }

  return LAMBDA_API_INSTANCE.post(
    `risk/searchResults`,

    crudeType === corrosionPageConstants.SEARCH_CRUDE_MODE.SINGLE_CRUDE
      ? bodySingleCrude
      : isBlendDate
      ? bodyBlendDate
      : bodyBlendComposition
  )
}
