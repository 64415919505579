import React, { useState, useCallback, useReducer, useEffect, useRef } from "react";
import _ from 'lodash'

// Components
import ChartCdu1 from './components/chartCdu1';
import ChartCdu2 from './components/chartCdu2';
import ChartCdu3 from './components/chartCdu3';

const ProcessingPage = (props) => {
    //const [state, dispatch] = useReducer(exampleReducer, initialState)
    //const [search, setSearch] = useState("intelligent")

    return (
        <div className="main-container page-processing">
            <ChartCdu1 />
            <ChartCdu2 />
            <ChartCdu3 />
        </div>
    )
}


export default ProcessingPage;