import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { endOfMonth } from 'date-fns'

import { Button, Popup, Icon } from 'semantic-ui-react'

// Date Components
import { DateRange } from 'react-date-range'
import DatePicker from 'react-datepicker'

const PeriodPicker = (props) => {
  const { period, setPeriod, type = 'dateRange', className, disabled, maxYear } = props

  const endMonth = endOfMonth(new Date())
  const endOfNextMonth = moment(endMonth).add(1, 'M')._d
  const monthPlusOneYear = moment(endMonth).add(1, 'Y')._d

  const minDate = moment('2010-08-01')._d

  return (
    <div className={`period-picker ${className}`}>
      {type === 'dateRange' && (
        <Popup
          trigger={
            <Button className={`date-range-picker-custom`} disabled={disabled}>
              {_.map(period, (a) => (
                <div className={`flex center`}>
                  <span>
                    {_.isNull(a.endDate) ||
                    _.isEqual(a.startDate, a.endDate) ||
                    _.isUndefined(a.endDate)
                      ? _.upperCase(moment(a.startDate).format('DD MMM YYYY'))
                      : _.upperCase(moment(a.startDate).format('DD MMM YYYY')) +
                        ' - ' +
                        _.upperCase(moment(a.endDate).format('DD MMM YYYY'))}
                  </span>
                  <Icon size="large" color="white" name="caret down" />
                </div>
              ))}
            </Button>
          }
          content={
            <div>
              <DateRange
                editableDateInputs={false}
                showMonthAndYearPickers={true}
                minDate={minDate}
                maxDate={endOfNextMonth}
                onChange={(item) => setPeriod([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={period}
                showDateDisplay={false}
              />
            </div>
          }
          on="click"
          hideOnScroll={true}
          className={`bg-blue period-picker`}
          position="bottom right"
        />
      )}
      {type === 'year' && (
        <Popup
          trigger={
            <Button className={`date-range-picker-custom`}>
              <div className={`flex center`}>
                <span>{_.map(period, (p) => _.upperCase(moment(p.startDate).format('YYYY')))}</span>
                <Icon size="large" name="caret down" />
              </div>
            </Button>
          }
          content={
            <div>
              <DatePicker
                minDate={minDate}
                maxDate={endOfNextMonth}
                selected={_.get(...period, 'startDate')}
                onChange={(date) =>
                  setPeriod([
                    {
                      startDate: date,
                      endDate: date,
                    },
                  ])
                }
                showYearPicker
                inline
              />
            </div>
          }
          on="click"
          className="bg-blue"
          hideOnScroll={true}
          position="bottom right"
        />
      )}
      {type === 'month' && (
        <Popup
          trigger={
            <Button className={`date-range-picker-custom`}>
              <div className={`flex center`}>
                <span>
                  {_.map(period, (p) => _.upperCase(moment(p.startDate).format('MMMM YY')))}
                </span>
                <Icon size="large" name="caret down" />
              </div>
            </Button>
          }
          content={
            <div>
              <DatePicker
                minDate={minDate}
                maxDate={maxYear ? monthPlusOneYear : endOfNextMonth}
                selected={_.get(...period, 'startDate')}
                onChange={(date) =>
                  setPeriod([
                    {
                      startDate: date,
                      endDate: endOfMonth(date),
                    },
                  ])
                }
                showMonthYearPicker
                inline
              />
            </div>
          }
          on="click"
          className="bg-blue"
          hideOnScroll={true}
          position="bottom right"
        />
      )}
    </div>
  )
}

export default PeriodPicker
