import moment from 'moment'

export const SEARCH_OPTIONS = {
  SingleCrude: 'single-crude',
  CustomBlend: 'custom-blend',
  Property: 'property',
}

export const singleCrudeChart = [
  {
    name: 'ARL',
    value: 12.5,
    color: '#FFD645',
  },
  {
    name: 'AXL',
    value: 25,
    color: '#FFA000',
  },
  {
    name: 'DAS',
    value: 12.5,
    color: '#00AFED',
  },
  {
    name: 'MUR',
    value: 6.75,
    color: '#0081C9',
  },
  {
    name: 'SLO',
    value: 12.5,
    color: '#3BC2C7',
  },
  {
    name: 'UPZ',
    value: 6.75,
    color: '#259396',
  },
  {
    name: 'SOC',
    value: 12.5,
    color: '#7A8CD1',
  },
  {
    name: 'BKZ',
    value: 12.5,
    color: '#3750B3',
  },
]

export const sevenDayChartInfo = [
  {
    name: 'ARL',
    value: 12.5,
    tankNo: 'T3077',
    new: false,
    concern: false,
    note: 'N2 in waxy feed',
    color: '#FFD645',
  },
  {
    name: 'AXL',
    value: 25,
    tankNo: 'T3078, T3089',
    new: false,
    concern: false,
    note: 'HCU2 is higher',
    color: '#FFA000',
  },
  {
    name: 'DAS',
    value: 12.5,
    tankNo: 'T3089',
    new: false,
    concern: false,
    note: '',
    color: '#00AFED',
  },
  {
    name: 'MUR',
    value: 6.75,
    tankNo: 'T3078, T3089',
    new: true,
    concern: false,
    note: '',
    color: '#0081C9',
  },
  {
    name: 'SLO',
    value: 12.5,
    tankNo: 'T3078',
    new: false,
    concern: false,
    note: '',
    color: '#3BC2C7',
  },
  {
    name: 'UPZ',
    value: 6.75,
    tankNo: 'T3078, T3089',
    new: true,
    concern: true,
    note: '',
    color: '#259396',
  },
  {
    name: 'SOC',
    value: 12.5,
    tankNo: 'T3089',
    new: false,
    concern: false,
    note: '',
    color: '#7A8CD1',
  },
  {
    name: 'BKZ',
    value: 12.5,
    tankNo: 'T3089',
    new: false,
    concern: false,
    note: '',
    color: '#3750B3',
  },
]

export const legendObject = [
  {
    type: 'square',
    name: 'ARL',
    color: '#FFD645',
  },
  {
    type: 'square',
    name: 'AXL',
    color: '#FFA000',
  },
  {
    type: 'square',
    name: 'DAS',
    color: '#00AFED',
  },
  {
    type: 'square',
    name: 'MUR',
    color: '#0081C9',
  },
  {
    type: 'square',
    name: 'SLO',
    color: '#3BC2C7',
  },
  {
    type: 'square',
    name: 'UPZ',
    color: '#259396',
  },
  {
    type: 'square',
    name: 'SOC',
    color: '#7A8CD1',
  },
  {
    type: 'square',
    name: 'BKZ',
    color: '#3750B3',
  },
]

export const dropdownCduOptions = [
  {
    key: '1',
    text: 'Property 1, unit/unit',
    value: '0.837',
  },
  {
    key: '2',
    text: 'Property 2, unit/unit',
    value: '0.50',
  },
  {
    key: '3',
    text: 'Property 3, unit/unit',
    value: '2.00',
  },
  {
    key: '4',
    text: 'Property 4, unit/unit',
    value: '0.50',
  },
  {
    key: '5',
    text: 'Property 5, unit/unit',
    value: '2.00',
  },
]

export const dropdownCduUnitOptions = [
  {
    key: 'cdu1',
    text: 'CDU-1',
    value: 'cdu-1',
  },
  {
    key: 'cdu2',
    text: 'CDU-2',
    value: 'cdu-2',
  },
  {
    key: 'cdu3',
    text: 'CDU-3',
    value: 'cdu-3',
  },
  {
    key: 'cdu4',
    text: 'CDU-4',
    value: 'cdu-4',
  },
  {
    key: 'cdu5',
    text: 'CDU-5',
    value: 'cdu-5',
  },
]

export const dropdownUnitOptions = [
  {
    key: '1',
    text: 'Sulphur',
    value: '0.837',
  },
  {
    key: '2',
    text: 'TAN',
    value: '0.50',
  },
  {
    key: '3',
    text: 'TAN / Sulphur',
    value: '2.00',
  },
  {
    key: '4',
    text: 'Total Nitrogren',
    value: '0.50',
  },
  {
    key: '5',
    text: 'Organic',
    value: '2.00',
  },
]

export const dropdownDownstreamOptions = [
  {
    key: '1',
    text: 'Cut Temperature',
    value: '0.837',
  },
  {
    key: '2',
    text: 'Cut Temperature',
    value: '0.50',
  },
  {
    key: '3',
    text: 'Cut Temperature',
    value: '2.00',
  },
  {
    key: '4',
    text: 'Cut Temperature',
    value: '0.50',
  },
  {
    key: '5',
    text: 'Cut Temperature',
    value: '2.00',
  },
]

export const recentSearchOutput = [
  {
    crudeCode: 'AMB',
    crudeName: 'Arab Medium Banaco',
    cduUnit: 'CDU-2',
  },
  {
    crudeCode: 'UML',
    crudeName: 'Umm Lulu',
    cduUnit: 'CDU-3',
  },
  {
    crudeCode: 'SMB',
    crudeName: 'Sarrir / Mesla',
    cduUnit: 'CDU-1',
  },
  {
    crudeCode: 'AMB',
    crudeName: 'Arab Medium Banaco',
    cduUnit: 'CDU-3',
  },
  {
    crudeCode: 'AMB',
    crudeName: 'Arab Medium Banaco',
    cduUnit: 'CDU-1',
  },
]

export const highlightsOutput = [
  {
    date: '2020-10-15',
    crudeCode: 'AMB',
    crudeName: 'Arab Medium Banaco',
    description: 'was concerned on high nitrogen in HVGO portion.',
  },
  {
    date: '2020-10-17',
    crudeCode: 'UML',
    crudeName: 'Umm Lulu',
    description: 'is high sulfur, used to processing on 10 Nov2017 at maximum processing XX%',
  },
  {
    date: '2020-10-20',
    crudeCode: 'SMB',
    crudeName: 'Sarrir / Mesla',
    description: 'was concerned on high nitrogen in HVGO portion.',
  },
  {
    date: '2020-10-27',
    crudeCode: 'AMB',
    crudeName: 'Arab Medium Banaco',
    description: 'is high sulfur, used to processing on 10 Nov2017 at maximum processing XX%',
  },
]

export const singleCrudeChartInfo = [
  {
    title: 'BKZ',
    data: {
      crudeCode: 'BKZ',
      crudeName: 'Brunei',
      crudeDescriptiom:
        'The crude has been processed  06 times in Thaioil and never been rejected before',
      history: '62% on 29 AUG 2019',
      selected: '52% ',
      data: [
        {
          name: 'ARL',
          value: '24',
          color: '#D9D9D9',
        },
        {
          name: 'AXL',
          value: '8',
          color: '#C4C4C4',
        },
        {
          name: 'DAS',
          value: '11',
          color: '#B0B0B0',
        },
        {
          name: 'MUR',
          value: '22',
          color: '#9E9E9E',
        },
        {
          name: 'SLO',
          value: '4',
          color: '#888888',
        },
        {
          name: 'UPZ',
          value: '6',
          color: '#717171',
        },
        {
          name: 'SOC',
          value: '4',
          color: '#4C4C4C',
        },
        {
          name: 'BKZ',
          value: '24',
          color: '#3750B3',
        },
      ],
    },
  },
]

export const singleCrudeSearchSuggestion = [
  {
    title: 'ARL',
    description: 'Cross-platform maximized project',
  },
  {
    title: 'AXL',
    description: 'Reverse-engineered asynchronous structure',
  },
  {
    title: 'DAS',
    description: 'Object-based multi-tasking moratorium',
  },
  {
    title: 'MUR',
    description: 'Organic user-facing hub',
  },
  {
    title: 'BKZ',
    description: 'Team-oriented neutral middleware',
  },
]

export const corrosionParamterTrendInfo = [
  {
    title: 'BKZ',
    data: {
      crudeCode: 'BKZ',
      crudeName: 'Brunei',
      dataDate: moment().format('DD MMM YYYY'),
      wholeCrude: {
        name: 'Whole Crude',
        value: 0.1,
        color: '#FFD645',
      },
      data: [
        {
          name: 'Gas Oil',
          value: 0.1,
          color: '#FFA000',
        },
        {
          name: 'High Cut',
          value: 0.1,
          color: '#3BC2C7',
        },
        {
          name: 'SR',
          value: 0.1,
          color: '#259396',
        },
        {
          name: 'LR',
          value: 0.1,
          color: '#00AFED',
        },
        {
          name: 'Low Cut',
          value: 0.1,
          color: '#0081C9',
        },
        {
          name: 'SR (DC)',
          value: 0.1,
          color: '#7A8CD1',
        },
      ],
      blend: [
        {
          name: 'ARL',
          value: '24',
          color: '#D9D9D9',
        },
        {
          name: 'AXL',
          value: '8',
          color: '#C4C4C4',
        },
        {
          name: 'DAS',
          value: '11',
          color: '#B0B0B0',
        },
        {
          name: 'MUR',
          value: '22',
          color: '#9E9E9E',
        },
        {
          name: 'SLO',
          value: '4',
          color: '#888888',
        },
        {
          name: 'UPZ',
          value: '6',
          color: '#717171',
        },
        {
          name: 'SOC',
          value: '4',
          color: '#4C4C4C',
        },
        {
          name: 'BKZ',
          value: '21',
          color: '#000000',
        },
      ],
    },
  },
]

export const VALIDATION_MSG = {
  ERROR_MSG_1: 'Please enter the total composition below 100',
  ERROR_MSG_2: 'Please enter a different crude',
  ERROR_MSG_3: 'Please enter composition more than 0',
}
