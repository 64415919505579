
import React, { useState, useCallback, useEffect } from 'react'
import _ from 'lodash'

import { Search, Header } from 'semantic-ui-react'

const SearchInput = (props) => {

    const {
        displayProperty,
        displayInListProperty,
        defaultSelection,
        selectionOptions,
        onSelectionUpdate,
        placeholder,
        className,
        size,
        minCharacter,
    } = props;

    const [searchValue, setSearchValue] = useState('');
    const [selections, setSelections] = useState([])

    useEffect(()=> {
        if(defaultSelection && !_.isEmpty(defaultSelection)){
            setSearchValue(_.get(defaultSelection, displayProperty))
        }

        if(_.isNull(defaultSelection)){
            setSearchValue('')
        }

    }, [displayProperty, defaultSelection])

    const onResultSelect = (e, data) => {
        setSearchValue(_.get(data.result, displayProperty))
        onSelectionUpdate(data.result)
    }

    const onSeachChange = useCallback((e, data) => {
        let searchNewValue = _.get(data, 'value') || ''
        setSearchValue(searchNewValue)

        const filteredData = _.filter(selectionOptions, (item) => {
            return (_.get(item, displayInListProperty)).toLowerCase().includes(searchNewValue.toLowerCase());
        })
        setSelections(filteredData)

        const foundDataByValue = _.find(filteredData, (item) => {
            return item[displayProperty].toLowerCase() === searchNewValue.toLowerCase()
        })
        if (!foundDataByValue || _.isEmpty(foundDataByValue)) {
            onSelectionUpdate({})
        }
        else {
            onSelectionUpdate(foundDataByValue)
        }

    }, [displayInListProperty, displayProperty, onSelectionUpdate, selectionOptions])

    return (
        <Search
            //loading={loading}
            onSearchChange={onSeachChange}
            onResultSelect={onResultSelect}
            resultRenderer={(data) => {
                return (<Header size="small" textAlign="left">
                    {_.get(data, displayInListProperty)}
                </Header>)
            }}
            results={selections}
            value={searchValue}
            minCharacters={minCharacter || 1}
            icon={false}
            size={size || 'small'}
            className={`search-input-wrapper ${className}`}
            placeholder={placeholder}
        />
    )
}

export default SearchInput;