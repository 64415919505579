import Axios from "axios";
import _ from 'lodash';
//import { Auth } from 'aws-amplify';

import { LAMBDA_API_ENDPOINT_URL } from '../../constants/environmentVariables';

import { serviceUnauthorized } from '../index'

const getJwtAccessToken = async () => {
  //   const oidcStorage = JSON.parse(localStorage.getItem(`oidc.user:${OIDC_URL}:${OIDC_CLIENT_ID}`));
  //   return oidcStorage ? oidcStorage.access_token : null;

    // const session = await Auth.currentSession();
    // const token = session.getAccessToken().getJwtToken();
    // return token;

   const token = localStorage.getItem('id_token');
   return token;
}

export const LAMBDA_API_INSTANCE = Axios.create({
  baseURL: LAMBDA_API_ENDPOINT_URL,
  headers: {
    "Content-Type": "application/json",// charset=utf-8",
    //"Authorization": `Bearer ${getJwtAccessToken()}`,
  },
});

LAMBDA_API_INSTANCE.interceptors.request.use(
  async function (config) {
    const token = await getJwtAccessToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

LAMBDA_API_INSTANCE.interceptors.response.use(
  function (response) {
    return _.get(response, 'data');
  },
  function (error) {

    if (Axios.isCancel(error)) {
      return Promise.reject(error);
    }

    //const originalRequest = error.config;
    const errorResponse = error.response;

    if (errorResponse) {

      // Get latest access token and retry api when 401
      // Max retry will redirect to logout
      if (errorResponse.status === 401 || errorResponse.data.message === "TOKEN_EXPIRED") {
        serviceUnauthorized()
      } else {
        return Promise.reject(error);
      }

    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an API_INSTANCE of XMLHttpRequest in the browser and an API_INSTANCE of
      // http.ClientRequest in node.js
      return Promise.reject(error);
    } else {
      // Something happened in setting up the request that triggered an Error
      throw Error(
        "Sorry! Something went wrong, please contact our administrator."
      );
    }

  }
);

export function errorHandler(error) {
  let message = "";

  // PSM error status 403
  if (error.response) {
    if (error.response.data.message) {
      message = error.response.data.message;
    } else {
      message = JSON.parse(error.request.responseText).msg;
    }
  } else {
    message = error.message;
  }

  console.log('Error:', message);
  return message;
}