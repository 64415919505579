import React from 'react'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import reducers from '../reducers'
import promise from 'redux-promise'
import thunk from 'redux-thunk'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const createStoreWithMiddleware = composeEnhancers(applyMiddleware(promise, thunk))(createStore);
export const store = createStoreWithMiddleware(reducers)

export const ReduxProvider = ({
	children
}) => {
	return (
		<Provider store={store}>
			{children}
		</Provider>
	)
}