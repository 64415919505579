import _ from 'lodash'
import { LAMBDA_API_INSTANCE } from './serverInstances/lambdaServer'
import * as UserGroup from '../constants/userGroups'

const CONTROLLER_NAME = 'compatibility'

export const postCheckCompatibilityShell = (searchObject) => {
  const formatSearchObject = _.map(searchObject, (item) =>
    !_.isUndefined(item.crudeCode) ? { crudeCode: item.crudeCode, percentage: item.percentage } : {}
  )
  const filterSearchObject = _.filter(formatSearchObject, (v) => _.keys(v).length !== 0)
  const bodyParams = {
    crudeList: filterSearchObject,
  }
  return LAMBDA_API_INSTANCE.post(`${CONTROLLER_NAME}/shellAssessmentImages`, bodyParams)
}

export const postCheckCompatibilityProcessingHistory = (searchObject) => {
  const formatSearchObject = _.map(searchObject.blendComposition, (item) =>
    !_.isUndefined(item.crudeCode) ? { crudeCode: item.crudeCode } : {}
  )
  const filterSearchObject = _.filter(formatSearchObject, (v) => _.keys(v).length !== 0)
  const bodyParams = {
    searchType: 'Blend',
    mode: 'filter',
    blendComposition: filterSearchObject,
    component: 'compatibility_risk_data',
    persona: currentPersonaFormatted(searchObject.currentPersona),
  }

  return LAMBDA_API_INSTANCE.post(`risk/searchResults`, bodyParams)
}

export const postCheckCompatibilityLab = (searchObject) => {
  const formatSearchObject = _.map(searchObject, (item) =>
    !_.isUndefined(item.crudeCode) ? { crudeCode: item.crudeCode, percentage: item.percentage } : {}
  )
  const filterSearchObject = _.filter(formatSearchObject, (v) => _.keys(v).length !== 0)
  const bodyParams = {
    crudeList: filterSearchObject,
  }
  return LAMBDA_API_INSTANCE.post(`${CONTROLLER_NAME}/labResult`, bodyParams)
}

export const postCheckCompatibilityCalculation = (searchObject) => {
  const formatSearchObject = _.map(searchObject, (item) =>
    !_.isUndefined(item.crudeCode) ? { crudeCode: item.crudeCode, percentage: item.percentage } : {}
  )
  const filterSearchObject = _.filter(formatSearchObject, (v) => _.keys(v).length !== 0)
  const bodyParams = {
    crudeList: filterSearchObject,
  }
  return LAMBDA_API_INSTANCE.post(`${CONTROLLER_NAME}/calculationCrude`, bodyParams)
}

export const postCheckCompatibilityAssessment = (searchObject) => {
  const formatSearchObjectSetA = _.map(searchObject.setA, (item) =>
    !_.isUndefined(item.crudeCode) ? { crudeCode: item.crudeCode, percentage: item.percentage } : {}
  )
  const formatSearchObjectSetB = _.map(searchObject.setB, (item) =>
    !_.isUndefined(item.crudeCode) ? { crudeCode: item.crudeCode, percentage: item.percentage } : {}
  )
  const formatSearchObjectSetC = _.map(searchObject.setC, (item) =>
    !_.isUndefined(item.crudeCode) ? { crudeCode: item.crudeCode, percentage: item.percentage } : {}
  )
  const setA = _.filter(formatSearchObjectSetA, (v) => _.keys(v).length !== 0)
  const setB = _.filter(formatSearchObjectSetB, (v) => _.keys(v).length !== 0)
  const setC = _.filter(formatSearchObjectSetC, (v) => _.keys(v).length !== 0)

  const bodyParams = {
    setA: setA,
    setB: setB,
    setC: setC,
  }
  return LAMBDA_API_INSTANCE.post(`${CONTROLLER_NAME}/assessment`, bodyParams)
}

const currentPersonaFormatted = (currentPersona) =>
  _.isEqual(currentPersona, UserGroup.SC)
    ? 'SC'
    : _.isEqual(currentPersona, UserGroup.ENTS)
    ? 'ENTS'
    : _.isEqual(currentPersona, UserGroup.TN)
    ? 'TN'
    : ''
