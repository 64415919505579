import React from "react";

const RadioButton = (props) => {
  const { menuData, className, selectCallback, selectedValue } = props;

  return (
    <div className={`radio-button-wrapper  ${className}`}>
      {menuData.map((item, index) => {
        return (
          <div
            className="radio-button-container"
            onClick={() => selectCallback(item.value)}
          >
            <span
              className={`radio-button ${
                selectedValue === item.value ? "active" : ""
              }`}
            >
              <span className="radio-button-selected"></span>
            </span>
            <span className="radio-button-label" title={item.label}>
              {item.label}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default RadioButton;
