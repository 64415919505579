import React from 'react'

//Components
import { Dimmer, Loader } from 'semantic-ui-react'

const CustomLoader = (props) => {
  const { isActive, isPage, hasLabel, customlabel, hasBorderRounded } = props

  let loaderLabel = customlabel ? customlabel : 'Loading'
  return (
    <Dimmer
      className={`custom-loader-wrapper ${hasBorderRounded ? 'border-round' : ''}`}
      active={isActive}
      inverted
      page={isPage || false}
    >
      <Loader>{hasLabel ? loaderLabel : ''}</Loader>
    </Dimmer>
  )
}

export default CustomLoader
