
import React from 'react';

const RadioMenu = (props) => {

    const { menuData, selectCallback, selectedValue } = props;


    return (
        <div className="radio-menu-container">
            {
                menuData.map((item, index) => {
                    return <div key={index} className={`menu-item ${selectedValue === item.value ? 'active' : ''}`}
                            onClick={()=>{ selectCallback(item.value) }}>{item.label}</div>
                })
            }
        </div>
    )
}

export default RadioMenu;