import _ from 'lodash'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import React from 'react'
import ReactHighchart from '../../react-highcharts'
import PropTypes from 'prop-types'

import { setSevenDaySelectedChartData } from '../../../actions/actionLanding'

const onClickEvent = (props, data, dispatch) => {
  const indexP = props.point.x
  const series = props.point.series.chart.series

  const seriesData = _.get(data, 'series')
  const seriesDataPM = _.get(seriesData, 'PM')

  const getSeriesName = _.map(series, (a) => a.name)
  const getSeriesColor = _.map(series, (a) => a.color)
  const getSeriesValue = _.map(series, (item) => item.data[indexP].y)
  const getSeriesUserOptions = _.map(series, (a) => a.userOptions);
  const getSeriesIsNew = _.map(getSeriesUserOptions, a => a.isNew[indexP])
  const getSeriesHasConcerns = _.map(getSeriesUserOptions, a => a.hasConcerns)
  const getSeriesDate = props.point.category
  const getSeriesTankNo = _.map(seriesDataPM, (a) => a.tank_no)

  const a = _.map(getSeriesName, (a) => ({ name: a }))
  const b = _.map(getSeriesValue, (a) => ({ value: a }))
  const c = _.map(getSeriesColor, (a) => ({ color: a }))
  const d = {
    date: getSeriesDate,
    selectedIndex: indexP,
    chartType: 'PM',
  }
  const e = _.map(getSeriesTankNo, (a) => ({ tank_no: a }))
  const f = _.map(getSeriesIsNew, (a) => ({ isNew: a }))
  const g = _.map(getSeriesHasConcerns, (a) => ({ hasConcerns: a }));

  const selectedData = _.merge(a, b, c, d, e, f, g)

  dispatch(setSevenDaySelectedChartData(selectedData))
}

export const PMSevenDayCrudeBlendChart = (props) => {
  const dispatch = useDispatch()
  const CHART_OPTIONS = setChartOptions(props.sevenDayCrudeBlendData.data.chart, dispatch)
  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <ReactHighchart options={CHART_OPTIONS} />
    </div>
  )
}

PMSevenDayCrudeBlendChart.propTypes = {
  PMsevenDayCrudeBlendData: PropTypes.object,
}

function setChartOptions(data, dispatch) {
  const PMsevenDayCrudeBlendDataSeries = _.get(_.get(data, 'series'), 'PM')
  const PMsevenDayCrudeBlendDataCategory = _.get(data, 'category')
  return {
    chart: {
      type: 'column',
      height: 200,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: PMsevenDayCrudeBlendDataCategory,
      labels: {
        autoRotation: [0],
        style: {
          fontSize: '14px'
        },
      },
      lineColor: '#FFF',
      visible: true,
    },
    yAxis: {
      visible: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
      shared: true,
    },
    plotOptions: {
      column: {
        stacking: 'percent',
        allowPointSelect: false,
        dataLabels: {
          enabled: false,
        },
        point: {
          events: {
            click: (props) => onClickEvent(props, data, dispatch),
          },
        },
        opacity: 1,
        states: {
          select: {
            enabled: true,
          },
          hover: {
            enabled: true,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
      series: {
        borderWidth: 0,
        pointWidth: 45,
      },
    },
    series: PMsevenDayCrudeBlendDataSeries,
  }
}
