import _ from 'lodash'
import {
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  addDays,
  subDays,
  subYears,
} from 'date-fns'
import {
  UPDATE_SEARCH_INPUT,
  SET_CRUDE_BLEND_SELECTED_CHART_DATA,
  SET_CRUDE_BLEND_CHART_DATE,
  SET_SEVEN_DAY_CRUDE_BLEND_CHART_DATE,
  SET_SINGLE_CRUDE_CHART,
  SET_PARAMETER_TREND_CHART_DATE,
  SET_CORROSION_PARAMETER_TREND_CHART_DATE,
  SET_CRUDE_ARRIVAL_CHART_DATE,
  POST_CRUDE_BLEND_CHART,
  POST_CRUDE_BLEND_CDU_VALUE_1,
  POST_CRUDE_BLEND_CDU_VALUE_2,
  POST_CRUDE_BLEND_CDU_VALUE_3,
  POST_CRUDE_BLEND_CDU_VALUE_4,
  POST_CRUDE_BLEND_CDU_VALUE_5,
  POST_CRUDE_BLEND_DOWNSTREAM_VALUE_1,
  POST_CRUDE_BLEND_DOWNSTREAM_VALUE_2,
  POST_CRUDE_BLEND_DOWNSTREAM_VALUE_3,
  POST_CRUDE_BLEND_DOWNSTREAM_VALUE_4,
  POST_CRUDE_BLEND_DOWNSTREAM_VALUE_5,
  SET_SEVEN_DAY_SELECTED_CHART_DATA,
  POST_SEVEN_DAY_CRUDE_BLEND_CHART,
  POST_SINGLE_CRUDE_CHART,
  POST_PARAMETER_TREND_CHART_1,
  POST_PARAMETER_TREND_CHART_2,
  SET_CORROSION_PARAMETER_DRILLDOWN,
  POST_CORROSION_PARAMTER_TREND_CHART,
  SET_CORROSION_BLEND_MONTH,
  POST_CORROSION_BLEND_COMPOSITION,
  POST_CRUDE_ARRIVAL_CHART,
  POST_RECENT_SEARCH,
  POST_FAVOURITES,
  POST_NOTIFICATION,
  POST_SAVE_NOTIFICATION,
  POST_CAM_LIMIT_NOTIFICATION,
  POST_HIGHLIGHTS,
  FREEFORM_TREND_CHART
} from '../actions/actionLanding'

import * as LandingActions from '../actions/actionLanding'

import * as landingPageConstant from '../containers/views/landing-page/helper'

const defaultState = {
  landingPage: {
    options: landingPageConstant.SEARCH_OPTIONS.SingleCrude,
    crudeNameAbbreviation: '',
    inputUnits: {
      unit1: null,
      unitPercent1: '',
      unit2: null,
      unitPercent2: '',
      unit3: null,
      unitPercent3: '',
      unit4: null,
      unitPercent4: '',
      unit5: null,
      unitPercent5: '',
      unit6: null,
      unitPercent6: '',
      unit7: null,
      unitPercent7: '',
      unit8: null,
      unitPercent8: '',
      unit9: null,
      unitPercent9: '',
      unit10: null,
      unitPercent10: '',
    },
    isSelectYearlyPeriod: true,
    isSelectCorrosionProperty: true,
  },
  highlights: {
    data: [],
    unit: 'CDU-3',
    defaultPeriod: {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
    },
    period: {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: 'selection',
    },
  },
  freeformChart: {
    isSearchByCrudeBlendDate: true,
    data: [],
    defaultPeriod: {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
    },
    period: {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: 'selection',
    },
    dataSource: 'APS Data',
    unit: '',
    property1: '',
    property2: '',
    property3: '',
    property4: '',
    property5: '',
    freeformData: [],
    freeformDataSource1: '',
    freeformDataSource2: '',
    freeformDataSource3: '',
    freeformDataSource4: '',
    freeformDataSource5: '',
    freeformUnit1: '',
    freeformUnit2: '',
    freeformUnit3: '',
    freeformUnit4: '',
    freeformUnit5: '',
    freeformStream1: '',
    freeformStream2: '',
    freeformStream3: '',
    freeformStream4: '',
    freeformStream5: '',
    freeformProperty1: '',
    freeformProperty2: '',
    freeformProperty3: '',
    freeformProperty4: '',
    freeformProperty5: '',
  },

  crudeBlendChart: {
    data: [],
    selectedData: [],
    camLimitNotification: '',
    unit: 'CDU-3',
    unit1: 'CDU-3',
    processUnit: 'CDU-1',
    dataSource: 'APS Data',
    cduFeed1: 'Total Sulfur',
    cduFeed2: 'Total distillate',
    cduFeed3: 'Intake',
    cduFeed4: '%Kerosene',
    cduFeed5: 'Total Acid Number',
    cduFeedValue1: '',
    cduFeedValue2: '',
    cduFeedValue3: '',
    cduFeedValue4: '',
    cduFeedValue5: '',
    downstreamValue1: '',
    downstreamValue2: '',
    downstreamValue3: '',
    downstreamValue4: '',
    downstreamValue5: '',
    fraction1: '',
    fraction3: '',
    fraction2: '',
    fraction4: '',
    fraction5: '',
    properties1: '',
    properties2: '',
    properties3: '',
    properties4: '',
    properties5: '',
    defaultPeriod: {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
    },
    period: {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: 'selection',
    },
  },
  singleCrudeChart: {
    data: [],
    unit: 'CDU-3',
    property: 'Total Sulfur',
    crudeNameAbbreviation: {
      crudeCode: 'MUR',
      crudeName: 'Murban',
      title: 'MUR - Murban',
    },
    defaultPeriod: {
      startDate: new Date().setMonth(new Date().getMonth() - 12),
      endDate: new Date(),
      // startDate: startOfYear(new Date(), 1),
      // endDate: new Date(),
    },
    period: {
      startDate: new Date().setMonth(new Date().getMonth() - 12),
      endDate: new Date(),
      // startDate: startOfYear(new Date(), 1),
      // endDate: new Date(),
      key: 'selection',
    },
  },
  parameterTrendChart1: {
    data: [],
    dataSource: 'APS Data',
    unit1: 'CDU-1',
    fraction1: 'Long residue',
    properties1: '%CCR',
    defaultPeriod: {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
    },
    period: {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: 'selection',
    },
  },
  parameterTrendChart2: {
    data: [],
    dataSource: 'APS Data',
    unit2: 'CDU-3',
    fraction2: 'Long residue',
    properties2: '%CCR',
    defaultPeriod: {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
    },
    period: {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: 'selection',
    },
  },
  corrosionParamterTrendChart: {
    data: [],
    blendComposition: [],
    month: 'mar',
    ddDupes: false,
    unit: 'CDU-3',
    material: 'Total Sulfur',
    defaultPeriod: {
      startDate: startOfYear(new Date()),
      endDate: endOfYear(new Date()),
    },
    period: {
      startDate: startOfYear(new Date()),
      endDate: endOfYear(new Date()),
      key: 'selection',
    },
  },
  sevenDayCrudeBlendChart: {
    data: [],
    selectedData: [],
    unit: 'CDU-3',
    period: {
      startDate: subDays(new Date(), 6),
      endDate: new Date(),
      key: 'selection',
    },
  },
  crudeArrivalChart: {
    data: [],
    period: {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: 'selection',
    },
  },
  recentSearch: {
    data: '',
  },
  favourites: {
    data: '',
  },
  notification: {
    data: '',
    save: '',
  },
}

const REDUCERS = {
  [SET_CRUDE_BLEND_CHART_DATE]: (state, { payload }) => {
    return {
      ...state,
      crudeBlendChart: {
        ...state.crudeBlendChart,
        period: payload,
      },
    }
  },

  [SET_SEVEN_DAY_CRUDE_BLEND_CHART_DATE]: (state, { payload }) => {
    return {
      ...state,
      sevenDayCrudeBlendChart: {
        period: payload,
      },
    }
  },

  [SET_SINGLE_CRUDE_CHART]: (state, { payload }) => {
    return {
      ...state,
      singleCrudeChart: {
        ...state.singleCrudeChart,
        period: payload,
      },
    }
  },

  [SET_PARAMETER_TREND_CHART_DATE]: (state, { payload }) => {
    return {
      ...state,
      parameterTrendChart: {
        ...state.parameterTrendChart,
        period: payload,
      },
    }
  },

  [SET_CORROSION_PARAMETER_TREND_CHART_DATE]: (state, { payload }) => {
    return {
      ...state,
      corrosionParamterTrendChart: {
        ...state.corrosionParamterTrendChart,
        period: payload,
      },
    }
  },

  [SET_CRUDE_ARRIVAL_CHART_DATE]: (state, { payload }) => {
    return {
      ...state,
      crudeArrivalChart: {
        period: payload,
      },
    }
  },

  [SET_CRUDE_BLEND_SELECTED_CHART_DATA]: (state, { payload }) => {
    return {
      ...state,
      crudeBlendChart: {
        ...state.crudeBlendChart,
        selectedData: payload,
      },
    }
  },

  [POST_CRUDE_BLEND_CHART]: (state, { payload }) => {
    return {
      ...state,
      crudeBlendChart: {
        ...state.crudeBlendChart,
        data: payload,
      },
    }
  },

  [POST_CAM_LIMIT_NOTIFICATION]: (state, { payload }) => {
    return {
      ...state,
      crudeBlendChart: {
        ...state.crudeBlendChart,
        camLimitNotification: payload,
      },
    }
  },

  [POST_CRUDE_BLEND_CDU_VALUE_1]: (state, { payload }) => {
    return {
      ...state,
      crudeBlendChart: {
        ...state.crudeBlendChart,
        cduFeedValue1: payload,
      },
    }
  },

  [POST_CRUDE_BLEND_CDU_VALUE_2]: (state, { payload }) => {
    return {
      ...state,
      crudeBlendChart: {
        ...state.crudeBlendChart,
        cduFeedValue2: payload,
      },
    }
  },

  [POST_CRUDE_BLEND_CDU_VALUE_3]: (state, { payload }) => {
    return {
      ...state,
      crudeBlendChart: {
        ...state.crudeBlendChart,
        cduFeedValue3: payload,
      },
    }
  },

  [POST_CRUDE_BLEND_CDU_VALUE_4]: (state, { payload }) => {
    return {
      ...state,
      crudeBlendChart: {
        ...state.crudeBlendChart,
        cduFeedValue4: payload,
      },
    }
  },

  [POST_CRUDE_BLEND_CDU_VALUE_5]: (state, { payload }) => {
    return {
      ...state,
      crudeBlendChart: {
        ...state.crudeBlendChart,
        cduFeedValue5: payload,
      },
    }
  },

  [POST_CRUDE_BLEND_DOWNSTREAM_VALUE_1]: (state, { payload }) => {
    return {
      ...state,
      crudeBlendChart: {
        ...state.crudeBlendChart,
        downstreamValue1: payload,
      },
    }
  },

  [POST_CRUDE_BLEND_DOWNSTREAM_VALUE_2]: (state, { payload }) => {
    return {
      ...state,
      crudeBlendChart: {
        ...state.crudeBlendChart,
        downstreamValue2: payload,
      },
    }
  },

  [POST_CRUDE_BLEND_DOWNSTREAM_VALUE_3]: (state, { payload }) => {
    return {
      ...state,
      crudeBlendChart: {
        ...state.crudeBlendChart,
        downstreamValue3: payload,
      },
    }
  },

  [POST_CRUDE_BLEND_DOWNSTREAM_VALUE_4]: (state, { payload }) => {
    return {
      ...state,
      crudeBlendChart: {
        ...state.crudeBlendChart,
        downstreamValue4: payload,
      },
    }
  },

  [POST_CRUDE_BLEND_DOWNSTREAM_VALUE_5]: (state, { payload }) => {
    return {
      ...state,
      crudeBlendChart: {
        ...state.crudeBlendChart,
        downstreamValue5: payload,
      },
    }
  },

  [SET_SEVEN_DAY_SELECTED_CHART_DATA]: (state, { payload }) => {
    return {
      ...state,
      sevenDayCrudeBlendChart: {
        ...state.sevenDayCrudeBlendChart,
        selectedData: payload,
      },
    }
  },

  [POST_SEVEN_DAY_CRUDE_BLEND_CHART]: (state, { payload }) => {
    return {
      ...state,
      sevenDayCrudeBlendChart: {
        ...state.sevenDayCrudeBlendChart,
        data: payload,
      },
    }
  },

  [POST_SINGLE_CRUDE_CHART]: (state, { payload }) => {
    return {
      ...state,
      singleCrudeChart: {
        ...state.singleCrudeChart,
        data: payload,
      },
    }
  },

  [POST_PARAMETER_TREND_CHART_1]: (state, { payload }) => {
    return {
      ...state,
      parameterTrendChart1: {
        ...state.parameterTrendChart1,
        data: payload,
      },
    }
  },

  [POST_PARAMETER_TREND_CHART_2]: (state, { payload }) => {
    return {
      ...state,
      parameterTrendChart2: {
        ...state.parameterTrendChart2,
        data: payload,
      },
    }
  },

  [SET_CORROSION_PARAMETER_DRILLDOWN]: (state, { payload }) => {
    return {
      ...state,
      corrosionParamterTrendChart: {
        ...state.corrosionParamterTrendChart,
        ddDupes: payload,
      },
    }
  },

  [POST_CORROSION_PARAMTER_TREND_CHART]: (state, { payload }) => {
    return {
      ...state,
      corrosionParamterTrendChart: {
        ...state.corrosionParamterTrendChart,
        data: payload,
      },
    }
  },

  [SET_CORROSION_BLEND_MONTH]: (state, { payload }) => {
    return {
      ...state,
      corrosionParamterTrendChart: {
        ...state.corrosionParamterTrendChart,
        month: payload,
      },
    }
  },

  [POST_CORROSION_BLEND_COMPOSITION]: (state, { payload }) => {
    return {
      ...state,
      corrosionParamterTrendChart: {
        ...state.corrosionParamterTrendChart,
        blendComposition: payload,
      },
    }
  },

  [POST_CRUDE_ARRIVAL_CHART]: (state, { payload }) => {
    return {
      ...state,
      crudeArrivalChart: {
        ...state.crudeArrivalChart,
        data: payload,
      },
    }
  },

  [POST_RECENT_SEARCH]: (state, { payload }) => {
    return {
      ...state,
      recentSearch: {
        ...state.recentSearch,
        data: payload,
      },
    }
  },

  [POST_FAVOURITES]: (state, { payload }) => {
    return {
      ...state,
      favourites: {
        ...state.favourites,
        data: payload,
      },
    }
  },

  [POST_NOTIFICATION]: (state, { payload }) => {
    return {
      ...state,
      notification: {
        ...state.notification,
        data: payload,
      },
    }
  },

  [POST_SAVE_NOTIFICATION]: (state, { payload }) => {
    return {
      ...state,
      notification: {
        ...state.notification,
        save: payload,
      },
    }
  },

  [POST_HIGHLIGHTS]: (state, { payload }) => {
    return {
      ...state,
      highlights: {
        ...state.highlights,
        data: payload,
      },
    }
  },

  [LandingActions.FREEFORM_TREND_CHART]: (state, { payload }) => {
    return {
      ...state,
      freeformChart: {
        ...state.freeformChart,
        data: payload,
      },
    }
  },
}
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}
export const LANDING_PAGE_REDUCER = 'landingPage'
