import React from "react";
import { Button, Image } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

// Assets
import Logo from "../../assets/images/thai-oil-logo.svg";

const LoginPage = () => {
  const history = useHistory();
  return (
    <div>
      <div className={`logout-page flex width-100`}>
        <div className={`left-container flex center justify-center`}>
          <Image size="medium" src={Logo} />
        </div>
        <div className={`right-container flex center justify-center pad-4`}>
          <div className={`flex column center start`}>
            <div className={`text-white logout-text`}>Welcome to TOP-CWP</div>
            <Button className={`login-button`} onClick={() => history.push("/")}>Login</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
