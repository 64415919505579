import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'

// Components
import { Grid, Header, Segment, Divider, Dropdown, Button, Image, Popup } from 'semantic-ui-react'
import { CrudeBlendChart } from '../../../components/charts/crude-blend-chart/crude-blend-stacked-chart'
import Legend, { LegendItem } from '../../../components/legend'
import Chevron from '../../../components/chevron'
import PeriodPicker from '../../../components/period-picker'
import FetchStateWrapper from '../../../components/fetch-state-wrapper'
import NoDataBlock from '../../../components/no-data-block'

import { stringToDecimalPoint } from '../../../helpers/variables'

// Hooks
import { useStateWithPaths } from '../../../hooks/useConnect'

// Redux
import { PERSONAL_SETTING_REDUCER } from '../../../reducers/common/personalSettingReducer'
import { MASTER_DATA_REDUCER } from '../../../reducers/common/masterDataReducer'
import * as CrudeBlendActions from '../../../actions/actionLanding'
import {
  setCrudeBlendSelectedChartData,
  setCrudeBlendChart,
  setCrudeBlendCduValue1,
  setCrudeBlendCduValue2,
  setCrudeBlendCduValue3,
  setCrudeBlendCduValue4,
  setCrudeBlendCduValue5,
  setCrudeBlendDownstreamValue1,
  setCrudeBlendDownstreamValue2,
  setCrudeBlendDownstreamValue3,
  setCrudeBlendDownstreamValue4,
  setCrudeBlendDownstreamValue5,
  setCamLimitNotification,
} from '../../../actions/actionLanding'
import {
  getMasterUnits,
  getMasterDownstreamProperties,
  getMasterDownstreamProperties2,
  getMasterDownstreamProperties3,
  getMasterDownstreamProperties4,
  getMasterDownstreamProperties5,
  getMasterCrudeDataSources,
  getMasterDownstreamUnits,
  getMasterCduFeedProperties,
  getMasterDownstreamFractions,
} from '../../../actions/common/actionMasterData'

// Assets & Style
import IncompabilityIcon from '../../../assets/images/incompability-icon.svg'
import IncompabilityIconBlend from '../../../assets/images/incompatibility-icon-blend.svg'
import NewIcon from '../../../assets/images/new-icon.svg'
import ConcernIcon from '../../../assets/images/concern-icon.svg'
import BlendConcernIcon from '../../../assets/images/blend-concerns.svg'
import DisabledNewIcon from '../../../assets/images/disabled-new-icon.svg'
import DisabledConcernIcon from '../../../assets/images/disabled-concern-icon.svg'

const GetLegend = () => {
  const [crudeBlendChartData] = useStateWithPaths(['landingPage.crudeBlendChart'])
  const getLegendName = _.map(_.get(crudeBlendChartData.data, 'series'), (item) => item.name)
  const getLegendColor = _.map(_.get(crudeBlendChartData.data, 'series'), (item) => item.color)

  const b = _.map(getLegendName, (a) => ({ type: 'square', name: a }))
  const c = _.map(getLegendColor, (a) => ({ color: a }))

  const mergeLegendObject = _.merge(b, c)

  return (
    <Legend>
      {mergeLegendObject.map(({ name, color, type }, id) => (
        <LegendItem key={id} title={name} type={type} color={color} />
      ))}
    </Legend>
  )
}

const CrudeBlendSelected = (props) => {
  const dispatch = useDispatch()

  const [
    {
      crudeDataSources,
      downstreamUnits,
      cduFeedProperties,
      downstreamFractions,
      downstreamProperties,
      downstreamProperties2,
      downstreamProperties3,
      downstreamProperties4,
      downstreamProperties5,
    },
  ] = useStateWithPaths([`${MASTER_DATA_REDUCER}`])
  const [{ currentPersona }] = useStateWithPaths([`${PERSONAL_SETTING_REDUCER}`])

  const [crudeBlendChartData] = useStateWithPaths(['landingPage.crudeBlendChart'])

  const [selectedData] = useStateWithPaths(['landingPage.crudeBlendChart.selectedData'])

  // Default selectedData

  const indexS = moment().format('DD')
  const i = indexS - 1

  const series = _.get(crudeBlendChartData.data, 'series')

  const getSeriesName = _.map(series, (a) => a.name)
  const getSeriesColor = _.map(series, (a) => a.color)
  const getSeriesIsNew = _.map(series, (a) => a.isNew[i])
  const getSeriesHasConcerns = _.map(series, (a) => a.hasConcerns)
  const getSeriesValue = _.map(series, (item) => item.data[i])

  const a = _.map(getSeriesName, (a) => ({ name: a }))
  const b = _.map(getSeriesValue, (a) => ({ value: a }))
  const c = _.map(getSeriesColor, (a) => ({ color: a }))
  const d = _.map(getSeriesIsNew, (a) => ({ isNew: a }))
  const e = _.map(getSeriesHasConcerns, (a) => ({ hasConcerns: a }))
  const f = { date: moment().format('DD MMM') }

  const defaultSelectedData = _.merge(a, b, c, d, e, f)

  const startDate = _.map(props.period, (a) => a.startDate)
  const year = moment(...startDate).format('YYYY')
  const formatSelectedDate = _.get(selectedData, 'date') + year

  const selectedDate = !_.isEmpty(selectedData)
    ? moment(formatSelectedDate, ['DDMMMY', 'DDMMMY']).format('YYYY-MM-DD')
    : moment().format('YYYY-MM-DD')

  const selectDownstreamFractionValue = _.map(downstreamFractions, (item) => ({
    value: item.value,
  }))
  const selectFirstDownstreamFraction = _.first(selectDownstreamFractionValue)
  const getDownstreamFraction = _.get(selectFirstDownstreamFraction, 'value')

  const [unit1, setUnit1] = useState(crudeBlendChartData.unit)
  const [dataSource, setDataSource] = useState(crudeBlendChartData.dataSource)

  const [cduFeed1, setCduFeed1] = useState(crudeBlendChartData.cduFeed1)
  const [cduFeed2, setCduFeed2] = useState(crudeBlendChartData.cduFeed2)
  const [cduFeed3, setCduFeed3] = useState(crudeBlendChartData.cduFeed3)
  const [cduFeed4, setCduFeed4] = useState(crudeBlendChartData.cduFeed4)
  const [cduFeed5, setCduFeed5] = useState(crudeBlendChartData.cduFeed5)

  const [fraction1, setFraction1] = useState()
  const [fraction2, setFraction2] = useState()
  const [fraction3, setFraction3] = useState()
  const [fraction4, setFraction4] = useState()
  const [fraction5, setFraction5] = useState()

  const [properties1, setProperties1] = useState()
  const [properties2, setProperties2] = useState()
  const [properties3, setProperties3] = useState()
  const [properties4, setProperties4] = useState()
  const [properties5, setProperties5] = useState()

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    dispatch(setCrudeBlendCduValue1(cduFeed1, props.unit, dataSource, selectedDate))
    dispatch(setCrudeBlendCduValue2(cduFeed2, props.unit, dataSource, selectedDate))
    dispatch(setCrudeBlendCduValue3(cduFeed3, props.unit, dataSource, selectedDate))
    dispatch(setCrudeBlendCduValue4(cduFeed4, props.unit, dataSource, selectedDate))
    dispatch(setCrudeBlendCduValue5(cduFeed5, props.unit, dataSource, selectedDate))
  }, [
    dispatch,
    props.unit,
    cduFeed1,
    cduFeed2,
    cduFeed3,
    cduFeed4,
    cduFeed5,
    dataSource,
    selectedDate,
  ])

  useEffect(() => {
    dispatch(setCrudeBlendCduValue1(cduFeed1, props.unit, dataSource, selectedDate))
    dispatch(setCrudeBlendCduValue2(cduFeed2, props.unit, dataSource, selectedDate))
    dispatch(setCrudeBlendCduValue3(cduFeed3, props.unit, dataSource, selectedDate))
    dispatch(setCrudeBlendCduValue4(cduFeed4, props.unit, dataSource, selectedDate))
    dispatch(setCrudeBlendCduValue5(cduFeed5, props.unit, dataSource, selectedDate))
  }, [
    dispatch,
    props.unit,
    cduFeed1,
    cduFeed2,
    cduFeed3,
    cduFeed4,
    cduFeed5,
    dataSource,
    selectedDate,
  ])

  useEffect(() => {
    dispatch(setCrudeBlendDownstreamValue1(fraction1, properties1, unit1, dataSource, selectedDate))
    dispatch(setCrudeBlendDownstreamValue2(fraction2, properties2, unit1, dataSource, selectedDate))
    dispatch(setCrudeBlendDownstreamValue3(fraction3, properties3, unit1, dataSource, selectedDate))
    dispatch(setCrudeBlendDownstreamValue4(fraction4, properties4, unit1, dataSource, selectedDate))
    dispatch(setCrudeBlendDownstreamValue5(fraction5, properties5, unit1, dataSource, selectedDate))
  }, [
    dispatch,
    unit1,
    fraction1,
    fraction2,
    fraction3,
    fraction4,
    fraction5,
    properties1,
    properties2,
    properties3,
    properties4,
    properties5,
    dataSource,
    selectedDate,
  ])

  useEffect(() => {
    dispatch(getMasterUnits(currentPersona))
    dispatch(getMasterCduFeedProperties(currentPersona, props.unit, dataSource))
  }, [dispatch, props.unit, dataSource, currentPersona, selectedDate])

  useEffect(() => {
    dispatch(getMasterDownstreamUnits(currentPersona))
    dispatch(getMasterCrudeDataSources(currentPersona))
  }, [dispatch, currentPersona])

  useEffect(() => {
    dispatch(getMasterDownstreamFractions(currentPersona, unit1, dataSource))
    dispatch(getMasterDownstreamProperties(currentPersona, unit1, fraction1, dataSource))
  }, [dispatch, fraction1, unit1, dataSource, currentPersona])

  useEffect(() => {
    dispatch(getMasterDownstreamProperties2(currentPersona, unit1, fraction2, dataSource))
  }, [dispatch, fraction2, unit1, dataSource, currentPersona])

  useEffect(() => {
    dispatch(getMasterDownstreamProperties3(currentPersona, unit1, fraction3, dataSource))
  }, [dispatch, fraction3, unit1, dataSource, currentPersona])

  useEffect(() => {
    dispatch(getMasterDownstreamProperties4(currentPersona, unit1, fraction4, dataSource))
  }, [dispatch, fraction4, unit1, dataSource, currentPersona])

  useEffect(() => {
    dispatch(getMasterDownstreamProperties5(currentPersona, unit1, fraction5, dataSource))
  }, [dispatch, fraction5, unit1, dataSource, currentPersona])

  const camLimitData = _.get(crudeBlendChartData, 'camLimitNotification')

  useEffect(() => {
    dispatch(setCamLimitNotification(props.unit, selectedDate, dataSource))
  }, [dispatch, setCamLimitNotification, props.unit, selectedDate, dataSource])

  const cduFeedPropertyData = _.map(camLimitData, (item) => item.cduFeedProperty)
  const downstreamPropertyData = _.map(camLimitData, (item) => item.downstreamFeedProperty)

  return (
    <>
      {!_.isEmpty(selectedData) ? (
        <>
          <Divider />
          <Grid.Row className={`pad-x-1_5`}>
            <div className={`flex center space-between`}>
              <Header className={`mar-0`} size="small" textAlign="left">
                Blend Of {_.upperCase(moment(selectedDate).format("DD MMM"))}{" "}
                {moment(selectedDate).format("YYYY")}
              </Header>
              <Dropdown
                placeholder="Data Source"
                className={`fraction-unit-dropdown-container`}
                value={dataSource}
                selection
                options={crudeDataSources}
                onChange={(e, data) => {
                  setDataSource(data.value);
                  setCduFeed1("");
                  setCduFeed2("");
                  setCduFeed3("");
                  setCduFeed4("");
                  setCduFeed5("");
                  setFraction1("");
                  setFraction2("");
                  setFraction3("");
                  setFraction4("");
                  setFraction5("");
                  setProperties1("");
                  setProperties2("");
                  setProperties3("");
                  setProperties4("");
                  setProperties5("");
                }}
              />
            </div>
          </Grid.Row>

          <Grid.Row>
            <div>
              <div className={`single-crude-blend-container `}>
                <div className={`flex column property-container`}>
                  {!_.isEmpty(_.reduce(cduFeedPropertyData)) ? (
                    <div
                      className={`flex space-between pad-x-3 pad-y-1 property-cam-limit`}
                    >
                       <div className={`flex center`}>
                        <Image
                          className={`mar-y-0 mar-left-0 mar-right-1 icon-size`}
                          src={BlendConcernIcon}
                        />
                        <div className={`property-cam-limit-title`}>
                          Property exceeds CAM Limit for selected CDU Feed
                        </div>
                      </div>
                      <Popup
                        trigger={
                          <div className={`cam-limit-see-more`}>See More</div>
                        }
                        content={
                          <div className={`content-container pad-1`}>
                            <div className={`flex space-between`}>
                              <div
                                className={`property-cam-limit-content-title`}
                              >
                                Property exceeds CAM Limit for selected CDU Feed
                              </div>
                              <div
                                className={`property-cam-limit-content-unit`}
                              >
                                {props.unit}
                              </div>
                            </div>
                            <div
                              className={`flex border-bottom-container mar-top-0_5`}
                            >
                              <div
                                className={`property-cam-limit-content-header fraction-container`}
                              >
                                Fraction
                              </div>
                              <div
                                className={`property-cam-limit-content-header property-container`}
                              >
                                Property
                              </div>
                            </div>
                            <div className={`content-data-container`}>
                              {_.map(camLimitData, (item) =>
                                _.map(item.cduFeedProperty, (a) => (
                                  <div className={`flex`}>
                                    <div className={`fraction-container`}>
                                      {a.fraction}
                                    </div>
                                    <div className={`property-container`}>
                                      {a.property}
                                    </div>
                                  </div>
                                ))
                              )}
                            </div>
                          </div>
                        }
                        on="click"
                        hideOnScroll={false}
                        position="top right"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className={`flex pad-x-3 pad-y-2`}>
                    <div
                      className={`mar-top-1 mar-bottom-1_5`}
                      style={{ height: "310px" }}
                    >
                      {_.map(selectedData, (a) => (
                        <>
                          <div
                            style={{
                              background: `${a.color}`,
                              width: "75px",
                              height: `${_.isNull(a.value) ? 0 : a.value}%`,
                            }}
                          />
                        </>
                      ))}
                    </div>
                    <div className={`pad-x-2 processing-legend-container`}>
                      <div
                        className={`single-crude-chart-legend-title bold mar-top-1 mar-bottom-1_5`}
                      >
                        Processing:
                      </div>
                      <div className={`processing-legend`}>
                        {_.map(selectedData, (a) =>
                          !_.isNull(a.value) ? (
                            <div
                              className={`single-crude-chart-legend-container `}
                            >
                              <div
                                className={`single-crude-chart-legend-square`}
                                style={{ background: `${a.color}` }}
                              />
                              <div className={`single-crude-chart-legend-name`}>
                                {a.name}
                              </div>
                              <div
                                className={`single-crude-chart-legend-value`}
                              >
                                {stringToDecimalPoint(a.value, 1, 1)}%
                              </div>
                              <div
                                className={`single-crude-chart-legend-icons`}
                              >
                                {a.isNew ? (
                                  <Image src={NewIcon} />
                                ) : (
                                  <Image src={DisabledNewIcon} />
                                )}
                                {a.hasConcerns ? (
                                  <Image src={ConcernIcon} />
                                ) : (
                                  <Image src={DisabledConcernIcon} />
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                    </div>
                    <div className={`pad-left-2`}>
                      <div
                        className={`single-crude-chart-legend-title bold mar-top-1 mar-bottom-1_5`}
                      >
                        CDU Feed Property:
                      </div>

                      <div className={`single-crude-chart-cdu-container`}>
                        <Dropdown
                          placeholder="Select CDU Feed"
                          className={`dropdown-container`}
                          value={cduFeed1}
                          selection
                          search
                          options={cduFeedProperties}
                          onChange={(e, data) => {
                            setCduFeed1(data.value);
                          }}
                        />
                        <div
                          className={`property-value-container`}
                          style={{
                            color: `${
                              !_.get(
                                crudeBlendChartData.cduFeedValue1,
                                "isHighlighted"
                              )
                                ? ""
                                : "#E95258"
                            }`,
                            fontWeight: `${
                              !_.get(
                                crudeBlendChartData.cduFeedValue1,
                                "isHighlighted"
                              )
                                ? ""
                                : "700"
                            }`,
                          }}
                        >
                          {stringToDecimalPoint(
                            _.get(crudeBlendChartData.cduFeedValue1, "value"),
                            2,
                            2
                          )}
                        </div>
                      </div>
                      <div className={`single-crude-chart-cdu-container`}>
                        <Dropdown
                          placeholder="Select CDU Feed"
                          className={`dropdown-container`}
                          value={cduFeed2}
                          selection
                          search
                          options={cduFeedProperties}
                          onChange={(e, data) => {
                            setCduFeed2(data.value);
                          }}
                        />
                        <div
                          className={`property-value-container`}
                          style={{
                            color: `${
                              !_.get(
                                crudeBlendChartData.cduFeedValue2,
                                "isHighlighted"
                              )
                                ? ""
                                : "#E95258"
                            }`,
                            fontWeight: `${
                              !_.get(
                                crudeBlendChartData.cduFeedValue2,
                                "isHighlighted"
                              )
                                ? ""
                                : "700"
                            }`,
                          }}
                        >
                          {stringToDecimalPoint(
                            _.get(crudeBlendChartData.cduFeedValue2, "value"),
                            2,
                            2
                          )}
                        </div>
                      </div>
                      <div className={`single-crude-chart-cdu-container`}>
                        <Dropdown
                          placeholder="Select CDU Feed"
                          className={`dropdown-container`}
                          value={cduFeed3}
                          selection
                          search
                          options={cduFeedProperties}
                          onChange={(e, data) => {
                            setCduFeed3(data.value);
                          }}
                        />
                        <div
                          className={`property-value-container`}
                          style={{
                            color: `${
                              !_.get(
                                crudeBlendChartData.cduFeedValue3,
                                "isHighlighted"
                              )
                                ? ""
                                : "#E95258"
                            }`,
                            fontWeight: `${
                              !_.get(
                                crudeBlendChartData.cduFeedValue3,
                                "isHighlighted"
                              )
                                ? ""
                                : "700"
                            }`,
                          }}
                        >
                          {stringToDecimalPoint(
                            _.get(crudeBlendChartData.cduFeedValue3, "value"),
                            2,
                            2
                          )}
                        </div>
                      </div>
                      <div className={`single-crude-chart-cdu-container`}>
                        <Dropdown
                          placeholder="Select CDU Feed"
                          className={`dropdown-container`}
                          value={cduFeed4}
                          selection
                          search
                          options={cduFeedProperties}
                          onChange={(e, data) => {
                            setCduFeed4(data.value);
                          }}
                        />
                        <div
                          className={`property-value-container`}
                          style={{
                            color: `${
                              !_.get(
                                crudeBlendChartData.cduFeedValue4,
                                "isHighlighted"
                              )
                                ? ""
                                : "#E95258"
                            }`,
                            fontWeight: `${
                              !_.get(
                                crudeBlendChartData.cduFeedValue4,
                                "isHighlighted"
                              )
                                ? ""
                                : "700"
                            }`,
                          }}
                        >
                          {stringToDecimalPoint(
                            _.get(crudeBlendChartData.cduFeedValue4, "value"),
                            2,
                            2
                          )}
                        </div>
                      </div>
                      <div className={`single-crude-chart-cdu-container`}>
                        <Dropdown
                          placeholder="Select CDU Feed"
                          className={`dropdown-container`}
                          value={cduFeed5}
                          selection
                          search
                          options={cduFeedProperties}
                          onChange={(e, data) => {
                            setCduFeed5(data.value);
                          }}
                        />
                        <div
                          className={`property-value-container`}
                          style={{
                            color: `${
                              !_.get(
                                crudeBlendChartData.cduFeedValue5,
                                "isHighlighted"
                              )
                                ? ""
                                : "#E95258"
                            }`,
                            fontWeight: `${
                              !_.get(
                                crudeBlendChartData.cduFeedValue5,
                                "isHighlighted"
                              )
                                ? ""
                                : "700"
                            }`,
                          }}
                        >
                          {stringToDecimalPoint(
                            _.get(crudeBlendChartData.cduFeedValue5, "value"),
                            2,
                            2
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`flex column mar-left-1 property-container`}>
                  {!_.isEmpty(_.reduce(downstreamPropertyData)) ? (
                    <div
                      className={`flex space-between pad-x-3 pad-y-1 property-cam-limit`}
                    >
                       <div className={`flex center`}>
                        <Image
                          className={`mar-y-0 mar-left-0 mar-right-1 icon-size`}
                          src={BlendConcernIcon}
                        />
                        <div className={`property-cam-limit-title`}>
                          Property exceeds CAM Limit for all units and fractions
                        </div>
                      </div>
                      <Popup
                        trigger={
                          <div className={`cam-limit-see-more`}>See More</div>
                        }
                        content={
                          <div className={`content-container pad-1`}>
                            <div className={`flex space-between`}>
                              <div
                                className={`property-cam-limit-content-title`}
                              >
                                Property exceeds CAM Limit for all units and fractions
                              </div>
                            </div>
                            <div
                              className={`flex border-bottom-container mar-top-0_5`}
                            >
                              <div
                                className={`property-cam-limit-content-header fraction-container`}
                              >
                                Unit
                              </div>
                              <div
                                className={`property-cam-limit-content-header fraction-container`}
                              >
                                Fraction
                              </div>
                              <div
                                className={`property-cam-limit-content-header property-container`}
                              >
                                Property
                              </div>
                            </div>
                            <div className={`content-data-container`}>
                              {_.map(camLimitData, (item) =>
                                _.map(item.downstreamFeedProperty, (a) => (
                                  <div className={`flex`}>
                                    <div className={`fraction-container`}>
                                      {a.unit}
                                    </div>
                                    <div className={`fraction-container`}>
                                      {a.fraction}
                                    </div>
                                    <div className={`property-container`}>
                                      {a.property}
                                    </div>
                                  </div>
                                ))
                              )}
                            </div>
                          </div>
                        }
                        on="click"
                        hideOnScroll={false}
                        position="top right"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className={`pad-x-3 pad-y-2 `}>
                    <div className={`flex center space-between mar-bottom-1`}>
                      <div
                        className={`single-crude-chart-legend-title bold mar-0`}
                      >
                        Downstream Property:
                      </div>
                      <Dropdown
                        placeholder="Process Unit"
                        className={`fraction-unit-dropdown-container`}
                        value={unit1}
                        selection
                        options={downstreamUnits}
                        onChange={(e, data) => {
                          setUnit1(data.value);
                          setFraction1("");
                          setFraction2("");
                          setFraction3("");
                          setFraction4("");
                          setFraction5("");
                          setProperties1("");
                          setProperties2("");
                          setProperties3("");
                          setProperties4("");
                          setProperties5("");
                        }}
                      />
                    </div>

                    <div className={`flex`}>
                      <div>
                        <div className={`single-crude-chart-cdu-container`}>
                          <Dropdown
                            placeholder="Select Fraction"
                            className={`dropdown-container`}
                            value={fraction1}
                            selection
                            search
                            options={downstreamFractions}
                            onChange={(e, data) => {
                              setFraction1(data.value);
                              setProperties1("");
                            }}
                          />
                        </div>
                      </div>
                      <div className={`pad-left-0_5`}>
                        <div className={`single-crude-chart-cdu-container`}>
                          <Dropdown
                            placeholder="Select Property"
                            className={`dropdown-container`}
                            value={properties1}
                            selection
                            search
                            options={downstreamProperties}
                            onChange={(e, data) => {
                              setProperties1(data.value);
                            }}
                          />
                          <div
                            className={`property-value-container`}
                            style={{
                              color: `${
                                !_.get(
                                  crudeBlendChartData.downstreamValue1,
                                  "isHighlighted"
                                )
                                  ? ""
                                  : "#E95258"
                              }`,
                              fontWeight: `${
                                !_.get(
                                  crudeBlendChartData.downstreamValue1,
                                  "isHighlighted"
                                )
                                  ? ""
                                  : "700"
                              }`,
                            }}
                          >
                            {stringToDecimalPoint(
                              _.get(
                                crudeBlendChartData.downstreamValue1,
                                "value"
                              ),
                              2,
                              2
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`flex`}>
                      <div>
                        <div className={`single-crude-chart-cdu-container`}>
                          <Dropdown
                            placeholder="Select Fraction"
                            className={`dropdown-container`}
                            value={fraction2}
                            selection
                            search
                            options={downstreamFractions}
                            onChange={(e, data) => {
                              setFraction2(data.value);
                              setProperties2("");
                            }}
                          />
                        </div>
                      </div>
                      <div className={`pad-left-0_5`}>
                        <div className={`single-crude-chart-cdu-container`}>
                          <Dropdown
                            placeholder="Select Property"
                            className={`dropdown-container`}
                            value={properties2}
                            selection
                            search
                            options={downstreamProperties2}
                            onChange={(e, data) => {
                              setProperties2(data.value);
                            }}
                          />
                          <div
                            className={`property-value-container`}
                            style={{
                              color: `${
                                !_.get(
                                  crudeBlendChartData.downstreamValue2,
                                  "isHighlighted"
                                )
                                  ? ""
                                  : "#E95258"
                              }`,
                              fontWeight: `${
                                !_.get(
                                  crudeBlendChartData.downstreamValue2,
                                  "isHighlighted"
                                )
                                  ? ""
                                  : "700"
                              }`,
                            }}
                          >
                            {stringToDecimalPoint(
                              _.get(
                                crudeBlendChartData.downstreamValue2,
                                "value"
                              ),
                              2,
                              2
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`flex`}>
                      <div>
                        <div className={`single-crude-chart-cdu-container`}>
                          <Dropdown
                            placeholder="Select Fraction"
                            className={`dropdown-container`}
                            value={fraction3}
                            selection
                            search
                            options={downstreamFractions}
                            onChange={(e, data) => {
                              setFraction3(data.value);
                              setProperties3("");
                            }}
                          />
                        </div>
                      </div>
                      <div className={`pad-left-0_5`}>
                        <div className={`single-crude-chart-cdu-container`}>
                          <Dropdown
                            placeholder="Select Property"
                            className={`dropdown-container`}
                            value={properties3}
                            selection
                            search
                            options={downstreamProperties3}
                            onChange={(e, data) => {
                              setProperties3(data.value);
                            }}
                          />
                          <div
                            className={`property-value-container`}
                            style={{
                              color: `${
                                !_.get(
                                  crudeBlendChartData.downstreamValue3,
                                  "isHighlighted"
                                )
                                  ? ""
                                  : "#E95258"
                              }`,
                              fontWeight: `${
                                !_.get(
                                  crudeBlendChartData.downstreamValue3,
                                  "isHighlighted"
                                )
                                  ? ""
                                  : "700"
                              }`,
                            }}
                          >
                            {stringToDecimalPoint(
                              _.get(
                                crudeBlendChartData.downstreamValue3,
                                "value"
                              ),
                              2,
                              2
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`flex`}>
                      <div>
                        <div className={`single-crude-chart-cdu-container`}>
                          <Dropdown
                            placeholder="Select Fraction"
                            className={`dropdown-container`}
                            value={fraction4}
                            selection
                            search
                            options={downstreamFractions}
                            onChange={(e, data) => {
                              setFraction4(data.value);
                              setProperties4("");
                            }}
                          />
                        </div>
                      </div>
                      <div className={`pad-left-0_5`}>
                        <div className={`single-crude-chart-cdu-container`}>
                          <Dropdown
                            placeholder="Select Property"
                            className={`dropdown-container`}
                            value={properties4}
                            selection
                            search
                            options={downstreamProperties4}
                            onChange={(e, data) => {
                              setProperties4(data.value);
                            }}
                          />
                          <div
                            className={`property-value-container`}
                            style={{
                              color: `${
                                !_.get(
                                  crudeBlendChartData.downstreamValue4,
                                  "isHighlighted"
                                )
                                  ? ""
                                  : "#E95258"
                              }`,
                              fontWeight: `${
                                !_.get(
                                  crudeBlendChartData.downstreamValue4,
                                  "isHighlighted"
                                )
                                  ? ""
                                  : "700"
                              }`,
                            }}
                          >
                            {stringToDecimalPoint(
                              _.get(
                                crudeBlendChartData.downstreamValue4,
                                "value"
                              ),
                              2,
                              2
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`flex`}>
                      <div>
                        <div className={`single-crude-chart-cdu-container`}>
                          <Dropdown
                            placeholder="Select Fraction"
                            className={`dropdown-container`}
                            value={fraction5}
                            selection
                            search
                            options={downstreamFractions}
                            onChange={(e, data) => {
                              setFraction5(data.value);
                              setProperties5("");
                            }}
                          />
                        </div>
                      </div>
                      <div className={`pad-left-0_5`}>
                        <div className={`single-crude-chart-cdu-container`}>
                          <Dropdown
                            placeholder="Select Property"
                            className={`dropdown-container`}
                            value={properties5}
                            selection
                            search
                            options={downstreamProperties5}
                            onChange={(e, data) => {
                              setProperties5(data.value);
                            }}
                          />
                          <div
                            className={`property-value-container`}
                            style={{
                              color: `${
                                !_.get(
                                  crudeBlendChartData.downstreamValue5,
                                  "isHighlighted"
                                )
                                  ? ""
                                  : "#E95258"
                              }`,
                              fontWeight: `${
                                !_.get(
                                  crudeBlendChartData.downstreamValue5,
                                  "isHighlighted"
                                )
                                  ? ""
                                  : "700"
                              }`,
                            }}
                          >
                            {stringToDecimalPoint(
                              _.get(
                                crudeBlendChartData.downstreamValue5,
                                "value"
                              ),
                              2,
                              2
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid.Row>
        </>
      ) : (
        <>
          <Divider />
          <Grid.Row className={`pad-x-1_5`}>
            <div className={`flex center space-between`}>
              <Header className={`mar-0`} size="small" textAlign="left">
                Blend Of {_.upperCase(moment().format("DD MMM YYYY"))}
              </Header>
              <Dropdown
                placeholder="Data Source"
                className={`fraction-unit-dropdown-container`}
                value={dataSource}
                selection
                options={crudeDataSources}
                onChange={(e, data) => {
                  setDataSource(data.value);
                  setCduFeed1("");
                  setCduFeed2("");
                  setCduFeed3("");
                  setCduFeed4("");
                  setCduFeed5("");
                  setFraction1("");
                  setFraction2("");
                  setFraction3("");
                  setFraction4("");
                  setFraction5("");
                  setProperties1("");
                  setProperties2("");
                  setProperties3("");
                  setProperties4("");
                  setProperties5("");
                }}
              />
            </div>
          </Grid.Row>

          <Grid.Row>
            <div>
              <div className={`single-crude-blend-container `}>
                <div className={`flex column property-container`}>
                  {!_.isEmpty(_.reduce(cduFeedPropertyData)) ? (
                    <div
                      className={`flex space-between pad-x-3 pad-y-1 property-cam-limit`}
                    >
                      <div className={`flex center`}>
                        <Image
                          className={`mar-y-0 mar-left-0 mar-right-1 icon-size`}
                          src={BlendConcernIcon}
                        />
                        <div className={`property-cam-limit-title`}>
                            Property exceeds CAM Limit for selected CDU Feed
                        </div>
                      </div>
                      <Popup
                        trigger={
                          <div className={`cam-limit-see-more`}>See More</div>
                        }
                        content={
                          <div className={`content-container pad-1`}>
                            <div className={`flex space-between`}>
                              <div
                                className={`property-cam-limit-content-title`}
                              >
                                Property exceeds CAM Limit for selected CDU Feed
                              </div>
                              <div
                                className={`property-cam-limit-content-unit`}
                              >
                                {props.unit}
                              </div>
                            </div>
                            <div
                              className={`flex border-bottom-container mar-top-0_5`}
                            >
                              <div
                                className={`property-cam-limit-content-header fraction-container`}
                              >
                                Fraction
                              </div>
                              <div
                                className={`property-cam-limit-content-header property-container`}
                              >
                                Property
                              </div>
                            </div>
                            <div className={`content-data-container`}>
                              {_.map(camLimitData, (item) =>
                                _.map(item.cduFeedProperty, (a) => (
                                  <div className={`flex`}>
                                    <div className={`fraction-container`}>
                                      {a.fraction}
                                    </div>
                                    <div className={`property-container`}>
                                      {a.property}
                                    </div>
                                  </div>
                                ))
                              )}
                            </div>
                          </div>
                        }
                        on="click"
                        hideOnScroll={false}
                        position="top right"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className={`flex pad-x-3 pad-y-2`}>
                    <div
                      className={`mar-top-1 mar-bottom-1_5`}
                      style={{ height: "310px" }}
                    >
                      {_.map(defaultSelectedData, (a) => (
                        <>
                          <div
                            style={{
                              background: `${a.color}`,
                              width: "75px",
                              height: `${_.isNull(a.value) ? 0 : a.value}%`,
                            }}
                          />
                        </>
                      ))}
                    </div>
                    <div className={`pad-x-2 processing-legend-container`}>
                      <div
                        className={`single-crude-chart-legend-title bold mar-top-1 mar-bottom-1_5`}
                      >
                        Processing:
                      </div>
                      <div className={`processing-legend`}>
                        {_.map(defaultSelectedData, (a) =>
                          !_.isNull(a.value) ? (
                            <div
                              className={`single-crude-chart-legend-container `}
                            >
                              <div
                                className={`single-crude-chart-legend-square`}
                                style={{ background: `${a.color}` }}
                              />
                              <div className={`single-crude-chart-legend-name`}>
                                {a.name}
                              </div>
                              <div
                                className={`single-crude-chart-legend-value`}
                              >
                                {stringToDecimalPoint(a.value, 1, 1)}%
                              </div>
                              <div
                                className={`single-crude-chart-legend-icons`}
                              >
                                {a.isNew ? (
                                  <Image src={NewIcon} />
                                ) : (
                                  <Image src={DisabledNewIcon} />
                                )}
                                {a.hasConcerns ? (
                                  <Image src={ConcernIcon} />
                                ) : (
                                  <Image src={DisabledConcernIcon} />
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                    </div>
                    <div className={`pad-left-2`}>
                      <div
                        className={`single-crude-chart-legend-title bold mar-top-1 mar-bottom-1_5`}
                      >
                        CDU Feed Property:
                      </div>

                      <div className={`single-crude-chart-cdu-container`}>
                        <Dropdown
                          placeholder="Select CDU Feed"
                          className={`dropdown-container`}
                          value={cduFeed1}
                          selection
                          search
                          options={cduFeedProperties}
                          onChange={(e, data) => {
                            setCduFeed1(data.value);
                          }}
                        />
                        <div
                          className={`property-value-container`}
                          style={{
                            color: `${
                              !_.get(
                                crudeBlendChartData.cduFeedValue1,
                                "isHighlighted"
                              )
                                ? ""
                                : "#E95258"
                            }`,
                            fontWeight: `${
                              !_.get(
                                crudeBlendChartData.cduFeedValue1,
                                "isHighlighted"
                              )
                                ? ""
                                : "700"
                            }`,
                          }}
                        >
                          {stringToDecimalPoint(
                            _.get(crudeBlendChartData.cduFeedValue1, "value"),
                            2,
                            2
                          )}
                        </div>
                      </div>
                      <div className={`single-crude-chart-cdu-container`}>
                        <Dropdown
                          placeholder="Select CDU Feed"
                          className={`dropdown-container`}
                          value={cduFeed2}
                          selection
                          search
                          options={cduFeedProperties}
                          onChange={(e, data) => {
                            setCduFeed2(data.value);
                          }}
                        />
                        <div
                          className={`property-value-container`}
                          style={{
                            color: `${
                              !_.get(
                                crudeBlendChartData.cduFeedValue2,
                                "isHighlighted"
                              )
                                ? ""
                                : "#E95258"
                            }`,
                            fontWeight: `${
                              !_.get(
                                crudeBlendChartData.cduFeedValue2,
                                "isHighlighted"
                              )
                                ? ""
                                : "700"
                            }`,
                          }}
                        >
                          {stringToDecimalPoint(
                            _.get(crudeBlendChartData.cduFeedValue2, "value"),
                            2,
                            2
                          )}
                        </div>
                      </div>
                      <div className={`single-crude-chart-cdu-container`}>
                        <Dropdown
                          placeholder="Select CDU Feed"
                          className={`dropdown-container`}
                          value={cduFeed3}
                          selection
                          search
                          options={cduFeedProperties}
                          onChange={(e, data) => {
                            setCduFeed3(data.value);
                          }}
                        />
                        <div
                          className={`property-value-container`}
                          style={{
                            color: `${
                              !_.get(
                                crudeBlendChartData.cduFeedValue3,
                                "isHighlighted"
                              )
                                ? ""
                                : "#E95258"
                            }`,
                            fontWeight: `${
                              !_.get(
                                crudeBlendChartData.cduFeedValue3,
                                "isHighlighted"
                              )
                                ? ""
                                : "700"
                            }`,
                          }}
                        >
                          {stringToDecimalPoint(
                            _.get(crudeBlendChartData.cduFeedValue3, "value"),
                            2,
                            2
                          )}
                        </div>
                      </div>
                      <div className={`single-crude-chart-cdu-container`}>
                        <Dropdown
                          placeholder="Select CDU Feed"
                          className={`dropdown-container`}
                          value={cduFeed4}
                          selection
                          search
                          options={cduFeedProperties}
                          onChange={(e, data) => {
                            setCduFeed4(data.value);
                          }}
                        />
                        <div
                          className={`property-value-container`}
                          style={{
                            color: `${
                              !_.get(
                                crudeBlendChartData.cduFeedValue4,
                                "isHighlighted"
                              )
                                ? ""
                                : "#E95258"
                            }`,
                            fontWeight: `${
                              !_.get(
                                crudeBlendChartData.cduFeedValue4,
                                "isHighlighted"
                              )
                                ? ""
                                : "700"
                            }`,
                          }}
                        >
                          {stringToDecimalPoint(
                            _.get(crudeBlendChartData.cduFeedValue4, "value"),
                            2,
                            2
                          )}
                        </div>
                      </div>
                      <div className={`single-crude-chart-cdu-container`}>
                        <Dropdown
                          placeholder="Select CDU Feed"
                          className={`dropdown-container`}
                          value={cduFeed5}
                          selection
                          search
                          options={cduFeedProperties}
                          onChange={(e, data) => {
                            setCduFeed5(data.value);
                          }}
                        />
                        <div
                          className={`property-value-container`}
                          style={{
                            color: `${
                              !_.get(
                                crudeBlendChartData.cduFeedValue5,
                                "isHighlighted"
                              )
                                ? ""
                                : "#E95258"
                            }`,
                            fontWeight: `${
                              !_.get(
                                crudeBlendChartData.cduFeedValue5,
                                "isHighlighted"
                              )
                                ? ""
                                : "700"
                            }`,
                          }}
                        >
                          {stringToDecimalPoint(
                            _.get(crudeBlendChartData.cduFeedValue5, "value"),
                            2,
                            2
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`flex column mar-left-1 property-container`}>
                  {!_.isEmpty(_.reduce(downstreamPropertyData)) ? (
                    <div
                      className={`flex space-between pad-x-3 pad-y-1 property-cam-limit`}
                    >
                       <div className={`flex center`}>
                        <Image
                          className={`mar-y-0 mar-left-0 mar-right-1 icon-size`}
                          src={BlendConcernIcon}
                        />
                        <div className={`property-cam-limit-title`}>
                        Property exceeds CAM Limit for all units and fractions
                        </div>
                      </div>
                      <Popup
                        trigger={
                          <div className={`cam-limit-see-more`}>See More</div>
                        }
                        content={
                          <div className={`content-container pad-1`}>
                            <div className={`flex space-between`}>
                              <div
                                className={`property-cam-limit-content-title`}
                              >
                                Property exceeds CAM Limit for all units and fractions
                              </div>
                            </div>
                            <div
                              className={`flex border-bottom-container mar-top-0_5`}
                            >
                              <div
                                className={`property-cam-limit-content-header fraction-container`}
                              >
                                Unit
                              </div>
                              <div
                                className={`property-cam-limit-content-header fraction-container`}
                              >
                                Fraction
                              </div>
                              <div
                                className={`property-cam-limit-content-header property-container`}
                              >
                                Property
                              </div>
                            </div>
                            <div className={`content-data-container`}>
                              {_.map(camLimitData, (item) =>
                                _.map(item.downstreamFeedProperty, (a) => (
                                  <div className={`flex`}>
                                    <div className={`fraction-container`}>
                                      {a.unit}
                                    </div>
                                    <div className={`fraction-container`}>
                                      {a.fraction}
                                    </div>
                                    <div className={`property-container`}>
                                      {a.property}
                                    </div>
                                  </div>
                                ))
                              )}
                            </div>
                          </div>
                        }
                        on="click"
                        hideOnScroll={false}
                        position="top right"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className={`pad-x-3 pad-y-2 `}>
                    <div className={`flex center space-between mar-bottom-1`}>
                      <div
                        className={`single-crude-chart-legend-title bold mar-0`}
                      >
                        Downstream Property:
                      </div>
                      <Dropdown
                        placeholder="Process Unit"
                        className={`fraction-unit-dropdown-container`}
                        value={unit1}
                        selection
                        options={downstreamUnits}
                        onChange={(e, data) => {
                          setUnit1(data.value);
                          setFraction1("");
                          setFraction2("");
                          setFraction3("");
                          setFraction4("");
                          setFraction5("");
                          setProperties1("");
                          setProperties2("");
                          setProperties3("");
                          setProperties4("");
                          setProperties5("");
                        }}
                      />
                    </div>

                    <div className={`flex`}>
                      <div>
                        <div className={`single-crude-chart-cdu-container`}>
                          <Dropdown
                            placeholder="Select Fraction"
                            className={`dropdown-container`}
                            value={fraction1}
                            selection
                            search
                            options={downstreamFractions}
                            onChange={(e, data) => {
                              setFraction1(data.value);
                              setProperties1("");
                            }}
                          />
                        </div>
                      </div>
                      <div className={`pad-left-0_5`}>
                        <div className={`single-crude-chart-cdu-container`}>
                          <Dropdown
                            placeholder="Select Property"
                            className={`dropdown-container`}
                            value={properties1}
                            selection
                            search
                            options={downstreamProperties}
                            onChange={(e, data) => {
                              setProperties1(data.value);
                            }}
                          />
                          <div
                            className={`property-value-container`}
                            style={{
                              color: `${
                                !_.get(
                                  crudeBlendChartData.downstreamValue1,
                                  "isHighlighted"
                                )
                                  ? ""
                                  : "#E95258"
                              }`,
                              fontWeight: `${
                                !_.get(
                                  crudeBlendChartData.downstreamValue1,
                                  "isHighlighted"
                                )
                                  ? ""
                                  : "700"
                              }`,
                            }}
                          >
                            {stringToDecimalPoint(
                              _.get(
                                crudeBlendChartData.downstreamValue1,
                                "value"
                              ),
                              2,
                              2
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`flex`}>
                      <div>
                        <div className={`single-crude-chart-cdu-container`}>
                          <Dropdown
                            placeholder="Select Fraction"
                            className={`dropdown-container`}
                            value={fraction2}
                            selection
                            search
                            options={downstreamFractions}
                            onChange={(e, data) => {
                              setFraction2(data.value);
                              setProperties2("");
                            }}
                          />
                        </div>
                      </div>
                      <div className={`pad-left-0_5`}>
                        <div className={`single-crude-chart-cdu-container`}>
                          <Dropdown
                            placeholder="Select Property"
                            className={`dropdown-container`}
                            value={properties2}
                            selection
                            search
                            options={downstreamProperties2}
                            onChange={(e, data) => {
                              setProperties2(data.value);
                            }}
                          />
                          <div
                            className={`property-value-container`}
                            style={{
                              color: `${
                                !_.get(
                                  crudeBlendChartData.downstreamValue2,
                                  "isHighlighted"
                                )
                                  ? ""
                                  : "#E95258"
                              }`,
                              fontWeight: `${
                                !_.get(
                                  crudeBlendChartData.downstreamValue2,
                                  "isHighlighted"
                                )
                                  ? ""
                                  : "700"
                              }`,
                            }}
                          >
                            {stringToDecimalPoint(
                              _.get(
                                crudeBlendChartData.downstreamValue2,
                                "value"
                              ),
                              2,
                              2
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`flex`}>
                      <div>
                        <div className={`single-crude-chart-cdu-container`}>
                          <Dropdown
                            placeholder="Select Fraction"
                            className={`dropdown-container`}
                            value={fraction3}
                            selection
                            search
                            options={downstreamFractions}
                            onChange={(e, data) => {
                              setFraction3(data.value);
                              setProperties3("");
                            }}
                          />
                        </div>
                      </div>
                      <div className={`pad-left-0_5`}>
                        <div className={`single-crude-chart-cdu-container`}>
                          <Dropdown
                            placeholder="Select Property"
                            className={`dropdown-container`}
                            value={properties3}
                            selection
                            search
                            options={downstreamProperties3}
                            onChange={(e, data) => {
                              setProperties3(data.value);
                            }}
                          />
                          <div
                            className={`property-value-container`}
                            style={{
                              color: `${
                                !_.get(
                                  crudeBlendChartData.downstreamValue3,
                                  "isHighlighted"
                                )
                                  ? ""
                                  : "#E95258"
                              }`,
                              fontWeight: `${
                                !_.get(
                                  crudeBlendChartData.downstreamValue3,
                                  "isHighlighted"
                                )
                                  ? ""
                                  : "700"
                              }`,
                            }}
                          >
                            {stringToDecimalPoint(
                              _.get(
                                crudeBlendChartData.downstreamValue3,
                                "value"
                              ),
                              2,
                              2
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`flex`}>
                      <div>
                        <div className={`single-crude-chart-cdu-container`}>
                          <Dropdown
                            placeholder="Select Fraction"
                            className={`dropdown-container`}
                            value={fraction4}
                            selection
                            search
                            options={downstreamFractions}
                            onChange={(e, data) => {
                              setFraction4(data.value);
                              setProperties4("");
                            }}
                          />
                        </div>
                      </div>
                      <div className={`pad-left-0_5`}>
                        <div className={`single-crude-chart-cdu-container`}>
                          <Dropdown
                            placeholder="Select Property"
                            className={`dropdown-container`}
                            value={properties4}
                            selection
                            search
                            options={downstreamProperties4}
                            onChange={(e, data) => {
                              setProperties4(data.value);
                            }}
                          />
                          <div
                            className={`property-value-container`}
                            style={{
                              color: `${
                                !_.get(
                                  crudeBlendChartData.downstreamValue4,
                                  "isHighlighted"
                                )
                                  ? ""
                                  : "#E95258"
                              }`,
                              fontWeight: `${
                                !_.get(
                                  crudeBlendChartData.downstreamValue4,
                                  "isHighlighted"
                                )
                                  ? ""
                                  : "700"
                              }`,
                            }}
                          >
                            {stringToDecimalPoint(
                              _.get(
                                crudeBlendChartData.downstreamValue4,
                                "value"
                              ),
                              2,
                              2
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`flex`}>
                      <div>
                        <div className={`single-crude-chart-cdu-container`}>
                          <Dropdown
                            placeholder="Select Fraction"
                            className={`dropdown-container`}
                            value={fraction5}
                            selection
                            search
                            options={downstreamFractions}
                            onChange={(e, data) => {
                              setFraction5(data.value);
                              setProperties5("");
                            }}
                          />
                        </div>
                      </div>
                      <div className={`pad-left-0_5`}>
                        <div className={`single-crude-chart-cdu-container`}>
                          <Dropdown
                            placeholder="Select Property"
                            className={`dropdown-container`}
                            value={properties5}
                            selection
                            search
                            options={downstreamProperties5}
                            onChange={(e, data) => {
                              setProperties5(data.value);
                            }}
                          />
                          <div
                            className={`property-value-container`}
                            style={{
                              color: `${
                                !_.get(
                                  crudeBlendChartData.downstreamValue5,
                                  "isHighlighted"
                                )
                                  ? ""
                                  : "#E95258"
                              }`,
                              fontWeight: `${
                                !_.get(
                                  crudeBlendChartData.downstreamValue5,
                                  "isHighlighted"
                                )
                                  ? ""
                                  : "700"
                              }`,
                            }}
                          >
                            {stringToDecimalPoint(
                              _.get(
                                crudeBlendChartData.downstreamValue5,
                                "value"
                              ),
                              2,
                              2
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid.Row>
        </>
      )}
    </>
  );
}

const CrudeBlend = (props) => {
  const dispatch = useDispatch()

  const [{ units }] = useStateWithPaths([`${MASTER_DATA_REDUCER}`])

  const [{ currentPersona }] = useStateWithPaths([`${PERSONAL_SETTING_REDUCER}`])

  const [crudeBlendChartData] = useStateWithPaths(['landingPage.crudeBlendChart'])
  const [periods] = useStateWithPaths(['landingPage.crudeBlendChart.period'])

  const [defaultPeriod] = useState([crudeBlendChartData.defaultPeriod])
  const [period, setPeriod] = useState([periods])

  const [unit, setUnit] = useState(crudeBlendChartData.unit)

  const getCrudeBlendData = _.get(crudeBlendChartData, 'data')

  useEffect(() => {
    dispatch(setCrudeBlendChart(period, unit, currentPersona))
    dispatch(setCrudeBlendSelectedChartData())
    dispatch(getMasterUnits(currentPersona))
  }, [dispatch, period, unit, currentPersona])

  return (
    <div>
      <Grid padded className={`crude-blend-chart`}>
        <Grid.Row columns={2} verticalAlign="bottom">
          <Grid.Column textAlign="left">
            <Header size="small" textAlign="left">
              CRUDE BLEND
            </Header>
          </Grid.Column>
          <Grid.Column>
            <div className={`flex end`}>
              <Dropdown
                placeholder="Select unit"
                className={`cdu-unit-dropdown-container`}
                value={unit}
                defaultValue={unit}
                selection
                options={units}
                onChange={(e, data) => {
                  setUnit(data.value)
                  dispatch(setCrudeBlendSelectedChartData())
                }}
              />
              <PeriodPicker
                defaultPeriod={defaultPeriod}
                period={period}
                setPeriod={setPeriod}
                onClick={setCrudeBlendChart(period, unit)}
              />
            </div>
          </Grid.Column>
        </Grid.Row>

        <Segment className={`width-100`}>
          <Grid.Row columns={12}>
            <div className={`spacing-2`}>
              <GetLegend />
            </div>
            <div className={`spacing-2`}>
              <Legend>
              <LegendItem icon={IncompabilityIconBlend} title={'Incompatibility'} />
                <LegendItem icon={NewIcon} title={'New Crude'} />
                <LegendItem icon={BlendConcernIcon} title={'Potential Blend Concerns'} />
                <LegendItem icon={ConcernIcon} title={'Potential Crude Concerns'} />
                
              </Legend>
            </div>
          </Grid.Row>

          <Divider />
          <FetchStateWrapper id={CrudeBlendActions.POST_CRUDE_BLEND_CHART}>
            <Grid.Row columns={12}>
              <div className={` crude-blend-container pad-bottom-2 flex center`}>
                <Button
                  className={``}
                  onClick={() => {
                    const startDate = moment(_.get(getCrudeBlendData, 'prevStartDate'))
                    const endDate = moment(_.get(getCrudeBlendData, 'prevEndDate'))
                    return (
                      setPeriod([
                        {
                          startDate: startDate._d,
                          endDate: endDate._d,
                          key: 'selection',
                        },
                      ]),
                      dispatch(setCrudeBlendSelectedChartData())
                    )
                  }}
                >
                  <Chevron direction="left" />
                </Button>

                {_.isEmpty(_.get(crudeBlendChartData, 'data.series')) ? (
                  <NoDataBlock />
                ) : (
                  <div className={`flex column width-100`}>
                    <div className={`flex space-between pad-x-2 icon-status-chart`}>
                      {_.map(_.get(crudeBlendChartData, 'data.flagList'), (item) => (
                        <div className={`flex column icon-size`}>
                          <Image
                            hidden={!item.isIncompatible}
                            className={`mar-0 icon-size`}
                            src={IncompabilityIconBlend}
                          />
                          <Image hidden={!item.isNew} className={`mar-0 icon-size`} src={NewIcon} />
                          <Image
                            hidden={!item.hasBlendConcerns}
                            className={`mar-0 icon-size`}
                            src={BlendConcernIcon}
                          />
                          <Image
                            hidden={!item.hasConcerns}
                            className={`mar-0 icon-size`}
                            src={ConcernIcon}
                          />
                        </div>
                      ))}
                    </div>
                    <CrudeBlendChart crudeBlendData={crudeBlendChartData} />
                  </div>
                )}

                <Button
                  onClick={() => {
                    const startDate = moment(_.get(getCrudeBlendData, 'nextStartDate'))
                    const endDate = moment(_.get(getCrudeBlendData, 'nextEndDate'))
                    return (
                      setPeriod([
                        {
                          startDate: startDate._d,
                          endDate: endDate._d,
                          key: 'selection',
                        },
                      ]),
                      dispatch(setCrudeBlendSelectedChartData())
                    )
                  }}
                >
                  <Chevron direction="right" />
                </Button>
              </div>
            </Grid.Row>
          </FetchStateWrapper>
          <CrudeBlendSelected unit={unit} period={period} />
        </Segment>
      </Grid>
    </div>
  )
}

export default CrudeBlend
