export default {
    "data": [
        {
            "CHP": "Champion",
			"SOK": "Sokol",
			"AMB": "Arab Medium Banoco"
        },
        {
            "Champion": "CHP",
			"Sokol": "SOK",
			"Arab Medium Banoco": "AMB"
        }
    ]
}