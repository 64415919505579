import * as services from '../services/serviceRisks'
import { fetchStart, fetchSuccess, fetchError } from './common/actionFetch'

// Constant
import * as landingPageConstant from '../../src/containers/views/landing-page/helper'

export const RISKS_RESULT_TABLE = 'RISKS_RESULT_TABLE'
export const getRiskResultTable = (searchObject) => {
  return (dispatch) => {
    dispatch(fetchStart(RISKS_RESULT_TABLE))

    if (searchObject.location.options === landingPageConstant.SEARCH_OPTIONS.SingleCrude) {
      services
        .postRiskResultTableSingleCrude({
          options: searchObject.location.options,
          mode: searchObject.mode,
          crudeNameAbbreviation: searchObject.location.crudeNameAbbreviation,
          currentPersona: searchObject.location.currentPersona,
          paramters: searchObject.paramters,
          riskRating: searchObject.riskRating,
          source: searchObject.source,
          propertyRange: searchObject.propertyRange,
          property: searchObject.properties,
        })
        .then((response) =>
          dispatch({
            type: RISKS_RESULT_TABLE,
            payload: response,
          })
        )
        .then(() => dispatch(fetchSuccess(RISKS_RESULT_TABLE)))
        .catch(() => dispatch(fetchError(RISKS_RESULT_TABLE)))
    } else {
      services
        .postRiskResultTableCustomBlend({
          options: searchObject.location.options,
          mode: searchObject.mode,
          blendComposition: searchObject.location.inputUnits,
          currentPersona: searchObject.location.currentPersona,
          paramters: searchObject.paramters,
          riskRating: searchObject.riskRating,
          source: searchObject.source,
          propertyRange: searchObject.propertyRange,
          property: searchObject.properties,
        })
        .then((response) =>
          dispatch({
            type: RISKS_RESULT_TABLE,
            payload: response,
          })
        )
        .then(() => dispatch(fetchSuccess(RISKS_RESULT_TABLE)))
        .catch(() => dispatch(fetchError(RISKS_RESULT_TABLE)))
    }
  }
}

export const RISKS_SAVE_FEEDBACK = 'RISKS_SAVE_FEEDBACK'
export const saveFeedback = (searchObject) => {
  return (dispatch) => {
    dispatch(fetchStart(RISKS_SAVE_FEEDBACK))
    services
      .postSaveFeedback({
        options: searchObject.options,
        currentPersona: searchObject.persona,
        inputUnits: searchObject.inputUnits,
        crudeNameAbbreviation: searchObject.crudeNameAbbreviation,
        property: searchObject.property,
        referenceId: searchObject.referenceId,
        feedback: searchObject.feedback,
        component: searchObject.component,
      })
      .then((response) =>
        dispatch({
          type: RISKS_SAVE_FEEDBACK,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(RISKS_SAVE_FEEDBACK)))
      .catch(() => dispatch(fetchError(RISKS_SAVE_FEEDBACK)))
  }
}

export const RISKS_SIMILIAR_CRUDE = 'RISKS_SIMILIAR_CRUDE'
export const searchSimiliarCrude = (searchObject, currentPersona) => {
  return (dispatch) => {
    dispatch(fetchStart(RISKS_SIMILIAR_CRUDE))
    services
      .postFindSimiliarCrude({
        crudeCode: searchObject,
        currentPersona: currentPersona
      })
      .then((response) =>
        dispatch({
          type: RISKS_SIMILIAR_CRUDE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(RISKS_SIMILIAR_CRUDE)))
      .catch(() => dispatch(fetchError(RISKS_SIMILIAR_CRUDE)))
  }
}

export const RISKS_SINGLE_CRUDE_HEADER = 'RISKS_SINGLE_CRUDE_HEADER'
export const RISKS_CUSTOM_BLEND_HEADER = 'RISKS_CUSTOM_BLEND_HEADER'
export const searchRisksHeader = (searchObject) => {
  return (dispatch) => {
    if (searchObject.options === landingPageConstant.SEARCH_OPTIONS.SingleCrude) {
      dispatch(fetchStart(RISKS_SINGLE_CRUDE_HEADER))
      services
        .postFindRisksHeaderSingle({
          options: searchObject.options,
          crudeNameAbbreviation: searchObject.crudeNameAbbreviation,
          currentPersona: searchObject.currentPersona,
        })
        .then((response) =>
          dispatch({
            type: RISKS_SINGLE_CRUDE_HEADER,
            payload: response,
          })
        )
        .then(() => dispatch(fetchSuccess(RISKS_SINGLE_CRUDE_HEADER)))
        .catch(() => dispatch(fetchError(RISKS_SINGLE_CRUDE_HEADER)))
    } else if (searchObject.options === landingPageConstant.SEARCH_OPTIONS.CustomBlend) {
      dispatch(fetchStart(RISKS_CUSTOM_BLEND_HEADER))
      services
        .postFindRisksHeaderBlend({
          options: searchObject.options,
          blendComposition: searchObject.inputUnits,
          offsetPercentage: searchObject.offset,
        })
        .then((response) =>
          dispatch({
            type: RISKS_CUSTOM_BLEND_HEADER,
            payload: response,
          })
        )
        .then(() => dispatch(fetchSuccess(RISKS_CUSTOM_BLEND_HEADER)))
        .catch(() => dispatch(fetchError(RISKS_CUSTOM_BLEND_HEADER)))
    }
  }
}

export const RISKS_SAVE_FAVOURITE = 'RISKS_SAVE_FAVOURITE'
export const saveHeaderFavourite = (actionObject) => {
  return (dispatch) => {
    dispatch(fetchStart(RISKS_SAVE_FAVOURITE))
    services
      .postSaveHeaderFavourite({ actionObject })
      .then((response) =>
        dispatch({
          type: RISKS_SAVE_FAVOURITE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(RISKS_SAVE_FAVOURITE)))
      .catch(() => dispatch(fetchError(RISKS_SAVE_FAVOURITE)))
  }
}

export const POST_RISK_CRUDE_BLEND_CHART = 'POST_RISK_CRUDE_BLEND_CHART'
export const setCrudeBlendChart = (offset, period, unit, inputUnits) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_RISK_CRUDE_BLEND_CHART))
    services
      .postCrudeBlendChart(offset, period, unit, inputUnits)
      .then((response) =>
        dispatch({
          type: POST_RISK_CRUDE_BLEND_CHART,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_RISK_CRUDE_BLEND_CHART)))
      .catch(() => dispatch(fetchError(POST_RISK_CRUDE_BLEND_CHART)))
  }
}

export const POST_FAVOURITE_ICON = 'POST_FAVOURITE_ICON'
export const setFavouriteIcon = (currentPersona, crudeCode) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_FAVOURITE_ICON))
    services
      .postFavouriteIcon(currentPersona, crudeCode)
      .then((response) =>
        dispatch({
          type: POST_FAVOURITE_ICON,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_FAVOURITE_ICON)))
      .catch(() => dispatch(fetchError(POST_FAVOURITE_ICON)))
  }
}


export const FREEFORM_TREND_CHART = 'FREEFORM_TREND_CHART'
export const setFreeformTrend = (
  currentPersona,
  formatCrudeList,
  period,
  color1,
  color2,
  color3,
  color4,
  color5,
  dataSource1,
  dataSource2,
  dataSource3,
  dataSource4,
  dataSource5,
  unit1,
  unit2,
  unit3,
  unit4,
  unit5,
  stream1,
  stream2,
  stream3,
  stream4,
  stream5,
  property1,
  property2,
  property3,
  property4,
  property5,
  unit
) => {
  return (dispatch) => {
    dispatch(fetchStart(FREEFORM_TREND_CHART))
    services
      .postFreeformTrend(
        currentPersona,
        formatCrudeList,
        period,
        color1,
        color2,
        color3,
        color4,
        color5,
        dataSource1,
        dataSource2,
        dataSource3,
        dataSource4,
        dataSource5,
        unit1,
        unit2,
        unit3,
        unit4,
        unit5,
        stream1,
        stream2,
        stream3,
        stream4,
        stream5,
        property1,
        property2,
        property3,
        property4,
        property5,
        unit
      )
      .then((response) =>
        dispatch({
          type: FREEFORM_TREND_CHART,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(FREEFORM_TREND_CHART)))
      .catch(() => dispatch(fetchError(FREEFORM_TREND_CHART)))
  }
}
