import React, { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Calendar } from 'react-date-range'
import { endOfMonth } from 'date-fns'

// Assest
import IconCloseWhite from '../../../../assets/images/icon-close-white.svg'
import AddRound from '../../../../assets/images/add-round.svg'
import IconClose from '../../../../assets/images/icon-close.svg'

// Components
import {
  Grid,
  Input,
  Button,
  Dropdown,
  Header,
  Icon,
  Popup,
  Divider,
  Image,
  GridColumn,
  Modal,
} from 'semantic-ui-react'
import SearchInput from '../../../../components/search/search-input/searchInput'
import RadioMenu from '../../../../components/radio-menu'
import RadioButton from '../../../../components/radio-button'
import FetchStateWrapper from '../../../../components/fetch-state-wrapper'

//Redux
import { PERSONAL_SETTING_REDUCER } from '../../../../reducers/common/personalSettingReducer'
import { MASTER_DATA_REDUCER } from '../../../../reducers/common/masterDataReducer'
import { STATISTICAL_ANALYSIS_PAGE_REDUCER } from '../../../../reducers/statisticalAnalaysisPageReducer'
import * as MasterDataActions from '../../../../actions/common/actionMasterData'
import * as StatisticalAnalysisPageActions from '../../../../actions/actionStatisticalAnalysis'

//Hooks
import { useStateWithPaths, useActions } from '../../../../hooks/useConnect'

//Helper
import * as statisticalAnalysisPageConstants from '../helpers/constants'
import { returnNullIfEmpty } from '../../../../helpers/variables'

const renderOptions = (options, onChangeSimiliarCrudeBlend) => {
  const menu = [
    {
      label: 'Find Similiar Crude',
      value: 'FSC',
    },
    {
      label: 'Compare Crude',
      value: 'CC',
    },
    {
      label: 'Compare Blend',
      value: 'CB',
    },
  ]
  return (
    <RadioMenu
      menuData={menu}
      selectCallback={onChangeSimiliarCrudeBlend}
      selectedValue={options}
    />
  )
}

const renderBlendDateComposition = (isSearchByCrudeBlendDate, setCallback) => {
  const menu = [
    {
      label: 'Blend Date',
      value: true,
    },
    {
      label: 'Blend Composition',
      value: false,
    },
  ]
  return (
    <RadioButton
      menuData={menu}
      className="color-white"
      selectCallback={setCallback}
      selectedValue={isSearchByCrudeBlendDate}
    />
  )
}

const SearchPanel = (props) => {
  const [{ searchCompareCrude, searchCompareBlend, searchPanel }] = useStateWithPaths([
    `${STATISTICAL_ANALYSIS_PAGE_REDUCER}`,
  ])
  const [{ currentPersona }] = useStateWithPaths([`${PERSONAL_SETTING_REDUCER}`])

  const [
    {
      crudeNames,
      statisticalProperties,
      statisticalSettingProperties,
      compareCrudeDataSources,
      crudePropertiesUnits,
      crudeCompareProperties,
      crudeBlendUnits,
      crudeBlendUnitsDS,
      compareBlendFraction,
      crudeCompareFractions,
      crudeCutProperties1,
      crudeCutProperties2,
      crudeCutProperties3,
      crudeCutProperties4,
      crudeCutProperties5,
      crudeCutCamUnit1,
      crudeCutCamUnit2,
      crudeCutCamUnit3,
      crudeCutCamUnit4,
      crudeCutCamUnit5,
      crudeBlendProperties,
      compareBlendDataSources,
    },
  ] = useStateWithPaths([`${MASTER_DATA_REDUCER}`])

  _.each(crudeNames, (item) => {
    return (item.title = item.crudeCode + ' - ' + item.crudeName)
  })

  const {
    searchStatisticalAnalysis,
    getMasterCrudeNames,
    getMasterCompareCrudeDataSources,
    getMasterStatisticalProperties,
    getMasterStatisticalSettingProperties,
    getMasterCrudeBlendUnits,
    getMasterCrudeBlendUnitsDS,
    getMasterCompareBlendFractions,
    getMasterCrudePropertyUnits,
    getMasterCrudeCompareProperties,
    getMasterCrudeCompareFractions,
    getMasterCrudeCutCamUnit1,
    getMasterCrudeCutCamUnit2,
    getMasterCrudeCutCamUnit3,
    getMasterCrudeCutCamUnit4,
    getMasterCrudeCutCamUnit5,
    getMasterCrudeCutProperties1,
    getMasterCrudeCutProperties2,
    getMasterCrudeCutProperties3,
    getMasterCrudeCutProperties4,
    getMasterCrudeCutProperties5,
    getMasterCrudeBlendProperties,
    getMasterCompareBlendDataSources,
    getSettingPage,
    saveSettingPage,
  } = useActions({
    searchStatisticalAnalysis: StatisticalAnalysisPageActions.searchStatisticalAnalysis,
    getMasterCrudeNames: MasterDataActions.getMasterCrudeNames,
    getMasterCompareCrudeDataSources: MasterDataActions.getMasterCompareCrudeDataSources,

    getMasterStatisticalProperties: MasterDataActions.getMasterStatisticalProperties,
    getMasterStatisticalSettingProperties: MasterDataActions.getMasterStatisticalSettingProperties,
    getMasterCrudeBlendUnits: MasterDataActions.getMasterCrudeBlendUnits,
    getMasterCrudeBlendUnitsDS: MasterDataActions.getMasterCrudeBlendUnitsDS,
    getMasterCompareBlendFractions: MasterDataActions.getMasterCompareBlendFractions,

    getMasterCrudePropertyUnits: MasterDataActions.getMasterCrudePropertyUnits,
    getMasterCrudeCompareProperties: MasterDataActions.getMasterCrudeCompareProperties,

    getMasterCrudeCompareFractions: MasterDataActions.getMasterCrudeCompareFractions,

    getMasterCrudeCutCamUnit1: MasterDataActions.getMasterCrudeCutCamUnit1,
    getMasterCrudeCutCamUnit2: MasterDataActions.getMasterCrudeCutCamUnit2,
    getMasterCrudeCutCamUnit3: MasterDataActions.getMasterCrudeCutCamUnit3,
    getMasterCrudeCutCamUnit4: MasterDataActions.getMasterCrudeCutCamUnit4,
    getMasterCrudeCutCamUnit5: MasterDataActions.getMasterCrudeCutCamUnit5,

    getMasterCrudeCutProperties1: MasterDataActions.getMasterCrudeCutProperties1,
    getMasterCrudeCutProperties2: MasterDataActions.getMasterCrudeCutProperties2,
    getMasterCrudeCutProperties3: MasterDataActions.getMasterCrudeCutProperties3,
    getMasterCrudeCutProperties4: MasterDataActions.getMasterCrudeCutProperties4,
    getMasterCrudeCutProperties5: MasterDataActions.getMasterCrudeCutProperties5,
    getMasterCrudeBlendProperties: MasterDataActions.getMasterCrudeBlendProperties,
    getMasterCompareBlendDataSources: MasterDataActions.getMasterCompareBlendDataSources,
    getSettingPage: StatisticalAnalysisPageActions.getSettingPage,
    saveSettingPage: StatisticalAnalysisPageActions.saveSettingPage,
  })

  const [options, setOptions] = useState(searchPanel.options)
  const [optionsSetting, setOptionsSetting] = useState('')
  const [open, setOpen] = useState(false)
  const [settingPageSavedMsg, setSettingPageSavedMsg] = useState(null)
  const [inputList, setInputList] = useState(searchPanel.settingPageResult)
  useEffect(() => {
    setInputList(searchPanel.settingPageResult)
  }, [setInputList, searchPanel.settingPageResult])

  const onChangeSimiliarCrudeBlend = (value) => {
    setOptions(value)
    setOptionsSetting('')
    searchStatisticalAnalysis({})
  }

  // Find Similiar
  const [crudeNameAbbreviation, setCrudeNameAbbreviation] = useState(
    searchPanel.crudeNameAbbreviation
  )

  // Compare Crude
  const [dataSource, setDataSource] = useState(searchCompareCrude.dataSource)
  const [crudeNameA, setCrudeNameA] = useState(searchPanel.crudeNameA)
  const [crudeNameB, setCrudeNameB] = useState(searchPanel.crudeNameB)
  const [crudeNameC, setCrudeNameC] = useState(searchPanel.crudeNameC)
  const [compareProperty1, setCompareProperty1] = useState(searchCompareCrude.compareProperty1)
  const [compareProperty2, setCompareProperty2] = useState(searchCompareCrude.compareProperty2)
  const [compareProperty3, setCompareProperty3] = useState(searchCompareCrude.compareProperty3)
  const [compareProperty4, setCompareProperty4] = useState(searchCompareCrude.compareProperty4)
  const [compareProperty5, setCompareProperty5] = useState(searchCompareCrude.compareProperty5)
  const [compareUnit, setCompareUnit] = useState(searchCompareCrude.compareUnit)

  const [cutUnit1, setCutUnit1] = useState(searchCompareCrude.cutUnit1)
  const [cutUnit2, setCutUnit2] = useState(searchCompareCrude.cutUnit2)
  const [cutUnit3, setCutUnit3] = useState(searchCompareCrude.cutUnit3)
  const [cutUnit4, setCutUnit4] = useState(searchCompareCrude.cutUnit4)
  const [cutUnit5, setCutUnit5] = useState(searchCompareCrude.cutUnit5)
  const [fraction1, setFraction1] = useState(searchCompareCrude.fraction1)
  const [fraction2, setFraction2] = useState(searchCompareCrude.fraction2)
  const [fraction3, setFraction3] = useState(searchCompareCrude.fraction3)
  const [fraction4, setFraction4] = useState(searchCompareCrude.fraction4)
  const [fraction5, setFraction5] = useState(searchCompareCrude.fraction5)

  const [cutProperty1, setCutProperty1] = useState(searchCompareCrude.cutProperty1)
  const [cutProperty2, setCutProperty2] = useState(searchCompareCrude.cutProperty2)
  const [cutProperty3, setCutProperty3] = useState(searchCompareCrude.cutProperty3)
  const [cutProperty4, setCutProperty4] = useState(searchCompareCrude.cutProperty4)
  const [cutProperty5, setCutProperty5] = useState(searchCompareCrude.cutProperty5)

  // Compare Blend

  const [blendDataSource, setBlendDataSource] = useState(searchCompareBlend.dataSource)
  const [isSearchByCrudeBlendDate, setIsSearchByCrudeBlendDate] = useState(
    searchPanel.isSearchByCrudeBlendDate
  )
  const [blendDateA, setBlendDateA] = useState(searchPanel.blendDateA)
  const [blendDateB, setBlendDateB] = useState(searchPanel.blendDateB)
  const [unitBlendA, setUnitBlendA] = useState(searchPanel.unitBlendA)
  const [unitBlendB, setUnitBlendB] = useState(searchPanel.unitBlendB)

  const [compareBlendProperty1, setCompareBlendProperty1] = useState(searchCompareBlend.property1)
  const [compareBlendProperty2, setCompareBlendProperty2] = useState(searchCompareBlend.property2)
  const [compareBlendProperty3, setCompareBlendProperty3] = useState(searchCompareBlend.property3)
  const [compareBlendProperty4, setCompareBlendProperty4] = useState(searchCompareBlend.property4)
  const [compareBlendProperty5, setCompareBlendProperty5] = useState(searchCompareBlend.property5)

  const [inputUnit1, setInputUnit1] = useState(searchPanel.inputUnits.unit1)
  const [inputPercent1, setInputPercent1] = useState(searchPanel.inputUnits.unitPercent1)
  const [inputUnit2, setInputUnit2] = useState(searchPanel.inputUnits.unit2)
  const [inputPercent2, setInputPercent2] = useState(searchPanel.inputUnits.unitPercent2)
  const [inputUnit3, setInputUnit3] = useState(searchPanel.inputUnits.unit3)
  const [inputPercent3, setInputPercent3] = useState(searchPanel.inputUnits.unitPercent3)
  const [inputUnit4, setInputUnit4] = useState(searchPanel.inputUnits.unit4)
  const [inputPercent4, setInputPercent4] = useState(searchPanel.inputUnits.unitPercent4)
  const [inputUnit5, setInputUnit5] = useState(searchPanel.inputUnits.unit5)
  const [inputPercent5, setInputPercent5] = useState(searchPanel.inputUnits.unitPercent5)
  const [inputUnit6, setInputUnit6] = useState(searchPanel.inputUnits.unit6)
  const [inputPercent6, setInputPercent6] = useState(searchPanel.inputUnits.unitPercent6)
  const [inputUnit7, setInputUnit7] = useState(searchPanel.inputUnits.unit7)
  const [inputPercent7, setInputPercent7] = useState(searchPanel.inputUnits.unitPercent7)
  const [inputUnit8, setInputUnit8] = useState(searchPanel.inputUnits.unit8)
  const [inputPercent8, setInputPercent8] = useState(searchPanel.inputUnits.unitPercent8)
  const [inputUnit9, setInputUnit9] = useState(searchPanel.inputUnits.unit9)
  const [inputPercent9, setInputPercent9] = useState(searchPanel.inputUnits.unitPercent9)
  const [inputUnit10, setInputUnit10] = useState(searchPanel.inputUnits.unit10)
  const [inputPercent10, setInputPercent10] = useState(searchPanel.inputUnits.unitPercent10)
  const [inputUnit11, setInputUnit11] = useState(searchPanel.inputUnits.unit11)
  const [inputPercent11, setInputPercent11] = useState(searchPanel.inputUnits.unitPercent11)
  const [inputUnit12, setInputUnit12] = useState(searchPanel.inputUnits.unit12)
  const [inputPercent12, setInputPercent12] = useState(searchPanel.inputUnits.unitPercent12)
  const [inputUnit13, setInputUnit13] = useState(searchPanel.inputUnits.unit13)
  const [inputPercent13, setInputPercent13] = useState(searchPanel.inputUnits.unitPercent13)
  const [inputUnit14, setInputUnit14] = useState(searchPanel.inputUnits.unit14)
  const [inputPercent14, setInputPercent14] = useState(searchPanel.inputUnits.unitPercent14)
  const [inputUnit15, setInputUnit15] = useState(searchPanel.inputUnits.unit15)
  const [inputPercent15, setInputPercent15] = useState(searchPanel.inputUnits.unitPercent15)
  const [inputUnit16, setInputUnit16] = useState(searchPanel.inputUnits.unit16)
  const [inputPercent16, setInputPercent16] = useState(searchPanel.inputUnits.unitPercent16)
  const [inputUnit17, setInputUnit17] = useState(searchPanel.inputUnits.unit17)
  const [inputPercent17, setInputPercent17] = useState(searchPanel.inputUnits.unitPercent17)
  const [inputUnit18, setInputUnit18] = useState(searchPanel.inputUnits.unit18)
  const [inputPercent18, setInputPercent18] = useState(searchPanel.inputUnits.unitPercent8)
  const [inputUnit19, setInputUnit19] = useState(searchPanel.inputUnits.unit19)
  const [inputPercent19, setInputPercent19] = useState(searchPanel.inputUnits.unitPercent19)
  const [inputUnit20, setInputUnit20] = useState(searchPanel.inputUnits.unit20)
  const [inputPercent20, setInputPercent20] = useState(searchPanel.inputUnits.unitPercent20)

  const [operatingUnit, setOperatingUnit] = useState(searchPanel.operatingUnit)
  const [fraction, setFraction] = useState(searchPanel.fraction)

  const [errorInputs, setErrorInputs] = useState({})

  useEffect(() => {
    getMasterCrudeCutCamUnit1(currentPersona, fraction1, dataSource, cutProperty1)
  }, [getMasterCrudeCutCamUnit1, currentPersona, fraction1, dataSource, cutProperty1])

  useEffect(() => {
    getMasterCrudeCutCamUnit2(currentPersona, fraction2, dataSource, cutProperty2)
  }, [getMasterCrudeCutCamUnit2, currentPersona, fraction2, dataSource, cutProperty2])

  useEffect(() => {
    getMasterCrudeCutCamUnit3(currentPersona, fraction3, dataSource, cutProperty3)
  }, [getMasterCrudeCutCamUnit3, currentPersona, fraction3, dataSource, cutProperty3])

  useEffect(() => {
    getMasterCrudeCutCamUnit4(currentPersona, fraction4, dataSource, cutProperty4)
  }, [getMasterCrudeCutCamUnit4, currentPersona, fraction4, dataSource, cutProperty4])

  useEffect(() => {
    getMasterCrudeCutCamUnit5(currentPersona, fraction5, dataSource, cutProperty5)
  }, [getMasterCrudeCutCamUnit5, currentPersona, fraction5, dataSource, cutProperty5])

  useEffect(() => {
    getMasterCrudeCutProperties1(currentPersona, fraction1, dataSource)
  }, [getMasterCrudeCutProperties1, currentPersona, fraction1, dataSource])

  useEffect(() => {
    getMasterCrudeCutProperties2(currentPersona, fraction2, dataSource)
  }, [getMasterCrudeCutProperties2, currentPersona, fraction2, dataSource])

  useEffect(() => {
    getMasterCrudeCutProperties3(currentPersona, fraction3, dataSource)
  }, [getMasterCrudeCutProperties3, currentPersona, fraction3, dataSource])

  useEffect(() => {
    getMasterCrudeCutProperties4(currentPersona, fraction4, dataSource)
  }, [getMasterCrudeCutProperties4, currentPersona, fraction4, dataSource])

  useEffect(() => {
    getMasterCrudeCutProperties5(currentPersona, fraction5, dataSource)
  }, [getMasterCrudeCutProperties5, currentPersona, fraction5, dataSource])

  useEffect(() => {
    getSettingPage(currentPersona)
  }, [getSettingPage])

  useEffect(() => {
    getMasterCrudeNames()
    getMasterStatisticalProperties(currentPersona)
    getMasterStatisticalSettingProperties(currentPersona)
    getMasterCompareCrudeDataSources(currentPersona)
    getMasterCrudePropertyUnits(currentPersona)
    getMasterCrudeCompareProperties(currentPersona, dataSource)
    getMasterCrudeBlendUnits(currentPersona)
    getMasterCrudeBlendUnitsDS(currentPersona)
    getMasterCompareBlendFractions(currentPersona, operatingUnit)
    getMasterCrudeCompareFractions(currentPersona, dataSource)
    getMasterCrudeBlendProperties(currentPersona, operatingUnit, fraction, blendDataSource)
    getMasterCompareBlendDataSources(currentPersona)
  }, [
    currentPersona,
    blendDataSource,
    dataSource,
    fraction,
    operatingUnit,
    getMasterCrudeNames,
    getMasterCompareCrudeDataSources,
    getMasterStatisticalProperties,
    getMasterStatisticalSettingProperties,
    getMasterCrudeBlendUnits,
    getMasterCrudeBlendUnitsDS,
    getMasterCompareBlendFractions,
    getMasterCrudeCompareFractions,
    getMasterCrudePropertyUnits,
    getMasterCrudeCompareProperties,
    getMasterCrudeBlendProperties,
    getMasterCompareBlendDataSources,
  ])

  const onClickResetCompareBlend = () => (
    setBlendDateA(''),
    setBlendDateB(''),
    setUnitBlendA(''),
    setUnitBlendB(''),
    setOperatingUnit(''),
    setFraction(''),
    setCompareBlendProperty1(''),
    setCompareBlendProperty2(''),
    setCompareBlendProperty3(''),
    setCompareBlendProperty4(''),
    setCompareBlendProperty5(''),
    setInputUnit1(null),
    setInputPercent1(''),
    setInputUnit2(null),
    setInputPercent2(''),
    setInputUnit3(null),
    setInputPercent3(''),
    setInputUnit4(null),
    setInputPercent4(''),
    setInputUnit5(null),
    setInputPercent5(''),
    setInputUnit6(null),
    setInputPercent6(''),
    setInputUnit7(null),
    setInputPercent7(''),
    setInputUnit8(null),
    setInputPercent8(''),
    setInputUnit9(null),
    setInputPercent9(''),
    setInputUnit10(null),
    setInputPercent10(''),
    setInputUnit11(null),
    setInputPercent11(''),
    setInputUnit12(null),
    setInputPercent12(''),
    setInputUnit13(null),
    setInputPercent13(''),
    setInputUnit14(null),
    setInputPercent14(''),
    setInputUnit15(null),
    setInputPercent15(''),
    setInputUnit16(null),
    setInputPercent16(''),
    setInputUnit17(null),
    setInputPercent17(''),
    setInputUnit18(null),
    setInputPercent18(''),
    setInputUnit19(null),
    setInputPercent19(''),
    setInputUnit20(null),
    setInputPercent20(''),
    searchStatisticalAnalysis({})
  )

  const onClickResetCompareCrude = () => (
    setCrudeNameA(null),
    setCrudeNameB(null),
    setCrudeNameC(null),
    setCompareProperty1(''),
    setCompareProperty2(''),
    setCompareProperty3(''),
    setCompareProperty4(''),
    setCompareProperty5(''),
    setCompareUnit(''),
    setFraction1(''),
    setCutProperty1(''),
    setCutUnit1(''),
    setFraction2(''),
    setCutProperty2(''),
    setCutUnit2(''),
    setFraction3(''),
    setCutProperty3(''),
    setCutUnit3(''),
    setFraction4(''),
    setCutProperty4(''),
    setCutUnit4(''),
    setFraction5(''),
    setCutProperty5(''),
    setCutUnit5(''),
    searchStatisticalAnalysis({})
  )

  const onClickCalculate = useCallback(() => {
    if (!isPassValidationForCalculate(options)) {
      return
    }
    searchStatisticalAnalysis({
      options,
      dataSource,
      blendDataSource,
      currentPersona,
      compareUnit,
      compareProperty1,
      compareProperty2,
      compareProperty3,
      compareProperty4,
      compareProperty5,
      cutUnit1,
      cutUnit2,
      cutUnit3,
      cutUnit4,
      cutUnit5,
      fraction1,
      fraction2,
      fraction3,
      fraction4,
      fraction5,
      cutProperty1,
      cutProperty2,
      cutProperty3,
      cutProperty4,
      cutProperty5,
      options,
      crudeNameAbbreviation,
      crudeNameA,
      crudeNameB,
      crudeNameC,
      isSearchByCrudeBlendDate,
      blendDateA,
      blendDateB,
      unitBlendA,
      unitBlendB,
      compareBlendProperty1,
      compareBlendProperty2,
      compareBlendProperty3,
      compareBlendProperty4,
      compareBlendProperty5,
      inputUnits: {
        unit1: inputUnit1,
        unitPercent1: inputPercent1,
        unit2: inputUnit2,
        unitPercent2: inputPercent2,
        unit3: inputUnit3,
        unitPercent3: inputPercent3,
        unit4: inputUnit4,
        unitPercent4: inputPercent4,
        unit5: inputUnit5,
        unitPercent5: inputPercent5,
        unit6: inputUnit6,
        unitPercent6: inputPercent6,
        unit7: inputUnit7,
        unitPercent7: inputPercent7,
        unit8: inputUnit8,
        unitPercent8: inputPercent8,
        unit9: inputUnit9,
        unitPercent9: inputPercent9,
        unit10: inputUnit10,
        unitPercent10: inputPercent10,
        unit11: inputUnit11,
        unitPercent11: inputPercent11,
        unit12: inputUnit12,
        unitPercent12: inputPercent12,
        unit13: inputUnit13,
        unitPercent13: inputPercent13,
        unit14: inputUnit14,
        unitPercent14: inputPercent14,
        unit15: inputUnit15,
        unitPercent15: inputPercent15,
        unit16: inputUnit16,
        unitPercent16: inputPercent16,
        unit17: inputUnit17,
        unitPercent17: inputPercent17,
        unit18: inputUnit18,
        unitPercent18: inputPercent18,
        unit19: inputUnit19,
        unitPercent19: inputPercent19,
        unit20: inputUnit20,
        unitPercent20: inputPercent20,
      },
      operatingUnit,
      fraction,
    })
  }, [
    searchStatisticalAnalysis,
    dataSource,
    currentPersona,
    compareUnit,
    options,
    crudeNameAbbreviation,
    crudeNameA,
    crudeNameB,
    crudeNameC,
    compareProperty1,
    compareProperty2,
    compareProperty3,
    compareProperty4,
    compareProperty5,
    cutUnit1,
    cutUnit2,
    cutUnit3,
    cutUnit4,
    cutUnit5,
    fraction1,
    fraction2,
    fraction3,
    fraction4,
    fraction5,
    cutProperty1,
    cutProperty2,
    cutProperty3,
    cutProperty4,
    cutProperty5,
    compareBlendProperty1,
    compareBlendProperty2,
    compareBlendProperty3,
    compareBlendProperty4,
    compareBlendProperty5,
    isSearchByCrudeBlendDate,
    blendDateA,
    blendDateB,
    unitBlendA,
    unitBlendB,
    inputUnit1,
    inputPercent1,
    inputUnit2,
    inputPercent2,
    inputUnit3,
    inputPercent3,
    inputUnit4,
    inputPercent4,
    inputUnit5,
    inputPercent5,
    inputUnit6,
    inputPercent6,
    inputUnit7,
    inputPercent7,
    inputUnit8,
    inputPercent8,
    inputUnit9,
    inputPercent9,
    inputUnit10,
    inputPercent10,
    inputUnit11,
    inputPercent11,
    inputUnit12,
    inputPercent12,
    inputUnit13,
    inputPercent13,
    inputUnit14,
    inputPercent14,
    inputUnit15,
    inputPercent15,
    inputUnit16,
    inputPercent16,
    inputUnit17,
    inputPercent17,
    inputUnit18,
    inputPercent18,
    inputUnit19,
    inputPercent19,
    inputUnit20,
    inputPercent20,
    operatingUnit,
    fraction,
  ])

  const onClickCancelSettingPage = () => {
    setOptionsSetting(null)
  }

  const isEmptyValue = useCallback((inputValue, isInputUnit) => {
    if (isInputUnit) {
      return _.isNull(inputValue) || _.isEmpty(inputValue)
    } else {
      return (
        _.isNull(inputValue) || _.isUndefined(inputValue) || inputValue <= 0 || inputValue > 100
      )
    }
  })

  const isNotEmptyPercentage = (inputPercent) => {
    return !_.isEqualWith(inputPercent, '') && !_.isUndefined(inputPercent)
  }

  const checkDuplicateInputUnits = (inputUnits) => {
    const inputUnitsList = _.map(inputUnits, (item, index) => ({ ...item, inputUnit: index }))
    const arr = []
    _.map(inputUnitsList, (item, index) => {
      if (!_.isEmpty(item.crudeCode)) {
        arr.push(item)
      }
    })

    const mapped = _.map(arr, (obj, index) => obj.crudeCode)
    const sorted_arr = mapped.slice().sort()

    var results = []
    for (var i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] === sorted_arr[i]) {
        results.push(sorted_arr[i])
      }
    }

    const exists = []
    _.map(results, (crudeCode) => {
      _.map(arr, (item) => {
        if (item.crudeCode === crudeCode) {
          exists.push(item)
        }
      })
    })

    return exists
  }

  const isPassValidationForCalculate = useCallback(
    (optionValue) => {
      let newErrorInputs = {}

      if (optionValue === statisticalAnalysisPageConstants.SEARCH_OPTIONS.FindSimiliarCrude) {
        if (
          optionsSetting === statisticalAnalysisPageConstants.OPTIONS_SETTING.ModifyCriteriaScore
        ) {
          let totalSettingPage = 0
          _.map(inputList, (item) => {
            totalSettingPage = totalSettingPage + item.weighted_score
          })
          newErrorInputs.ModifyCriteriaScoreError = totalSettingPage != 100
        } else if (
          optionsSetting === statisticalAnalysisPageConstants.OPTIONS_SETTING.AdjustPropertyRange
        ) {
          _.map(inputList, (item) => {
            if (
              item.ui_criteria_type === 'Number' &&
              (_.isNaN(item.parameter_offset_percent) || item.parameter_offset_percent < 0)
            ) {
              newErrorInputs.AdjustPropertyRangeError =
                'Please enter non-negative value for %offset'
              return
            }
          })
        } else {
          newErrorInputs.crudeNameAbbreviation = isEmptyValue(crudeNameAbbreviation, true)
        }
      } else if (optionValue === statisticalAnalysisPageConstants.SEARCH_OPTIONS.CompareCrude) {
        newErrorInputs.crudeNameA = isEmptyValue(crudeNameA, true)
        newErrorInputs.crudeNameB = isEmptyValue(crudeNameB, true)
      } else if (optionValue === statisticalAnalysisPageConstants.SEARCH_OPTIONS.CompareBlend) {
        if (isSearchByCrudeBlendDate) {
          newErrorInputs.blendDateA = _.isNull(blendDateA)
          newErrorInputs.blendDateB = _.isNull(blendDateB)
        } else {
          newErrorInputs.unitBlendA = isEmptyValue(unitBlendA, true)
          newErrorInputs.unitBlendB = isEmptyValue(unitBlendB, true)
          newErrorInputs.inputUnit1 = isEmptyValue(inputUnit1, true)
          newErrorInputs.inputUnit2 = isEmptyValue(inputUnit2, true)
          newErrorInputs.inputUnit11 = isEmptyValue(inputUnit11, true)
          newErrorInputs.inputUnit12 = isEmptyValue(inputUnit12, true)
          newErrorInputs.inputPercent1 = isEmptyValue(inputPercent1, false)
          newErrorInputs.inputPercent2 = isEmptyValue(inputPercent2, false)
          newErrorInputs.inputPercent11 = isEmptyValue(inputPercent11, false)
          newErrorInputs.inputPercent12 = isEmptyValue(inputPercent12, false)

          let totalA = 0
          totalA = isNotEmptyPercentage(inputPercent1) ? totalA + inputPercent1 : totalA + 0
          totalA = isNotEmptyPercentage(inputPercent2) ? totalA + inputPercent2 : totalA + 0
          totalA = isNotEmptyPercentage(inputPercent3) ? totalA + inputPercent3 : totalA + 0
          totalA = isNotEmptyPercentage(inputPercent4) ? totalA + inputPercent4 : totalA + 0
          totalA = isNotEmptyPercentage(inputPercent5) ? totalA + inputPercent5 : totalA + 0
          totalA = isNotEmptyPercentage(inputPercent6) ? totalA + inputPercent6 : totalA + 0
          totalA = isNotEmptyPercentage(inputPercent7) ? totalA + inputPercent7 : totalA + 0
          totalA = isNotEmptyPercentage(inputPercent8) ? totalA + inputPercent8 : totalA + 0
          totalA = isNotEmptyPercentage(inputPercent9) ? totalA + inputPercent9 : totalA + 0
          totalA = isNotEmptyPercentage(inputPercent10) ? totalA + inputPercent10 : totalA + 0

          if (
            (!isEmptyValue(inputUnit1, true) && isEmptyValue(inputPercent1, false)) ||
            (!isEmptyValue(inputUnit2, true) && isEmptyValue(inputPercent2, false)) ||
            (!isEmptyValue(inputUnit3, true) && isEmptyValue(inputPercent3, false)) ||
            (!isEmptyValue(inputUnit4, true) && isEmptyValue(inputPercent4, false)) ||
            (!isEmptyValue(inputUnit5, true) && isEmptyValue(inputPercent5, false)) ||
            (!isEmptyValue(inputUnit6, true) && isEmptyValue(inputPercent6, false)) ||
            (!isEmptyValue(inputUnit7, true) && isEmptyValue(inputPercent7, false)) ||
            (!isEmptyValue(inputUnit8, true) && isEmptyValue(inputPercent8, false)) ||
            (!isEmptyValue(inputUnit9, true) && isEmptyValue(inputPercent9, false)) ||
            (!isEmptyValue(inputUnit10, true) && isEmptyValue(inputPercent10, false))
          ) {
            if (!isEmptyValue(inputUnit1, true)) {
              newErrorInputs.inputPercent1 = isEmptyValue(inputPercent1)
            }
            if (!isEmptyValue(inputUnit2, true)) {
              newErrorInputs.inputPercent2 = isEmptyValue(inputPercent2)
            }
            if (!isEmptyValue(inputUnit3, true)) {
              newErrorInputs.inputPercent3 = isEmptyValue(inputPercent3)
            }
            if (!isEmptyValue(inputUnit4, true)) {
              newErrorInputs.inputPercent4 = isEmptyValue(inputPercent4)
            }
            if (!isEmptyValue(inputUnit5, true)) {
              newErrorInputs.inputPercent5 = isEmptyValue(inputPercent5)
            }
            if (!isEmptyValue(inputUnit6, true)) {
              newErrorInputs.inputPercent6 = isEmptyValue(inputPercent6)
            }
            if (!isEmptyValue(inputUnit7, true)) {
              newErrorInputs.inputPercent7 = isEmptyValue(inputPercent7)
            }
            if (!isEmptyValue(inputUnit8, true)) {
              newErrorInputs.inputPercent8 = isEmptyValue(inputPercent8)
            }
            if (!isEmptyValue(inputUnit9, true)) {
              newErrorInputs.inputPercent9 = isEmptyValue(inputPercent9)
            }
            if (!isEmptyValue(inputUnit10, true)) {
              newErrorInputs.inputPercent10 = isEmptyValue(inputPercent10)
            }

            newErrorInputs.errorBlendA = statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_4
          }

          if (_.isEmpty(newErrorInputs.errorBlendA)) {
            if (totalA != '100') {
              newErrorInputs.inputPercent1 = isNotEmptyPercentage(inputPercent1)
              newErrorInputs.inputPercent2 = isNotEmptyPercentage(inputPercent2)
              newErrorInputs.inputPercent3 = isNotEmptyPercentage(inputPercent3)
              newErrorInputs.inputPercent4 = isNotEmptyPercentage(inputPercent4)
              newErrorInputs.inputPercent5 = isNotEmptyPercentage(inputPercent5)
              newErrorInputs.inputPercent6 = isNotEmptyPercentage(inputPercent6)
              newErrorInputs.inputPercent7 = isNotEmptyPercentage(inputPercent7)
              newErrorInputs.inputPercent8 = isNotEmptyPercentage(inputPercent8)
              newErrorInputs.inputPercent9 = isNotEmptyPercentage(inputPercent9)
              newErrorInputs.inputPercent10 = isNotEmptyPercentage(inputPercent10)

              newErrorInputs.errorBlendA =
                statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_2
            } else {
              const inputUnits = {
                inputUnit1,
                inputUnit2,
                inputUnit3,
                inputUnit4,
                inputUnit5,
                inputUnit6,
                inputUnit7,
                inputUnit8,
                inputUnit9,
                inputUnit10,
              }

              const duplicateList = checkDuplicateInputUnits(inputUnits)
              _.map(duplicateList, (error) => {
                newErrorInputs[error.inputUnit] = true
              })

              newErrorInputs.errorBlendA = !_.isEmpty(duplicateList)
                ? statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_3
                : ''
            }
          }

          let totalB = 0
          totalB = isNotEmptyPercentage(inputPercent11) ? totalB + inputPercent11 : totalB + 0
          totalB = isNotEmptyPercentage(inputPercent12) ? totalB + inputPercent12 : totalB + 0
          totalB = isNotEmptyPercentage(inputPercent13) ? totalB + inputPercent13 : totalB + 0
          totalB = isNotEmptyPercentage(inputPercent14) ? totalB + inputPercent14 : totalB + 0
          totalB = isNotEmptyPercentage(inputPercent15) ? totalB + inputPercent15 : totalB + 0
          totalB = isNotEmptyPercentage(inputPercent16) ? totalB + inputPercent16 : totalB + 0
          totalB = isNotEmptyPercentage(inputPercent17) ? totalB + inputPercent17 : totalB + 0
          totalB = isNotEmptyPercentage(inputPercent18) ? totalB + inputPercent18 : totalB + 0
          totalB = isNotEmptyPercentage(inputPercent19) ? totalB + inputPercent19 : totalB + 0
          totalB = isNotEmptyPercentage(inputPercent20) ? totalB + inputPercent20 : totalB + 0

          if (
            (!isEmptyValue(inputUnit11, true) && isEmptyValue(inputPercent11, false)) ||
            (!isEmptyValue(inputUnit12, true) && isEmptyValue(inputPercent12, false)) ||
            (!isEmptyValue(inputUnit13, true) && isEmptyValue(inputPercent13, false)) ||
            (!isEmptyValue(inputUnit14, true) && isEmptyValue(inputPercent14, false)) ||
            (!isEmptyValue(inputUnit15, true) && isEmptyValue(inputPercent15, false)) ||
            (!isEmptyValue(inputUnit16, true) && isEmptyValue(inputPercent16, false)) ||
            (!isEmptyValue(inputUnit17, true) && isEmptyValue(inputPercent17, false)) ||
            (!isEmptyValue(inputUnit18, true) && isEmptyValue(inputPercent18, false)) ||
            (!isEmptyValue(inputUnit19, true) && isEmptyValue(inputPercent19, false)) ||
            (!isEmptyValue(inputUnit20, true) && isEmptyValue(inputPercent20, false))
          ) {
            if (!isEmptyValue(inputUnit11, true)) {
              newErrorInputs.inputPercent11 = isEmptyValue(inputPercent11)
            }
            if (!isEmptyValue(inputUnit12, true)) {
              newErrorInputs.inputPercent12 = isEmptyValue(inputPercent12)
            }
            if (!isEmptyValue(inputUnit13, true)) {
              newErrorInputs.inputPercent13 = isEmptyValue(inputPercent13)
            }
            if (!isEmptyValue(inputUnit14, true)) {
              newErrorInputs.inputPercent14 = isEmptyValue(inputPercent14)
            }
            if (!isEmptyValue(inputUnit15, true)) {
              newErrorInputs.inputPercent15 = isEmptyValue(inputPercent15)
            }
            if (!isEmptyValue(inputUnit16, true)) {
              newErrorInputs.inputPercent16 = isEmptyValue(inputPercent16)
            }
            if (!isEmptyValue(inputUnit17, true)) {
              newErrorInputs.inputPercent17 = isEmptyValue(inputPercent17)
            }
            if (!isEmptyValue(inputUnit18, true)) {
              newErrorInputs.inputPercent18 = isEmptyValue(inputPercent18)
            }
            if (!isEmptyValue(inputUnit19, true)) {
              newErrorInputs.inputPercent19 = isEmptyValue(inputPercent19)
            }
            if (!isEmptyValue(inputUnit20, true)) {
              newErrorInputs.inputPercent20 = isEmptyValue(inputPercent20)
            }

            newErrorInputs.errorBlendB = statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_4
          }

          if (_.isEmpty(newErrorInputs.errorBlendB)) {
            if (totalB != '100') {
              newErrorInputs.inputPercent11 = isNotEmptyPercentage(inputPercent11)
              newErrorInputs.inputPercent12 = isNotEmptyPercentage(inputPercent12)
              newErrorInputs.inputPercent13 = isNotEmptyPercentage(inputPercent13)
              newErrorInputs.inputPercent14 = isNotEmptyPercentage(inputPercent14)
              newErrorInputs.inputPercent15 = isNotEmptyPercentage(inputPercent15)
              newErrorInputs.inputPercent16 = isNotEmptyPercentage(inputPercent16)
              newErrorInputs.inputPercent17 = isNotEmptyPercentage(inputPercent17)
              newErrorInputs.inputPercent18 = isNotEmptyPercentage(inputPercent18)
              newErrorInputs.inputPercent19 = isNotEmptyPercentage(inputPercent19)
              newErrorInputs.inputPercent20 = isNotEmptyPercentage(inputPercent20)

              newErrorInputs.errorBlendB =
                statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_2
            } else {
              const inputUnits = {
                inputUnit11,
                inputUnit12,
                inputUnit13,
                inputUnit14,
                inputUnit15,
                inputUnit16,
                inputUnit17,
                inputUnit18,
                inputUnit19,
                inputUnit20,
              }

              const duplicateList = checkDuplicateInputUnits(inputUnits)
              _.map(duplicateList, (error) => {
                newErrorInputs[error.inputUnit] = true
              })

              newErrorInputs.errorBlendB = !_.isEmpty(duplicateList)
                ? statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_3
                : ''
            }
          }
        }
        newErrorInputs.operatingUnit = isEmptyValue(operatingUnit, true)
        newErrorInputs.fraction = isEmptyValue(fraction, true)
        newErrorInputs.compareBlendProperty1 = isEmptyValue(compareBlendProperty1, true)
        newErrorInputs.compareBlendProperty2 = isEmptyValue(compareBlendProperty2, true)
      }

      setErrorInputs(newErrorInputs)
      let hasError = false
      _.forOwn(newErrorInputs, (value, key) => {
        if (hasError) return

        hasError = value
      })
      return !hasError
    },
    [
      optionsSetting,
      inputList,
      crudeNameAbbreviation,
      crudeNameA,
      crudeNameB,
      isSearchByCrudeBlendDate,
      blendDateA,
      blendDateB,
      unitBlendA,
      unitBlendB,
      inputUnit1,
      inputUnit2,
      inputUnit3,
      inputUnit4,
      inputUnit5,
      inputUnit6,
      inputUnit7,
      inputUnit8,
      inputUnit9,
      inputUnit10,
      inputUnit11,
      inputUnit12,
      inputUnit13,
      inputUnit14,
      inputUnit15,
      inputUnit16,
      inputUnit17,
      inputUnit18,
      inputUnit19,
      inputUnit20,
      inputPercent1,
      inputPercent2,
      inputPercent3,
      inputPercent4,
      inputPercent5,
      inputPercent6,
      inputPercent7,
      inputPercent8,
      inputPercent9,
      inputPercent10,
      inputPercent11,
      inputPercent12,
      inputPercent13,
      inputPercent14,
      inputPercent15,
      inputPercent16,
      inputPercent17,
      inputPercent18,
      inputPercent19,
      inputPercent20,
      operatingUnit,
      fraction,
      compareBlendProperty1,
      compareBlendProperty2,
    ]
  )

  const onChangeBlendDateComposition = (isSearchByCrudeBlendDateValue) => {
    if (isSearchByCrudeBlendDateValue) {
      // setUnit('')
      // clearInputBlendComposition()
    } else {
      // setBlendDate(null)
    }
    setIsSearchByCrudeBlendDate(isSearchByCrudeBlendDateValue)
  }

  const getBlendDateDisplayLabel = (selectedDate) => {
    return _.isDate(selectedDate) ? _.upperCase(moment(selectedDate).format('DD MMM YYYY')) : ''
  }

  const endMonth = endOfMonth(new Date())
  const endOfNextMonth = moment(endMonth).add(1, 'M')._d

  const minDate = moment('2010-08-01')._d

  const getFractions = (dataSource) => {
    return dataSource === 'Spiral Data' ? 'IBP-FBP' : 'Whole Crude'
  }

  const handleInputChange = (data, index) => {
    const { name, value } = data
    let text = null

    const list = [...inputList]
    if (name === 'ui_display_name') {
      _.map(statisticalSettingProperties, (item) => {
        if (item.value === value) {
          text = item.text
          return
        }
      })

      list[index][name] = text
      list[index]['ui_prop_name'] = value
      list[index]['integration_criteria_name'] = null
      list[index]['parameter_offset_percent'] = 0
      list[index]['ui_criteria_name'] = null
      list[index]['ui_criteria_type'] = 'Number'
    } else {
      list[index][name] = parseInt(value)
    }
    setInputList(list)
  }

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        integration_criteria_name: null,
        parameter_offset_percent: 0,
        ui_criteria_name: null,
        ui_criteria_type: 'Number',
        ui_display_name: null,
        ui_prop_name: null,
        weighted_score: 0,
      },
    ])
  }

  const onClickSaveSettingPage = useCallback(() => {
    if (!isPassValidationForCalculate(options)) {
      return
    }

    saveSettingPage({ options, currentPersona, inputList })
    setOpen(true)
    setSettingPageSavedMsg(
      optionsSetting === statisticalAnalysisPageConstants.OPTIONS_SETTING.AdjustPropertyRange
        ? statisticalAnalysisPageConstants.SETTING_PAGE_SAVED_MSG.ADJUST_PROPERTY
        : statisticalAnalysisPageConstants.SETTING_PAGE_SAVED_MSG.MODIFY_CRITERIA
    )
  }, [saveSettingPage, options, optionsSetting, currentPersona, inputList])

  const onClickNavigateAdjustPropertyRange = useCallback(() => {
    setOpen(false)
    setOptionsSetting(statisticalAnalysisPageConstants.OPTIONS_SETTING.AdjustPropertyRange)
    getSettingPage(currentPersona)
  }, [getSettingPage, currentPersona])

  return (
    <div className="section section-search">
      <Grid padded>
        <Grid.Row>
          <div className={`flex space-between center width-100`}>
            {renderOptions(options, onChangeSimiliarCrudeBlend)}
            {options === statisticalAnalysisPageConstants.SEARCH_OPTIONS.FindSimiliarCrude && (
              <Dropdown
                floating
                direction="left"
                icon={<Icon name="setting" size="large" className={`settings-icon`} />}
                className={`icon`}
              >
                <Dropdown.Menu>
                  <Dropdown.Header content="Settings" />
                  <Dropdown.Item
                    text="Adjust Property Range"
                    onClick={() =>
                      setOptionsSetting(
                        statisticalAnalysisPageConstants.OPTIONS_SETTING.AdjustPropertyRange
                      )
                    }
                  />
                  <Dropdown.Item
                    text="Modify Criteria & Score"
                    onClick={() =>
                      setOptionsSetting(
                        statisticalAnalysisPageConstants.OPTIONS_SETTING.ModifyCriteriaScore
                      )
                    }
                  />
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </Grid.Row>
        {optionsSetting ===
          statisticalAnalysisPageConstants.OPTIONS_SETTING.ModifyCriteriaScore && (
          <>
            <Grid.Row>
              <Header className={`mar-y-0_5 text-white`} as="h2">
                Modify Criteria & Score
              </Header>
            </Grid.Row>
            <Grid.Row columns={3}>
              {inputList.map((x, i) => {
                return (
                  <>
                    <GridColumn>
                      <div className={`flex center mar-y-1`}>
                        {x.ui_criteria_type === 'Text' ? (
                          <Input
                            name="ui_display_name"
                            className={`input-container`}
                            type="text"
                            value={x.ui_display_name}
                            disabled
                          />
                        ) : (
                          <Dropdown
                            search
                            name="ui_display_name"
                            value={x.ui_prop_name}
                            defaultValue={x.ui_prop_name}
                            placeholder="Select property"
                            className={`ddl-secondary`}
                            style={{ width: '100%' }}
                            onChange={(e, data) => handleInputChange(data, i)}
                            selection
                            options={statisticalSettingProperties}
                          />
                        )}

                        <Input
                          name="weighted_score"
                          className=""
                          className={`input-percent ${
                            errorInputs.ModifyCriteriaScoreError ? 'error' : ''
                          }`}
                          type="number"
                          min="1"
                          max="100"
                          value={x.weighted_score}
                          defaultValue={x.weighted_score}
                          onChange={(e, data) => {
                            errorInputs.ModifyCriteriaScoreError = false
                            handleInputChange(data, i)
                          }}
                          placeholder="0"
                        />
                        <div className={`mar-right-4`}>
                          {inputList.length !== 1 && (
                            <Button
                              onClick={() => handleRemoveClick(i)}
                              className={`button-remove-setting`}
                              disabled={x.ui_criteria_type === 'Text'}
                            >
                              <Image src={IconCloseWhite} />
                            </Button>
                          )}
                        </div>
                      </div>
                    </GridColumn>
                  </>
                )
              })}
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div
                  className={`field-error-label ${
                    errorInputs.ModifyCriteriaScoreError ? 'show' : ''
                  }`}
                >
                  <Icon name="warning circle" />{' '}
                  {statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_2}
                </div>
              </Grid.Column>
            </Grid.Row>
          </>
        )}
        {optionsSetting ===
          statisticalAnalysisPageConstants.OPTIONS_SETTING.AdjustPropertyRange && (
          <>
            <Grid.Row>
              <Header className={`mar-y-0_5 text-white`} as="h2">
                Adjust Property Range
              </Header>
            </Grid.Row>
            <Grid.Row>
              {inputList.map((x, i) => {
                return (
                  <>
                    {x.ui_criteria_type === 'Number' ? (
                      <div className={`flex mar-y-1`} style={{ width: '31%' }}>
                        <Input
                          name="ui_display_name"
                          className={`input-container`}
                          type="text"
                          value={x.ui_display_name}
                          disabled
                        />
                        <Input
                          name="parameter_offset_percent"
                          className={`input-percent ${
                            errorInputs.AdjustPropertyRangeError ? 'error' : ''
                          }`}
                          type="number"
                          min="1"
                          max="100"
                          value={x.parameter_offset_percent}
                          defaultValue={x.parameter_offset_percent}
                          onChange={(e, data) => {
                            errorInputs.AdjustPropertyRangeError = false
                            handleInputChange(data, i)
                          }}
                          placeholder="0"
                        />
                        <div className={`plus-minus-symbol mar-right-4`}>%</div>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                )
              })}
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div
                  className={`field-error-label ${
                    errorInputs.AdjustPropertyRangeError ? 'show' : ''
                  }`}
                >
                  <Icon name="warning circle" /> {errorInputs.AdjustPropertyRangeError}
                </div>
              </Grid.Column>
            </Grid.Row>
          </>
        )}
        {options === statisticalAnalysisPageConstants.SEARCH_OPTIONS.FindSimiliarCrude &&
          !(
            optionsSetting ===
              statisticalAnalysisPageConstants.OPTIONS_SETTING.ModifyCriteriaScore ||
            optionsSetting === statisticalAnalysisPageConstants.OPTIONS_SETTING.AdjustPropertyRange
          ) && (
            <Grid.Row>
              <Grid.Column width={12}>
                <SearchInput
                  displayProperty="title"
                  displayInListProperty="title"
                  defaultSelection={crudeNameAbbreviation}
                  selectionOptions={crudeNames}
                  onSelectionUpdate={(selectedResult) => {
                    errorInputs.crudeNameAbbreviation = false
                    setCrudeNameAbbreviation(selectedResult)
                  }}
                  placeholder="Enter crude name or abbreviation*"
                  className={errorInputs.crudeNameAbbreviation ? 'error' : ''}
                />
                <div
                  className={`field-error-label ${errorInputs.crudeNameAbbreviation ? 'show' : ''}`}
                >
                  <Icon name="warning circle" />{' '}
                  {statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_1}
                </div>
              </Grid.Column>
            </Grid.Row>
          )}
        {options === statisticalAnalysisPageConstants.SEARCH_OPTIONS.CompareBlend && (
          <>
            <Grid.Row>
              <Grid.Column width={8}>
                <div className={`pad-bottom-1`}>
                  {renderBlendDateComposition(
                    isSearchByCrudeBlendDate,
                    onChangeBlendDateComposition
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
            {isSearchByCrudeBlendDate && (
              <Grid.Row>
                <Grid.Column width={4}>
                  <Header size="small" textAlign="left" className={`text-white`}>
                    Blend A
                  </Header>
                  <Popup
                    trigger={
                      <Input
                        value={getBlendDateDisplayLabel(blendDateA)}
                        error={errorInputs.blendDateA}
                        placeholder="Select blend date*"
                      />
                    }
                    content={
                      <Calendar
                        minDate={minDate}
                        maxDate={endOfNextMonth}
                        date={blendDateA}
                        onChange={(selectedDateA) => setBlendDateA(selectedDateA)}
                      />
                    }
                    on="click"
                    className="bg-blue"
                    hideOnScroll={true}
                    position="bottom right"
                  />
                  <div className={`field-error-label ${errorInputs.blendDateA ? 'show' : ''}`}>
                    <Icon name="warning circle" />{' '}
                    {statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_1}
                  </div>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Header size="small" textAlign="left" className={`text-white`}>
                    Blend B
                  </Header>
                  <Popup
                    trigger={
                      <Input
                        value={getBlendDateDisplayLabel(blendDateB)}
                        error={errorInputs.blendDateB}
                        placeholder="Select blend date*"
                      />
                    }
                    content={
                      <Calendar
                        minDate={minDate}
                        maxDate={endOfNextMonth}
                        date={blendDateB}
                        onChange={(selectedDateB) => setBlendDateB(selectedDateB)}
                      />
                    }
                    on="click"
                    className="bg-blue"
                    hideOnScroll={true}
                    position="bottom right"
                  />
                  <div className={`field-error-label ${errorInputs.blendDateB ? 'show' : ''}`}>
                    <Icon name="warning circle" />{' '}
                    {statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_1}
                  </div>
                </Grid.Column>
              </Grid.Row>
            )}
            {!isSearchByCrudeBlendDate && (
              <>
                <div className={`text-white pad-bottom-1`}>Blend A</div>
                <Grid.Row>
                  <Grid.Column className={`blend-composition-left`}>
                    <Dropdown
                      placeholder="Select CDU*"
                      className={`ddl ddl-secondary`}
                      value={unitBlendA}
                      selection
                      error={errorInputs.unitBlendA}
                      options={crudeBlendUnits}
                      onChange={(e, data) => {
                        errorInputs.unitBlendA = false
                        setUnitBlendA(data.value)
                      }}
                    />
                    <div className={`field-error-label ${errorInputs.unitBlendA ? 'show' : ''}`}>
                      <Icon name="warning circle" />{' '}
                      {statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_1}
                    </div>
                  </Grid.Column>
                  <Grid.Column className={`blend-composition-right`}>
                    <Grid columns={5}>
                      <Grid.Column className="unit-percent-group-container">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={inputUnit1}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.inputUnit1 = false
                              setInputUnit1(selectedResult)
                            }}
                            placeholder="-*"
                            className={`input-unit ${errorInputs.inputUnit1 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={inputPercent1}
                            error={errorInputs.inputPercent1}
                            onChange={({ target = {} }) => {
                              errorInputs.inputPercent1 = false
                              const inputValue = parseFloat(target.value)
                              setInputPercent1(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--*"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>

                        <div
                          className={`field-error-label ${
                            (errorInputs.inputUnit1 || errorInputs.inputPercent1) &&
                            _.isEmpty(errorInputs.errorBlendA)
                              ? 'show'
                              : ''
                          }`}
                        >
                          <Icon name="warning circle" />{' '}
                          {statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_1}
                        </div>
                      </Grid.Column>
                      <Grid.Column className="unit-percent-group-container">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={inputUnit2}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.inputUnit2 = false
                              setInputUnit2(selectedResult)
                            }}
                            placeholder="-*"
                            className={`input-unit ${errorInputs.inputUnit2 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={inputPercent2}
                            error={errorInputs.inputPercent2}
                            onChange={({ target = {} }) => {
                              errorInputs.inputPercent2 = false
                              const inputValue = parseFloat(target.value)
                              setInputPercent2(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--*"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                        <div
                          className={`field-error-label ${
                            (errorInputs.inputUnit2 || errorInputs.inputPercent2) &&
                            _.isEmpty(errorInputs.errorBlendA)
                              ? 'show'
                              : ''
                          }`}
                        >
                          <Icon name="warning circle" />{' '}
                          {statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_1}
                        </div>
                      </Grid.Column>
                      <Grid.Column className="unit-percent-group-container">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={inputUnit3}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.setInputUnit3 = false
                              setInputUnit3(selectedResult)
                            }}
                            placeholder=""
                            className={`input-unit ${errorInputs.inputUnit3 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={inputPercent3}
                            error={errorInputs.inputPercent3}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setInputPercent3(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>
                      <Grid.Column className="unit-percent-group-container">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={inputUnit4}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.setInputUnit4 = false
                              setInputUnit4(selectedResult)
                            }}
                            placeholder=""
                            className={`input-unit ${errorInputs.inputUnit4 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={inputPercent4}
                            error={errorInputs.inputPercent4}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setInputPercent4(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>
                      <Grid.Column className="unit-percent-group-container">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={inputUnit5}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.setInputUnit5 = false
                              setInputUnit5(selectedResult)
                            }}
                            placeholder=""
                            className={`input-unit ${errorInputs.inputUnit5 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={inputPercent5}
                            error={errorInputs.inputPercent5}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setInputPercent5(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>
                      <Grid.Column className="unit-percent-group-container">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={inputUnit6}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.setInputUnit6 = false
                              setInputUnit6(selectedResult)
                            }}
                            placeholder=""
                            className={`input-unit ${errorInputs.inputUnit6 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={inputPercent6}
                            error={errorInputs.inputPercent6}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setInputPercent6(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>
                      <Grid.Column className="unit-percent-group-container">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={inputUnit7}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.setInputUnit7 = false
                              setInputUnit7(selectedResult)
                            }}
                            placeholder=""
                            className={`input-unit ${errorInputs.inputUnit7 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={inputPercent7}
                            error={errorInputs.inputPercent7}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setInputPercent7(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>
                      <Grid.Column className="unit-percent-group-container">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={inputUnit8}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.setInputUnit8 = false
                              setInputUnit8(selectedResult)
                            }}
                            placeholder=""
                            className={`input-unit ${errorInputs.inputUnit8 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={inputPercent8}
                            error={errorInputs.inputPercent8}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setInputPercent8(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>
                      <Grid.Column className="unit-percent-group-container">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={inputUnit9}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.setInputUnit9 = false
                              setInputUnit9(selectedResult)
                            }}
                            placeholder=""
                            className={`input-unit ${errorInputs.inputUnit9 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={inputPercent9}
                            error={errorInputs.inputPercent9}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setInputPercent9(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>
                      <Grid.Column className="unit-percent-group-container">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={inputUnit10}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.setInputUnit10 = false
                              setInputUnit10(selectedResult)
                            }}
                            placeholder=""
                            className={`input-unit ${errorInputs.inputUnit10 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={inputPercent10}
                            error={errorInputs.inputPercent10}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setInputPercent10(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ display: !_.isEmpty(errorInputs.errorBlendA) ? '' : 'none' }}>
                  <Grid.Column>
                    <div
                      className={`field-error-label error-msg-percentage ${
                        !_.isEmpty(errorInputs.errorBlendA) ? 'show' : ''
                      }`}
                    >
                      <Icon name="warning circle" /> {errorInputs.errorBlendA}
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <div className={`text-white pad-bottom-1`}>Blend B</div>
                <Grid.Row>
                  <Grid.Column className={`blend-composition-left`}>
                    <Dropdown
                      placeholder="Select CDU*"
                      className={`ddl ddl-secondary`}
                      value={unitBlendB}
                      selection
                      error={errorInputs.unitBlendB}
                      options={crudeBlendUnits}
                      onChange={(e, data) => {
                        errorInputs.unitBlendB = false
                        setUnitBlendB(data.value)
                      }}
                    />
                    <div className={`field-error-label ${errorInputs.unitBlendB ? 'show' : ''}`}>
                      <Icon name="warning circle" />{' '}
                      {statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_1}
                    </div>
                  </Grid.Column>
                  <Grid.Column className={`blend-composition-right`}>
                    <Grid columns={5}>
                      <Grid.Column className="unit-percent-group-container">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={inputUnit11}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.inputUnit11 = false
                              setInputUnit11(selectedResult)
                            }}
                            placeholder="-*"
                            className={`input-unit ${errorInputs.inputUnit11 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={inputPercent11}
                            error={errorInputs.inputPercent11}
                            onChange={({ target = {} }) => {
                              errorInputs.inputPercent11 = false
                              const inputValue = parseFloat(target.value)
                              setInputPercent11(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--*"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                        <div
                          className={`field-error-label ${
                            (errorInputs.inputUnit11 || errorInputs.inputPercent11) &&
                            _.isEmpty(errorInputs.errorBlendB)
                              ? 'show'
                              : ''
                          }`}
                        >
                          <Icon name="warning circle" />{' '}
                          {statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_1}
                        </div>
                      </Grid.Column>
                      <Grid.Column className="unit-percent-group-container">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={inputUnit12}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.inputUnit12 = false
                              setInputUnit12(selectedResult)
                            }}
                            placeholder="-*"
                            className={`input-unit ${errorInputs.inputUnit12 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={inputPercent12}
                            error={errorInputs.inputPercent12}
                            onChange={({ target = {} }) => {
                              errorInputs.inputPercent12 = false
                              const inputValue = parseFloat(target.value)
                              setInputPercent12(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--*"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                        <div
                          className={`field-error-label ${
                            (errorInputs.inputUnit12 || errorInputs.inputPercent12) &&
                            _.isEmpty(errorInputs)
                              ? 'show'
                              : ''
                          }`}
                        >
                          <Icon name="warning circle" />{' '}
                          {statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_1}
                        </div>
                      </Grid.Column>
                      <Grid.Column className="unit-percent-group-container">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={inputUnit13}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.inputUnit13 = false
                              setInputUnit13(selectedResult)
                            }}
                            placeholder=""
                            className={`input-unit ${errorInputs.inputUnit13 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={inputPercent13}
                            error={errorInputs.inputPercent13}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setInputPercent13(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>
                      <Grid.Column className="unit-percent-group-container">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={inputUnit14}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.inputUnit14 = false
                              setInputUnit14(selectedResult)
                            }}
                            placeholder=""
                            className={`input-unit ${errorInputs.inputUnit14 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={inputPercent14}
                            error={errorInputs.inputPercent14}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setInputPercent14(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>
                      <Grid.Column className="unit-percent-group-container">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={inputUnit15}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.inputUnit15 = false
                              setInputUnit15(selectedResult)
                            }}
                            placeholder=""
                            className={`input-unit ${errorInputs.inputUnit15 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={inputPercent15}
                            error={errorInputs.inputPercent15}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setInputPercent15(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>
                      <Grid.Column className="unit-percent-group-container">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={inputUnit16}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.inputUnit16 = false
                              setInputUnit16(selectedResult)
                            }}
                            placeholder=""
                            className={`input-unit ${errorInputs.inputUnit16 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={inputPercent16}
                            error={errorInputs.inputPercent16}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setInputPercent16(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>
                      <Grid.Column className="unit-percent-group-container">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={inputUnit17}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.inputUnit17 = false
                              setInputUnit17(selectedResult)
                            }}
                            placeholder=""
                            className={`input-unit ${errorInputs.inputUnit17 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={inputPercent17}
                            error={errorInputs.inputPercent17}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setInputPercent17(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>
                      <Grid.Column className="unit-percent-group-container">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={inputUnit18}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.inputUnit18 = false
                              setInputUnit18(selectedResult)
                            }}
                            placeholder=""
                            className={`input-unit ${errorInputs.inputUnit18 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={inputPercent18}
                            error={errorInputs.inputPercent18}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setInputPercent18(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>
                      <Grid.Column className="unit-percent-group-container">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={inputUnit19}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.inputUnit19 = false
                              setInputUnit19(selectedResult)
                            }}
                            placeholder=""
                            className={`input-unit ${errorInputs.inputUnit19 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={inputPercent19}
                            error={errorInputs.inputPercent19}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setInputPercent19(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>
                      <Grid.Column className="unit-percent-group-container">
                        <div className="unit-percent-group">
                          <SearchInput
                            displayProperty="title"
                            displayInListProperty="title"
                            defaultSelection={inputUnit20}
                            selectionOptions={crudeNames}
                            onSelectionUpdate={(selectedResult) => {
                              errorInputs.inputUnit20 = false
                              setInputUnit20(selectedResult)
                            }}
                            placeholder=""
                            className={`input-unit ${errorInputs.inputUnit20 ? 'error' : ''}`}
                          />
                          <Input
                            className="input-percent"
                            type="number"
                            min="1"
                            max="100"
                            value={inputPercent20}
                            error={errorInputs.inputPercent20}
                            onChange={({ target = {} }) => {
                              const inputValue = parseFloat(target.value)
                              setInputPercent20(_.isNaN(inputValue) ? undefined : inputValue)
                            }}
                            placeholder="--"
                          ></Input>
                          <div className="input-percent-symbol">%</div>
                        </div>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ display: !_.isEmpty(errorInputs.errorBlendB) ? '' : 'none' }}>
                  <Grid.Column>
                    <div
                      className={`field-error-label error-msg-percentage ${
                        !_.isEmpty(errorInputs.errorBlendB) ? 'show' : ''
                      }`}
                    >
                      <Icon name="warning circle" /> {errorInputs.errorBlendB}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </>
            )}
            <Grid.Row>
              <Grid.Column>
                <div className="operating-criteria-wrapper flex center">
                  <div className="operating-criteria-label">Blend Property Comparison</div>
                  <div className="operating-criteria-line"></div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={6}>
              <Grid.Column>
                <Dropdown
                  placeholder="Data Source"
                  className={`ddl-secondary large-dropdown-container`}
                  value={blendDataSource}
                  selection
                  options={compareBlendDataSources}
                  onChange={(e, data) => {
                    setBlendDataSource(data.value)
                    setOperatingUnit('')
                    setFraction('')
                    setCompareBlendProperty1('')
                    setCompareBlendProperty2('')
                    setCompareBlendProperty3('')
                    setCompareBlendProperty4('')
                    setCompareBlendProperty5('')
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <Dropdown
                  placeholder="Select Unit*"
                  className={`ddl-secondary large-dropdown-container`}
                  value={operatingUnit}
                  selection
                  search
                  error={errorInputs.operatingUnit}
                  options={crudeBlendUnitsDS}
                  onChange={(e, data) => {
                    errorInputs.operatingUnit = false
                    setOperatingUnit(data.value)
                    setFraction('')
                    setCompareBlendProperty1('')
                    setCompareBlendProperty2('')
                    setCompareBlendProperty3('')
                    setCompareBlendProperty4('')
                    setCompareBlendProperty5('')
                  }}
                />
                <div className={`field-error-label ${errorInputs.operatingUnit ? 'show' : ''}`}>
                  <Icon name="warning circle" />{' '}
                  {statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_1}
                </div>
              </Grid.Column>
              <Grid.Column>
                <Dropdown
                  placeholder="Select fraction*"
                  className={`ddl-secondary large-dropdown-container`}
                  value={fraction}
                  selection
                  search
                  error={errorInputs.fraction}
                  options={compareBlendFraction}
                  onChange={(e, data) => {
                    errorInputs.fraction = false
                    setFraction(data.value)
                    setCompareBlendProperty1('')
                    setCompareBlendProperty2('')
                    setCompareBlendProperty3('')
                    setCompareBlendProperty4('')
                    setCompareBlendProperty5('')
                  }}
                />
                <div className={`field-error-label ${errorInputs.fraction ? 'show' : ''}`}>
                  <Icon name="warning circle" />{' '}
                  {statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_1}
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className={`flex space-between`}>
                  <div className={`flex column large-dropdown`}>
                    <div className={`text-title mar-bottom-0_5`}>Angle 1</div>
                    <Dropdown
                      placeholder="Select Property*"
                      className={`ddl-secondary large-dropdown-container`}
                      value={compareBlendProperty1}
                      selection
                      search
                      error={errorInputs.compareBlendProperty1}
                      options={crudeBlendProperties}
                      onChange={(e, data) => {
                        errorInputs.compareBlendProperty1 = false
                        setCompareBlendProperty1(data.value)
                      }}
                    />
                    <div
                      className={`field-error-label ${
                        errorInputs.compareBlendProperty1 ? 'show' : ''
                      }`}
                    >
                      <Icon name="warning circle" />{' '}
                      {statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_1}
                    </div>
                  </div>
                  <div className={`flex column large-dropdown`}>
                    <div className={`text-title mar-bottom-0_5`}>Angle 2</div>
                    <Dropdown
                      placeholder="Select Property*"
                      className={`ddl-secondary large-dropdown-container`}
                      value={compareBlendProperty2}
                      selection
                      search
                      error={errorInputs.compareBlendProperty2}
                      options={crudeBlendProperties}
                      onChange={(e, data) => {
                        errorInputs.compareBlendProperty2 = false
                        setCompareBlendProperty2(data.value)
                      }}
                    />
                    <div
                      className={`field-error-label ${
                        errorInputs.compareBlendProperty2 ? 'show' : ''
                      }`}
                    >
                      <Icon name="warning circle" />{' '}
                      {statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_1}
                    </div>
                  </div>
                  <div className={`flex column large-dropdown`}>
                    <div className={`text-title mar-bottom-0_5`}>Angle 3</div>
                    <Dropdown
                      placeholder="Select Property"
                      className={`ddl-secondary large-dropdown-container`}
                      value={compareBlendProperty3}
                      selection
                      search
                      options={crudeBlendProperties}
                      onChange={(e, data) => {
                        setCompareBlendProperty3(data.value)
                      }}
                    />
                  </div>
                  <div className={`flex column large-dropdown`}>
                    <div className={`text-title mar-bottom-0_5`}>Angle 4</div>
                    <Dropdown
                      placeholder="Select Property"
                      className={`ddl-secondary large-dropdown-container`}
                      value={compareBlendProperty4}
                      selection
                      search
                      options={crudeBlendProperties}
                      onChange={(e, data) => {
                        setCompareBlendProperty4(data.value)
                      }}
                    />
                  </div>
                  <div className={`flex column large-dropdown`}>
                    <div className={`text-title mar-bottom-0_5`}>Angle 5</div>
                    <Dropdown
                      placeholder="Select Property"
                      className={`ddl-secondary large-dropdown-container`}
                      value={compareBlendProperty5}
                      selection
                      search
                      options={crudeBlendProperties}
                      onChange={(e, data) => {
                        setCompareBlendProperty5(data.value)
                      }}
                    />
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </>
        )}
        {options === statisticalAnalysisPageConstants.SEARCH_OPTIONS.CompareCrude && (
          <>
            <Grid.Row>
              <Grid.Column width={3} className={`mar-right-2`}>
                <Header size="small" textAlign="left" className={`text-white`}>
                  Data Source
                </Header>
                <Dropdown
                  placeholder="Data Source"
                  className={`ddl-secondary large-dropdown-container mar-right-3`}
                  value={dataSource}
                  selection
                  options={compareCrudeDataSources}
                  onChange={(e, data) => {
                    setDataSource(data.value)
                  }}
                />
              </Grid.Column>
              <Grid.Column width={3} className={`mar-right-2`}>
                <Header size="small" textAlign="left" className={`text-white`}>
                  Crude A
                </Header>
                <SearchInput
                  displayProperty="title"
                  displayInListProperty="title"
                  defaultSelection={crudeNameA}
                  selectionOptions={crudeNames}
                  onSelectionUpdate={(selectedResult) => {
                    errorInputs.crudeNameA = false
                    setCrudeNameA(selectedResult)
                  }}
                  placeholder="Enter crude name*"
                  className={errorInputs.crudeNameA ? 'error' : ''}
                />
                <div className={`field-error-label ${errorInputs.crudeNameA ? 'show' : ''}`}>
                  <Icon name="warning circle" />{' '}
                  {statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_1}
                </div>
              </Grid.Column>
              <Grid.Column width={3} className={`mar-right-2`}>
                <Header size="small" textAlign="left" className={`text-white`}>
                  Crude B
                </Header>
                <SearchInput
                  displayProperty="title"
                  displayInListProperty="title"
                  defaultSelection={crudeNameB}
                  selectionOptions={crudeNames}
                  onSelectionUpdate={(selectedResult) => {
                    errorInputs.crudeNameB = false
                    setCrudeNameB(selectedResult)
                  }}
                  placeholder="Enter crude name*"
                  className={errorInputs.crudeNameB ? 'error' : ''}
                />
                <div className={`field-error-label ${errorInputs.crudeNameB ? 'show' : ''}`}>
                  <Icon name="warning circle" />{' '}
                  {statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_1}
                </div>
              </Grid.Column>
              <Grid.Column width={3} className={`mar-right-2`}>
                <Header size="small" textAlign="left" className={`text-white`}>
                  Crude C
                </Header>
                <SearchInput
                  displayProperty="title"
                  displayInListProperty="title"
                  defaultSelection={crudeNameC}
                  selectionOptions={crudeNames}
                  onSelectionUpdate={(selectedResult) => {
                    setCrudeNameC(selectedResult)
                  }}
                  placeholder="Enter crude name*"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className="operating-criteria-wrapper flex center">
                  <div className="operating-criteria-label">
                    Crude Property Comparison (Whole crude)
                  </div>
                  <div className="operating-criteria-line"></div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className={`flex`}>
                  <Dropdown
                    placeholder="Select CAM unit for limit"
                    className={`ddl-secondary unit-small-dropdown-container`}
                    value={compareUnit}
                    selection
                    options={crudePropertiesUnits}
                    onChange={(e, data) => {
                      setCompareUnit(data.value)
                    }}
                  />
                  <div className={`property-comparision-fraction`}>{getFractions(dataSource)}</div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className={`flex space-between`}>
                  <div className={`large-dropdown`}>
                    <div className={`text-title mar-bottom-0_5`}>Angle 1</div>
                    <Dropdown
                      placeholder="Select Property"
                      className={`ddl-secondary large-dropdown-container ${
                        errorInputs.compareProperty1 ? 'error' : ''
                      }`}
                      value={compareProperty1}
                      selection
                      search
                      options={crudeCompareProperties}
                      onChange={(e, data) => {
                        errorInputs.compareProperty1 = false
                        setCompareProperty1(data.value)
                      }}
                    />
                    <div
                      className={`field-error-label ${errorInputs.compareProperty1 ? 'show' : ''}`}
                    >
                      <Icon name="warning circle" />{' '}
                      {statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_1}
                    </div>
                  </div>

                  <div className={`large-dropdown`}>
                    <div className={`text-title mar-bottom-0_5`}>Angle 2</div>
                    <Dropdown
                      placeholder="Select Property"
                      className={`ddl-secondary large-dropdown-container ${
                        errorInputs.compareProperty2 ? 'error' : ''
                      }`}
                      value={compareProperty2}
                      selection
                      search
                      options={crudeCompareProperties}
                      onChange={(e, data) => {
                        errorInputs.compareProperty2 = false
                        setCompareProperty2(data.value)
                      }}
                    />
                    <div
                      className={`field-error-label ${errorInputs.compareProperty2 ? 'show' : ''}`}
                    >
                      <Icon name="warning circle" />{' '}
                      {statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_1}
                    </div>
                  </div>

                  <div className={`large-dropdown`}>
                    <div className={`text-title mar-bottom-0_5`}>Angle 3</div>
                    <Dropdown
                      placeholder="Select Property"
                      className={`ddl-secondary large-dropdown-container`}
                      value={compareProperty3}
                      selection
                      search
                      options={crudeCompareProperties}
                      onChange={(e, data) => {
                        setCompareProperty3(data.value)
                      }}
                    />
                  </div>

                  <div className={`large-dropdown`}>
                    <div className={`text-title mar-bottom-0_5`}>Angle 4</div>
                    <Dropdown
                      placeholder="Select Property"
                      className={`ddl-secondary large-dropdown-container`}
                      value={compareProperty4}
                      selection
                      search
                      options={crudeCompareProperties}
                      onChange={(e, data) => {
                        setCompareProperty4(data.value)
                      }}
                    />
                  </div>

                  <div className={`large-dropdown`}>
                    <div className={`text-title mar-bottom-0_5`}>Angle 5</div>
                    <Dropdown
                      placeholder="Select Property"
                      className={`ddl-secondary large-dropdown-container`}
                      value={compareProperty5}
                      selection
                      search
                      options={crudeCompareProperties}
                      onChange={(e, data) => {
                        setCompareProperty5(data.value)
                      }}
                    />
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className="operating-criteria-wrapper flex center">
                  <div className="operating-criteria-label">Crude Cut Comparison (Fraction)</div>
                  <div className="operating-criteria-line"></div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className={`flex space-between`}>
                  <div className={`flex column cut-comparision-prop-container`}>
                    <div className={`flex column large-dropdown`}>
                      <div className={`text-title mar-bottom-0_5`}>Angle 1</div>
                      <Dropdown
                        placeholder="Select Fraction"
                        className={`ddl-secondary large-dropdown-container ${
                          errorInputs.fraction1 ? 'error' : ''
                        }`}
                        value={fraction1}
                        selection
                        search
                        options={crudeCompareFractions}
                        onChange={(e, data) => {
                          errorInputs.fraction1 = false
                          setFraction1(data.value)
                        }}
                      />
                      <div className={`field-error-label ${errorInputs.fraction1 ? 'show' : ''}`}>
                        <Icon name="warning circle" />{' '}
                        {statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_1}
                      </div>
                      <Dropdown
                        placeholder="Select Property"
                        className={`ddl-secondary large-dropdown-container ${
                          errorInputs.cutProperty1 ? 'error' : ''
                        }`}
                        value={cutProperty1}
                        selection
                        search
                        options={crudeCutProperties1}
                        onChange={(e, data) => {
                          errorInputs.cutProperty1 = false
                          setCutProperty1(data.value)
                        }}
                      />
                      <div
                        className={`field-error-label ${errorInputs.cutProperty1 ? 'show' : ''}`}
                      >
                        <Icon name="warning circle" />{' '}
                        {statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_1}
                      </div>
                      <Dropdown
                        placeholder="Select CAM unit for limit"
                        className={`ddl-secondary large-dropdown-container`}
                        value={cutUnit1}
                        selection
                        search
                        options={crudeCutCamUnit1}
                        onChange={(e, data) => {
                          setCutUnit1(data.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className={`flex column cut-comparision-prop-container`}>
                    <div className={`flex column large-dropdown`}>
                      <div className={`text-title mar-bottom-0_5`}>Angle 2</div>
                      <Dropdown
                        placeholder="Select Fraction"
                        className={`ddl-secondary large-dropdown-container ${
                          errorInputs.fraction2 ? 'error' : ''
                        }`}
                        value={fraction2}
                        selection
                        search
                        options={crudeCompareFractions}
                        onChange={(e, data) => {
                          errorInputs.fraction2 = false
                          setFraction2(data.value)
                        }}
                      />
                      <div className={`field-error-label ${errorInputs.fraction2 ? 'show' : ''}`}>
                        <Icon name="warning circle" />{' '}
                        {statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_1}
                      </div>
                      <Dropdown
                        placeholder="Select Property"
                        className={`ddl-secondary large-dropdown-container ${
                          errorInputs.cutProperty2 ? 'error' : ''
                        }`}
                        value={cutProperty2}
                        selection
                        search
                        options={crudeCutProperties2}
                        onChange={(e, data) => {
                          errorInputs.cutProperty2 = false
                          setCutProperty2(data.value)
                        }}
                      />
                      <div
                        className={`field-error-label ${errorInputs.cutProperty2 ? 'show' : ''}`}
                      >
                        <Icon name="warning circle" />{' '}
                        {statisticalAnalysisPageConstants.VALIDATION_MSG.ERROR_MSG_1}
                      </div>
                      <Dropdown
                        placeholder="Select CAM unit for limit"
                        className={`ddl-secondary large-dropdown-container`}
                        value={cutUnit2}
                        selection
                        search
                        options={crudeCutCamUnit2}
                        onChange={(e, data) => {
                          setCutUnit2(data.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className={`flex column cut-comparision-prop-container`}>
                    <div className={`flex column large-dropdown`}>
                      <div className={`text-title mar-bottom-0_5`}>Angle 3</div>
                      <Dropdown
                        placeholder="Select Fraction"
                        className={`ddl-secondary large-dropdown-container`}
                        value={fraction3}
                        selection
                        search
                        options={crudeCompareFractions}
                        onChange={(e, data) => {
                          setFraction3(data.value)
                        }}
                      />
                      <Dropdown
                        placeholder="Select Property"
                        className={`ddl-secondary large-dropdown-container`}
                        value={cutProperty3}
                        selection
                        search
                        options={crudeCutProperties3}
                        onChange={(e, data) => {
                          setCutProperty3(data.value)
                        }}
                      />
                      <Dropdown
                        placeholder="Select CAM unit for limit"
                        className={`ddl-secondary large-dropdown-container`}
                        value={cutUnit3}
                        selection
                        search
                        options={crudeCutCamUnit3}
                        onChange={(e, data) => {
                          setCutUnit3(data.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className={`flex column cut-comparision-prop-container`}>
                    <div className={`flex column large-dropdown`}>
                      <div className={`text-title mar-bottom-0_5`}>Angle 4</div>
                      <Dropdown
                        placeholder="Select Fraction"
                        className={`ddl-secondary large-dropdown-container`}
                        value={fraction4}
                        selection
                        search
                        options={crudeCompareFractions}
                        onChange={(e, data) => {
                          setFraction4(data.value)
                        }}
                      />
                      <Dropdown
                        placeholder="Select Property"
                        className={`ddl-secondary large-dropdown-container`}
                        value={cutProperty4}
                        selection
                        search
                        options={crudeCutProperties4}
                        onChange={(e, data) => {
                          setCutProperty4(data.value)
                        }}
                      />
                      <Dropdown
                        placeholder="Select CAM unit for limit"
                        className={`ddl-secondary large-dropdown-container`}
                        value={cutUnit4}
                        selection
                        search
                        options={crudeCutCamUnit4}
                        onChange={(e, data) => {
                          setCutUnit4(data.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className={`flex column cut-comparision-prop-container`}>
                    <div className={`flex column large-dropdown`}>
                      <div className={`text-title mar-bottom-0_5`}>Angle 5</div>
                      <Dropdown
                        placeholder="Select Fraction"
                        className={`ddl-secondary large-dropdown-container`}
                        value={fraction5}
                        selection
                        search
                        options={crudeCompareFractions}
                        onChange={(e, data) => {
                          setFraction5(data.value)
                        }}
                      />
                      <Dropdown
                        placeholder="Select Property"
                        className={`ddl-secondary large-dropdown-container`}
                        value={cutProperty5}
                        selection
                        search
                        options={crudeCutProperties5}
                        onChange={(e, data) => {
                          setCutProperty5(data.value)
                        }}
                      />
                      <Dropdown
                        placeholder="Select CAM unit for limit"
                        className={`ddl-secondary large-dropdown-container`}
                        value={cutUnit5}
                        selection
                        search
                        options={crudeCutCamUnit5}
                        onChange={(e, data) => {
                          setCutUnit5(data.value)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </>
        )}
        <Grid.Row>
          <div className={`flex end width-100`}>
            {(optionsSetting ===
              statisticalAnalysisPageConstants.OPTIONS_SETTING.ModifyCriteriaScore ||
              optionsSetting ===
                statisticalAnalysisPageConstants.OPTIONS_SETTING.AdjustPropertyRange) && (
              <>
                {optionsSetting ===
                  statisticalAnalysisPageConstants.OPTIONS_SETTING.ModifyCriteriaScore && (
                  <Button onClick={handleAddClick} className={`btn-secondary`}>
                    <Image src={AddRound} verticalAlign="middle" className={`add-property-img`} />
                    Add Property
                  </Button>
                )}
                <Button className="btn-tertiary" onClick={onClickCancelSettingPage}>
                  Cancel
                </Button>
              </>
            )}
            {options === statisticalAnalysisPageConstants.SEARCH_OPTIONS.CompareCrude && (
              <Button className="reset-btn" onClick={onClickResetCompareCrude}>
                Reset
              </Button>
            )}
            {options === statisticalAnalysisPageConstants.SEARCH_OPTIONS.CompareBlend && (
              <Button className="reset-btn" onClick={onClickResetCompareBlend}>
                Reset
              </Button>
            )}
            {!(
              optionsSetting ===
                statisticalAnalysisPageConstants.OPTIONS_SETTING.ModifyCriteriaScore ||
              optionsSetting ===
                statisticalAnalysisPageConstants.OPTIONS_SETTING.AdjustPropertyRange
            ) && (
              <Button className="btn-primary" onClick={onClickCalculate}>
                {options === statisticalAnalysisPageConstants.SEARCH_OPTIONS.FindSimiliarCrude
                  ? 'Search'
                  : 'Compare'}
              </Button>
            )}
            {(optionsSetting ===
              statisticalAnalysisPageConstants.OPTIONS_SETTING.ModifyCriteriaScore ||
              optionsSetting ===
                statisticalAnalysisPageConstants.OPTIONS_SETTING.AdjustPropertyRange) && (
              <>
                <Button className="btn-primary" onClick={onClickSaveSettingPage}>
                  Save
                </Button>
                <Modal
                  className={`modal-table`}
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                  open={open}
                >
                  <Modal.Header>
                    <div className={`flex`}>
                      <div className={`left-header flex center`}>
                        <Header size="small" style={{ margin: 'auto 0' }}>
                          Infomation
                        </Header>
                      </div>
                      <div className={`right-header`}>
                        <div className={`flex end`}>
                          <Button
                            onClick={() => setOpen(false)}
                            style={{ backgroundColor: 'transparent' }}
                          >
                            <Image src={IconClose} />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Modal.Header>
                  <Modal.Content>
                    <div className={`flex center pad-bottom-2`}>{settingPageSavedMsg}</div>
                    <div className={`flex end`}>
                      {optionsSetting ===
                      statisticalAnalysisPageConstants.OPTIONS_SETTING.AdjustPropertyRange ? (
                        <Button className="btn-primary" onClick={() => setOpen(false)}>
                          OK
                        </Button>
                      ) : (
                        <Button
                          className="btn-primary"
                          onClick={onClickNavigateAdjustPropertyRange}
                        >
                          Go to Adjust Property Range
                        </Button>
                      )}
                    </div>
                  </Modal.Content>
                </Modal>
              </>
            )}
          </div>
        </Grid.Row>
      </Grid>
      <FetchStateWrapper
        id={StatisticalAnalysisPageActions.STATISTICAL_ANALYSIS_SIMILIAR_CRUDE}
      ></FetchStateWrapper>
      <FetchStateWrapper
        id={StatisticalAnalysisPageActions.STATISTICAL_ANALYSIS_COMPARE_CRUDE}
      ></FetchStateWrapper>
      <FetchStateWrapper
        id={StatisticalAnalysisPageActions.STATISTICAL_ANALYSIS_COMPARE_BLEND}
      ></FetchStateWrapper>

      <FetchStateWrapper
        id={StatisticalAnalysisPageActions.STATISTICAL_ANALYSIS_GET_SETTING_PAGE}
      ></FetchStateWrapper>

      <FetchStateWrapper
        id={StatisticalAnalysisPageActions.STATISTICAL_ANALYSIS_SAVE_SETTING_PAGE}
      ></FetchStateWrapper>
    </div>
  )
}

export default SearchPanel
