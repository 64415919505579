import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Grid, Header, Image } from 'semantic-ui-react'

// Asset
import IconFavouriteUnclick from '../../../../assets/images/icon-favourite-unclick.svg'
import IconFavouriteClicked from '../../../../assets/images/icon-favourite-clicked.svg'

//Components
import NoDataBlock from '../../../../components/no-data-block'
import FetchStateWrapper from '../../../../components/fetch-state-wrapper'

//Redux
import { RISKS_PAGE_REDUCER } from '../../../../reducers/risksPageReducer'
import { searchRisksHeader, saveHeaderFavourite } from '../../../../actions/actionRisks'
import * as RisksActions from '../../../../actions/actionRisks'
import { setFavouriteIcon } from '../../../../actions/actionRisks'
import { useDispatch } from 'react-redux'

//Hooks
import { useStateWithPaths, useActions } from '../../../../hooks/useConnect'

//Constants
import * as landingPageConstant from '../../landing-page/helper'

const CrudeHeader = (props) => {
  const crudeNameAbbreviation = props.searchObject.crudeNameAbbreviation
  const options = props.searchObject.options
  const currentPersona = props.searchObject.currentPersona
  const dispatch = useDispatch()
  const crudeCode = _.get(crudeNameAbbreviation, 'crudeCode')

  const [{ risksPage }] = useStateWithPaths([`${RISKS_PAGE_REDUCER}`])
  const singleCrudeHeaderData = _.get(risksPage.singleCrudeHeader, 'data')
  const customBlendHeaderData = _.get(risksPage.customBlendHeader, 'data')

  const getRejection = _.get(singleCrudeHeaderData, 'rejection')

  const [isFavourite] = useStateWithPaths([`risksPage.risksPage.isFavourite`])

  useEffect(() => {
    dispatch(searchRisksHeader(props.searchObject))
  }, [dispatch, searchRisksHeader, props.searchObject])

  useEffect(() => {
    dispatch(setFavouriteIcon(currentPersona, crudeCode))
  }, [dispatch, setFavouriteIcon, currentPersona, crudeCode])

  const onClickFavourite = (isClickedOption) => {
    dispatch(
      saveHeaderFavourite({
        currentPersona,
        crudeNameAbbreviation,
        isClickedOption,
      }),
      [saveHeaderFavourite, currentPersona, crudeNameAbbreviation, isClickedOption]
    )
    setTimeout(function () {
      dispatch(setFavouriteIcon(currentPersona, crudeCode), [
        setFavouriteIcon,
        currentPersona,
        crudeCode,
      ])
    }, 1000)
    // dispatch(
    //   searchRisksHeader(props.searchObject)
    // )
  }

  return (
    <>
      {options === landingPageConstant.SEARCH_OPTIONS.SingleCrude ? (
        <>
          <FetchStateWrapper id={RisksActions.RISKS_SINGLE_CRUDE_HEADER}></FetchStateWrapper>
          <FetchStateWrapper id={RisksActions.RISKS_SIMILIAR_CRUDE}></FetchStateWrapper>
          <FetchStateWrapper id={RisksActions.RISKS_SAVE_FAVOURITE}></FetchStateWrapper>
          <div className="section section-crude-header flex single-crude">
            {!_.isEmpty(singleCrudeHeaderData) ? (
              <Grid padded>
                <Grid.Row style={{ padding: '0' }}>
                  <Grid.Column width={15}>
                    <Header size="big" textAlign="center">
                      {!_.isEmpty(singleCrudeHeaderData)
                        ? singleCrudeHeaderData.crudeCode + ' - ' + singleCrudeHeaderData.crudeName
                        : ''}
                    </Header>
                  </Grid.Column>
                  <Grid.Column>
                    <Image
                      src={isFavourite ? IconFavouriteClicked : IconFavouriteUnclick}
                      onClick={() => onClickFavourite(!isFavourite)}
                      className={`favourite-btn`}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column textAlign="center" width={15}>
                    <>
                      <div className="rate-point"></div>
                      <div className={`rate-value`}>
                        Origin{` `}
                        <span className={`processing-unit`}>
                          {_.isEmpty(singleCrudeHeaderData.origin)
                            ? 'N.A'
                            : singleCrudeHeaderData.origin}
                        </span>
                      </div>
                      <div className="rate-point"></div>
                      <div className={`rate-value`}>
                        Region{` `}
                        <span className={`processing-unit`}>
                          {_.isEmpty(singleCrudeHeaderData.region)
                            ? 'N.A'
                            : singleCrudeHeaderData.region}
                        </span>
                      </div>
                      <div className="rate-point"></div>
                      <div className={`rate-value`}>
                        Kerogen Type{' '}
                        {_.isEmpty(singleCrudeHeaderData.kerogenType)
                          ? 'N.A'
                          : singleCrudeHeaderData.kerogenType}
                      </div>
                      <div className="rate-point"></div>
                      <div className={`rate-value`}>
                        {_.isEmpty(singleCrudeHeaderData.characteristic)
                          ? 'N.A'
                          : singleCrudeHeaderData.characteristic}
                      </div>
                      <div className="rate-point"></div>
                      <div className={`rate-value`}>
                        {_.isEmpty(singleCrudeHeaderData.maturity)
                          ? 'N.A'
                          : singleCrudeHeaderData.maturity}
                      </div>
                      <div className="rate-point"></div>
                      <div className={`rate-value`}>
                        Has been purchased{' '}
                        <span className={`processing-unit`}>
                          {singleCrudeHeaderData.timesPurchased}
                        </span>{' '}
                        times{' '}
                        <span className={`processing-unit`}>
                          ({singleCrudeHeaderData.percentagePurchased}%)
                        </span>{' '}
                        since 2017
                      </div>
                      {!_.isEmpty(getRejection) && (
                        <>
                          <div className="rate-point"></div>
                          <div className={`rate-value`}>
                            Crude cannot be processed at{" "}
                            <span className={`processing-unit`}>
                              {_.join(getRejection, ', ')}
                            </span>
                          </div>
                        </>
                      )}
                    </>
                  </Grid.Column>
                  <Grid.Column width={1}></Grid.Column>
                </Grid.Row>
                {_.map(singleCrudeHeaderData.crudeProcessing, (item) => (
                  <Grid.Row>
                    <Grid.Column width={4} textAlign="center">
                      <div className={`processing-unit`} style={{ display: 'inline-block' }}>
                        {item.unit}
                      </div>{' '}
                      <div className={`processing-header`}>%Processing</div>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <div className={`processing-header`}>Latest:</div>
                      <div className={`processing-value`}>
                        <div className={`processing-value-percentage`}>
                          {item.latestProcessingValue} {item.latestProcessingValue >= 0 ? '%' : ''}
                        </div>
                        <div className={`processing-value-date`}>{item.latestProcessingDate}</div>
                      </div>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <div className={`processing-header`}>Max.</div>
                      <div className={`processing-value`}>
                        <div className={`processing-value-percentage`}>
                          {item.maxInHistoryValue} {item.maxInHistoryValue >= 0 ? '%' : ''}
                        </div>
                        <div className={`processing-value-date`}>{item.maxInHistoryDate}</div>
                      </div>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <div className={`processing-header`}>Min.</div>
                      <div className={`processing-value`}>
                        <div className={`processing-value-percentage`}>
                          {item.minInHistoryValue} {item.minInHistoryValue >= 0 ? '%' : ''}
                        </div>
                        <div className={`processing-value-date`}>{item.minInHistoryDate}</div>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                ))}
              </Grid>
            ) : (
              <NoDataBlock />
            )}
          </div>
        </>
      ) : (
        <>
          <FetchStateWrapper id={RisksActions.RISKS_CUSTOM_BLEND_HEADER}></FetchStateWrapper>

          {!_.isEmpty(customBlendHeaderData) ? (
            <div className="section section-crude-header flex compare-blend">
              <div className={`pad-2 width-100 center`}>
                <div className={`pad-y-1`}>
                  <div className={`large-header-value`}>
                    Blend of{' '}
                    <span className={`large-header-value-bold`}>{customBlendHeaderData.date},</span>{' '}
                    {customBlendHeaderData.unit}
                  </div>
                  <div className={`small-header-value`}>
                    Similar to the blend you have selected.
                  </div>
                </div>

                <div>
                  {_.map(customBlendHeaderData.blendComposition, (a) => (
                    <>
                      <div
                        style={{
                          background: `${a.color}`,
                          height: '35px',
                          width: `${_.isNull(a.value) ? 0 : a.value - 0.01}%`,
                          display: 'inline-block',
                        }}
                      />
                    </>
                  ))}
                </div>
                <div>
                  <Grid padded>
                    <Grid.Row columns={4}>
                      {_.map(customBlendHeaderData.blendComposition, (a) => (
                        <Grid.Column className={`horizontal-detail`}>
                          <div style={{ backgroundColor: a.color }} />
                          <div>{a.name}</div>
                          <div>{a.value > 0 ? a.value : 0}%</div>
                        </Grid.Column>
                      ))}
                    </Grid.Row>
                  </Grid>
                </div>
              </div>
            </div>
          ) : (
            <div className="section section-crude-header">
              <div className={`loader-message mar-2 center`}>
                <span>The blend of </span>
                {_.map(props.searchObject.inputUnits, (item, index) => {
                  return props.searchObject.inputUnits.length - 1 === index
                    ? item.crudeCode + ' ' + item.percentage + '%'
                    : item.crudeCode + ' ' + item.percentage + '%, '
                })}
                <span> has never been processed before</span>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default CrudeHeader
