import React, { useState, useEffect, useCallback, useMemo } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { extendMoment } from 'moment-range'
import { useJsonToCsv } from 'react-json-csv'

//Asset
import IconCloseWhite from '../../../assets/images/icon-close-white.svg'

//Components
import { Grid, Header, Divider, Dropdown, Segment, Button, Icon, Image } from 'semantic-ui-react'
import { FreeformChart } from '../../../components/charts/statistical-analysis-chart/freefrom-chart'
import PeriodPicker from '../../../components/period-picker'
import NoDataBlock from '../../../components/no-data-block'

//Redux
import { PERSONAL_SETTING_REDUCER } from '../../../reducers/common/personalSettingReducer'
import { MASTER_DATA_REDUCER } from '../../../reducers/common/masterDataReducer'
import { LANDING_PAGE_REDUCER } from '../../../reducers/landingPageReducer'
import * as MasterDataActions from '../../../actions/common/actionMasterData'
import * as LandingPageActions from '../../../actions/actionLanding'

//Hooks
import { useStateWithPaths, useActions } from '../../../hooks/useConnect'


const FreeformTrend = (props) => {
  const [{ freeformChart }] = useStateWithPaths([
    `${LANDING_PAGE_REDUCER}`,
  ])
  const [{ currentPersona }] = useStateWithPaths([`${PERSONAL_SETTING_REDUCER}`])

  const [
    {
      freeformDataSource,
      freeformUnit,
      freeformStream1,
      freeformStream2,
      freeformStream3,
      freeformStream4,
      freeformStream5,
      freeformProperty1,
      freeformProperty2,
      freeformProperty3,
      freeformProperty4,
      freeformProperty5,
    },
  ] = useStateWithPaths([`${MASTER_DATA_REDUCER}`])

  const {
    getMasterFreeformDataSource,
    getMasterFreeformUnit,
    getMasterFreeformStream1,
    getMasterFreeformStream2,
    getMasterFreeformStream3,
    getMasterFreeformStream4,
    getMasterFreeformStream5,
    getMasterFreeformProperty1,
    getMasterFreeformProperty2,
    getMasterFreeformProperty3,
    getMasterFreeformProperty4,
    getMasterFreeformProperty5,
  } = useActions({
    getMasterFreeformDataSource: MasterDataActions.getMasterFreeformDataSource,
    getMasterFreeformUnit: MasterDataActions.getMasterFreeformUnit,
    getMasterFreeformStream1: MasterDataActions.getMasterFreeformStream1,
    getMasterFreeformStream2: MasterDataActions.getMasterFreeformStream2,
    getMasterFreeformStream3: MasterDataActions.getMasterFreeformStream3,
    getMasterFreeformStream4: MasterDataActions.getMasterFreeformStream4,
    getMasterFreeformStream5: MasterDataActions.getMasterFreeformStream5,
    getMasterFreeformProperty1: MasterDataActions.getMasterFreeformProperty1,
    getMasterFreeformProperty2: MasterDataActions.getMasterFreeformProperty2,
    getMasterFreeformProperty3: MasterDataActions.getMasterFreeformProperty3,
    getMasterFreeformProperty4: MasterDataActions.getMasterFreeformProperty4,
    getMasterFreeformProperty5: MasterDataActions.getMasterFreeformProperty5,
  })

  const { setFreeformTrend } = useActions({
    setFreeformTrend: LandingPageActions.setFreeformTrend,
  })

  const [freeformChartData] = useStateWithPaths([
    'landingPage.freeformChart.data',
  ])

  const color1 = '#983E66'
  const color2 = '#FFA000'
  const color3 = '#259396'
  const color4 = '#40C3F2'
  const color5 = '#3750B3'

  const [dataSource1, setDataSource1] = useState(freeformChart.freeformDataSource1)
  const [dataSource2, setDataSource2] = useState(freeformChart.freeformDataSource2)
  const [dataSource3, setDataSource3] = useState(freeformChart.freeformDataSource3)
  const [dataSource4, setDataSource4] = useState(freeformChart.freeformDataSource4)
  const [dataSource5, setDataSource5] = useState(freeformChart.freeformDataSource5)

  const [unit1, setUnit1] = useState(freeformChart.freeformUnit1)
  const [unit2, setUnit2] = useState(freeformChart.freeformUnit2)
  const [unit3, setUnit3] = useState(freeformChart.freeformUnit3)
  const [unit4, setUnit4] = useState(freeformChart.freeformUnit4)
  const [unit5, setUnit5] = useState(freeformChart.freeformUnit5)

  const [stream1, setStream1] = useState(freeformChart.freeformStream1)
  const [stream2, setStream2] = useState(freeformChart.freeformStream2)
  const [stream3, setStream3] = useState(freeformChart.freeformStream3)
  const [stream4, setStream4] = useState(freeformChart.freeformStream4)
  const [stream5, setStream5] = useState(freeformChart.freeformStream5)

  const [property1, setProperty1] = useState(freeformChart.freeformProperty1)
  const [property2, setProperty2] = useState(freeformChart.freeformProperty2)
  const [property3, setProperty3] = useState(freeformChart.freeformProperty3)
  const [property4, setProperty4] = useState(freeformChart.freeformProperty4)
  const [property5, setProperty5] = useState(freeformChart.freeformProperty5)

  const [defaultPeriod] = useState([freeformChart.defaultPeriod])
  const [period, setPeriod] = useState([freeformChart.period])

  useEffect(() => {
    setFreeformTrend(
      currentPersona,
      period,
      color1,
      color2,
      color3,
      color4,
      color5,
      dataSource1,
      dataSource2,
      dataSource3,
      dataSource4,
      dataSource5,
      unit1,
      unit2,
      unit3,
      unit4,
      unit5,
      stream1,
      stream2,
      stream3,
      stream4,
      stream5,
      property1,
      property2,
      property3,
      property4,
      property5
    )
  }, [
    setFreeformTrend,
    currentPersona,
    period,
    color1,
    color2,
    color3,
    color4,
    color5,
    dataSource1,
    dataSource2,
    dataSource3,
    dataSource4,
    dataSource5,
    unit1,
    unit2,
    unit3,
    unit4,
    unit5,
    stream1,
    stream2,
    stream3,
    stream4,
    stream5,
    property1,
    property2,
    property3,
    property4,
    property5,
  ])

  // Freeform Data & Unit

  useEffect(() => {
    getMasterFreeformDataSource(currentPersona)
  }, [getMasterFreeformDataSource, currentPersona])

  useEffect(() => {
    getMasterFreeformUnit(currentPersona)
  }, [getMasterFreeformUnit, currentPersona])

  // Freeform Stream

  useEffect(() => {
    getMasterFreeformStream1(currentPersona, unit1, dataSource1)
  }, [getMasterFreeformStream1, currentPersona, unit1, dataSource1])

  useEffect(() => {
    getMasterFreeformStream2(currentPersona, unit2, dataSource2)
  }, [getMasterFreeformStream2, currentPersona, unit2, dataSource2])

  useEffect(() => {
    getMasterFreeformStream3(currentPersona, unit3, dataSource3)
  }, [getMasterFreeformStream3, currentPersona, unit3, dataSource3])

  useEffect(() => {
    getMasterFreeformStream4(currentPersona, unit4, dataSource4)
  }, [getMasterFreeformStream4, currentPersona, unit4, dataSource4])

  useEffect(() => {
    getMasterFreeformStream5(currentPersona, unit5, dataSource5)
  }, [getMasterFreeformStream5, currentPersona, unit5, dataSource5])

  // Freeform Property

  useEffect(() => {
    getMasterFreeformProperty1(currentPersona, unit1, stream1, dataSource1)
  }, [getMasterFreeformProperty1, currentPersona, unit1, stream1, dataSource1])

  useEffect(() => {
    getMasterFreeformProperty2(currentPersona, unit2, stream2, dataSource2)
  }, [getMasterFreeformProperty2, currentPersona, unit2, stream2, dataSource2])

  useEffect(() => {
    getMasterFreeformProperty3(currentPersona, unit3, stream3, dataSource3)
  }, [getMasterFreeformProperty3, currentPersona, unit3, stream3, dataSource3])

  useEffect(() => {
    getMasterFreeformProperty4(currentPersona, unit4, stream4, dataSource4)
  }, [getMasterFreeformProperty4, currentPersona, unit4, stream4, dataSource4])

  useEffect(() => {
    getMasterFreeformProperty5(currentPersona, unit5, stream5, dataSource5)
  }, [getMasterFreeformProperty5, currentPersona, unit5, stream5, dataSource5])

  // const searchCompareBlendResult = _.get(searchResult, 'freeformChart')

  // if (
  //   !_.get(searchCompareBlendResult, "data") ||
  //   _.isEmpty(_.get(searchCompareBlendResult, "data"))
  // ) {
  //   return <NoDataBlock />;
  // }

  // const data = _.get(searchResult.freeformChart, 'data')


  // Export CSV

  const { saveAsCsv } = useJsonToCsv()
  const tableData = _.get(freeformChartData, 'series')

  const tableDataForExport = useMemo(() => {
    return _.map(tableData, (item, index) => {
      return _.mapValues(item, (value, key) => {
        return value
      })
    })
  }, [tableData])

  const transformTableData = [
    {
      dataSource: dataSource1,
      unit: unit1,
      fraction: stream1,
    },
    {
      dataSource: dataSource2,
      unit: unit2,
      fraction: stream2,
    },
    {
      dataSource: dataSource3,
      unit: unit3,
      fraction: stream3,
    },
    {
      dataSource: dataSource4,
      unit: unit4,
      fraction: stream4,
    },
    {
      dataSource: dataSource5,
      unit: unit5,
      fraction: stream5,
    },
  ]

  const formatSearchObject = _.map(transformTableData, (item) =>
    !_.isEmpty(item.dataSource)
      ? { dataSource: item.dataSource, unit: item.unit, fraction: item.fraction }
      : {}
  )
  const filterSearchObject = _.filter(formatSearchObject, (v) => _.keys(v).length !== 0)

  const chartExport = _.merge(tableData, filterSearchObject)

  const startDate = _.map(period, (item) => item.startDate)
  const endDate = _.map(period, (item) => item.endDate)

  const formatStart = moment(startDate.toString()).format('YYYY-MM-DD')
  const formatEnd = moment(endDate.toString()).format('YYYY-MM-DD')
  const range = moment.range(formatStart, formatEnd)

  for (let days of range.by('month')) {
    days.format('YYYY-MM-DD')
  }

  const days = Array.from(range.by('days'))
  const daysInMonth = days.map((m) => m.format('DD MMM'))

  const fileNameStartDate = moment(startDate.toString()).format('DD-MMM-YYYY')
  const fileNameEndDate = moment(endDate.toString()).format('DD-MMM-YYYY')

  const transfromFileName = `Freeform trend ${fileNameStartDate} - ${fileNameEndDate}`

  const clickExport = useCallback(
    (e) => {
      const filename = transfromFileName,
        fields = {
          dataSource: 'Data Source',
          unit: 'Unit',
          fraction: 'Fraction',
          name: 'Property',
          data: daysInMonth,
        },
        style = {
          padding: '5px',
        },
        data = chartExport
      saveAsCsv({ data, fields, filename, style })
    },
    [chartExport, saveAsCsv]
  )

  const onClickExport = () => {
    clickExport()
  }

  const onClickResetDataSourceParameter = (index) => {
    if (index === 1) {
      setDataSource1('')
      setUnit1('')
      setStream1('')
      setProperty1('')
    } else if (index === 2) {
      setDataSource2('')
      setUnit2('')
      setStream2('')
      setProperty2('')
    } else if (index === 3) {
      setDataSource3('')
      setUnit3('')
      setStream3('')
      setProperty3('')
    } else if (index === 4) {
      setDataSource4('')
      setUnit4('')
      setStream4('')
      setProperty4('')
    } else if (index === 5) {
      setDataSource5('')
      setUnit5('')
      setStream5('')
      setProperty5('')
    }
  }

  return (
    <Grid padded>
      <Grid.Row columns={1} verticalAlign="bottom">
        <Grid.Column textAlign="left">
          <div className={`flex center space-between`}>
            <div className={`flex`}>
              <Header className={`mar-0`} size="small" textAlign="left">
                FREEFORM TREND
              </Header>
            </div>
            <PeriodPicker
              defaultPeriod={defaultPeriod}
              period={period}
              setPeriod={setPeriod}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
      <Segment className={`width-100 mar-bottom-2`}>
        <div className={`pad-2`}>
          <Grid.Row>
            <div className={`pad-y-2`}>
              {_.isEmpty(freeformChartData) ? (
                <NoDataBlock />
              ) : (
                <FreeformChart freeformChartData={freeformChartData} />
              )}
            </div>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header
                className={`mar-bottom-0 mar-left-0 text-primary`}
                size="small"
                textAlign="left"
              >
                Data Source & Parameter
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div className={`pad-top-1 flex center space-between`}>
                <div
                  className={`custom-legend-icon`}
                  style={{ backgroundColor: `${color1}` }}
                ></div>
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Data Source"
                  value={dataSource1}
                  selection
                  options={freeformDataSource}
                  onChange={(e, data) => {
                    setDataSource1(data.value)
                    setStream1('')
                    setProperty1('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Unit"
                  value={unit1}
                  selection
                  options={freeformUnit}
                  onChange={(e, data) => {
                    setUnit1(data.value)
                    setStream1('')
                    setProperty1('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Stream"
                  value={stream1}
                  selection
                  options={freeformStream1}
                  onChange={(e, data) => {
                    setStream1(data.value)
                    setProperty1('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Property"
                  value={property1}
                  selection
                  options={freeformProperty1}
                  onChange={(e, data) => {
                    setProperty1(data.value)
                  }}
                />
                <Button
                  onClick={() => onClickResetDataSourceParameter(1)}
                  className={`button-reset-data-source`}
                >
                  <Image src={IconCloseWhite} />
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div className={`pad-top-1 flex center space-between`}>
                <div
                  className={`custom-legend-icon`}
                  style={{ backgroundColor: `${color2}` }}
                ></div>
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Data Source"
                  value={dataSource2}
                  selection
                  options={freeformDataSource}
                  onChange={(e, data) => {
                    setDataSource2(data.value)
                    setStream2('')
                    setProperty2('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Unit"
                  value={unit2}
                  selection
                  options={freeformUnit}
                  onChange={(e, data) => {
                    setUnit2(data.value)
                    setStream2('')
                    setProperty2('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Stream"
                  value={stream2}
                  selection
                  options={freeformStream2}
                  onChange={(e, data) => {
                    setStream2(data.value)
                    setProperty2('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Property"
                  value={property2}
                  selection
                  options={freeformProperty2}
                  onChange={(e, data) => {
                    setProperty2(data.value)
                  }}
                />
                <Button
                  onClick={() => onClickResetDataSourceParameter(2)}
                  className={`button-reset-data-source`}
                >
                  <Image src={IconCloseWhite} />
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div className={`pad-top-1 flex center space-between`}>
                <div
                  className={`custom-legend-icon`}
                  style={{ backgroundColor: `${color3}` }}
                ></div>
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Data Source"
                  value={dataSource3}
                  selection
                  options={freeformDataSource}
                  onChange={(e, data) => {
                    setDataSource3(data.value)
                    setStream3('')
                    setProperty3('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Unit"
                  value={unit3}
                  selection
                  options={freeformUnit}
                  onChange={(e, data) => {
                    setUnit3(data.value)
                    setStream3('')
                    setProperty3('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Stream"
                  value={stream3}
                  selection
                  options={freeformStream3}
                  onChange={(e, data) => {
                    setStream3(data.value)
                    setProperty3('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Property"
                  value={property3}
                  selection
                  options={freeformProperty3}
                  onChange={(e, data) => {
                    setProperty3(data.value)
                  }}
                />
                <Button
                  onClick={() => onClickResetDataSourceParameter(3)}
                  className={`button-reset-data-source`}
                >
                  <Image src={IconCloseWhite} />
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div className={`pad-top-1 flex center space-between`}>
                <div
                  className={`custom-legend-icon`}
                  style={{ backgroundColor: `${color4}` }}
                ></div>
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Data Source"
                  value={dataSource4}
                  selection
                  options={freeformDataSource}
                  onChange={(e, data) => {
                    setDataSource4(data.value)
                    setStream4('')
                    setProperty4('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Unit"
                  value={unit4}
                  selection
                  options={freeformUnit}
                  onChange={(e, data) => {
                    setUnit4(data.value)
                    setStream4('')
                    setProperty4('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Stream"
                  value={stream4}
                  selection
                  options={freeformStream4}
                  onChange={(e, data) => {
                    setStream4(data.value)
                    setProperty4('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Property"
                  value={property4}
                  selection
                  options={freeformProperty4}
                  onChange={(e, data) => {
                    setProperty4(data.value)
                  }}
                />
                <Button
                  onClick={() => onClickResetDataSourceParameter(4)}
                  className={`button-reset-data-source`}
                >
                  <Image src={IconCloseWhite} />
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div className={`pad-top-1 flex center space-between`}>
                <div
                  className={`custom-legend-icon`}
                  style={{ backgroundColor: `${color5}` }}
                ></div>
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Data Source"
                  value={dataSource5}
                  selection
                  options={freeformDataSource}
                  onChange={(e, data) => {
                    setDataSource5(data.value)
                    setStream5('')
                    setProperty5('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Unit"
                  value={unit5}
                  selection
                  options={freeformUnit}
                  onChange={(e, data) => {
                    setUnit5(data.value)
                    setStream5('')
                    setProperty5('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Stream"
                  value={stream5}
                  selection
                  options={freeformStream5}
                  onChange={(e, data) => {
                    setStream5(data.value)
                    setProperty5('')
                  }}
                />
                <Dropdown
                  className={`freeform-dropdown`}
                  placeholder="Select Property"
                  value={property5}
                  selection
                  options={freeformProperty5}
                  onChange={(e, data) => {
                    setProperty5(data.value)
                  }}
                />
                <Button
                  onClick={() => onClickResetDataSourceParameter(5)}
                  className={`button-reset-data-source`}
                >
                  <Image src={IconCloseWhite} />
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <div className={`flex mar-top-2 end`}>
              <Button size="large" className="btn-secondary icon" onClick={onClickExport}>
                Export <Icon name="external alternate"></Icon>
              </Button>
            </div>
          </Grid.Row>
        </div>
      </Segment>
    </Grid>
  )
}

export default FreeformTrend
