import React, { Component } from 'react';
import { Divider, Grid, Icon, Dimmer, Header, Segment, Container } from 'semantic-ui-react';

export default class CustomPopUp extends Component {
 
    render() {
        let curr = this.props.curr;
        return (
            <Segment className='popupDetails' style={{ position: 'absolute', left: '253px', top: '20%', right: '253px'}}>
                <Header as='h3' style={{ textAlign: 'left', paddingTop: '16px', paddingLeft: '14px' }} >Mitigation</Header>
                <Divider></Divider>
                <Container>
                    <Grid columns={3} textAlign='left' padded>
                        <Grid.Column width={4}>
                            <Grid.Row>
                                <p className='gray'>Parameter</p>
                                <p>{curr.name}</p>
                                <br></br>
                            </Grid.Row>
                            <Grid.Row>
                                <p className='gray'>Value</p>
                                <p>{curr.val}</p>
                                <br></br>
                            </Grid.Row>
                            <Grid.Row>
                                <p className='gray'>Risk Rating</p>
                                <p>Medium</p>
                                <br></br>
                            </Grid.Row>
                            <Grid.Row>
                                <p className='gray'>Source</p>
                                <p>Outlook</p>
                                <br></br>
                            </Grid.Row>
                        </Grid.Column>
                        <Grid.Column width={11}>
                            <Grid.Row>
                                <p className='gray'>Risk Description</p>
                                <ul>
                                    {
                                        curr.desc.map((item, count) => {
                                            return <li key={count}>{item.val}</li>;
                                        })}
                                </ul>
                                <br></br>
                            </Grid.Row>
                            <Grid.Row>
                                <p className='gray'>Mitigation</p>
                                <ul>
                                    {
                                        curr.miti.map((item, count) => {
                                            return <li key={count}>{item}</li>;
                                        })}
                                </ul>
                            </Grid.Row>
                        </Grid.Column>
                        <Grid.Column width={1} stretched>
                            <Grid.Row>{curr.desc.length > 0 && <Icon name='copy outline' size='large'></Icon>}</Grid.Row>
                            <Grid.Row>{curr.miti.length > 0 && <Icon name='copy outline' size='large'></Icon>}</Grid.Row>
                        </Grid.Column>
                    </Grid>
                </Container>
            </Segment>

        )
    } 
}