import React from "react";
import { Button, Image } from "semantic-ui-react";
import { useLocation, useHistory } from "react-router-dom";

// Assets
import Logo from "../../assets/images/thai-oil-logo.svg";

const LogoutPage = () => {
  const history = useHistory();
  return (
    <div>
      <div className={`logout-page flex width-100`}>
        <div className={`left-container flex center justify-center`}>
          <Image size="medium" src={Logo} />
        </div>
        <div className={`right-container flex center justify-center pad-4`}>
          <div className={`flex column center start`}>
            <div className={`text-white logout-text bold`}>You have been successfully logged out</div>
            <div className={`text-white logout-text`}>Please close your browser to complete the log out process</div>
            <Button className={`login-button`} onClick={() => history.push("/login")}>Login</Button>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default LogoutPage;
