import { combineReducers } from 'redux'

//Common
import masterDataReducer from './common/masterDataReducer'
import notificationReducer from './common/notificationReducer'
import sessionReducer from './common/sessionReducer'
import systemReducer from './common/systemReducer'
import personalSettingReducer from './common/personalSettingReducer'
import fetchReducer from './common/fetchReducer'

//Page Reducer
import compatibilityPageReducer from './compatibilityPageReducer'
import corrosionPageReducer from './corrosionPageReducer'
import landingPageReducer from './landingPageReducer'
import processingPageReducer from './processingPageReducer'
import propertyPageReducer from './propertyPageReducer'
import risksPageReducer from './risksPageReducer'
import statisticalAnalaysisPageReducer from './statisticalAnalaysisPageReducer'

const rootReducer = combineReducers({
    commonMasterData: masterDataReducer,
    commonNotification: notificationReducer,
    commonSession: sessionReducer,
    commonSystem: systemReducer,
    commonPersonalSetting: personalSettingReducer,
    commonFetch: fetchReducer,

    compatibilityPage: compatibilityPageReducer,
    corrosionPage: corrosionPageReducer,
    landingPage: landingPageReducer,
    processingPage: processingPageReducer,
    propertyPage: propertyPageReducer,
    risksPage: risksPageReducer,
    statisticalAnalaysisPage: statisticalAnalaysisPageReducer,
})

export default rootReducer
