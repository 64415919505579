export const getEstimatedCorrosionRateLevelClassName = (value) => {
  if (!value) return ''

  value = value.toLowerCase()
  switch (value) {
    case 'negligible':
      return 'level-negligible'
    case 'low':
      return 'level-low'
    case 'medium':
      return 'level-medium'
    case 'high':
      return 'level-high'
    default:
      return 'no-result'
  }
}
