import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import _ from 'lodash';

//Redux
import * as sessionActions from '../../actions/common/actionSession';
import { SESSION_REDUCER } from '../../reducers/common/sessionReducer';
import { PERSONAL_SETTING_REDUCER } from '../../reducers/common/personalSettingReducer'

//Hooks
import { useActions, useStateWithPaths } from '../../hooks/useConnect';
import { sessionLogout, SESSION_STATUS } from '../../actions/common/actionSession'

import CustomLoader from '../../components/custom-loader';
import { getUrlKeyValue} from '../../services/helper';

const RouteWithAuth = ({ component: Component, accessGroups, ...rest }) => {

    const [{ currentPersona }] = useStateWithPaths([`${PERSONAL_SETTING_REDUCER}`])

    const history = useHistory()
    const [{ status, idToken }] = useStateWithPaths([`${SESSION_REDUCER}`])
    const { sessionAuthentication } = useActions({
        sessionAuthentication: sessionActions.sessionAuthentication,
    });

    // const logout = getUrlKeyValue('logout');
    // if(logout)
    //     return null;
    
    if(status === SESSION_STATUS.START)
        return <Route ><CustomLoader isActive={true} isPage={true} /></Route>

    if (status !== SESSION_STATUS.SUCCESS) {
        sessionAuthentication()
        return null;
    }

    console.log('Authentication status: ' + status);

    const idTokenDetails = jwt.decode(idToken);
    let tokenGroups = _.get(idTokenDetails, 'name') || ''
    tokenGroups = tokenGroups.replace("[", "").replace("]","").split(", ");

    console.log()

    const intersectedGroupAccess = _.intersection(accessGroups, [currentPersona])
    let hasGroupAccess = _.isEqual(intersectedGroupAccess, [currentPersona]) || _.isEqual(accessGroups, [])
    
    // if(accessGroups && accessGroups.length > 0){
    //     const intersectedGroupAccess = _.intersection(accessGroups, tokenGroups)
    //     hasGroupAccess = intersectedGroupAccess && intersectedGroupAccess.length > 0;
    // }

    if(!hasGroupAccess){
        history.push('/unauthorised-access')
    }

    return (
        <Route {...rest} render={props =>
            (
                <Component {...props} />
            )
        }
        />
    );
};
export default RouteWithAuth;