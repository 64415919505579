import _ from "lodash";
import React from "react";

import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

import PropTypes from "prop-types";

import ReactDOMServer from "react-dom/server";
import Tooltip from "../../tooltip";

// Helpers
import { stringToDecimalPoint } from "../../../helpers/variables";

export const FreeformChart = (props) => {
  const CHART_OPTIONS = setChartOptions(props.freeformChartData);
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        containerProps={{ style: { width: "100%", height: "100%" } }}
        constructorType={"chart"}
        options={CHART_OPTIONS}
        immutable={true}
      />
    </div>
  );
};

FreeformChart.propTypes = {
  freeformChartData: PropTypes.object,
};

function setChartOptions(data) {
  const freeformChartCategories = _.get(data, "category");
  // const freeformChartSeries = _.get(data, "series");
  // const formatTrendCategories = _.map(freeformChartCategories, (item) =>
  //   moment(item).format("DD MMM")
  // );

  const transformFreeformChartSeries = _.map(
    _.get(data, "series"),
    (item, index) => ({
      color: item.color,
      data: item.data,
      dataSource: item.dataSource,
      fraction: item.fraction,
      name: item.name,
      unit: item.unit,
      yAxis: index,
    })
  );

  const transformYAxis = _.map(_.get(data, "series"), (item, index) => ({
    labels: {
      formatter: (point) => {
        const getPoint = point.value
          return Number(getPoint).toFixed(2);
      },
      style: {
        color: item.color,
        fontWeight: 700,
        fontSize: '14px',
      },
    },
    title: {
      text: item.name,
      style: {
        color: item.color,
        fontWeight: 700,
        fontSize: '14px',
      },
    },
    opposite: _.isEqual(index, 0) || _.isEqual(index, 2) || _.isEqual(index, 4) ? true : false,
  }));

  return {
    chart: {
      type: "spline",
      height: 340,
      zoomType: "xy",
    },
    credits: {
      enabled: false,
    },

    title: {
      text: "",
    },

    yAxis: transformYAxis,

    xAxis: {
      categories: freeformChartCategories,
    },
    colors: [
      "#FFD645",
      "#FFA000",
      "#00AFED",
      "#0081C9",
      "#3BC2C7",
      "#259396",
      "#7A8CD1",
    ],
    tooltip: getTooltip(data, tooltipFormatter),
    legend: {
      enabled: false,
    },

    plotOptions: {
      series: {
        pointStart: 0,
        marker: {
          enabled: true,
          symbol: "circle",
        },
      },
    },

    series: transformFreeformChartSeries,
  };
}

const getTooltip = (payload, formatter, period) => {
  return {
    shared: false,
    backgroundColor: null,
    borderWidth: 0,
    shadow: false,
    className: "test-classname-tooltip",
    style: {
      padding: 0,
    },
    useHTML: true,
    formatter: function () {
      return formatter(this, payload, period);
    },
    outside: true,
  };
};

function getTooltipContent(category, data) {
  let content = [
    {
      name: _.map(data.series, (item) => item.name),
      color: _.map(data.series, (item) => item.color),
      data: _.map(data.series, (item) => item.data),
    },
  ];
  return content;
}

// tooltip in normal view
const tooltipFormatter = (chart, data) => {
  const x = chart;
  const i = x.series.index;
  const points = getTooltipContent(x, data);

  const options = {
    title: _.map(points, (a) => a.name[i]),
    headerColor: _.map(points, (a) => a.color[i]),
    items: [
      _.map(points, (a) => ({
        type: "card",
        label: x.x,
        value: stringToDecimalPoint(x.y, 2, 2),
      })),
    ],
  };

  return ReactDOMServer.renderToString(
    <Tooltip
      large
      type="TooltipTwoColumns"
      {...options}
      className={"parameter-trend-tooltip"}
    />
  );
};
