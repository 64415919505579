import React from 'react'
import _ from 'lodash'

//Components
import { Icon } from 'semantic-ui-react'
import SearchPanel from './components/searchPanel'
import CalculationResult from './components/calculationResult'
import ApiStandard from './components/apiStandard'
import SummaryTable from './components/summaryTable'
import ResultContainer from '../../../components/result-container'
import HistoricalData from './components/historicalData'

//Redux
import { CORROSION_PAGE_REDUCER } from '../../../reducers/corrosionPageReducer'

//Hooks
import { useStateWithPaths } from '../../../hooks/useConnect'

//#region Private Methods
const getSearchIcon = () => {
  return (
    <div className="search-icon-wrapper flex center justify-center">
      <Icon name="search margin-none"></Icon>
    </div>
  )
}
////#endregion

const CorrosionRatePage = (props) => {
  const [{ searchResult }] = useStateWithPaths([`${CORROSION_PAGE_REDUCER}`])
  const hasSearch = !_.isEmpty(searchResult)
  return (
    <div className="main-container page-corrosion-rate">
      <SearchPanel />
      {!hasSearch && (
        <div className="session section-result-message">
          <ResultContainer
            label="Please input blend and parameters above to enable the calculation"
            icon={getSearchIcon()}
          />
        </div>
      )}
      {hasSearch && (
        <>
          <CalculationResult />
          <ApiStandard />
          <SummaryTable />
          <HistoricalData />
        </>
      )}
    </div>
  )
}

export default CorrosionRatePage
