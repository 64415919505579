import React from 'react'
import _ from 'lodash'
import { Segment, Table } from 'semantic-ui-react'
import NoDataBlock from '../../../../components/no-data-block'

const CompatibilityCalculation = (props) => {
  const data = _.get(props.data, 'data')[0]

  return (
    <div className={`compatibility-calculation mar-x-2`}>
      <Segment>
        <div className={`pad-2`}>
          {!_.isEmpty(data) ? (
            <>
              <Table celled structured textAlign="left" className="table-property border-dot">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    {Object.keys(data.sBN).map(function (currentKey) {
                      return <Table.HeaderCell>{currentKey}</Table.HeaderCell>
                    })}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <div>
                        <div>S</div>
                        <div style={{ marginLeft: '10px', fontSize: '14px' }}>BN</div>
                      </div>
                    </Table.Cell>
                    {Object.keys(data.sBN).map(function (currentKey) {
                      return (
                        <Table.Cell>
                          {!_.isEmpty(data.sBN[currentKey]) > 0 ? data.sBN[currentKey] : 'N.A.'}
                        </Table.Cell>
                      )
                    })}
                  </Table.Row>
                  <Table.Row className={`last-row`}>
                    <Table.Cell>
                      <div>
                        <div>I</div>
                        <div style={{ marginLeft: '10px', fontSize: '14px' }}>N</div>
                      </div>
                    </Table.Cell>
                    {Object.keys(data.iN).map(function (currentKey) {
                      return (
                        <Table.Cell>
                          {!_.isEmpty(data.iN[currentKey]) > 0 ? data.iN[currentKey] : 'N.A.'}
                        </Table.Cell>
                      )
                    })}
                  </Table.Row>
                </Table.Body>
              </Table>
              <div className={`flex end pad-2`}>
                <div>
                  <div className={`flex`}>
                    <div className="input-description">
                      <div>S</div>
                      <div style={{ marginLeft: '10px', fontSize: '14px' }}>BN - Blend</div>
                    </div>
                    <input readOnly value="=" className="input-equal-symbol" />
                    <input readOnly value={data.result.sBNBlend} className="input-result" />
                  </div>
                  <div className={`flex`}>
                    <div className="input-description">
                      <div>I</div>
                      <div style={{ marginLeft: '10px', fontSize: '14px' }}>N - Max</div>
                    </div>
                    <input readOnly value="=" className="input-equal-symbol" />
                    <input readOnly value={data.result.INMax} className="input-result" />
                  </div>
                  <div className={`flex`}>
                    <div className="input-description">Compatibility - R</div>
                    <input readOnly value="=" className="input-equal-symbol" />
                    <input readOnly value={data.result.R} className="input-result" />
                    <input readOnly value="=" className="input-equal-symbol" />
                    <span
                      className={`ui label span-result`}
                      style={{ backgroundColor: data.result.color }}
                    >
                      {data.result.status}
                    </span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <NoDataBlock />
          )}
        </div>
      </Segment>
    </div>
  )
}

export default CompatibilityCalculation
