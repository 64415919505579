import _ from 'lodash'
import { useState, useCallback, useEffect } from "react"

const useDelayedState = (defaultState, delay = 0) => {
	const [state, setState] = useState(defaultState)
	const [timer, setTimer] = useState()
	
	const setDelayedState = useCallback(
		(value) => {
			const newTimer = setTimeout(() => setState(value), delay)
			setTimer(newTimer)
		},
		[delay],
	)

	useEffect(() => {
		return () => {
			clearTimeout(timer)
			setTimer(null)
		};
	}, [timer])

	return [state, setDelayedState]
}

const useDelayedValue = (value, delay = 0, exceptions = []) => {
	const [state, setState] = useState(value)
	useEffect(() => {
		if(_.includes(exceptions, value)) {
			setState(value)
		}
    const timer = setTimeout(() => setState(value), delay);
    return () => clearTimeout(timer);
	}, [value, delay])
	return state
}

export {
	useDelayedState,
	useDelayedValue
}