import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect, useDispatch } from 'react-redux'
import { isFetching, isSuccess, isError } from '../../reducers/common/fetchReducer'
// import NoDataBlock from '../no-data-block'
import CustomLoader from '../custom-loader/customLoader'
import { getDisplayName } from '../../helpers/component'
import { useDelayedValue } from '../../hooks/useDelayedState'
import { fetchReset } from '../../actions/common/actionFetch'

const FetchStateWrapper = ({
  fetching,
  success,
  error,
  reFetchData,
  children,
  fetchingLabel,
  delay,
  id,
}) => {
  const delayedFetching = useDelayedValue(fetching, delay, [true])
  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch(fetchReset(id))
    }
  }, [])

  const hasChildren = children != null

  return hasChildren ? (
    <div className="fetch-state-container width-100" style={{ position: 'relative' }}>
      {delayedFetching && (
        <CustomLoader isPage={false} isActive={true} loaderLabel={fetchingLabel} />
      )}
      {error
        ? '' // // <NoDataBlock reFetchData={reFetchData}/> :
        : children}
    </div>
  ) : (
    <div className={`fetch-state-container ${classNames({ fetching, success, error })}`}>
      {delayedFetching && (
        <CustomLoader isPage={true} isActive={true} loaderLabel={fetchingLabel} />
      )}
      {error
        ? '' // // <NoDataBlock reFetchData={reFetchData}/> :
        : children}
    </div>
  )
}

FetchStateWrapper.propTypes = {
  fetching: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
  id: PropTypes.string.isRequired,
  reFetchData: PropTypes.func,
  children: PropTypes.node,
  noContainer: PropTypes.bool,
  delay: PropTypes.number,
}

const mapStateToProps = ({ commonFetch }, { id }) => {
  return {
    fetching: isFetching({ state: commonFetch, id: id }),
    success: isSuccess({ state: commonFetch, id: id }),
    error: isError({ state: commonFetch, id: id }),
  }
}

const ConnectedFetchStateWrapper = connect(mapStateToProps)(FetchStateWrapper)

export default ConnectedFetchStateWrapper

const withFetchStateWrapper = (fetchStateId = '', fetchDataActionCeator) => (Component) => {
  const WithFetchStateWrapper = ({ dispatch, ...rest }) => (
    <ConnectedFetchStateWrapper
      id={fetchStateId}
      noContainer
      reFetchData={fetchDataActionCeator ? () => dispatch(fetchDataActionCeator()) : null}
    >
      <Component {...rest} />
    </ConnectedFetchStateWrapper>
  )

  WithFetchStateWrapper.propTypes = {
    dispatch: PropTypes.func,
  }

  WithFetchStateWrapper.displayName = `withFetchStateWrapper(${getDisplayName(Component)})`
  return connect(null, (dispatch) => ({ dispatch }))(WithFetchStateWrapper)
}

export { withFetchStateWrapper }
