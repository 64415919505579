import React from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import moment from 'moment'

import ReactHighchart from '../../react-highcharts'

import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import PropTypes from 'prop-types'

import ReactDOMServer from 'react-dom/server'
import Tooltip from '../../tooltip'

// Helpers
import { stringToDecimalPoint } from '../../../helpers/variables'

import { setCrudeBlendSelectedChartData } from '../../../actions/actionLanding'

export const RisksCrudeBlendChart = (props) => {
  const dispatch = useDispatch()
  const CHART_OPTIONS = setChartOptions(props.crudeBlendData, dispatch)
  return (
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={{ style: { width: "100%", height: "100%" } }}
      constructorType={"chart"}
      options={CHART_OPTIONS}
      immutable={true}
    />
    // <div style={{ width: '100%', overflowX: 'auto' }}>
    //   <ReactHighchart options={CHART_OPTIONS} />
    // </div>
  )
}

RisksCrudeBlendChart.propTypes = {
  risksCrudeBlendChartData: PropTypes.object,
}

function setChartOptions(data, dispatch) {
  const risksCrudeBlendCategoriesData = _.get(data, 'category')
  const risksCrudeBlendChartSeriesData = _.get(data, 'series')

  const formatCrudeCategories = _.map(risksCrudeBlendCategoriesData, (item) =>
    moment(item).format('DD MMM')
  )

  return {
    chart: {
      type: 'column',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: risksCrudeBlendCategoriesData,
      labels: {
        autoRotation: [0],
      },
      lineColor: '#FFF',
    },
    yAxis: {
      visible: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
      shared: true,
    },
    colors: [
      '#FFD645',
      '#FFA000',
      '#00AFED',
      '#0081C9',
      '#3BC2C7',
      '#259396',
      '#7A8CD1',
      '#3750B3',
    ],
    tooltip: getTooltip(data, tooltipFormatter),
    plotOptions: {
      column: {
        stacking: 'percent',
        allowPointSelect: false,
        dataLabels: {
          enabled: false,
        },
        opacity: 1,
        states: {
          select: {
            enabled: true,
          },
          hover: {
            enabled: true,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
      series: {
        borderWidth: 0,
        // pointWidth: 30,
        inactive: {
          opacity: 1,
        },
      },
    },
    series: risksCrudeBlendChartSeriesData,
  }
}

const getTooltip = (payload, formatter) => {
  return {
    shared: true,
    backgroundColor: null,
    borderWidth: 0,
    shadow: false,
    className: 'test-classname-tooltip',
    style: {
      padding: 0,
    },
    useHTML: true,
    formatter: function () {
      return formatter(this, payload)
    },
    outside: true,
  }
}

function getTooltipContent(category, data) {
  let content = _.map(_.get(data, 'series'), (a) => ({
    name: a.name,
    color: a.color,
    data: a.data,
  }))
  return content
}

// tooltip in normal view
const tooltipFormatter = (chart, data) => {
  const x = chart
  const i = x.points[0].point.index
  const points = getTooltipContent(x, data)

  const options = {
    title: `${x.x}`,
    items: [
      _.map(points, (a) =>
        !_.isNull(a.data[i])
          ? {
              type: 'card',
              label: a.name,
              value: stringToDecimalPoint(a.data[i], 1, 1),
              indicatorColor: a.color,
            }
          : ''
      ),
    ],
  }

  return ReactDOMServer.renderToString(
    <Tooltip
      small
      // headerColor={data.series[i].color}
      type="TooltipTwoColumns"
      {...options}
      className={'plant-production-volume-tooltip'}
    />
  )
}
