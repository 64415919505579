import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'

//Components
import { Grid, Header } from 'semantic-ui-react'
import BarMultiChart from './barMultiChart'
import FetchStateWrapper from '../../../../components/fetch-state-wrapper'
import PeriodPicker from '../../../../components/period-picker'

//Redux
import { PROCESSING_PAGE_REDUCER } from '../../../../reducers/processingPageReducer'
import { updateCDU1Period, postCDU1ChartData } from '../../../../actions/actionProcessing'
import * as ProcessingActions from '../../../../actions/actionProcessing'

//Hooks
import { useStateWithPaths } from '../../../../hooks/useConnect'

const ChartCdu1 = () => {
  const dispatch = useDispatch()
  const [{ cdu1 }] = useStateWithPaths([`${PROCESSING_PAGE_REDUCER}`])
  const { chartData, selectedPeriod } = cdu1

  const [period, setPeriod] = useState([selectedPeriod])

  useEffect(() => {
    dispatch(postCDU1ChartData(period))
  }, [period, postCDU1ChartData])

  return (
    <div className="section with-relative">
      <Grid padded>
        <Grid.Row columns={2} verticalAlign="bottom">
          <Grid.Column textAlign="left">
            <Header className="header-with-subtitle">CDU - 1</Header>
            <div className="header-sub">CRUDE PROCESSING</div>
          </Grid.Column>
          <Grid.Column>
            <div className={`flex end`}>
              <PeriodPicker
                defaultPeriod={period}
                period={period}
                setPeriod={setPeriod}
                onClick={postCDU1ChartData(period)}
                type="month"
              />
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <FetchStateWrapper id={ProcessingActions.PROCESSING_CDU1_FETCH_CHART_DATA}>
              <BarMultiChart data={chartData} />
            </FetchStateWrapper>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default ChartCdu1
